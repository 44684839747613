/* eslint-disable consistent-return */
import checkIsIOSPlatform from "web/utils/checkIsIOSPlatform";

import type { StringNumber } from "web/types/Utils";

import changeToPolishLocalTime from "./changeToPolishLocalTime";

const addZero = (digit: StringNumber) => `0${digit}`.slice(-2);
const toLocalFormat = (
  day: StringNumber,
  month: StringNumber,
  year: StringNumber,
  dashedReversed: boolean
) => (dashedReversed ? `${year}-${month}-${day}` : `${day}.${month}.${year}`);

function formatDate(
  date: unknown,
  dashedReversed = false,
  localTimeChange = false
) {
  if (
    (typeof date !== "string" && typeof date !== "number") ||
    Number.isNaN(date) ||
    date === ""
  )
    return;

  // if date is already in proper format do not proccess it
  if (typeof date === "string" && date.includes(".")) return date;

  if (typeof date === "string" && localTimeChange)
    return changeToPolishLocalTime(date);

  // if date is in format not supported by iOS devices
  if (checkIsIOSPlatform() && typeof date === "string") {
    const iosProperDate = date.split(/[- : T]/);

    if (iosProperDate.length > 1) {
      return toLocalFormat(
        iosProperDate[2],
        iosProperDate[1],
        iosProperDate[0],
        dashedReversed
      );
    }
  }

  // process date arg to number when it is a timestamp
  const processedDate = Number.isNaN(+date) ? new Date(date) : new Date(+date);
  const day = addZero(processedDate.getDate());
  const month = addZero(processedDate.getMonth() + 1);
  const year = processedDate.getFullYear();

  return toLocalFormat(day, month, year, dashedReversed);
}

export default formatDate;
