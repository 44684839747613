import { attributesSliceName } from "web/features/attributes/attributes";
import { campaignGraphicsSliceName } from "web/features/campaignGraphics/campaignGraphicsSlice";
import { categoriesSliceName } from "web/features/categories/categoriesSlice";
import { categoryCountsSliceName } from "web/features/categoryCounts/categoryCountsSlice";
import { facetsStatsSliceName } from "web/features/facetsStats/facetsStatsSlice";
import { productsSliceName } from "web/features/products/productsSlice";
import { productsDomesticTourismSliceName } from "web/features/productsDomesticTourism/productsDomesticTourismSlice";
import { productsTypesSliceName } from "web/features/productsTypes/productsTypesSlice";
import { wishlistProductsSliceName } from "web/features/wishlistProducts/wishlistProductsSlice";

const persistWhitelist: string[] = [
  attributesSliceName,
  campaignGraphicsSliceName,
  categoriesSliceName,
  categoryCountsSliceName,
  facetsStatsSliceName,
  productsSliceName,
  productsDomesticTourismSliceName,
  productsTypesSliceName,
  wishlistProductsSliceName,
];

export default persistWhitelist;
