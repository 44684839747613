import { FC } from "react";

import __ from "web/Layout/Translations";

import styles from "./pointsEqualIcon.scss";

interface IPointsEqualIconProps {
  badge?: string;
}

const PointsEqualIcon: FC<IPointsEqualIconProps> = ({ badge = "" }) => {
  return (
    <div className={styles.root}>
      {badge ? (
        <span className={styles.equalBadge} data-t1="points_equal_icon_badge">
          {badge}
        </span>
      ) : null}
      <span
        className={styles.equalCurrency}
        data-t1="points_equal_icon_currency"
      >
        {__("zł")}
      </span>
      <span className={styles.equalPoints} data-t1="points_equal_icon_points">
        {__("pkt")}
      </span>
    </div>
  );
};

export default PointsEqualIcon;
