// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-modal-iKo{left:0;position:fixed;right:0;bottom:0;top:0;overflow-x:hidden;overflow-y:auto;display:flex;align-items:center;justify-content:center;z-index:2147483004}`, "",{"version":3,"sources":["webpack://./web/Layout/NavigationPanel/container.scss"],"names":[],"mappings":"AAIuB,qBACnB,MAAA,CACA,cAAA,CACA,OAAA,CACA,QAAA,CACA,KAAA,CACA,iBAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .modal {\n    left: 0;\n    position: fixed;\n    right: 0;\n    bottom: 0;\n    top: 0;\n    overflow-x: hidden;\n    overflow-y: auto;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 2147483004;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `container-modal-iKo`
};
export default ___CSS_LOADER_EXPORT___;
