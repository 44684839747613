/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";
import { useSelector } from "react-redux";

import getToken from "web/utils/system/essentials/getToken";

import type { Nullable } from "web/types/Utils";

let setTimeoutId: Nullable<NodeJS.Timeout> = null;
const TIMEOUT = 180000; // 60 * 3 * 1000
const offset = 20000;
const TokenRefresh = (): null => {
  const { tokenTTL } = useSelector((state) => state.app);
  useEffect(() => {
    setTimeoutId = setTimeout(async function refresh() {
      let newToken;
      try {
        newToken = await getToken();
      } finally {
        // @ts-ignore
        setTimeoutId = setTimeout(refresh, newToken?.ttl - offset || TIMEOUT);
      }
    }, tokenTTL! - offset || TIMEOUT);
    return () => {
      if (setTimeoutId) {
        clearTimeout(setTimeoutId);
      }
    };
  }, []);
  return null;
};

export default TokenRefresh;
