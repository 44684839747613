/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { SortOptions, SortOptionsProperty } from "../container";
import classes from "./dropdown.scss";

interface IDropdownProps {
  options: (SortOptions | SortOptionsProperty)[];
  onChange: ({ name, direction }: { name: string; direction?: string }) => void;
  darkMode: boolean;
}

const Dropdown: FC<IDropdownProps> = ({
  options,
  onChange,
  darkMode = false,
}) => {
  const classNameOption = darkMode ? classes.optionDark : classes.option;
  return (
    <div className={classes.root}>
      {options?.map((option) => {
        return (
          <div
            className={classes.group}
            key={(option.name || option.label) as string}
          >
            {isArrayHasItems((option as SortOptionsProperty).direction) ? (
              // @ts-ignore
              option.direction.map((directionItem) => (
                <button
                  key={`${option.name}${directionItem.name}`}
                  className={classNameOption}
                  type="button"
                  data-t2={option.name || option.label}
                  data-t1="dropdown_option_button"
                  onClick={() => {
                    onChange({
                      name: option.name as string,
                      direction: directionItem.name,
                    });
                  }}
                >
                  <span
                    className={classes.label}
                    data-t1={`${option.name || option.label}_span`}
                  >
                    {/* @ts-ignore */}
                    {__(option.label)}:
                  </span>{" "}
                  {__(directionItem.label)}
                </button>
              ))
            ) : (
              <button
                className={classes.option}
                type="button"
                data-t2={option.name || option.label}
                data-t1="dropdown_option_button"
                onClick={() => {
                  onChange({
                    name: option.name as string,
                  });
                }}
              >
                <span
                  className={classes.label}
                  data-t1={`${option.name || option.label}_span`}
                >
                  {/* @ts-ignore */}
                  {__(option.label)}
                  {option.name && ":"}
                </span>
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Dropdown;
