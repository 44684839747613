import { FC } from "react";
import { useSelector } from "react-redux";

import SectionList from "web/Layout/Common/SectionList";
import ErrorBoundary from "web/Layout/ErrorBoundary";
import PointsLanguage from "web/Layout/MainHeader/Desktop/Customer/PointsLanguage";

import { ICmsBlockItem } from "web/types/CmsBlocks";
import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";
import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import Logo from "../Common/Logo";
import defaultClasses from "./footer.scss";

interface IFooterFromCmsProps {
  footerContent: ICmsBlockItem[];
}

const FooterFromCms: FC<PropsWithClasses<IFooterFromCmsProps>> = ({
  classes = {},
  footerContent,
}) => {
  const { storeConfig } = useSelector((state) => state.app);

  const { data: dataBanks } = useGetBanksQuery();
  const { total: totalPoints } = dataBanks || {};

  const [footerLogoSrc, footerLogoAlt] = [
    storeConfig?.footer_logo_src,
    storeConfig?.footer_logo_alt,
  ];

  return (
    <footer className={`container ${classes.root}`}>
      <div className={classes.row}>
        <div className={classes.sections}>
          <ErrorBoundary>
            {footerContent?.map((menuSection) => {
              return (
                <SectionList
                  key={menuSection.headerText}
                  className={classes.section}
                  title={menuSection.headerText}
                  items={menuSection.elements}
                  keyProperty="text"
                />
              );
            })}
          </ErrorBoundary>
        </div>
        <ErrorBoundary>
          <div className={classes.column}>
            <Logo
              className={classes.logo}
              logoSrc={footerLogoSrc}
              logoAlt={footerLogoAlt || "logo"}
            />
            <PointsLanguage
              className={classes.points}
              totalPoints={totalPoints}
            />
          </div>
        </ErrorBoundary>
      </div>
    </footer>
  );
};

export default classify<PropsWithClasses<IFooterFromCmsProps>>(defaultClasses)(
  FooterFromCms
);
