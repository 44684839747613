import { FC } from "react";

import __ from "web/Layout/Translations";

import RefreshArrowIcon from "web/assets/icons/refresh-arrow.svg";

import ResetButton from "../ResetButton";
import classes from "./reset.scss";

interface IResetProps {
  facetsHidden: string[];
}

const Reset: FC<IResetProps> = ({ facetsHidden = [] }) => {
  const text = __("Resetuj ustawienia");

  return (
    <div className={classes.root}>
      <ResetButton
        className={classes.button}
        text={text}
        dataT1="reset_button"
        facetsHidden={facetsHidden}
      >
        <RefreshArrowIcon width={14} />
        {text}
      </ResetButton>
    </div>
  );
};

export default Reset;
