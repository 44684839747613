import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

export default class AbstractBrowserStorage {
  removeItem(name, key) {
    if (this.storage) {
      if (key) {
        const dataByName = this.storage.getItem(name);
        try {
          const dataByNameParsed = dataByName ? JSON.parse(dataByName) : null;
          if (
            dataByNameParsed &&
            Object.prototype.hasOwnProperty.call(dataByNameParsed, key)
          ) {
            delete dataByNameParsed[key];
            this.storage.setItem(
              name,
              JSON.stringify({
                ...dataByNameParsed,
              })
            );
          }
        } catch (error) {
          newRelicErrorReport(
            error,
            "web-app/web/utils/system/storage/storage/abstractBrowserStorage.js - 23"
          );
          console.error(error);
        }
      } else {
        this.storage.removeItem(name);
      }
    }
  }
}
