// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productConfigurableOptions-configurableOptions-SJf{height:170px;margin-bottom:25px;border-radius:26px !important}`, "",{"version":3,"sources":["webpack://./web/Layout/Common/SkeletonComponents/SkeletonProduct/SkeletonProductConfigurableOptions/productConfigurableOptions.scss"],"names":[],"mappings":"AAIuB,oDACrB,YAAA,CACA,kBAAA,CACA,6BAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .configurableOptions {\n  height: 170px;\n  margin-bottom: 25px;\n  border-radius: 26px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"configurableOptions": `productConfigurableOptions-configurableOptions-SJf`
};
export default ___CSS_LOADER_EXPORT___;
