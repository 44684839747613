import { useMemo } from "react";

import checkIfAppIsInPWAMode from "./utils/checkIfAppIsInPWAMode";

export const enum AppModes {
  WEBVIEW = "webview",
  PWA = "pwa",
  BROWSER = "browser",
}

const useAppMode = (): AppModes => {
  const appMode = useMemo<AppModes>(() => {
    if (window.ReactNativeWebView) {
      return AppModes.WEBVIEW;
    }
    if (checkIfAppIsInPWAMode()) {
      return AppModes.PWA;
    }
    return AppModes.BROWSER;
  }, []);

  return appMode;
};

export default useAppMode;
