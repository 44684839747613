import { isArray } from "lodash";

import sanitizeArray from "../array/sanitizeArray";
import sanitizeString from "../string/sanitizeString";

const isObject = (value: unknown): value is Record<string, unknown> => {
  return typeof value === "object" && value !== null;
};

const sanitizeObject = <T>(entity: T): T => {
  if (!entity || typeof entity !== "object") {
    return entity;
  }

  const sanitizedObject: Record<string, unknown> = {};
  Object.entries(entity).forEach(([key, value]) => {
    if (!Object.prototype.hasOwnProperty.call(entity, key)) return;

    if (typeof value === "string") {
      sanitizedObject[key] = sanitizeString(value);
    } else if (isArray(value)) {
      sanitizedObject[key] = sanitizeArray(value);
    } else if (isObject(value)) {
      sanitizedObject[key] = sanitizeObject(value);
    } else {
      sanitizedObject[key] = value;
    }
  });
  return sanitizedObject as T;
};

export default sanitizeObject;
