const datasetPromotionCountFirstPart = 'data-promotion-count="';
const datasetPromotionCountLastPart = '"';

/*
    Function getHtmlPromoCount gets value of data-promotion-count. 
    Example:
        function gets: 
            "{\"content\":\"<div class=\\\"kontener_1600\\\" data-promotion-count=\\\"15\\\">\\r\\n<\\/div>\\r\\n\"}"
        function returns:
            15
*/

const getHtmlPromoCount = (params: string) => {
    if (!params) {
        return 0;
    }

    const parsedContent = JSON.parse(params)?.content || "";
    const splittedContentByFirstPart = parsedContent?.split(datasetPromotionCountFirstPart) || [];

    if (splittedContentByFirstPart.length < 2) {
        return 0;
    }
    
    const contentWithoutFirstPart = splittedContentByFirstPart?.pop() || "";
    const splittedContentByLastPart = contentWithoutFirstPart?.split(datasetPromotionCountLastPart) || [];
    const promoCount = +(splittedContentByLastPart?.[0] || 0);
    return promoCount;
};

export default getHtmlPromoCount;
