import { FC } from "react";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import processUrlKey from "web/utils/page/product/universal/processUrlKey";

import { ProductInList } from "web/types/Product";

import Product from "./Product";
import classes from "./products.scss";

interface ISearchAutocompleteProductsMobileProps {
  products: ProductInList[];
  closeAction: () => void;
}

const SearchAutocompleteProductsMobile: FC<
  ISearchAutocompleteProductsMobileProps
> = ({ products, closeAction }) => {
  return isArrayHasItems(products) ? (
    <>
      {products.map((product) => (
        <Product
          className={classes.item}
          key={product.id}
          name={product.name}
          url={processUrlKey(product.url_key)}
          image={product.thumbnail}
          price={product.price}
          finalPrice={product.final_price}
          options={product.product_options}
          closeAction={closeAction}
          type={product.mb_product_type}
        />
      ))}
    </>
  ) : null;
};

export default SearchAutocompleteProductsMobile;
