import { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import useBenefitsListingSearch from "web/Layout/Listing/ListingSearch/useBenefitsListingSearch";
import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { FacetsStats } from "web/types/Product";

import { getFilteredBenefitGroups } from "web/features/subscriptions/benefitGroupsThunks";

import Categories from "./Categories";
import Products from "./Products";
import classes from "./autocomplete.scss";

const PRODUCTS_LIST_LENGTH = 4;
const CATEGORIES_LIST_LENGTH = 10;

interface ISearchAutocompleteProps {
  data: FacetsStats;
  searchPhrase: string;
  closeAction: () => void;
}

const SearchAutocomplete: FC<ISearchAutocompleteProps> = ({
  data = {} as FacetsStats,
  searchPhrase,
  closeAction,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.subscription_items) {
      const subscriptionsSku =
        data?.subscription_items?.map((el) => el.sku) || [];

      dispatch(getFilteredBenefitGroups({ skuArray: subscriptionsSku }));
    }
  }, [data]);

  const { idsMapped } = useBenefitsListingSearch(
    data?.items_ids,
    data?.subscription_items
  );

  const [productIds, categoriesIds] = useMemo(() => {
    const productsIdsMemo = isArrayHasItems(idsMapped)
      ? idsMapped.slice(0, PRODUCTS_LIST_LENGTH)
      : [];
    const categories =
      data &&
      data.facets &&
      isArrayHasItems(data.facets) &&
      data.facets.find((facet) => facet.code === "category_ids");
    const categoriesIdsMemo =
      categories && isArrayHasItems(categories.values)
        ? categories.values
            ?.slice(0, CATEGORIES_LIST_LENGTH)
            .map((category) => +category.value)
        : [];
    return [productsIdsMemo, categoriesIdsMemo];
  }, [data, data?.subscription_items, idsMapped]);

  return isArrayHasItems(productIds) || isArrayHasItems(categoriesIds) ? (
    <div className={classes.root} data-t1="autocomplete">
      <section className={classes.categories}>
        <Categories
          ids={categoriesIds}
          productIds={productIds}
          searchPhrase={searchPhrase}
          closeAction={closeAction}
          isSubscriptions={isArrayHasItems(data?.subscription_items)}
        />
      </section>
      <section className={classes.products}>
        <Products
          ids={productIds}
          closeAction={closeAction}
          searchPhrase={searchPhrase}
        />
      </section>
    </div>
  ) : (
    <div className={classes.empty}>{__("Brak wyników wyszukiwania")}</div>
  );
};

export default SearchAutocomplete;
