/* eslint-disable @typescript-eslint/ban-ts-comment */
import type {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
} from "@reduxjs/toolkit";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading/loading";
import __ from "web/Layout/Translations";

import ArrowLeftIcon from "web/assets/icons/arrow-left.svg";
import ArrowRightIcon from "web/assets/icons/arrow-right.svg";

import jsonParse from "web/utils/data/parser/string/jsonParse";

import type { ICategory } from "web/types/Category";
import type { ICategoryCount } from "web/types/CategoryCount";
import { Functionality } from "web/types/Employee";
import {
  IFunctionalityMenuItem,
  IUrlFunctionalityMenuItem,
  IUrlMenuItem,
  MenuItemType,
} from "web/types/Menu";
import type { Nullable } from "web/types/Utils";

import { useGetMenuWebviewQuery } from "web/features/api/graphQLApiSlice";
import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";
import { useGetEmployeeDetailsQuery } from "web/features/employee/employeeApiSlice";

import classes from "./list.scss";
import NavItem from "./navItem";

const getLink = (path: string) => (!path.indexOf("/") ? path : `/${path}`);

const redirectToCategory = (categoryObj: ICategory) => {
  switch (true) {
    case categoryObj.url_key === "turystyka-online":
      return getLink("tourism");
    default:
      return getLink(categoryObj.request_path);
  }
};

interface INavigationWebViewListProps {
  rootCategoryId: number;
  startCategoryId: Nullable<number>;
  categoriesTree?: string[];
  categoriesData: ICategory[];
  categoriesSizes: ICategoryCount[];
  setCategory: ActionCreatorWithPayload<Nullable<number>, string>;
  closeNavigationAction: ActionCreatorWithoutPayload<string>;
}

const NavigationWebViewList: FC<INavigationWebViewListProps> = ({
  rootCategoryId,
  startCategoryId = "",
  categoriesTree,
  categoriesData,
  categoriesSizes,
  setCategory,
  closeNavigationAction,
}) => {
  const {
    app: { storeConfig },
  } = useSelector((state) => state);

  const {
    mybenefit_active_url: myBenefitActiveUrlPl,
    mybenefit_active_url_en: myBenefitActiveUrlEn,
    mybenefit_active_menu: myBenefitActiveMenuPl,
    mybenefit_active_menu_en: myBenefitActiveMenuEn,
    msi_url: msiUrlPl,
    msi_url_en: msiUrlEn,
    msi_menu_en: msiMenuEn,
  } = storeConfig;
  const { data: employee } = useGetEmployeeDetailsQuery();
  const {
    functionalities,
    msiCartsEnabled: msiCartsEnabledSelector,
    mbActiveEnabled: mbActiveEnabledSelector,
  } = employee || {};

  const { data, isLoading, isError } = useGetMenuWebviewQuery();
  const { data: customer } = useGetCustomerDetailsQuery();
  const { language } = customer || {};

  const isEn = language === "en_GB";
  const myBenefitActiveUrl = isEn ? myBenefitActiveUrlEn : myBenefitActiveUrlPl;
  const myBenefitActiveMenu = isEn
    ? myBenefitActiveMenuEn
    : myBenefitActiveMenuPl;

  const msiUrl = isEn ? msiUrlEn : msiUrlPl;

  const dispatch = useDispatch();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorComponent />;
  }

  const initialStartCategoryId = startCategoryId === 2 ? null : startCategoryId;
  const treeCurrent = categoriesTree?.reduce<ICategory[]>((result, path) => {
    switch (true) {
      case !!initialStartCategoryId: {
        const startCategoryIdStringBegin = `${startCategoryId}/`;
        const startCategoryIdStringMiddle = `/${startCategoryId}/`;

        let pathSliced: string;
        if (path.indexOf(startCategoryIdStringBegin) === 0) {
          pathSliced = path.slice(startCategoryIdStringBegin.length);
        }

        if (path.indexOf(startCategoryIdStringMiddle) !== -1) {
          pathSliced = path.slice(
            path.indexOf(startCategoryIdStringMiddle) +
              startCategoryIdStringMiddle.length
          );
        }

        // @ts-ignore
        if (pathSliced?.length) {
          const idChild = parseInt(pathSliced, 10);
          const categoryDataChild =
            idChild &&
            categoriesData.find(
              (category) => category && +category.id === +idChild
            );
          return categoryDataChild ? [...result, categoryDataChild] : result;
        }

        return result;
      }
      case path.indexOf("/") === -1: {
        const categoryData = categoriesData.find(
          (category) => category && +category.id === +path
        );
        return categoryData ? [...result, categoryData] : result;
      }
      default: {
        return result;
      }
    }
  }, []);

  const treeCurrentUnique = [
    ...new Map(treeCurrent?.map((item) => [item.id, item])).values(),
  ];
  const startCategoryData = initialStartCategoryId
    ? // @ts-ignore
      categoriesData.find((category) => +category.id === +startCategoryId)
    : null;

  const list = treeCurrentUnique
    .sort((a, b) => a?.position - b?.position)
    .map((category) => {
      const categoryIdStringBegin = `${category.id}/`;
      const categoryIdStringMiddle = `/${category.id}/`;
      const hasSubcategory = categoriesTree?.some((categoryPath) => {
        switch (true) {
          case categoryPath.indexOf(categoryIdStringBegin) === 0:
          case categoryPath.indexOf(categoryIdStringMiddle) !== -1: {
            return true;
          }
          default: {
            return false;
          }
        }
      });

      const content = (
        <>
          <span>{category.name}</span>
          <ArrowRightIcon width={10} />
        </>
      );

      if (categoriesSizes.find((x) => +x.id === +category.id)) {
        if (hasSubcategory) {
          return (
            <button
              key={category.id}
              type="button"
              className={classes.navItem}
              onClick={() => dispatch(setCategory(category.id))}    
            >
              {content}
            </button>
          );
        }
        return (
          <Link
            key={category.id}
            className={classes.navItem}
            to={redirectToCategory(category)}
            onClick={() => {
              dispatch(setCategory(null));
              dispatch(closeNavigationAction());
            }}
          >
            {content}
          </Link>
        );
      }
      return null;
    });

  const menuData = jsonParse<
    (IFunctionalityMenuItem | IUrlMenuItem | IUrlFunctionalityMenuItem)[]
  >(data?.menu, true)?.filter(({ show_on_mobile }) => show_on_mobile === "1");
  const additionalList = menuData.map((item) => {
    // @ts-ignore
    const { type, title, code, url, url_en, functionality, url_functionality } =
      item;

    if (type === MenuItemType.URL) {
      return <NavItem key={code} to={isEn ? url_en : url} title={title} />;
    }

    const funcFromEmployee = functionalities?.find(
      (func) => func === functionality || func === url_functionality
    );

    if (type === MenuItemType.FUNCTIONALITY) {
      if (
        funcFromEmployee === Functionality.ENABLE_MYBENEFIT_ACTIVE &&
        mbActiveEnabledSelector &&
        myBenefitActiveUrl
      ) {
        return (
          <NavItem
            key={code}
            to={myBenefitActiveUrl}
            title={myBenefitActiveMenu ?? title}
          />
        );
      }

      if (funcFromEmployee === Functionality.ENABLE_MSI_CARDS) {
        return (
          <NavItem key={code} to={msiUrl} title={isEn ? msiMenuEn : title} />
        );
      }
    }

    if (type === MenuItemType.URL_FUNCTIONALITY) {
      if (
        funcFromEmployee &&
        (funcFromEmployee !== Functionality.ENABLE_MSI_CARDS ||
          (funcFromEmployee === Functionality.ENABLE_MSI_CARDS &&
            msiCartsEnabledSelector))
      ) {
        return <NavItem key={code} to={isEn ? url_en : url} title={title} />;
      }
    }

    return null;
  });

  if (!startCategoryData) {
    list?.push(...additionalList);
  }

  return startCategoryData ? (
    <div className={classes.subcategoryRoot}>
      <div className={classes.subcategory}>
        <button
          className={classes.subcategoryHeader}
          type="button"
          onClick={() => {
            const pathSplit = startCategoryData.path.split("/");
            const currentIdIndex = pathSplit.indexOf(`${startCategoryData.id}`);
            const parentId = currentIdIndex
              ? +pathSplit[currentIdIndex - 1]
              : null;
            const parentIdProcessed =
              parentId && parentId !== rootCategoryId ? parentId : null;
            dispatch(setCategory(parentIdProcessed));
          }}
        >
          <ArrowLeftIcon width={10} />
          <span>{startCategoryData.name}</span>
        </button>
        <Link
          className={classes.navItem}
          to={getLink(startCategoryData.request_path)}
          onClick={() => {
            dispatch(setCategory(null));
            dispatch(closeNavigationAction())
          }}
        >
          <span>{__("Wszystkie w ") + startCategoryData.name}</span>
          <ArrowRightIcon width={10} />
        </Link>
        {list}
      </div>
    </div>
  ) : (
    <div className={classes.categoryRoot}>{list}</div>
  );
};

export default NavigationWebViewList;
