import { useCallback, useState } from "react";

import __ from "web/Layout/Translations";

import Button from "web/Components/Common/Button";

import tooltipMessages from "web/constants/tooltipMessages";

import classes from "./unavailableTooltip.scss";

let timeout: NodeJS.Timeout;

export const enum UnavailableTooltipVariants {
  OOS = "OOS",
  NA = "na",
}

const UnavailableTooltip = ({ variant = UnavailableTooltipVariants.OOS }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleTouch = useCallback(() => {
    if (timeout) {
      clearTimeout(timeout);
    }

    setIsTooltipVisible(true);
    timeout = setTimeout(() => {
      setIsTooltipVisible(false);
    }, 5000);
  }, []);

  return (
    <div className={classes.root}>
      <div
        className={classes.tooltip}
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        onTouchStart={handleTouch}
      >
        <Button disabled>
          {variant === UnavailableTooltipVariants.OOS
            ? __("Chwilowo wyprzedane")
            : __("Produkt niedostępny")}
        </Button>
        <div
          className={`${classes.messageFromRight} ${
            isTooltipVisible ? classes.visible : ""
          }`}
        >
          <span>{__(tooltipMessages.productUnavailable)}</span>
        </div>
      </div>
    </div>
  );
};

export default UnavailableTooltip;
