import __ from "web/Layout/Translations";

import type { StringNumber } from "web/types/Utils";

const getRoomText = (count: StringNumber) => {
  switch (true) {
    case +count === 1: {
      return `${count} ${__("pokój")}`;
    }
    case [2, 3, 4].indexOf(+count) !== -1: {
      return `${count} ${__("pokoje")}`;
    }
    default: {
      return `${count} ${__("pokoi")}`;
    }
  }
};

export default getRoomText;
