import {
  IEmployeeRentableGroupAdditionalDataEntry,
  RentableGroupAgreement,
} from "web/types/Employee";

import { IRentableGroupCalculatorValues } from "../rentableGroupCalculatorContainer";
import calculateAverageIncome from "./calculateAverageIncome";
import calculateTotalIncome from "./calculateTotalIncome";

const getAdditionalData = (
  values: IRentableGroupCalculatorValues,
  monthCountToDivide: number,
  rentableGroupAgreementsList?: RentableGroupAgreement[]
): IEmployeeRentableGroupAdditionalDataEntry => {
  const agreementsMap = new Map();

  rentableGroupAgreementsList?.forEach(({ id, value }) => {
    agreementsMap.set(id, value);
  });
  const agreements = values.agreements
    ? Object.entries(values.agreements).map(([id, value]) => ({
        id,
        content: agreementsMap.get(id),
        value,
      }))
    : [];
  const additionalData = {
    financingCheckbox: values["financing-checkbox"],
    fillCalculatorCheckbox: values["fill-calculator-checkbox"],
    agreements,
  };

  if (values["financing-checkbox"] || values["fill-calculator-checkbox"]) {
    return additionalData;
  }
  const { income } = values;
  const householdCount = income.people.length + 1;
  const totalIncome = calculateTotalIncome(income);
  const averageIncome = calculateAverageIncome({
    totalIncome,
    peopleCount: householdCount,
    monthsCount: monthCountToDivide,
  });

  const peopleIncome = [
    { role: "me", income: income.me || 0 },
    ...income.people.map((incomeData) => ({
      ...incomeData,
      income: incomeData.income || 0,
    })),
  ];

  return {
    ...additionalData,
    income: peopleIncome,
    householdCount,
    totalIncome,
    averageIncome,
  };
};

export default getAdditionalData;
