import { FC } from "react";

import Anchor from "web/Components/Common/Anchor";

import ArrowRightIcon from "web/assets/icons/arrow-right.svg";

import classes from "./navItem.scss";

interface INavItemProps {
  key: string;
  to: string;
  title: string;
}

const NavItem: FC<INavItemProps> = ({ key, to, title }) => (
  <Anchor key={key} className={classes.navItem} to={to}>
    <span>{title}</span>
    <ArrowRightIcon width={10} />
  </Anchor>
);

export default NavItem;
