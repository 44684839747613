import { FC, ReactNode } from "react";

import styles from "./subscriptionActivitiesList.scss";

interface ISubscriptionActivitiesList {
  activities: ReactNode[];
}

const SubscriptionActivitiesList: FC<ISubscriptionActivitiesList> = ({
  activities,
}) => {
  return activities.length ? (
    <ul className={styles.body}>{activities}</ul>
  ) : null;
};

export default SubscriptionActivitiesList;
