import { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";

import getToken from "web/utils/system/essentials/getToken";
import logout from "web/utils/system/essentials/logout";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { Nullable } from "web/types/Utils";

const enum SessionActionType {
  ERROR = "error",
  LOGGED = "logged",
}

type ErrorAction = {
  type: SessionActionType.ERROR;
};

type LoggedAction = {
  type: SessionActionType.LOGGED;
  ttl: Nullable<number>;
};

export type SessionReducerAction = ErrorAction | LoggedAction;

export interface ISessionState {
  loading: boolean;
  error: boolean;
  isLogged: boolean;
  ttl: import("web/types/Utils").Nullable<number>;
  dispatch?: (args: SessionReducerAction) => void;
}

type ErrorData = {
  response: {
    status: number;
  };
};

const { search } = window.location;
const params = new URLSearchParams(search);
const NAME_NATIVE = "redirectBack";
const NAME_NATIVE_LOGOUT = "clearCachedToken";
const shouldLogoutAndRedirectToNative =
  search && params.has(NAME_NATIVE) && params.has(NAME_NATIVE_LOGOUT);
const shouldRedirectToNative = search && params.has(NAME_NATIVE);

const initialState = {
  loading: true,
  error: false,
  isLogged: false,
  ttl: null,
};

const reducer = (state: ISessionState, action: SessionReducerAction) => {
  switch (action.type) {
    case SessionActionType.ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case SessionActionType.LOGGED: {
      return {
        ...state,
        isGuest: false,
        isLogged: true,
        loading: false,
        ttl: action.ttl,
      };
    }
    default:
      return initialState;
  }
};

const useSession = () => {
  const { pathname } = useLocation();
  const [{ loading, error, isLogged, ttl }, dispatch] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getToken();

        if (
          response &&
          response.token &&
          response.accessToken &&
          response.ttl
        ) {
          if (shouldLogoutAndRedirectToNative) {
            logout(`${NAME_NATIVE}=${params.get(NAME_NATIVE)}`, null, "native");
            return;
          }
          if (shouldRedirectToNative) {
            window.location = `${params.get(NAME_NATIVE)}?token=${
              response.token
            }&tokenAccess=${response.accessToken}` as unknown as Location;
            return;
          }

          dispatch({ type: SessionActionType.LOGGED, ttl: response.ttl });
        } else {
          dispatch({ type: SessionActionType.ERROR });
        }
      } catch (errorData) {
        if (
          (errorData as ErrorData)?.response?.status &&
          (errorData as ErrorData).response.status !== 401
        ) {
          newRelicErrorReport(
            errorData,
            "web-app/web/hooks/useSession/useSession.ts - 112"
          );
          dispatch({ type: SessionActionType.ERROR });
        }
      }
    };

    if (!isLogged && !error) {
      fetchData();
    }
  }, [isLogged, error, pathname]);

  return {
    loading,
    error,
    isLogged,
    ttl,
  };
};

export default useSession;
