import { FC, PropsWithChildren } from "react";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./transparentButton.scss";

interface ITransparentButtonProps {
  type?: "button" | "reset" | "submit" | undefined;
}

const TransparentButton: FC<
  PropsWithChildren<PropsWithClasses<ITransparentButtonProps>>
> = ({ children, classes = {}, type = "button", ...rest }) => {
  return (
    // eslint-disable-next-line
    <button className={classes.root} type={type} {...rest}>
      {children}
    </button>
  );
};

export default classify(defaultClasses)(TransparentButton);
