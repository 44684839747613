import { URL_SEND } from "web/constants/session";

const clearStorageAndRedirectToLogin = async () => {
  const urlSearchParams = {
    relayState: window.location.href,
    loginOrigin: window.location.origin,
  } as Record<string, string>;
  if (window.ReactNativeWebView) {
    urlSearchParams.specialClient = "true";
    if (window.appLoginFlow === "mobileApp-v1") {
      urlSearchParams.loginOrigin = "mybenefit://webview";
      urlSearchParams.loginFlow = window.appLoginFlow;
    }
  }
  const PARAMS = new URLSearchParams(urlSearchParams);

  sessionStorage.clear();
  localStorage.clear();

  window.location.replace(`${URL_SEND}?${PARAMS.toString()}`);
};

export default clearStorageAndRedirectToLogin;
