import { ASTNode, print } from "graphql";
import { useMemo, useEffect, useState } from "react";
import { request } from "web/api";
import { IApiRequestMethods } from "web/api/apiRequestTypes";
import useDataCachedCampaignGraphics from "web/features/useDataCached/useDataCachedCampaignGraphics";
import campaignGraphicsIdsQuery from "web/queries/default/campaignGraphicsIds.graphql";
import { ICampaignGraphicsIds } from "web/types/CampaignGraphics";
import type { IStoreConfig } from "web/types/StoreConfig";
import type { Nullable } from "web/types/Utils";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import graphQlAsGet from "web/utils/system/query/graphQlAsGet";

const endpoint = "/graphql";

interface IUseCampaignGraphicsArgs {
  placement: string;
  categoryId?: Nullable<number>;
  storeConfig: IStoreConfig;
}

interface IUseCampaignGraphicsResponse {
  data:{campaignGraphicsIds: ICampaignGraphicsIds}
}

interface IUseCampaignGraphicsState {
  errorIds: unknown,
  loadingIds: boolean,
  ids: Nullable<number[]>
}

const useCampaignGraphicsIds = ({
  placement,
  categoryId,
  storeConfig,
}: IUseCampaignGraphicsArgs) => {
  const { token, id: storeId } = storeConfig;

  const [{ errorIds, loadingIds, ids }, setIds] = useState<IUseCampaignGraphicsState>({
    errorIds: "",
    loadingIds: false,
    ids: null,
  });

  useEffect(() => {
    let isMounted = true;

    async function getCampaignGraphicsIds() {
      if (setIds)
        setIds({
          loadingIds: true,
          errorIds: "",
          ids: null,
        });

      const selector: { position?: string; categoryId?: Nullable<number> } = {};
      if (placement) selector.position = placement;
      else selector.categoryId = categoryId;

      try {
        const response: unknown = await graphQlAsGet(
          endpoint,
          {
            method: IApiRequestMethods.POST,
            body: JSON.stringify({
              query: print(campaignGraphicsIdsQuery as ASTNode),
              variables: {
                storeId,
                token,
                ...selector,
              },
            }),
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          request
        );

        if (setIds && isMounted) {
          setIds({
            loadingIds: false,
            errorIds: "",
            ids: (<IUseCampaignGraphicsResponse>response)?.data?.campaignGraphicsIds?.id,
          });
        }
      } catch (error) {
        newRelicErrorReport(
          error,
          "web-app/web/hooks/useCampaignGraphics/useCampaignGraphics.js - 68"
        );
        if (setIds && isMounted)
          setIds({
            loadingIds: false,
            errorIds: JSON.stringify(error),
            ids: null,
          });
      }
    }
    getCampaignGraphicsIds();

    return () => {
      isMounted = false;
    };
  }, [placement, categoryId, setIds]);

  const graphicIds = isArrayHasItems(ids) ? ids : [];

  const element = {
    ids: graphicIds,
  };

  const {
    error: errorGraphics,
    loading: loadingGraphics,
    data: dataGraphics,
  } = useDataCachedCampaignGraphics(element);

  if (errorGraphics)
    newRelicErrorReport(
      errorGraphics,
      "web-app/web/hooks/useCampaignGraphics/useCampaignGraphics.js - 101"
    );

  return useMemo(
    () => ({
      graphics: isArrayHasItems(dataGraphics) ? dataGraphics : [],
      error: errorIds || errorGraphics,
      loading: loadingIds || loadingGraphics,
    }),
    [
      errorIds,
      errorGraphics,
      loadingIds,
      loadingGraphics,
      JSON.stringify(dataGraphics),
    ]
  );
};

export default useCampaignGraphicsIds;
