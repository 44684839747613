const deleteGraphqlExtraSpaces = <T>(str: T) => {
  if (typeof str !== "string") return str;
  const query = str.replace(
    /\s\s+|\s{\s\s+|{\s\s+|\s\s+}|\s}/g,
    removeExtraSpaces
  );

  return query;
};

const removeExtraSpaces = (match: string) => {
  if (match.includes("{")) {
    return "{";
  }
  if (match.includes("}")) {
    return "}";
  }
  return " ";
};

export default deleteGraphqlExtraSpaces;
