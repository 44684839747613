/* eslint-disable jsx-a11y/control-has-associated-label */
import { FC, PropsWithChildren, ReactNode } from "react";

import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import BackNavElement from "../BackNavElement";
import { IBackNavElement } from "../BackNavElement/backNavElement";
import classes from "./mobileHeader.scss";

interface IMobileHeaderBase extends IBackNavElement {
  dataT1?: string;
  rightEl?: ReactNode;
}

export const MobileHeader: FC<PropsWithChildren<IMobileHeaderBase>> = ({
  children,
  dataT1,
  rightEl,
  ...restProps
}) => {
  if (!children) return null;

  const rightElement = rightEl && (
    <div className={classes.headerBar__rightEl}>{rightEl}</div>
  );

  return (
    <header className={classes.headerBar} data-t1={dataT1}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <BackNavElement {...restProps} />
      <div className={classes.headerBar__wrapper}>
        <div className={classes.headerBar__content}>{children}</div>
        {rightElement}
      </div>
    </header>
  );
};

const HeaderWithScrollToTop = withScrollToTop(MobileHeader);
export default HeaderWithScrollToTop;
