import clearCache from "web/utils/system/essentials/clearCache";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import storageNames from "web/constants/storageNames";
import urls from "web/constants/urls";

import { Nullable } from "web/types/Utils";

import newRelicErrorReport from "./newRelicErrorReport";

const storage = new BrowserPersistence();

const logout = async (
  paramsAdditional?: string,
  idTokenHint?: Nullable<string>,
  logoutId?: string
) => {
  const idToken = storage.getItem(storageNames.idToken) || idTokenHint;

  const params = new URLSearchParams({
    post_logout_redirect_uri: `${window.location.origin}${urls.customerLogout}`,
    id_token_hint: idToken,
  });
  const paramsAdditionalProcessed = paramsAdditional
    ? `&${paramsAdditional}`
    : "";
  if (idToken) {
    await clearCache();
    return window.location.replace(
      `${
        process.env.LOGOUT_URL
      }?${params.toString()}${paramsAdditionalProcessed}`
    );
  }

  newRelicErrorReport(new Error("no idToken"), logoutId);
  return window.location.replace(urls.error);
};

export default logout;
