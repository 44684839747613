import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import type { Nullable, StringNumber } from "web/types/Utils";

const currentLanguage = getLanguageCode();

const fractionDigits = 2;
export const fractionSigns = currentLanguage === "en" ? "." : ",";
const pattern = /(-?\d+)(\d{3})/;
const parse = (number: StringNumber) => {
  let numberString = number.toString();
  while (pattern.test(numberString))
    numberString = numberString.replace(pattern, "$1 $2");
  return numberString;
};

// Format number 123456789.12345 to 123 456 789,12
const formatNumber = (number: Nullable<StringNumber>) => {
  if (!number) {
    return (0).toFixed(fractionDigits).replace(".", fractionSigns);
  }

  const num = typeof number === "string" ? +number.replace(",", ".") : number;
  return typeof num === "number" && !Number.isNaN(num)
    ? parse(num.toFixed(fractionDigits).replace(".", fractionSigns))
    : number;
};

export default formatNumber;
