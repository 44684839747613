import { useLocation } from "react-router-dom";

import {
  pageDefault,
  pageName,
  pageSizeDefault,
  pageSizeName,
} from "web/constants/toolbar";

const usePaginationParams = () => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const page = parseInt(params.get(pageName) || "", 10) || pageDefault;
  const pageSize =
    parseInt(params.get(pageSizeName) || "", 10) || pageSizeDefault;

  return { page, pageSize, params };
};

export default usePaginationParams;
