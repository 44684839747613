// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categoriesPage-desktopWrapper-bB_{margin:0 15px}.categoriesPage-mobileWrapper-Kai{margin-top:90px}.categoriesPage-breadcrumbsWrapper-gpf{padding-top:30px}`, "",{"version":3,"sources":["webpack://./web/Layout/Common/SkeletonComponents/SkeletonCategoriesPage/categoriesPage.scss"],"names":[],"mappings":"AAIuB,mCACrB,aAAA,CAGF,kCACE,eAAA,CAGF,uCACE,gBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .desktopWrapper {\n  margin: 0 15px;\n}\n\n.mobileWrapper {\n  margin-top: 90px;\n}\n\n.breadcrumbsWrapper {\n  padding-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktopWrapper": `categoriesPage-desktopWrapper-bB_`,
	"mobileWrapper": `categoriesPage-mobileWrapper-Kai`,
	"breadcrumbsWrapper": `categoriesPage-breadcrumbsWrapper-gpf`
};
export default ___CSS_LOADER_EXPORT___;
