import { useContext, useEffect } from "react";

import { WebViewCtx } from "web/context/webView";

const useWebViewInfo = () => {
  const ctx = useContext(WebViewCtx);

  useEffect(() => {
    if (!ctx) throw new Error("useWebViewInfo used outside of WebView");
  }, []);

  return ctx;
};

export default useWebViewInfo;
