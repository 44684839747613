/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, PropsWithChildren } from "react";
import SwiperCore, { Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { Classes } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./carouselMobile.scss";

SwiperCore.use([Mousewheel]);

interface ICarouselMobileProps {
  classes?: Classes;
  withoutGlider?: boolean;
  withoutOverflow?: boolean;
  freeMode?: boolean;
}

const CarouselMobile: FC<PropsWithChildren<ICarouselMobileProps>> = ({
  classes = {},
  children = null,
  withoutGlider = false,
  withoutOverflow = false,
  freeMode = false,
}) => {
  const rootClassName = `${classes.root || ""} ${classes.scroll || ""}`.trim();
  const rowClasses = withoutOverflow ? classes.rowShort : classes.rowLong;
  return children && isArrayHasItems(children) ? (
    <div className={classes.root}>
      <div className={rowClasses}>
        {!withoutGlider ? (
          <Swiper
            slidesPerView="auto"
            mousewheel={{ forceToAxis: true, sensitivity: 2 }}
            className={rootClassName}
            freeMode={freeMode}
          >
            {children?.map((child) => (
              // @ts-ignore
              <SwiperSlide key={child?.key}>{child}</SwiperSlide>
            ))}
          </Swiper>
        ) : (
          children
        )}
      </div>
    </div>
  ) : null;
};

export default classify(defaultClasses)(CarouselMobile);
