import { useLocation } from "react-router-dom";

import urls from "web/constants/urls";

import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import Customer from "./Customer";

const NavigationPanelAdditional = () => {
  const { data: dataBanks } = useGetBanksQuery();
  const { total: totalPoints } = dataBanks || {};
  const { pathname } = useLocation();

  switch (true) {
    case typeof pathname !== "string": {
      return null;
    }
    case urls.customer === pathname: {
      return <Customer totalPoints={totalPoints} />;
    }
    default:
      return null;
  }
};

export default NavigationPanelAdditional;
