/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useEffect, useState } from "react";

import classes from "./checkbox.scss";
import type { IMultiselectCheckboxContainerProps } from "./container";
import Content from "./content";

type CheckboxProps = Omit<
  IMultiselectCheckboxContainerProps,
  "value" | "labelWithoutCount" | "filterTitle"
> & {
  onChange: (val: boolean) => void;
  value: boolean;
};

const Checkbox: FC<CheckboxProps> = ({
  name,
  info = null,
  label,
  count,
  onChange,
  value,
}) => {
  const textClassName = info ? classes.textWithInfo : classes.text;
  const [isChecked, setChecked] = useState(!!value);
  const onChangeHandler = () => {
    setChecked(!isChecked);
    onChange(!isChecked);
  };

  useEffect(() => {
    if (value !== isChecked) {
      setChecked(value);
    }
  }, [value, setChecked]);

  return (
    <label className={classes.root}>
      <input
        type="checkbox"
        className="visually-hidden"
        name={name}
        checked={isChecked}
        onChange={onChangeHandler}
        data-t1={`multiselect_checkbox_${name}`}
      />
      <span className={classes.check} />
      <span className={classes.label}>
        <span
          className={textClassName}
          data-t1={`multiselect_checkbox_label_${name}`}
        >
          <Content name={name} classes={classes} label={label} count={count} />
        </span>
        {info ? (
          <span
            className={classes.info}
            data-t1="multiselect_checkbox_label_info"
          >
            {info}
          </span>
        ) : null}
      </span>
    </label>
  );
};

export default Checkbox;
