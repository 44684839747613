import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import AbstractBrowserStorage from "./abstractBrowserStorage";
import NamespacedLocalStorage from "./namespacedStorage";
import redirectToBrowserSettingsError from "./redirectToBrowserSettingsError";

export default class BrowserPersistence extends AbstractBrowserStorage {
  static KEY = "FENIKS_BROWSER_PERSISTENCE";

  static TTL = +process.env.CACHE_TTL; // 30 * 60 * 1000;

  constructor() {
    super();
    try {
      this.storage = new NamespacedLocalStorage(
        window.localStorage,
        BrowserPersistence.KEY
      );
    } catch (error) {
      redirectToBrowserSettingsError();
    }
  }

  getItem(name, key) {
    try {
      const now = Date.now();
      const dataByName = this.storage.getItem(name);
      const dataByNameParsed = dataByName ? JSON.parse(dataByName) : null;

      if (dataByNameParsed) {
        if (key) {
          if (Object.prototype.hasOwnProperty.call(dataByNameParsed, key)) {
            if (now < dataByNameParsed[key].timeExpiration) {
              return dataByNameParsed[key].value;
            }
            this.removeItem(name, key);
          }
          return null;
        }

        if (now > dataByNameParsed.timeExpiration) {
          this.removeItem(name);
        } else {
          return dataByNameParsed.value;
        }
      }

      return null;
    } catch (error) {
      newRelicErrorReport(
        error,
        "web-app/web/utils/system/storage/storage/browserPersistence.js - 45"
      );
      console.error(error);
      redirectToBrowserSettingsError();
    }

    return null;
  }

  getItemUnprocessed(name) {
    return this.storage.getItem(name);
  }

  setItem(name, key, value, ttl = BrowserPersistence.TTL) {
    const timeExpiration = Date.now() + ttl;
    if (key) {
      try {
        const dataByName = this.storage.getItem(name);
        const dataByNameParsed = dataByName ? JSON.parse(dataByName) : {};
        this.storage.setItem(
          name,
          JSON.stringify({
            ...dataByNameParsed,
            [key]: {
              value,
              timeExpiration,
            },
          })
        );
      } catch (error) {
        newRelicErrorReport(
          error,
          "web-app/web/utils/system/storage/storage/browserPersistence.js - 76"
        );
        console.error(error);
      }
    } else {
      this.storage.setItem(
        name,
        JSON.stringify({
          value,
          timeExpiration,
        })
      );
    }
  }
}
