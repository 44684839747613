interface IGetSearchParameterArgs {
  name: string;
  search: string;
}

const getSearchParameter = ({ name, search }: IGetSearchParameterArgs) => {
  if (typeof name === "string" && typeof search === "string") {
    const urlParams = new URLSearchParams(search);

    if (urlParams.has(name)) {
      const paramByName = urlParams.get(name) as string;

      try {
        return decodeURIComponent(paramByName);
      } catch (error) {
        return paramByName;
      }
    }
  }

  return "";
};

export default getSearchParameter;
