import { FC } from "react";

import SearchAutocomplete from "web/Layout/SearchAutocomplete";

import classes from "./modalSearch.scss";

interface IModalSearchAction {
  closeAction: () => unknown;
}

const ModalSearch: FC<IModalSearchAction> = ({ closeAction }) => {
  return <SearchAutocomplete className={classes.root} onClick={closeAction} />;
};

export default ModalSearch;
