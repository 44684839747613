import __ from "web/Layout/Translations";

import useScroll from "web/hooks/useScroll";

import scrollTopAction from "web/utils/system/DOM/scroll/scrollToTop";

import { useAppContext } from "web/context/app";

import styles from "./scrollTop.scss";

const ScrollTop = () => {
  const isActive = useScroll();
  const { isMobile } = useAppContext();
  const title = __("Przewiń do góry");
  const mobileClass = isActive ? styles.mobileRootActive : styles.mobileRoot;
  const desktopClass = isActive ? styles.rootActive : styles.root;

  const rootClassName = isMobile ? mobileClass : desktopClass;

  return (
    <button
      type="button"
      className={rootClassName}
      onClick={scrollTopAction}
      title={title}
    >
      <span className="visually-hidden">{title}</span>
    </button>
  );
};

export default ScrollTop;
