// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-root-MfV{border:0;padding:0;overflow:hidden}.switch-root-MfV[disabled]{pointer-events:none}.switch-dot-UWz{display:block;border-radius:inherit}`, "",{"version":3,"sources":["webpack://./web/Components/Common/Switch/switch.scss"],"names":[],"mappings":"AAIuB,iBACrB,QAAA,CACA,SAAA,CACA,eAAA,CAEA,2BACE,mBAAA,CAIJ,gBACE,aAAA,CACA,qBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  border: 0;\n  padding: 0;\n  overflow: hidden;\n\n  &[disabled] {\n    pointer-events: none;\n  }\n}\n\n.dot {\n  display: block;\n  border-radius: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `switch-root-MfV`,
	"dot": `switch-dot-UWz`
};
export default ___CSS_LOADER_EXPORT___;
