import { FC, PropsWithChildren, useState } from "react";

import __ from "web/Layout/Translations";

import Button from "web/Components/Common/Button/button";

import RentableGroupDefaultForm from "../../RentableGroupDefaultForm";
import RentableGroupCalculatorForm, {
  RentableGroupFormVariant,
} from "../rentableGroupCalculatorForm";
import classes from "./rentableGroupWrapper.scss";

interface IRentableGroupWrapperProps {
  calculatorEnabled: boolean;
  impersonated: boolean;
}

const RentableGroupWrapper: FC<
  PropsWithChildren<IRentableGroupWrapperProps>
> = ({ children, calculatorEnabled, impersonated }) => {
  const [skipRGSelection, setSkipRGSelection] = useState(false);

  if (skipRGSelection) return <>{children}</>;

  return (
    <>
      {impersonated && (
        <div className={classes.buttonWrapper}>
          <Button onClick={() => setSkipRGSelection(true)}>
            {__("Pomiń warstwę GD")}
          </Button>
        </div>
      )}
      {calculatorEnabled ? (
        <RentableGroupCalculatorForm variant={RentableGroupFormVariant.POPUP}>
          {children}
        </RentableGroupCalculatorForm>
      ) : (
        <RentableGroupDefaultForm>{children}</RentableGroupDefaultForm>
      )}
    </>
  );
};

export default RentableGroupWrapper;
