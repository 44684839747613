import { arrayOf, shape, string } from "prop-types";
import { Link } from "react-router-dom";

import LinkTransition from "web/Layout/Common/LinkTransition";

import classes from "./dropdown.scss";

const Dropdown = ({ label, url, items }) => {
  return (
    <div className={classes.root}>
      <Link
        className={classes.link}
        to={url}
        data-t1="breadcrumbs_dropdown_link"
      >
        {label}
      </Link>
      <div className={classes.dropdown}>
        <ul className={classes.list} data-t1="breadcrumbs_list">
          {items?.map((item) => {
            return (
              <li
                className={classes.item}
                key={item.url}
                data-t2={item.url}
                data-t1="breadcrumbs_item_li"
              >
                <LinkTransition
                  url={item.url}
                  className={classes.subLink}
                  name={item.label}
                  dataT1="breadcrumbs"
                >
                  {item.label}
                </LinkTransition>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  label: string.isRequired,
  url: string.isRequired,
  items: arrayOf(shape({})).isRequired,
};

export default Dropdown;
