import { toZonedTime } from "date-fns-tz";

import convertStringToDate from "web/utils/data/parser/dateAndTime/convertStringToDate";

import { Nullable } from "web/types/Utils";

/**
 * Support function for Formik datepicker.
 * Changes UTC timestamp provided from API to local date used by flatpickr (also in form of timestamp).
 * @param { object } field - Form field, value could be timestamp, date or null
 * @param { boolean } convertToDate - flag hadling dates provided as strings
 * @returns { number | null }
 */
export default (
  field: { [key: string]: Nullable<number | string> },
  convertToDate = false
) => {
  if (!field || !Object.prototype.hasOwnProperty.call(field, "value"))
    return null;

  if (field.value) {
    const zonedDate = toZonedTime(new Date(field.value), "UTC");

    return convertToDate && typeof zonedDate === "string" && !+zonedDate
      ? convertStringToDate(zonedDate)?.getTime()
      : +zonedDate;
  }

  return null;
};
