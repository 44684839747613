import { FC } from "react";

import SubscriptionSummary from "web/Layout/SubscriptionGroupSummary/SubscriptionSummary";
import SubscriptionGroupSummary from "web/Layout/SubscriptionGroupSummary/subscriptionGroupSummary";
import SubscriptionReceivers from "web/Layout/SubscriptionReceivers";

import { getIdFromIri } from "web/utils/page/product/subscription/iri";

import restUrls from "web/constants/restUrls";

import {
  FormView,
  HandleToggleReceiversModalFn,
  ISubscriptionItemCurrentUserSubscription,
} from "web/types/Subscription";

interface IItemFormToFill {
  item: ISubscriptionItemCurrentUserSubscription;
  handleToggleEditModal: HandleToggleReceiversModalFn;
}

const ItemFormToFill: FC<IItemFormToFill> = ({
  item,
  handleToggleEditModal,
}) => {
  const {
    "@id": _id,
    benefit: {
      benefitGroup: {
        groupType = 1,
        groupPhoto: { contentUrl: groupPhotoURL = null } = {},
      },
      wayChooseOthersInActiveForm,
    },
    groupName,
    name,
    receivers,
    quantity,
  } = item;
  const benefitGroupType = groupType.toString();
  const subscriptionId =
    getIdFromIri(_id, restUrls.employeeSubscriptionItems) ?? "";

  return (
    <SubscriptionGroupSummary
      benefitGroupType={benefitGroupType}
      name={groupName}
      groupPhotoURL={groupPhotoURL}
    >
      <SubscriptionSummary name={name} quantity={quantity}>
        <SubscriptionReceivers
          receivers={receivers}
          subscriptionId={subscriptionId}
          wayChooseOthersInActiveForm={wayChooseOthersInActiveForm}
          viewType={FormView.MY_SUBSCRIPTIONS}
          handleToggleReceiversModal={handleToggleEditModal}
        />
      </SubscriptionSummary>
    </SubscriptionGroupSummary>
  );
};

export default ItemFormToFill;
