import { useCallback } from "react";

import sendGtmEvent from "web/utils/system/GTM/sendGtmEvent";

import { IBanner } from "web/types/Banners";
import { IPromoItems } from "web/types/Gtm";
import { useDispatch } from "react-redux";
import { setPromoLinks } from "web/features/gtm/gtm";

const usePromotionAnalytics = (bannerList: IBanner[], mobile: boolean) => {
  const dispatch = useDispatch();
  const promoData =
    bannerList?.map(
      ({
        graphics_title: title,
        graphics_alt: alt,
        img_mobile: imgMobile,
        img,
        position,
      }) => {
        return {
          name: title || alt || "",
          creative: mobile ? imgMobile : img,
          position: position || "",
          promotion_name: title || alt || "",
          creative_slot: position || "",
        };
      }
    ) || [];

  const promoViewData = {
    ecommerce: {
      promoView: {
        promotions: promoData,
      },
    },
  };

  const promoClickData = {
    ecommerce: {
      promoClick: {
        promotions: promoData,
      },
    },
  };

  const link = bannerList?.[0]?.url || "";

  const onPromoClick = useCallback(() => {
    if (link) {
      const promotionsPerProduct: IPromoItems = {};
      promotionsPerProduct[link] = {
        creative_slot: promoData[0].creative_slot,
        promotion_name: promoData[0].promotion_name,
      };
      dispatch(setPromoLinks(promotionsPerProduct));  
    }

    sendGtmEvent("promotionClick", promoClickData);
  }, [promoClickData]);

  return {
    promoViewData,
    onPromoClick,
  };
};

export default usePromotionAnalytics;
