import { setPromoLinks } from "web/features/gtm/gtm";
import { createGtmClickProps, modifyImage } from "web/Pages/PageGenerator/utils/gtmPromoEvent";
import { AppDispatch } from "web/store";
import { IPromoItems } from "web/types/Gtm";

const onGtmPromoClick = ({
  promoSrc,
  promoPosition,
  link,
  dispatch,
}: {
  promoSrc: string;
  promoPosition: number | string;
  link: string;
  dispatch: AppDispatch,
}) => {
  const name = modifyImage(promoSrc);
  const props = [
    {
      name,
      position: +promoPosition,
      promotion_name: name,
      creative_slot: +promoPosition,
    },
  ];

  if (link) {
    const linkValid = link?.charAt(0) !== "/" ? `/${link}` : link;
    const promotionsPerProduct: IPromoItems = {};
    promotionsPerProduct[linkValid] = { creative_slot: +promoPosition, promotion_name: name };
    dispatch(setPromoLinks(promotionsPerProduct));  
  }

  createGtmClickProps(props);
};

export default onGtmPromoClick;
