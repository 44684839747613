import { Form as FormFormik, Formik, useFormikContext } from "formik";
import { FC, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import __ from "web/Layout/Translations";

import GoogleLocalization from "web/Components/Common/GoogleLocalization";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import { IFiltersLocation } from "web/types/Geolocation";
import { Nullable, StringNumber } from "web/types/Utils";

import { useAppContext } from "web/context/app";

import { LocationOptions } from "../../Shared/Fieldset/fieldset";
import Distance from "./Distance";
import classes from "./geolocation.scss";

interface IGeolocationProps {
  option: LocationOptions;
}

export const names = {
  location: "location",
  distance: "distance",
};

const GeolocationForm: FC<IGeolocationProps> = ({ option }) => {
  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const { values, setValues } =
    useFormikContext<Record<string, Nullable<StringNumber>>>();
  const { isMobile } = useAppContext();

  const attributeCode = option.attribute_code;

  useEffect(() => {
    const valueLocation = values[names.location];
    const valueDistance = values[names.distance];
    const params = new URLSearchParams(search);
    const previousGeoParam = params.get(attributeCode);

    if (valueLocation && valueDistance) {
      const geolocationValues = {
        [names.location]: valueLocation,
        [names.distance]: valueDistance,
      };
      const geolocationValuesProcessed = JSON.stringify(geolocationValues);
      params.set(attributeCode, encodeURIComponent(geolocationValuesProcessed));
    } else {
      params.delete(attributeCode);
    }
    if (params.get(attributeCode) !== previousGeoParam)
      push(`${pathname}?${params.toString()}`);
  }, [JSON.stringify(values)]);

  useEffect(() => {
    const valueLocation = values[names.location];
    const valueDistance = values[names.distance];
    const params = new URLSearchParams(search);
    const isParamsExist = params.has(attributeCode);

    if (valueLocation && valueDistance && !isParamsExist) {
      const optionFirst = option?.attribute_options?.[0]?.value;

      setValues({
        [names.location]: null,
        [names.distance]: optionFirst,
      });
    }
  }, [search]);

  const locationValue = values && values[names.location];
  return (
    <FormFormik className={classes.root}>
      <GoogleLocalization
        name={names.location}
        placeholder={__("Wpisz miasto...")}
        initialValue={locationValue || ""}
        fromMobileFilters={isMobile}
      />
      <Distance name={names.distance} options={option.attribute_options} />
    </FormFormik>
  );
};

const Geolocation: FC<IGeolocationProps> = ({ option }) => {
  const { search } = useLocation();
  const initialValues = useMemo(() => {
    const geolocationParameter = getSearchParameter({
      name: option.attribute_code,
      search,
    });
    const geolocationParameterParsed = geolocationParameter
      ? jsonParse<IFiltersLocation>(geolocationParameter)
      : null;
    const { location, distance } = geolocationParameterParsed || {};
    const optionFirst =
      option &&
      option.attribute_options &&
      option.attribute_options[0] &&
      option.attribute_options[0].value;

    return {
      [names.location]:
        location && Object.keys(location).length ? location : null,
      [names.distance]: distance || optionFirst,
    };
  }, [JSON.stringify(option), search]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      data-t1="geolocation_wrapper"
    >
      <GeolocationForm option={option} />
    </Formik>
  );
};

export default Geolocation;
