import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import useGetProductOptions from "web/hooks/useGetProductOptions";

import getPriceFinalPriceDiscount from "web/utils/page/product/universal/getPriceFinalPriceDiscount";
import gtmGetLastPageType from "web/utils/system/GTM/gtmGetLastPageType";
import sendGtmEvent from "web/utils/system/GTM/sendGtmEvent";
import getAdditionalFilterParametersUrl from "web/utils/system/url/getAdditionalFilterParametersUrl";

import { IProduct } from "web/types/Product";

import { setCurrentProductId } from "web/features/app/appSlice";

import { usePageGeneratorContext } from "web/context/pageGenerator";
import { IProductOption } from "../useGetProductOptions/useGetProductOptions";
import useGetTourismProductOptions from "../useGetTourismProductOptions";

interface IProductClickObject {
  name: string;
  id: string | number;
  price: string;
  brand: string;
  category: string;
  variant: string;
  location_name?: string;
  dateFrom?: string;
  dateTo?: string;
  peopleAmount?: number;
  dimension16: string[];
  position?: number;
  index?: number;
  item_list_name?: string;
}

const useOnProductClikCallback = (
  product: IProduct,
  position = 1,
  searchPhrase = "",
  listingPosition = 1,
  listingAmount = 1,
  listingCategory = "",
) => {
  const dispatch = useDispatch();
  const { state: locationState, search } = useLocation<{ clickFrom: string }>();

  const { base_currency_code: baseCurrencyCode } = useSelector(
    (state) => state.app.storeConfig
  );
  const { promoItems } = useSelector((state) => state.gtm);
  const { pageName } = usePageGeneratorContext() || {};

  const productsOptionsData = [
    {
      productId: product?.id,
      productCategoryId: product?.main_category_id,
      productAttributesId: [{}],
      productCategoriesIds: product?.category_ids,
    },
  ];

  const [finalProductOptions, isReady] = useGetProductOptions(
    productsOptionsData as IProductOption[]
  );

  const additionalParameters = getAdditionalFilterParametersUrl(search);

  const { dateFrom, dateTo, peopleAmount } = useGetTourismProductOptions(
    additionalParameters as string
  );

  const { finalPrice } = getPriceFinalPriceDiscount({
    price: product?.price,
    finalPrice: product?.final_price,
    options: product?.product_options,
  });

  const listPath = gtmGetLastPageType({
    clickFrom: locationState?.clickFrom || "",
    listingPosition,
    listingAmount,
    listingCategory,
    searchPhrase,
    pageName,
  });

  const [productClick, isProductReady] = useMemo(() => {
    const productCategoryString =
      isReady && finalProductOptions[0]?.fullCategoryPath?.length
        ? finalProductOptions[0].fullCategoryPath
        : "";
    const productCategoriesPaths =
      finalProductOptions[0]?.fullCategoriesPaths || [];
    const { creative_slot, promotion_name } = promoItems?.[product?.id] || {};

    const productClickObject: IProductClickObject = {
      name: product?.name || "",
      id: product?.id || "",
      price: finalPrice?.toString() || "",
      brand: product?.supplier_name || "",
      category: productCategoryString,
      variant: "",
      ...(product?.tourism_city ? { location_name: product.tourism_city } : {}),
      ...(dateFrom ? { date_from: dateFrom } : {}),
      ...(dateTo ? { date_to: dateTo } : {}),
      ...(peopleAmount ? { amount_people: peopleAmount } : {}),
      dimension16: productCategoriesPaths,
      item_list_name: listPath?.list || "",
      ...(creative_slot && promotion_name ? { creative_slot, promotion_name } : {}),
    };

    const enhancedEcommProductClick = {
      ecommerce: {
        currencyCode: baseCurrencyCode,
        click: {
          actionField: listPath || "",
          products: [productClickObject],
        },
      },
    };

    if (position && position > 0) {
      productClickObject.position = position;
      productClickObject.index = position;
    }

    const isProductReady =
      isReady &&
      enhancedEcommProductClick?.ecommerce?.click?.products[0]?.category
        ?.length > 0;

    return [enhancedEcommProductClick, isProductReady];
  }, [
    isReady,
    finalProductOptions,
    finalPrice,
    product,
    position,
    baseCurrencyCode,
    listPath,
    promoItems,
  ]);

  const sendProductClickEvent = useCallback(() => {
    if (isProductReady) {
      sendGtmEvent("productClick", productClick, isProductReady);
      dispatch(setCurrentProductId(product.id));
    }
  }, [productClick, isProductReady]);

  return [sendProductClickEvent];
};

export default useOnProductClikCallback;
