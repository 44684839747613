import { FC } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import type { NavigationItem } from "../navigationPanel";
import PanelItem from "./panelItem";

interface IPanelItemContainerProps {
  item: NavigationItem;
}

const PanelItemContainer: FC<IPanelItemContainerProps> = ({ item }) => {
  const { pathname } = useLocation();

  const { pageType, isNavigationOpened } = useSelector((state) => state.app);

  return (
    <PanelItem
      item={item}
      pathname={pathname}
      pageType={pageType}
      isNavigationOpened={isNavigationOpened}
    />
  );
};

export default PanelItemContainer;
