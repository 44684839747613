import getWindowDateFromBenefitGroups from "web/utils/page/product/subscription/getWindowDateFromBenefitGroups";

import { BENEFIT_PRODUCT_TYPES } from "web/constants/benefits";

import { MBProductType } from "web/types/Product";

export const isSubscriptionDisabled = (
  productType: MBProductType,
  isWindowOpen: boolean,
  isUmsWindowOpen: boolean
) => {
  switch (productType) {
    case BENEFIT_PRODUCT_TYPES.GROUP_1:
      return !isWindowOpen;
    case BENEFIT_PRODUCT_TYPES.GROUP_2:
      return !isUmsWindowOpen;
    default:
      return false;
  }
};

export const getProductTypeWindowDate = (
  productType: MBProductType,
  openToDate: string,
  selectionWindowType: string,
  subscriptionThresholdDay: string,
  selectionWindowTypeUms: string,
  subscriptionThresholdDayUms: string
) => {
  const openToDateParsed =
    typeof openToDate === "string" ? openToDate.split(" ")[0] : null;

  switch (productType) {
    case BENEFIT_PRODUCT_TYPES.GROUP_1:
      return getWindowDateFromBenefitGroups(
        selectionWindowType,
        openToDateParsed,
        subscriptionThresholdDay
      );
    case BENEFIT_PRODUCT_TYPES.GROUP_2:
      return getWindowDateFromBenefitGroups(
        selectionWindowTypeUms,
        openToDateParsed,
        subscriptionThresholdDayUms
      );
    default:
      return null;
  }
};
