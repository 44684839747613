import { Button } from "@benefit-systems/common-components";
import { FC, MouseEventHandler } from "react";

import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import Download from "web/assets/icons/download.svg";
import PrinterIcon from "web/assets/icons/printer.svg";

import { useAppContext } from "web/context/app";

import classes from "./downloadCodeButton.scss";

interface IDownloadCodeButtonProps {
  hasCode: boolean;
  onPrintCodeDirectlyHandler: MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement
  >;
  canPrintCode: boolean;
  isPrinting: boolean;
  onPrintHandler: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
}

interface ICodeBtnProps {
  transparent: boolean;
  variant: "secondary";
  dataT1: "print_button";
}

const codeBtnDefaultProps: ICodeBtnProps = {
  transparent: true,
  variant: "secondary",
  dataT1: "print_button",
};

const DownloadCodeButton: FC<IDownloadCodeButtonProps> = ({
  hasCode,
  onPrintCodeDirectlyHandler,
  canPrintCode,
  isPrinting,
  onPrintHandler,
}) => {
  const { isMobile } = useAppContext();

  if (isPrinting) return <Loading />;

  if (isMobile) {
    if (!hasCode) return null;
    return (
      <Button
        onClick={canPrintCode ? onPrintHandler : onPrintCodeDirectlyHandler}
        {...codeBtnDefaultProps}
      >
        <>
          <Download className={classes.icon} />
          {__("Pobierz kod")}
        </>
      </Button>
    );
  }

  return (
    <Button
      onClick={canPrintCode ? onPrintHandler : window.print}
      {...codeBtnDefaultProps}
    >
      <>
        <PrinterIcon className={classes.icon} />
        {__("Drukuj kod")}
      </>
    </Button>
  );
};

export default DownloadCodeButton;
