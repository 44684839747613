// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerSearch-root-fZb{position:sticky;top:0;width:100%;z-index:100;display:flex;justify-content:space-between;align-items:center;background-color:#fff;border-bottom:1px solid #d3e6e6;padding:0 5px}.headerSearch-search-hsm{flex-grow:1;padding:10px 5px}.headerSearch-trigger-JBD{position:relative;display:block;padding:0 50px 0 20px;width:100%;background:#fff;border:1px solid #e8f5f5;border-radius:18px;height:41px;text-align:left;font-size:14px}.headerSearch-icon-SHJ{position:absolute;right:20px;width:20px;top:calc(50% - 10px)}`, "",{"version":3,"sources":["webpack://./web/Layout/HeaderSearch/headerSearch.scss"],"names":[],"mappings":"AAIuB,uBACrB,eAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,qBAAA,CACA,+BAAA,CACA,aAAA,CAGF,yBACE,WAAA,CACA,gBAAA,CAGF,0BACE,iBAAA,CACA,aAAA,CACA,qBAAA,CACA,UAAA,CACA,eAAA,CACA,wBAAA,CACA,kBAAA,CACA,WAAA,CACA,eAAA,CACA,cAAA,CAGF,uBACE,iBAAA,CACA,UAAA,CACA,UAAA,CACA,oBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  position: sticky;\n  top: 0;\n  width: 100%;\n  z-index: 100;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: white;\n  border-bottom: 1px solid $cyan-light-2;\n  padding: 0 5px;\n}\n\n.search {\n  flex-grow: 1;\n  padding: 10px 5px;\n}\n\n.trigger {\n  position: relative;\n  display: block;\n  padding: 0 50px 0 20px;\n  width: 100%;\n  background: white;\n  border: 1px solid $cyan-light-1;\n  border-radius: 18px;\n  height: 41px;\n  text-align: left;\n  font-size: 14px;\n}\n\n.icon {\n  position: absolute;\n  right: 20px;\n  width: 20px;\n  top: calc(50% - 10px);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `headerSearch-root-fZb`,
	"search": `headerSearch-search-hsm`,
	"trigger": `headerSearch-trigger-JBD`,
	"icon": `headerSearch-icon-SHJ`
};
export default ___CSS_LOADER_EXPORT___;
