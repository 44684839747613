import formatDate from "@feniks-pwa/web-app/web/utils/data/parser/dateAndTime/formatDate";
import {
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
} from "react";

import { readMoreTypeConditions } from "web/Pages/ContactForm/constants";

import Price from "web/Layout/Common/Price";
import Definition from "web/Layout/SubscriptionDefinition";
import ReadMore from "web/Layout/SubscriptionReadMore";
import __ from "web/Layout/Translations";

import ToolTip from "web/Components/Common/ToolTip";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import checkIfReadMoreShouldBeDisplayed from "web/utils/page/product/subscription/checkIfReadMoreShouldBeDisplayed";
import getBenefitAttachments from "web/utils/page/product/subscription/getBenefitAttachments";
import getBenefitLegends from "web/utils/page/product/subscription/getBenefitLegends";
import { getIdFromIri } from "web/utils/page/product/subscription/iri";

import {
  BENEFIT_EMPLOYER_PRICE_TYPE,
  zlotyPerMonth,
} from "web/constants/benefits";

import {
  IRI,
  ISubscriptionBenefit,
  ISubscriptionDraftItem,
  ISubscriptionItemCurrentUserSubscription,
  ISubscriptionTotals,
  SubscriptionMethod,
  SubscriptionStatus,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import { useAppContext } from "web/context/app";

import SubscriptionFoundingSource from "./SubscriptionFoundingSource";
import classes from "./subscriptionSummary.scss";

interface ISubscriptionSummaryProps {
  benefitGroupType: string;
  data:
    | ISubscriptionItemCurrentUserSubscription
    | ISubscriptionDraftItem<ISubscriptionBenefit>;
  employerPriceType: string;
  employerPriceAlternativeText: string;
  groupName: string;
  pointsBankId: Nullable<string>;
  totals: ISubscriptionTotals;
}

const definitionClass = {
  root: classes.definition,
};

const SubscriptionSummaryExtended: FC<
  PropsWithChildren<ISubscriptionSummaryProps>
> = ({
  benefitGroupType,
  children,
  data,
  employerPriceType,
  employerPriceAlternativeText = "",
  groupName,
  pointsBankId = null,
  totals,
}) => {
  const { modal } = useAppContext();
  const { dispatch: dispatchModal } = modal;

  const setModal = (content: ReactElement) => {
    dispatchModal({ type: ModalActionTypes.ADD_MODAL, modal: content });
  };

  const {
    benefit,
    name,
    description,
    endsAt,
    expectedEndsAt,
    method,
    quantity,
    status,
  } = data;
  const {
    "@id": benefitDraftUUID,
    relationBenefitTypeConfiguration,
    relationBenefitFreeTypeConfiguration,
    relationPeriodResignationBlockade,
  } = benefit;

  const uuid = getIdFromIri(benefitDraftUUID, IRI.BENEFIT);
  const attachments = getBenefitAttachments(benefit);
  const resultEndDate = expectedEndsAt || endsAt;
  const priceData = totals?.items?.find((item) => uuid === item.benefitId);
  const {
    employeePrice,
    employerPrice,
    pointsBankPrice,
    method: methodPrice,
  } = priceData || {};
  // Check if hidden employer cost is hidden on group type I
  const isEmployerPriceHidden =
    employerPriceType === BENEFIT_EMPLOYER_PRICE_TYPE.HIDDEN;
  // Depending on the view, method is taken from two various data structures, so need to be checked twice
  const isCoFinanced =
    method === SubscriptionMethod.CO_FINANCED ||
    methodPrice === SubscriptionMethod.CO_FINANCED;
  const isResigned = status === SubscriptionStatus.RESIGNED;

  const [benefitLegendsData, benefitLegendIcons] = useMemo(() => {
    // get shown benefit TYPE icons
    const benefitLegends = getBenefitLegends(
      relationBenefitTypeConfiguration,
      relationBenefitFreeTypeConfiguration || null,
      relationPeriodResignationBlockade || null
    );

    const benefitIcons = benefitLegends.map(
      ({ name: iconName, icon: Icon, message }) => {
        return (
          <Definition
            key={iconName}
            message={message}
            classes={definitionClass}
          >
            <Icon className={classes.detailsIcon} />
          </Definition>
        );
      }
    );
    return [benefitLegends, benefitIcons];
  }, []);

  const handleToggleModal = useCallback(() => {
    setModal(
      <ReadMore
        title={name}
        description={description}
        attachments={attachments}
        benefitLegends={benefitLegendsData}
        benefitDraftUUID={uuid}
        groupName={groupName}
        type={readMoreTypeConditions.readMore}
      />
    );
  }, []);

  const hasReadMore = checkIfReadMoreShouldBeDisplayed({
    visibleDescription: description,
    attachments,
  });

  return (
    <>
      <article className={classes.root}>
        <div className={classes.header}>
          <div className={classes.name}>
            <span>{name}</span>
            {hasReadMore || isArrayHasItems(benefitLegendIcons) ? (
              <div className={classes.other}>
                {hasReadMore ? (
                  <button
                    onClick={handleToggleModal}
                    className={classes.more}
                    type="button"
                  >
                    {__("czytaj więcej")}
                  </button>
                ) : null}
                {benefitLegendIcons}
              </div>
            ) : null}
          </div>
          <div className={classes.details}>
            <div
              className={classes.item}
              data-t1="numberOfPeople"
              data-t2={quantity}
            >{`${quantity} ${__("os.")}`}</div>
            <>
              {benefitGroupType === "2" ? (
                <>
                  <SubscriptionFoundingSource
                    classes={classes}
                    employeePrice={employeePrice}
                    employerPrice={employerPrice}
                    pointsBankPrice={pointsBankPrice}
                    pointsBankId={pointsBankId}
                    isCoFinanced={isCoFinanced}
                    isResigned={isResigned}
                    isEmployerPriceHidden={isEmployerPriceHidden}
                    alternativeText={employerPriceAlternativeText}
                  />
                  <div className={classes.item}>
                    {resultEndDate
                      ? formatDate(resultEndDate)
                      : __("Czas nieokreślony")}
                    {expectedEndsAt && (
                      <ToolTip
                        customStyles={{
                          root: classes.tooltipRoot,
                        }}
                        variant="right"
                        message={__(
                          "Data wskazuje na jaki okres zablokowane są punkty na opłacenie świadczenia"
                        )}
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.item}>
                    <Price
                      value={employerPrice}
                      currency={__(zlotyPerMonth)}
                      alternativePlaceholder={
                        employerPriceAlternativeText || ""
                      }
                      dataT1="employerCost"
                    />
                  </div>
                  <div className={classes.item}>
                    <Price
                      value={employeePrice}
                      currency={__(zlotyPerMonth)}
                      dataT1="employeeCost"
                    />
                  </div>
                </>
              )}
            </>
          </div>
        </div>
        {children}
      </article>
    </>
  );
};

export default SubscriptionSummaryExtended;
