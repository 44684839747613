const separateSign = ":";
const defaultPageSize = 10;
const sortName = "sort";
const sortDirectionName = "sortDir";

const orderIdName = "entity_id";
const pageName = "page";
const pageSize = "pageSize";
const defaultSplashPageTimeout = 8000;

const ordersTableHeader = [
  { title: "Data", dataT1: "orders_date_label" },
  { title: "Numer zamówienia", dataT1: "orders_nr_label" },
  {
    title: "Wartość zamówienia",
    dataT1: "orders_total_label",
    className: "blue",
  },
  { title: "Status", dataT1: "orders_status_label", className: "light" },
  {
    title: "Produkty w zamówieniu",
    dataT1: "orders_details_label",
    className: "ordersDetailsHeader",
  },
];

export {
  orderIdName,
  sortName,
  sortDirectionName,
  separateSign,
  pageName,
  pageSize,
  defaultPageSize,
  defaultSplashPageTimeout,
  ordersTableHeader,
};
