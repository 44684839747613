export interface IOptionsData {
  parent_product_data: {
    image: string;
  };
}

const getProductOrParentImg = (
  productImage: string,
  optionsData: IOptionsData
) => {
  if (
    (!productImage || productImage === "no_selection") &&
    optionsData?.parent_product_data?.image
  ) {
    return optionsData.parent_product_data.image;
  }

  if (!productImage) return null;

  return productImage;
};

export default getProductOrParentImg;
