import { FC } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Footer from "web/Layout/Footer";
import MainHeaderContainer from "web/Layout/MainHeader";
import Modal from "web/Layout/Modal";
import NavigationPanel from "web/Layout/NavigationPanel";
import Notifications from "web/Layout/Notifications";
import Overlay from "web/Layout/Overlay";
import ScrollTop from "web/Layout/ScrollTop";

import urls from "web/constants/urls";

import styles from "./main.scss";

const footerClass = { root: styles.footer };

interface IMainProps {
  children: JSX.Element;
}

const Main: FC<IMainProps> = ({ children }) => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const { pathname } = useLocation();

  const adjustingCustomerPageClass =
    pathname === urls.customer ? styles.largerWrapperMobilePadding : "";

  return (
    <>
      <Helmet>
        <title>{storeConfig?.default_title}</title>
        <meta name="description" content={storeConfig?.default_description} />
        <meta name="keywords" content={storeConfig?.default_keywords} />
      </Helmet>
      <MainHeaderContainer />
      <div
        className={`${styles.mainContentWrapper} ${adjustingCustomerPageClass}`}
      >
        <Notifications />
        <main className={styles.main}>{children}</main>
        <Footer classes={footerClass} />
        <Modal />
        <NavigationPanel />
      </div>
      <ScrollTop />
      <Overlay />
    </>
  );
};

export default Main;
