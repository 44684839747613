import { isNil } from "lodash";

import storageNames from "web/constants/storageNames";

import BrowserPersistence from "../storage/storage/browserPersistence";

const storage = new BrowserPersistence();

const isValidToken = (): boolean => {
  const token = storage.getItem(storageNames.token);
  return !isNil(token);
};

export default isValidToken;
