import jwt_decode from "jwt-decode";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import storageNames from "web/constants/storageNames";
import { getToken } from "web/constants/urls";

import type { IToken } from "web/types/Token";

import { request } from "web/api";

import clearStorageAndRedirectToLogin from "./clearStorageAndRedirectToLogin";

const storage = new BrowserPersistence();

export default async () => {
  const location = window?.location?.pathname;

  try {
    const response = (await request(getToken)) as IToken;
    const { success, token, accessToken, idToken } = response || {};
    if (success) {
      const { exp } = jwt_decode<{ exp: number }>(accessToken);
      const ttl = exp * 1000 - Date.now();

      storage.setItem(storageNames.token, null, token, ttl);
      storage.setItem(storageNames.tokenAccess, null, accessToken, ttl);
      storage.setItem(storageNames.idToken, null, idToken, ttl);

      return {
        token,
        accessToken,
        ttl,
        idToken,
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // removed new relic reporting for 401 in this request
    // as this is correct response if user is not logged in
    if (error?.response?.status !== 401) {
      newRelicErrorReport(
        error,
        "web-app/web/utils/system/essentials/getToken.js - 41"
      );
    }

    if (error?.response?.status === 401 && location !== "/sso/error") {
      clearStorageAndRedirectToLogin();
    }
    throw error;
  }
  return null;
};
