import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./productCategoriesWrapper.scss";

const ProductCategoriesWrapper: FC<PropsWithClasses> = ({ classes = {} }) => {
  return <Skeleton className={classes.categoriesWrapper} />;
};

export default classify<PropsWithClasses>(defaultClasses)(
  ProductCategoriesWrapper
);
