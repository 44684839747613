// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productsList-desktopList-QR4{display:flex;flex-wrap:wrap;margin:-25px -15px}.productsList-mobileGrid-QKD{display:flex;flex-wrap:wrap;margin:-25px -15px;flex-direction:column;padding-bottom:150px}.productsList-mobileList-_k0{display:flex;flex-wrap:wrap;flex-direction:column;justify-content:center;padding-bottom:150px}`, "",{"version":3,"sources":["webpack://./web/Layout/Common/SkeletonComponents/SkeletonProductsList/productsList.scss"],"names":[],"mappings":"AAIuB,8BACrB,YAAA,CACA,cAAA,CACA,kBAAA,CAGF,6BACE,YAAA,CACA,cAAA,CACA,kBAAA,CACA,qBAAA,CACA,oBAAA,CAGF,6BACE,YAAA,CACA,cAAA,CACA,qBAAA,CACA,sBAAA,CACA,oBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .desktopList {\n  display: flex;\n  flex-wrap: wrap;\n  margin: -25px -15px;\n}\n\n.mobileGrid {\n  display: flex;\n  flex-wrap: wrap;\n  margin: -25px -15px;\n  flex-direction: column;\n  padding-bottom: 150px;\n}\n\n.mobileList {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n  justify-content: center;\n  padding-bottom: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktopList": `productsList-desktopList-QR4`,
	"mobileGrid": `productsList-mobileGrid-QKD`,
	"mobileList": `productsList-mobileList-_k0`
};
export default ___CSS_LOADER_EXPORT___;
