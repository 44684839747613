import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import LinkTransition from "web/Layout/Common/LinkTransition";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getBenefitKeys from "web/utils/system/essentials/getBenefitKeys";

import {
  BENEFIT_PRODUCT_TYPES,
  BENEFIT_URLKEY_TYPES,
} from "web/constants/benefits";

import { ICategory } from "web/types/Category";
import { IFacetsItem } from "web/types/Facets";
import { ISubscriptionItemMs } from "web/types/Subscription";

import { useCategoryContext } from "web/context/category";

import { processCategoriesToShow } from "./list";
import classes from "./list.scss";

interface IListWithSubscriptions {
  categories: ICategory[];
  facet: IFacetsItem;
  subscriptionItems: ISubscriptionItemMs[];
}

const ListWithSubscriptions: FC<IListWithSubscriptions> = ({
  categories,
  facet,
  subscriptionItems,
}) => {
  const { category = {} } = useCategoryContext();
  const { id: currentCategoryId } = category as ICategory;

  const categoriesToShow = processCategoriesToShow(
    category as ICategory,
    categories,
    facet
  ) as (ICategory & { count: number })[];

  const dataFiltered = useSelector((state) => state.benefitGroups);

  const skuArray = getBenefitKeys(dataFiltered ?? null);
  const idsArray = isArrayHasItems(skuArray)
    ? subscriptionItems
        ?.filter((el) => {
          return !!skuArray.find((i) => i === el.sku);
        })
        .map((e) => +e.id)
    : [];

  const getSubscriptionCategoriesCount = useMemo(() => {
    const countGroup2 = idsArray.reduce((count, id) => {
      const item = subscriptionItems.find(
        (subItem) =>
          subItem.id === id.toString() &&
          subItem.subscription_group === BENEFIT_PRODUCT_TYPES.GROUP_2
      );
      return count + (item ? 1 : 0);
    }, 0);
    const countGroup1 = idsArray.reduce((count, id) => {
      const item = subscriptionItems.find(
        (subItem) =>
          subItem.id === id.toString() &&
          subItem.subscription_group === BENEFIT_PRODUCT_TYPES.GROUP_1
      );
      return count + (item ? 1 : 0);
    }, 0);

    return { countGroup1, countGroup2 };
  }, [categoriesToShow, subscriptionItems, idsArray]);

  return isArrayHasItems(categoriesToShow) ? (
    <ul className={classes.root}>
      {categoriesToShow.map(
        ({ id, name, request_path: requestPath, count, url_key: urlKey }) => {
          const isCurrentCategory = +currentCategoryId === +id;
          const { countGroup1, countGroup2 } = getSubscriptionCategoriesCount;
          let currentCount = count;

          /* Overwrite magento count with filtered count for subs */
          if (urlKey === BENEFIT_URLKEY_TYPES.GROUP_1) {
            currentCount = countGroup1;
          }
          if (urlKey === BENEFIT_URLKEY_TYPES.GROUP_2) {
            currentCount = countGroup2;
          }

          return currentCount > 0 ? (
            <li className={classes.item} key={id}>
              {isCurrentCategory ? (
                <span className={classes.linkCurrent}>
                  {name}{" "}
                  {currentCount > 0 && (
                    <span className={classes.count}>({currentCount})</span>
                  )}
                </span>
              ) : (
                <LinkTransition
                  className={classes.link}
                  url={`/${requestPath}`}
                  name={name}
                  dataT1="list"
                >
                  {name}{" "}
                  {currentCount > 0 && (
                    <span className={classes.count}> ({currentCount})</span>
                  )}
                </LinkTransition>
              )}
            </li>
          ) : null;
        }
      )}
    </ul>
  ) : null;
};

export default ListWithSubscriptions;
