import { useMemo } from "react";

import { defaultMonthCountToDivide } from "web/constants/rentableGroups";

import {
  useGetEmployeeConfigurationQuery,
  useGetEmployeeDetailsQuery,
} from "web/features/employee/employeeApiSlice";

import { IRentableGroupCalculatorValues } from "../rentableGroupCalculatorContainer";
import calculateRentableGroup from "./calculateRentableGroup";

const useCalculateRentableGroup = (values: IRentableGroupCalculatorValues) => {
  const { data: employeeDetails } = useGetEmployeeDetailsQuery();
  const { data: employeeConfig } = useGetEmployeeConfigurationQuery();

  const {
    rentableGroups,
    calculatorRentableGroupDefault,
    calculatorRentableGroupHighest,
  } = employeeDetails || {};

  const {
    rentableGroupCalculatorMonthToDivide:
      monthCountToDivide = defaultMonthCountToDivide,
  } = employeeConfig || {};

  return useMemo(
    () =>
      calculateRentableGroup({
        formValues: values,
        rentableGroups,
        calculatorRentableGroupDefault,
        calculatorRentableGroupHighest,
        monthCountToDivide,
      }),
    [values, rentableGroups]
  );
};

export default useCalculateRentableGroup;
