/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import { SkeletonCategoriesListing } from "web/Layout/Common/SkeletonComponents";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import {
  sortOptions as originalSortOptions,
  sortCategoryAnixeOptions,
  sortCategoryOptions,
} from "web/constants/toolbar";

import { ICategory } from "web/types/Category";
import { ISubscriptionItemMs } from "web/types/Subscription";

import { useCategoryContext } from "web/context/category";
import { setFiltersLoading } from "web/features/app/appSlice";
import { getFilteredBenefitGroups } from "web/features/subscriptions/benefitGroupsThunks";
import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";
import useDataCachedIdsFacetsStats from "web/features/useDataCached/useDataCachedIdsFacetsStats";

import Listing from "./listing";
import ListingSubscriptions from "./listingSubscriptions";

const getCatIds = (path: string, rootCategoryId: number) => {
  const stringRootCategories = `/${rootCategoryId}/`;
  const indexRootCategories = path.indexOf(stringRootCategories);
  const sliceRootCategories = path.slice(
    indexRootCategories + stringRootCategories.length,
    path.length
  );
  return sliceRootCategories.split("/").map((categoryId) => +categoryId);
};

interface IListingCategoryContainerProps {
  activeFilters: string[];
  sortName: string;
  sortDirection: string;
  additionalParameters: string;
}

const ListingCategoryContainer: FC<IListingCategoryContainerProps> = ({
  activeFilters,
  sortName,
  sortDirection,
  additionalParameters,
}) => {
  const dispatch = useDispatch();
  const { category = {} as ICategory } = useCategoryContext() as {
    category: ICategory;
  };
  const {
    tourism_anixe_caegory: tourismAnixeCategoryId,
    root_category_id: rootCategoryId,
  } = useSelector((state) => state.app.storeConfig);
  const { id, name, path } = category;

  const isAnixeTourismCategory = +tourismAnixeCategoryId === +id;
  const defaultSort = isAnixeTourismCategory
    ? {
        sortBy: originalSortOptions.finalPrice.name,
        sortOrder: originalSortOptions.finalPrice.direction[0].name,
      }
    : {};

  const options = useMemo(() => {
    return {
      variables: {
        categoryId: [+id],
        facet: true,
        ...(sortName && sortDirection
          ? { sortBy: sortName, sortOrder: sortDirection }
          : defaultSort),
        ...(isArrayHasItems(activeFilters)
          ? { attributes: activeFilters }
          : {}),
        ...(additionalParameters
          ? {
              additionalParameters,
            }
          : {}),
      },
      context: {
        clientName: "ms",
      },
      fetchPolicy: "cache-and-network",
    };
  }, [
    id,
    sortName,
    sortDirection,
    JSON.stringify(activeFilters),
    JSON.stringify(additionalParameters),
  ]);

  const categoryIds = getCatIds(path, rootCategoryId);
  const { loading: categoryLoading, data: categoryData } =
    useDataCachedCategories({
      ids: categoryIds,
    }) as { loading: boolean; data: ICategory[] };
  const categoriesPath = categoryData?.reduce((catNames, cat, idx) => {
    return !idx ? cat.name : `${catNames}/${cat.name}`;
  }, "");
  const { loading, error, data } = useDataCachedIdsFacetsStats({
    options: JSON.stringify(options),
  });

  useEffect(() => {
    if (typeof setFiltersLoading === "function") {
      dispatch(setFiltersLoading(loading));
    }
  }, [loading]);

  useEffect(() => {
    if (data?.subscription_items) {
      const subscriptionsSku =
        data?.subscription_items?.map((el: ISubscriptionItemMs) => el.sku) ||
        [];
      dispatch(getFilteredBenefitGroups({ skuArray: subscriptionsSku }));
    }
  }, [data]);
  switch (true) {
    case !!loading &&
      !!categoryLoading &&
      (!data || !Object.keys(data).length): {
      return <SkeletonCategoriesListing />;
    }
    case !!error: {
      console.error(error);
      return <ErrorComponentWithContainer />;
    }
    default: {
      const {
        items_ids: ids,
        facets,
        stats,
        subscription_items,
        prices,
      } = data || {
        facets: [],
        stats: [],
        ids: [],
        subscription_items: [],
        prices: null,
      };
      const sortOptions = isAnixeTourismCategory
        ? sortCategoryAnixeOptions.map((option) =>
            option === originalSortOptions.finalPrice
              ? { ...option, default: true }
              : option
          )
        : sortCategoryOptions;

      return !isArrayHasItems(subscription_items) ? (
        <>
          <Listing
            ids={ids!}
            stats={stats}
            facets={facets}
            prices={prices}
            name={name}
            activeFilters={activeFilters}
            count={ids?.length}
            // @ts-ignore
            sortOptions={sortOptions}
            additionalParameters={additionalParameters}
            isAnixeTourismCategory={isAnixeTourismCategory}
            categoriesPath={categoriesPath}
          />
        </>
      ) : (
        <>
          <ListingSubscriptions
            ids={ids!}
            stats={stats}
            facets={facets}
            name={name}
            count={ids?.length}
            // @ts-ignore
            sortOptions={sortOptions}
            additionalParameters={additionalParameters}
            categoriesPath={categoriesPath}
            subscriptionItems={subscription_items as ISubscriptionItemMs[]}
          />
        </>
      );
    }
  }
};

export default ListingCategoryContainer;
