/* eslint-disable jsx-a11y/mouse-events-have-key-events */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import __ from "web/Layout/Translations";

import CheckIcon from "web/assets/icons/check.svg";

import urls from "web/constants/urls";

import { resetAddedItemToCart } from "web/features/cart/cartSlice";

import classes from "./addToCartNotification.scss";

let setTimeoutId: NodeJS.Timeout;
const TIMEOUT = 1500;
const LEAVE_TIMEOUT = 150;
const AddToCartNotification = () => {
  const { isAddedItem } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAddedItem) {
      setTimeoutId = setTimeout(() => {
        dispatch(resetAddedItemToCart());
      }, TIMEOUT);
    }
  }, [isAddedItem]);
  const onMouseOverHandler = () => {
    clearTimeout(setTimeoutId);
  };
  const onMouseLeaveHandler = () => {
    setTimeoutId = setTimeout(() => {
      dispatch(resetAddedItemToCart());
    }, LEAVE_TIMEOUT);
  };
  const onClickHandler = useCallback(() => {
    dispatch(resetAddedItemToCart());
  }, [resetAddedItemToCart]);
  return isAddedItem ? (
    <div
      className={classes.root}
      onMouseOver={onMouseOverHandler}
      onMouseLeave={onMouseLeaveHandler}
      onClick={onClickHandler}
      data-t1="add_to_cart_notification"
      data-testid="add_to_cart_notification"
    >
      <div className={classes.shield}>
        <CheckIcon className={classes.icon} />
      </div>
      <div className={classes.label} data-t1="add_to_cart_notification_text">
        {__("Produkt dodano do koszyka")}
      </div>
      <Link
        className={classes.button}
        to={urls.checkoutDefaultCart}
        onClick={onClickHandler}
        data-t1="add_to_cart_notification_link"
      >
        <span data-t1="add_to_cart_notification_label">
          {__("Przejdź do koszyka")}
        </span>
      </Link>
    </div>
  ) : null;
};

export default AddToCartNotification;
