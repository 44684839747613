const dateConstants = {
  milliseconds: 1000,
  seconds: 60,
  minutes: 60,
  hours: 24,
  week: 7,
  month: 12,
};

const dayMilliseconds =
  dateConstants.milliseconds *
  dateConstants.seconds *
  dateConstants.minutes *
  dateConstants.hours;

const weekMilliseconds = dayMilliseconds * dateConstants.week;

const yearMilliseconds = dayMilliseconds * 365;

export default dateConstants;
export { dayMilliseconds, weekMilliseconds, yearMilliseconds };
