import { FC, useCallback } from "react";

import __ from "web/Layout/Translations";

import PointerIcon from "web/assets/icons/pointer.svg";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import canUseWebp from "web/utils/system/DOM/media/canUseWebp";

import { useAppContext } from "web/context/app";

import MapModal from "./MapModal";
import classes from "./map.scss";

interface IMapProps {
  variant?: "primary" | "secondary";
  prices?: Record<string, string>;
  ids: number[];
}

const webpStatus = canUseWebp();
const buttonClass = webpStatus ? classes.buttonWebp : classes.button;
const Map: FC<IMapProps> = ({ variant = "primary", ids, prices = null }) => {
  const { modal, isMobile } = useAppContext();
  const { dispatch } = modal;

  const rootClasses = isMobile ? classes.rootMobile : classes.root;

  const showModal = useCallback(() => {
    dispatch({
      type: ModalActionTypes.ADD_MODAL,
      modal: <MapModal ids={ids} prices={prices} />,
    });
  }, [dispatch, MapModal, ids, prices]);

  return (
    <div className={rootClasses}>
      {variant === "primary" ? (
        <>
          {!isMobile ? (
            <strong className={classes.title}>{__("Mapa")}</strong>
          ) : null}
          <button className={buttonClass} type="button" onClick={showModal}>
            <span>{__("Widok mapy")}</span>
          </button>
        </>
      ) : (
        <button
          className={classes.secondaryButton}
          type="button"
          onClick={showModal}
        >
          <PointerIcon />
          {__("Sprawdź na mapie")}
        </button>
      )}
    </div>
  );
};

export default Map;
