/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { isInteger } from "lodash";
import { FC, HTMLProps } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import ArrowLeftIcon from "web/assets/icons/arrow-left.svg";

import urls from "web/constants/urls";

import { Classes } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./backNavElement.scss";

export interface IBackNavElement {
  classes?: Classes;
  to?: string;
  replacementPath?: string;
  back?: boolean | number;
  backCb?: () => void;
  onClick?: () => void;
}

const BackNavElement: FC<IBackNavElement> = ({
  to = "",
  replacementPath = "",
  back = false,
  classes,
  backCb,
  onClick,
}) => {
  const { goBack, replace, push, go } = useHistory();
  const dispatch = useDispatch();

  const arrow = <ArrowLeftIcon className={classes!.arrow} />;

  const Btn = (props: HTMLProps<HTMLButtonElement>) => (
    <button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classes!.root}
      type="button"
      data-testid="button"
    >
      {arrow}
    </button>
  );

  const onReturnHandler = () => {
    if (isInteger(back) && back !== 0) {
      go(back < 0 ? (back as number) : -back);
      return;
    }
    goBack();
  };

  switch (true) {
    case !!to: {
      return (
        <Link className={classes!.root} to={to}>
          {arrow}
        </Link>
      );
    }
    // Don't know what exactly it is about, but I kept it cause such use cases exist in the project.
    case typeof backCb === "function": {
      return (
        <Btn
          onClick={() => {
            push(urls.home);
            dispatch(backCb);
          }}
        />
      );
    }
    case !!replacementPath: {
      return <Btn onClick={() => replace(replacementPath)} />;
    }
    case !!back: {
      return <Btn onClick={onReturnHandler} />;
    }
    case !!onClick: {
      return <Btn onClick={onClick} />;
    }
    default: {
      return null;
    }
  }
};

export default classify(defaultClasses)(BackNavElement);
