/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import __ from "web/Layout/Translations";

import ActiveFilters from "web/Components/Common/Filters/Desktop/ActiveFilters";
import Fieldset from "web/Components/Common/Filters/Shared/Fieldset";
import { FieldType } from "web/Components/Common/Filters/Shared/Fieldset/fieldset";

import EmptyProductList from "web/assets/icons/emptyProductList.svg";

import { geolocation, location } from "web/constants/filters";

import { useAppContext } from "web/context/app";

import classes from "./emptyListing.scss";

const geolocationOption = {
  ...location,
  ...geolocation,
  attribute_type: "text",
  input_type: "textarea",
};

const facetsHidden = ["mb_product_type_filter"];
const facetsHiddenMobile = ["mb_product_type_filter", "category_ids"];

const EmptyListing = () => {
  const [isLocationVisible, setIsLocationVisible] = useState(false);
  const { isMobile } = useAppContext();
  const { search } = useLocation();

  const facetsHiddenByViewport = isMobile ? facetsHiddenMobile : facetsHidden;

  useEffect(() => {
    if (typeof search === "string") {
      setIsLocationVisible(search.includes("geolocation_data="));
    }
  }, [search]);

  return (
    <div className={`${classes.wrapper} row`}>
      {/* filters */}
      {!isMobile && (
        <aside className="col col-3">
          <ActiveFilters facetsHidden={facetsHiddenByViewport} />
          {isLocationVisible && (
            <div className={classes.location}>
              {/* @ts-ignore */}
              <Fieldset
                title="Lokalizacja"
                type={FieldType.LOCATION}
                options={[geolocationOption]}
              />
            </div>
          )}
        </aside>
      )}

      {/* main */}
      <main className="col col-9">
        <p className={classes.main}>{__("Brak produktów")}</p>
        <EmptyProductList className={classes.icon} width={446} />
      </main>
    </div>
  );
};

export default EmptyListing;
