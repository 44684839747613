import { ErrorMessage } from "formik";
import { FC } from "react";

import classes from "./validationErrorMessage.scss";

interface IValidationErrorMessageProps {
  name: string;
}

const ValidationErrorMessage: FC<IValidationErrorMessageProps> = ({ name }) => {
  return (
    <div className={classes.error}>
      <ErrorMessage name={name} />
    </div>
  );
};

export default ValidationErrorMessage;
