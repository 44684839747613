import type { IApiRequestOpts, IRequestOpts } from "web/api/apiRequestTypes";

import deleteGraphqlExtraSpaces from "web/utils/system/query/deleteGraphqlExtraSpaces";

import { request } from "web/api";

const graphQlAsGet = (
  uri: string,
  options: IRequestOpts,
  fetchMethod: typeof fetch | typeof request = fetch
) => {
  const { uri: newUri, fetchOptions } = prepareGraphQlRequest(uri, options);
  return fetchMethod(newUri, fetchOptions as IRequestOpts);
};
export const prepareGraphQlRequest = (
  uri: string,
  options: IApiRequestOpts
) => {
  let { query, variables } = JSON.parse(options.body as string);
  query = deleteGraphqlExtraSpaces(query);
  variables = JSON.stringify(variables);
  const modifiedUri = encodeURI(
    `${uri}?query=${query}&variables=${variables}&_format=json`
  ).replace(/\+/, "%2B");

  const useGet = modifiedUri.length <= 2000;

  const fetchOptions = {
    ...options,
    method: useGet ? "GET" : "POST",
    useGETForQueries: useGet,
  };

  if (useGet) {
    delete fetchOptions.body;
    // eslint-disable-next-line no-param-reassign
    uri = modifiedUri;
  }

  return {
    uri,
    fetchOptions,
  };
};

export default graphQlAsGet;
