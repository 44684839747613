// hooks/useBanksData.ts
import { useMemo } from "react";

import convertObjArrayToMap from "web/utils/data/transform/convertObjArrayToMap";

import { IBankPartialDischargeItem } from "web/types/Banks";

import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import checkIfResetsDatesEqual from "./checkIfResetsDatesEqual";
import getBanksDischarged from "./getBanksDischarged";
import getBanskReset from "./getBanksReset";

type BankDischargedWithName = IBankPartialDischargeItem & { bankName: string };

const useBanksData = () => {
  const { data: dataBanks } = useGetBanksQuery();
  const { itemsReset, itemsActive, itemsDischarge } = dataBanks || {};

  return useMemo(() => {
    if (!itemsActive) {
      return {
        banksReset: [],
        banksDischarged: [] as BankDischargedWithName[],
        areDatesEqual: false,
      };
    }

    const activeBanksMap = convertObjArrayToMap(itemsActive, "points_bank_id");

    const banksReset = getBanskReset({ activeBanksMap, itemsReset });

    const areDatesEqual = checkIfResetsDatesEqual(banksReset);

    const banksDischarged = getBanksDischarged({
      activeBanksMap,
      itemsDischarge,
    });

    return { banksReset, banksDischarged, areDatesEqual };
  }, [itemsActive, itemsReset, itemsDischarge]);
};

export default useBanksData;
