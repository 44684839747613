// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-root-V1c{position:fixed;left:0;right:0;top:0;bottom:0;z-index:2;overflow-x:hidden;overflow-y:auto;background:#fff;padding-bottom:60px}`, "",{"version":3,"sources":["webpack://./web/Layout/NavigationPanel/Navigation/navigation.scss"],"names":[],"mappings":"AAIuB,qBACrB,cAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,QAAA,CACA,SAAA,CACA,iBAAA,CACA,eAAA,CACA,eAAA,CACA,mBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  position: fixed;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 2;\n  overflow-x: hidden;\n  overflow-y: auto;\n  background: white;\n  padding-bottom: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `navigation-root-V1c`
};
export default ___CSS_LOADER_EXPORT___;
