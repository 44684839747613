import { Field, useFormikContext } from "formik";
import { ChangeEvent, FC, useCallback } from "react";

import __ from "web/Layout/Translations";

import CloseIcon from "web/assets/icons/close.svg";
import Magnifier from "web/assets/icons/magnifier.svg";

import removeNonUTF8Chars from "web/utils/removeNonUTF8Chars";

import classes from "./searchField.scss";

interface ISearchAutocompleteSearchFieldProps {
  name: string;
  currentValue: string;
}

const SearchAutocompleteSearchField: FC<
  ISearchAutocompleteSearchFieldProps
> = ({ name, currentValue }) => {
  const { setValues } = useFormikContext();
  const clearHandler = useCallback(() => {
    setValues({
      [name]: "",
    });
  }, [setValues]);
  const clearClassName = currentValue ? classes.clear : classes.clearHidden;
  return (
    <div className={classes.root}>
      <button
        className={classes.submit}
        type="submit"
        data-t1="search_submit"
        aria-label="Search"
      >
        <Magnifier width={26} />
      </button>
      <Field
        className={classes.input}
        type="text"
        name={name}
        placeholder={__("Czego szukasz?")}
        autoComplete="off"
        data-t1="search_input"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValues({
            [name]: removeNonUTF8Chars(e.target.value),
          })
        }
      />
      <button
        className={clearClassName}
        type="button"
        onClick={clearHandler}
        data-t1="search_clear"
        aria-label="Clear search input"
        data-testid="search_clear"
      >
        <CloseIcon width={18} />
      </button>
    </div>
  );
};

export default SearchAutocompleteSearchField;
