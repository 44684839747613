import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IBankItem } from "web/types/Banks";
import { IStoreConfig } from "web/types/StoreConfig";

import {
  geolocation as geolocationFilter,
  location,
} from "../../../constants/filters";
import { Filter } from "./mergeFacetsStatsAttributes";

interface IFiltersProcessed {
  filtersWithLocation: FiltersWithLocation[];
  filtersWithoutLocation: unknown[];
}

interface IFiltersWithLocation {
  geolocation: unknown;
  locationRest: unknown[];
}

type FiltersWithLocation = IFiltersWithLocation | Filter;

const processFilters = (
  filtersMerged: Filter[],
  storeConfig: IStoreConfig,
  banks?: IBankItem[]
): Filter[] => {
  const { filtersWithLocation, filtersWithoutLocation } =
    filtersMerged.reduce<IFiltersProcessed>(
      (result, filter) => {
        if (filter && typeof filter.attribute_code === "string") {
          if (filter.attribute_code.includes("location")) {
            return {
              ...result,
              filtersWithLocation: [...result.filtersWithLocation, filter],
            };
          }

          if (filter.attribute_code === "payment_settings") {
            const optionsFiltered = isArrayHasItems(filter.attribute_options)
              ? filter.attribute_options.filter((option) => {
                  const currentBank = isArrayHasItems(banks)
                    ? banks.find((bank) => bank.points_bank_id === option.value)
                    : null;

                  return currentBank && +currentBank.balance > 0;
                })
              : [];

            return {
              ...result,
              filtersWithoutLocation: [
                ...result.filtersWithoutLocation,
                {
                  ...filter,
                  attribute_options: optionsFiltered,
                },
              ],
            };
          }
        }

        return {
          ...result,
          filtersWithoutLocation: [...result.filtersWithoutLocation, filter],
        };
      },
      {
        filtersWithLocation: [],
        filtersWithoutLocation: [],
      }
    );

  const { geolocation, locationRest } = isArrayHasItems(filtersWithLocation)
    ? filtersWithLocation.reduce<IFiltersWithLocation>(
        (locationResult, locationItem) => {
          if (
            isFilters(locationItem) &&
            locationItem.attribute_code.indexOf("geolocation") !== -1 &&
            storeConfig &&
            storeConfig.google_api_enable &&
            storeConfig.google_api_key
          ) {
            return {
              geolocation: locationItem,
              locationRest: locationResult.locationRest,
            };
          }
          return {
            geolocation: locationResult.geolocation,
            locationRest: [...locationResult.locationRest, locationItem],
          };
        },
        { geolocation: null, locationRest: [] }
      )
    : { geolocation: null, locationRest: [] };

  return (
    geolocation || isArrayHasItems(locationRest)
      ? [
          {
            ...location,
            attribute_options: geolocation
              ? [
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...geolocation,
                    attribute_options: geolocationFilter.attribute_options,
                  },
                  ...locationRest,
                ]
              : locationRest,
          },
          ...filtersWithoutLocation,
        ]
      : filtersWithoutLocation
  ) as Filter[];
};

const isFilters = (item: FiltersWithLocation): item is Filter =>
  item.hasOwnProperty("attribute_code");

export default processFilters;
