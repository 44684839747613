import { RelationBenefitAutomaticTypeConfiguration } from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

const filterOutHiddenBenefits = <
  T extends {
    benefit: {
      relationBenefitAutomaticTypeConfiguration?: Nullable<
        RelationBenefitAutomaticTypeConfiguration[]
      >;
    };
  }
>(
  benefits: T[] = [],
  option: RelationBenefitAutomaticTypeConfiguration
) =>
  benefits.filter(
    (benefit) =>
      !benefit.benefit.relationBenefitAutomaticTypeConfiguration?.includes(
        option
      )
  );

export default filterOutHiddenBenefits;
