/* eslint-disable no-unused-vars */
import { FC, memo, useEffect, useState } from "react";

import placeholder from "web/assets/images/placeholder.jpg";

import { PropsWithClasses } from "web/types/Common";
import { Nullable } from "web/types/Utils";

import classify from "web/classify";

import defaultClasses from "./image.scss";

interface IImageProps {
  src: Nullable<string>;
  alt?: Nullable<string>;
  height?: Nullable<number>;
  dataT1?: string;
}

const Image: FC<PropsWithClasses<IImageProps>> = memo(
  ({ src = null, alt = null, classes = {}, height = null, dataT1 = "" }) => {
    const [isError, setError] = useState(false);
    const onError = () => {
      setError(true);
    };

    useEffect(() => {
      setError(false);
    }, [src]);

    return (
      <img
        className={classes.root}
        data-t1={dataT1}
        src={isError ? placeholder : src}
        alt={alt || ""}
        onError={onError}
        style={isError ? { maxHeight: `${height}px`, objectFit: "cover" } : {}}
      />
    );
  }
);

export default classify<PropsWithClasses<IImageProps>>(defaultClasses)(Image);
