import LogoSignIcon from "web/assets/icons/logo-sign.svg";

import classes from "./loader.scss";

function Loader() {
  return (
    <div className={classes.loader}>
      <LogoSignIcon />
    </div>
  );
}

export default Loader;
