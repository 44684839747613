import { FC } from "react";

import __ from "web/Layout/Translations";

import { Classes } from "web/types/Common";
import { SubscriptionsDatesInfoVariants } from "web/types/Subscription";

import classify from "web/classify";

import defaultClasses from "./info.scss";

interface ISubscriptionsDatesInfoProps {
  classes?: Classes;
  finalDates: { fullDateFromDate: string; fullDateToDate: string };
  highlightColor?: string;
  variant?: SubscriptionsDatesInfoVariants;
  displayDisclaimer?: boolean;
}

const SubscriptionsDatesInfo: FC<ISubscriptionsDatesInfoProps> = ({
  finalDates,
  classes,
  highlightColor = "#00A82A",
  variant = SubscriptionsDatesInfoVariants.DEFAULT,
  displayDisclaimer = false,
}) => {
  const classesByVariant = {
    default: classes?.infoData,
    large: classes?.infoDataLarge,
    product: classes?.infoDataProduct,
  };

  return (
    <strong className={classesByVariant[variant]}>
      {displayDisclaimer ? (
        <>
          <span style={{ color: highlightColor }}>
            {__("Sprawdź do kiedy")}
          </span>{" "}
          {__("możesz wybrać abonamenty.")}
        </>
      ) : (
        <>
          {__("Wyboru abonamentów dokonaj")}{" "}
          <span style={{ color: highlightColor }}>
            {__("do")}&nbsp;{finalDates.fullDateToDate},
          </span>{" "}
          {__("aby zostać nimi objętym")}{" "}
          <span style={{ color: highlightColor }}>
            {__("od")}&nbsp;{finalDates.fullDateFromDate}.
          </span>
        </>
      )}
    </strong>
  );
};

export default classify<ISubscriptionsDatesInfoProps>(defaultClasses)(
  SubscriptionsDatesInfo
);
