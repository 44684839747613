import React, { FC, ReactNode, useMemo } from "react";

import Badge from "web/Layout/Common/Badge";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./title.scss";

interface ITitleProps {
  name: string | ReactNode;
  count?: number;
  isFlex?: boolean;
  container?: string;
  dataT1?: string;
  dataT2?: string;
  isCentered?: boolean;
}

const Title: FC<PropsWithClasses<ITitleProps>> = ({
  name,
  count,
  classes = {},
  isFlex = false,
  container = "h1",
  dataT1 = "listing_title",
  dataT2 = "",
  isCentered = false,
}) => {
  const titleContent = (
    <>
      {name}{" "}
      {!!+count! && (
        <Badge
          amount={count}
          className={classes.badge}
          dataT1={`${dataT1}_badge`}
          withoutSpacer
          round
        />
      )}
    </>
  );

  const titleElement = useMemo(
    () =>
      React.createElement(
        container,
        {
          className: `${classes.header} ${isFlex ? defaultClasses.flex : ""} ${
            isCentered ? defaultClasses.centeredContent : ""
          }`.trim(),
          "data-t1": dataT1,
          "data-t2": dataT2 || name,
        },
        titleContent
      ),
    [container, name, titleContent]
  );
  return (
    <div className={classes.root} data-t1={dataT1}>
      {titleElement}
    </div>
  );
};

export default classify<PropsWithClasses<ITitleProps>>(defaultClasses)(Title);
