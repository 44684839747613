import { arrayOf, number, oneOf, oneOfType, shape, string } from "prop-types";
import { useHistory, useLocation } from "react-router-dom";

import LinkTransition from "web/Layout/Common/LinkTransition";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import classes from "./list.scss";

const List = ({ categories, idCurrent, code }) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  return isArrayHasItems(categories) ? (
    <ul className={classes.root}>
      {categories.map(({ id, name, count }) => {
        const isCurrentCategory = +idCurrent === +id;
        const onClickHandler = () => {
          const params = new URLSearchParams(search);
          params.set(code, id);
          push(`${pathname}?${params.toString()}`);
        };
        return (
          <li className={classes.item} key={id}>
            {isCurrentCategory ? (
              <span className={classes.linkCurrent}>
                {name} <span className={classes.count}>({count})</span>
              </span>
            ) : (
              <LinkTransition
                className={classes.link}
                name={name}
                onClick={onClickHandler}
                dataT1="list"
              >
                {name} <span className={classes.count}>({count})</span>
              </LinkTransition>
            )}
          </li>
        );
      })}
    </ul>
  ) : null;
};

List.propTypes = {
  categories: arrayOf(shape({})).isRequired,
  code: string.isRequired,
  idCurrent: oneOfType([string, oneOf([null]), number]),
};

List.defaultProps = {
  idCurrent: null,
};

export default List;
