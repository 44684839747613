export default {
  token: "token",
  tokenAccess: "tokenAccess",
  idToken: "idToken",
  urlResolver: "urlResolver",
  products: "products",
  wishlist: "wishlist",
  categories: "categories",
  campaignGraphics: "campaignGraphics",
  cartId: "cartId",
  manuallySeparatedSources: "manuallySeparatedSources",
  storeConfig: "storeConfig",
  tourismInternational: "tourismInternational",
  tourismDomestic: "tourismDomestic",
  subscription: "subscription",
  attributes: "attributes",
  language: "language",
  ssoLink: "sso_link",
  categoryCounts: "categoryCounts",
  idsFacetsStats: "productsIdsFacetsStats",
  idsFacetsStatsSub: "productsIdsFacetsStatsSub",
  search: "productsSearch",
  searchSub: "productsSearchSub",
  productsDomesticTourism: "productsDomesticTourism",
  isNativeAppLogin: "isNativeAppLogin",
  productsTypes: "productsTypes",
  blocks: "blocks",
  subscriptionSelectionHistory: "subscriptionSelectionHistory",
  skippedMessages: "skippedMessages",
  modulesHomepage: "modulesHomepage",
  agreements: "agreements",
};
