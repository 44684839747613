import { FC } from "react";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";

import Anchor from "web/Components/Common/Anchor";

import { IBanner } from "web/types/Banners";
import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import styles from "./banner.scss";
import usePromotionAnalytics from "./usePromotionAnalytics";

interface IBannerProps {
  banner: IBanner;
  mobile?: boolean;
}

const Banner: FC<PropsWithClasses<IBannerProps>> = ({
  banner,
  classes = {},
  mobile = false,
}) => {
  const { onPromoClick } = usePromotionAnalytics(
    banner ? [banner] : [],
    mobile
  );

  return banner?.img ? (
    <Anchor
      to={banner.url}
      title={banner.title}
      className={classes.banner}
      onClick={onPromoClick}
    >
      <LazyLoadImage
        alt={banner.graphics_alt}
        width={banner.width}
        height={banner.height}
        src={mobile ? banner.img_mobile : banner.img}
        className={classes.banner__image}
        isCampaign
        isAutoresized={false}
      />
    </Anchor>
  ) : null;
};

export default classify<PropsWithClasses<IBannerProps>>(styles)(Banner);
