import { FC } from "react";

import __ from "web/Layout/Translations";

import DateRange from "./DateRange";
import Location from "./Location";
import Multiselect from "./Multiselect";
import Price from "./Price";
import Special from "./Special";
import Text from "./Text";

export const enum FieldType {
  SELECT = "select",
  MULTISELECT = "multiselect",
  PRICE = "price",
  DATE = "date",
  SPECIAL = "special",
  TEXT = "text",
  LOCATION = "location",
}

export type CommonOptions = {
  label: string;
  info: unknown;
  value: string;
  count: number;
};

export type PriceOptions = {
  min: number;
  max: number;
};

export type LocationOptions = {
  attribute_options: {
    value: string;
    label: string;
  }[];
  attribute_code: string;
};

export type TextOptions = {
  value: string;
};

interface IFieldBase {
  title: string;
  code: string;
}

interface ICommonField extends IFieldBase {
  type: FieldType.MULTISELECT | FieldType.SELECT | FieldType.SPECIAL;
  options: CommonOptions[];
}

interface IPriceField extends IFieldBase {
  type: FieldType.PRICE;
  options: PriceOptions;
}

interface IDateField extends IFieldBase {
  type: FieldType.DATE;
  options: undefined;
}

interface ILocationField extends IFieldBase {
  type: FieldType.LOCATION;
  options: LocationOptions[];
}

interface ITextField extends IFieldBase {
  type: FieldType.TEXT;
  options: TextOptions[];
}

export type FieldsetType =
  | ICommonField
  | IPriceField
  | IDateField
  | ILocationField
  | ITextField;

const FiltersFieldset: FC<FieldsetType> = ({ type, options, title, code }) => {
  switch (type) {
    case FieldType.SELECT:
    case FieldType.MULTISELECT: {
      return (
        <Multiselect
          data-t1="fieldset_multiselect"
          title={title}
          code={code}
          options={options}
        />
      );
    }
    case FieldType.PRICE: {
      return (
        <Price
          data-t1="fieldset_price"
          title={title}
          code={code}
          options={options}
        />
      );
    }
    case FieldType.DATE: {
      return (
        <DateRange data-t1="fieldset_date_range" title={title} code={code} />
      );
    }
    case FieldType.SPECIAL: {
      return (
        <Special
          data-t1="fieldset_special"
          title={title}
          code={code}
          options={options}
        />
      );
    }
    case FieldType.TEXT: {
      return <Text data-t1="fieldset_text" code={code} options={options} />;
    }
    case FieldType.LOCATION: {
      return (
        <Location
          data-t1="fieldset_location"
          title={__(title)}
          options={options}
        />
      );
    }
    default:
      return null;
  }
};

export default FiltersFieldset;
