import { fromZonedTime } from "date-fns-tz";

const getBirthDateFromPesel = (pesel: string) => {
  if (pesel.length < 6) {
    return null;
  }

  let year = parseInt(pesel.substring(0, 2), 10);
  let month = parseInt(pesel.substring(2, 4), 10) - 1;
  const day = parseInt(pesel.substring(4, 6), 10);

  if (month > 80) {
    year += 1800;
    month -= 80;
  } else if (month >= 60) {
    year += 2200;
    month -= 60;
  } else if (month >= 40) {
    year += 2100;
    month -= 40;
  } else if (month >= 20) {
    year += 2000;
    month -= 20;
  } else {
    year += 1900;
  }

  if (
    typeof year !== "number" ||
    typeof month !== "number" ||
    typeof day !== "number"
  ) {
    return null;
  }

  const localDate = new Date(year, month, day);
  const dateUTC = fromZonedTime(localDate, "UTC");

  return dateUTC;
};

export default getBirthDateFromPesel;
