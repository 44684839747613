import { isNil } from "lodash";

import getLastItem from "web/utils/data/parser/array/getLastItem";
import canUseWebp from "web/utils/system/DOM/media/canUseWebp";
import getSrcMedia from "web/utils/system/url/getSrcMedia";

import type { IStoreConfig } from "web/types/StoreConfig";
import type { Nullable } from "web/types/Utils";

export interface IGetAutoResizeSrcArgs {
  storeConfig: IStoreConfig;
  src: string;
  width: Nullable<number | string>;
  height: Nullable<number | string>;
  isProduct: boolean;
  isCategory: boolean;
  isCampaign: boolean;
}

enum ValidImageExtensions {
  PNG = "png",
  JPEG = "jpeg",
  WEBP = "webp",
}

const checkIfImageTypeIsValid = (type: string) =>
  (Object.values(ValidImageExtensions) as string[]).includes(type);

const webpStatus = canUseWebp();
const getAutoresizeSrc = ({
  storeConfig,
  src,
  width,
  height,
  isProduct,
  isCategory,
  isCampaign,
}: IGetAutoResizeSrcArgs) => {
  let mediaUrl = process.env.MAGENTO_CDN_MEDIA_DOMAIN;

  if (!mediaUrl) {
    return getSrcMedia({ storeConfig, src, isProduct, isCategory, isCampaign });
  }

  mediaUrl += mediaUrl[mediaUrl.length - 1] === "/" ? "" : "/";
  const srcTrim = typeof src === "string" ? src.trim() : "";
  const srcTrimProcessed = !srcTrim.indexOf("/") ? srcTrim.slice(1) : srcTrim;
  const imagePath = getLastItem(srcTrimProcessed.split("/"));
  const imagePathWithoutType = imagePath.split(".")[0];
  const srcSplittedByDots = imagePath.split(".");
  const imageTypeFromSrc = srcSplittedByDots[srcSplittedByDots.length - 1];
  const safeImageType =
    !imageTypeFromSrc || !checkIfImageTypeIsValid(imageTypeFromSrc)
      ? ValidImageExtensions.JPEG
      : imageTypeFromSrc;
  const imageType = webpStatus ? ValidImageExtensions.WEBP : safeImageType;
  let fullSrc = srcTrimProcessed;
  if (
    typeof src !== "string" ||
    !src.length ||
    typeof mediaUrl !== "string" ||
    src.includes("http")
  ) {
    fullSrc = src;
  }
  if (isProduct) {
    fullSrc = `catalog/product/${srcTrimProcessed}`;
  }
  if (isCategory) {
    fullSrc = `catalog/category/${srcTrimProcessed}`;
  }
  if (isCampaign) {
    fullSrc = `marketing/campaign/${srcTrimProcessed}`;
  }

  const mediaUrlData = {
    bucket: "media",
    key: fullSrc,
    edits: {
      [imageType]: { quality: 80 },
      resize: {
        fit: "cover",
        background: { r: 255, g: 255, b: 255, alpha: 0 },
        width: (!isNil(width) && +width) || 781,
        height: (!isNil(height) && +height) || 521,
      },
    },
  };
  const encodedData = Buffer.from(JSON.stringify(mediaUrlData)).toString(
    "base64"
  );

  return `${mediaUrl}${encodedData}/${imagePathWithoutType}.${imageType}`;
};

export default getAutoresizeSrc;
