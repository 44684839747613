import { FC } from "react";

import __ from "web/Layout/Translations";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./subscriptionDefinition.scss";

interface ISubscriptionLegendDefinitionProps {
  message: string;
  children: any;
}

const SubscriptionLegendDefinition: FC<
  PropsWithClasses<ISubscriptionLegendDefinitionProps>
> = ({ classes = {}, message, children }) => {
  return (
    <div className={classes.root}>
      {children}
      <div className={classes.tooltip}>{__(message)}</div>
    </div>
  );
};

export default classify<PropsWithClasses<ISubscriptionLegendDefinitionProps>>(
  defaultClasses
)(SubscriptionLegendDefinition);
