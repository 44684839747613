import { FC } from "react";
import { Link } from "react-router-dom";

import CodeAttributeList from "web/Components/Common/ConfigurableDetailsList";
import ProductExpires from "web/Components/Common/ProductExpires";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import almostExpiredPeriod from "web/constants/codeUniversal";

import { ICodeDetails, ICodeOptions } from "web/types/Code";

import { useAppContext } from "web/context/app";

import classes from "./content.scss";

interface IContentProps {
  className?: string;
  link: string;
  name: string;
  expirationDate: string;
  options?: { attribute?: { wartosc?: string } }[];
  dataT1?: string;
  codeDetails?: ICodeDetails | Record<string, unknown>;
  producSuperOptions?: ICodeOptions | Record<string, unknown>;
}

const Content: FC<IContentProps> = ({
  className = "",
  link,
  name,
  expirationDate,
  options = [],
  dataT1 = "code_item_content",
  codeDetails = {},
  producSuperOptions = {},
}) => {
  const { isMobile } = useAppContext();

  return (
    <div className={className || classes.root}>
      {isMobile ? null : (
        <h2 className={classes.title} data-t1={`${dataT1}_title`}>
          <Link
            to={link}
            title={name}
            data-t1={`${dataT1}_link`}
            data-t2={name}
          >
            {name}
          </Link>
        </h2>
      )}
      <div className={classes.content}>
        <ProductExpires
          date={expirationDate}
          almostExpiredPeriod={almostExpiredPeriod}
          dataT1={`${dataT1}_product_expires`}
        />
        {isArrayHasItems(options) ? (
          <CodeAttributeList
            codeDetails={codeDetails}
            items={options}
            codesVariant
            producSuperOptions={producSuperOptions}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Content;
