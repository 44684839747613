import { FC } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import Breadcrumbs, { IBreadcrumbsProps } from "./breadcrumbs";

const Container: FC<IBreadcrumbsProps> = (props) => {
  return (
    <ErrorBoundary>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Breadcrumbs {...props} />
    </ErrorBoundary>
  );
};

export default Container;
