// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscriptionReceivers-root-uPY{padding:2.5rem 2rem}.subscriptionReceivers-heading-Zu9{margin:0}.subscriptionReceivers-receivers-qzU{margin-top:1rem}.subscriptionReceivers-receivers__items-GIl{display:grid;grid-template-columns:minmax(0, 1fr) minmax(0, 1fr);-moz-column-gap:1.5rem;column-gap:1.5rem;align-items:flex-start}`, "",{"version":3,"sources":["webpack://./web/Layout/SubscriptionReceivers/subscriptionReceivers.scss"],"names":[],"mappings":"AAIuB,gCACrB,mBAAA,CAGF,mCACE,QAAA,CAGF,qCACE,eAAA,CAEA,4CACE,YAAA,CACA,mDAAA,CACA,sBAAA,CAAA,iBAAA,CACA,sBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  padding: 2.5rem 2rem;\n}\n\n.heading {\n  margin: 0;\n}\n\n.receivers {\n  margin-top: 1rem;\n\n  &__items {\n    display: grid;\n    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);\n    column-gap: 1.5rem;\n    align-items: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `subscriptionReceivers-root-uPY`,
	"heading": `subscriptionReceivers-heading-Zu9`,
	"receivers": `subscriptionReceivers-receivers-qzU`,
	"receivers__items": `subscriptionReceivers-receivers__items-GIl`
};
export default ___CSS_LOADER_EXPORT___;
