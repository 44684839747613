/* eslint-disable @typescript-eslint/ban-ts-comment */

const changeToPolishLocalTime = (date: string) => {
  const newDate = new Date(date);
  const options = {
    timeZone: "Europe/Warsaw",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  // @ts-ignore
  return newDate.toLocaleString("pl-PL", options);
};

export default changeToPolishLocalTime;
