/**
 * Add exceptions for logging error here
 * the syntax is:
 * {endpointName: status[]}
 */
const errorExceptionsConfig: Record<string, number[] | "all"> = {
  getEmployeeRentableGroupAdditionalData: [404],
  resetMFA: "all",
  changeCustomerPassword: [400],
};

export default errorExceptionsConfig;
