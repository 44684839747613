export default {
  claimSuccess: "paid_claimed_codes",
  paid: "paid",
  paidToVerification: "paid_to_verification",
  waitingForPayment: "waiting_for_payment",
  canceledMissingPayment: "canceled_missing_payment",
  rollBack: "roll_back",
  rollBackError: "roll_back_error",
  rolledBack: "rolled_back",
  toCancel: "to_cancel",
  canceledError: "canceled_error",
  canceled: "canceled",
  toManuallyCancel: "to_manually_cancel",
  manuallyCancelError: "manuallyCancelError",
};
