import { FC } from "react";

import { Classes } from "web/types/Common";

import { useGetWishlistCountQuery } from "web/features/wishlist/wishlistApiSlice";

import WishlistProducts from "./wishlistTrigger";

interface IWishlistTriggerContainerProps {
  classes?: Classes;
}

const WishlistTriggerContainer: FC<IWishlistTriggerContainerProps> = ({
  classes,
}) => {
  const { data } = useGetWishlistCountQuery();

  const count = data?.count;

  return <WishlistProducts classes={classes} count={count} />;
};

export default WishlistTriggerContainer;
