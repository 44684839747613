// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs-list-b_c{display:flex;align-items:baseline;margin-bottom:8px}.breadcrumbs-list-b_c>span{margin:5px !important;width:120px}.breadcrumbs-item-Hi8{height:20px}`, "",{"version":3,"sources":["webpack://./web/Layout/Common/SkeletonComponents/SkeletonBreadcrumbs/breadcrumbs.scss"],"names":[],"mappings":"AAIuB,sBACrB,YAAA,CACA,oBAAA,CACA,iBAAA,CAGF,2BACE,qBAAA,CACA,WAAA,CAGF,sBACE,WAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .list {\n  display: flex;\n  align-items: baseline;\n  margin-bottom: 8px;\n}\n\n.list > span {\n  margin: 5px !important;\n  width: 120px;\n}\n\n.item {\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `breadcrumbs-list-b_c`,
	"item": `breadcrumbs-item-Hi8`
};
export default ___CSS_LOADER_EXPORT___;
