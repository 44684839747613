// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carouselMobile-root-Nv4{display:block}.carouselMobile-rowLong-Acc{white-space:nowrap;overflow:hidden;-ms-overflow-style:none;scrollbar-width:none;width:100%}.carouselMobile-rowLong-Acc ::-webkit-scrollbar{display:none}.carouselMobile-rowLong-Acc>*{display:inline-block;vertical-align:top;white-space:normal}.carouselMobile-rowLong-Acc>*:first-child{margin-left:15px}.carouselMobile-rowLong-Acc>*:last-child{margin-right:15px}.carouselMobile-rowShort-P77{margin-left:15px;margin-right:15px}.swiper-slide{width:-moz-min-content;width:min-content}`, "",{"version":3,"sources":["webpack://./web/Layout/CarouselMobile/carouselMobile.scss"],"names":[],"mappings":"AAIuB,yBACrB,aAAA,CAGF,4BACE,kBAAA,CACA,eAAA,CACA,uBAAA,CACA,oBAAA,CACA,UAAA,CAEA,gDACE,YAAA,CAGF,8BACE,oBAAA,CACA,kBAAA,CACA,kBAAA,CAEA,0CACE,gBAAA,CAGF,yCACE,iBAAA,CAKN,6BACE,gBAAA,CACA,iBAAA,CAGF,cACE,sBAAA,CAAA,iBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: block;\n}\n\n.rowLong {\n  white-space: nowrap;\n  overflow: hidden;\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n  width: 100%;\n\n  :global(::-webkit-scrollbar) {\n    display: none;\n  }\n\n  > * {\n    display: inline-block;\n    vertical-align: top;\n    white-space: normal;\n\n    &:first-child {\n      margin-left: 15px;\n    }\n\n    &:last-child {\n      margin-right: 15px;\n    }\n  }\n}\n\n.rowShort {\n  margin-left: 15px;\n  margin-right: 15px;\n}\n\n:global(.swiper-slide) {\n  width: min-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `carouselMobile-root-Nv4`,
	"rowLong": `carouselMobile-rowLong-Acc`,
	"rowShort": `carouselMobile-rowShort-P77`
};
export default ___CSS_LOADER_EXPORT___;
