import { useFormikContext } from "formik";
import { bool, func, number, oneOfType, shape, string } from "prop-types";
import { useCallback, useRef } from "react";

import ValidationErrorMessage from "web/Components/Common/ValidationErrorMessage";

import CalendarIcon from "web/assets/icons/calendar.svg";

import useDropdown from "web/hooks/useDropdown";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";

import classify from "web/classify";

import Calendar from "./Calendar";
import defaultStyles from "./datepicker.scss";

const EVENT_LISTENERS_NAMES = {
  mouseDown: "onMouseDown",
  click: "onClick",
};

const Datepicker = ({
  isError,
  name,
  classes,
  placeholder,
  minDate,
  maxDate,
  disabled,
  defaultValue,
  buttonBackgroundColor,
  buttonBorderColor,
  onChange,
  dataT1,
  isMobile,
  datePickerIconColor,
  eventListenerName,
  dropdownClickOutside,
  useUTC = false,
}) => {
  const { setFieldTouched } = useFormikContext() || {};
  const [isOpen, setOpenStatus] = useDropdown({
    scopeSelector: `.${classes.calendar}`,
    clickOutside: dropdownClickOutside,
  });

  const calendarIconRef = useRef();

  const valueFormatted = defaultValue ? formatDate(defaultValue) : null;
  const getTriggerClassName = useCallback(() => {
    switch (true) {
      case isOpen: {
        return classes.triggerOpened;
      }
      case !!defaultValue: {
        return classes.triggerChosen;
      }
      default:
        return classes.trigger;
    }
  }, [isOpen, defaultValue, classes]);
  const buttonStyles = {
    backgroundColor: buttonBackgroundColor,
    borderColor: isError ? "red" : buttonBorderColor,
  };
  const iconStyles = {
    color: datePickerIconColor,
  };

  const onChangeHandler = useCallback(
    (newValue) => {
      onChange(newValue);

      if (typeof setFieldTouched === "function") {
        setFieldTouched(name, true);
      }
    },
    [onChange]
  );

  const onClickEvent = {
    [EVENT_LISTENERS_NAMES[eventListenerName]]: () => {
      setOpenStatus((state) => !state);
    },
  };

  return (
    <div className={classes.root}>
      <button
        ref={calendarIconRef}
        className={getTriggerClassName()}
        style={buttonStyles}
        type="button"
        disabled={disabled ? "disabled" : ""}
        data-t1={dataT1}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...onClickEvent}
      >
        <CalendarIcon className={classes.icon} style={iconStyles} />
        {isOpen || !valueFormatted ? placeholder : valueFormatted}
      </button>
      {isOpen && (
        <Calendar
          className={`${isMobile && classes.calendarMobile} ${
            classes.calendar
          } ${classes.calendarStyle}`}
          minDate={minDate}
          maxDate={maxDate}
          value={defaultValue}
          setValue={onChangeHandler}
          action={setOpenStatus}
          eventListenerName={eventListenerName}
          useUTC={useUTC}
        />
      )}
      {isError && <ValidationErrorMessage name={name} />}
    </div>
  );
};

Datepicker.propTypes = {
  classes: oneOfType([
    shape({
      root: string,
      trigger: string,
      triggerOpened: string,
      triggerChosen: string,
      icon: string,
      calendar: string,
    }),
    string,
  ]).isRequired,
  placeholder: string,
  disabled: bool,
  onChange: func,
  buttonBackgroundColor: string,
  buttonBorderColor: string,
  minDate: oneOfType([string, number, shape({})]),
  maxDate: oneOfType([string, number, shape({})]),
  defaultValue: number,
  dataT1: string,
  datePickerIconColor: string,
  isMobile: bool,
  name: string,
  isError: bool,
  eventListenerName: string,
  dropdownClickOutside: bool,
};

Datepicker.defaultProps = {
  placeholder: "",
  disabled: false,
  minDate: null,
  maxDate: null,
  buttonBackgroundColor: null,
  datePickerIconColor: null,
  buttonBorderColor: null,
  defaultValue: null,
  onChange: () => {},
  dataT1: "datepicker",
  isMobile: false,
  name: "",
  isError: false,
  eventListenerName: "click",
  dropdownClickOutside: true,
};

export default classify(defaultStyles)(Datepicker);
