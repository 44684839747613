import { FC, PropsWithChildren } from "react";

import RentableGroupHeader from "../Header/header";
import classes from "./rentableGroupFormWrapper.scss";

const RentableGroupFromWrapper: FC<PropsWithChildren> = ({ children }) => (
  <main className={classes.wrapper}>
    <div className={classes.headerWrapper}>
      <RentableGroupHeader />
    </div>

    {children}
  </main>
);

export default RentableGroupFromWrapper;
