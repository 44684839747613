import { Button } from "@benefit-systems/common-components";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Image from "web/Layout/Common/Image";
import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";
import jsonParse from "web/utils/data/parser/string/jsonParse";
import processUrlKey from "web/utils/page/product/universal/processUrlKey";

import urls from "web/constants/urls";

import { ICartItemProductData, ICartParentProductData } from "web/types/Cart";

import classes from "./dropdown.scss";

interface ICartTriggerDropdownProps {
  action: (e: unknown) => void;
}

export const getUrlKeyImage = (
  parentData: ICartParentProductData[],
  productData: string
) => {
  const productDataParsed = jsonParse<ICartItemProductData>(productData);
  if (parentData && parentData[0]) {
    return {
      urlKey: parentData[0].url_key,
      image:
        productDataParsed.image === "no_selection"
          ? parentData[0].image
          : productDataParsed.image,
      name: productDataParsed.name,
    };
  }

  return productDataParsed
    ? {
        urlKey: productDataParsed.url_key,
        image: productDataParsed.image,
        name: productDataParsed.name,
      }
    : {};
};

const CartTriggerDropdown: FC<ICartTriggerDropdownProps> = ({ action }) => {
  const { items, total } = useSelector((state) => state.cart);
  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {items?.map(
          ({
            sku,
            name: nameQuote,
            qty,
            row_total_incl_tax: rowTotal,
            parent_product_data: parentData,
            product_data: productData,
          }) => {
            const { urlKey, image, name } = getUrlKeyImage(
              parentData,
              productData
            );
            const nameProcessed = nameQuote || name;
            const urlKeyProcessed = urlKey ? processUrlKey(urlKey) : "";
            return (
              <li key={sku} className={classes.item} data-t1="cart_item">
                <Link
                  to={urlKeyProcessed}
                  className={classes.imageLink}
                  onClick={action}
                  title={nameProcessed}
                  data-t1="cart_item_link_image"
                >
                  <Image
                    className={classes.image}
                    src={image as string}
                    alt={nameProcessed as string}
                    isProduct
                  />
                </Link>
                <div className={classes.details}>
                  <Link
                    to={urlKeyProcessed}
                    className={classes.name}
                    onClick={action}
                    title={__(nameProcessed as string)}
                    data-t1="cart_item_link_name"
                  >
                    {__(nameProcessed as string)}
                  </Link>
                  <div className={classes.priceQuantity}>
                    <div
                      className={classes.price}
                      data-t1="cart_item_price"
                      data-t2={formatNumber(rowTotal)}
                    >
                      {formatNumber(rowTotal)}
                    </div>
                    <div className={classes.quantity}>
                      <div className={classes.quantityLabel}>{__("Ilość")}</div>
                      <div
                        className={classes.quantityValue}
                        data-t1="cart_item_qty"
                        data-t2={qty}
                      >
                        x{qty}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          }
        )}
      </ul>

      <div className={classes.summary}>
        <span>{__("Wartość koszyka")}</span>
        <span data-t1="cart_total">{formatNumber(total)}</span>
      </div>
      <Button
        dataT1="cart_link_checkout"
        to={urls.checkoutDefaultCart}
        onClick={action}
        block
        variant="tertiary"
      >
        {__("Przejdź do koszyka")}
      </Button>
    </div>
  );
};

export default CartTriggerDropdown;
