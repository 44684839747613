/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, memo } from "react";

import Badge from "web/Layout/Common/Badge";
import Tile from "web/Layout/MainHeader/Desktop/MainMenu/Tile";
import __ from "web/Layout/Translations";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import Anchor from "web/Components/Common/Anchor";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import scrollTopAction from "web/utils/system/DOM/scroll/scrollToTop";

import { PropsWithClasses } from "web/types/Common";
import { ITile, MenuData } from "web/types/Menu";
import { Nullable } from "web/types/Utils";

import classify from "web/classify";

import defaultClasses from "./dropdown.scss";

interface IMainMenuDropdownProps {
  dataT1: string;
  closeAction: (index: Nullable<number>) => void;
  items: MenuData;
  banners: ITile[];
}

const MainMenuDropdown: FC<PropsWithClasses<IMainMenuDropdownProps>> = memo(
  ({ classes = {}, banners = [], items, closeAction, dataT1 = "dropdown" }) => {
    const isItemsExist = isArrayHasItems(items);
    const isBannersExist = isArrayHasItems(banners);
    const onClickHandler = () => {
      closeAction(null);
      scrollTopAction();
    };
    const langCode = getLanguageCode();
    const isPolishLanguage = langCode === "pl";

    return isItemsExist || isBannersExist ? (
      <div className={classes.root}>
        {isItemsExist && (
          <div className={classes.listBox} data-t1={`list_box_${dataT1}`}>
            <ul className={classes.list} data-t1={`list_${dataT1}`}>
              {items?.map((item, index) => {
                if (!item) return null;

                // @ts-ignore
                const url = item.category_request_path || item.url;
                const itemKey = `${item.title}${index}`;
                return (
                  <li
                    className={classes.listItem}
                    key={itemKey}
                    data-t2={item.title}
                    data-t1="main_menu_item_li"
                  >
                    <Anchor
                      to={url}
                      onClick={onClickHandler}
                      className={classes.listLink}
                      title={item.title}
                      dataT1="main_menu_item_anchor"
                    >
                      {__(item.title)}&nbsp;
                      {Object.prototype.hasOwnProperty.call(
                        item,
                        "category_products_count"
                      ) ? (
                        <Badge
                          className={classes.badge}
                          // @ts-ignore
                          amount={parseInt(item.category_products_count, 10)}
                        />
                      ) : null}
                    </Anchor>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        {isBannersExist && (
          <ul className={classes.banners}>
            {banners?.map((banner, index) => {
              const { title, href, img, alt } = {
                title:
                  // @ts-ignore
                  (!isPolishLanguage && banner[`title_${langCode}`]) ||
                  banner.title,
                href:
                  // @ts-ignore
                  (!isPolishLanguage && banner[`href_${langCode}`]) ||
                  banner.href,
                img:
                  // @ts-ignore
                  (!isPolishLanguage && banner[`img_${langCode}`]) ||
                  banner.img,
                alt:
                  // @ts-ignore
                  (!isPolishLanguage && banner[`alt_${langCode}`]) ||
                  banner.alt,
              };

              const bannerKey = `${title}${alt}${index}`;
              return (
                <li
                  className={classes.bannerItem}
                  key={bannerKey}
                  data-t1="dropdown_banner_item"
                  data-t2={alt}
                >
                  <Tile
                    href={href}
                    img={img}
                    alt={alt}
                    closeAction={onClickHandler}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </div>
    ) : null;
  }
);

export default classify<PropsWithClasses<IMainMenuDropdownProps>>(
  defaultClasses
)(MainMenuDropdown);
