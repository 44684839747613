/* eslint-disable max-len */
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getBenefitAttachments from "web/utils/page/product/subscription/getBenefitAttachments";

import { BENEFIT_STATUS } from "web/constants/benefits";

import {
  FormType,
  ISubscriptionUserCurrent,
  SubscriptionAttachmentType,
  SubscriptionFormReceiver,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

export const hasBenefitsWithReceiverForms = (
  data: Nullable<ISubscriptionUserCurrent>,
  disableActive = false
) => {
  if (!data || !isArrayHasItems(data.items)) return false;

  return data.items.some((item) => {
    const { receivers, status } = item || {};
    const hasAnyActiveForm = receivers.some(
      (receiver) => receiver.formType === FormType.ACTIVE_FORM
    );

    if (
      status === BENEFIT_STATUS.STATUS_RESIGNED ||
      !hasAnyActiveForm ||
      (disableActive && status === BENEFIT_STATUS.STATUS_ACTIVE)
    )
      return false;

    return receivers.some(
      (receiver) =>
        receiver.type === SubscriptionFormReceiver.OTHERS &&
        receiver.formType === FormType.ACTIVE_FORM &&
        item.benefit.wayChooseOthersInActiveForm ===
          SubscriptionFormReceiver.OTHERS
    );
  });
};

export const hasBenefitsWithPdfForms = (
  data: Nullable<ISubscriptionUserCurrent>,
  disableActive = false
) => {
  if (!data || !isArrayHasItems(data.items)) return false;

  return data?.items.some((item) => {
    if (
      item.status === BENEFIT_STATUS.STATUS_RESIGNED ||
      (disableActive && item.status === BENEFIT_STATUS.STATUS_ACTIVE)
    )
      return false;

    const attachments = getBenefitAttachments(item.benefit);
    const hasPdfForms = attachments.some(
      (attachment) => attachment.type === SubscriptionAttachmentType.FORM
    );

    return isArrayHasItems(item.pdfBarcodes) || hasPdfForms;
  });
};

/**
 * @param {array} data Collection of employee's benefits.
 * @param {number | string} benefitGroupType Current benefit group type. Depending on data source can be either a number (API) or a string (param in URL).
 */
export const getBenefitsFromGroup = (
  data: ISubscriptionUserCurrent[],
  benefitGroupType: number
) => {
  if (!isArrayHasItems(data)) return false;

  return data.find(
    (groupData) => groupData.benefitGroupType === Number(benefitGroupType)
  );
};
