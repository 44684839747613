import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { IAttribute, IAttributeOption } from "web/types/Attributes";
import type { Facets, IFacetValue } from "web/types/Facets";
import type { IStatsItemValues, Stats } from "web/types/Stats";
import type { StringNumber } from "web/types/Utils";

type AttributeOptionMerged =
  | Partial<IStatsItemValues>
  | (Partial<Pick<IAttributeOption, "label">> & {
      value?: StringNumber;
      min?: number;
      max?: number;
      count: number;
    })[];

type AttributeWithoutOptions = Omit<IAttribute, "attribute_options">;

export type Filter = AttributeWithoutOptions & {
  attribute_options: AttributeOptionMerged;
};

const mergeFacetsStatsAttributes = (
  attributes: IAttribute[],
  stats: Stats,
  facets: Facets
) => {
  return isArrayHasItems(attributes) &&
    (isArrayHasItems(stats) || isArrayHasItems(facets))
    ? attributes.reduce<Filter[]>((result, attribute) => {
        if (
          attribute &&
          ["text", "price", "date"].indexOf(attribute.input_type) !== -1
        ) {
          const statCurrent = isArrayHasItems(stats)
            ? stats.find(
                (stat) => stat && attribute.attribute_code === stat.code
              )
            : null;
          if (statCurrent) {
            const newItem = {
              ...(attribute as AttributeWithoutOptions),
              attribute_options: statCurrent.values as AttributeOptionMerged,
            };
            return [...result, newItem];
          }
        }

        const facetCurrent = isArrayHasItems(facets)
          ? facets.find(
              (facet) => facet && attribute.attribute_code === facet.code
            )
          : null;

        return facetCurrent && isArrayHasItems(facetCurrent.values)
          ? [
              ...result,
              {
                ...attribute,
                attribute_options: facetCurrent.values.map(
                  (facetValue: IFacetValue) => {
                    const currentOption: IAttributeOption | null | undefined =
                      isArrayHasItems(attribute.attribute_options)
                        ? attribute.attribute_options.find(
                            (option) =>
                              `${option.value}` === `${facetValue.value}`
                          )
                        : null;

                    return {
                      ...facetValue,
                      label: currentOption
                        ? currentOption.label
                        : facetValue.value,
                    };
                  }
                ),
              },
            ]
          : result;
      }, [])
    : [];
};

export default mergeFacetsStatsAttributes;
