import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import type { IFiltersLocation } from "web/types/Geolocation";

const splitGeolocationData = (values: string | string[]) => {
  let valuesParsed: IFiltersLocation | Record<string, never>;
  try {
    valuesParsed = Array.isArray(values)
      ? JSON.parse(values[0])
      : JSON.parse(values);
  } catch (error) {
    newRelicErrorReport(
      error,
      "web-app/web/utils/data/parser/geolocation/splitGeolocationData.js - 8"
    );
    console.error(error);
    valuesParsed = {};
  }
  const { lat, lng, placeId } =
    valuesParsed && valuesParsed.location
      ? valuesParsed.location
      : { lat: undefined, lng: undefined, placeId: undefined };
  const distance = valuesParsed && valuesParsed.distance;

  return {
    placeId,
    lat,
    lng,
    distance,
  };
};

export default splitGeolocationData;
