import type {
  IRentableGroupCalculatorValues,
  Statements,
} from "../rentableGroupCalculatorContainer";

const shouldDisableCheckbox = (
  values: IRentableGroupCalculatorValues,
  statements: Statements,
  currStatement: Statements[number]
) =>
  statements.some(
    (statement) =>
      statement !== currStatement &&
      values[statement as keyof IRentableGroupCalculatorValues]
  );

export default shouldDisableCheckbox;
