import { useSelector } from "react-redux";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import {
  deleteNotificationError,
  deleteNotificationSuccess,
  deleteNotificationWarning,
} from "web/features/app/appSlice";

import Notification from "./Notification";
import classes from "./notifications.scss";

const Notifications = () => {
  const {
    notificationSuccess: successes,
    notificationWarning: warnings,
    notificationError: errors,
  } = useSelector((state) => state.app);
  return (
    <div className={classes.root}>
      <div className="container">
        {isArrayHasItems(successes)
          ? successes.map(({ id, message, timeout }) => {
              return (
                <Notification
                  type="success"
                  key={id}
                  message={message}
                  remove={deleteNotificationSuccess}
                  id={id}
                  timeout={timeout}
                />
              );
            })
          : null}
        {isArrayHasItems(warnings)
          ? warnings.map(({ id, message, timeout }) => {
              return (
                <Notification
                  type="warning"
                  key={id}
                  message={message}
                  remove={deleteNotificationWarning}
                  id={id}
                  timeout={timeout}
                />
              );
            })
          : null}
        {isArrayHasItems(errors)
          ? errors.map(({ id, message, timeout }) => {
              return (
                <Notification
                  type="error"
                  key={id}
                  message={message}
                  remove={deleteNotificationError}
                  id={id}
                  timeout={timeout}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

export default Notifications;
