import { FC } from "react";
import { Link } from "react-router-dom";

import Image from "web/Layout/Common/Image";
import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getPriceFinalPriceDiscount from "web/utils/page/product/universal/getPriceFinalPriceDiscount";

import type { IProductOptions } from "web/types/ProductOptions";

import classes from "./product.scss";

interface ISearchAutocompleteProductMobileProps {
  className: string;
  url: string;
  price: number;
  finalPrice: number;
  options: IProductOptions;
  image: string;
  name: string;
  type: string;
  closeAction: () => void;
}

const SearchAutocompleteProductMobile: FC<
  ISearchAutocompleteProductMobileProps
> = ({
  className,
  url,
  price,
  finalPrice,
  options = {} as IProductOptions,
  image,
  name,
  type,
  closeAction,
}) => {
  const isPriceVisible =
    [
      "international_tourism_travelplanet",
      "international_tourism_other",
      "anixe_tourism",
    ].indexOf(type) === -1;

  const { price: priceProcessed, finalPrice: finalPriceProcessed } =
    getPriceFinalPriceDiscount({
      price,
      finalPrice,
      options,
    });

  const isConfigurable =
    options &&
    options.configurable_options &&
    isArrayHasItems(options.configurable_options.attributes);
  const textFrom = isConfigurable ? `${__("od")} ` : "";
  const isDiscount = priceProcessed !== finalPriceProcessed;
  const classNamePriceBox = isDiscount
    ? classes.priceBoxDiscount
    : classes.priceBox;
  return (
    <article className={className} data-t1="autocomplete_product">
      <Link
        to={url}
        className={classes.imageBox}
        onClick={closeAction}
        data-t1="autocomplete_product_link_image"
      >
        <Image className={classes.image} src={image} alt={name} isProduct />
      </Link>
      <div className={classes.content}>
        <h4 className={classes.name} data-t1="autocomplete_product_title">
          <Link
            className={classes.link}
            to={url}
            title={name}
            onClick={closeAction}
            data-t1="autocomplete_product_title_link"
          >
            {name}
          </Link>
        </h4>
        {isPriceVisible && (
          <div className={classNamePriceBox}>
            {textFrom}
            <Price
              className={classes.price}
              value={finalPriceProcessed}
              currency={__("zł")}
            />
          </div>
        )}
      </div>
    </article>
  );
};

export default SearchAutocompleteProductMobile;
