import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

interface ILink {
  linkEl: HTMLAnchorElement;
  listener: EventListener;
}

/**
 * returns a ref - passed to element with innerHTML replaces
 * anchor internal links with react router links
 */
const useHTMLLink = () => {
  const contentRef = useRef<HTMLElement>(null);

  const { push } = useHistory();

  useEffect(() => {
    const links = Array.from(contentRef?.current?.querySelectorAll("a") || [])
      .map((linkEl) => {
        const { href, baseURI } = linkEl;

        if (!href.includes(baseURI)) {
          return null;
        }

        const linkTarget = href.replace(baseURI, "");
        if (linkTarget.includes(".") && !linkTarget.includes(".html")) {
          return null;
        }

        const listener: EventListener = (e) => {
          e.preventDefault();
          push(linkTarget);
        };

        linkEl.addEventListener("click", listener);
        return { linkEl, listener };
      })
      .filter((el) => !!el) as ILink[];

    return () => {
      links.forEach(({ linkEl, listener }) => {
        linkEl.removeEventListener("click", listener);
      });
    };
  }, []);

  return contentRef;
};

export default useHTMLLink;
