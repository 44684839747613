import { useQuery } from "@apollo/client";
import { FC, memo, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import searchGraphql from "web/queries/ms/search.graphql";
import searchSubGraphql from "web/queries/ms/searchSub.graphql";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import type { FacetsStats } from "web/types/Product";

import Autocomplete from "./autocomplete";
import classes from "./autocomplete.scss";

interface ISearchAutocompleteContainerMobileProps {
  options: {
    variables: {
      token: string;
      storeId: number;
      search: string;
    };
    context: {
      clientName: string;
    };
  };
  searchPhrase: string;
  closeAction: () => void;
}

const SearchAutocompleteContainerMobile: FC<ISearchAutocompleteContainerMobileProps> =
  memo(({ options, searchPhrase, closeAction }) => {
    const { hasEnabledAnySubscriptions } = useSelector(
      (state) => state.subscriptionQuote
    );

    const searchQuery = hasEnabledAnySubscriptions
      ? searchSubGraphql
      : searchGraphql;
    const { loading, error, data } = useQuery(searchQuery, options);

    switch (true) {
      case loading && (!data || !Object.keys(data).length): {
        return <Loading />;
      }
      case !!error: {
        newRelicErrorReport(
          error,
          `web-app/web/Layout/SearchAutocomplete/Mobile/Autocomplete/container.js - 26, phrase: ${searchPhrase}`
        );
        console.error(error);
        return <ErrorComponent />;
      }
      default: {
        return data &&
          data.msProducts &&
          data.msProducts.edges &&
          data.msProducts.edges[0] &&
          data.msProducts.edges[0].node ? (
          <Autocomplete
            data={data.msProducts.edges[0].node as FacetsStats}
            searchPhrase={searchPhrase}
            closeAction={closeAction}
          />
        ) : (
          <div className={classes.empty}>{__("Brak wyników wyszukiwania")}</div>
        );
      }
    }
  });

type SearchAutocompleteContainerWithContextMobileProps = Pick<
  ISearchAutocompleteContainerMobileProps,
  "searchPhrase" | "closeAction"
> & { className: string };

const SearchAutocompleteContainerWithContextMobile: FC<
  SearchAutocompleteContainerWithContextMobileProps
> = ({ searchPhrase, closeAction, className }) => {
  const { token, id: storeId } = useSelector((state) => state.app.storeConfig);
  const options = useMemo(() => {
    return {
      variables: {
        token,
        storeId,
        search: searchPhrase,
      },
      context: {
        clientName: "ms",
      },
    };
  }, [searchPhrase, storeId, token]);

  useEffect(() => {
    const keypressEscapeHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeAction();
      }
    };
    const clickOutsideHandler = (event: MouseEvent) => {
      if (
        event &&
        event.target &&
        typeof (event.target as HTMLElement).closest === "function" &&
        !(event.target as HTMLElement).closest(`.${className}`)
      ) {
        closeAction();
      }
    };

    document.addEventListener("keydown", keypressEscapeHandler);
    document.addEventListener("click", clickOutsideHandler);
    return () => {
      document.removeEventListener("keydown", keypressEscapeHandler);
      document.removeEventListener("click", clickOutsideHandler);
    };
  }, [closeAction]);

  return (
    <SearchAutocompleteContainerMobile
      options={options}
      searchPhrase={searchPhrase}
      closeAction={closeAction}
    />
  );
};

export default SearchAutocompleteContainerWithContextMobile;
