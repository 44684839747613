import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import {
  ICheckoutSubscriptionBenefitsItem,
  ISubscriptionAttachment,
  SubscriptionAttachmentType,
} from "web/types/Subscription";

interface ICheckIfReadMoreShouldBeDisplayedArgs {
  visibleDescription: ICheckoutSubscriptionBenefitsItem["visibleDescription"];
  attachments: ISubscriptionAttachment[];
}

/** Function determining wheter modal for single subscription on 1st step of configurator should be displayed.
 * Modal is shown if given subscription has a description or at least one attachment other than PDF barcode.
 * @param { string } visibleDescription - Text description from wysiwyg.
 * @param { array } attachments - Collection of attachments.
 */
const checkIfReadMoreShouldBeDisplayed = ({
  visibleDescription,
  attachments,
}: ICheckIfReadMoreShouldBeDisplayedArgs) =>
  visibleDescription ||
  (isArrayHasItems(attachments) &&
    !!attachments.filter(
      ({ type }) => type !== SubscriptionAttachmentType.PDF_BARCODE
    ).length);

export default checkIfReadMoreShouldBeDisplayed;
