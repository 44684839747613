import { AnimatePresence, motion } from "framer-motion";
import { FC, PropsWithChildren } from "react";

import useInitialRender from "web/hooks/useInitialRender";

interface IHideAnimationProps {
  isOpen: boolean;
  duration?: number;
}

const HideAnimation: FC<PropsWithChildren<IHideAnimationProps>> = ({
  children,
  isOpen,
  duration = 0.3,
}) => {
  const isInitialRender = useInitialRender();
  return (
    <AnimatePresence exitBeforeEnter>
      {isOpen && (
        <motion.div
          initial={{
            ...(isInitialRender ? {} : { height: 0 }),
            overflow: "hidden",
          }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          transition={{ duration }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default HideAnimation;
