import { isString } from "lodash";
import { ICartItemProductData } from "web/types/Cart";
import { ICodeItem } from "web/types/Code";
import { ITourismOrderItem } from "web/types/Tourism";
import jsonParse from "web/utils/data/parser/string/jsonParse";

interface IProductsInfo {
  productId: string;
  fullCategoryPath: string;
  fullAttributePath: string;
  fullCategoriesPaths: string[];
}

const getProductInfo = (
  productsInfo: IProductsInfo[],
  productItem: ICodeItem | ITourismOrderItem
) => {
  const parsedProductData = isString(productItem.product_data)
    ? jsonParse(productItem.product_data) as ICartItemProductData
    : productItem.product_data as ICartItemProductData;
  const product =
    productsInfo?.find(
      (element) => element.productId === parsedProductData?.entity_id
    ) || ({} as IProductsInfo);

  const {
    fullCategoryPath: productCategoryPath,
    fullAttributePath: productAttributesPath,
    fullCategoriesPaths,
  } = product;

  return {
    category: productCategoryPath,
    variant: productAttributesPath,
    fullCategoriesPaths,
  };
};

export default getProductInfo;
