import { FC } from "react";

import Points from "web/Layout/Common/Points";

import WalletIcon from "web/assets/icons/wallet.svg";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./customer.scss";

interface INavigationPanelAdditionalCustomeProps {
  totalPoints?: number;
}

const NavigationPanelAdditionalCustomer: FC<
  PropsWithClasses<INavigationPanelAdditionalCustomeProps>
> = ({ classes = {}, totalPoints }) => {
  return (
    <div className={classes.root}>
      <WalletIcon className={classes.icon} width={22} />
      <Points className={classes.points} points={totalPoints} />
    </div>
  );
};

export default classify(defaultClasses)(NavigationPanelAdditionalCustomer);
