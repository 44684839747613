import { useQuery } from "@apollo/client";

import getHelpCenterProvinces from "web/queries/default/getHelpCenterProvinces.graphql";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import type { IHelpCenterProvinces } from "web/types/HelpCenter";

const useProvinces = () => {
  const { data, error } = useQuery<{
    helpCenterProvinces: IHelpCenterProvinces;
  }>(getHelpCenterProvinces);

  if (error)
    newRelicErrorReport(
      error,
      "web-app/web/hooks/useProvinces/useProvinces.js - 10"
    );

  return (
    data?.helpCenterProvinces?.provinces?.map(({ name, value }) => ({
      value,
      label: name,
    })) || []
  );
};

export default useProvinces;
