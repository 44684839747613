// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.downloadCodeButton-icon-IiL{width:22px;margin-right:10px}`, "",{"version":3,"sources":["webpack://./web/Components/Common/CodeItem/CodeModal/DownloadCodeButton/downloadCodeButton.scss"],"names":[],"mappings":"AAIuB,6BACrB,UAAA,CACA,iBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .icon {\n  width: 22px;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `downloadCodeButton-icon-IiL`
};
export default ___CSS_LOADER_EXPORT___;
