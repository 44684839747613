import { motion } from "framer-motion";
import { FC, useEffect, useState } from "react";

import {
  availableLanguages,
  defaultLanguage,
} from "web/Layout/Translations/LanguageWrapper";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";
import { useGetCompanyDetailsQuery } from "web/features/company/companyApiSlice";
import {
  useGetCustomerDetailsQuery,
  useUpdateCustomerMutation,
} from "web/features/customer/customerApiSlice";
import { flushReduxPersistCache } from "web/store";

import SharedLayoutAnimation from "../SharedLayoutAnimation";
import defaultClasses from "./languageSwitcher.scss";

const LanguageSwitcher: FC<PropsWithClasses> = ({ classes = {} }) => {
  const [companyLanguages, setCompanyLanguages] = useState<
    typeof availableLanguages
  >([]);
  const [lang, setLang] = useState(defaultLanguage);
  const { data } = useGetCompanyDetailsQuery();
  const languages = data?.languages || [];

  const [updateCustomerFn] = useUpdateCustomerMutation();

  const { data: customer, isLoading } = useGetCustomerDetailsQuery();
  const { language: userLanguage } = customer || {};

  const changeMetaLang = (lang: string, defaultLang = defaultLanguage) => {
    const appDocument = document?.documentElement;

    switch (lang) {
      case "en_GB":
        appDocument.setAttribute("lang", "en");
        break;
      case "pl_PL":
        appDocument.setAttribute("lang", "pl");
        break;
      default:
        appDocument.setAttribute("lang", defaultLang);
    }
  };

  useEffect(() => {
    setCompanyLanguages(
      availableLanguages.filter(
        ({ key, code }) => languages?.includes(code) || key === defaultLanguage
      )
    );
  }, [data]);
  useEffect(() => {
    if (userLanguage) {
      changeMetaLang(userLanguage);
      setLang(userLanguage);
    }
  }, [userLanguage]);

  if (!languages?.length) {
    return null;
  }

  const changeLanguage = async (key: string) => {
    await flushReduxPersistCache();
    setLang(key);
    updateCustomerFn({
      language: key,
    });
  };

  return (
    <div className={classes.root} data-t1="language_switcher">
      <SharedLayoutAnimation groupId="language_switcher">
        <ul className={classes.list}>
          {companyLanguages.map(({ key, label, name }) => {
            const isActive = key === lang;

            return (
              <motion.li key={label} className={classes.item}>
                {isActive && (
                  <motion.div layoutId="active" className={classes.active} />
                )}
                <button
                  type="button"
                  data-t1="language_switcher_button"
                  data-t2={name}
                  className={classes.button}
                  disabled={isLoading}
                  style={{ color: isActive ? "black" : undefined }}
                  onClick={() => changeLanguage(key)}
                  title={label}
                >
                  {name}
                </button>
              </motion.li>
            );
          })}
        </ul>
      </SharedLayoutAnimation>
    </div>
  );
};

export default classify<PropsWithClasses>(defaultClasses)(LanguageSwitcher);
