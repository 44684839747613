export default {
  instructionHeader:
    "Użyj poniższego kalkulatora dochodowego, aby w prosty sposób wybrać właściwą dla Ciebie grupę dochodową.",
  instruction:
    "Możesz nie używać kalkulatora i zadeklarować przynależność do najwyższej grupy dochodowej lub zrezygnować z dofinansowania z Zakładowego Funduszu Świadczeń Socjalnych.",
  fillCalculatorCheckbox:
    "Nie wypełniam kalkulatora dochodowego. Oświadczam, że moje dochody w ostatnim roku znajdują się w najwyższym progu dochodowym.",
  financingCheckbox:
    "Nie jestem zainteresowany/a dofinansowaniem z Zakładowego Funduszu Świadczeń Socjalnych.",
  calculatorHeader: "Kalkulator wyboru grup dochodowości",
  peopleCount: "Liczba osób w gospodarstwie",
  averageIncome: "Średni miesięczny dochód na osobę",
  incomeGroup: "Wyliczony próg dochodowy:",
  incomeGroupDeclared: "Oświadczony próg dochodowy:",
  noIncomeGroupAvailable:
    "Przedział w którym się znajdujesz nie jest zdefiniowany - wypełnij powyższe dane o dochodach lub zgłoś się do swojego działu HR.",
  removePerson: "Usuń osobę",
  addPerson: "Dodaj kolejną osobę",
  personInHousehold: "Osoba w gospodarstwie domowym",
  incomeInHousehold: "Dochód w gospodarstwie",
};
