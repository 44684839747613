import { Button } from "@benefit-systems/common-components";

import NoInternetConnectionImg from "web/assets/images/no-internet-connection.png";

import __ from "../Translations";
import classes from "./noInternetConnection.scss";

const NoInternetConnection = () => {
  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <img
          className={classes.image}
          src={NoInternetConnectionImg}
          alt="no internet connection"
        />
        <h1 className={classes.title}>{__("Ups!")}</h1>
        <p className={classes.description}>
          {__(
            "Nie możemy wyświetlić zawartości. Sprawdź połączenie internetowe i wróć do nas szybko."
          )}
        </p>
        <Button
          onClick={() => window.location.reload()}
          className={classes.btn}
          variant="tertiary"
        >
          {__("Odśwież stronę")}
        </Button>
      </div>
    </div>
  );
};

export default NoInternetConnection;
