import { useCallback, useEffect } from "react";

import __ from "web/Layout/Translations";

import { useAppContext } from "web/context/app";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";
import classes from "./overlay.scss";

const Overlay = () => {
  const { modal } = useAppContext();
  const { component, menuIndex, withoutBackdrop, dispatch } = modal;
  const isMenuIndexNumber = typeof menuIndex === "number";
  const isDispatchFunction = typeof dispatch === "function";
  const classNameActiveMenu = isMenuIndexNumber ? classes.activeMenu : "";
  const classNameActiveModal =
    component && !withoutBackdrop ? classes.activeModal : "";
  const classNameOverlay =
    `${classes.root} ${classNameActiveMenu} ${classNameActiveModal}`.trim();
  const keypressEscapeHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape" && isDispatchFunction) {
        dispatch({ type: ModalActionTypes.RESET });
      }
    },
    [dispatch]
  );
  const clickHandler = useCallback(() => {
    if (isDispatchFunction) {
      dispatch({ type: ModalActionTypes.RESET });
    }
  }, [dispatch]);

  useEffect(() => {
    if (isMenuIndexNumber || component) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [component, menuIndex]);

  useEffect(() => {
    document.addEventListener("keydown", keypressEscapeHandler);
    return () => document.removeEventListener("keydown", keypressEscapeHandler);
  }, [dispatch]);
  const title = __("Nakładka");
  return (
    <button
      type="button"
      className={classNameOverlay}
      onClick={clickHandler}
      title={title}
    >
      <span className="visually-hidden" />
    </button>
  );
};

export default Overlay;
