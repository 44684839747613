import { useQuery } from "@apollo/client";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

export default (query, options, ErrorView = null, LoadingView, DataView) => {
  const { loading, error, data } = useQuery(query, options);

  switch (true) {
    case loading && (!data || !Object.keys(data).length): {
      return typeof LoadingView === "function" ? (
        <LoadingView data={data} />
      ) : (
        LoadingView
      );
    }
    case !!error: {
      newRelicErrorReport(
        error,
        "web-app/web/hooks/useQueryWithRender/useQueryWithRender.js - 17"
      );
      console.error(error);
      return typeof ErrorView === "function" ? (
        <ErrorView data={data} error={error} />
      ) : (
        ErrorView
      );
    }
    default: {
      return typeof DataView === "function" ? <DataView data={data} /> : data;
    }
  }
};
