/* eslint-disable no-unused-vars */
import { FC, memo, useEffect, useState } from "react";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";

import placeholder from "web/assets/images/placeholder.jpg";

import { PropsWithClasses } from "web/types/Common";
import type { Nullable } from "web/types/Utils";

import classify from "web/classify";

interface ILazyLoadImageProps {
  src: Nullable<string>;
  alt?: string;
  height: Nullable<number>;
  dataT1: string;
}

const LazyLoadImage: FC<PropsWithClasses<ILazyLoadImageProps>> = memo(
  ({ classes = {}, alt, src = null, dataT1 }) => {
    const [isError, setError] = useState(false);
    const onError = () => {
      setError(true);
    };

    useEffect(() => {
      setError(false);
    }, [src]);

    return (
      <Image
        className={classes.root}
        data-t1={dataT1}
        alt={alt}
        src={isError ? placeholder : src}
        onError={onError}
        style={{ objectFit: "cover" }}
      />
    );
  }
);

export default classify<PropsWithClasses<ILazyLoadImageProps>>({})(
  LazyLoadImage
);
