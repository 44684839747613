/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import { facilitiesIcons } from "web/Pages/Product/Shared/Amenities/amenities";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IAttribute } from "web/types/Attributes";
import type { DomesticTourismProduct, IProduct } from "web/types/Product";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

import classes from "./facilities.scss";

interface IProductCardFacilitiesProps {
  className: string;
  facilities: IProduct<DomesticTourismProduct>["tourism_facilities"];
}

const ProductCardFacilities: FC<IProductCardFacilitiesProps> = ({
  className = "",
  facilities,
}) => {
  const { loading, error, data } = useDataCachedAttributes({
    ids: ["tourism_facilities"],
  }) as { loading: boolean; error: unknown; data: IAttribute[] };

  switch (true) {
    case !!loading: {
      return <p>{__("Ładowanie")}</p>;
    }
    case !!error: {
      console.error(error);
      return <ErrorComponent />;
    }
    default: {
      const facilitiesCodes =
        isArrayHasItems(data) &&
        data[0] &&
        isArrayHasItems(data[0].attribute_options)
          ? facilities.reduce<string[]>((result, current) => {
              const option =
                current &&
                data[0].attribute_options.find(
                  (attributeOption) =>
                    attributeOption && attributeOption.value === current
                );
              return option ? [...result, option.code] : result;
            }, [])
          : null;

      return isArrayHasItems(facilitiesCodes) ? (
        <div className={className || classes.root}>
          <div className={classes.row}>
            {/* @ts-ignore */}
            {facilitiesCodes.reduce((result, facility) => {
              const FacilityIcon = facilitiesIcons.find(
                (icon) => icon.label === facility
              )?.icon;

              return FacilityIcon
                ? [
                    ...result,
                    <span className={classes.facility} key={facility}>
                      <FacilityIcon
                        title={facility}
                        className={classes.facilityIcon}
                      />
                    </span>,
                  ]
                : result;
            }, [])}
          </div>
        </div>
      ) : null;
    }
  }
};

export default ProductCardFacilities;
