import BarcodeDownloaderActivityItem from "web/Layout/SubscriptionActivities/ActivityItem/BarcodeDownloaderItem";
import EmailSenderActivityItem from "web/Layout/SubscriptionActivities/ActivityItem/EmailSenderItem/emailSenderItem";
import FileDownloaderActivityItem from "web/Layout/SubscriptionActivities/ActivityItem/FileDownloaderItem";
import FormFillerItem from "web/Layout/SubscriptionActivities/ActivityItem/FormFillerItem";
import ActivityItem from "web/Layout/SubscriptionActivities/ActivityItem/activityItem";
import __ from "web/Layout/Translations";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import downloadFile from "web/api/downloadFile";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import checkIfTimePassed from "web/utils/data/validator/dateAndTime/checkIfTimePassed";
import { isExternalForm } from "web/utils/page/product/subscription/forms/getFormMode";
import getAttachmentFiles from "web/utils/page/product/subscription/getAttachmentFiles";
import getBenefitAttachments from "web/utils/page/product/subscription/getBenefitAttachments";
import getNameAndLastnameFromFormObj from "web/utils/page/product/subscription/getNameAndLastnameFromFormObj";
import { getIdFromIri } from "web/utils/page/product/subscription/iri";
import setBenefitGroupTypeParam from "web/utils/page/product/subscription/setBenefitGroupTypeParam";

import { NOT_ACTIVE_STATUSES } from "web/constants/benefits";
import { dayMilliseconds } from "web/constants/date";
import restUrls from "web/constants/restUrls";

import {
  ISubscriptionItemCurrentUserSubscription,
  ISubscriptionUserCurrent,
  SubscriptionFormReceiver,
  SubscriptionPurchaseMethod,
} from "web/types/Subscription";

export default (
  data: ISubscriptionUserCurrent,
  disablePending = true,
  setData: React.Dispatch<React.SetStateAction<ISubscriptionUserCurrent>>
) => {
  const elements = [];
  const benefitsWithFormsToUpdate: ISubscriptionItemCurrentUserSubscription[] =
    [];
  const languageCode = getLanguageCode();
  const {
    id: subscriptionId,
    items: benefits,
    agreementsPdfUrl,
    totals,
  } = data;

  const isAnyBenefitPending = benefits.some((ele) =>
    NOT_ACTIVE_STATUSES.includes(ele.status)
  );
  const showOfflineDeclarations =
    isAnyBenefitPending && agreementsPdfUrl && !disablePending;

  if (isArrayHasItems(benefits)) {
    benefits.forEach((item) => {
      const {
        status,
        benefit,
        name,
        activeForms,
        pdfBarcodes,
        purchaseMethod,
        receivers,
      } = item;
      const { manualActivationRequired } = benefit;
      const benefitUUID = getIdFromIri(
        item["@id"],
        restUrls.employeeSubscriptionItems
      );

      if (!benefitUUID) return;

      // @TODO BenefitsWithFormsToUpdate are based on receivers now. The rest of this file
      // must be cleaned up once FA will work properly (get rid of forms/activeForms fields)
      if (receivers?.length) {
        receivers.some((receiver) => {
          const { form, type } = receiver;
          const {
            awaitsUpdate,
            emailSentAt,
            receiverEmail,
            type: formType,
          } = form || {};

          const external = isExternalForm(
            formType,
            type,
            benefit.wayChooseOthersInActiveForm
          );

          if (external) {
            const needsResend = !!(
              external &&
              awaitsUpdate &&
              emailSentAt &&
              checkIfTimePassed(emailSentAt, dayMilliseconds)
            );

            if (awaitsUpdate && (needsResend || !receiverEmail)) {
              benefitsWithFormsToUpdate.push(item);
              return true;
            }
          } else if (awaitsUpdate) {
            benefitsWithFormsToUpdate.push(item);
            return true;
          }
          return false;
        });
      }

      if (!NOT_ACTIVE_STATUSES.includes(status)) {
        return;
      }

      const attachments = getBenefitAttachments(benefit);

      // Prep every user connection to forms attachments
      const receiverWithAttachmentsObject =
        isArrayHasItems(receivers) &&
        receivers.map((receiver) => {
          const isActive = !NOT_ACTIVE_STATUSES.includes(receiver.status);
          const receiverAttachments = isActive ? [] : attachments;

          return {
            ...receiver,
            attachments: receiverAttachments,
          };
        });

      // TYPE FORMS
      if (
        isArrayHasItems(attachments) &&
        isArrayHasItems(receiverWithAttachmentsObject)
      ) {
        receiverWithAttachmentsObject.map((recEle) => {
          const { form } = recEle || {};
          const { firstname, lastname, receiverEmail } =
            getNameAndLastnameFromFormObj(form);

          return recEle.attachments.forEach((attachment) => {
            if (attachment.type === "form") {
              const files = getAttachmentFiles(attachment);

              if (isArrayHasItems(files)) {
                files.forEach((file) => {
                  if (file.language !== languageCode) return;
                  const description = attachment.name;
                  const fileUrl = `${restUrls.currentUserSubscription}/attachment/${file.id}`;
                  elements.push(
                    <ActivityItem
                      key={`${benefitUUID}-attachment-${fileUrl}`}
                      onClick={() => downloadFile(fileUrl, name, true)}
                      name={name}
                      description={description}
                      receiverName={firstname}
                      receiverLastname={lastname}
                      receiverEmail={receiverEmail}
                    >
                      {__("Pobierz")}
                    </ActivityItem>
                  );
                });
              }
            }
          });
        });
      }

      if (isArrayHasItems(receivers)) {
        receivers.forEach((receiver) => {
          const { type: receiverType, form: receiverForm } = receiver || {};

          if (
            receiverType === SubscriptionFormReceiver.OTHERS &&
            receiverForm &&
            !receiverForm.completed &&
            receiverForm.receiverEmail &&
            receiverForm.formConfig
          ) {
            const description = `${__(
              "Nie został wypełniony formularz świadczeń abonamentowych, wysłany na adres e-mail:"
            )} ${receiverForm.receiverEmail}`;
            const resendLink = `${
              restUrls.subscriptions
            }/${subscriptionId}/active-form-resend/${getIdFromIri(
              receiverForm.formConfig,
              restUrls.activeForms
            )}/${receiverForm.receiverEmail}`;

            elements.push(
              <EmailSenderActivityItem
                key={`${benefitUUID}-email-${resendLink}`}
                name={name}
                description={description}
                resendLink={resendLink}
                setData={setData}
                receiver={receiver}
                benefitUUID={benefitUUID}
              />
            );
          }
        });
      }

      // In benefits with individual payments - render one form per each receiver
      if (purchaseMethod === SubscriptionPurchaseMethod.INDIVIDUAL) {
        receivers.forEach((receiver) => {
          const { form, receiverPdfBarcodes } = receiver || {};

          if (receiverPdfBarcodes.length) {
            const { firstname, lastname } = getNameAndLastnameFromFormObj(form);

            receiverPdfBarcodes.forEach(
              ({ accepted, generateUrl, id: barcodeId, attachmentName }) => {
                if (
                  accepted ||
                  (!manualActivationRequired &&
                    // TODO: change activeForms to receivers after FR-1106
                    !activeForms.some(
                      ({ receiver }) =>
                        receiver === SubscriptionFormReceiver.OTHERS
                    ))
                )
                  return;

                elements.push(
                  <BarcodeDownloaderActivityItem
                    key={`barcodeDownload_${barcodeId}`}
                    benefitUUID={benefitUUID}
                    name={name}
                    description={attachmentName}
                    generateUrl={generateUrl}
                    receiverName={firstname}
                    receiverLastname={lastname}
                  />
                );
              }
            );
          }
        });
      } else if (pdfBarcodes.length) {
        pdfBarcodes.forEach(
          ({ accepted, generateUrl, id: barcodeId, attachmentName }) => {
            if (
              accepted ||
              (!manualActivationRequired &&
                !activeForms.some(
                  ({ receiver }) => receiver === SubscriptionFormReceiver.OTHERS
                ))
            )
              return;

            elements.push(
              <BarcodeDownloaderActivityItem
                key={`barcodeDownload_${barcodeId}`}
                benefitUUID={benefitUUID}
                name={name}
                description={attachmentName}
                generateUrl={generateUrl}
              />
            );
          }
        );
      }
    });

    if (benefitsWithFormsToUpdate.length) {
      const description = __(`
        Uzupełnij swoje dane dotyczące abonamentów, 
        które wybrałeś lub wpisz adres e-mail osób, 
        dla których wybrałeś abonament.
      `);
      elements.push(
        <FormFillerItem
          key="benefitsWithFormsToUpdate"
          benefitsWithFormsToUpdate={benefitsWithFormsToUpdate}
          setDataRequiredActivities={setData}
          totals={totals}
          name={__("Wymagane uzupełnienie formularza")}
          description={description}
        />
      );
    }
  }

  if (showOfflineDeclarations) {
    elements.push(
      <FileDownloaderActivityItem
        key="selectedSubscriptions"
        name={__("Wybrane abonamenty")}
        file={`${
          restUrls.currentUserSubscriptionAgreements
        }${setBenefitGroupTypeParam(data.benefitGroupType)}`}
        description={__("Automatycznie generowane zgody i oświadczenia")}
      />
    );
  }

  return elements;
};
