import { useCallback, useState } from "react";

import __ from "web/Layout/Translations";

const sortMethods = [
  {
    title: "A-Z",
    name: "alphabet",
    method: (first: string, second: string) => {
      if (/\d/.test(first) && /\d/.test(second)) {
        const firstNumber =
          typeof first === "string" ? (first.match(/\d+/)?.[0] as string) : 0;
        const secondNumber =
          typeof second === "string"
            ? (second?.match(/\d+/)?.[0] as string)
            : 0;
        if (firstNumber > secondNumber) {
          return -1;
        }

        if (firstNumber < secondNumber) {
          return 1;
        }

        return 0;
      }

      const firstLowerCase = `${first}`.toLowerCase();
      const secondLowerCase = `${second}`.toLowerCase();

      if (firstLowerCase < secondLowerCase) {
        return -1;
      }

      if (firstLowerCase > secondLowerCase) {
        return 1;
      }

      return 0;
    },
  },
  {
    title: "Ilość",
    name: "count",
    method: (first: string, second: string) => {
      const firstFloat = parseFloat(first) || 0;
      const secondFloat = parseFloat(second) || 0;
      return secondFloat - firstFloat;
    },
  },
];
const getSortMethodIndexByName = (name?: string) =>
  name ? sortMethods.findIndex((method) => method.name === name) : -1;

const useSortMethods = (defaultSortMethodName?: string) => {
  const sortMethodIndexByName = getSortMethodIndexByName(defaultSortMethodName);
  const defaultSortMethodIndex =
    sortMethodIndexByName === -1 ? 0 : sortMethodIndexByName;
  const [currentSortMethodIndex, setSortMethodIndex] = useState(
    defaultSortMethodIndex
  );

  const nextSortMethodIndex =
    sortMethods.length - 1 === currentSortMethodIndex
      ? 0
      : currentSortMethodIndex + 1;

  const setNextSortMethod = useCallback(() => {
    setSortMethodIndex(nextSortMethodIndex);
  }, [nextSortMethodIndex, setSortMethodIndex, sortMethods]);

  const { method, name, title } = sortMethods[currentSortMethodIndex];

  return {
    sortMethod: method,
    nameMethod: name,
    titleMethod: __(title),
    setNextMethod: setNextSortMethod,
  };
};

export default useSortMethods;
