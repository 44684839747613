/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { names } from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import { SkeletonProductsList } from "web/Layout/Common/SkeletonComponents";
import ErrorBoundary from "web/Layout/ErrorBoundary";
import ListingHeader from "web/Layout/Listing/Common/Header";
import classes from "web/Layout/Listing/listing.scss";
import classesMobile from "web/Layout/Listing/listingMobile.scss";

import Categories from "web/Components/Common/Categories";
import Filters from "web/Components/Common/Filters";
import Map from "web/Components/Common/Filters/Desktop/Map";

import getUniqueElementsArray from "web/utils/data/transform/getUniqueElementsArray";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import isAttributeHasAnixeTourismOption from "web/utils/page/category/isAttributeHasAnixeTourismOption";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import { sortOptions } from "web/constants/toolbar";

import { Facets } from "web/types/Facets";
import { Stats } from "web/types/Stats";

import { useAppContext } from "web/context/app";
import { useGoogleContext } from "web/context/google.context";
import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

import jsonParse from "../../../utils/data/parser/string/jsonParse";
import BannerCampaign from "./BannerCampaign";
import Products from "./Products";

const facetsHidden = ["mb_product_type_filter", "category_ids"];
const facetsHiddenForSpecialCategories = [
  "mb_product_type_filter",
  "category_ids",
  "geolocation_data",
];

interface IListingCategoryProps {
  ids: (string | number)[];
  facets: Facets;
  stats: Stats;
  name: string;
  count?: number;
  prices: any;
  activeFilters: string[];
  sortOptions: (typeof sortOptions)[];
  additionalParameters: string;
  isAnixeTourismCategory: boolean;
  categoriesPath: string;
}

const ListingCategory: FC<IListingCategoryProps> = ({
  ids = null,
  facets = [],
  stats = [],
  prices = null,
  name,
  count = 0,
  activeFilters = [],
  sortOptions,
  additionalParameters,
  isAnixeTourismCategory = false,
  categoriesPath = "",
}) => {
  const [city, setCity] = useState("");
  const { isFiltersLoading } = useSelector((state) => state.app);

  const { isMobile } = useAppContext();
  const { search } = useLocation();
  const locationParameter = getSearchParameter({
    name: names.location,
    search,
  });

  const { geocoderService } = useGoogleContext();

  useEffect(() => {
    if (!isMobile) {
      return;
    }
    const locationParameterParsed = locationParameter
      ? jsonParse(locationParameter)
      : null;
    //  @ts-ignore
    if (locationParameterParsed && locationParameterParsed.placeId) {
      geocoderService?.geocode(
        {
          //  @ts-ignore
          placeId: locationParameterParsed.placeId,
        },
        //  @ts-ignore
        (result: { formatted_address: string }[], status: string) => {
          if (status === "OK" && result[0] && result[0].formatted_address) {
            setCity(result[0].formatted_address);
          }
        }
      );
    } else {
      setCity("");
    }
  }, [search]);

  const options = useMemo(() => {
    const filterCodes = [
      ...(stats?.map((stat) => stat.code) || []),
      ...(facets?.map((facet) => facet.code) || []),
    ];
    const filterFilteredCodes = filterCodes.filter(
      (item) => item && ["price"].indexOf(item) === -1
    );

    return {
      ids: getUniqueElementsArray(filterFilteredCodes),
    };
  }, [JSON.stringify(stats), JSON.stringify(facets)]);

  const { loading, error, data } = useDataCachedAttributes(options);

  const rowClasses = isMobile ? classesMobile.row : classes.row;

  switch (true) {
    case !!error: {
      return <ErrorComponent />;
    }
    default: {
      const isAnixeTourism =
        isAnixeTourismCategory ||
        isAttributeHasAnixeTourismOption({
          attributes: data,
          facets,
        });

      const facetsHiddenProcessed = isAnixeTourism
        ? facetsHiddenForSpecialCategories
        : facetsHidden;

      return (
        <>
          <ErrorBoundary>
            <ListingHeader
              name={city || name}
              count={count}
              sortOptions={sortOptions}
              stats={stats}
              facets={facets}
              facetsHidden={facetsHiddenProcessed}
              attributes={data}
            />
          </ErrorBoundary>
          <div className={`row ${rowClasses}`}>
            {isMobile ? null : (
              <aside className="col col-3">
                <ErrorBoundary>
                  {isAnixeTourism ? null : (
                    <Categories
                      className={classes.categories}
                      facets={facets}
                    />
                  )}
                </ErrorBoundary>
                <ErrorBoundary>
                  <Filters
                    stats={stats}
                    facets={facets}
                    prices={prices}
                    facetsHidden={facetsHiddenProcessed}
                    attributes={data}
                    isAnixeTourism={isAnixeTourism}
                    ids={ids as number[]}
                  />
                </ErrorBoundary>
                <ErrorBoundary>
                  <BannerCampaign />
                </ErrorBoundary>
              </aside>
            )}
            {isMobile && isAnixeTourism && isArrayHasItems(ids) && (
              <Map variant="secondary" ids={ids as number[]} prices={prices} />
            )}
            <div className="col col-9">
              <ErrorBoundary>
                {loading || isFiltersLoading ? (
                  <SkeletonProductsList />
                ) : (
                  <Products
                    ids={ids as string[]}
                    activeFilters={activeFilters}
                    isAnixeTourism={isAnixeTourism}
                    additionalParameters={additionalParameters}
                    categoriesPath={categoriesPath}
                  />
                )}
              </ErrorBoundary>
            </div>
          </div>
        </>
      );
    }
  }
};

export default ListingCategory;
