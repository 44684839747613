import { FC } from "react";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import type {
  Filter,
  Filter as FilterType,
} from "web/utils/page/category/mergeFacetsStatsAttributes";

import Filters from "./filters";
import FiltersAnixe from "./filtersAnixeProduct";

interface IFiltersContainerMobileProps {
  isAnixeTourism?: boolean;
  isInternationalTourism?: boolean;
  filters: FilterType[];
  facetsHidden: string[];
}

const FiltersContainerMobile: FC<IFiltersContainerMobileProps> = ({
  isAnixeTourism = false,
  isInternationalTourism = false,
  filters,
  facetsHidden = [],
}) => {
  const filteredFilters =
    isArrayHasItems(filters) &&
    filters.filter((singleFilter) =>
      isInternationalTourism
        ? singleFilter.attribute_code !== "review_summary_filter"
        : (singleFilter.attribute_options as [])?.length !== 0
    );

  if (isAnixeTourism) {
    return <FiltersAnixe />;
  }

  return (
    <Filters
      filters={filteredFilters as Filter[]}
      facetsHidden={facetsHidden}
    />
  );
};

export default FiltersContainerMobile;
