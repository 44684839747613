import { names } from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import splitGeolocationData from "web/utils/data/parser/geolocation/splitGeolocationData";

import { eventDate, geolocation } from "web/constants/filters";

import type { ILocationItem } from "web/types/Geolocation";

import jsonParse from "../../data/parser/string/jsonParse";
import urlSearchParamsToolbarFiltered from "../product/universal/urlSearchParamsToolbarFiltered";

const addZero = (digit: number) => `0${digit}`.slice(-2);
const formatDate = (milliseconds: string, withTimezoneOffset = false) => {
  const millisecondsProcessed = +milliseconds
    ? +milliseconds
    : Date.parse(milliseconds);

  if (!millisecondsProcessed) {
    return milliseconds;
  }

  const offset = new Date().getTimezoneOffset();
  const dateChosen = !withTimezoneOffset
    ? new Date(millisecondsProcessed)
    : new Date(millisecondsProcessed + offset * 1000);

  const day = addZero(dateChosen.getDate());
  const month = addZero(dateChosen.getMonth() + 1);

  return `${dateChosen.getFullYear()}-${month}-${day}`;
};

const getFilterParametersUrl = (search: string) => {
  const searchFiltered = urlSearchParamsToolbarFiltered(search);
  const searchFilteredKeys = Object.keys(searchFiltered);

  return searchFilteredKeys && searchFilteredKeys.length
    ? searchFilteredKeys.reduce<string[]>((resultSearch, key: string) => {
        switch (key) {
          case names.location: {
            const { lat, lng } = searchFiltered[key]
              ? (jsonParse<ILocationItem>(searchFiltered[key]) as ILocationItem)
              : { lat: undefined, lng: undefined };
            return lat && lng
              ? [...resultSearch, `${geolocation.attribute_code}=${lng};${lat}`]
              : resultSearch;
          }
          case geolocation.attribute_code: {
            const { lat, lng, distance } = splitGeolocationData(
              searchFiltered[key]
            );
            return lat && lng && distance
              ? [...resultSearch, `${key}=${lng};${lat};${distance}`]
              : resultSearch;
          }
          case eventDate.attribute_code: {
            const keyData = `${key}=${searchFiltered[key].reduce(
              (result, current, index, array) => {
                const dateFormatted = formatDate(current, true);
                if (!index) {
                  return array.length === 1
                    ? `${dateFormatted}-`
                    : dateFormatted;
                }

                return index === 1 ? `${result}-${dateFormatted}` : result;
              },
              ""
            )}`;
            return [...resultSearch, keyData];
          }
          case "final_price": {
            return [...resultSearch, `${key}=${searchFiltered[key].join("-")}`];
          }
          default: {
            return [...resultSearch, `${key}=${searchFiltered[key].join(",")}`];
          }
        }
      }, [])
    : [];
};

export default getFilterParametersUrl;
