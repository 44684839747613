import { FC } from "react";

import SortFilter from "web/Components/Common/SortFilter";
import Title from "web/Components/Common/Title";

import { sortOptions } from "web/constants/toolbar";

import classes from "./header.scss";

type SortOption = typeof sortOptions;

export interface IListingDesktopHeader {
  name: string;
  count?: number;
  sortOptions?: SortOption[];
  isEmpty?: boolean;
}

const ListingDesktopHeader: FC<IListingDesktopHeader> = ({
  name,
  count,
  sortOptions,
  isEmpty = false,
}) => {
  return (
    <header className={classes.root}>
      <Title name={name} count={count} />
      {isEmpty ? null : (
        <SortFilter className={classes.sort} options={sortOptions!} darkMode />
      )}
    </header>
  );
};

export default ListingDesktopHeader;
