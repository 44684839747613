import { useEffect, FC } from "react";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";
import { IGtm } from "web/types/Gtm";

interface IGtmEvent {
  eventName?: string;
  options: IGtm;
  withUrl?: boolean;
  isReady?: boolean;
}

const GtmEvent: FC<IGtmEvent> = ({ eventName = "virtualPageView", options = {}, withUrl = false, isReady = true }) => {
  const location = useLocation();

  useEffect(() => {
    const url = withUrl ? { url: location?.pathname + location?.search } : {};

    if (isReady) {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          ...url,
          ...options,
        },
      });
    }
  }, [isReady]);
  return null;
};

export default GtmEvent;
