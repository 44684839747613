import { DocumentNode, print } from "graphql";

import deleteGraphqlExtraSpaces from "web/utils/system/query/deleteGraphqlExtraSpaces";

export interface IConvertGraphqlDocumentToGetStringOptions {
  variables: {
    url: string;
    storeId: number;
  };
}

const convertGraphqlDocumentToGetString = (
  query: DocumentNode,
  options: IConvertGraphqlDocumentToGetStringOptions
) => {
  const queryParsed = deleteGraphqlExtraSpaces(print(query).trim());
  const queryParsedEncoded = encodeURIComponent(queryParsed);
  const optionsParsed =
    options && Object.keys(options)
      ? Object.keys(options).reduce<string>((result, key) => {
          const valueString = JSON.stringify(
            options[key as keyof IConvertGraphqlDocumentToGetStringOptions]
          );
          const valueStringEncoded = encodeURIComponent(valueString);
          return `${result}&${key}=${valueStringEncoded}`;
        }, "")
      : "";

  return `/graphql?query=${queryParsedEncoded}${optionsParsed}`;
};

export default convertGraphqlDocumentToGetString;
