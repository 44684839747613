import { IPromoItems } from "web/types/Gtm";

const getListingPromotion = (promoLinks: IPromoItems) => {
  const currentPathname = window.location.pathname;
  const promoLink = promoLinks[currentPathname];
  if (promoLink) {
    return promoLink;
  }

  const dataLayerTemp = window.dataLayer ? [...window.dataLayer].reverse() : [];
  return dataLayerTemp?.find(({ event = "", page_referrer = "", url = "" }) => {
    const urlPathname = url.split("?")[0];
    const pageReferrerPathname = page_referrer.split("?")[0];

    if (
      [
        "productImpression",
        "promotionImpression",
        "gtm.scrollDepth",
        "gtm.load",
        "gtm.dom",
      ].includes(event) ||
      (event === "virtualPageView" && urlPathname === pageReferrerPathname)
    ) {
      return false;
    }
    return true; // if event === "promotionClick", useMemo is promoItem, otherwise useMemo is undefined
  })?.ecommerce?.promoClick?.promotions?.[0] as IPromoItems["key"] || {};
};

export default getListingPromotion;
