const checkIsIOSPlatform = () => {
  const platform = navigator?.userAgentData?.platform || navigator?.platform;
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(platform) ||
    // Mac detection (modern macs return value MacIntel but it might change in future)
    platform.toUpperCase().indexOf("MAC") >= 0 ||
    // iPad on iOS 13 detection
    (navigator?.userAgent?.includes("Mac") && "ontouchend" in document)
  );
};

export default checkIsIOSPlatform;
