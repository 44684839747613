import { FC, SyntheticEvent } from "react";

import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import formatNumber from "web/utils/data/parser/number/formatNumber";
import substractCanceledPayments from "web/utils/substractCanceledPayments";

import type { ISources, OrderStatus } from "web/types/Order";

import { useAppContext } from "web/context/app";

import classes from "./paymentSummary.scss";

export interface IPaymentSummaryProps {
  total: number;
  paid: number;
  sources?: ISources[];
  setSourcesOpen: () => unknown;
  orderState: OrderStatus;
}

const PaymentSummary: FC<IPaymentSummaryProps> = ({
  total,
  paid,
  sources = [],
  setSourcesOpen = () => {},
  orderState,
}) => {
  const { isMobile, isPrint } = useAppContext();
  const payments = substractCanceledPayments({ sources, total, paid });

  const toSources = (event: SyntheticEvent) => {
    event.preventDefault();
    setSourcesOpen();
    const element = document.getElementById("sources");
    if (element) {
      const yOffset = -150;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scroll({
        top: y,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return [total, paid].every((item) => !Number.isNaN(item)) ? (
    <div className={classes.root}>
      {(!isMobile || isPrint) && (
        <strong className={classes.title} data-t1="payment_summary">
          {__("Podsumowanie płatności")}
        </strong>
      )}
      <div className={classes.sectionTop}>
        {isMobile && !isPrint && (
          <strong className={classes.titleMobile} data-t1="payment_summary">
            {__("Podsumowanie płatności")}
          </strong>
        )}
        <div className={classes.itemTop}>
          <strong
            className={classes.itemTitle}
            data-t1="payment_summary_item_title"
          >
            {__("Wartość pobytu")}
          </strong>
          <span
            className={classes.itemValue}
            data-t1="payment_summary_item_value"
            data-t2={formatNumber(total)}
          >
            {formatNumber(total)}
          </span>
        </div>
      </div>
      <div className={classes.sectionBottom}>
        {isMobile && (
          <strong className={classes.titleMobile} data-t1="payment_summary">
            {__("Podsumowanie płatności")}
          </strong>
        )}
        <div className={classes.item}>
          <strong className={classes.itemTitle} data-t1="payment_summary_paid">
            {__("Zapłacono")}
          </strong>
          <div className={classes.itemValue}>
            <span
              className={classes.itemValue}
              data-t1="payment_summary_paid_amount"
              data-t2={payments.successfullyPaid}
            >
              {payments.successfullyPaid}
            </span>
            {!!sources.length && (payments.successfullyPaid as number) > 0 && (
              <button
                type="button"
                className={classes.checkButton}
                onClick={toSources}
              >
                {__("Sprawdź źródła finansowania")}
              </button>
            )}
          </div>

          {!!sources.length &&
            sources.map((source) => {
              return source.status === "success" && +source.value > 0 ? (
                <div
                  className={classes.source}
                  key={source.code + source.date}
                  data-t1="payment_summary_source"
                >
                  <span
                    data-t1="payment_summary_source_value"
                    data-t2={formatNumber(source.value)}
                  >
                    {formatNumber(source.value)}
                  </span>
                  <span
                    data-t1="payment_summary_source_date"
                    data-t2={`${formatDate(source.date)}`}
                  >{`${__("Wpłata")} ${formatDate(source.date)}`}</span>
                </div>
              ) : null;
            })}
        </div>
        <div className={classes.item}>
          <strong
            className={classes.itemTitle}
            data-t1="payment_summary_item_title_2"
          >
            {__("Pozostało do zapłaty")}
            <span className={classes.star}>*</span>
          </strong>
          <span
            className={classes.itemValue}
            data-t1="payment_summary_to_pay"
            data-t2={`${
              orderState && orderState !== "paid"
                ? "0,00"
                : payments.remainsToPay
            }`}
          >
            {orderState && orderState !== "paid"
              ? "0,00"
              : payments.remainsToPay}
          </span>
        </div>
        <div className={classes.item}>
          <strong
            className={classes.itemTitle}
            data-t1="payment_summary_item_title"
          >
            {__("Zwrócono")}
            <span className={classes.star}>**</span>
          </strong>
          <div className={classes.itemValue}>
            <span
              className={classes.itemValue}
              data-t1="payment_summary_item_value"
              data-t2={formatNumber(total)}
            >
              {formatNumber(payments.returnedPayment)}
            </span>
            {!!sources.length && payments.returnedPayment > 0 && (
              <button
                type="button"
                className={classes.checkButton}
                onClick={toSources}
              >
                {__("Sprawdź źródła finansowania")}
              </button>
            )}
          </div>
          {!!sources.length &&
            sources.map((source) => {
              return (source.status === "success" && +source.value < 0) ||
                source.status === "returned" ? (
                <div
                  className={classes.source}
                  key={source.code + source.date}
                  data-t1="payment_summary_source"
                >
                  <span
                    data-t1="payment_summary_source_value"
                    data-t2={formatNumber(source.value)}
                  >
                    {formatNumber(Math.abs(+source.value))}
                  </span>
                  <span
                    data-t1="payment_summary_source_date"
                    data-t2={`${formatDate(source.date)}`}
                  >{`${__("Zwrot")} ${formatDate(source.date)}`}</span>
                </div>
              ) : null;
            })}
        </div>
        {isMobile && !isPrint && (
          <p
            className={classes.descMobile}
            data-t1="payment_summary_description"
          >
            <span className={classes.star}>*</span>
            {__(
              '"Pozostało do zapłaty" to kwota, którą należy wpłacić zgodnie z polityką anulacji danego obiektu.'
            )}
            <br />
            <span className={classes.star}>**</span>
            {__(
              '"Zwrócono" to kwota jaka wynika z polityki anulacji danego obiektu'
            )}
          </p>
        )}
      </div>
      {(!isMobile || isPrint) && (
        <div className={classes.descContainer}>
          <p className={classes.desc} data-t1="payment_summary_description">
            <span className={classes.star}>*</span>
            {__(
              '"Pozostało do zapłaty" to kwota, którą należy wpłacić zgodnie z polityką anulacji danego obiektu.'
            )}
            <br />
            <span className={classes.star}>**</span>
            {__(
              '"Zwrócono" to kwota jaka wynika z polityki anulacji danego obiektu'
            )}
          </p>
        </div>
      )}
    </div>
  ) : null;
};

export default PaymentSummary;
