import { FC, useEffect, useState } from "react";

import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { useGoogleContext } from "web/context/google.context";

import classes from "./mapModal.scss";

interface ITitleProps {
  placeId: string;
}

const Title: FC<ITitleProps> = ({ placeId = "" }) => {
  const { geocoderService } = useGoogleContext();
  const [place, setPlace] = useState("");
  const text = place
    ? `${__("Mapa noclegów w")}: ${place}`
    : `${__("Mapa noclegów")}:`;
  useEffect(() => {
    if (placeId) {
      geocoderService?.geocode(
        {
          placeId,
        },
        (result, status) => {
          if (
            status === "OK" &&
            isArrayHasItems(result) &&
            result[0] &&
            result[0].formatted_address
          ) {
            setPlace(result[0].formatted_address);
          }
        }
      );
    }
  }, [placeId, setPlace]);
  return <strong className={classes.title}>{text}</strong>;
};

export default Title;
