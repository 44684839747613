import { FC } from "react";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";

import type { ICategory } from "web/types/Category";

import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";

import Categories from "./categories";
import CategoriesWithSubscriptions from "./categoriesSubscriptions";

interface ISearchAutocompleteCategoresContainerProps {
  ids: number[];
  searchPhrase: string;
  productIds: number[];
  isSubscriptions: boolean;
  closeAction: () => void;
}

const SearchAutocompleteCategoriesContainer: FC<
  ISearchAutocompleteCategoresContainerProps
> = ({ ids = [], searchPhrase, closeAction, productIds, isSubscriptions }) => {
  const { loading, error, data } = useDataCachedCategories({
    ids,
  }) as { loading: boolean; error: unknown; data: ICategory[] };

  switch (true) {
    case !!loading: {
      return <Loading />;
    }
    case !!error: {
      return <ErrorComponent />;
    }
    default: {
      return !isSubscriptions ? (
        <Categories
          categories={data}
          searchPhrase={searchPhrase}
          closeAction={closeAction}
        />
      ) : (
        <CategoriesWithSubscriptions
          categories={data}
          productIds={productIds}
          searchPhrase={searchPhrase}
          closeAction={closeAction}
        />
      );
    }
  }
};

export default SearchAutocompleteCategoriesContainer;
