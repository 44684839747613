/* eslint-disable react/no-array-index-key */
import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./headerMenu.scss";

const SkeletonHeaderMenu: FC<PropsWithClasses> = ({ classes = {} }) => {
  return (
    <div className={classes.list}>
      {Array(6)
        .fill(undefined)
        .map((item, index) => (
          <Skeleton className={classes.item} key={index} />
        ))}
    </div>
  );
};

export default classify<PropsWithClasses>(defaultClasses)(SkeletonHeaderMenu);
