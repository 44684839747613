/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable react/prop-types */
import { Loader } from "@googlemaps/js-api-loader";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import Product from "web/Layout/ProductCard";

import customIcon from "web/assets/images/tourist-pin.png";

import listingProductsDomesticTourismByIds from "web/queries/ms/listingProductsDomesticTourismByIds.graphql";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";
import useQueryWrapper from "web/hooks/useQueryWrapper";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import getAdditionalFilterParametersUrl from "web/utils/system/url/getAdditionalFilterParametersUrl";

import type {
  DomesticTourismPins,
  DomesticTourismProductInList,
} from "web/types/Product";
import type { ITourismParams } from "web/types/Tourism";
import { Nullable } from "web/types/Utils";

import { useAppContext } from "web/context/app";

import classes from "./mapModal.scss";
import Title from "./title";

interface ICustomProductProps {
  productId: number;
  token: string;
  storeId: number;
}

const CustomProduct: FC<ICustomProductProps> = ({
  productId,
  token,
  storeId,
}) => {
  const { search } = useLocation();
  const additionalParameters = getAdditionalFilterParametersUrl(search);
  const params = (getAdditionalFilterParametersUrl(search, false, false) ||
    {}) as ITourismParams;
  const { error, loading, data } = useQueryWrapper<{
    msProducts: { edges: { node: DomesticTourismProductInList }[] };
  }>(listingProductsDomesticTourismByIds, {
    variables: {
      token,
      storeId,
      ids: [productId],
      ...(additionalParameters ? { additionalParameters } : {}),
    },
    context: {
      clientName: "ms",
    },
    fetchPolicy: "cache-and-network",
  });

  switch (true) {
    case !!loading && (!data || !Object.keys(data).length): {
      return <Loading />;
    }
    case !!error: {
      newRelicErrorReport(
        error,
        "web-app/web/Components/Common/Filters/Desktop/Map/MapModal/mapModal.js - 53"
      );
      console.error(error);
      return <ErrorComponentWithContainer />;
    }
    default: {
      return data?.msProducts?.edges[0]?.node ? (
        <Product
          product={data.msProducts.edges[0].node}
          variant="inModal"
          params={params}
        />
      ) : null;
    }
  }
};

interface ICustomInfoWindowProps {
  node: Nullable<HTMLElement>;
  productId: Nullable<number>;
  token: string;
  storeId: number;
}

const CustomInfoWindow: FC<ICustomInfoWindowProps> = ({
  node,
  productId,
  token,
  storeId,
}) => {
  return node && productId
    ? ReactDOM.createPortal(
        <CustomProduct productId={productId} token={token} storeId={storeId} />,
        node
      )
    : null;
};

interface IMapModalProps {
  storeId: number;
  token: string;
  center: {
    lat: number;
    lng: number;
  };
  products: (DomesticTourismPins & { price: string })[];
  googleApiKey: string;
  placeId?: string;
}

const MapModal: FC<IMapModalProps> = ({
  storeId,
  token,
  center,
  products,
  googleApiKey,
  placeId = "",
}) => {
  const [infoWindowID, setId] = useState<Nullable<HTMLElement>>(null);
  const [productId, setProductId] = useState<Nullable<number>>(null);
  const mapRef = useRef<HTMLElement>();
  const isMouseOnWindowInfo = useRef(false);
  const isMouseOnMarker = useRef(false);
  const history = useHistory();
  const { modal } = useAppContext();
  const { dispatch } = modal;

  const closeModal = useCallback(() => {
    dispatch({ type: ModalActionTypes.RESET });
  }, [dispatch]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: googleApiKey,
      version: "weekly",
      libraries: ["places"],
    });

    loader
      .load()
      .then(() => {
        // eslint-disable-next-line no-undef
        const map = new google.maps.Map(mapRef.current as HTMLElement, {
          center,
          zoom: 7,
        });

        let lastOpenedInfoWindow: google.maps.InfoWindow;
        const closeLastOpenedInfoWindow = () => {
          if (lastOpenedInfoWindow) {
            lastOpenedInfoWindow.close();
          }
        };

        const markers =
          products?.map((product) => {
            // eslint-disable-next-line no-undef
            const infowindow = new google.maps.InfoWindow();
            infowindow.setOptions({
              disableAutoPan: true,
            });
            const centerPosition = {
              lat: parseFloat(product?.geolocation_data.lat as string),
              lng: parseFloat(product?.geolocation_data.lon as string),
            };

            const marker = new MarkerWithLabel({
              position: centerPosition,
              clickable: true,
              icon: customIcon,
              map,
              // eslint-disable-next-line no-undef
              labelAnchor: new google.maps.Point(0, -35),
              labelContent: product?.price ? `${product.price} zł` : "",
              labelClass: classes.gmLabels,
            });
            const identifier = `${product?.id}-anixe`;
            marker.addListener("click", () => {
              closeLastOpenedInfoWindow();

              isMouseOnMarker.current = true;
              infowindow.setContent(
                `<div class="gmCard"><div id="${identifier}"></div></div>`
              );

              infowindow.open(map, marker);
              lastOpenedInfoWindow = infowindow;
              // eslint-disable-next-line no-undef
              google.maps.event.addListener(infowindow, "domready", () => {
                const itemNode = document.getElementById(identifier)!;

                itemNode.addEventListener("mouseenter", () => {
                  isMouseOnWindowInfo.current = true;
                });
                itemNode.addEventListener("mouseleave", () => {
                  isMouseOnWindowInfo.current = false;
                });
                setProductId(+product?.id);
                setId(itemNode);
              });
            });
            marker.addListener("click", () => {
              map.panTo(centerPosition);
              /**
               * after google maps update, info window containers
               * visibility is set to 'hidden'
               * this is a hack to override this behaviour
               * in future check if still needed
               */
              // eslint-disable-next-line no-undef
              google.maps.event.addListenerOnce(map, "idle", () => {
                const infowindowContainer = document.querySelector(
                  ".gm-style-iw-a"
                ) as HTMLElement;

                if (infowindowContainer) {
                  infowindowContainer.style.visibility = "visible";
                }
              });
            });
            marker.addListener("mouseout", () => {
              isMouseOnMarker.current = false;
            });
            // eslint-disable-next-line no-undef
            google.maps.event.addListener(map, "click", () => {
              if (!isMouseOnWindowInfo.current) {
                closeLastOpenedInfoWindow();
              }
            });
            return marker;
          }) || [];
        // eslint-disable-next-line no-new
        new MarkerClusterer(map, markers, {
          imagePath: "/public/icons/m",
        });
      })
      .catch((errorData) => {
        newRelicErrorReport(
          errorData,
          "web-app/web/Components/Common/Filters/Desktop/Map/MapModal/mapModal.js - 213"
        );
        console.error(errorData);
      });
  }, []);

  useEffect(() => {
    return history.listen(() => {
      closeModal();
    });
  }, [history]);

  return (
    <div className={classes.body}>
      <Title placeId={placeId} />
      <div className={classes.map_wrapper}>
        {/* @ts-ignore */}
        <div ref={mapRef} className={classes.map_container} />
        <CustomInfoWindow
          node={infoWindowID}
          productId={productId}
          token={token}
          storeId={storeId}
        />
      </div>
    </div>
  );
};

export default MapModal;
