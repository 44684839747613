import ResponseError from "web/api/responseError";

import { getToken } from "web/constants/urls";

const isGetToken401 = (error: ResponseError) => {
  return !!(
    (error?.response?.url as string)?.includes(getToken) &&
    error?.response?.status === 401
  );
};

export default isGetToken401;
