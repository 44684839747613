const polishSigns = "ĄąĆćDdĘęŁłŃńÓóŚśŹźŻż";

const removeNonUTF8Chars = (input: string) => {
  let output = "";
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) <= 127 || polishSigns.includes(input[i])) {
      output += input.charAt(i);
    }
  }
  return output;
};

export default removeNonUTF8Chars;
