const convertObjArrayToMap = <T extends object, K extends keyof T>(
  items: T[],
  identifier: K
): Map<T[K], T> => {
  return items.reduce((map, item) => {
    const key = item[identifier];
    map.set(key as T[K], item);
    return map;
  }, new Map<T[K], T>());
};

export default convertObjArrayToMap;
