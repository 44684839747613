import { AnimatePresence, motion } from "framer-motion";
import { ElementType, FC, MutableRefObject } from "react";

import __ from "web/Layout/Translations";

import { PropsWithClasses } from "web/types/Common";
import type { Nullable } from "web/types/Utils";

import classify from "web/classify";

import defaultStyles from "./modal.scss";

const [initial, animate, exit] = [
  { opacity: 0, y: 200 },
  { opacity: 1, y: 0 },
  { opacity: 0, y: -200 },
];

interface IMobileModalProps {
  component?: ElementType;
  closeAction?: () => void;
  modalRef: MutableRefObject<Nullable<HTMLDivElement>>;
}

const MobileModal: FC<PropsWithClasses<IMobileModalProps>> = ({
  classes = {},
  component: Component = null,
  closeAction = () => {},
  modalRef,
}) => {
  const title = __("Zamknąć");
  return Component ? (
    <AnimatePresence>
      <motion.div
        className={classes.root}
        initial={initial}
        animate={animate}
        exit={exit}
        ref={modalRef}
      >
        {typeof Component === "function" ? (
          <Component closeAction={closeAction} />
        ) : (
          Component
        )}
        <button
          type="button"
          className={classes.closeButton}
          onClick={closeAction}
          title={title}
        >
          <span className="visually-hidden">{title}</span>
        </button>
      </motion.div>
    </AnimatePresence>
  ) : null;
};

export default classify<PropsWithClasses<IMobileModalProps>>(defaultStyles)(
  MobileModal
);
