import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import __ from "web/Layout/Translations";

import classes from "./notification.scss";

interface NotificationProps {
  type: "success" | "warning" | "error";
  id: number;
  message?: string;
  remove: (id: number) => void;
  timeout?: number;
}

const defaultCommunicates = {
  success: "Operacja wykonana poprawnie.",
  warning: "Operacja mogła nie zostać wykonana poprawnie.",
  error: "Błąd podczas wczytywania. Spróbuj jeszcze raz.",
};

const HIDE_TIMEOUT = 3000;

const Notification: React.FC<NotificationProps> = ({
  type,
  id,
  message,
  remove,
  timeout,
}) => {
  const dispatch = useDispatch();
  const setTimeoutIdRef = useRef<number | null>(null);

  const removeAction = useCallback(() => {
    dispatch(remove(id));
  }, [dispatch, id, remove]);

  useEffect(() => {
    if (setTimeoutIdRef.current) {
      clearTimeout(setTimeoutIdRef.current);
    }
    setTimeoutIdRef.current = window.setTimeout(() => {
      dispatch(remove(id));
    }, timeout ?? HIDE_TIMEOUT);

    return () => {
      if (setTimeoutIdRef.current) {
        clearTimeout(setTimeoutIdRef.current);
      }
    };
  }, [dispatch, id, remove, timeout]);

  let classNameRoot;

  switch (type) {
    case "success":
      classNameRoot = classes.rootSuccess;
      break;
    case "warning":
      classNameRoot = classes.rootWarning;
      break;
    default:
      classNameRoot = classes.rootError;
      break;
  }

  const localMessage = message || defaultCommunicates[type];

  return (
    <div className={classes.container} data-t1="notification" data-t2={type}>
      <button
        className={classNameRoot}
        type="button"
        onClick={removeAction}
        data-t1="closeNotificationButton"
        data-testid="closeNotificationButton"
      >
        <figure className={classes.buttonCircle} />
        <span
          className={classes.buttonText}
          data-t1="notificationValue"
          data-t2={__(localMessage)}
        >
          {__(localMessage)}
        </span>
        <span className={classes.buttonClose}>✕</span>
      </button>
    </div>
  );
};

export default Notification;
