/* eslint-disable react/no-array-index-key */
import { FieldArray, useFormikContext } from "formik";

import __ from "web/Layout/Translations";

import RentableGroupCalculatorAddPerson from "../RentableGroupCalculatorAddPerson";
import RentableGroupCalculatorPerson from "../RentableGroupCalculatorPerson";
import rentableGroupCalculatorCopy from "../const/rentableGroupCalculatorCopy";
import { IRentableGroupCalculatorValues } from "../rentableGroupCalculatorContainer";
import getNewPersonField from "../utils/getNewPersonField";
import classes from "./rentableGroupCalculator.scss";

const RentableGroupCalculator = () => {
  const { values } = useFormikContext<IRentableGroupCalculatorValues>();

  return (
    <section className={`${classes.root} rg-calculator`}>
      <h3 className={classes.header}>
        {__(rentableGroupCalculatorCopy.calculatorHeader)}
      </h3>

      <div className={classes.innerWrapper}>
        {/* me */}
        <div>
          <RentableGroupCalculatorPerson
            personRole="me"
            selectName="me"
            inputName="income.me"
            isSelectDisabled
          />
        </div>

        {/* people */}
        <FieldArray name="income.people">
          {({ push, remove }) => (
            <>
              {(
                values as {
                  income: { people: { role: string; income: number }[] };
                }
              ).income.people?.map((person, index) => (
                <RentableGroupCalculatorPerson
                  key={person.role + index}
                  personRole={person.role}
                  onRemove={() => remove(index)}
                  selectName={`income.people[${index}].role`}
                  inputName={`income.people[${index}].income`}
                />
              ))}

              {/* add person */}
              <div className={classes.addPersonBtnContainer}>
                <RentableGroupCalculatorAddPerson
                  onClick={() =>
                    push(getNewPersonField(values.income.people.length))
                  }
                />
              </div>
            </>
          )}
        </FieldArray>
      </div>
    </section>
  );
};
export default RentableGroupCalculator;
