import { FC } from "react";

const NO_PRICE_PLACEHOLDER = "-";

interface INoPricePlaceholderProps {
  dataT1: string;
  alternativePlaceholder: string;
}

const NoPricePlaceholder: FC<INoPricePlaceholderProps> = ({
  dataT1,
  alternativePlaceholder = "",
}) => {
  return (
    <strong data-t1={`${dataT1}_value`} data-t2={NO_PRICE_PLACEHOLDER}>
      {alternativePlaceholder || NO_PRICE_PLACEHOLDER}
    </strong>
  );
};

export default NoPricePlaceholder;
