import type { IProductOptions } from "web/types/ProductOptions";
import type { Nullable } from "web/types/Utils";

import getDiscount from "./getDiscount";

interface IGetPriceFinalPriceDiscountArgs {
  options: IProductOptions;
  price: number;
  finalPrice: number;
}

interface IOptionsWithMinimalPrice {
  price: Nullable<number>;
  finalPrice: Nullable<number>;
}

export default ({
  options,
  price,
  finalPrice,
}: IGetPriceFinalPriceDiscountArgs) => {
  if (
    options &&
    options.configurable_options &&
    options.configurable_options.price &&
    Object.keys(options.configurable_options.price).length
  ) {
    const optionsWithMinimalPrice = Object.keys(
      options.configurable_options.price
    ).reduce<IOptionsWithMinimalPrice>(
      (result, key) => {
        const currentOption = options.configurable_options.price[key];
        return currentOption &&
          !options?.configurable_options?.to_delete?.includes(key) &&
          !options?.configurable_options?.disabled?.includes(key) &&
          (typeof result.finalPrice !== "number" ||
            +currentOption.final_price < result.finalPrice)
          ? {
              price: +currentOption.price,
              finalPrice: +currentOption.final_price,
            }
          : result;
      },
      { price: null, finalPrice: null }
    );

    return {
      price: optionsWithMinimalPrice.price,
      finalPrice: optionsWithMinimalPrice.finalPrice,
      discount: getDiscount(
        optionsWithMinimalPrice.price as number,
        optionsWithMinimalPrice.finalPrice as number
      ),
    };
  }

  return {
    price: +price,
    finalPrice: +finalPrice,
    discount: getDiscount(price, finalPrice),
  };
};
