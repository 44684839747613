/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useReducer } from "react";
import ReactSlider from "react-slider";

import __ from "web/Layout/Translations";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./price.scss";

interface IPriceProps {
  min: number;
  max: number;
  from: number;
  to: number;
  onChange: (values: number[]) => void;
  fromPriceParam: string;
  toPriceParam: string;
}

enum PriceReducerActionTypes {
  FROM = "from",
  TO = "to",
  RANGE = "range",
}

interface IPriceReducerAction<
  T extends PriceReducerActionTypes = PriceReducerActionTypes
> {
  type: PriceReducerActionTypes;
  payload: T extends PriceReducerActionTypes.RANGE ? [number, number] : number;
}

interface IPriceReducerState {
  currentFrom: number;
  currentTo: number;
}

const reducer = (
  state: IPriceReducerState,
  action: IPriceReducerAction
): IPriceReducerState => {
  switch (action.type) {
    case PriceReducerActionTypes.RANGE:
      return {
        currentFrom: (
          action as IPriceReducerAction<PriceReducerActionTypes.RANGE>
        ).payload[0],
        currentTo: (
          action as IPriceReducerAction<PriceReducerActionTypes.RANGE>
        ).payload[1],
      };
    case PriceReducerActionTypes.FROM: {
      return {
        currentFrom: (
          action as IPriceReducerAction<PriceReducerActionTypes.FROM>
        ).payload,
        currentTo: state.currentTo,
      };
    }
    case PriceReducerActionTypes.TO: {
      return {
        currentFrom: state.currentFrom,
        currentTo: (action as IPriceReducerAction<PriceReducerActionTypes.TO>)
          .payload,
      };
    }
    default:
      return state;
  }
};

const Price: FC<PropsWithClasses<IPriceProps>> = ({
  min,
  max,
  classes = {},
  from,
  to,
  onChange = () => {},
  fromPriceParam = null,
  toPriceParam = null,
}) => {
  const initialState: IPriceReducerState = {
    currentFrom: from,
    currentTo: to,
  };
  const [{ currentFrom, currentTo }, dispatch] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    switch (true) {
      case !fromPriceParam && !toPriceParam: {
        dispatch({ type: PriceReducerActionTypes.RANGE, payload: [min, max] });
        break;
      }

      case !fromPriceParam: {
        dispatch({ type: PriceReducerActionTypes.FROM, payload: min });
        break;
      }

      case !toPriceParam: {
        dispatch({ type: PriceReducerActionTypes.TO, payload: max });
        break;
      }

      default:
        break;
    }
  }, [fromPriceParam, toPriceParam]);

  useEffect(() => {
    onChange([Math.abs(currentFrom), Math.abs(currentTo)]);
  }, [currentFrom, currentTo]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.item}>
          <input
            className={classes.input}
            type="number"
            data-t1="pice_input_from"
            value={currentFrom}
            placeholder={currentFrom?.toString()}
            min={min}
            max={max}
            onChange={(syntheticEvent) => {
              const nextValue = syntheticEvent?.currentTarget?.value;
              dispatch({
                type: PriceReducerActionTypes.FROM,
                payload: +nextValue,
              });
            }}
          />
        </div>
        <div className={classes.line} />
        <div className={classes.item}>
          <input
            className={classes.input}
            type="number"
            value={currentTo}
            data-t1="pice_input_to"
            max={max}
            min={min}
            onChange={(syntheticEvent) => {
              const nextValue = syntheticEvent?.currentTarget?.value;
              dispatch({
                type: PriceReducerActionTypes.TO,
                payload: +nextValue,
              });
            }}
          />
        </div>
        <div className={classes.currency}>{__("zł")}</div>
      </div>
      <ReactSlider
        className={classes.range}
        min={min}
        max={max}
        step={1}
        // @ts-ignore
        dataT1="price_slider"
        pearling
        value={[currentFrom, currentTo]}
        minDistance={1}
        onChange={(sliderValues: [number, number]) => {
          dispatch({
            type: PriceReducerActionTypes.RANGE,
            payload: sliderValues,
          });
        }}
        ariaLabel={["Price slider"]}
      />
    </div>
  );
};

export default classify<PropsWithClasses<IPriceProps>>(defaultClasses)(Price);
