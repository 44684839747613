interface IRTKQueryError {
  status: number;
  data: {
    message: string;
  };
}

const getNewRelicErrorMessage = (error: unknown) => {
  const messages: string[] = [];

  if ((error as Error)?.message) {
    messages.push((error as Error).message);
  }

  if ((error as IRTKQueryError)?.status) {
    messages.push(`status code: ${(error as IRTKQueryError).status}`);
  }

  if ((error as IRTKQueryError)?.data?.message) {
    messages.push((error as IRTKQueryError).data.message);
  }

  return messages.join(", ");
};

export default getNewRelicErrorMessage;
