import { useFormikContext } from "formik";
import { motion } from "framer-motion";
import { FC, useCallback } from "react";

import ArrowUpIcon from "web/assets/icons/arrow-up.svg";

import useDropdown from "web/hooks/useDropdown";

import Dropdown from "./Dropdown";
import classes from "./distance.scss";

interface IDistanceProps {
  name: string;
  options: {
    value: string;
    label: string;
  }[];
}

const dropdownVariants = {
  open: {
    height: "auto",
  },
  closed: {
    height: 0,
  },
};
const transition = { duration: 0.25 };

const Distance: FC<IDistanceProps> = ({ name, options }) => {
  const { values, setFieldValue } = useFormikContext<Record<string, string>>();
  const [isOpen, setIsOpen] = useDropdown({
    scopeSelector: `.${classes.root}`,
  });
  const valueCurrent = values && values[name];
  const optionCurrent = valueCurrent
    ? options.find((optionItem) => optionItem.value === valueCurrent)
    : null;
  const labelCurrent = optionCurrent ? optionCurrent.label : null;
  const classNameWrapper = isOpen ? classes.wrapperActive : classes.wrapper;
  const changeHandler = useCallback(
    (valueChosen: string) => {
      setFieldValue(name, valueChosen);
      setIsOpen((status) => !status);
    },
    [setIsOpen, setFieldValue, name]
  );

  return (
    <div className={classes.root}>
      <div className={classNameWrapper}>
        <button
          type="button"
          className={classes.trigger}
          onClick={() => setIsOpen((state) => !state)}
          data-t1="distance_trigger_button"
        >
          {labelCurrent} <ArrowUpIcon width={10} className={classes.arrow} />
        </button>
        <div className={classes.body} data-t1="distance_trigger_dropdown">
          <motion.div
            className={classes.dropdown}
            animate={isOpen ? "open" : "closed"}
            transition={transition}
            variants={dropdownVariants}
          >
            <Dropdown
              options={options}
              onChange={changeHandler}
              value={valueCurrent}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Distance;
