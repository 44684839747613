import type { IGetEmployeeRentableGroupAdditionalData } from "web/types/Employee";

const getInitialCalculatorValues = (
  additionalData: IGetEmployeeRentableGroupAdditionalData
) => {
    let myIncome = 0;
    const othersIncome = additionalData?.income?.filter(({ role, income }) => {
      if (role !== "me") return true;

      myIncome = income;
      return false;
    });
    const agreements = additionalData?.agreements?.reduce((acc, { id, value }) => {
      return { ...acc, [id]: value };
    }, {});

    const initialValues = {
      income: {
        me: myIncome,
        people: othersIncome || [],
      },
      "fill-calculator-checkbox": additionalData.fillCalculatorCheckbox || false,
      "financing-checkbox": additionalData.financingCheckbox || false,
      agreements: agreements || {},
    };
    return initialValues;
};

export default getInitialCalculatorValues;
