import TagManager from "react-gtm-module";

const sendGtmEvent = (
  eventName: string,
  options: Record<PropertyKey, unknown>,
  isReady = true
) => {
  if (isReady) {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...options,
      },
    });
  }
  return null;
};

export default sendGtmEvent;
