import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import type { Nullable } from "web/types/Utils";

import isArrayHasItems from "../../validator/array/isArrayHasItems";

const jsonParse = <T>(
  value?: Nullable<string> | string[],
  shouldBeArray = false
): T => {
  const fallbackValue = shouldBeArray ? [] : {};
  try {
    return value
      ? JSON.parse(isArrayHasItems(value) ? value[0] : value)
      : fallbackValue;
  } catch (error) {
    newRelicErrorReport(
      error,
      "web-app/web/utils/data/parser/string/jsonParse.js - 7"
    );
    console.error(error);
    return fallbackValue as T;
  }
};

export default jsonParse;
