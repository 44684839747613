import { FC, memo } from "react";

import __ from "web/Layout/Translations";

import SquarePlus from "web/assets/icons/square-plus.svg";

import rentableGroupCalculatorCopy from "../const/rentableGroupCalculatorCopy";
import classes from "./rentableGroupCalculatorAddPerson.scss";

const { addPerson } = rentableGroupCalculatorCopy;

interface IRentableGroupCalculatorAddPersonProps {
  onClick: () => void;
}

const RentableGroupCalculatorAddPerson: FC<IRentableGroupCalculatorAddPersonProps> =
  memo(({ onClick }) => {
    return (
      <button type="button" onClick={onClick} className={classes.addPersonBtn}>
        <SquarePlus width={15} />
        <span className={classes.btnText}>{__(addPerson)}</span>
      </button>
    );
  });
export default RentableGroupCalculatorAddPerson;
