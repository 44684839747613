/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";
import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import useOnProductClikCallback from "web/hooks/useOnProductClikCallback";
import { IProduct } from "web/types/Product";
import { ProductCardCarouselProps } from "./container";
import defaultClasses from "./product.scss";

type ProductCardCarouselProductProps = ProductCardCarouselProps & {
  positionNumber?: number;
};

const ProductCardCarouselProduct: FC<
  PropsWithClasses<ProductCardCarouselProductProps>
> = ({
  classes = {},
  product,
  gtmProduct,
  positionNumber,
  listingAmount,
  listingPosition,
  listingCategory = "",
}) => {
  if (!product) {
    return null;
  }
  // @ts-ignore
  const { url, name, image, price, finalPrice, currency, options } = product;
  const { search } = useLocation();

  const productTyped = gtmProduct as IProduct<unknown>;
  const [sendProductClickEvent] = useOnProductClikCallback(
    productTyped,
    positionNumber,
    "",
    listingPosition,
    listingAmount,
    listingCategory
  );

  const isConfigurable = isArrayHasItems(
    options?.configurable_options?.attributes
  );

  const convertName = () => {
    if (!name) {
      return "";
    }
    const splittedName = __(name).split(" ");
    const convertedName = splittedName.map((item) => {
      if (item.length > 20 && item.includes(".")) {
        return item.replace(".", " .");
      }
      return item;
    });

    return convertedName.toString().replaceAll(",", " ");
  };

  const setPositionNumber = () => {
    const newParams = new URLSearchParams(search);

    newParams.set("position_id", positionNumber?.toString() || "");
    newParams.set("listing_amount", listingAmount?.toString() || "");
    newParams.set("listing_position", listingPosition?.toString() || "");
    newParams.set("listing_category", listingCategory?.toString() || "");

    return newParams.toString();
  };

  const urlSearchParams = setPositionNumber();
  const redirectTo = {
    pathname: url,
    search: urlSearchParams,
  };

  const priceClassName =
    finalPrice < price ? classes.priceDiscount : classes.price;
  return (
    <article className={classes.root}>
      <div className={classes.box}>
        <Link to={redirectTo} className={classes.imgLink} onClick={sendProductClickEvent}>
          <LazyLoadImage
            src={image}
            alt={name}
            className={classes.img}
            isProduct
            width={280}
            height={187}
          />
        </Link>
        {!!price && (
          <Price
            className={priceClassName}
            currency={currency}
            value={finalPrice}
            from={isConfigurable}
          />
        )}
      </div>
      <h3 className={classes.title}>
        <Link to={redirectTo} className={classes.link} onClick={sendProductClickEvent}>
          {convertName()}
        </Link>
      </h3>
    </article>
  );
};

export default classify<PropsWithClasses<ProductCardCarouselProductProps>>(
  defaultClasses
)(ProductCardCarouselProduct);
