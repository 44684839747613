export const NAMES = {
  ROOM: "room",
  NIGHT: "night",
} as const;

const forms = {
  room: ["pokój", "pokoje", "pokoi"],
  night: ["noc", "noce", "nocy"],
};

export const getNameByCount = (count: number, name: keyof typeof forms) => {
  let index = 2;
  if (count === 1) index = 0;
  if (count > 1 && count < 5) index = 1;

  return forms[name][index];
};
