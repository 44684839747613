// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-root-C4u{z-index:199;display:block;position:fixed;top:0;left:0;right:0;bottom:0;overflow-x:hidden;overflow-y:auto;outline:0;padding-left:15px;padding-right:15px}.modal-content-b4j{position:relative;z-index:2;max-width:1100px;margin:60px auto}.modal-closeButton-TGl{z-index:4;position:absolute;top:25px;right:25px;border:none;background:rgba(0,0,0,0)}.modal-closeIcon-nEX{width:22px}@media print{.modal-root-C4u{top:0;left:0;width:100vw;height:auto;position:absolute}.modal-closeButton-TGl{display:none}}`, "",{"version":3,"sources":["webpack://./web/Layout/Modal/Desktop/modal.scss"],"names":[],"mappings":"AAIuB,gBACrB,WAAA,CACA,aAAA,CACA,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,iBAAA,CACA,eAAA,CACA,SAAA,CACA,iBAAA,CACA,kBAAA,CAGF,mBACE,iBAAA,CACA,SAAA,CACA,gBAAA,CACA,gBAAA,CAGF,uBACE,SAAA,CACA,iBAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,wBAAA,CAGF,qBACE,UAAA,CAGF,aACE,gBACE,KAAA,CACA,MAAA,CACA,WAAA,CACA,WAAA,CACA,iBAAA,CAGF,uBACE,YAAA,CAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  z-index: 199;\n  display: block;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  overflow-x: hidden;\n  overflow-y: auto;\n  outline: 0;\n  padding-left: 15px;\n  padding-right: 15px;\n}\n\n.content {\n  position: relative;\n  z-index: 2;\n  max-width: 1100px;\n  margin: 60px auto;\n}\n\n.closeButton {\n  z-index: 4;\n  position: absolute;\n  top: 25px;\n  right: 25px;\n  border: none;\n  background: transparent;\n}\n\n.closeIcon {\n  width: 22px;\n}\n\n@media print {\n  .root {\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: auto;\n    position: absolute;\n  }\n\n  .closeButton {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `modal-root-C4u`,
	"content": `modal-content-b4j`,
	"closeButton": `modal-closeButton-TGl`,
	"closeIcon": `modal-closeIcon-nEX`
};
export default ___CSS_LOADER_EXPORT___;
