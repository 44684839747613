// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.external-mobileAppRedirectionInfo-UY2{display:flex;justify-content:center;align-items:center;height:100%}`, "",{"version":3,"sources":["webpack://./web/Layout/External/external.scss"],"names":[],"mappings":"AAIuB,uCACrB,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .mobileAppRedirectionInfo {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileAppRedirectionInfo": `external-mobileAppRedirectionInfo-UY2`
};
export default ___CSS_LOADER_EXPORT___;
