import { FC, PropsWithChildren } from "react";
import { useHistory, useLocation } from "react-router-dom";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import urlSearchParamsToolbarFiltered from "web/utils/page/product/universal/urlSearchParamsToolbarFiltered";

interface IResetButtonProps {
  text?: string;
  dataT1: string;
  className: string;
  facetsHidden?: string[];
}

const ResetButton: FC<PropsWithChildren<IResetButtonProps>> = ({
  text = "Resetuj ustawienia",
  className,
  children = null,
  dataT1,
  facetsHidden = [],
}) => {
  const { search, pathname } = useLocation();
  const searchFiltered = urlSearchParamsToolbarFiltered(search);
  const searchFilteredKeys = searchFiltered && Object.keys(searchFiltered);
  const searchFilteredKeysWithoutHidden =
    isArrayHasItems(searchFilteredKeys) && isArrayHasItems(facetsHidden)
      ? searchFilteredKeys.filter((key) => facetsHidden.indexOf(key) === -1)
      : [];
  const { push } = useHistory();
  const resetHandler = () => {
    const params = new URLSearchParams(search);
    searchFilteredKeysWithoutHidden.forEach((key) => {
      params.delete(key);
    });
    push(`${pathname}?${params.toString()}`);
  };

  return searchFilteredKeysWithoutHidden.length ? (
    <button
      className={className}
      type="button"
      onClick={resetHandler}
      title={text}
      data-t1={dataT1}
    >
      {children || text}
    </button>
  ) : null;
};

export default ResetButton;
