import {
  isDefaultLanguage,
  previewTranslations,
} from "web/Layout/Translations/LanguageWrapper";
import { fetchSimpleTranslations } from "web/Layout/Translations/helpers";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

window.banksTranslations = { notInitialized: true };

const getTranslation = (phrase) => {
  return window.banksTranslations[phrase];
};

const bankNameScope = "companyManagement:newBank:name";

// eslint-disable-next-line import/prefer-default-export
export const translateBanks = async (banks) => {
  try {
    if (isDefaultLanguage) {
      return banks;
    }
    if (!Array.isArray(banks)) {
      return banks;
    }
    await fetchBanksTranslations();
    return banks.map((el) => ({
      ...el,
      points_bank_name: translateBankValue(el.points_bank_name),
    }));
  } catch (error) {
    newRelicErrorReport(
      error,
      "web-app/web/utils/system/redux/banksTranslations.js - 32"
    );
    console.error(error);
    return banks;
  }
};

const fetchBanksTranslations = async () => {
  try {
    if (!window?.banksTranslations?.notInitialized) {
      return;
    }
    const response = await fetchSimpleTranslations([bankNameScope]);
    window.banksTranslations = {
      ...window.banksTranslations,
      notInitialized: false,
      ...response,
    };
  } catch (error) {
    newRelicErrorReport(
      error,
      "web-app/web/utils/system/redux/banksTranslations.js - 53"
    );
    console.error(error);
    window.banksTranslations = {
      ...window.banksTranslations,
      notInitialized: false,
    };
  }
};

const translateBankValue = (phrase) => {
  const translation = getTranslation(phrase);
  const result = translation || phrase;
  return previewTranslations ? `[B]${result}[/B]` : result;
};
