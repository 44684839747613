import type {
  IRentableGroupCalculatorValues,
  Statements,
} from "../rentableGroupCalculatorContainer";

const shouldShowCalculator = (
  values: IRentableGroupCalculatorValues,
  statements: Statements
) =>
  !statements.some(
    (statement) => values[statement as keyof IRentableGroupCalculatorValues]
  );

export default shouldShowCalculator;
