enum PWAModes {
  FULLSCREEN = "fullscreen",
  STANDALONE = "standalone",
  MINIMAL_UI = "minimal-ui",
}

const checkIfAppIsInPWAMode = () =>
  "serviceWorker" in navigator &&
  "MediaQueryList" in window &&
  window.matchMedia(
    Object.values(PWAModes)
      .map((mode) => `(display-mode: ${mode})`)
      .join(", ")
  ).matches;

export default checkIfAppIsInPWAMode;
