import DOMPurify from "dompurify";
import { FC, PropsWithChildren, ReactElement } from "react";

import Title from "web/Components/Common/Title/title";
import TransparentButton from "web/Components/Common/TransparentButton/transparentButton";

import { Classes } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./subscriptionModal.scss";

interface IModalCloseBtnProps {
  onClick: () => void;
}

interface ISubscriptionModalProps {
  classes?: Classes;
  title?: string;
  description?: string | ReactElement;
  additionalChildren?: ReactElement;
}

export const SubscriptionModalCloseBtn: FC<
  PropsWithChildren<IModalCloseBtnProps>
> = ({ children, onClick, ...restProps }) => (
  <div className={defaultClasses.closeModalBtn} {...restProps}>
    <TransparentButton onClick={onClick}>{children}</TransparentButton>
  </div>
);

const SubscriptionModal: FC<PropsWithChildren<ISubscriptionModalProps>> = ({
  classes = {},
  title,
  description,
  children,
  additionalChildren,
}) => {
  const titleClasses = {
    header: classes?.title || defaultClasses.title,
  };

  return (
    <section className={classes.root}>
      <Title classes={titleClasses} name={title} container="h2" />
      {typeof description === "string" ? (
        <div
          className="cke_editable"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              description.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
            ),
          }}
        />
      ) : (
        description
      )}
      <div className={classes.content}>{children}</div>
      {additionalChildren}
    </section>
  );
};

export default classify<PropsWithChildren<ISubscriptionModalProps>>(
  defaultClasses
)(SubscriptionModal);
