import { Formik } from "formik";
import { FC, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { searchName } from "web/constants/toolbar";
import urls from "web/constants/urls";

import { closeNavigation } from "web/features/app/appSlice";

import Search from "./search";

interface ISearchAutocompleteContainerProps {
  className: string;
  onClick?: () => void;
}

const SearchAutocompleteContainer: FC<ISearchAutocompleteContainerProps> = ({
  className = "",
  onClick = null,
}) => {
  const dispatch = useDispatch();

  const { push } = useHistory();
  const { pathname, search } = useLocation();
  const initialValues = useMemo(() => {
    const params = new URLSearchParams(search);
    const paramsSearch = params.get(searchName);

    return {
      [searchName]:
        pathname === urls.search && paramsSearch ? paramsSearch : "",
    };
  }, []);
  const submitHandler = useCallback(
    (values: { search: string }) => {
      const searchPhrase = values[searchName];
      const searchPhraseEncoded = encodeURIComponent(searchPhrase);
      push(`${urls.search}?${searchName}=${searchPhraseEncoded}`);
      dispatch(closeNavigation());
      if (typeof onClick === "function") {
        onClick();
      }
    },
    [push, onClick]
  );

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Search
        name={searchName}
        className={className}
        pathname={pathname}
        search={search}
        onClick={onClick as () => void}
      />
    </Formik>
  );
};

export default SearchAutocompleteContainer;
