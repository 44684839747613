import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getBenefitKeys from "web/utils/system/essentials/getBenefitKeys";

import {
  ISubscriptionFilterBenefitGroups,
  ISubscriptionItemMs,
} from "web/types/Subscription";

const useBenefitsListingSearch = (
  ids: number[],
  subscriptionItems: ISubscriptionItemMs[]
): {
  loading: boolean;
  idsMapped: number[];
  idsValidSubscriptions: number[];
  idsWithoutSubscription: number[];
  skuFilteredBenefits: string[];
  dataFiltered: ISubscriptionFilterBenefitGroups;
} => {
  const [idsMapped, setIdsMapped] = useState<number[]>([]);
  const [idsValidSubscriptions, setIdsValidSubscriptions] = useState<number[]>(
    []
  );
  const [idsWithoutSubscription, setIdsWithoutSubscriptions] = useState<
    number[]
  >([]);

  const dataFiltered = useSelector((state) => state.benefitGroups);

  const skuFilteredBenefits = getBenefitKeys(dataFiltered ?? null);

  useEffect(() => {
    if (!isArrayHasItems(subscriptionItems)) {
      return;
    }

    if (
      isArrayHasItems(subscriptionItems) &&
      isArrayHasItems(skuFilteredBenefits)
    ) {
      const idsWithoutSubscriptions = ids?.filter((id) => {
        const subItem = subscriptionItems.find((el) => +el.id === id);
        return !subItem;
      });
      setIdsWithoutSubscriptions(idsWithoutSubscriptions);

      const validSubIds = subscriptionItems?.reduce(
        (res: number[], curr: ISubscriptionItemMs) => {
          if (skuFilteredBenefits.includes(curr.sku)) {
            res.push(+curr.id);
          }
          return res;
        },
        []
      );
      setIdsValidSubscriptions(validSubIds);

      const idsMap = [...validSubIds, ...idsWithoutSubscriptions];
      setIdsMapped(idsMap);
    } else {
      const idsWithoutSubscriptions = ids?.filter((id) => {
        const subItem = subscriptionItems.find((el) => +el.id === id);
        return !subItem;
      });

      setIdsMapped(idsWithoutSubscriptions);
    }
  }, [dataFiltered]);

  if (!isArrayHasItems(subscriptionItems)) {
    return {
      loading: false,
      idsMapped: ids,
      idsValidSubscriptions: [],
      idsWithoutSubscription: [],
      skuFilteredBenefits: [],
      dataFiltered: { benefitGroups: {} },
    };
  }

  return {
    loading: dataFiltered.isLoading,
    idsMapped,
    idsValidSubscriptions,
    idsWithoutSubscription,
    skuFilteredBenefits,
    dataFiltered: dataFiltered ?? { benefitGroups: {} },
  };
};

export default useBenefitsListingSearch;
