import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setWindowDimension } from "web/features/app/appSlice";

const WindowDimension = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let timeoutId: number | undefined;
    const resizeHandler = () => {
      const width = window && window.innerWidth ? window.innerWidth : 0;
      const height = window && window.innerHeight ? window.innerHeight : 0;
      if (typeof setWindowDimension === "function") {
        clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
          dispatch(
            setWindowDimension({
              width,
              height,
            })
          );
        }, 100);
      }
    };
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);
  return null;
};

export default WindowDimension;
