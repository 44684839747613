import jsonParse from "web/utils/data/parser/string/jsonParse";
import type { IOrder, IOrderDetails, TourismOrderDetailsItem } from "web/types/Order";
import type { MBProductType } from "web/types/Product";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

const parseOrderDetailResult = (order: IOrderDetails<MBProductType> | IOrder<MBProductType>) => {
  if (!order || typeof order !== "object") return null;
  const items = isArrayHasItems(order.items)
    ? order.items.map((item) => {
        const paymentMethod = item.payment_method
          ? jsonParse(item.payment_method as string)
          : null;
        const tourismOfferData = (<TourismOrderDetailsItem>item).tourism_offer_data
          ? jsonParse((<TourismOrderDetailsItem>item).tourism_offer_data)
          : null;
        const productData = item.product_data
          ? jsonParse(item.product_data)
          : null;
        const parentProductData = item.product_options
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore 
          ? jsonParse(item.product_options)?.parent_product_data
          : null;
        return {
          ...item,
          payment_method: paymentMethod,
          tourism_offer_data: tourismOfferData,
          product_data: productData,
          parent_product_data: parentProductData,
        };
      })
    : [];

  const tourismData = (<IOrderDetails<MBProductType.ANIXE_TOURISM>>order).tourism_data ? jsonParse((<IOrderDetails>order).tourism_data) : null;
  const tourismDataOther = (<IOrder<MBProductType.ANIXE_TOURISM>>order).tourism_offer_data
    ? jsonParse((<IOrder<MBProductType.ANIXE_TOURISM>>order).tourism_offer_data)
    : null;
  return {
    ...order,
    items,
    tourism_data: tourismData || tourismDataOther,
    total: (<IOrderDetails>order).total || (<IOrder>order).grand_total,
  };
};

export default parseOrderDetailResult