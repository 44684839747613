/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { names } from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import CategoryLink from "web/Layout/Common/CategoryLink/categoryLink";
import __ from "web/Layout/Translations";

import useOnProductClikCallback from "web/hooks/useOnProductClikCallback";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import setBenefitGroupTypeParam from "web/utils/page/product/subscription/setBenefitGroupTypeParam";
import { isSubscriptionDisabled } from "web/utils/page/product/subscription/windowGroupConfig";
import getPriceFinalPriceDiscount from "web/utils/page/product/universal/getPriceFinalPriceDiscount";
import parseReviewSummary from "web/utils/page/product/universal/parseReviewSummary";
import processUrlKey from "web/utils/page/product/universal/processUrlKey";
import getAdditionalFilterParametersUrl from "web/utils/system/url/getAdditionalFilterParametersUrl";

import { BENEFIT_PRODUCT_TYPES } from "web/constants/benefits";
import notSelected from "web/constants/image";
import urls from "web/constants/urls";

import { DomesticTourismProduct, IProduct, ProductInList } from "web/types/Product";
import useProductLabels from "web/hooks/useProductLabels";
import { useAppContext } from "web/context/app";

import Body from "./Body";
import Discount from "./Discount";
import Footer from "./Footer";
import Image from "./Image";
import ProductFavorite from "./ProductFavorite";
import ReviewsLabel from "./ReviewsLabel";
import TitleLabel from "./TitleLabel";
import Top from "./Top";
import { ProductCardProps } from "./container";
import classes from "./productCard.scss";
import New from "./TitleLabel/New";
import Bestseller from "./ReviewsLabel/Bestseller";

const getAnixeTourismProductUrl = (
  params: ProductCardProps["params"],
  positionNumber: ProductCardProps["positionNumber"],
  listingPosition: ProductCardProps["listingPosition"],
  listingAmount: ProductCardProps["listingAmount"],
  listingCategory: ProductCardProps["listingCategory"]
) => {
  const searchParams = new URLSearchParams("");
  const paramsToSet = {
    position_id: positionNumber?.toString(),
    listing_position: listingPosition?.toString(),
    listing_amount: listingAmount?.toString(),
    listing_category: listingCategory?.toString(),
  };

  Object.entries(paramsToSet).forEach(([key, value]) => {
    if (value) {
      searchParams.set(key, value);
    }
  });

  if (params && Object.keys(params).length) {
    Object.keys(params).forEach((key) => {
      switch (key) {
        case "date_from": {
          searchParams.set(names.dateFrom, params[key]);
          break;
        }
        case "date_to": {
          searchParams.set(names.dateTo, params[key]);
          break;
        }
        case "rooms": {
          searchParams.set(
            names.rooms,
            encodeURIComponent(JSON.stringify(params[key]))
          );
          break;
        }
        case "rid": {
          if (params[key]) {
            searchParams.set("rid", params[key]);
          }
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  return `?${searchParams.toString()}`;
};

const ProductCard: FC<ProductCardProps> = ({
  product,
  isWishlistPage,
  categoryId,
  params,
  clickFrom,
  positionNumber,
  listingPosition,
  listingAmount,
  listingCategory = "",
  variant = "default",
}) => {
  if (!product) {
    return null;
  }

  const { open: isWindowOpen, openUms: isUmsWindowOpen } =
    useSelector((state) => state.subscriptionQuote.config) || {};

  const productTyped = product as IProduct<unknown> & {
    isWishlistUnavailable?: boolean;
  };

  const { search } = useLocation();
  const isAnixeTourism = productTyped?.mb_product_type === "anixe_tourism";
  const isSubscription =
    typeof productTyped?.mb_product_type === "string"
      ? productTyped?.mb_product_type.includes("abonament")
      : false;

  const url = processUrlKey(productTyped.url_key);

  const dataSubscription = useSelector((state) => state.benefitGroups);
  const benefitGroups = dataSubscription?.benefitGroups ?? {};

  const setPositionNumber = () => {
    const newParams = new URLSearchParams(search);

    if (positionNumber) {
      newParams.set("position_id", positionNumber.toString());
    }
    if (listingPosition) {
      newParams.set("listing_position", listingPosition.toString());
    }
    if (listingAmount) {
      newParams.set("listing_amount", listingAmount.toString());
    }
    if (listingCategory) {
      newParams.set("listing_category", listingCategory.toString());
    }

    return newParams.toString();
  };

  const urlSearchParams = isAnixeTourism
    ? getAnixeTourismProductUrl(
      params,
      positionNumber,
      listingPosition,
      listingAmount,
      listingCategory
    )
    : setPositionNumber();

  const additionalParameters = getAdditionalFilterParametersUrl(search);
  const hasAdditionalParameters = !!additionalParameters;

  const { name } = productTyped;

  const productOptions = productTyped.product_options;

  const outOfStock =
    productTyped.isWishlistUnavailable ||
    // @ts-ignore
    parseInt(productTyped.stock_status, 10) === 0;

  const isDisabled = useMemo(
    () =>
      isSubscriptionDisabled(
        productTyped.mb_product_type,
        isWindowOpen,
        isUmsWindowOpen
      ),
    [productTyped.mb_product_type, isWindowOpen, isUmsWindowOpen]
  );

  const isTourismAnixeMinOfferExist =
    isAnixeTourism &&
    productTyped.tourism_anixe_min_offer &&
    Object.keys(productTyped.tourism_anixe_min_offer).length;

  const isAnnouncement =
    productTyped.sale_date_from &&
    (Date.parse(productTyped.sale_date_from) || 0) > Date.now();

  const isAdditionalExist = Boolean(
    productTyped &&
    hasAdditionalParameters &&
    (productTyped.additional_short_text ||
      productTyped.sale_date_to ||
      isTourismAnixeMinOfferExist ||
      isAnnouncement)
  );

  const type = productTyped.mb_product_type;
  const isUnavailable = isAnnouncement || outOfStock;
  const classNameRoot = isAdditionalExist
    ? classes.rootWithHover
    : classes.root;
  const classNameImageBox = outOfStock
    ? classes.imageBoxHidden
    : classes.imageBox;
  const classNameTop = isUnavailable ? classes.topStatic : classes.top;

  const { price, finalPrice, discount } = getPriceFinalPriceDiscount({
    price: productTyped.price,
    finalPrice: productTyped.final_price,
    options: productOptions,
  });

  const { isMobile } = useAppContext();

  const to = isSubscription
    ? {
      pathname: urls.checkoutSubscriptionConfiguration,
      search: `${setBenefitGroupTypeParam(
        productTyped.mb_product_type.split("_")[2]
      )}&id=${productTyped.sku}`,
    }
    : {
      pathname: url,
      state: {
        categoryId,
        clickFrom,
      },
      search: urlSearchParams,
    };

  const [sendProductClickEvent] = useOnProductClikCallback(
    productTyped,
    positionNumber,
    "",
    listingPosition,
    listingAmount,
    listingCategory
  );

  const wrapperClasses =
    [
      "international_tourism_travelplanet",
      "international_tourism_other",
      BENEFIT_PRODUCT_TYPES.GROUP_1,
      BENEFIT_PRODUCT_TYPES.GROUP_2,
    ].indexOf(type) !== -1
      ? classes.wrapperPadding
      : classes.wrapper;

  const classesByVariant = {
    default: classes.default,
    inModal: classes.inModal,
  };

  const footerClasses =
    discount && `${price}`.length >= 4
      ? classes.footerSmallPadding
      : classes.footer;

  const isConfigurable =
    productTyped &&
    productTyped.product_options &&
    productTyped.product_options.configurable_options &&
    isArrayHasItems(
      productTyped.product_options.configurable_options.attributes
    );

  const subscriptionPrice = isSubscription
    ? benefitGroups[(product as IProduct).sku]?.price
    : null;

  const image =
    productTyped.thumbnail === notSelected || !productTyped.thumbnail
      ? productTyped.image
      : productTyped.thumbnail;

  const { product_label } = product as ProductInList;

  const { isBestseller, isNew } = useProductLabels(product_label);

  return (
    <article
      className={`${classNameRoot} ${classesByVariant[variant as keyof typeof classesByVariant]
        } ${isDisabled ? classes.disabled : ""}`}
    >
      {!outOfStock && <Discount value={discount} type={type} />}
      {!isSubscription && (
        <ProductFavorite
          id={productTyped.id}
          sku={productTyped.sku}
          isWishlistPage={!!isWishlistPage}
        />
      )}
      <div className={classes.mobileLabelsWrapper}>
        <Image
          className={classNameImageBox}
          image={image}
          name={name}
          // @ts-ignore
          to={to}
          onClick={sendProductClickEvent}
        />
        {
          isMobile && (
            <div className={classes.mobileLabels}>
              {isBestseller && <Bestseller code="bestseller" ids={productTyped.product_label} />}
              {isNew && <New code="new" ids={productTyped.product_label} />}
            </div>
          )
        }

      </div>
      <div className={classes.content}>
        <Top
          className={classNameTop}
          isAnnouncement={!!isAnnouncement}
          options={productOptions}
        />
        <div className={wrapperClasses}>
          <ReviewsLabel
            average={parseReviewSummary(productTyped.review_summary)}
            reviews={productTyped.review_count}
            ids={productTyped.product_label}
            type={type}
          />
          {productTyped.main_category_id && (
            <CategoryLink
              className={classes.category}
              id={productTyped.main_category_id}
            />
          )}
          <TitleLabel
            name={isConfigurable ? __(name) : name}
            // @ts-ignore
            to={to}
            ids={productTyped.product_label}
            onClick={sendProductClickEvent}
            isDisabled={isDisabled}
            additionalClassname={
              productTyped.main_category_id ? "" : classes.titleMargin
            }
          />
          <div
            className={`${classes.body} ${isSubscription ? classes.bodySubscription : ""
              }`}
          >
            <Body
              product={productTyped}
              isAdditionalExist={isAdditionalExist}
            />
          </div>

          <Footer
            className={footerClasses}
            isOutOfStock={outOfStock}
            isAnnouncement={!!isAnnouncement}
            price={(!isSubscription ? price! : subscriptionPrice) as number}
            finalPrice={
              (!isSubscription ? finalPrice! : subscriptionPrice) as number
            }
            payments={productTyped.payment_settings}
            options={productTyped.product_options}
            type={type}
            // @ts-ignore
            to={to}
            priceTourismDomestic={
              isTourismAnixeMinOfferExist && hasAdditionalParameters
                ? +(productTyped as IProduct<DomesticTourismProduct>)
                  .tourism_anixe_min_offer.total_price
                : 0
            }
            onClick={sendProductClickEvent}
            isUnavailable={productTyped?.isWishlistUnavailable}
          />
        </div>
      </div>
    </article>
  );
};

export default ProductCard;
