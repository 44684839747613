import { FC } from "react";

import __ from "web/Layout/Translations";

import StarsRating, { StarsRow } from "web/Components/Common/StarsRating";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { PropsWithClasses } from "web/types/Common";

interface ICheckboxContentProps {
  name: string;
  label: string;
  count: number;
}

const CheckboxContent: FC<PropsWithClasses<ICheckboxContentProps>> = ({
  classes = {},
  name,
  label,
  count,
}) => {
  switch (name) {
    case "review_summary_filter": {
      const ratingMatch = typeof label === "string" ? label.match(/\d+/) : null;
      const rating = isArrayHasItems(ratingMatch) ? +ratingMatch[0] : null;
      return (
        <>
          {typeof rating === "number" ? (
            <StarsRating className={classes.rating} rating={rating} />
          ) : null}
          <span
            data-t1="multiselect_checkbox_content_label"
            className={classes.badge}
          >
            {label}
          </span>
        </>
      );
    }
    case "tourism_hotel_category": {
      const categoryMatch =
        typeof label === "string" ? label.match(/\d+/) : null;
      const category = isArrayHasItems(categoryMatch)
        ? +categoryMatch[0]
        : null;
      return (
        <>
          <div className={classes.categoryRow}>
            {category ? (
              <StarsRow className={classes.category} count={category} />
            ) : (
              <span className={classes.categoryLack}>{__("Brak")}</span>
            )}
          </div>
          <span
            data-t1="multiselect_checkbox_content_label_standards"
            className={classes.badge}
          >
            ({count})
          </span>
        </>
      );
    }
    default: {
      return (
        <>
          <span
            className={classes.badge}
            data-t1={`multiselect_checkbox_content_label_default_${name}`}
          >
            {label}
          </span>{" "}
          {[
            "location_location_parent_location_parent",
            "location_province",
          ].indexOf(name) === -1 && (
            <span
              data-t1="multiselect_checkbox_content_label_standards"
              className={classes.badge}
            >
              ({count})
            </span>
          )}
        </>
      );
    }
  }
};

export default CheckboxContent;
