interface ICalculateAverageIncomeArgs {
  totalIncome: number;
  peopleCount: number;
  monthsCount: number;
}

const calculateAverageIncome = ({
  totalIncome,
  peopleCount,
  monthsCount,
}: ICalculateAverageIncomeArgs) =>
  Math.ceil((totalIncome / peopleCount / monthsCount) * 100) / 100;

export default calculateAverageIncome;
