import { FC, memo } from "react";

import { SkeletonProductCategories } from "web/Layout/Common/SkeletonComponents";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { ICategory } from "web/types/Category";
import { Facets, IFacetsItem } from "web/types/Facets";
import { ISubscriptionItemMs } from "web/types/Subscription";

import List from "./list";
import ListWithSubscriptions from "./listSubscriptions";

interface IContainerProps {
  categories: ICategory[];
  facets: Facets;
  subscriptionItems: ISubscriptionItemMs[];
}

const Container: FC<IContainerProps> = memo(
  ({ categories, facets, subscriptionItems }) => {
    if (facets.length) {
      const facet = facets.find(
        (facetItem) => facetItem.code === "category_ids"
      ) as IFacetsItem;

      return !isArrayHasItems(subscriptionItems) ? (
        <List categories={categories} facet={facet} />
      ) : (
        <ListWithSubscriptions
          categories={categories}
          facet={facet}
          subscriptionItems={subscriptionItems}
        />
      );
    }

    return <SkeletonProductCategories />;
  }
);

export default Container;
