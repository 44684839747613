import { FC, MouseEventHandler } from "react";

import ArrowUpIcon from "web/assets/icons/arrow-down.svg";

import classes from "./filtersFieldsetHeaderTrigger.scss";

export interface IFiltersFieldsetHeaderTrigger {
  title: string;
  toggle: MouseEventHandler<HTMLButtonElement>;
  isOpen: boolean;
}

const FiltersFieldsetHeaderTrigger: FC<IFiltersFieldsetHeaderTrigger> = ({
  title,
  isOpen,
  toggle,
}) => {
  const arrowClassName = isOpen ? classes.arrow : classes.arrowClosed;

  return (
    <button
      data-t1="trigger_button"
      className={classes.root}
      type="button"
      onClick={toggle}
    >
      {title}
      <ArrowUpIcon className={arrowClassName} width={12} />
    </button>
  );
};

export default FiltersFieldsetHeaderTrigger;
