import { Button } from "@benefit-systems/common-components";
import DOMPurify from "dompurify";
import { FC, useState } from "react";

import DuplicateCardForm from "web/Layout/SubscriptionReceivers/SubscriptionReceiverDuplicateCard/DuplicateCardForm/duplicateCardForm";
import __ from "web/Layout/Translations";

import { as24HoursPassed } from "web/utils/page/product/subscription/checkDuplicateButtonStatus";
import { getIdFromIri } from "web/utils/page/product/subscription/iri";

import { PropsWithClasses } from "web/types/Common";
import {
  DuplicateOrderStatus,
  IDuplicateCardData,
  IRI,
  ISubscriptionReceiver,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import styles from "./subscriptionReceiverDuplicateCard.scss";

interface ISubscriptionReceiverDuplicateCardProps {
  data: ISubscriptionReceiver;
  duplicateCardData: IDuplicateCardData;
  enableOrderDuplicateCard: boolean;
  openDuplicateForm: Nullable<string>;
  setOpenDuplicateForm: (id: Nullable<string>) => void;
  updateReceiversDataFunc?: (newReceiverData: ISubscriptionReceiver) => void;
}

const SubscriptionReceiverDuplicateCard: FC<
  PropsWithClasses<ISubscriptionReceiverDuplicateCardProps>
> = ({
  classes = {},
  duplicateCardData,
  enableOrderDuplicateCard,
  data,
  openDuplicateForm,
  setOpenDuplicateForm,
  updateReceiversDataFunc,
}) => {
  const {
    id: receiverId,
    lastSportCardRequestAt,
    subscriptionItemReceiver,
  } = data;
  const isDuplicateFormOpen = openDuplicateForm === receiverId;
  const subscriptionItemReceiverId = getIdFromIri(
    subscriptionItemReceiver,
    IRI.RECEIVER
  );
  // Card duplicates use different ids depending on context
  // - on My Subscriptions view - receiverId
  // - on Configurator - receiver id changes with draft, so it uses subscriptionItemReceiverId
  const receiverIdForDuplicates = subscriptionItemReceiverId || receiverId;

  const [currentDuplicateOrderStatus, setCurrentDuplicateOrderStatus] =
    useState(as24HoursPassed(lastSportCardRequestAt, enableOrderDuplicateCard));

  return (
    <>
      {!isDuplicateFormOpen &&
        currentDuplicateOrderStatus !== DuplicateOrderStatus.JUST_ORDERED && (
          <div
            className={`${styles.duplicate__orderButton} ${classes.duplicate__orderButton}`}
          >
            <Button
              type="button"
              variant="primary"
              onClick={() => {
                setOpenDuplicateForm(receiverId);
              }}
              disabled={!!(openDuplicateForm && !isDuplicateFormOpen)}
            >
              {__("Zamów duplikat")}
            </Button>
          </div>
        )}
      {isDuplicateFormOpen && (
        // TODO: cleanup types after removing benefitForm.js
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <DuplicateCardForm
          currentReceiver={data}
          duplicateCardData={duplicateCardData}
          receiverId={receiverIdForDuplicates}
          updateReceiversDataFunc={updateReceiversDataFunc}
          setOpenDuplicateForm={setOpenDuplicateForm}
          setCurrentDuplicateOrderStatus={setCurrentDuplicateOrderStatus}
        />
      )}
      {currentDuplicateOrderStatus === DuplicateOrderStatus.JUST_ORDERED && (
        <div className={styles.duplicate__info}>
          <span className={styles.duplicate__orderedText}>
            {__("Duplikat został zamówiony")}
          </span>
          {duplicateCardData.duplicateInfo && (
            <div
              className={`${styles.duplicate__infoText} cke_editable`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(duplicateCardData.duplicateInfo),
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SubscriptionReceiverDuplicateCard;
