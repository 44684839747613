import { useMemo } from "react";
import { useSelector } from "react-redux";

import useGetProductOptions from "web/hooks/useGetProductOptions";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import gtmGetLastPageType from "web/utils/system/GTM/gtmGetLastPageType";

import type { IProduct } from "web/types/Product";
import { Nullable } from "web/types/Utils";

import { usePageGeneratorContext } from "web/context/pageGenerator";

interface IGtmFinalProductOption {
  fullAttributePath: string;
  fullCategoriesPaths: string[];
  fullCategoryPath: string;
  productId: string;
}

export interface IEnhancedEcommOptions {
  ecommerce: {
    currencyCode: string;
    impressions: {
      dimension16: undefined;
      location_name?: undefined;
      name: string;
      id: string;
      price: string;
      brand: string;
      category: undefined;
      variant: string;
      list: string;
      position: number;
    }[];
  };
}

const useGtmProductImpression = (
  products: IProduct[],
  listingPosition: number,
  listingAmount: number,
  listingHeader: string
): [boolean, Nullable<IEnhancedEcommOptions>] => {
  const { base_currency_code: currency } = useSelector(
    (state) => state.app.storeConfig
  );
  const { promoItems } = useSelector((state) => state.gtm);
  const { pageName } = usePageGeneratorContext() || {};

  const productsOptionsData = isArrayHasItems(products)
    ? products.map((itemData) => {
        const productObj = {
          productId: itemData.id,
          productCategoryId: itemData?.main_category_id,
          productAttributesId: [{}],
          ...(itemData?.tourism_city
            ? { location_name: itemData.tourism_city }
            : {}),
          productCategoriesIds: itemData?.category_ids,
        };
        return productObj;
      })
    : null;

  const [finalProductOptions, isReady] =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useGetProductOptions(productsOptionsData);

  const gtmEnhancedEcommOptions = useMemo<
    Nullable<IEnhancedEcommOptions>
  >(() => {
    if (!isArrayHasItems(finalProductOptions)) return null;
    const gtmGetProductImpressions = (
      productList: IProduct[],
      gtmFinalProductOptions: IGtmFinalProductOption[]
    ) => {
      const gtmProductList =
        productList?.map((item, index) => {
          let productCategoryPath;
          let productCategoriesPaths;
          gtmFinalProductOptions.some((element) => {
            if (+element.productId === +item.id) {
              productCategoryPath = element.fullCategoryPath;
              productCategoriesPaths = element.fullCategoriesPaths;
              return true;
            }
            return false;
          });

          const categoriesString = productCategoryPath;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const productListing = gtmGetLastPageType({
            clickFrom: "",
            listingPosition,
            listingAmount,
            listingCategory: listingHeader,
            pageName,
          }) as { list: string };

          const { creative_slot, promotion_name } = (promoItems?.[item?.id] ||
            {}) as { creative_slot: number; promotion_name: string };

          return {
            name: item?.name || "",
            id: item?.id || "",
            price: item?.final_price?.toString() || "",
            brand: item?.supplier_name || "",
            category: categoriesString,
            variant: "",
            list: productListing?.list,
            position: index + 1,
            index: index + 1,
            ...(item?.tourism_city ? { location_name: item.tourism_city } : {}),
            dimension16: productCategoriesPaths,
            item_list_name: productListing?.list || "",
            ...(creative_slot && promotion_name
              ? { creative_slot, promotion_name }
              : {}),
          };
        }) || [];

      return gtmProductList;
    };

    const enhancedEcomm = {
      ecommerce: {
        currencyCode: currency,
        impressions: isReady
          ? gtmGetProductImpressions(products, finalProductOptions)
          : [],
      },
    };

    return enhancedEcomm;
  }, [
    finalProductOptions,
    isReady,
    products,
    currency,
    listingPosition,
    listingAmount,
    promoItems,
  ]);

  const isGtmObjectReady =
    isReady &&
    (gtmEnhancedEcommOptions?.ecommerce?.impressions?.length ?? 0) > 0;

  return [isGtmObjectReady, gtmEnhancedEcommOptions];
};

export default useGtmProductImpression;
