import { FC, RefObject, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import logotype from "web/assets/logo.svg";

import getSrcMedia from "web/utils/system/url/getSrcMedia";

import urls from "web/constants/urls";

import { PropsWithClasses } from "web/types/Common";
import type { Nullable } from "web/types/Utils";

import classify from "web/classify";

import defaultClasses from "./logo.scss";

interface ILogoProps {
  onClick?: () => unknown;
  logoSrc: Nullable<string>;
  logoAlt: Nullable<string>;
  logoRef?: RefObject<Nullable<HTMLImageElement>>;
}

const Logo: FC<PropsWithClasses<ILogoProps>> = ({
  classes = {},
  onClick = () => {},
  logoSrc = "",
  logoAlt = "",
  logoRef = null,
}) => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const [src, alt] = logoSrc
    ? [
        getSrcMedia({
          isLogotype: true,
          storeConfig,
          src: logoSrc,
        }),
        logoAlt,
      ]
    : [logotype, ""];
  const onClickHandler = useCallback(() => {
    if (typeof onClick === "function") {
      onClick();
    }
  }, [onClick]);

  return (
    <Link
      to={urls.home}
      className={classes.root}
      data-t1="logo_link"
      onClick={onClickHandler}
      aria-label="Go to Home Page"
    >
      <img
        src={src}
        className={classes.image}
        alt={alt || ""}
        data-t1="logo_image"
        ref={logoRef ? (logoRef as RefObject<HTMLImageElement>) : null}
      />
    </Link>
  );
};

export default classify<PropsWithClasses<ILogoProps>>(defaultClasses)(Logo);
