/* eslint-disable @typescript-eslint/ban-ts-comment */
import { motion } from "framer-motion";
import { FC, useCallback } from "react";

import Badge from "web/Layout/Common/Badge";
import Loading from "web/Layout/Common/Loading";
import Dropdown from "web/Layout/MainHeader/Desktop/CartTrigger/Dropdown";

import CartIcon from "web/assets/icons/cart.svg";

import useDropdown from "web/hooks/useDropdown";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { ICartItem } from "web/types/Cart";

import AddToCartNotification from "./AddToCartNotification";
import classes from "./cartTrigger.scss";

/**
 * If needed, we have custom addToCart notification in <AddToCartNotification />
 * It's a standalone feature, if client want to customise this notic just add & modify this one.
 */

interface ICartTriggerProps {
  items: ICartItem[];
  totalCount: number;
  isAddingItem: boolean;
  isAddingItems: boolean;
}

const variants = {
  open: {
    opacity: 1,
    visibility: "visible",
  },
  closed: {
    opacity: 0,
    visibility: "hidden",
  },
};
const duration = { duration: 0.2 };

const CartTrigger: FC<ICartTriggerProps> = ({
  items = [],
  totalCount,
  isAddingItem,
  isAddingItems,
}) => {
  const [isOpen, setIsOpen] = useDropdown({
    scopeSelector: `.${classes.root}`,
    closeAfterClick: true,
  });
  const clickHandler = useCallback(() => {
    setIsOpen((status) => !status);
  }, [setIsOpen]);

  const badgeClassName = isArrayHasItems(items)
    ? classes.badge
    : classes.badgeEmpty;

  return isAddingItem || isAddingItems ? (
    <Loading />
  ) : (
    <div className={classes.root}>
      <button
        className={classes.trigger}
        type="button"
        data-t1="cart_trigger"
        onClick={clickHandler}
      >
        <Badge className={badgeClassName} amount={totalCount} />
        <CartIcon className={classes.icon} />
      </button>
      {isArrayHasItems(items) ? (
        <motion.div
          data-t1="cart_trigger_dropdown"
          className={classes.dropdown}
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          transition={duration}
          // @ts-ignore
          variants={variants}
        >
          <Dropdown action={clickHandler} />
        </motion.div>
      ) : null}
      <AddToCartNotification />
    </div>
  );
};

export default CartTrigger;
