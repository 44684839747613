import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import isDateExpired from "web/utils/data/validator/dateAndTime/isDateExpired";
import mergeFacetsStatsAttributes from "web/utils/page/category/mergeFacetsStatsAttributes";
import processFilters from "web/utils/page/category/processFilters";

import type { IAttribute } from "web/types/Attributes";
import type { Facets } from "web/types/Facets";
import type { Stats } from "web/types/Stats";
import { IStoreConfig } from "web/types/StoreConfig";

import { useAppContext } from "web/context/app";
import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

interface IFiltersProps {
  attributes?: IAttribute[];
  stats?: Stats;
  facets?: Facets;
  prices?: [] | Record<string, string>;
  facetsHidden?: string[];
  isAnixeTourism?: boolean;
  withShiftedActiveFilters?: boolean;
  ids?: number[];
}

const facetSequencesDefault = [
  "category_ids",
  "payment_settings",
  "final_price",
  "location",
  "event_date",
];
const facetSequencesAnixeTourism = [
  "final_price",
  "price",
  "tourism_object_type",
  "tourism_hotel_category",
  "tourism_facilities",
  "tourism_for_kids",
  "tourism_others",
];

const Filters: FC<IFiltersProps> = ({
  attributes = [],
  stats = [],
  facets = [],
  prices,
  facetsHidden = [],
  isAnixeTourism = false,
  withShiftedActiveFilters = false,
  ids,
}) => {
  const { data: dataBanks } = useGetBanksQuery();
  const { items: banks } = dataBanks || {};
  const storeConfig = useSelector((state) => state.app.storeConfig);

  const { isMobile } = useAppContext();

  const filters = useMemo(() => {
    const facetSequences = isAnixeTourism
      ? facetSequencesAnixeTourism
      : facetSequencesDefault;

    const facetsFiltered =
      isArrayHasItems(facetsHidden) && isArrayHasItems(facets)
        ? facets.filter((facet) => {
            return facetsHidden.indexOf(facet?.code) === -1;
          })
        : facets;

    const statsFiltered =
      isAnixeTourism && isArrayHasItems(stats)
        ? stats.filter((stat) => stat.code !== "event_date")
        : stats;

    const statsWithActiveDate =
      isArrayHasItems(statsFiltered) &&
      statsFiltered?.filter(
        (stat) =>
          !(
            stat?.code === "event_date" &&
            stat?.values?.max &&
            isDateExpired(stat.values.max as string)
          )
      );

    const filtersMerged = mergeFacetsStatsAttributes(
      attributes!,
      statsWithActiveDate as Stats,
      facetsFiltered!
    );

    if (isArrayHasItems(filtersMerged)) {
      const filtersProcessed = processFilters(
        filtersMerged,
        storeConfig as IStoreConfig,
        banks
      );

      return filtersProcessed.sort((first, second) => {
        const firstIndex = facetSequences.indexOf(first.attribute_code);
        const secondIndex = facetSequences.indexOf(second.attribute_code);
        const firstPosition = firstIndex === -1 ? 1000 : firstIndex;
        const secondPosition = secondIndex === -1 ? 1000 : secondIndex;
        return firstPosition - secondPosition;
      });
    }

    return [];
  }, [
    attributes,
    stats,
    facets,
    facetsHidden,
    storeConfig,
    banks,
    isAnixeTourism,
  ]);

  return isMobile ? (
    <Mobile
      filters={filters}
      facetsHidden={facetsHidden}
      isAnixeTourism={isAnixeTourism}
    />
  ) : (
    <Desktop
      filters={filters}
      isAnixeTourism={isAnixeTourism}
      facetsHidden={facetsHidden}
      withShiftedActiveFilters={withShiftedActiveFilters}
      ids={ids!}
      prices={prices as Record<string, string>}
    />
  );
};

export default Filters;
