import { ISubscriptionBenefit } from "web/types/Subscription";

export default (benefit: ISubscriptionBenefit) => {
  const benefitAttachments = benefit?.attachments;
  const benefitSubgroupAttachments =
    benefit?.benefitSubgroup?.benefitSubgroupAttachments;
  const benefitGroupAttachments =
    benefit?.benefitGroup?.benefitGroupAttachments;

  return [
    ...(benefitGroupAttachments || []),
    ...(benefitSubgroupAttachments || []),
    ...(benefitAttachments || []),
  ];
};
