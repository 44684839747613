/* eslint-disable @typescript-eslint/ban-ts-comment */
import { motion } from "framer-motion";
import { FC, useState } from "react";

import Badge from "web/Layout/Common/Badge";

import Bell from "web/assets/icons/bell.svg";

import useDropdown from "web/hooks/useDropdown";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IMessage, IPopup } from "web/types/Message";

import { useAppContext } from "web/context/app";
import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";

import Dropdown from "./Dropdown";
import ImportantMessagesPopup from "./ImportantMessagesPopup";
import classes from "./messagesTrigger.scss";

const variants = {
  open: {
    opacity: 1,
    visibility: "visible",
  },
  closed: {
    opacity: 0,
    visibility: "hidden",
  },
};

const duration = { duration: 0.2 };

interface IMessagesTriggerProps {
  amount: number;
  messageList: IMessage[];
  messagesAsPopup: IMessage[] | IPopup[];
  onNotificationOpen: (arg: boolean) => void;
}

const MessagesTrigger: FC<IMessagesTriggerProps> = ({
  amount = 0,
  messageList = [],
  messagesAsPopup = [],
  onNotificationOpen = () => {},
}) => {
  const { data: customerData } = useGetCustomerDetailsQuery();
  const { impersonated } = customerData || {};

  const { isMobile } = useAppContext();

  const [isOpen, setIsOpen] = useDropdown({
    scopeSelector: `.${classes.root}`,
    closeAfterClick: true,
  });

  const [isImportantNotificationOpen, setImportantNotificationOpen] = useState(
    !!messagesAsPopup?.length && !impersonated
  );

  const setNotificationOpen = (isOpen: boolean) => {
    setImportantNotificationOpen(isOpen);
    onNotificationOpen(isOpen);
  };

  return (
    <div
      className={
        isImportantNotificationOpen && isMobile
          ? classes.root
          : classes.rootRelative
      }
    >
      {isImportantNotificationOpen && isMobile && (
        <div className={classes.notificationIconWrapper} />
      )}
      <button
        className={isOpen ? classes.triggerActive : classes.trigger}
        type="button"
        data-t1="messages_trigger"
        onClick={() =>
          !isImportantNotificationOpen && setIsOpen((state) => !state)
        }
      >
        <Badge className={classes.badge} amount={amount} withoutSpacer />
        <Bell className={classes.icon} />
      </button>
      <motion.div
        className={
          isMobile ? classes.dropdownImportantMobile : classes.dropdownImportant
        }
        initial="closed"
        animate={isImportantNotificationOpen ? "open" : "closed"}
        transition={duration}
        // @ts-ignore
        variants={variants}
      >
        {isArrayHasItems(messagesAsPopup) && (
          <ImportantMessagesPopup
            items={messagesAsPopup}
            closeAction={() => setNotificationOpen(false)}
            isMobile={isMobile}
          />
        )}
      </motion.div>
      <motion.div
        data-t1="messages_trigger_dropdown"
        className={classes.dropdown}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        transition={duration}
        // @ts-ignore
        variants={variants}
      >
        <Dropdown
          items={messageList}
          closeAction={() => setIsOpen((state) => !state)}
        />
      </motion.div>
      {isImportantNotificationOpen && <div className={classes.overlay} />}
    </div>
  );
};

export default MessagesTrigger;
