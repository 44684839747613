const processAnchorHref = (link: string) => {
  const { origin: currentAppOrigin } = window.location;

  if (!link?.includes(currentAppOrigin)) {
    return link;
  }

  const newLink = link.replace(currentAppOrigin, "");
  if (newLink.includes(".") && !newLink.includes(".html")) {
    return link;
  }

  return newLink;
};

export default processAnchorHref;
