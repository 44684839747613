import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import defaultClasses from "./productImage.scss";

const SkeletonProductImage: FC<PropsWithClasses> = ({ classes = {} }) => {
  const { isMobile } = useAppContext();

  return (
    <Skeleton
      className={
        isMobile ? classes.mobileProductImage : classes.desktopProductImage
      }
    />
  );
};

export default classify<PropsWithClasses>(defaultClasses)(SkeletonProductImage);
