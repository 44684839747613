import { FC } from "react";

import PointerIcon from "web/assets/icons/pointer.svg";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import LocationParent from "../LocationParent/locationParent";
import defaultClasses from "./placeInfo.scss";

interface IPlaceInfoProps {
  address?: string | number;
  name?: string;
  cities?: string;
  isLocationParent?: boolean;
}

const PlaceInfo: FC<PropsWithClasses<IPlaceInfoProps>> = ({
  address = "",
  name = "",
  classes = {},
  cities = "",
  isLocationParent,
}) => {
  return address || name || cities ? (
    <div className={classes.root}>
      <PointerIcon className={classes.icon} />
      {address && (
        <span>
          {isLocationParent ? (
            <LocationParent location={address.toString()} />
          ) : (
            address
          )}
        </span>
      )}
      {cities && <span className={classes.cities}>{cities}</span>}
      {name && (
        <div data-t1="code_realization_place" data-t2={name}>
          {name}
        </div>
      )}
    </div>
  ) : null;
};

export default classify(defaultClasses)(PlaceInfo);
