import { FC } from "react";
import { useLocation } from "react-router-dom";

import urls from "web/constants/urls";

import { useAppContext } from "web/context/app";

import DesktopMainHeader from "./Desktop";
import MobileMainHeader from "./Mobile";

const hiddenHeaderDesktopPathNames = [
  urls.checkoutDefaultPayments,
  urls.checkoutDefaultSummary,
  urls.checkoutInternationalTourismPayments,
  urls.checkoutInternationalTourismSummary,
  urls.checkoutDomesticTourismInformation,
  urls.checkoutDomesticTourismPayments,
  urls.checkoutDomesticTourismSummary,
  urls.checkoutSubscriptionConfiguration,
  urls.checkoutSubscriptionInformation,
  urls.checkoutSubscriptionAgreements,
  urls.checkoutSubscriptionSummary,
  urls.checkoutSubscriptionBlocked,
  urls.checkoutBuyNowPayments,
  urls.checkoutBuyNowSummary,
  urls.checkoutSplash,
  urls.checkoutExternalPrepaid,
];

const MainHeader: FC = () => {
  const { pathname } = useLocation();
  const { isMobile } = useAppContext();

  const isLandingPage = pathname?.indexOf("/lp/") !== -1;

  if (!isMobile &&
    hiddenHeaderDesktopPathNames.some((path) => !pathname.indexOf(path))
    || isMobile && pathname !== urls.home && !isLandingPage) {
      return null;
    }
    if (isMobile) {
      return <MobileMainHeader />;
    } 
    return <DesktopMainHeader />;
};

export default MainHeader;
