import BrowserTemporality from "web/utils/system/storage/storage/browserTemporality";

import storageNames from "web/constants/storageNames";

import { ridKey } from "./getRid";

const storage = new BrowserTemporality();

const removeRidFromStorage = () => {
  storage.removeItem(storageNames.tourismDomestic, ridKey);
};

export default removeRidFromStorage;
