import { FC } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import type { PropsWithClasses } from "web/types/Common";

import { IProductCardProps } from "../container";
import Product from "./product";

export type ProductCardCarouselProps = Pick<
  IProductCardProps,
  "product" | "listingPosition" | "listingAmount" | "listingCategory" | "gtmProduct"
>;

const ProductCardCarouselContainer: FC<
  PropsWithClasses<ProductCardCarouselProps>
> = ({ classes, product, gtmProduct, listingPosition, listingAmount, listingCategory }) => {
  return (
    <ErrorBoundary>
      <Product
        classes={classes}
        product={product}
        gtmProduct={gtmProduct}
        listingPosition={listingPosition}
        listingAmount={listingAmount}
        listingCategory={listingCategory}
      />
    </ErrorBoundary>
  );
};

export default ProductCardCarouselContainer;
