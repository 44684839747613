/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { AnimatePresence, motion } from "framer-motion";
import {
  ElementType,
  FC,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useRef,
} from "react";
import { useHistory } from "react-router";

import CloseIcon from "web/assets/icons/close.svg";

import { PropsWithClasses } from "web/types/Common";
import type { Nullable } from "web/types/Utils";

import classify from "web/classify";

import defaultStyles from "./modal.scss";

const [initial, animate, exit] = [
  { opacity: 0, y: 200 },
  { opacity: 1, y: 0 },
  { opacity: 0, y: -200 },
];

interface IDesktopModalProps {
  component?: ElementType;
  closeAction?: () => void;
  modalRef: MutableRefObject<Nullable<HTMLDivElement>>;
}

type ExtendedCSSStyleDeclaration = CSSStyleDeclaration & {
  zoom?: string;
};

const DesktopModal: FC<PropsWithClasses<IDesktopModalProps>> = ({
  classes = {},
  component: Component = null,
  closeAction = () => {},
  modalRef,
}) => {
  const modalContentRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const documentClickHandler = (e: MouseEvent) => {
    const modalElement = modalContentRef.current;
    const clickedElement = e.target as HTMLElement;
    const scrollbarWidth =
      clickedElement.offsetWidth - clickedElement.clientWidth;

    // to not close modal when user clicks on a scrollbar with resolutions that set zoom property on root
    const rootStyles: ExtendedCSSStyleDeclaration = getComputedStyle(
      document.getElementById("root")!
    );
    const rootZoom = "zoom" in rootStyles ? Number(rootStyles.zoom) : 1;
    const adjustedClientX = e.clientX / rootZoom;

    const isScrollbarClicked =
      scrollbarWidth > 0 &&
      adjustedClientX > clickedElement.offsetWidth - scrollbarWidth;

    if (
      !modalElement?.contains(clickedElement as Node) &&
      !isScrollbarClicked
    ) {
      closeAction();
    }
  };

  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP") {
        setTimeout(() => {
          closeAction();
        }, 500);
      }
    });
  }, [history]);

  return Component ? (
    <AnimatePresence>
      <motion.div
        className={classes.root}
        initial={initial}
        animate={animate}
        exit={exit}
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        onMouseDown={documentClickHandler}
        ref={modalRef}
      >
        <div className={classes.content} role="document" ref={modalContentRef}>
          {typeof Component === "function" ? (
            <Component closeAction={closeAction} />
          ) : (
            Component
          )}
          <button
            type="button"
            className={classes.closeButton}
            onClick={closeAction}
            data-t1="close_modal_button"
          >
            <CloseIcon className={classes.closeIcon} />
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  ) : null;
};

export default classify<PropsWithClasses<IDesktopModalProps>>(defaultStyles)(
  DesktopModal
);
