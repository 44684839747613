import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

export default class MapWrapper {
  constructor() {
    this.observer = new Map();
  }

  has(key) {
    const keys = this.observer.keys();
    return [...keys].some((keyArrays) =>
      keyArrays.some((keyItem) => `${keyItem}` === `${key}`)
    );
  }

  getPromiseByKey(key) {
    const entries = Array.from(this.observer.entries());
    const currentEntry = entries.find((currentEntryItem) => {
      return (
        isArrayHasItems(currentEntryItem) &&
        currentEntryItem[0].some((entryKey) => `${entryKey}` === `${key}`)
      );
    });
    return currentEntry && currentEntry[1];
  }

  get(keys) {
    return this.observer.get(keys);
  }

  set(keys, promise) {
    this.observer.set(keys, promise);
  }

  delete(keys) {
    this.observer.delete(keys);
  }
}
