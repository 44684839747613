import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import sendGtmEvent from "web/utils/system/GTM/sendGtmEvent";
import getSearchParameterAll from "web/utils/system/url/getSearchParameterAll";

import { gtmFiltersKeys } from "web/constants/filters";

import type { PropsWithClasses } from "web/types/Common";

import FiltersFieldsetWrapper from "../Wrapper";
import type { PriceOptions } from "../fieldset";
import Price from "./price";

interface IFieldsetPriceContainerProps {
  title: string;
  code: string;
  options: PriceOptions;
}

let timeoutId: NodeJS.Timeout;
const TIMEOUT = 2000;
const FieldsetPriceContainer: FC<
  PropsWithClasses<IFieldsetPriceContainerProps>
> = ({ title, code, options, classes = {}, className = "" }) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();

  const { min, max } = options;
  const [minProcessed, maxProcessed] = [Math.floor(min), Math.ceil(max)];

  const priceParams = getSearchParameterAll({ name: code, search });
  const [fromPriceParam, toPriceParam] = priceParams;

  const [fromValue, setFromValue] = useState(+(fromPriceParam || minProcessed));
  const [toValue, setToValue] = useState(+(toPriceParam || maxProcessed));

  useEffect(() => {
    const [currentMinParam, currentMaxParam] = priceParams;
    const params = new URLSearchParams(search);

    const isGreaterPossibleMin = +currentMinParam < min;
    const isSmallerPossibleMax = +currentMaxParam > max;

    if (isGreaterPossibleMin || isSmallerPossibleMax) {
      params.delete(code);

      const newPriceValues = [
        isGreaterPossibleMin ? min : currentMinParam,
        isSmallerPossibleMax ? max : currentMaxParam,
      ];

      newPriceValues.forEach((newValue) => {
        params.append(code, encodeURIComponent(newValue));
      });

      if (priceParams.length) push(`${pathname}?${params.toString()}`);
    }
  }, [max, min, push]);

  const onChangeHandler = useCallback(
    (values: number[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        const params = new URLSearchParams(search);
        params.delete(code);
        if (
          isArrayHasItems(values) &&
          JSON.stringify(values) !==
            JSON.stringify([minProcessed, maxProcessed])
        ) {
          values.forEach((value) => {
            params.append(code, encodeURIComponent(value));
          });
          if (+values[0] !== +fromValue || values[1] !== toValue) {
            const gtmOptions = {
              eventCategory: title,
              [gtmFiltersKeys.final_price_from]: +values[0],
              [gtmFiltersKeys.final_price_to]: +values[1],
            };
            sendGtmEvent("filtrujProdukty", gtmOptions, true);
            if (+values[0] !== +fromValue) setFromValue(+values[0]);
            if (values[1] !== toValue) setToValue(+values[1]);
            push(`${pathname}?${params.toString()}`);
          }
        }
      }, TIMEOUT);
    },
    [pathname, search, code, minProcessed, maxProcessed, timeoutId, push]
  );

  // clear push timeout on component unmount
  useEffect(() => clearTimeout.bind(this, timeoutId), []);

  useEffect(() => {
    if (!fromPriceParam) {
      setFromValue(minProcessed);
    }

    if (!toPriceParam) {
      setToValue(maxProcessed);
    }
  }, [fromPriceParam, toPriceParam]);

  return maxProcessed > minProcessed ? (
    <FiltersFieldsetWrapper title={title}>
      <Price
        min={minProcessed}
        max={maxProcessed}
        to={toValue}
        from={fromValue}
        classes={classes}
        className={className}
        onChange={onChangeHandler}
        fromPriceParam={fromPriceParam}
        toPriceParam={toPriceParam}
      />
    </FiltersFieldsetWrapper>
  ) : null;
};

export default FieldsetPriceContainer;
