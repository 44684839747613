export default class NamespacedLocalStorage {
  constructor(localStorage, key) {
    this.localStorage = localStorage;
    this.key = key;
  }

  createKey(key) {
    return `${this.key}__${key}`;
  }

  getItem(name) {
    return this.localStorage.getItem(this.createKey(name));
  }

  setItem(name, value) {
    return this.localStorage.setItem(this.createKey(name), value);
  }

  removeItem(name) {
    return this.localStorage.removeItem(this.createKey(name));
  }
}
