import { FC } from "react";

import getSaleDateLabel from "web/utils/page/product/universal/getSaleDateLabel";

interface ISaleDateProps {
  className?: string;
  from: string;
  to: string;
  dataT1?: string;
}

const SaleDate: FC<ISaleDateProps> = ({
  className = "",
  from = "",
  to = "",
  dataT1 = "sale_date",
}) => {
  const saleDateProcessed = getSaleDateLabel(from, to);

  return saleDateProcessed ? (
    <div
      className={className}
      data-t1={dataT1}
      data-t2={saleDateProcessed?.split(": ")[1]}
    >
      {saleDateProcessed}
    </div>
  ) : null;
};

export default SaleDate;
