import { useSelector } from "react-redux";

import { useAppContext } from "web/context/app";

import Navigation from "./navigation";

const NavigationContainer = () => {
  const { isMobile } = useAppContext();
  const { isNavigationOpened } = useSelector((state) => state.app);
  return isMobile && isNavigationOpened ? <Navigation /> : null;
};

export default NavigationContainer;
