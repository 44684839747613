import { FC } from "react";

import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./productExpires.scss";

interface IProductExpiresProps {
  date: string;
  almostExpiredPeriod: number;
  dataT1?: string;
}

const ProductExpires: FC<PropsWithClasses<IProductExpiresProps>> = ({
  date,
  classes = {},
  almostExpiredPeriod,
  dataT1 = "product_expires",
}) => {
  const dateMilliseconds = date ? Date.parse(date) : null;

  if (!dateMilliseconds) {
    return null;
  }

  const status = dateMilliseconds > almostExpiredPeriod;
  const rootClassName = status ? classes.root : classes.rootExpires;

  return (
    <div className={rootClassName} data-t1={`${dataT1}_label`}>
      {__("Oferta ważna do")}
      {": "}
      <strong
        className={classes.value}
        data-t1={`${dataT1}_date`}
        data-t2={formatDate(dateMilliseconds)}
      >
        {formatDate(dateMilliseconds)}
      </strong>
    </div>
  );
};

export default classify<PropsWithClasses<IProductExpiresProps>>(defaultClasses)(
  ProductExpires
);
