// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.products-item-FmJ{display:flex;justify-content:space-between;border:1px solid #d3e6e6;border-radius:15px;margin:0 0 12px}.products-item-FmJ:last-child{margin:0}`, "",{"version":3,"sources":["webpack://./web/Layout/SearchAutocomplete/Mobile/Autocomplete/Products/products.scss"],"names":[],"mappings":"AAIuB,mBACrB,YAAA,CACA,6BAAA,CACA,wBAAA,CACA,kBAAA,CACA,eAAA,CAEA,8BACE,QAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .item {\n  display: flex;\n  justify-content: space-between;\n  border: 1px solid $cyan-light-2;\n  border-radius: 15px;\n  margin: 0 0 12px;\n\n  &:last-child {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `products-item-FmJ`
};
export default ___CSS_LOADER_EXPORT___;
