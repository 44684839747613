/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useLocation } from "react-router";

import { IFinalProductOption } from "web/hooks/useGetProductOptions/useGetProductOptions";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import isEmpty from "web/utils/data/validator/object/isEmpty";
import getPriceFinalPriceDiscount from "web/utils/page/product/universal/getPriceFinalPriceDiscount";

import { pageName } from "web/constants/toolbar";

import { IGtmEcommerceImpression, IPromoItems } from "web/types/Gtm";
import { DomesticTourismProductInList, ProductInList } from "web/types/Product";
import { Nullable } from "web/types/Utils";

import gtmGetLastPageType from "./gtmGetLastPageType";

interface IGtmProductImpressionsProps {
  data: (ProductInList | DomesticTourismProductInList)[];
  isAnixe: boolean;
  productsOptionsList: IFinalProductOption[];
  previousValue: number;
  lastProductImpressionEvent: Nullable<IGtmEcommerceImpression>;
  lastProductPosition: number;
  currentCgGroup2: string;
  dateFrom: string;
  dateTo: string;
  peopleAmount: number;
  pageSizeDefault: number;
  searchPhrase: string;
  promoItems: IPromoItems;
  listingPromotion: IPromoItems["key"];
}

const gtmGetProductImpressions = ({
  data,
  isAnixe,
  productsOptionsList,
  previousValue,
  lastProductImpressionEvent,
  lastProductPosition,
  currentCgGroup2,
  dateFrom,
  dateTo,
  peopleAmount,
  pageSizeDefault,
  searchPhrase,
  promoItems,
  listingPromotion,
}: IGtmProductImpressionsProps) => {
  if (!isArrayHasItems(data) || !isArrayHasItems(productsOptionsList))
    return [];

  const { search } = useLocation();
  const paramsSearch = new URLSearchParams(search);
  const pageCurrent = parseInt(paramsSearch.get(pageName) || "", 10) || 1;

  const startFromIndex =
    previousValue === data.length
      ? pageSizeDefault * (pageCurrent - 1)
      : previousValue - data.length;
  const currentProducts = lastProductImpressionEvent
    ? data.slice(startFromIndex, data.length)
    : data;

  return currentProducts?.reduce(
    (acc: IGtmEcommerceImpression[], prod, idx) => {
      let productCategoryPath;
      let productCategoriesPaths;

      productsOptionsList.some((element) => {
        if (element.productId === prod.id) {
          productCategoryPath = element?.fullCategoryPath;
          productCategoriesPaths = element?.fullCategoriesPaths;
          return true;
        }
        return false;
      });

      const categoriesString = productCategoryPath;

      const myBrand = () => {
        let brandName = "";

        if (isAnixe) {
          brandName = "Anixe";
        } else if ((prod as ProductInList)?.supplier_name) {
          brandName = ((prod as ProductInList).supplier_name || "").replace(
            /(<([^>]+)>)/gi,
            ""
          );
        }

        return brandName;
      };

      const { finalPrice } = getPriceFinalPriceDiscount({
        price: (prod as ProductInList)?.price,
        finalPrice: (prod as ProductInList)?.final_price,
        options: (prod as ProductInList)?.product_options,
      });

      const productFinalPrice = () => {
        let productPrice = "";

        if (isAnixe) {
          productPrice = (prod as DomesticTourismProductInList)
            ?.tourism_anixe_min_offer?.total_price;
        } else if ((prod as ProductInList)?.final_price) {
          productPrice = `${finalPrice}`;
        }

        return `${productPrice}`;
      };

      const productListing = gtmGetLastPageType({
        currentCgGroup2,
        searchPhrase,
      });
      const { creative_slot, promotion_name } = !isEmpty(listingPromotion)
        ? listingPromotion
        : promoItems?.[prod?.id] || {};

      acc.push({
        name: prod?.name || "",
        id: prod?.id || "",
        price: productFinalPrice(),
        brand: myBrand(),
        category: categoriesString,
        variant: "",
        list: productListing?.list,
        item_list_name: productListing?.list,
        position: lastProductPosition + idx + 1,
        index: lastProductPosition + idx + 1,
        ...(isAnixe
          ? {
              location_name:
                (prod as DomesticTourismProductInList)?.tourism_city || "",
            }
          : {}),
        dimension16: productCategoriesPaths,
        ...(dateFrom ? { date_from: dateFrom } : {}),
        ...(dateTo ? { date_to: dateTo } : {}),
        ...(peopleAmount ? { amount_people: peopleAmount } : {}),
        ...(creative_slot && promotion_name
          ? { creative_slot, promotion_name }
          : {}),
      });
      return acc;
    },
    []
  );
};

export default gtmGetProductImpressions;
