import { format } from "date-fns";

import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import formatHours from "web/utils/data/parser/dateAndTime/formatHours";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import classes from "./bankResetAnnouncement.scss";
import useBanksData from "./utils/useBanksData";

const BankResetAnnouncement = ({
  shouldShowMessage,
}: {
  shouldShowMessage: boolean;
}) => {
  const { banksReset, banksDischarged, areDatesEqual } = useBanksData();

  if (
    !shouldShowMessage ||
    (!isArrayHasItems(banksReset) && !isArrayHasItems(banksDischarged))
  )
    return null;

  return (
    <div className={classes.container}>
      <div className={classes.containerInner}>
        <p className={classes.topParagraph}>
          <div>
            <strong>{__("UWAGA!")}</strong>{" "}
            <span>{__("Wkrótce Twoje punkty stracą ważność!")}</span>
          </div>
          {/* resets */}
          {banksReset.map((item, i, arr) => (
            <span key={item.points_bank_id}>
              <strong> {item.balance} </strong>
              <span>{__("pkt z banku punktów")} </span>
              <span>{item.points_bank_name} </span>

              {areDatesEqual ? (
                <>
                  <span>{__("możesz wykorzystać do")} </span>
                  <strong>{formatDate(banksReset[0].resetTime)}</strong>
                  <span>{i + 1 === arr.length ? "." : ","} </span>
                </>
              ) : (
                <>
                  <span>{__("możesz wykorzystać do godziny")} </span>
                  <strong>
                    {formatHours(item.resetTime)} {formatDate(item.resetTime)}
                  </strong>
                  {i + 1 === arr.length ? "." : ","}
                </>
              )}
            </span>
          ))}
        </p>
        {areDatesEqual && (
          <p className={classes.secondParagraph}>
            <strong>{__("PAMIĘTAJ!")} </strong>
            <span>{__("Punkty są ważne do godziny")} </span>
            <strong>{formatHours(banksReset[0].resetTime)}.</strong>
          </p>
        )}
        {/* discharge */}
        {banksDischarged?.map((item, i, arr) => (
          <span key={item.points_bank_id}>
            <strong> {item.amount} </strong>
            <span>{__("pkt")} </span>
            <span>
              {__("z")} {item.year_to} {__("roku")}{" "}
            </span>
            <span>
              {__("z")} {item.bankName}{" "}
            </span>
            <span>{__("możesz wykorzystać do")} </span>
            <strong>
              {format(new Date(item.discharge_date), "dd.MM.yyyy")}
            </strong>
            <span>{i + 1 === arr.length ? "." : ","} </span>
          </span>
        ))}
        <p className={classes.bottomParagraph}>
          {__(
            "Masz niewystarczającą ilość punktów na zakupy? Różnicę możesz dopłacić online!"
          )}
        </p>
      </div>
    </div>
  );
};

export default BankResetAnnouncement;
