// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bannerStyles-banner-dCt{display:flex;flex-direction:column;justify-content:center;align-items:center;flex-grow:1;border-radius:35px;overflow:hidden;transition:opacity .2s ease-in-out}.bannerStyles-banner-dCt:hover{opacity:.75}.bannerStyles-banner__image-lyW{width:100%;height:100%;display:block;-o-object-fit:cover;object-fit:cover}`, "",{"version":3,"sources":["webpack://./web/Layout/Listing/Common/Products/bannerStyles.scss"],"names":[],"mappings":"AAIuB,yBACrB,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,kBAAA,CACA,eAAA,CACA,kCAAA,CAEA,+BACE,WAAA,CAGF,gCACE,UAAA,CACA,WAAA,CACA,aAAA,CACA,mBAAA,CAAA,gBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .banner {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-grow: 1;\n  border-radius: 35px;\n  overflow: hidden;\n  transition: opacity $transition-duration $transition-timing-function;\n\n  &:hover {\n    opacity: 0.75;\n  }\n\n  &__image {\n    width: 100%;\n    height: 100%;\n    display: block;\n    object-fit: cover;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `bannerStyles-banner-dCt`,
	"banner__image": `bannerStyles-banner__image-lyW`
};
export default ___CSS_LOADER_EXPORT___;
