// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autocomplete-root-Set{display:flex;flex-direction:column;height:100%}.autocomplete-categories-sG9{position:relative;flex-shrink:0;margin-bottom:20px}.autocomplete-products-pd6{position:relative;flex-grow:1;min-width:1px;overflow-y:auto}.autocomplete-empty-P1N{display:block;font-size:14px}`, "",{"version":3,"sources":["webpack://./web/Layout/SearchAutocomplete/Mobile/Autocomplete/autocomplete.scss"],"names":[],"mappings":"AAIuB,uBACrB,YAAA,CACA,qBAAA,CACA,WAAA,CAGF,6BACE,iBAAA,CACA,aAAA,CACA,kBAAA,CAGF,2BACE,iBAAA,CACA,WAAA,CACA,aAAA,CACA,eAAA,CAGF,wBACE,aAAA,CACA,cAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.categories {\n  position: relative;\n  flex-shrink: 0;\n  margin-bottom: 20px;\n}\n\n.products {\n  position: relative;\n  flex-grow: 1;\n  min-width: 1px;\n  overflow-y: auto;\n}\n\n.empty {\n  display: block;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `autocomplete-root-Set`,
	"categories": `autocomplete-categories-sG9`,
	"products": `autocomplete-products-pd6`,
	"empty": `autocomplete-empty-P1N`
};
export default ___CSS_LOADER_EXPORT___;
