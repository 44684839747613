// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rentableGroupWrapper-buttonWrapper-S02{position:fixed;right:2rem;bottom:2rem;z-index:100}`, "",{"version":3,"sources":["webpack://./web/Layout/RentableGroup/RentableGroupCalculatorForm/RentableGroupWrapper/rentableGroupWrapper.scss"],"names":[],"mappings":"AAIuB,wCACrB,cAAA,CACA,UAAA,CACA,WAAA,CACA,WAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .buttonWrapper {\n  position: fixed;\n  right: 2rem;\n  bottom: 2rem;\n  z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": `rentableGroupWrapper-buttonWrapper-S02`
};
export default ___CSS_LOADER_EXPORT___;
