import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

const commonAttributes = [
  "location_province",
  "product_label",
  "location_location_parent_location_parent",
  "tourism_facilities",
  "label_type_product",
  "tourism_for_kids",
  "tourism_others",
  "tourism_object_type",
  "tourism_hotel_category",
];

const PrefetchAttributes = () => {
  useDataCachedAttributes({
    ids: commonAttributes,
  });
  return null;
};

export default PrefetchAttributes;
