// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productImage-desktopProductImageWrapper-bKz{width:74%;text-align:center}.productImage-desktopProductImage-gJ4{width:74% !important;height:100%;min-height:450px;max-height:490px;border-radius:26px !important;margin:20px 0}.productImage-mobileProductImage-VVj{height:240px;margin-top:60px}`, "",{"version":3,"sources":["webpack://./web/Layout/Common/SkeletonComponents/SkeletonProduct/SkeletonImage/productImage.scss"],"names":[],"mappings":"AAIuB,6CACnB,SAAA,CACA,iBAAA,CAGF,sCACE,oBAAA,CACA,WAAA,CACA,gBAAA,CACA,gBAAA,CACA,6BAAA,CACA,aAAA,CAGF,qCACE,YAAA,CACA,eAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .desktopProductImageWrapper {\n    width: 74%;\n    text-align: center;\n  }\n  \n  .desktopProductImage {\n    width: 74% !important;\n    height: 100%;\n    min-height: 450px;\n    max-height: 490px;\n    border-radius: 26px !important;\n    margin: 20px 0;\n  }\n  \n  .mobileProductImage {\n    height: 240px;\n    margin-top: 60px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktopProductImageWrapper": `productImage-desktopProductImageWrapper-bKz`,
	"desktopProductImage": `productImage-desktopProductImage-gJ4`,
	"mobileProductImage": `productImage-mobileProductImage-VVj`
};
export default ___CSS_LOADER_EXPORT___;
