/**
 * Takes an array of objects and filters them by unique value for provided key
 * @param items array of objects having the same structure
 * @param key key of items array element
 * @returns array of objects having unique value for provided field
 */
function getUniqueArrayElementsByField<T>(
  items: T[],
  key: keyof T & (string | number)
) {
  const uniqueItemsMap: Record<PropertyKey, T> = {};

  items.forEach((item) => {
    const val = item[key];
    if (typeof val === "string" || typeof val === "number") {
      uniqueItemsMap[val] = item;
    }
  });

  return Object.values(uniqueItemsMap);
}

export default getUniqueArrayElementsByField;
