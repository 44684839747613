import {
  BankPartialDischargeItems,
  IBankItem,
  IBankPartialDischargeItem,
} from "web/types/Banks";

type BankDischargedWithName = IBankPartialDischargeItem & { bankName: string };

interface IGetBanksDischargedArgs {
  activeBanksMap: Map<string, IBankItem>;
  itemsDischarge?: BankPartialDischargeItems;
}

const getBanksDischarged = ({
  activeBanksMap,
  itemsDischarge,
}: IGetBanksDischargedArgs) =>
  itemsDischarge?.reduce((acc, curr) => {
    const activeBank = activeBanksMap.get(curr.points_bank_id);
    const shouldDisplay = new Date() > new Date(curr.display_from);

    if (shouldDisplay && activeBank) {
      acc.push({
        ...curr,
        bankName: activeBank.points_bank_name,
      });
    }
    return acc;
  }, [] as BankDischargedWithName[]);

export default getBanksDischarged;
