import { useFormikContext } from "formik";
import { FC } from "react";

import __ from "web/Layout/Translations";

import Checkbox from "web/Components/Common/Form/Checkbox";

import rentableGroupCalculatorCopy from "../const/rentableGroupCalculatorCopy";
import type {
  IRentableGroupCalculatorValues,
  Statements,
} from "../rentableGroupCalculatorContainer";
import shouldDisableCheckbox from "../utils/shouldDisableCheckbox";
import classes from "./rentableGroupCalculatorStatements.scss";

const { fillCalculatorCheckbox, financingCheckbox } =
  rentableGroupCalculatorCopy;

const statementsContent = {
  "fill-calculator-checkbox": fillCalculatorCheckbox,
  "financing-checkbox": financingCheckbox,
};

interface IRentableGroupCalculatorStatementsProps {
  statements: Statements;
}

const RentableGroupCalculatorStatements: FC<
  IRentableGroupCalculatorStatementsProps
> = ({ statements }) => {
  const { values } = useFormikContext<IRentableGroupCalculatorValues>();
  return (
    <section className={classes.root}>
      {statements?.map((statement) => (
        <Checkbox
          key={statement}
          classes={{ root: classes.checkbox }}
          name={statement}
          isDisabled={shouldDisableCheckbox(values, statements, statement)}
        >
          <span className={classes.checkboxContent}>
            {__(statementsContent[statement as keyof typeof statementsContent])}
          </span>
        </Checkbox>
      ))}
    </section>
  );
};

export default RentableGroupCalculatorStatements;
