import { FC, useCallback } from "react";

import classes from "./sort.scss";

interface ISortProps {
  action: () => void;
  name: string;
}

const Sort: FC<ISortProps> = ({ action, name }) => {
  const clickHandler = useCallback(() => {
    if (typeof action === "function") {
      action();
    }
  }, [action]);

  return (
    <button
      type="button"
      data-t1={`multiselect_sort_button_${name}`}
      className={classes.root}
      onClick={clickHandler}
    >
      {name}
    </button>
  );
};

export default Sort;
