import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { IAttribute } from "web/types/Attributes";
import type { Facets } from "web/types/Facets";

const TYPE = "mb_product_type_filter";
const CODE = "anixe_tourism";

export interface IIsAttributeHasAnixeTourismOptionArgs {
  attributes?: IAttribute[];
  facets: Facets;
}

const isAttributeHasAnixeTourismOption = ({
  attributes,
  facets,
}: IIsAttributeHasAnixeTourismOptionArgs) => {
  if (isArrayHasItems(attributes) && isArrayHasItems(facets)) {
    const facetType = facets.find((facet) => facet && facet.code === TYPE);
    const attributeType = attributes.find(
      (attribute) => attribute && attribute.attribute_code === TYPE
    );
    return (
      facetType &&
      attributeType &&
      isArrayHasItems(facetType.values) &&
      isArrayHasItems(attributeType.attribute_options) &&
      facetType.values.some(
        (facetValue) =>
          facetValue &&
          facetValue.value &&
          attributeType.attribute_options.some(
            (option) =>
              option &&
              option.value === facetValue.value &&
              option.code === CODE
          )
      )
    );
  }

  return false;
};

export default isAttributeHasAnixeTourismOption;
