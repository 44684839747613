import { combineReducers } from "redux";

import anixeFilters from "web/features/anixeFilters";
import api from "web/features/api/apiSlice";
import app from "web/features/app";
import attributes from "web/features/attributes";
import buyNow from "web/features/buyNow";
import campaignGraphics from "web/features/campaignGraphics";
import cart from "web/features/cart";
import categories from "web/features/categories";
import categoryCounts from "web/features/categoryCounts";
import codes from "web/features/codes";
import customerMenu from "web/features/customerMenu";
import facetsStats from "web/features/facetsStats";
import financingSources from "web/features/financingSources";
import products from "web/features/products";
import productsDomesticTourism from "web/features/productsDomesticTourism";
import productsTypes from "web/features/productsTypes";
import subscriptionQuote from "web/features/subscriptionQuote";
// import benefitGroups from "web/features/subscription";
import benefitGroups from "web/features/subscriptions";
import tourismAttributes from "web/features/tourismAttributes";
import tourismQuoteDomestic from "web/features/tourismQuoteDomestic";
import tourismQuoteInternational from "web/features/tourismQuoteInternational";
import wishlistProducts from "web/features/wishlistProducts";
import gtm from "web/features/gtm";

import graphQLApi from "../features/api/graphQLApiSlice";

const reducer = combineReducers({
  app,
  buyNow,
  cart,
  customerMenu,
  benefitGroups,
  codes,
  tourismQuoteInternational,
  tourismQuoteDomestic,
  subscriptionQuote,
  financingSources,
  anixeFilters,
  tourismAttributes,
  attributes,
  categoryCounts,
  categories,
  campaignGraphics,
  products,
  productsTypes,
  productsDomesticTourism,
  wishlistProducts,
  facetsStats,
  gtm,
  [api.reducerPath]: api.reducer,
  [graphQLApi.reducerPath]: graphQLApi.reducer,
});

export default reducer;
