import { FC } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { sortOptions } from "web/constants/toolbar";

import { PropsWithClasses } from "web/types/Common";

import SortFilter from "./sortFilter";

export type SortOptions = typeof sortOptions;
export type SortOptionsKeys = keyof SortOptions;
export type SortOptionsProperty = SortOptions[SortOptionsKeys];

export interface ISortFilterNewContainerProps {
  options: (SortOptions | SortOptionsProperty)[];
  darkMode?: boolean;
}

const SortFilterNewContainer: FC<
  PropsWithClasses<ISortFilterNewContainerProps>
> = ({ className = "", options = [], classes = {}, darkMode = false }) => {
  return isArrayHasItems(options) ? (
    <ErrorBoundary>
      <SortFilter
        className={className}
        options={options}
        classes={classes}
        darkMode={darkMode}
      />
    </ErrorBoundary>
  ) : null;
};

export default SortFilterNewContainer;
