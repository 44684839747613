// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.barcodeDownloaderItem-loading-element--small-roP{max-width:21px}`, "",{"version":3,"sources":["webpack://./web/Layout/SubscriptionActivities/ActivityItem/BarcodeDownloaderItem/barcodeDownloaderItem.scss"],"names":[],"mappings":"AAIuB,kDACrB,cAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .loading-element--small {\n  max-width: 21px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-element--small": `barcodeDownloaderItem-loading-element--small-roP`
};
export default ___CSS_LOADER_EXPORT___;
