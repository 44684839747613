import { FC, PropsWithChildren, useCallback } from "react";
import { useLocation } from "react-router-dom";

import __ from "web/Layout/Translations";

import MagnifierIcon from "web/assets/icons/magnifier.svg";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import getBackTo from "web/utils/system/url/getBackTo";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import { searchName } from "web/constants/toolbar";
import urls from "web/constants/urls";

import { Classes } from "web/types/Common";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import BackNavElement from "../BackNavElement";
import ModalSearch from "./ModalSearch";
import defaultClasses from "./headerSearch.scss";

interface IHeaderSearch {
  classes?: Classes;
  to?: string;
  back?: boolean;
  backCb?: () => void;
}

const HeaderSearch: FC<PropsWithChildren<IHeaderSearch>> = ({
  classes = {},
  to = "",
  back = false,
  children,
  backCb,
}) => {
  const { modal } = useAppContext();
  const { dispatch: modalDispatch } = modal;

  const openSearchModal = useCallback(() => {
    const closeAction = () => {
      modalDispatch({ type: ModalActionTypes.RESET });
    };
    modalDispatch({
      type: ModalActionTypes.ADD_MODAL,
      modal: <ModalSearch closeAction={closeAction} />,
    });
  }, [modalDispatch, ModalSearch]);
  const { pathname, search } = useLocation();

  const searchPhrase = getSearchParameter({ name: searchName, search });
  const text =
    pathname === urls.search && searchPhrase
      ? searchPhrase
      : __("Czego szukasz?");
  return (
    <div className={classes.root}>
      <BackNavElement to={getBackTo(to, search)} back={back} backCb={backCb} />
      <div className={classes.search}>
        <button
          className={classes.trigger}
          type="button"
          onClick={openSearchModal}
        >
          {text}
          <MagnifierIcon className={classes.icon} />
        </button>
      </div>
      {children}
    </div>
  );
};

export default classify<PropsWithChildren<IHeaderSearch>>(defaultClasses)(
  HeaderSearch
);
