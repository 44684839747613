const pageName = "page";
const pageSizeName = "pageSize";
const pageDefault = 1;
const pageSizeDefault = 10;
const pageGridDefaultSize = 12;
const sortName = "sort";
const filterName = "filter";
const sortDirectionName = "sortDir";
const searchName = "search";
const headerHeightOffset = 150;
const utmPrefix = "utm_";
const pageLayout = {
  name: "pageLayout",
  options: ["grid", "list"],
} as const;

const sortOptions = {
  default: {
    label: "Domyślne",
    name: null,
    direction: null,
  },
  date: {
    label: "Data",
    name: "created_at",
    direction: [
      {
        label: "od najnowszych",
        name: "DESC",
      },
      {
        label: "od najstarszych",
        name: "ASC",
      },
    ],
  },
  popularity: {
    label: "Polecamy",
    name: null,
    direction: null,
  },
  finalPrice: {
    label: "Cena",
    name: "final_price",
    direction: [
      {
        label: "od najniższej",
        name: "ASC",
      },
      {
        label: "od najwyższej",
        name: "DESC",
      },
    ],
  },
  name: {
    label: "Od",
    name: "name",
    direction: [
      {
        label: "A-Z",
        name: "ASC",
      },
      {
        label: "Z-A",
        name: "DESC",
      },
    ],
  },
  standard: {
    label: "Standard",
    name: "hotel_standard",
    direction: [
      {
        label: "od najniższego",
        name: "ASC",
      },
      {
        label: "od najwyższego",
        name: "DESC",
      },
    ],
  },
  rating: {
    label: "Ocena",
    name: "rating_summary",
    direction: [
      {
        label: "od najniższej",
        name: "ASC",
      },
      {
        label: "od najwyższej",
        name: "DESC",
      },
    ],
  },
  reviewCount: {
    label: "Ocena",
    name: "rating",
    direction: [
      {
        label: "od najmniejszej il. opinii",
        name: "ASC",
      },
      {
        label: "od największej il. opinii",
        name: "DESC",
      },
    ],
  },
} as const;
const sortOrderOptions = [sortOptions.date];
const sortReviewsOptions = [sortOptions.date, sortOptions.rating];
const sortRoomOffersOptions = [sortOptions.default, sortOptions.finalPrice];
const sortCategoryOptions = [
  sortOptions.popularity,
  sortOptions.finalPrice,
  sortOptions.name,
  {
    ...sortOptions.rating,
    direction: [sortOptions.rating.direction[1]],
  },
  {
    ...sortOptions.reviewCount,
    direction: [sortOptions.reviewCount.direction[1]],
  },
];
const sortCategoryAnixeOptions = [
  sortOptions.default,
  sortOptions.finalPrice,
  sortOptions.name,
  sortOptions.standard,
];
export {
  searchName,
  sortName,
  sortDirectionName,
  pageName,
  pageDefault,
  filterName,
  pageSizeName,
  pageSizeDefault,
  pageGridDefaultSize,
  sortOptions,
  sortOrderOptions,
  sortReviewsOptions,
  sortCategoryOptions,
  sortRoomOffersOptions,
  sortCategoryAnixeOptions,
  headerHeightOffset,
  pageLayout,
  utmPrefix,
};
