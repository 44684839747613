import { FC, SyntheticEvent, memo } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import urls from "web/constants/urls";

import { PropsWithClasses } from "web/types/Common";
import { Nullable } from "web/types/Utils";

import classify from "web/classify";
import { closeNavigation } from "web/features/app/appSlice";

import type { NavigationItem } from "../navigationPanel";
import Points from "./Points";
import defaultClasses from "./panelItem.scss";

interface IPanelItemProps {
  item: NavigationItem;
  pathname: string;
  pageType: string;
  isNavigationOpened: boolean;
}

const PanelItem: FC<PropsWithClasses<IPanelItemProps>> = memo(
  ({ classes = {}, item, pathname = "", isNavigationOpened }) => {
    const dispatch = useDispatch();
    const { name, link, icon: Icon } = item;
    const isActive =
      !isNavigationOpened &&
      // 0. Default behaviour
      (pathname === link ||
        // Behaviour for all "/customer" tabs
        // 1. Must start with "/customer"
        (link.indexOf(urls.customer) === 0 &&
          pathname.indexOf(urls.customer) === 0 &&
          // 2. Can't be "/customer/wishlist" cause wishlist would be checked while being on "/customer"/
          // "/customer/wishlist" is handled by default behaviour
          link !== urls.customerWishlist &&
          // 3. Behaviour from 2 can apply only while being on "/customer/something"
          pathname !== urls.home &&
          pathname !== urls.productsQuickList &&
          pathname !== urls.customerWishlist &&
          pathname !== urls.contactForm) ||
        // 4. Spacial behaviour for help-center
        (link === urls.customer &&
          (pathname.indexOf(urls.helpCenter) === 0 ||
            pathname.indexOf(urls.contactForm) === 0)));
  
    const isCustomer = typeof pathname === "string" && pathname === urls.customer;
    const classNameRoot = isActive
      ? `${classes.root} ${classes.rootActive}`
      : classes.root;
    const classNameRootCustomer = isCustomer
      ? `${classNameRoot} ${classes.customer}`
      : classNameRoot;
  
    const content = (
      <span className={classes.iconBox}>
        <Icon className={classes.icon} />
      </span>
    );
  
    const history = useHistory();
  
    const isTouchScreen = "ontouchstart" in document.documentElement;
    const touchEnd = isTouchScreen ? "touchend" : "mouseup";
  
    let lastTimer = 0;
    let startTimer = 0;
    let doubleClickFlag = false;
    let clickTarget: Nullable<HTMLElement> = null;
    const DOUBLE_CLICK_TIMEOUT = 250;
  
    function clickTypeDetector(
      event: SyntheticEvent,
      singleClickHandler: Nullable<() => void>,
      doubleClickHandler: Nullable<() => void>,
      longClickHandler: Nullable<() => void>
    ) {
      startTimer = new Date().getTime();
      doubleClickFlag = false;
  
      if (startTimer - lastTimer < DOUBLE_CLICK_TIMEOUT) {
        lastTimer = 0;
        doubleClickFlag = true;
        if (doubleClickHandler) {
          doubleClickHandler();
        }
      }
  
      clickTarget = event.currentTarget as HTMLElement;
  
      clickTarget.addEventListener(touchEnd, function handler() {
        const touchTimer = new Date().getTime();
  
        if (touchTimer - startTimer >= DOUBLE_CLICK_TIMEOUT) {
          if (longClickHandler) {
            longClickHandler();
          }
        } else {
          setTimeout(() => {
            if (!doubleClickFlag) {
              if (singleClickHandler) {
                singleClickHandler();
              }
            }
          }, DOUBLE_CLICK_TIMEOUT);
        }
        clickTarget?.removeEventListener(touchEnd, handler);
      });
  
      lastTimer = new Date().getTime();
      return 0;
    }
  
    return (
      <div className={classNameRootCustomer}>
        {isActive ? (
          <>
            {isTouchScreen ? (
              <span
                className={classes.link}
                title={name}
                data-t1="panel_item_link"
                data-t2={name}
                onTouchStart={(e) =>
                  clickTypeDetector(
                    e,
                    null,
                    () => {
                      history.push(link);
                    },
                    () => {
                      history.push(link);
                    }
                  )
                }
              >
                {content}
              </span>
            ) : (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <span
                className={classes.link}
                title={name}
                data-t1="panel_item_link"
                data-t2={name}
                onMouseDown={(e) =>
                  clickTypeDetector(
                    e,
                    null,
                    () => {
                      history.push(link);
                    },
                    () => {
                      history.push(link);
                    }
                  )
                }
              >
                {content}
              </span>
            )}
          </>
        ) : (
          <>
            {isTouchScreen ? (
              <span
                className={classes.link}
                title={name}
                data-t1="panel_item_link"
                data-t2={name}
                onTouchStart={(e) =>
                  clickTypeDetector(
                    e,
                    () => {
                      history.push(link);
                      dispatch(closeNavigation());
                    },
                    null,
                    null
                  )
                }
              >
                {content}
                {urls.customer === link && <Points />}
              </span>
            ) : (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <span
                className={classes.link}
                title={name}
                data-t1="panel_item_link"
                data-t2={name}
                onMouseDown={(e) =>
                  clickTypeDetector(
                    e,
                    () => {
                      history.push(link);
                      dispatch(closeNavigation());
                    },
                    null,
                    null
                  )
                }
              >
                {content}
                {urls.customer === link && <Points />}
              </span>
            )}
          </>
        )}
      </div>
    );
  });

export default classify<PropsWithClasses<IPanelItemProps>>(defaultClasses)(
  PanelItem
);