import { FC } from "react";

import __ from "web/Layout/Translations";

import downloadFile from "web/api/downloadFile";

import ActivityItem, { IActivityItemProps } from "../activityItem";

export interface IFileDownloaderActivityItem extends IActivityItemProps {
  file: string;
}

const FileDownloaderActivityItem: FC<IFileDownloaderActivityItem> = ({
  file,
  name,
  ...restProps
}) => {
  const onDownloadFileHandler = () => {
    downloadFile(file, name, true);
  };

  return (
    <ActivityItem {...restProps} name={name} onClick={onDownloadFileHandler}>
      {__("Pobierz")}
    </ActivityItem>
  );
};

export default FileDownloaderActivityItem;
