import { ErrorData } from "web/api/apiRequestTypes";

const INFO_IDENTIFIER = "Additional info:";

/**
 * Function retreving additional information from server error.
 * To be used with `request()` method in catch block.
 * @param {object} errorData - error response from server, parsed from `.json()` method.
 */
export default (errorData: ErrorData) => {
  try {
    const { message } = errorData;

    if (typeof message === "string") {
      const additionalInfoIndex =
        message.indexOf(INFO_IDENTIFIER) + INFO_IDENTIFIER.length;
      const additionalInfo = message.substring(
        additionalInfoIndex,
        message.length
      );
      return JSON.parse(additionalInfo);
    }
    return {};
  } catch {
    return {};
  }
};
