import { BankResetItems, IBankItem } from "web/types/Banks";

import { IBanksReset } from "./types";

interface IGetBanksResetArgs {
  activeBanksMap: Map<string, IBankItem>;
  itemsReset?: BankResetItems;
}

const getBanksReset = ({ activeBanksMap, itemsReset }: IGetBanksResetArgs) =>
  itemsReset?.reduce((acc, itemFromResets) => {
    const activeBank = activeBanksMap.get(itemFromResets.points_bank_id);
    if (activeBank) {
      acc.push({
        ...activeBank,
        resetTime: itemFromResets.reset_datetime,
      });
    }
    return acc;
  }, [] as IBanksReset[]) || [];

export default getBanksReset;
