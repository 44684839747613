import { FC } from "react";

import ProductsCommon from "web/Layout/Listing/Common/Products";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import placements from "web/constants/campaignGraphics";

import { ICampaignGraphics } from "web/types/CampaignGraphics";
import { ICategory } from "web/types/Category";

import { useCategoryContext } from "web/context/category";

interface IListingCategoryProductsProps {
  ids: (number | string)[];
  isAnixeTourism?: boolean;
  additionalParameters: string;
  activeFilters: string[];
  categoriesPath: string;
}

const ListingCategoryProducts: FC<IListingCategoryProductsProps> = ({
  ids,
  isAnixeTourism = false,
  additionalParameters = "",
  activeFilters = [],
  categoriesPath = "",
}) => {
  const { campaignGraphics, category = {} as ICategory } =
    useCategoryContext() as {
      campaignGraphics: ICampaignGraphics[];
      category: ICategory;
    };
  const { id } = category;
  const productCampaign =
    isArrayHasItems(campaignGraphics) &&
    campaignGraphics.find(
      (graphic) => graphic.position === placements.itemInCategory
    );

  return (
    <ProductsCommon
      ids={ids as number[]}
      campaign={productCampaign as ICampaignGraphics}
      categoryId={id}
      activeFilters={activeFilters}
      isAnixeTourism={isAnixeTourism}
      additionalParameters={additionalParameters}
      categoriesPath={categoriesPath}
    />
  );
};

export default ListingCategoryProducts;
