import {
  DUPLICATE_ADDITIONAL_FIELDS_NAME,
  DUPLICATE_DATA_TYPE,
} from "web/constants/benefits";

import { IDuplicateCardFormState } from "web/types/Subscription";

interface IParsedObj {
  reason?: { value?: string; required?: boolean; id?: string };
  userComment?: { value?: string; required?: boolean };
  newFirstName?: string | { value?: string; required?: boolean };
  newLastName?: string | { value?: string; required?: boolean };
  place?:
    | {
        street?: string | { value?: string; required?: boolean };
        city?: string | { value?: string; required?: boolean };
        "post-code"?: string | { value?: string; required?: boolean };
        houseNumber?: string | { value?: string; required?: boolean };
        flatNumber?: string | { value?: string; required?: boolean };
      }
    | { id?: string; name?: string };
}

const getField = (
  formFieldsState: IDuplicateCardFormState,
  fieldName: string
) => {
  const fieldStateName =
    formFieldsState[fieldName as keyof IDuplicateCardFormState];
  return typeof fieldStateName === "object" ? fieldStateName?.value : "";
};

const prepareDuplicateFormsData = (
  formFieldsState: IDuplicateCardFormState,
  placeDuplicateDeliveredType: string
) => {
  const { address, jo } = DUPLICATE_DATA_TYPE;
  const {
    city,
    houseNumber,
    flatNumber,
    "post-code": postCode,
    street,
  } = DUPLICATE_ADDITIONAL_FIELDS_NAME;
  let parsedObj: IParsedObj = {};
  Object.keys(formFieldsState).forEach((fieldKey) => {
    const formFieldsStateEle =
      formFieldsState[fieldKey as keyof IDuplicateCardFormState];
    const isFormFieldState =
      typeof formFieldsStateEle !== "string" && formFieldsStateEle;
    if (isFormFieldState && formFieldsStateEle.value)
      parsedObj[fieldKey as keyof IParsedObj] = formFieldsStateEle.value;

    if (isFormFieldState && formFieldsStateEle.id)
      parsedObj[fieldKey as keyof IParsedObj] = formFieldsStateEle.id;

    if (
      isFormFieldState &&
      fieldKey === "place" &&
      placeDuplicateDeliveredType === jo
    ) {
      parsedObj[fieldKey] = {
        id: formFieldsStateEle.id,
        name: formFieldsStateEle.value,
      };
    }

    if (
      isFormFieldState &&
      fieldKey === "place" &&
      placeDuplicateDeliveredType === "address"
    ) {
      parsedObj[fieldKey] = {
        city: getField(formFieldsState, city),
        flatNumber: getField(formFieldsState, flatNumber),
        houseNumber: getField(formFieldsState, houseNumber),
        "post-code": getField(formFieldsState, postCode),
        street: getField(formFieldsState, street),
      };
    }
  });

  if (placeDuplicateDeliveredType === address) {
    const { reason, newFirstName, newLastName, userComment, place } = parsedObj;
    parsedObj = {
      reason,
      newFirstName,
      newLastName,
      userComment,
      place: {
        ...place,
      },
    };
  }

  return parsedObj;
};

export default prepareDuplicateFormsData;
