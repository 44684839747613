const BIRTH_DATE_MAX_YEARS_AGO = 150;

const minDate = new Date();
minDate.setFullYear(minDate.getFullYear() - BIRTH_DATE_MAX_YEARS_AGO);

export const BIRTH_DATE_MIN = minDate;

const today = new Date();

export const BIRTH_DATE_MAX = today;

export const INPUT_TYPES = {
  REQUIRE_PESEL: "requirePesel",
  PESEL: "pesel",
  EMAIL: "E-Mail",
  FA_EMAIL: "email",
  PHONE: "phone",
  POSTCODE: "postcode",
  NAME: "firstname",
  LAST_NAME: "lastname",
};

export const DEFAULT_MULTIVALUES = {
  [INPUT_TYPES.REQUIRE_PESEL]: {
    pesel: "",
    birthDate: null,
    babyOrForeigner: false,
  },
};
