import aboImage2 from "web/assets/images/abo_podst_baner2.png";
import aboImage from "web/assets/images/abo_podst_baner.png";

import { ISubscriptionConfigWithEmployeeSettings } from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

interface ISubscriptionEnable {
  subscriptionThresholdDayUMS?: Nullable<string>;
  subscriptionThresholdDay?: Nullable<string>;
}

export default (
  config: ISubscriptionConfigWithEmployeeSettings,
  subscriptionEnable: ISubscriptionEnable
) => {
  if (config) {
    const { subscriptionThresholdDayUMS, subscriptionThresholdDay } =
      subscriptionEnable;

    const selectionWindowData = [
      {
        selectionWindowOpenToDate: config.selectionWindowOpenToDate,
        selectionWindowOpenToDay: config.selectionWindowOpenToDay,
        selectionWindowType: config.selectionWindowType,
        group: 1,
        image: aboImage,
        isWindowOpen: config.open,
        subscriptionThresholdDay,
        isGroupAvailable: config.configuratorWindowOpen,
        showOnListing: config.showSubscriptionsInListing === "1",
      },
      {
        selectionWindowOpenToDate: config.selectionWindowOpenToDateUms,
        selectionWindowOpenToDay: config.selectionWindowOpenToDayUms,
        selectionWindowType: config.selectionWindowTypeUms,
        group: 2,
        image: aboImage2,
        isWindowOpen: config.openUms,
        subscriptionThresholdDay: subscriptionThresholdDayUMS,
        isGroupAvailable: config.configuratorWindowOpenUms,
        showOnListing: config.showSubscriptionsInListingUMS === "1",
      },
    ];

    return selectionWindowData;
  }

  return null;
};
