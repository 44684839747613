import { FC, memo } from "react";

import LinkTransition from "web/Layout/Common/LinkTransition";
import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultStyles from "./sectionList.scss";

interface ISectionListItem {
  name?: string;
  url?: string;
  href?: string;
  title?: string;
  text?: string;
  isAnchor?: boolean;
  openInNewTab?: boolean;
}

interface ISectionListProps {
  items: ISectionListItem[];
  title: string;
  onClick?: (arg?: unknown) => unknown;
  keyProperty?: keyof ISectionListItem;
}

const SectionList: FC<PropsWithClasses<ISectionListProps>> = memo(
  ({ classes = {}, items, title = "", onClick, keyProperty = "name" }) => {
    const titleTemplate = title ? (
      <h3 className={classes.title}>{__(title)}</h3>
    ) : null;

    return isArrayHasItems(items) ? (
      <section className={classes.root}>
        {titleTemplate}
        <ul className={classes.list}>
          {items.map((item) => (
            <li className={classes.item} key={item[keyProperty] as string}>
              <LinkTransition
                url={item.url || item.href}
                name={__(item.title || item.name || item.text || "")}
                onClick={onClick}
                dataT1="section_list"
                isAnchor={item.isAnchor}
                openInNewTab={item.openInNewTab}
              />
            </li>
          ))}
        </ul>
      </section>
    ) : null;
  }
);

export default classify<PropsWithClasses<ISectionListProps>>(defaultStyles)(
  SectionList
);
