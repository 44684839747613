import { FC, PropsWithChildren } from "react";

import __ from "web/Layout/Translations";

import classes from "./subscriptionSummary.scss";

interface ISubscriptionSummaryProps {
  name: string;
  quantity: number;
}

const SubscriptionSummary: FC<PropsWithChildren<ISubscriptionSummaryProps>> = ({
  children,
  name,
  quantity,
}) => {
  return (
    <article className={classes.root}>
      <div className={classes.header}>
        <div className={classes.name}>
          <span>{name}</span>
        </div>
        <div className={classes.details}>
          <div
            className={classes.item}
            data-t1="numberOfPeople"
            data-t2={quantity}
          >{`${quantity} ${__("os.")}`}</div>
        </div>
      </div>
      {children}
    </article>
  );
};

export default SubscriptionSummary;
