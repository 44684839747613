import {
  DetailedHTMLProps,
  FC,
  LiHTMLAttributes,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
} from "react";

import { Classes } from "web/types/Common";
import { Nullable } from "web/types/Utils";

import classify from "web/classify";

import defaultClasses from "./activityItem.scss";

export interface IActivityItemProps
  extends Omit<
    DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>,
    "onClick"
  > {
  name: string;
  description: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  additionalElement?: ReactElement;
  disabled?: boolean;
  loading?: boolean;
  classes?: Classes;
  receiverName?: string;
  receiverLastname?: string;
  receiverEmail?: Nullable<string>;
}

export type ActivityItemProps = PropsWithChildren<IActivityItemProps>;

const ActivityItem: FC<ActivityItemProps> = ({
  children,
  name,
  description,
  onClick,
  additionalElement,
  disabled = false,
  loading = false,
  classes = {},
  receiverName,
  receiverLastname,
  receiverEmail,
  ...restProps
}) => (
  <li className={classes.item} {...restProps}>
    <div className={classes.itemName}>
      {name}
      {receiverName && receiverLastname && (
        <span
          className={defaultClasses.itemName__receiver}
        >{`${receiverName} ${receiverLastname}`}</span>
      )}
      {receiverEmail && !receiverName && (
        <span
          className={defaultClasses.itemName__receiver}
        >{`${receiverEmail}`}</span>
      )}
    </div>
    <div className={classes.itemDesc}>
      {description}
      {additionalElement}
    </div>
    <div className={classes.itemAction}>
      <button
        type="button"
        className={`${classes.buttonAction} ${
          loading ? classes["buttonAction--loading"] : ""
        } `}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </div>
  </li>
);

export default classify<ActivityItemProps>(defaultClasses)(ActivityItem);
