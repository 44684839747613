// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchMobile-root-DRg{display:block}.searchMobile-wrapper-hVm{display:flex;flex-direction:column}.searchMobile-form-J00{display:block}.searchMobile-autocomplete-sri{flex-grow:1;padding:20px}`, "",{"version":3,"sources":["webpack://./web/Layout/SearchAutocomplete/searchMobile.scss"],"names":[],"mappings":"AAIuB,uBACrB,aAAA,CAGF,0BACE,YAAA,CACA,qBAAA,CAGF,uBACE,aAAA,CAGF,+BACE,WAAA,CACA,YAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: block;\n}\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n.form {\n  display: block;\n}\n\n.autocomplete {\n  flex-grow: 1;\n  padding: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `searchMobile-root-DRg`,
	"wrapper": `searchMobile-wrapper-hVm`,
	"form": `searchMobile-form-J00`,
	"autocomplete": `searchMobile-autocomplete-sri`
};
export default ___CSS_LOADER_EXPORT___;
