import {
  DocumentNode,
  QueryHookOptions,
  TypedDocumentNode,
  useQuery,
} from "@apollo/client";
import { shape } from "prop-types";

import usePrevious from "web/hooks/usePrevious";

const useQueryWrapper = <T = unknown>(
  query: DocumentNode | TypedDocumentNode,
  options?: QueryHookOptions
) => {
  const { loading, error, data, fetchMore } = useQuery<T>(query, options);
  const previousData = usePrevious(data);

  return {
    loading,
    error,
    data:
      loading &&
      (!data || !Object.keys(data).length) &&
      previousData &&
      Object.keys(previousData).length
        ? previousData
        : data,
    fetchMore,
  };
};

useQueryWrapper.propTypes = {
  query: shape({}).isRequired,
  options: shape({}).isRequired,
};

export default useQueryWrapper;
