// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-root-OnR{display:flex;justify-content:space-between}.dropdown-section-OjU,.dropdown-sectionColumn-iGU{flex-grow:1;flex-shrink:1;flex-basis:0;padding:30px;overflow-wrap:anywhere}.dropdown-twoMenusContainer-Tp1{flex-grow:1;flex-shrink:1;flex-basis:0}.dropdown-twoMenusContainer-Tp1 .dropdown-section-OjU:first-child,.dropdown-twoMenusContainer-Tp1 .dropdown-sectionColumn-iGU:first-child{padding-bottom:0}.dropdown-sectionColumn-iGU{display:flex;flex-direction:column}`, "",{"version":3,"sources":["webpack://./web/Layout/MainHeader/Desktop/Customer/Dropdown/dropdown.scss"],"names":[],"mappings":"AAIuB,mBACnB,YAAA,CACA,6BAAA,CAGJ,kDACI,WAAA,CACA,aAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CAGJ,gCACI,WAAA,CACA,aAAA,CACA,YAAA,CAEA,0IACI,gBAAA,CAIR,4BAEI,YAAA,CACA,qBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n    display: flex;\n    justify-content: space-between;\n}\n\n.section {\n    flex-grow: 1;\n    flex-shrink: 1;\n    flex-basis: 0;\n    padding: 30px;\n    overflow-wrap: anywhere;\n}\n\n.twoMenusContainer {\n    flex-grow: 1;\n    flex-shrink: 1;\n    flex-basis: 0;\n    \n    .section:first-child {\n        padding-bottom: 0;\n    }\n}\n\n.sectionColumn {\n    @extend .section;\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dropdown-root-OnR`,
	"section": `dropdown-section-OjU`,
	"sectionColumn": `dropdown-sectionColumn-iGU`,
	"twoMenusContainer": `dropdown-twoMenusContainer-Tp1`
};
export default ___CSS_LOADER_EXPORT___;
