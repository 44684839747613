interface IGetSearchParameterAllArgs {
  name: string;
  search: string;
}

const getSearchParameterAll = ({
  name,
  search,
}: IGetSearchParameterAllArgs) => {
  if (typeof name === "string" && typeof search === "string") {
    const urlParams = new URLSearchParams(search);
    if (urlParams.has(name)) {
      const paramsByName = urlParams.getAll(name);

      if (Array.isArray(paramsByName) && paramsByName.length) {
        return paramsByName.map((param) => {
          try {
            return decodeURIComponent(param);
          } catch (error) {
            return param;
          }
        });
      }
    }
  }

  return [];
};

export default getSearchParameterAll;
