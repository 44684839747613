import { FC } from "react";

import classes from "./dropdown.scss";

interface IDropdownProps {
  options: {
    value: string;
    label: string;
  }[];
  onChange: (val: string) => void;
  value: string;
}

const Dropdown: FC<IDropdownProps> = ({ options, onChange, value }) => {
  return (
    <div className={classes.root}>
      {options?.map((option) => {
        return (
          <button
            className={classes.option}
            key={option.value}
            type="button"
            onClick={() => {
              onChange(option.value);
            }}
            data-t2={option.value}
            data-t1="distance_dropdown_button"
            disabled={value === option.value}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
};

export default Dropdown;
