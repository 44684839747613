import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import jsonParse from "../../data/parser/string/jsonParse";

const BANK_PAYMENTS_TYPE = "BANK_OF_POINTS";
const ONLINE_PAYMENTS_TYPE = "ONLINE_PAYMENT";
const countBankAppearing = (bank, products) =>
  bank && isArrayHasItems(products)
    ? products.reduce(
        (sum, product) =>
          isArrayHasItems(product.payment_settings) &&
          product.payment_settings.find(
            (paymentSetting) => paymentSetting.code === bank.code
          )
            ? sum + 1
            : sum,
        0
      )
    : 0;

export default ({ products, banks, name, separated }) => {
  if (!isArrayHasItems(products)) {
    return [];
  }

  const productsWithActivePaymentSettings = products.map(
    ({ payment_settings: paymentSettings, ...rest }) => {
      const paymentSettingsParsed =
        typeof paymentSettings === "string"
          ? jsonParse(paymentSettings)
          : paymentSettings;
      const paymentSettingsActive = isArrayHasItems(paymentSettingsParsed)
        ? paymentSettingsParsed.filter(({ active, code, type }) => {
            return type === "BANK_OF_POINTS"
              ? active &&
                  isArrayHasItems(banks) &&
                  banks.some(
                    (bank) =>
                      bank &&
                      bank.points_bank_id === code &&
                      bank.blockade_locked === 0 &&
                      +bank.balance &&
                      +bank.balance > 0
                  )
              : active;
          })
        : [];

      return {
        ...rest,
        paymentSettings: paymentSettingsActive,
      };
    }
  );

  const productsSeparatedWithTypes = isArrayHasItems(separated)
    ? separated.map(({ payment_settings: paymentSettings, sku }) => {
        const productCurrent = products.find((product) => product.sku === sku);
        const paymentSettingsWithTypes =
          productCurrent && paymentSettings
            ? paymentSettings.map(({ code, value }) => {
                const currentPaymentMethod =
                  productCurrent.payment_settings.find(
                    (method) => method.code === code
                  );
                return {
                  code,
                  value,
                  type: currentPaymentMethod.type,
                };
              })
            : [];

        return {
          sku,
          payment_settings: paymentSettingsWithTypes,
        };
      })
    : [];

  const productsToSeparateAutomatically = isArrayHasItems(
    productsSeparatedWithTypes
  )
    ? productsWithActivePaymentSettings.filter(
        ({ sku }) =>
          !productsSeparatedWithTypes.some((product) => product.sku === sku)
      )
    : productsWithActivePaymentSettings;

  const bankMappedWithBalances = isArrayHasItems(banks)
    ? banks.reduce((result, current) => {
        const bankId = current.points_bank_id;
        const balance = isArrayHasItems(productsSeparatedWithTypes)
          ? productsSeparatedWithTypes.reduce((subResult, subCurrent) => {
              const { payment_settings: paymentSettings } = subCurrent;
              const currentPayment = paymentSettings.find(
                (payment) => payment.code === bankId
              );

              return currentPayment
                ? +(subResult - currentPayment.value).toFixed(2)
                : subResult;
            }, current.balance)
          : current.balance;

        return {
          ...result,
          [bankId]: balance,
        };
      }, {})
    : {};

  const productsPaymentSettingsData = productsToSeparateAutomatically.reduce(
    (result, current, index, sourceArray) => {
      const { sku, paymentSettings } = current;
      if (isArrayHasItems(paymentSettings)) {
        let banksBalances = {
          ...result.banksBalances,
        };
        const paymentSettingsSorted = paymentSettings.sort(
          (firstPayment, secondPayment) => {
            if (firstPayment.type === ONLINE_PAYMENTS_TYPE) {
              return 1;
            }

            if (secondPayment.type === ONLINE_PAYMENTS_TYPE) {
              return -1;
            }

            const firstBankCountAppearing = countBankAppearing(
              firstPayment,
              sourceArray
            );
            const secondBankCountAppearing = countBankAppearing(
              secondPayment,
              sourceArray
            );

            switch (true) {
              case firstBankCountAppearing < secondBankCountAppearing: {
                return -1;
              }
              case firstBankCountAppearing > secondBankCountAppearing: {
                return 1;
              }
              default:
                return 0;
            }
          }
        );
        const productData = paymentSettingsSorted.reduce(
          (subResult, subCurrent) => {
            switch (true) {
              case !subResult.productPriceTotal: {
                return subResult;
              }
              case subCurrent.type === ONLINE_PAYMENTS_TYPE: {
                return {
                  productPriceTotal: 0,
                  productPaymentSettingsProcessed: [
                    ...subResult.productPaymentSettingsProcessed,
                    {
                      type: subCurrent.type,
                      code: subCurrent.code,
                      value: subResult.productPriceTotal,
                    },
                  ],
                };
              }
              case subCurrent.type === BANK_PAYMENTS_TYPE: {
                const currentBankBalance =
                  banksBalances &&
                  Object.prototype.hasOwnProperty.call(
                    banksBalances,
                    subCurrent.code
                  )
                    ? banksBalances[subCurrent.code]
                    : 0;

                if (subResult.productPriceTotal && currentBankBalance) {
                  if (subResult.productPriceTotal >= currentBankBalance) {
                    banksBalances = {
                      ...banksBalances,
                      [subCurrent.code]: 0,
                    };

                    return {
                      productPriceTotal: +(
                        subResult.productPriceTotal - currentBankBalance
                      ).toFixed(2),
                      productPaymentSettingsProcessed: [
                        ...subResult.productPaymentSettingsProcessed,
                        {
                          type: subCurrent.type,
                          code: subCurrent.code,
                          value: currentBankBalance,
                        },
                      ],
                    };
                  }
                  banksBalances = {
                    ...banksBalances,
                    [subCurrent.code]: +(
                      currentBankBalance - subResult.productPriceTotal
                    ).toFixed(2),
                  };

                  return {
                    productPriceTotal: 0,
                    productPaymentSettingsProcessed: [
                      ...subResult.productPaymentSettingsProcessed,
                      {
                        type: subCurrent.type,
                        code: subCurrent.code,
                        value: subResult.productPriceTotal,
                      },
                    ],
                  };
                }

                return subResult;
              }
              default: {
                return subResult;
              }
            }
          },
          {
            productPriceTotal: current[name],
            productPaymentSettingsProcessed: [],
          }
        );

        return {
          banksBalances,
          items: [
            ...result.items,
            {
              sku,
              payment_settings: productData.productPaymentSettingsProcessed,
            },
          ],
        };
      }

      return result;
    },
    {
      banksBalances: bankMappedWithBalances,
      items: [],
    }
  );

  return [
    ...productsPaymentSettingsData.items,
    ...productsSeparatedWithTypes,
  ].map((item) =>
    item?.value ? { ...item, value: parsePaymentValue(item.value) } : item
  );
};

const parsePaymentValue = (value) => {
  if (value?.toFixed) {
    return value.toFixed(2);
  }

  return value;
};
