import Banner from "web/Components/Common/Banner";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import placements from "web/constants/campaignGraphics";

import { ICampaignGraphics } from "web/types/CampaignGraphics";

import { useCategoryContext } from "web/context/category";

const ListingCategoryBannerCampaign = () => {
  const { campaignGraphics } = useCategoryContext() as {
    campaignGraphics: ICampaignGraphics[];
  };
  const underFiltersCampaign =
    isArrayHasItems(campaignGraphics) &&
    campaignGraphics.find(
      (graphic) => graphic.position === placements.underFiltersCategory
    );

  return underFiltersCampaign ? <Banner banner={underFiltersCampaign} /> : null;
};

export default ListingCategoryBannerCampaign;
