/* eslint-disable @typescript-eslint/ban-ts-comment */
import DOMPurify from "dompurify";
import { Form, Formik } from "formik";
import { FC, PropsWithChildren, useMemo, useState } from "react";

import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import Checkbox from "web/Components/Common/Form/Checkbox";
import Field from "web/Components/Common/Form/Field";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import {
  getInitialValues,
  validateFields,
} from "web/utils/system/formValidator/validation";

import {
  useGetEmployeeConfigurationQuery,
  useGetEmployeeDetailsQuery,
  useUpdateEmployeeDetailsMutation,
} from "web/features/employee/employeeApiSlice";

import RentableGroupFromWrapper from "../RentableGroupFormWrapper/rentableGroupFormWrapper";
import classes from "./rentableGroup.scss";

// @ts-ignore
const RentableGroupDefaultForm: FC<PropsWithChildren> = ({ children }) => {
  const { data: employeeDetails, isLoading: isLoadingEmployeeDetails } =
    useGetEmployeeDetailsQuery();
  const [updateEmployeeDetailsFn, { isLoading: isUpdating }] =
    useUpdateEmployeeDetailsMutation({
      fixedCacheKey: "shared-update-employee",
    });
  const { rentableGroups: groups } = employeeDetails || {};
  const { data: employeeConfig, isLoading: isLoadingConfiguration } =
    useGetEmployeeConfigurationQuery();
  const {
    rentableGroupConfirmationContent: contentConfirmation,
    rentableGroupConfirmationContentSecondCheckbox:
      contentConfirmationSecondCheckbox,
  } = employeeConfig || {};

  const isConfigurationGotten =
    !isLoadingConfiguration || !isLoadingEmployeeDetails;

  const [isStatusChanged, toggleStatus] = useState(false);
  const rentableGroupActive = employeeDetails?.rentableGroupActive;
  const rentableGroupContentAbove = employeeDetails?.pathContentAbove?.includes(
    "content-above-for-new"
  )
    ? employeeConfig?.rentableGroupContentAbove
    : employeeConfig?.rentableGroupContentAboveForNew;

  const [validate, fields, initialValues] = useMemo(() => {
    const selectOptions = isArrayHasItems(groups)
      ? groups.reduce<{ name: string; label: string }[]>((result, group) => {
          return group
            ? [
                ...result,
                {
                  name: group.id,
                  label: group.frontendName,
                },
              ]
            : result;
        }, [])
      : [];

    const result: [
      {
        name: string;
        placeholder: string;
        select: boolean;
        initialValue: string;
        rules: string[];
        selectOptions: {
          name: string;
          label: string;
        }[];
      },
      ...{
        name: string;
        type: string;
        label: string;
        initialValue: false;
        rules: string[];
      }[]
    ] = [
      {
        name: "incomeGroup",
        placeholder: __("Wybierz"),
        select: true,
        initialValue: rentableGroupActive?.id || "",
        rules: ["required"],
        selectOptions,
      },
    ];
    // eslint-disable-next-line array-callback-return
    [contentConfirmation, contentConfirmationSecondCheckbox].map(
      (confirmation, index) =>
        confirmation &&
        result.push({
          name: `agreement-${index}`,
          type: "checkbox",
          label: confirmation,
          initialValue: false,
          rules: ["required"],
        })
    );

    // @ts-ignore
    const validateFunc = validateFields(result);
    // @ts-ignore
    const initialValuesMemo = result.length ? getInitialValues(result) : [];

    return [validateFunc, result, initialValuesMemo];
  }, [
    JSON.stringify(groups),
    JSON.stringify(rentableGroupActive),
    contentConfirmation,
    contentConfirmationSecondCheckbox,
  ]);
  const [fieldIncomeGroup, ...fieldAgreements] = fields;
  // @ts-ignore
  const submitHandler = async (values) => {
    const groupId = values && fieldIncomeGroup && values[fieldIncomeGroup.name];
    if (groupId) {
      const responseData = await updateEmployeeDetailsFn({
        id: groupId,
      }).unwrap();
      // @ts-ignore
      if (responseData?.status === "OK") {
        toggleStatus(true);
      }
    }
  };

  return isStatusChanged ? (
    children
  ) : (
    <>
      <RentableGroupFromWrapper>
        {isConfigurationGotten && (
          <div className={classes.content}>
            <h1
              className={`${classes.title} cke_editable`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  employeeConfig?.rentableGroupTitle as string
                ),
              }}
            />
            {rentableGroupContentAbove && (
              <div
                className={`${classes.contentAbove} reset-global-styles cke_editable`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(rentableGroupContentAbove),
                }}
              />
            )}
            <strong
              className={`${classes.subtitle} cke_editable`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  employeeConfig?.rentableGroupHeaderPopup as string
                ),
              }}
            />
            <Formik
              initialValues={initialValues}
              onSubmit={submitHandler}
              // @ts-ignore
              validate={validate}
              enableReinitialize
            >
              <Form className={classes.form}>
                <Field
                  className={classes.select}
                  name={fieldIncomeGroup.name}
                  select={fieldIncomeGroup.select}
                  selectOptions={fieldIncomeGroup.selectOptions}
                  dataT1="edit_income_group_field"
                  placeholder={fieldIncomeGroup.placeholder}
                  classesSelect={classes}
                />
                {fieldAgreements?.map((fieldAgreement) => (
                  <Checkbox
                    className={classes.checkbox}
                    name={fieldAgreement.name}
                  >
                    <div
                      className={`${classes.text} reset-global-styles cke_editable`}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        // @ts-ignore
                        __html: DOMPurify.sanitize(fieldAgreement.label),
                      }}
                    />
                  </Checkbox>
                ))}
                <div className={classes.footer}>
                  <span className={classes.required}>
                    {__("Pole obowiązkowe")}
                  </span>
                  <button
                    className={classes.submit}
                    type="submit"
                    disabled={isUpdating}
                  >
                    {__("Przejdź dalej")}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        )}
        {isUpdating || isLoadingEmployeeDetails || isLoadingConfiguration ? (
          <Loading className={classes.loader} />
        ) : null}
      </RentableGroupFromWrapper>
    </>
  );
};

export default RentableGroupDefaultForm;
