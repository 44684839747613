const checkIfDateIsValid = (year: number, month: number, day: number) => {
  const date = new Date(year, month, day);

  return !(
    date.getMonth() !== month ||
    date.getDate() !== day ||
    date.getFullYear() !== year
  );
};

export default checkIfDateIsValid;
