import { useMemo } from "react";

import type { IDomesticTourismRoom } from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormTypes";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";

interface ITourismProductOptions {
  dateFrom?: string;
  dateTo?: string;
  peopleAmount?: number;
}

const useGetTourismProductOptions = (
  additionalParameters: string,
  isFormattedDate = true
): ITourismProductOptions => {
  const { dateFrom, dateTo, peopleAmount } = useMemo(() => {
    if (!additionalParameters) return {};
    const parsedParams: {
      rooms: IDomesticTourismRoom[];
      date_from: string;
      date_to: string;
    } = JSON.parse(additionalParameters);
    const peopleAmount = parsedParams?.rooms?.reduce(
      (prev, { adults, children }) => {
        return prev + adults + children.length;
      },
      0
    );

    return {
      dateFrom: isFormattedDate
        ? parsedParams?.date_from
        : formatDate(parsedParams?.date_from, true),
      dateTo: isFormattedDate
        ? parsedParams?.date_to
        : formatDate(parsedParams?.date_to, true),
      peopleAmount,
    };
  }, [additionalParameters, isFormattedDate]);

  return { dateFrom, dateTo, peopleAmount };
};

export default useGetTourismProductOptions;
