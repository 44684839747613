import { arrayOf, shape, string } from "prop-types";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import classify from "web/classify";
import { useCheckoutContext } from "web/context/checkout";

import defaultClasses from "./detailsList.scss";

export const detailsListVariants = {
  DEFAULT: "default",
  ANIXE: "anixe",
};

const testIndicators = {
  [detailsListVariants.ANIXE]: {
    t1: ["number_of_rooms", "people_information", "trip_length"],
    t2: ["value", "value", "value"],
  },
};

const DetailsList = ({ classes, items, variant }) => {
  const context = useCheckoutContext();
  const listVariant =
    variant || context?.variant || detailsListVariants.DEFAULT;

  if (!isArrayHasItems(items)) {
    return null;
  }

  if (listVariant === detailsListVariants.DEFAULT) {
    return (
      <ul className={classes.root}>
        {items.map(({ name, value, dataT1 }) => (
          <li
            className={classes.item}
            key={`${name}${value}`}
            data-t2={`${value}`}
            data-t1={dataT1}
          >
            {name ? (
              <span data-t2="details_list_name" className={classes.name}>
                {name}:{" "}
              </span>
            ) : null}
            <span
              className={classes.value}
              data-t2={`${value}`}
              data-t1={`${dataT1}_value`}
            >
              {value}
            </span>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <ul className={classes.root}>
      {items.map(({ value }, idx) => (
        <li className={classes.item} key={`${value}`}>
          <span
            className={classes.value}
            data-t2={testIndicators[listVariant]?.t2?.[idx]}
            data-t1={testIndicators[listVariant]?.t1?.[idx]}
          >
            {value}
          </span>
        </li>
      ))}
    </ul>
  );
};

DetailsList.propTypes = {
  classes: shape({
    root: string,
    item: string,
    name: string,
    value: string,
  }).isRequired,
  items: arrayOf(shape({})),
};

DetailsList.defaultProps = {
  items: [],
};

export default classify(defaultClasses)(DetailsList);
