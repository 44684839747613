import { FC, MouseEventHandler, useCallback, useEffect } from "react";

import Dropdown from "web/Layout/MainHeader/Desktop/MainMenu/Dropdown";
import __ from "web/Layout/Translations";

import type { PropsWithClasses } from "web/types/Common";
import { ISubCategoryMenuItem, MenuItemType } from "web/types/Menu";
import type { Nullable } from "web/types/Utils";

import classify from "web/classify";

import defaultClasses from "./mainMenuItem.scss";

interface IMainMenuItemWithDropdownProps {
  item: ISubCategoryMenuItem<MenuItemType.TILES | MenuItemType.SUBCATEGORIES>;
  isActive: boolean;
  index: number;
  setIndex: (index: Nullable<number>) => void;
  navigationScope: string;
  dataT1: string;
}

const MainMenuItemWithDropdown: FC<
  PropsWithClasses<IMainMenuItemWithDropdownProps>
> = ({
  classes = {},
  item,
  index,
  isActive,
  setIndex,
  navigationScope,
  dataT1,
}) => {
  const clickHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();
      const nextIndex = isActive ? null : index;
      if (typeof setIndex === "function") {
        setIndex(nextIndex);
      }
    },
    [index, isActive, setIndex]
  );
  const dropdownClassName = isActive
    ? `${classes.dropdown} ${classes.dropdownActive}`
    : classes.dropdown;
  const triggerClassName = isActive
    ? `${classes.trigger} ${classes.triggerActive}`
    : classes.trigger;
  const closeClickOutsideScopeHandler = useCallback(
    (event: MouseEvent) => {
      if (
        navigationScope &&
        event &&
        event.target &&
        typeof (event.target as HTMLElement).closest === "function" &&
        !(event.target as HTMLElement).closest(navigationScope)
      ) {
        setIndex(null);
      }
    },
    [setIndex]
  );

  useEffect(() => {
    if (isActive) {
      document.addEventListener("click", closeClickOutsideScopeHandler);
    } else {
      document.removeEventListener("click", closeClickOutsideScopeHandler);
    }
    return () => {
      document.removeEventListener("click", closeClickOutsideScopeHandler);
    };
  }, [isActive]);

  return (
    <>
      <button
        className={triggerClassName}
        onClick={clickHandler}
        type="button"
        title={item.title}
        data-t1="main_menu_item_with_dropdown_button"
        data-t2={item.title}
      >
        {__(item.title)}
      </button>
      <Dropdown
        className={dropdownClassName}
        items={item.submenu}
        banners={item.tiles}
        closeAction={setIndex}
        dataT1={dataT1}
      />
    </>
  );
};
export default classify<PropsWithClasses<IMainMenuItemWithDropdownProps>>(
  defaultClasses
)(MainMenuItemWithDropdown);
