import { FC } from "react";

import __ from "web/Layout/Translations";

import DownloadAppGallery from "web/assets/icons/download_appGallery.svg";
import DownloadAppstore from "web/assets/icons/download_appstore.svg";
import DownloadGooglePlay from "web/assets/icons/download_googleplay.svg";
import DownloadNative from "web/assets/images/download_native.png";
import DownloadNativeWebp from "web/assets/images/download_native.webp";

import canUseWebp from "web/utils/system/DOM/media/canUseWebp";

import classes from "./modal.scss";

const Modal: FC = () => {
  const webpStatus = canUseWebp();
  const mobileImage = webpStatus ? DownloadNativeWebp : DownloadNative;

  return (
    <div className={classes.root}>
      <div className={classes.main_img}>
        <img
          src={mobileImage}
          alt="Pobierz aplikacje i korzystaj z Kafeterii szybciej"
        />
      </div>
      <p className={classes.heading}>{__("Używasz przeglądarki?")}</p>
      <p className={classes.description}>
        {__(
          "Pobierz aplikacje i korzystaj z Kafeterii szybciej, łatwiej i wygodniej."
        )}
      </p>
      <div className={classes.button_wrapper}>
        <a
          href="https://play.google.com/store/apps/details?id=com.mybenefit"
          className={classes.button}
          data-testid="link-google-play"
        >
          <DownloadGooglePlay data-testid="download-googleplay" />
        </a>
        <a
          href="https://apps.apple.com/in/app/mybenefit/id1601176160"
          className={classes.button}
          data-testid="link-app-store"
        >
          <DownloadAppstore data-testid="download-appstore" />
        </a>
        <a
          href="https://appgallery.huawei.com/app/C105648247"
          className={classes.button}
          data-testid="link-app-gallery"
        >
          <DownloadAppGallery data-testid="download-appgallery" />
        </a>
      </div>
    </div>
  );
};

export default Modal;
