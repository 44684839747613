import { FC, useEffect } from "react";

const withScrollToTop = <PropsType extends object>(
  WrappedComponent: FC<PropsType>
): FC<PropsType> => {
  const WithScrollToTop: FC<PropsType> = (props) => {
    useEffect(() => {
      if (window) {
        window.scroll(0, 0);
      }
    }, []);

    return <WrappedComponent {...props} />;
  };

  return WithScrollToTop;
};

export default withScrollToTop;
