import Messenger from "@intercom/messenger-js-sdk";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import getToken from "web/utils/system/essentials/getToken";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";
import {
  useGetEmployeeDetailsQuery,
  useGetEmployeeIntercomQuery,
} from "web/features/employee/employeeApiSlice";

import classes from "./intercom.scss";

enum Regions {
  EU = "eu",
}

const Intercom = () => {
  // set to test instance if env value doesnt exist
  const appId = process.env.INTERCOM_APP_ID || "qsbms4tn";
  const [isIntercomAccessGranted, setIsIntercomAccessGranted] = useState(false);
  const [isIntercomDisabledForRoute, setIsIntercomDisabledForRoute] =
    useState(false);
  const [isIntercomOpen, setIsIntercomOpen] = useState<boolean>(false);
  const { data: employeeDetails } = useGetEmployeeDetailsQuery();
  const { data: customer } = useGetCustomerDetailsQuery();
  const { data: intercom } = useGetEmployeeIntercomQuery();
  const location = useLocation();
  const [ssoUid, setSsoUid] = useState<string>("");

  useEffect(() => {
    setIsIntercomAccessGranted(
      !!employeeDetails?.functionalities?.find(
        (value: string) => value === "enable_intercom"
      )
    );
  }, [employeeDetails]);

  useEffect(() => {
    setIsIntercomDisabledForRoute(location.pathname.includes("checkout"));
  }, [location]);

  useEffect(() => {
    try {
      const localStorageToken =
        localStorage.getItem("FENIKS_BROWSER_PERSISTENCE__tokenAccess") || "";

      if (localStorageToken === "") {
        const InitializeIntercom = async () => {
          const token = await getToken();
          const { MyB_SSO_UID } = token
            ? jwt_decode<{ MyB_SSO_UID: string }>(token.accessToken)
            : { MyB_SSO_UID: "" };
          setSsoUid(MyB_SSO_UID);
        };
        InitializeIntercom();
      } else {
        setSsoUid(
          jwt_decode<{ MyB_SSO_UID: string }>(
            JSON.parse(localStorageToken)?.value
          ).MyB_SSO_UID
        );
      }
    } catch (error) {
      newRelicErrorReport(error, "web-app/web/Components/Intercom.tsx - 68");
      console.error(error);
    }
  }, []);

  if (!intercom?.hash) {
    return null;
  }

  if (isIntercomAccessGranted && !isIntercomDisabledForRoute && ssoUid !== "") {
    Messenger({
      app_id: appId,
      user_hash: intercom.hash,
      user_id: ssoUid,
      region: Regions.EU,
      name: `${customer?.firstname} ${customer?.lastname}`,
      language_override: customer?.language
        ? customer?.language.slice(0, 2)
        : "pl",
      user_language_mybenefit: customer?.language
        ? customer?.language.slice(0, 2)
        : "pl",
      background_color: "#00a1ed",
      action_color: "#00a1ed",
      custom_launcher_selector: "#customIntercomLauncher",
      hide_default_launcher: true,
      has_mybenefit_id: true,
    });

    return (
      <button
        type="button"
        style={{ display: "none" }}
        onClick={() => setIsIntercomOpen(!isIntercomOpen)}
        id="customIntercomLauncher"
        className={classes.intercomLauncher}
      >
        <div className={classes.svgBox}>
          <div className={isIntercomOpen ? classes.svgClose : classes.svgOpen}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
              <path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z" />
            </svg>
          </div>
          <div className={isIntercomOpen ? classes.svgOpen : classes.svgClose}>
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.601 8.39897C18.269 8.06702 17.7309 8.06702 17.3989 8.39897L12 13.7979L6.60099 8.39897C6.26904 8.06702 5.73086 8.06702 5.39891 8.39897C5.06696 8.73091 5.06696 9.2691 5.39891 9.60105L11.3989 15.601C11.7309 15.933 12.269 15.933 12.601 15.601L18.601 9.60105C18.9329 9.2691 18.9329 8.73091 18.601 8.39897Z"
              />
            </svg>
          </div>
        </div>
      </button>
    );
  }

  return null;
};

export default Intercom;
