import { FC } from "react";

import __ from "web/Layout/Translations";

import CityWithProvince from "web/Components/Common/CityWithProvince";
import LocationParent from "web/Components/Common/LocationParent";

import type { IProduct } from "web/types/Product";

import classes from "./location.scss";

interface IProductCardLocationProps {
  cities: IProduct["location_cities_cities"];
  province: IProduct["location_province"];
  location: IProduct["location_location_parent_location_parent"];
  city: IProduct["location_address_city"];
  code: IProduct["location_address_postal_code"];
  street: IProduct["location_address_street"];
}

const ProductCardLocation: FC<IProductCardLocationProps> = ({
  cities = null,
  province = [],
  location = null,
  city = null,
  code = null,
  street = null,
}) => {
  switch (true) {
    case typeof cities === "string" && !!cities.length: {
      const commaIndex = (cities as string).indexOf(",");
      switch (true) {
        case commaIndex !== -1: {
          return (
            <div className={classes.root}>
              <div
                className={classes.row}
                data-t1="location_multiple_locations"
              >
                {__("Miasto")}: {__("Wiele lokalizacji")}
              </div>
            </div>
          );
        }
        case !!province: {
          return (
            <CityWithProvince
              className={classes.root}
              city={cities as string}
              province={province}
            />
          );
        }
        default: {
          return (
            <div className={classes.root} data-t1="location_cities">
              {cities}
            </div>
          );
        }
      }
    }
    case !!location: {
      return (
        <LocationParent
          className={classes.root}
          location={(location as number).toString()}
        />
      );
    }
    case [city, code, street].some(
      (item) => typeof item === "string" && !!item.length
    ): {
      const cityText = city || "";
      const codeText = code ? `${city ? ", " : " "}${code}` : "";
      const streetText = street ? `${code ? ", " : " "}${street}` : "";
      const text = `${cityText}${codeText}${streetText}`.trim();
      return (
        <div className={classes.root} data-t1="location_text">
          {text}
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default ProductCardLocation;
