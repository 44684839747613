import { Button } from "@benefit-systems/common-components";
import DOMPurify from "dompurify";
import { Form, Formik, FormikProps, FormikValues } from "formik";
import { isArray } from "lodash";
import { FC, Fragment, useMemo, useRef } from "react";

import { DatepickerFormik } from "web/Layout/Datepicker";
import {
  BIRTH_DATE_MAX,
  BIRTH_DATE_MIN,
} from "web/Layout/SubscriptionReceivers/SubscriptionReceiverForm/ActiveForm/constants";
import __ from "web/Layout/Translations";

import Checkbox from "web/Components/Common/Form/Checkbox";
import Field from "web/Components/Common/Form/Field";
import RequirePeselForm from "web/Components/Common/Form/RequirePeselForm/requirePeselForm";
import SelectForm from "web/Components/Common/Form/Select/selectForm";
import ToolTip from "web/Components/Common/ToolTip";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import prepareActiveFormData from "web/utils/page/product/subscription/forms/prepareActiveFormData";
import { peselMask } from "web/utils/peselMask";
import { validateFields } from "web/utils/system/formValidator/validation";

import {
  ContentTypeActiveFormData,
  HeaderTypeActiveFormData,
  IActiveFormValues,
  ISubscriptionFormData,
  ISubscriptionReceiverFormField,
  ISubscriptionReceiverFormValues,
  SubscriptionActiveFormData,
  SubscriptionActiveFormDataType,
  SubscriptionActiveFormField,
  SubscriptionActiveFormFieldType,
} from "web/types/Subscription";

import styles from "./activeForm.scss";

interface IActiveFormProps {
  configuration: SubscriptionActiveFormData[];
  fields: ISubscriptionReceiverFormField[];
  formData: ISubscriptionFormData;
  lang?: {
    key: string;
    code: string;
  };
  nameFieldsDisabled: boolean;
  submitHandler: (values: ISubscriptionReceiverFormValues) => void;
}

const checkboxClass = {
  check: styles.check,
  icon: styles.icon,
  customLabel: styles.activeFormCustomLabel,
  checkError: styles.checkError,
  labelText: styles.activeFormLabelText,
};

const ActiveForm: FC<IActiveFormProps> = ({
  configuration,
  fields,
  formData,
  lang = { key: "pl_PL", code: "pl" },
  nameFieldsDisabled,
  submitHandler,
}) => {
  if (!isArray(fields) || !configuration) return null;

  const ref = useRef<FormikProps<FormikValues>>(null);

  const buttonsText =
    lang.key === "pl_PL" ? __("Zapisz i zamknij") : "Save and close";
  const selectPlaceholder =
    lang.key === "pl_PL" ? __("Wybierz opcję") : "Select option";

  const getHeader = (header: HeaderTypeActiveFormData) => {
    return (
      <strong className={styles.header} key={header.id}>
        {header.value}
      </strong>
    );
  };

  const getContent = (content: ContentTypeActiveFormData) => {
    if (!content.content) {
      return null;
    }

    const contentElement = (
      <div
        className={styles.content}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content.content),
        }}
      />
    );
    return (
      <div className="cke_editable" key={content.id}>
        <strong className={styles.title}>{content.name}</strong>
        <div className={styles.contentWrapper}>
          {content?.useCheckbox ? (
            <span className={styles.checkbox}>
              <Checkbox name={content.id} classes={checkboxClass}>
                <span className={styles.useCheckboxText}>{contentElement}</span>
              </Checkbox>
            </span>
          ) : (
            contentElement
          )}
        </div>
      </div>
    );
  };

  const getField = (field: SubscriptionActiveFormField) => {
    let input;
    let initialValuesPlaceholder;
    switch (field.type) {
      case SubscriptionActiveFormFieldType.REQUIRE_PESEL:
        input = (
          <RequirePeselForm
            field={field}
            checkboxClass={checkboxClass}
            classes={styles}
            tooltipMessage={field.tooltip}
            lang={lang}
          />
        );
        break;
      case SubscriptionActiveFormFieldType.SELECT:
        initialValuesPlaceholder =
          field?.options?.find(
            (el) => el.value === (initialValues as IActiveFormValues)[field.id]
          )?.label || (initialValues as IActiveFormValues)[field.id];
        input = (
          <SelectForm
            boldTextIfActive
            name={field.id}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            placeholder={initialValuesPlaceholder || selectPlaceholder}
            options={field.options.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            hasBackgroundWhenActive
          />
        );
        break;
      case SubscriptionActiveFormFieldType.CHECKBOX:
        input = (
          <Checkbox
            uppercase
            name={field.id}
            classes={checkboxClass}
            tooltipMessage={field.tooltip}
          >
            <span
              className={`${styles.label} ${styles.checkboxLabel}`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(field.label),
              }}
            />
          </Checkbox>
        );
        break;
      case SubscriptionActiveFormFieldType.BIRTHDATE:
      case SubscriptionActiveFormFieldType.DATE: {
        const isBirthDate =
          field.type === SubscriptionActiveFormFieldType.BIRTHDATE;

        const minDateValue = isBirthDate ? BIRTH_DATE_MIN : null;
        const maxDateValue = isBirthDate ? BIRTH_DATE_MAX : null;

        input = (
          <div className={`${styles.inputWrapper} ${styles.dataInputWrapper}`}>
            <DatepickerFormik
              name={field.id}
              label={field.label}
              placeholder={field.label}
              className={styles.input}
              minDate={minDateValue}
              maxDate={maxDateValue}
            />
          </div>
        );
        break;
      }
      case SubscriptionActiveFormFieldType.POSTCODE:
        input = <Field label={field.label} name={field.id} />;
        break;
      case SubscriptionActiveFormFieldType.FIRSTNAME:
      case SubscriptionActiveFormFieldType.LASTNAME: {
        input = (
          <Field
            label={field.label}
            name={field.id}
            disabled={nameFieldsDisabled}
          />
        );
        break;
      }
      case SubscriptionActiveFormFieldType.PESEL: {
        input = <Field label={field.label} name={field.id} mask={peselMask} />;
        break;
      }
      case SubscriptionActiveFormFieldType.NUMBER: {
        input = (
          <Field label={field.label} name={field.id} enableNumericHandler />
        );
        break;
      }
      default: {
        input = <Field label={field.label} name={field.id} type={field.type} />;
      }
    }

    const doesFieldHaveTooltip = field.tooltip?.trim();

    return (
      <Fragment key={field.id}>
        <div className={styles.tooltipWrapper}>
          {field.type !== SubscriptionActiveFormFieldType.CHECKBOX &&
            field.type !== SubscriptionActiveFormFieldType.REQUIRE_PESEL && (
              <dl className={styles.label}>
                {field.type === SubscriptionActiveFormFieldType.SELECT
                  ? field.title
                  : __(field.label)}
                {doesFieldHaveTooltip && (
                  <ToolTip
                    customStyles={{
                      root: styles.tooltipWrapper,
                      tooltip: styles.tooltip,
                    }}
                    message={field.tooltip}
                    variant="right"
                  />
                )}
              </dl>
            )}
        </div>
        {input}
      </Fragment>
    );
  };

  const [initialValues, fieldsToValidate] = useMemo(
    () => prepareActiveFormData(fields, formData, lang),
    [fields]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const validate = validateFields(fieldsToValidate);

  const formBody = useMemo(() => {
    if (!isArrayHasItems(configuration)) return null;

    return configuration.map((section) => {
      switch (section.type) {
        case SubscriptionActiveFormDataType.HEADER:
          return getHeader(section);
        case SubscriptionActiveFormDataType.CONTENT:
          return getContent(section);
        case SubscriptionActiveFormDataType.FORM:
          return (
            <Fragment key={section.id}>
              {section.fields.map((key) => getField(key))}
              <div className={styles.separator} />
            </Fragment>
          );
        default:
          return null;
      }
    });
  }, [configuration]);

  return (
    <Formik
      enableReinitialize
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={initialValues}
      onSubmit={submitHandler}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      validate={validate}
      innerRef={ref}
    >
      <Form noValidate>
        <div className={styles.root}>{formBody}</div>
        <div className={styles.buttons}>
          <Button variant="tertiary" type="submit">
            {buttonsText}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default ActiveForm;
