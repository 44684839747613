import { FC, memo, useMemo } from "react";

import __ from "web/Layout/Translations";

import getPersonTextByCount from "web/utils/data/transform/getPersonTextByCount";

import classes from "./rentableGroupCalculatorResult.scss";

interface IRentableGroupCalculatorResultProps {
  peopleCount: number;
  peopleCountCopy: string;
  averageIncome: number;
  averageIncomeCopy: string;
}

const RentableGroupCalculatorResult: FC<IRentableGroupCalculatorResultProps> =
  memo(({ peopleCount, peopleCountCopy, averageIncome, averageIncomeCopy }) => {
    const personText = useMemo(
      () => getPersonTextByCount(peopleCount),
      [peopleCount]
    );

    return (
      <section className={classes.root}>
        {/* people count */}
        <p className={classes.paragraph}>
          {peopleCountCopy}:{" "}
          <span className={`${classes.highlight} rg-calculator-highlight`}>
            {peopleCount} {__(personText)}
          </span>
        </p>

        {/* average income */}
        <p className={classes.paragraph}>
          {averageIncomeCopy}:{" "}
          <span className={`${classes.highlight} rg-calculator-highlight`}>
            {averageIncome.toFixed(2)} {__("zł")} {__("brutto")}
          </span>
        </p>
      </section>
    );
  });
export default RentableGroupCalculatorResult;
