import { FC, useCallback, useEffect, useRef } from "react";

import Filters from "web/Components/Common/Filters";
import Title from "web/Components/Common/Title";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import { IAttribute } from "web/types/Attributes";
import { Facets } from "web/types/Facets";
import { Stats } from "web/types/Stats";

import { useAppContext } from "web/context/app";

import Actions from "./Actions";
import classes from "./header.scss";
import modalClasses from "./modalClasses.scss";

export interface IListingMobileHeader {
  name: string;
  count?: number;
  stats?: Stats;
  facets?: Facets;
  facetsHidden?: string[];
  attributes?: IAttribute[];
}

const ListingMobileHeader: FC<IListingMobileHeader> = ({
  name,
  count,
  stats = [],
  facets = [],
  facetsHidden = [],
  attributes = [],
}) => {
  const { modal } = useAppContext();
  const { dispatch, component } = modal;

  const isModalLoaded = useRef(!!component);

  const openModal = useCallback(() => {
    dispatch({
      type: ModalActionTypes.ADD_MODAL,
      modal: (
        <Filters
          facetsHidden={facetsHidden}
          facets={facets}
          stats={stats}
          attributes={attributes}
        />
      ),
      classes: modalClasses,
    });

    isModalLoaded.current = true;
  }, [dispatch, facetsHidden, facets, stats, attributes]);

  useEffect(() => {
    if (isModalLoaded.current) {
      openModal();
    }
  }, [openModal]);

  // close modal on unmount
  useEffect(
    () => dispatch.bind(this, { type: ModalActionTypes.RESET }),
    [dispatch]
  );

  return (
    <header className={classes.root}>
      <Title name={name} count={count} />
      {!!count && <Actions className={classes.actions} openModal={openModal} />}
    </header>
  );
};

export default ListingMobileHeader;
