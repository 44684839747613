// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropArrow-root-ltO{display:inline-block;background-color:rgba(0,0,0,0);border:none;padding:0}.dropArrow-root_small-j5T{width:8px}.dropArrow-root_medium-ZBp{width:12px}.dropArrow-root_large-lKu{width:16px}.dropArrow-icon-pW8{width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./web/Components/Common/DropArrow/dropArrow.scss"],"names":[],"mappings":"AAIuB,oBACrB,oBAAA,CACA,8BAAA,CACA,WAAA,CACA,SAAA,CACA,0BACE,SAAA,CAEF,2BACE,UAAA,CAEF,0BACE,UAAA,CAIJ,oBACE,UAAA,CACA,WAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: inline-block;\n  background-color: transparent;\n  border: none;\n  padding: 0;\n  &_small {\n    width: 8px;\n  }\n  &_medium {\n    width: 12px;\n  }\n  &_large {\n    width: 16px;\n  }\n}\n\n.icon {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `dropArrow-root-ltO`,
	"root_small": `dropArrow-root_small-j5T`,
	"root_medium": `dropArrow-root_medium-ZBp`,
	"root_large": `dropArrow-root_large-lKu`,
	"icon": `dropArrow-icon-pW8`
};
export default ___CSS_LOADER_EXPORT___;
