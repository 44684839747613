import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import useIsNativeApp from "web/hooks/useIsNativeApp";
import { nativeGTMKey } from "web/constants/native";

const Gtm = () => {
  const { gtm_api_key: webGTMKey, gtm_api_enable: isEnabled } = useSelector(
    (state) => state.app.storeConfig
  );
  const isNativeApp = useIsNativeApp();

  useEffect(() => {
    if (TagManager && isEnabled) {
      TagManager.initialize({
        gtmId: isNativeApp ? nativeGTMKey : webGTMKey,
      });
    }
  }, [TagManager, webGTMKey, isEnabled, isNativeApp]);

  return null;
};

export default Gtm;
