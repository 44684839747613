const personText = ["osoba", "osoby", "osób"];

const getPersonTextByCount = (count: number) => {
  if (count >= 5) {
    return personText[2];
  }
  if (count >= 2) {
    return personText[1];
  }
  return personText[0];
};

export default getPersonTextByCount;
