import { FC, memo, useMemo } from "react";
import { useSelector } from "react-redux";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import { SkeletonProductCategoriesWrapper } from "web/Layout/Common/SkeletonComponents";
import ErrorBoundary from "web/Layout/ErrorBoundary";

import msCategoryPaths from "web/queries/default/categoryPaths.graphql";

import useQueryWithRender from "web/hooks/useQueryWithRender";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { ICategory } from "web/types/Category";
import { Facets } from "web/types/Facets";
import { ISubscriptionItemMs } from "web/types/Subscription";

import { useCategoryContext } from "web/context/category";
import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

const MAGENTO_ROOT_CATEGORY_ID = 1;

interface IContainerProps {
  rootCategoryId: number;
  className: string;
  categoryTree: string;
  isMobile?: boolean;
  facets: Facets;
  subItems?: ISubscriptionItemMs[];
}

const Container: FC<IContainerProps> = memo(
  ({ rootCategoryId, className, categoryTree, isMobile, subItems, facets }) => {
    const { category = {} as ICategory } = useCategoryContext();
    const { path, id: categoryId } = category;
    const [idsChildren, ids, parentCategoryId, idsSiblings] = useMemo(() => {
      const categoryTreeParsed = jsonParse(categoryTree) as string[];
      const checkAmountOfNests = (stringValue: string) => {
        return stringValue.split("").filter((item) => item === "/").length;
      };
      const amountOfPath = checkAmountOfNests(path);

      const categoryTreeParsedArray = isArrayHasItems(categoryTreeParsed)
        ? categoryTreeParsed
        : [];

      const categoryTreeChildren = categoryTreeParsedArray
        .filter((item) => !item.indexOf(`${path}/`))
        .filter((item) => checkAmountOfNests(item) === amountOfPath + 1);

      const idsChildrenArray = isArrayHasItems(categoryTreeChildren)
        ? categoryTreeChildren.reduce((result: number[], current: string) => {
            const currentWithoutPath = current.replace(`${path}/`, "");
            const idsArray = currentWithoutPath.split("/");
            const idsArrayProcessed = isArrayHasItems(idsArray)
              ? idsArray.map((id) => +id)
              : [];

            return [...result, ...idsArrayProcessed];
          }, [])
        : [];
      const pathWithoutCurrentId =
        typeof path === "string" ? path.replace(`/${categoryId}`, "") : "";
      const pathWithoutCurrentIdArray = pathWithoutCurrentId
        ? pathWithoutCurrentId.split("/")
        : [];
      const parentCategoryIdCurrent =
        +pathWithoutCurrentIdArray[pathWithoutCurrentIdArray.length - 1];
      const isCurrentIsRoot =
        `${parentCategoryIdCurrent}` === `${rootCategoryId}`;

      const idsSiblingsArray = isArrayHasItems(idsChildrenArray)
        ? []
        : categoryTreeParsedArray.reduce((result: number[], currentTree) => {
            if (
              !currentTree.indexOf(pathWithoutCurrentId) &&
              currentTree !== pathWithoutCurrentId
            ) {
              const idSibling = parseInt(
                currentTree.replace(`${pathWithoutCurrentId}/`, ""),
                10
              );
              if (idSibling === MAGENTO_ROOT_CATEGORY_ID) {
                return result;
              }
              return [...result, idSibling];
            }
            return result;
          }, []);
      const idsArray = [
        ...idsChildrenArray,
        ...(isCurrentIsRoot ? [] : [parentCategoryIdCurrent]),
        ...idsSiblingsArray,
      ].map((id) => +id);
      const idsUnique = Array.from(new Set(idsArray));

      return [
        idsChildrenArray,
        idsUnique,
        isCurrentIsRoot ? null : parentCategoryIdCurrent,
        idsSiblingsArray,
      ];
    }, [path, rootCategoryId, categoryId, categoryTree]);
    const { loading, error, data } = useDataCachedCategories({
      ids,
    });

    switch (true) {
      case !!loading: {
        return <SkeletonProductCategoriesWrapper />;
      }
      case !!error: {
        return <ErrorComponent />;
      }
      case !!isMobile: {
        return <Mobile className={className} categories={data!} />;
      }

      default: {
        return (
          <Desktop
            className={className}
            categories={data!}
            parentCategoryId={parentCategoryId}
            childrenIds={idsChildren}
            siblingIds={idsSiblings}
            facets={facets}
            subscriptionItems={subItems}
          />
        );
      }
    }
  }
);

interface IContainerWithContextProps {
  className?: string;
  isMobile?: boolean;
  facets?: Facets;
  subItems?: ISubscriptionItemMs[];
}

const ContainerWithContext = ({
  className = "",
  isMobile = false,
  subItems,
  facets = [],
}: IContainerWithContextProps) => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const rootCategoryId = storeConfig && storeConfig.root_category_id;
  const token = storeConfig && storeConfig.token;
  const storeId = storeConfig && storeConfig.id;
  const options = useMemo(() => {
    return {
      variables: {
        token,
        storeId: +storeId,
      },
      context: {
        clientName: "magento",
      },
    };
  }, [token, storeId]);

  const result = useQueryWithRender(
    msCategoryPaths,
    options,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ErrorComponent,
    SkeletonProductCategoriesWrapper
  );

  return result &&
    Object.prototype.hasOwnProperty.call(result, "msCategoryPaths") ? (
    <Container
      rootCategoryId={rootCategoryId}
      className={className}
      categoryTree={result.msCategoryPaths}
      isMobile={isMobile}
      subItems={subItems}
      facets={facets}
    />
  ) : (
    result
  );
};

interface IContainerErrorProps {
  className?: string;
  facets?: Facets;
  subItems?: ISubscriptionItemMs[];
}

const ContainerError = ({
  className = "",
  facets = [],
  subItems = [],
}: IContainerErrorProps) => {
  return (
    <ErrorBoundary>
      <ContainerWithContext
        className={className}
        subItems={subItems}
        facets={facets}
      />
    </ErrorBoundary>
  );
};

export default ContainerError;
