import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { Product } from "web/types/Product";
import type { ICustomerReview, IReview } from "web/types/Review";

export type IMergedReviewProduct<T extends ICustomerReview | IReview> = T & {
  productName: Product["name"];
  productUrl: Product["url_key"];
  productImage: Product["image"];
  thumbnail: Product["thumbnail"];
};

const mergeReviewsProducts = <T extends ICustomerReview | IReview>(
  reviews: T[],
  products?: Product[]
) => {
  return isArrayHasItems(reviews) && isArrayHasItems(products)
    ? reviews.reduce<IMergedReviewProduct<T>[]>((result, review) => {
        const productData = products.find((product) => {
          if (!product || !review) {
            return false;
          }
          const reviewProductId = isCustomerReview(review)
            ? review.productId
            : review.product_id;
          return +reviewProductId === +product.id;
        });
        return productData
          ? [
              ...result,
              {
                ...review,
                productName: productData.name,
                productUrl: productData.url_key,
                productImage: productData.image,
                thumbnail: productData.thumbnail,
              },
            ]
          : result;
      }, [])
    : null;
};

export default mergeReviewsProducts;

const isCustomerReview = (
  review: IReview | ICustomerReview
): review is ICustomerReview => review.hasOwnProperty("productId");
