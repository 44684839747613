/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable array-callback-return */

/* eslint-disable consistent-return */
import { FC, memo, useCallback } from "react";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { Functionality } from "web/types/Employee";
import { MenuData, MenuItemType } from "web/types/Menu";
import type { Nullable } from "web/types/Utils";

import { useAppContext } from "web/context/app";

import { MainMenuItem, MainMenuItemWithDropdown } from "./MainMenuItem";
import classes from "./mainMenu.scss";

interface IMainMenuProps {
  menu: MenuData;
}

const MainMenu: FC<IMainMenuProps> = memo(({ menu }) => {
  const { modal } = useAppContext();
  const { menuIndex, dispatch } = modal;
  const setIndexAction = useCallback(
    (index: Nullable<number>) => {
      dispatch({ type: ModalActionTypes.SET_MENU_INDEX, index });
    },
    [dispatch]
  );

  return (
    <nav className={`container ${classes.root}`}>
      <ul className={classes.list} data-t1="main_menu_list">
        {isArrayHasItems(menu) &&
          menu.filter(Boolean).map((item, index) => {
            switch (item.type) {
              case MenuItemType.SUBCATEGORIES:
              case MenuItemType.TILES: {
                if (
                  !(isArrayHasItems(item.submenu) && item.submenu.some(Boolean))
                )
                  return null;
                return (
                  <li
                    className={classes.item}
                    key={`${item.title}${item.type}`}
                    data-t2={item.title}
                    data-t1="main_menu_item_with_dropdown"
                  >
                    <MainMenuItemWithDropdown
                      item={item}
                      isActive={menuIndex === index}
                      setIndex={setIndexAction}
                      index={index}
                      navigationScope={`.${classes.list}`}
                      dataT1={`${item.title}_${item.type}`}
                    />
                  </li>
                );
              }
              case MenuItemType.URL:
              case MenuItemType.URL_CATEGORY:
              case MenuItemType.FUNCTIONALITY:
              case MenuItemType.URL_FUNCTIONALITY: {
                // @ts-ignore
                if (!(item.category_request_path || item.url)) return null;
                return (
                  <li
                    className={classes.item}
                    key={`${item.title}${item.type}`}
                    data-t2={item.title}
                    data-t1="main_menu_item"
                  >
                    <MainMenuItem
                      item={item}
                      setIndex={setIndexAction}
                      dataT1={`${item.title}_${item.type}`}
                    />
                  </li>
                );
              }
              case MenuItemType.CATEGORY_FUNCTIONALITY: {
                if (
                  item.category_functionality === Functionality.SUBSCRIPTIONS
                ) {
                  return (
                    <li
                      className={classes.item}
                      key={`${item.title}${item.type}`}
                      data-t2={item.title}
                      data-t1="main_menu_item_with_dropdown"
                    >
                      <MainMenuItemWithDropdown
                        // @ts-ignore
                        item={item}
                        isActive={menuIndex === index}
                        setIndex={setIndexAction}
                        index={index}
                        navigationScope={`.${classes.list}`}
                        dataT1={`${item.title}_${item.type}`}
                      />
                    </li>
                  );
                }
                return null;
              }
              default:
                return null;
            }
          })}
      </ul>
    </nav>
  );
});

export default MainMenu;
