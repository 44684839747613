/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from "@benefit-systems/common-components";
import { FC, useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Sort from "web/Pages/Customer/Content/Orders/Mobile/Sort";

import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { Filter } from "web/utils/page/category/mergeFacetsStatsAttributes";
import urlSearchParamsToolbarFiltered from "web/utils/page/product/universal/urlSearchParamsToolbarFiltered";

import { sortCategoryOptions } from "web/constants/toolbar";

import { useAppContext } from "web/context/app";

import ActiveFilters from "../Desktop/ActiveFilters";
import Fieldset from "../Shared/Fieldset";
import classes from "./filters.scss";

export const filtersHidden = ["mb_product_type_filter", "tourism_location"];

interface IFiltersProps {
  filters: Filter[];
  facetsHidden: string[];
}

const Filters: FC<IFiltersProps> = ({ filters, facetsHidden = [] }) => {
  const { modal } = useAppContext();
  const { dispatch } = modal;
  const { isFiltersLoading } = useSelector((state) => state.app);
  const { search, pathname } = useLocation();
  const { push } = useHistory();

  const searchFiltered = urlSearchParamsToolbarFiltered(search);
  const searchFilteredKeys = searchFiltered && Object.keys(searchFiltered);
  const searchFilteredKeysWithoutHidden =
    isArrayHasItems(searchFilteredKeys) && isArrayHasItems(facetsHidden)
      ? searchFilteredKeys.filter((key) => facetsHidden.indexOf(key) === -1)
      : [];

  const resetHandler = () => {
    const params = new URLSearchParams(search);
    searchFilteredKeysWithoutHidden.forEach((key) => {
      params.delete(key);
    });
    push(`${pathname}?${params.toString()}`);
  };

  const closeModal = useCallback(() => {
    dispatch({ type: ModalActionTypes.RESET });
  }, []);
  const filtersActive = urlSearchParamsToolbarFiltered(search);
  const amountOfActiveFilters = Object.keys(filtersActive).filter(
    (item) => !filtersHidden.includes(item)
  ).length;

  return (
    <div className={classes.root}>
      <strong className={classes.header}>
        <h3 className={classes.title_main} data-t1="filters_main_title">
          {__("Sortuj")}
        </h3>
        <Sort options={sortCategoryOptions} />
      </strong>
      <div className={classes.form}>
        <h3 className={classes.title} data-t1="filters_badge_title_selected">
          {__("Wybrane filtry")}
          <span className={classes.badge} data-t1="filters_badge_selected">
            {amountOfActiveFilters}
          </span>
        </h3>
        <ActiveFilters facetsHidden={facetsHidden} />
        {isFiltersLoading ? (
          <Loading className={classes.loader} />
        ) : (
          <>
            <h3 className={classes.title} data-t1="filters_badge_title">
              {__("Filtry")}
              <span className={classes.badge} data-t1="filters_badge">
                {filters.length}
              </span>
            </h3>
            <div className={classes.filtersContainer}>
              {isArrayHasItems(filters)
                ? filters.map((filter) => (
                    <Fieldset
                      key={filter.attribute_code}
                      title={filter.attribute_label}
                      code={filter.attribute_code}
                      // @ts-ignore
                      type={filter.input_type}
                      // @ts-ignore
                      options={filter.attribute_options}
                    />
                  ))
                : null}
            </div>
            <div className={classes.actions}>
              <Button
                type="reset"
                variant="secondary"
                transparent
                onClick={resetHandler}
                dataT1="filters_clear"
              >
                {__("Wyczyść")}
              </Button>
              <Button
                type="button"
                dataT1="filters_submit"
                onClick={closeModal}
              >
                {__("Pokaż oferty")}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Filters;
