// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalSearch-root-wQK{display:block;min-height:100%;background:#fff;padding:60px 15px 70px}`, "",{"version":3,"sources":["webpack://./web/Layout/HeaderSearch/ModalSearch/modalSearch.scss"],"names":[],"mappings":"AAIuB,sBACrB,aAAA,CACA,eAAA,CACA,eAAA,CACA,sBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: block;\n  min-height: 100%;\n  background: white;\n  padding: 60px 15px 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `modalSearch-root-wQK`
};
export default ___CSS_LOADER_EXPORT___;
