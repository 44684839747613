import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./linkTransition.scss";

interface ILinkTransitionProps {
  url?: string;
  name?: string;
  onClick?: () => unknown;
  dataT1: string;
  isAnchor?: boolean;
  openInNewTab?: boolean;
}

const LinkTransition: FC<
  PropsWithChildren<PropsWithClasses<ILinkTransitionProps>>
> = ({
  classes = {},
  url = "",
  name = "",
  children,
  onClick = () => {},
  dataT1 = "link_transition",
  isAnchor = false,
  openInNewTab,
}) => {
  const linkClassName = `${classes.root || ""} ${
    classes.transition || ""
  }`.trim();
  const content = children || name;

  switch (true) {
    case isAnchor: {
      return (
        <a
          href={url}
          target={openInNewTab ? "_blank" : "_self"}
          rel="noreferrer"
        >
          {name}
        </a>
      );
    }
    case Boolean(typeof url === "string" && url.length): {
      return url.indexOf("http") === -1 ? (
        <Link
          className={linkClassName}
          to={url.startsWith("/") ? url : `/${url}`}
          title={name}
          onClick={onClick}
          data-t1={`link_${dataT1}`}
          data-t2={url}
        >
          {content}
        </Link>
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          className={linkClassName}
          href={url}
          title={name}
          onClick={onClick}
          data-t1={`link_${dataT1}`}
          data-t2={url}
        >
          {content}
        </a>
      );
    }
    default: {
      return (
        <button
          className={linkClassName}
          type="button"
          data-t1="link_button"
          data-t2={dataT1}
          title={name}
          onClick={onClick}
        >
          {content}
        </button>
      );
    }
  }
};

export default classify<
  PropsWithChildren<PropsWithClasses<ILinkTransitionProps>>
>(defaultClasses)(LinkTransition);
