import { FC } from "react";

import __ from "web/Layout/Translations";

import DownloadIcon from "web/assets/icons/document-arrow.svg";

import downloadFile from "web/api/downloadFile";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import restUrls from "web/constants/restUrls";

import { PropsWithClasses } from "web/types/Common";
import { Nullable } from "web/types/Utils";

import classify from "web/classify";

import defaultClasses from "./subscriptionFilesList.scss";

interface ISubscriptionFilesListProps {
  list: {
    title: string;
    attachments: { name: string; id: string }[];
  };
  benefitDraftUUID: Nullable<string>;
}

const SubscriptionFilesListProps: FC<
  PropsWithClasses<ISubscriptionFilesListProps>
> = ({ classes = {}, list, benefitDraftUUID }) => {
  const fileList = isArrayHasItems(list?.attachments)
    ? list.attachments.map((file) => {
        const fileUrl = `${restUrls.benefitsAttachments
          .replace(":uuid", benefitDraftUUID || "")
          .replace(":aid", file.id)}`;

        return (
          <button
            type="button"
            key={file.id}
            className={classes.file}
            onClick={() => downloadFile(fileUrl, file.name, true)}
          >
            {file.name}
            <DownloadIcon className={classes.icon} />
          </button>
        );
      })
    : null;

  return (
    <section className={classes.root}>
      {list?.title ? (
        <h3 className={classes.title}>{__(list?.title)}</h3>
      ) : null}
      <div className={classes.list}>{fileList}</div>
    </section>
  );
};

export default classify<PropsWithClasses<ISubscriptionFilesListProps>>(
  defaultClasses
)(SubscriptionFilesListProps);
