import { FC, PropsWithChildren } from "react";

import CloseIcon from "web/assets/icons/close.svg";

import classes from "./clearInputFieldWrapper.scss";

interface IClearInputFieldWrapperProps {
  value: string;
  clearField: () => void;
}

const ClearInputFieldWrapper: FC<
  PropsWithChildren<IClearInputFieldWrapperProps>
> = ({ children, value, clearField }) => (
  <div className={classes.root}>
    {children}

    {/* clear button */}
    <button
      type="button"
      className={`${classes.clearButton} ${value ? classes.show : ""}`}
      onClick={() => clearField()}
      name="clear"
    >
      <CloseIcon className={classes.closeIcon} />
    </button>
  </div>
);

export default ClearInputFieldWrapper;
