/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Button } from "@benefit-systems/common-components";
import DOMPurify from "dompurify";
import { Form, Formik } from "formik";
import {
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";

import Loading from "web/Layout/Common/Loading/loading";
import __ from "web/Layout/Translations";

import {
  getInitialValues,
  validateFields,
} from "web/utils/system/formValidator/validation";

import { defaultMonthCountToDivide } from "web/constants/rentableGroups";

import { SimpleFn } from "web/types/Common";
import { IEmployeeConfig } from "web/types/Employee";

import {
  useGetEmployeeConfigurationQuery,
  useGetEmployeeDetailsQuery,
  useGetEmployeeRentableGroupAdditionalDataQuery,
  useUpdateEmployeeDetailsMutation,
  useUpdateEmployeeRentableGroupAdditionalDataMutation,
} from "web/features/employee/employeeApiSlice";

import RentableGroupFromWrapper from "../RentableGroupFormWrapper/rentableGroupFormWrapper";
import RentableGroupFormDivider from "./Divider/divider";
import RentableGroupCalculatorContainer from "./RentableGroupCalculator/rentableGroupCalculatorContainer";
import getAdditionalData from "./RentableGroupCalculator/utils/getAdditionalData";
import getInitialCalculatorValues from "./RentableGroupCalculator/utils/getInitialCalculatorValues";
import RentableGroupCalculatorAgreements from "./RentableGroupCalculatorAgreements";
import classes from "./rentableGroupCalculatorForm.scss";

export const enum RentableGroupFormVariant {
  POPUP = "POPUP",
  EDIT = "EDIT",
}

interface IRentableGroupStandaloneFormProps {
  variant: RentableGroupFormVariant.POPUP;
  onClose?: never;
}

interface IRentableGroupPopupFormProps {
  variant: RentableGroupFormVariant.EDIT;
  onClose: SimpleFn;
}

type IRentableGroupFormProps =
  | IRentableGroupStandaloneFormProps
  | IRentableGroupPopupFormProps;

const RENTABLE_GROUP_VARIANTS_CLASSES = {
  [RentableGroupFormVariant.POPUP]: {
    content: classes.whiteBG,
  },
  [RentableGroupFormVariant.EDIT]: {
    content: `${classes.cyanBG} rg-calculator-white`,
  },
};

const initialCalculatorValues = {
  income: {
    me: 0,
    people: [],
  },
  "fill-calculator-checkbox": false,
  "financing-checkbox": false,
};

const RentableGroupCalculatorForm: FC<
  PropsWithChildren<IRentableGroupFormProps>
> = ({
  variant = RentableGroupFormVariant.POPUP,
  children = null,
  onClose,
}) => {
  const { data: employeeDetails, isLoading: isLoadingEmployeeDetails } =
    useGetEmployeeDetailsQuery();
  const { data: employeeConfig, isLoading: isLoadingConfiguration } =
    useGetEmployeeConfigurationQuery();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStatusChanged, toggleStatus] = useState(false);

  const {
    data: rentableGroupsAdditionalData,
    isLoading: isLoadingRentableGroupsAdditionalData,
  } = useGetEmployeeRentableGroupAdditionalDataQuery(undefined, {
    skip: variant === RentableGroupFormVariant.POPUP,
  });

  const [updateEmployeeDetailsFn, { isLoading: isUpdating }] =
    useUpdateEmployeeDetailsMutation({
      fixedCacheKey: "shared-update-employee",
    });

  const [updateRGAdditionalData] =
    useUpdateEmployeeRentableGroupAdditionalDataMutation();

  const isConfigurationGotten =
    !isLoadingConfiguration &&
    !isLoadingEmployeeDetails &&
    !isLoadingRentableGroupsAdditionalData;

  const { pathContentAbove } = employeeDetails || {};
  const {
    rentableGroupContentAbove,
    rentableGroupContentAboveForNew,
    rentableGroupAgreementsList,
    rentableGroupCalculatorMonthToDivide:
      monthCountToDivide = defaultMonthCountToDivide,
  } = employeeConfig || {};

  const initialValuesCalculated = useMemo(
    () =>
      rentableGroupsAdditionalData
        ? getInitialCalculatorValues(rentableGroupsAdditionalData)
        : initialCalculatorValues,
    [rentableGroupsAdditionalData, initialCalculatorValues]
  );

  const agreementsListFields = useMemo(
    () =>
      (rentableGroupAgreementsList || [])
        .filter((agreement) => agreement.isActive)
        .map(({ id, value, isRequired, dependencies }) => ({
          name: id,
          type: "checkbox",
          label: value,
          initialValue: false,
          rules: isRequired ? ["required"] : [],
          dependencies: dependencies || [],
        })),
    [rentableGroupAgreementsList]
  );

  const initialValues = useMemo(
    () => ({
      agreements: getInitialValues(agreementsListFields),
      ...initialValuesCalculated,
    }),
    [agreementsListFields, initialValuesCalculated]
  );

  const rentableGroupPathContentAbove = useMemo(
    () =>
      pathContentAbove?.includes("content-above-for-new")
        ? rentableGroupContentAbove
        : rentableGroupContentAboveForNew,
    [pathContentAbove]
  );

  const isLoading =
    isUpdating ||
    isLoadingEmployeeDetails ||
    isLoadingConfiguration ||
    isLoadingRentableGroupsAdditionalData;

  const submitHandler = useCallback(
    // @ts-ignore
    async (values) => {
      setIsSubmitting(true);
      const { rentableGroupAgreementsList } =
        employeeConfig || ({} as IEmployeeConfig);
      const additionalData = getAdditionalData(
        values,
        monthCountToDivide,
        rentableGroupAgreementsList
      );
      const groupId = values && values.incomeGroupId;
      if (groupId) {
        try {
          await Promise.all([
            updateEmployeeDetailsFn({
              id: groupId,
            }).unwrap(),
            updateRGAdditionalData({
              data: additionalData,
              isPatch: variant === RentableGroupFormVariant.EDIT,
            }).unwrap(),
          ]);
          setIsSubmitting(false);
          if (variant === RentableGroupFormVariant.POPUP) {
            toggleStatus(true);
          } else if (typeof onClose === "function") {
            onClose();
          }
        } catch (error) {
          setIsSubmitting(false);
          console.error(error);
        }
      }
    },
    [employeeConfig, rentableGroupAgreementsList, monthCountToDivide]
  );

  if (isStatusChanged) return <>{children}</>;

  const Wrapper =
    variant === RentableGroupFormVariant.POPUP
      ? RentableGroupFromWrapper
      : Fragment;

  return (
    <>
      <Wrapper>
        <div
          className={`${classes.content} ${
            RENTABLE_GROUP_VARIANTS_CLASSES[variant].content
          } ${isSubmitting ? classes.submitting : ""}`}
        >
          {isConfigurationGotten && (
            <>
              <h1
                className={`${classes.title} cke_editable`}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    employeeConfig?.rentableGroupTitle as string
                  ),
                }}
              />
              {rentableGroupPathContentAbove && (
                <div
                  className={`${classes.contentAbove} reset-global-styles cke_editable`}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(rentableGroupPathContentAbove),
                  }}
                />
              )}
              <RentableGroupFormDivider />
              <Formik
                initialValues={initialValues}
                onSubmit={submitHandler}
                // @ts-ignore
                validate={validateFields(agreementsListFields)}
                enableReinitialize
              >
                {({ values }) => (
                  <Form className={classes.form}>
                    <RentableGroupCalculatorContainer />
                    <RentableGroupFormDivider />
                    <RentableGroupCalculatorAgreements
                      agreementsListFields={agreementsListFields}
                      initialValues={
                        initialValues.agreements as Record<string, boolean>
                      }
                    />

                    <div className={classes.footer}>
                      <div className={onClose ? classes.footerBtns : ""}>
                        {onClose && (
                          <Button
                            variant="secondary"
                            className={classes.footerBtn}
                            onClick={onClose}
                          >
                            {__("Anuluj")}
                          </Button>
                        )}
                        <Button
                          variant="tertiary"
                          className={classes.footerBtn}
                          type="submit"
                          // @ts-ignore
                          disabled={isUpdating || !values.incomeGroupId}
                        >
                          {__("Zatwierdź deklarację")}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
          {isLoading ? <Loading className={classes.loader} /> : null}
        </div>
      </Wrapper>
    </>
  );
};

export default RentableGroupCalculatorForm;
