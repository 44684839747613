/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import { SkeletonHeaderMenu } from "web/Layout/Common/SkeletonComponents";
import __ from "web/Layout/Translations";
import {
  getLanguageCode,
  setLocaleField,
} from "web/Layout/Translations/LanguageWrapper";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import setBenefitGroupTypeParam from "web/utils/page/product/subscription/setBenefitGroupTypeParam";

import urls from "web/constants/urls";

import type { ICategory } from "web/types/Category";
import type { ICategoryCount } from "web/types/CategoryCount";
import { Functionality } from "web/types/Employee";
import {
  ICategoryFunctionalityMenuItem,
  ICategoryMenuItem,
  IFunctionalityMenuItem,
  ISubCategoryMenuItem,
  IUrlFunctionalityMenuItem,
  MenuData,
  MenuItemType,
} from "web/types/Menu";
import type { IStoreConfig } from "web/types/StoreConfig";

import { useAppContext } from "web/context/app";
import { useGetMenuQuery } from "web/features/api/graphQLApiSlice";
import { useGetEmployeeDetailsQuery } from "web/features/employee/employeeApiSlice";
import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";
import useDataCachedCategoryCounts from "web/features/useDataCached/useDataCachedCategoryCounts";

import MainMenu from "./mainMenu";

interface IGetUrlAndCountArgs {
  id: number;
  categories: ICategory[];
  counts: ICategoryCount[];
}

const getUrlAndCount = ({ id, categories, counts }: IGetUrlAndCountArgs) => {
  const currentCategory =
    id &&
    isArrayHasItems(categories) &&
    categories.find((category) => category && +category.id === id);
  const currentProductCount =
    id &&
    isArrayHasItems(counts) &&
    counts.find((item) => item && +item.id === id);
  const url = currentCategory ? `/${currentCategory.request_path}` : null;
  const count = (currentProductCount as ICategoryCount)?.product_count || null;

  return {
    url,
    count,
  };
};

const getSubscriptionChoiceUrl = (
  configuratorWindowOpen: boolean,
  configuratorWindowOpenUms: boolean
) => {
  switch (true) {
    case configuratorWindowOpen && configuratorWindowOpenUms: {
      return urls.subscriptions;
    }
    case configuratorWindowOpen: {
      return `${
        urls.checkoutSubscriptionConfiguration
      }${setBenefitGroupTypeParam("1")}`;
    }
    case configuratorWindowOpenUms: {
      return `${
        urls.checkoutSubscriptionConfiguration
      }${setBenefitGroupTypeParam("2")}`;
    }
    default: {
      return urls.subscriptions;
    }
  }
};

interface IMainMenuContainerWithCategoryProductCountProps {
  menu: MenuData;
  ids: number[];
  storeConfig: IStoreConfig;
  categories: ICategory[];
}

const MainMenuContainerWithCategoryProductCount: FC<
  IMainMenuContainerWithCategoryProductCountProps
> = ({ menu, ids, storeConfig, categories }) => {
  const { isMobile } = useAppContext();
  const currentLanguage = getLanguageCode();
  const {
    msi_menu_en: msMenuEn,
    msi_url: msiUrl,
    msi_url_en: msiUrlEn,
    mybenefit_active_url: MBActiveUrl,
    mybenefit_active_url_en: MBActiveUrlEn,
    mybenefit_active_menu: MBActiveMenu,
    mybenefit_active_menu_en: MBActiveMenuEn,
  } = storeConfig;

  const {
    loading,
    data: productCounts,
    error,
  } = useDataCachedCategoryCounts({
    ids,
  });

  const { data: employeeDetails } = useGetEmployeeDetailsQuery();
  const {
    msiCartsEnabled: msiCartsEnabledSelector,
    mbActiveEnabled: mbActiveEnabledSelector,
    functionalities,
  } = employeeDetails || {};

  const { hasEnabledAnySubscriptions, config: subscriptionConfig } =
    useSelector((state) => state.subscriptionQuote);

  switch (true) {
    case !!loading: {
      return <SkeletonHeaderMenu />;
    }
    case !!error: {
      return <ErrorComponent />;
    }
    default: {
      const menuProcessed = menu?.map((current) => {
        switch (current && current.type) {
          case MenuItemType.URL_CATEGORY: {
            const { url, count } = getUrlAndCount({
              id: +(current as ICategoryMenuItem<MenuItemType.URL_CATEGORY>)
                .id_category,
              categories,
              counts: productCounts!,
            });

            return url && count
              ? {
                  ...current,
                  category_request_path: url,
                  category_products_count: count,
                }
              : null;
          }
          case MenuItemType.FUNCTIONALITY: {
            const currentTyped = current as IFunctionalityMenuItem;
            const funcFromStoreConfig = functionalities?.find(
              (func) => func === currentTyped.functionality
            );

            if (!currentTyped.functionality) {
              return null;
            }

            switch (true) {
              case currentTyped.functionality === funcFromStoreConfig &&
                currentTyped.functionality ===
                  Functionality.ENABLE_MYBENEFIT_ACTIVE:
                return (
                  mbActiveEnabledSelector &&
                  MBActiveUrl && {
                    ...current,
                    title: !MBActiveMenu
                      ? current.title
                      : setLocaleField(
                          currentLanguage,
                          MBActiveMenu,
                          MBActiveMenuEn
                        ),
                    url: setLocaleField(
                      currentLanguage,
                      MBActiveUrl,
                      MBActiveUrlEn
                    ),
                  }
                );
              case currentTyped.functionality === funcFromStoreConfig &&
                currentTyped.functionality !== Functionality.ENABLE_MSI_CARDS:
                return {
                  ...current,
                  url: setLocaleField(currentLanguage, msiUrl, msiUrlEn),
                };
              case currentTyped.functionality ===
                Functionality.ENABLE_MSI_CARDS && msiCartsEnabledSelector:
                return {
                  ...current,
                  url: setLocaleField(currentLanguage, msiUrl, msiUrlEn),
                  title: setLocaleField(
                    currentLanguage,
                    current.title,
                    msMenuEn
                  ),
                };
              default:
                return null;
            }
          }
          case MenuItemType.CATEGORY_FUNCTIONALITY: {
            const currentTyped = current as ICategoryFunctionalityMenuItem;
            if (!currentTyped.category_functionality) {
              return null;
            }
            switch (true) {
              case currentTyped.category_functionality ===
                Functionality.SUBSCRIPTIONS && hasEnabledAnySubscriptions:
                return {
                  ...current,
                  submenu: [
                    {
                      title: __("Wybierz abonamenty"),
                      url: getSubscriptionChoiceUrl(
                        subscriptionConfig.configuratorWindowOpen,
                        subscriptionConfig.configuratorWindowOpenUms
                      ),
                    },
                    {
                      title: __("Moje abonamenty"),
                      url: urls.customerSubscriptions,
                    },
                    {
                      title: __("Katalog abonamentów"),
                      url: "/abonamenty.html",
                    },
                  ],
                  url: "",
                  title: current.title,
                };
              default:
                return null;
            }
          }
          case MenuItemType.URL_FUNCTIONALITY: {
            const currentTyped = current as IUrlFunctionalityMenuItem;
            const funcFromStoreConfig = functionalities?.find(
              (func) => func === currentTyped.url_functionality
            );
            switch (true) {
              case currentTyped.url_functionality &&
                currentTyped.url_functionality === funcFromStoreConfig &&
                currentTyped.url_functionality !==
                  Functionality.ENABLE_MSI_CARDS:
                return {
                  ...current,
                };
              case currentTyped.url_functionality &&
                currentTyped.url_functionality ===
                  Functionality.ENABLE_MSI_CARDS &&
                msiCartsEnabledSelector:
                return {
                  ...current,
                };
              default:
                return null;
            }
          }
          case MenuItemType.URL: {
            if (current && current.type === MenuItemType.URL) {
              return {
                ...current,
                url: setLocaleField(
                  currentLanguage,
                  current.url,
                  current.url_en
                ),
              };
            }
            return null;
          }
          case MenuItemType.SUBCATEGORIES:
          case MenuItemType.TILES: {
            const currentTyped = current as ISubCategoryMenuItem<
              MenuItemType.SUBCATEGORIES | MenuItemType.TILES
            >;
            const subCategoriesWithData = isArrayHasItems(currentTyped.submenu)
              ? currentTyped.submenu.map((submenuItem) => {
                  if (
                    submenuItem &&
                    submenuItem.type === MenuItemType.CATEGORY
                  ) {
                    const { url, count } = getUrlAndCount({
                      id: +submenuItem.id_category,
                      categories,
                      counts: productCounts!,
                    });

                    return url && count
                      ? {
                          ...submenuItem,
                          category_request_path: url,
                          category_products_count: count,
                        }
                      : null;
                  }

                  return submenuItem;
                })
              : [];

            return {
              ...current,
              submenu: subCategoriesWithData,
            };
          }
          default: {
            // @ts-ignore
            return current?.title && current?.url ? current : null;
          }
        }
      });

      return (
        <>{isMobile ? null : <MainMenu menu={menuProcessed as MenuData} />}</>
      );
    }
  }
};

interface IMainMenuContainerWithCategoryData {
  menu: MenuData;
  ids: number[];
}

const MainMenuContainerWithCategoryData: FC<
  IMainMenuContainerWithCategoryData
> = ({ menu, ids }) => {
  const storeConfig = useSelector(
    (state) => state.app.storeConfig
  ) as IStoreConfig;
  const { loading, error, data } = useDataCachedCategories({
    ids,
  });

  switch (true) {
    case !!loading: {
      return <SkeletonHeaderMenu />;
    }
    case !!error: {
      return <ErrorComponent />;
    }
    default: {
      return (
        <MainMenuContainerWithCategoryProductCount
          categories={data!}
          menu={menu}
          storeConfig={storeConfig}
          ids={ids}
        />
      );
    }
  }
};

interface IMainMenuContainerWithCategoryIdsProps {
  menu: string;
}

const MainMenuContainerWithCategoryIds: FC<
  IMainMenuContainerWithCategoryIdsProps
> = ({ menu }) => {
  const menuParsed = menu ? jsonParse<MenuData>(menu) : [];

  const categoryIds = useMemo(() => {
    const idsMemo = isArrayHasItems(menuParsed)
      ? menuParsed.reduce<number[]>((result, current) => {
          switch (current?.type) {
            case MenuItemType.URL_CATEGORY: {
              const id = +current.id_category;
              return id ? [...result, id] : result;
            }
            case MenuItemType.SUBCATEGORIES:
            case MenuItemType.TILES: {
              // @ts-ignore
              const subCategoriesIds: number[] = isArrayHasItems(
                current.submenu
              )
                ? // @ts-ignore
                  current.submenu.reduce((subResult, subCurrent) => {
                    return subCurrent &&
                      subCurrent.type === "category" &&
                      +subCurrent.id_category
                      ? [...subResult, +subCurrent.id_category]
                      : subResult;
                  }, [])
                : [];
              return [...result, ...subCategoriesIds];
            }
            case MenuItemType.CATEGORY_FUNCTIONALITY: {
              const categoryId = +current?.functionality_id_category;
              return categoryId ? [...result, categoryId] : result;
            }
            default: {
              return result;
            }
          }
        }, [])
      : null;

    return Array.from(new Set(idsMemo));
  }, [menu]);

  switch (true) {
    case !isArrayHasItems(menuParsed): {
      return null;
    }
    case !!isArrayHasItems(categoryIds): {
      return (
        <MainMenuContainerWithCategoryData
          ids={categoryIds}
          menu={menuParsed}
        />
      );
    }
    default: {
      return <MainMenu menu={menuParsed} />;
    }
  }
};

const MainMenuContainer = () => {
  const { data, isLoading, isError } = useGetMenuQuery();

  switch (true) {
    case isLoading: {
      return <SkeletonHeaderMenu />;
    }
    case isError: {
      return <ErrorComponent />;
    }
    default: {
      const menuData = data?.menu;
      return menuData ? (
        <MainMenuContainerWithCategoryIds menu={menuData} />
      ) : null;
    }
  }
};

export default MainMenuContainer;
