import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getPriceWithCommission from "web/utils/page/product/universal/getPriceWithCommission";

import {
  BENEFIT_PRICE_TYPE_METHOD,
  BENEFIT_PURCHASE_METHOD,
  SOURCE_FOUND_LABEL,
  SOURCE_FOUND_LABEL_SUMMARY,
  SOURCE_FOUND_MARKER,
  pointsPerMonth,
  zlotyPerMonth,
} from "web/constants/benefits";

import { BankItems } from "web/types/Banks";
import {
  ISubscriptionConfiguratorValuesFormikEle,
  ISubscriptionEmployeeGroup,
  ISubscriptionPriceVariant,
  SubscriptionMethod,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

interface IBasePriceData {
  employeePayUPrice: Nullable<string>;
  employeePrice: Nullable<string>;
  pointsBankPrice: Nullable<string>;
  pointsBankId: string;
  employerPrice?: Nullable<string>;
  pointsBanks?: BankItems;
}

interface IPriceData extends IBasePriceData {
  pointsBankName?: string;
}

interface IFundingSourceData extends IBasePriceData {
  method: SubscriptionMethod;
  pointsBanks?: BankItems;
  pointsBankId: string;
  alternativeText?: Nullable<string>;
}

interface IGroupPriceConfig {
  currentBenefit: ISubscriptionPriceVariant;
  priceDetailed: Nullable<ISubscriptionEmployeeGroup>;
  purchaseMethod: string;
  benefitValues: ISubscriptionConfiguratorValuesFormikEle;
  pointsBanks: BankItems;
  isEmployerPriceTypeHidden: boolean;
}

interface ICoFinancedPrice {
  price?: number;
  method: string;
  unit: string;
  label: string;
  pointsBankName?: string;
  pointsBankId?: string;
}

const getCoFinancedPrices = ({
  employeePayUPrice,
  employeePrice,
  pointsBankPrice,
  pointsBankName,
  pointsBankId,
  employerPrice,
}: IPriceData): ICoFinancedPrice[] => {
  return [
    {
      price: Number(employeePayUPrice),
      method: EMPLOYEE_PAY_U,
      unit: zlotyPerMonth,
      label: SOURCE_FOUND_LABEL_SUMMARY.employeePayU || "",
    },
    {
      price: Number(employeePrice),
      method: EMPLOYEE,
      unit: zlotyPerMonth,
      label: SOURCE_FOUND_LABEL_SUMMARY.employee || "",
    },
    {
      price: Number(pointsBankPrice),
      method: POINTS,
      unit: pointsPerMonth,
      label: pointsBankName || "",
      pointsBankName,
      pointsBankId,
    },
    {
      price: Number(employerPrice),
      method: EMPLOYER,
      unit: zlotyPerMonth,
      pointsBankName,
      pointsBankId,
      label: SOURCE_FOUND_LABEL_SUMMARY.employer || "",
    },
  ].filter((coFinancedEle) => Number(coFinancedEle.price));
};

export const getFundingSourceData = ({
  method,
  employeePayUPrice,
  employeePrice,
  pointsBanks,
  pointsBankPrice,
  pointsBankId,
  employerPrice,
  alternativeText = null,
}: IFundingSourceData) => {
  let singlePaymentMethodLabel;
  const pointsBankName = getPointsBankName(pointsBanks, pointsBankId);
  const coFinancedPrices = getCoFinancedPrices({
    employeePayUPrice,
    employeePrice,
    pointsBankPrice,
    pointsBankName: pointsBankName || __("Płatne z banku punktów"),
    pointsBankId,
    employerPrice,
  });

  const coFinancedLabel: any[] = coFinancedPrices.map((ele) => ({
    label: `${ele.price}${__(ele.unit)} - ${__(ele.label)}`,
    dataT1: "coFinancedValue",
  }));

  if (alternativeText) {
    const hiddenEmployerPriceMessage = {
      label: alternativeText,
      dataT1: "hiddenCostMessage",
    };
    singlePaymentMethodLabel = [hiddenEmployerPriceMessage];
    coFinancedLabel.push(hiddenEmployerPriceMessage);
  } else {
    singlePaymentMethodLabel = [
      {
        label: __(
          SOURCE_FOUND_LABEL[
            method as keyof typeof SOURCE_FOUND_LABEL
          ]?.replace(":bankName", pointsBankName || __("banku punktów"))
        ),
        dataT1: SOURCE_FOUND_MARKER[method as keyof typeof SOURCE_FOUND_MARKER],
      },
    ];
  }

  const isCofinanced = method === CO_FINANCED;
  const finalLabelResult = isCofinanced
    ? coFinancedLabel
    : singlePaymentMethodLabel;

  return finalLabelResult;
};

const preparePrice = (
  price: any,
  purchaseMethod: string,
  benefitValues: ISubscriptionConfiguratorValuesFormikEle
) => {
  const visibleEmployeePrice = benefitValues?.showVariantPrice
    ? getPriceWithCommission(
        benefitValues?.variant,
        benefitValues?.commissionPercentage
      )
    : price;

  const qty =
    purchaseMethod === BENEFIT_PURCHASE_METHOD.INDIVIDUAL
      ? benefitValues?.quantity
      : 1;

  const preparedPrice = parseFloat(visibleEmployeePrice);

  return preparedPrice > 0 ? (preparedPrice * qty).toFixed(2) : "-";
};

const { EMPLOYEE, EMPLOYER, EMPLOYEE_PAY_U, POINTS, CO_FINANCED } =
  BENEFIT_PRICE_TYPE_METHOD;

export const generateBenefitsGroupTwo = ({
  currentBenefit,
  priceDetailed,
  purchaseMethod,
  benefitValues,
  pointsBanks,
  isEmployerPriceTypeHidden,
}: IGroupPriceConfig) => {
  const {
    employeePayUPrice,
    pointsBankPrice,
    employeePrice,
    employerPrice,
    method,
  } = currentBenefit;

  const { pointsBankId } = Object(priceDetailed?.benefitEmployeeGroupPrices[0]);
  const currentBenefitPrice =
    employeePayUPrice ||
    pointsBankPrice ||
    employeePrice ||
    (employerPrice && !isEmployerPriceTypeHidden);

  const currentBeneftisUnit =
    method === POINTS ? pointsPerMonth : zlotyPerMonth;
  const currentPriceWithUnit =
    currentBenefitPrice || +benefitValues?.variant
      ? [
          `${preparePrice(
            currentBenefitPrice,
            purchaseMethod,
            benefitValues
          )} ${__(currentBeneftisUnit)}`,
        ]
      : ["-"];

  const isCofinanced = method === CO_FINANCED;
  const coFinancedPrices = getCoFinancedPrices({
    employeePayUPrice,
    employeePrice,
    pointsBankPrice,
    pointsBanks,
    pointsBankId,
  });
  let alternativeText = null;

  if (isEmployerPriceTypeHidden) {
    alternativeText = priceDetailed?.currentPriceType
      ?.employerPriceAlternativeText
      ? priceDetailed?.currentPriceType?.employerPriceAlternativeText
      : "-";
  }

  // const coFinancedTotalPrice = isArrayHasItems(coFinancedPrices)
  //   ? coFinancedPrices?.reduce((prev, curr) => {
  //       return parseFloat(prev.price, 10) + parseFloat(curr.price, 10);
  //     })
  //   : [];

  const coFinancedTotalPrice = isArrayHasItems(coFinancedPrices)
    ? coFinancedPrices.reduce((sum, current) => sum + Number(current.price), 0)
    : [];

  const finalPriceResult =
    isCofinanced && !isEmployerPriceTypeHidden
      ? [
          `${preparePrice(
            coFinancedTotalPrice,
            purchaseMethod,
            benefitValues
          )} ${__(zlotyPerMonth)}`,
        ]
      : currentPriceWithUnit;

  const finalLabelResult = getFundingSourceData({
    method,
    employeePayUPrice,
    employeePrice,
    pointsBankPrice,
    pointsBanks,
    pointsBankId,
    alternativeText,
  });

  return {
    columnOne: finalLabelResult,
    columnTwo: finalPriceResult,
  };
};

export const generateBenefitsGroupOne = (
  currentBenefit: ISubscriptionPriceVariant,
  priceDetailed: Nullable<ISubscriptionEmployeeGroup>,
  purchaseMethod: string,
  benefitValues: ISubscriptionConfiguratorValuesFormikEle
) => {
  const { employeePrice, employerPrice } = currentBenefit;
  const alternativeText = priceDetailed?.currentPriceType
    ?.employerPriceAlternativeText
    ? priceDetailed?.currentPriceType?.employerPriceAlternativeText
    : "-";

  const currentMethod =
    priceDetailed?.benefitEmployeeGroupPrices[0]?.currentPriceVariant?.method;
  const currentEmployeePrice =
    currentMethod !== "employer"
      ? preparePrice(employeePrice, purchaseMethod, benefitValues)
      : "-";
  const currentEmployerPrice =
    currentMethod !== "employee"
      ? preparePrice(employerPrice, purchaseMethod, benefitValues)
      : "-";

  return {
    alternativeText,
    currentEmployeePrice,
    currentEmployerPrice,
  };
};

export const generateDefaultBenefitsPriceData = (
  benefitEmployeeGroupDetailed: ISubscriptionEmployeeGroup,
  pointsBanks: BankItems
) => {
  const sourceFoundValues: { value: number; priceUnit: string }[] = [];
  const { benefitEmployeeGroupPrices = [], currentPriceType } =
    benefitEmployeeGroupDetailed || {};
  const { priceVariants = [] } = currentPriceType || {};

  const benefitPricesData = benefitEmployeeGroupPrices.map((sourceEle) => {
    const {
      currentPriceVariant,
      pointsBankId,
      id: benefitEmployeeGroupPrice,
    } = Object(sourceEle);
    const pointsBankName = getPointsBankName(pointsBanks, pointsBankId);
    const {
      employeePayUPrice,
      employeePrice,
      employerPrice,
      pointsBankPrice,
      method,
    } = currentPriceVariant;

    const matchPriceVariant = [
      employeePayUPrice,
      employeePrice,
      employerPrice,
      pointsBankPrice,
    ].find((variant) => {
      return variant && parseFloat(variant) !== 0;
    });

    /**
     * Uwzględnienie kwoty wybieranej przez pracownika, przy ustawianiu default'owej ceny benefitu
     * Z kwot wybieranych przez pracownika, default'owo ustawiana jest pierwsza cena z listy
     *  */
    const prepMatchPriceVariant =
      !matchPriceVariant && isArrayHasItems(priceVariants)
        ? priceVariants[0]
        : matchPriceVariant;

    const isMethodCoFinanced = method === CO_FINANCED;
    const matchPriceVariantWithCoFinanced = isMethodCoFinanced
      ? +employeePrice + +pointsBankPrice + +employeePayUPrice
      : prepMatchPriceVariant;
    const lowestCoFinancedUnit =
      employeePrice < pointsBankPrice ? pointsPerMonth : zlotyPerMonth;
    const priceUnit = method !== POINTS ? zlotyPerMonth : pointsPerMonth;
    const priceUnitWithCoFinanced = isMethodCoFinanced
      ? lowestCoFinancedUnit
      : priceUnit;
    sourceFoundValues.push({
      value: Number(parseFloat(matchPriceVariantWithCoFinanced).toFixed(2)),
      priceUnit: priceUnitWithCoFinanced,
    });

    if (method === CO_FINANCED) {
      const coFinancedPrices = [
        {
          price: employeePayUPrice,
          method: EMPLOYEE_PAY_U,
          unit: zlotyPerMonth,
          pointsBankName: "",
          pointsBankId: "",
        },
        {
          price: employeePrice,
          method: EMPLOYEE,
          unit: zlotyPerMonth,
          pointsBankName: "",
          pointsBankId: "",
        },
        {
          price: pointsBankPrice,
          method: POINTS,
          unit: pointsPerMonth,
          pointsBankName,
          pointsBankId,
        },
        {
          price: employerPrice,
          method: EMPLOYER,
          unit: zlotyPerMonth,
          pointsBankName,
          pointsBankId,
        },
      ].filter((coFinancedEle) => coFinancedEle.price);

      return coFinancedPrices.map((ele) => {
        return {
          matchPriceVariant: ele.price,
          method: ele.method,
          priceUnit: ele.unit,
          pointsBank: ele.pointsBankName,
          pointsBankId: ele.pointsBankId,
          pricing: benefitEmployeeGroupDetailed.id,
          benefitEmployeeGroupPrice,
        };
      });
    }

    return [
      {
        matchPriceVariant: prepMatchPriceVariant,
        method,
        priceUnit,
        pointsBank: method === POINTS ? pointsBankName : "",
        pointsBankId: method === POINTS ? pointsBankId : "",
        pricing: benefitEmployeeGroupDetailed.id,
        benefitEmployeeGroupPrice,
      },
    ];
  });

  return { benefitPricesData, sourceFoundValues };
};

export const getPointsBankName = (
  pointsBanks?: BankItems,
  bankId?: Nullable<string>
) => {
  if (isArrayHasItems(pointsBanks) && bankId) {
    return (
      pointsBanks.find((bank) => bank.points_bank_id === bankId)
        ?.points_bank_name || ""
    );
  }

  return "";
};
