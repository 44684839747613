import { Button } from "@benefit-systems/common-components";
import { Form, Formik } from "formik";
import { FC, useMemo } from "react";

import __ from "web/Layout/Translations";

import Field from "web/Components/Common/Form/Field/field";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import {
  getFieldSpecificProps,
  getInitialValues,
} from "web/utils/page/product/subscription/forms/prepareFormData";
import { validateFields } from "web/utils/system/formValidator/validation";

import {
  ISubscriptionFormData,
  ISubscriptionReceiverFormField,
  ISubscriptionReceiverFormValues,
} from "web/types/Subscription";

import styles from "./simpleForm.scss";

interface ISimpleFormProps {
  fields: ISubscriptionReceiverFormField[];
  nameFieldsDisabled: boolean;
  formData: ISubscriptionFormData;
  submitHandler: (values: ISubscriptionReceiverFormValues) => void;
}

const fieldClasses = {
  root: styles.input,
  label: styles.label,
  labelText: styles.labelText_required,
  buttons: styles.buttons,
};

const SimpleForm: FC<ISimpleFormProps> = ({
  fields,
  nameFieldsDisabled = false,
  formData,
  submitHandler,
}) => {
  if (!isArrayHasItems(fields)) return null;

  const [initialValues, fieldsToValidate] = useMemo(
    () => getInitialValues(fields, formData),
    [fields]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const validate = validateFields(fieldsToValidate);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues as ISubscriptionReceiverFormValues}
      onSubmit={submitHandler}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      validate={validate}
    >
      <Form noValidate>
        {fields.map((field) => (
          <Field
            classes={fieldClasses}
            key={field.type}
            label={field.label}
            name={field.id}
            dataT1={field.type}
            {...getFieldSpecificProps(field.type, nameFieldsDisabled)}
          />
        ))}
        <div className={styles.buttons}>
          <Button variant="tertiary" type="submit">
            {__("Zapisz i zamknij")}
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default SimpleForm;
