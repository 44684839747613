import { FC } from "react";

import __ from "web/Layout/Translations";

import classes from "./labels.scss";

interface ISubscriptionSummaryLabels {
  benefitGroupType: string;
  extendedView: boolean;
}

const tableHeaderBasic = ["Rodzaj abonamentu", "Liczba osób"];
const tableHeadersExtendedView = {
  "1": ["Koszt pracodawcy", "Koszt pracownika"],
  "2": ["Źródło finansowania", "Koszt", "Okres ważności"],
};

const SubscriptionSummaryLabels: FC<ISubscriptionSummaryLabels> = ({
  extendedView,
  benefitGroupType,
}) => {
  const currentTableHeadersExtended =
    tableHeadersExtendedView[
      benefitGroupType as keyof typeof tableHeadersExtendedView
    ] ?? [];

  return (
    <header className={classes.root}>
      {tableHeaderBasic.map((header) => (
        <div className={classes.label} key={header}>
          {__(header)}
        </div>
      ))}
      {extendedView &&
        currentTableHeadersExtended.map((header) => (
          <div className={classes.label} key={header}>
            {__(header)}
          </div>
        ))}
    </header>
  );
};

export default SubscriptionSummaryLabels;
