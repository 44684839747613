/* eslint-disable no-console */
import { isDevMode } from "config/env";

import getNewRelicErrorMessage from "./getNewRelicErrorMessage";
import newRelicExcludedErrors from "./newRelicExcludedErrors";

export const enum ErrorSource {
  BROWSER = "Browser",
  WEBVIEW = "WebView",
}

export const ENV_URL_PARTS = {
  LOCAL: "feniks-pwa-local",
  STAGING: "staging",
  QA1: "qa1",
};
export const ENV_NAMES = {
  STAGING: "staging",
  PROD: "prod",
  QA1: "qa1",
};

const newRelicErrorReport = (
  error: unknown,
  additionalMessage = "no context provided"
) => {
  if (!window) {
    return;
  }

  const message = getNewRelicErrorMessage(error);

  if (newRelicExcludedErrors.some((regex) => regex.test(message))) {
    return;
  }

  const updatedError = new Error(
    `${`${message} - ` || ""}additional: ${additionalMessage}`
  );

  if (isDevMode()) {
    console.log(
      "%c !!!!!!!!!!!!!!!!!!!!!! NEW RELIC ERROR !!!!!!!!!!!!!!!!!!!!!!",
      "background: #222; color: red; ; font-size: 20px"
    );
    console.trace(updatedError);
    console.log(
      "%c !!!!!!!!!!!!!!!!!!!! NEW RELIC ERROR END !!!!!!!!!!!!!!!!!!!!",
      "background: #222; color: red; font-size: 20px"
    );
  }

  const url = window.location.origin;

  // no notification on local
  if (url.includes(ENV_URL_PARTS.LOCAL)) {
    return;
  }

  // eslint-disable-next-line no-nested-ternary
  const environment = url.includes(ENV_URL_PARTS.STAGING)
    ? ENV_NAMES.STAGING
    : url.includes(ENV_URL_PARTS.QA1)
    ? ENV_NAMES.QA1
    : ENV_NAMES.PROD;

  const source = window.ReactNativeWebView
    ? ErrorSource.WEBVIEW
    : ErrorSource.BROWSER;

  window.newrelic?.noticeError(updatedError, { environment, source });
};

export default newRelicErrorReport;
