import classify from "web/classify";

import defaultClasses from "./discount.scss";

interface IDiscountProps {
  value?: number;
  classes?: {
    root: string;
  };
}

const Discount = ({ value = 0, classes = { root: "" } }: IDiscountProps) => {
  return +value && +value > 1 ? (
    <span
      className={classes.root}
      data-t1="discount_value"
      data-t2={value.toFixed(0)}
    >
      -{value.toFixed(0)}
      <sup>%</sup>
    </span>
  ) : null;
};

export default classify(defaultClasses)(Discount);
