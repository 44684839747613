import { FC } from "react";
import { useSelector } from "react-redux";

import placeholder from "web/assets/images/placeholder.jpg";

import getAutoresizeSrc from "web/utils/system/url/getAutoresizeSrc";
import getSrcMedia from "web/utils/system/url/getSrcMedia";

import { IStoreConfig } from "web/types/StoreConfig";
import type { Nullable } from "web/types/Utils";

import Image from "./image";

interface IImageContainerProps {
  src: Nullable<string>;
  alt: Nullable<string>;
  width?: Nullable<number>;
  height?: Nullable<number>;
  dataT1?: string;
  isProduct?: boolean;
  isCategory?: boolean;
  isCampaign?: boolean;
  isAutoresized?: boolean;
  className?: string;
}

const ImageContainer: FC<IImageContainerProps> = ({
  src = null,
  alt = null,
  width = null,
  height = null,
  isProduct = false,
  isCategory = false,
  isCampaign = false,
  dataT1 = "image",
  isAutoresized = true,
  className,
}) => {
  const storeConfig = useSelector(
    (state) => state.app.storeConfig
  ) as IStoreConfig;
  const imagePathCreator = isAutoresized ? getAutoresizeSrc : getSrcMedia;
  const srcProcessed = src
    ? imagePathCreator({
        storeConfig,
        src,
        width,
        height,
        isProduct,
        isCategory,
        isCampaign,
      })
    : placeholder;
  return (
    <Image
      src={srcProcessed}
      alt={alt}
      height={height}
      dataT1={`${dataT1}_${alt}_${src}`}
      className={className}
    />
  );
};

export default ImageContainer;
