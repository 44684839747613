import { FC } from "react";

import styles from "./fieldPreview.scss";

interface IFieldPreviewProps {
  label: string;
  value?: string;
}

const FieldPreview: FC<IFieldPreviewProps> = ({ label, value = "" }) => (
  <p className={styles.field} title={value}>
    {label}: <strong>{value?.length ? value : "-"}</strong>
  </p>
);

export default FieldPreview;
