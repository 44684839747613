import { names } from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import {
  pageLayout,
  pageName,
  pageSizeName,
  searchName,
  sortDirectionName,
  sortName,
  utmPrefix,
} from "web/constants/toolbar";

export default (search: string) => {
  const urlParams = new URLSearchParams(search);
  const result: Record<string, string[]> = {};

  urlParams.forEach((value, key) => {
    if (key.startsWith(utmPrefix)) {
      return;
    }
    if (
      [
        searchName,
        pageName,
        pageSizeName,
        sortName,
        sortDirectionName,
        names.rooms,
        names.dateFrom,
        names.dateTo,
        names.tourismObject,
        pageLayout.name,
      ].indexOf(key) === -1
    ) {
      let valueDecoded: string;
      try {
        valueDecoded = decodeURIComponent(value);
      } catch (error) {
        newRelicErrorReport(
          error,
          "web-app/web/utils/page/product/universal/urlSearchParamsToolbarFiltered.ts - 37"
        );
        valueDecoded = value;
      }
      result[key] = result[key]
        ? [...result[key], valueDecoded]
        : [valueDecoded];
    }
  });

  return result;
};
