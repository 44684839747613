import { useLocation } from "react-router-dom";

import { lang } from "web/constants/queryParams";

const useLangFromParams = () => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const languageFromParam = params.get(lang);
  const langLinkSuffix = languageFromParam ? `?lang=${languageFromParam}` : "";
  const isParamLangEn = languageFromParam === "en";

  return { languageFromParam, langLinkSuffix, isParamLangEn };
};

export default useLangFromParams;
