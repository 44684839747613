import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

/**
 * @param {Object} config
 * @param {Boolean} config.initialState - Dropdown initialState
 * @param {string} config.scopeSelector - Ancestor item selector
 * @param {Boolean} config.clickOutside - Handle click outside.
 * @param {Boolean} config.keydown - Handle keydown "Escape".
 * @param {Boolean} config.closeAfterClick - Handle click anywhere.
 * @return {Array} [isOpen, setIsOpen]
 */

interface IuseDropdown {
  initialState?: boolean;
  scopeSelector?: string | null;
  clickOutside?: boolean;
  keydown?: boolean;
  closeAfterClick?: boolean;
}

const useDropdown = (
  config: IuseDropdown = {}
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const {
    initialState = false,
    scopeSelector = null,
    clickOutside = true,
    keydown = true,
    closeAfterClick = false,
  } = config;

  const [isOpen, setIsOpen] = useState(initialState);
  const keypressEscapeHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  const clickOutsideHandler = useCallback(
    (event: MouseEvent) => {
      if (
        scopeSelector &&
        event &&
        event.target &&
        isOpen &&
        typeof (event.target as HTMLElement).closest === "function" &&
        !(event.target as HTMLElement).closest(scopeSelector)
      ) {
        setIsOpen(false);
      }
    },
    [setIsOpen, isOpen]
  );

  const clickAnywhereHandler = (event: MouseEvent) => {
    if (event) {
      setIsOpen(false);
    }
  };

  const removeUnnecessaryListeners = () => {
    if (keydown) {
      document.removeEventListener("keydown", keypressEscapeHandler);
    }
    if (clickOutside && scopeSelector) {
      document.removeEventListener("click", clickOutsideHandler);
    }
    if (closeAfterClick) {
      document.removeEventListener("click", clickAnywhereHandler);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (keydown) {
        document.addEventListener("keydown", keypressEscapeHandler);
      }
      if (clickOutside && scopeSelector) {
        document.addEventListener("click", clickOutsideHandler);
      }
      if (closeAfterClick) {
        document.addEventListener("click", clickAnywhereHandler);
      }
    } else {
      removeUnnecessaryListeners();
    }

    return () => {
      removeUnnecessaryListeners();
    };
  }, [isOpen, keydown, closeAfterClick, clickOutside, scopeSelector]);

  return [isOpen, setIsOpen];
};

export default useDropdown;
