import { fromZonedTime } from "date-fns-tz";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { english } from "flatpickr/dist/l10n/default";
import { Polish } from "flatpickr/dist/l10n/pl";
import { func, number, oneOfType, shape, string } from "prop-types";
import { useEffect, useRef } from "react";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import isValidToken from "web/utils/system/essentials/isValidToken";

import classify from "web/classify";
import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";

const DatePickerCalendar = ({
  classes,
  minDate,
  maxDate,
  value,
  setValue,
  action,
  eventListenerName,
  useUTC,
}) => {
  const { data: customer } = useGetCustomerDetailsQuery(undefined, {
    skip: !isValidToken(),
  });

  const locale = () => {
    if (!(customer && customer.language)) {
      return Polish;
    }
    switch (customer.language) {
      case "en_GB":
        return english;
      default:
        return Polish;
    }
  };

  useEffect(() => {
    const calendar = calendarRef.current;
    calendar?.addEventListener(eventListenerName, (e) => {
      e.stopPropagation();
    });
  }, []);

  const calendarRef = useRef();
  const inputRef = useRef();
  useEffect(() => {
    const datepicker = flatpickr(inputRef.current, {
      dateFormat: "U",
      defaultDate: value || null,
      onChange: (selectedDates) => {
        const timestamp =
          // eslint-disable-next-line no-nested-ternary
          isArrayHasItems(selectedDates) &&
          selectedDates[0] &&
          typeof selectedDates[0].getTime === "function"
            ? useUTC
              ? // Save the date in universal format to prevent differences in various timezones
                fromZonedTime(selectedDates[0], "UTC").getTime()
              : selectedDates[0].getTime()
            : 0;

        setValue(timestamp);
        if (typeof action === "function") {
          action((status) => !status);
        }
      },
      disable: [minDate],
      minDate: minDate || null,
      maxDate: maxDate || null,
      locale: locale(),
      inline: true,
    });

    return () => {
      datepicker.destroy();
    };
  }, [inputRef, value, setValue, minDate, maxDate]);

  return (
    <div
      data-t1="date_form_calendar"
      className={classes.root}
      ref={calendarRef}
    >
      <input className="visually-hidden" ref={inputRef} readOnly />
    </div>
  );
};

DatePickerCalendar.propTypes = {
  classes: shape({
    root: string,
  }).isRequired,
  minDate: oneOfType([string, number, shape({})]),
  maxDate: oneOfType([string, number, shape({})]),
  value: oneOfType([string, number, shape({})]),
  action: func,
  setValue: func.isRequired,
  eventListenerName: string,
};

DatePickerCalendar.defaultProps = {
  minDate: null,
  maxDate: null,
  value: null,
  action: null,
  eventListenerName: "click",
};

export default classify({})(DatePickerCalendar);
