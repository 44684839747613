import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { Guest, IRoom } from "web/types/Tourism";

type RoomsWithGuests = (IRoom & { guests: Guest[] })[];

const getRoomsWithGuests = (
  rooms: IRoom[],
  guests?: Guest[]
): RoomsWithGuests => {
  if (!isArrayHasItems(rooms) || !isArrayHasItems(guests)) {
    return [];
  }

  return rooms.map((room) => {
    const roomGuests = guests.filter((guest) => +guest.rate_num === +room.num);
    return { ...room, guests: roomGuests };
  });
};

export default getRoomsWithGuests;
