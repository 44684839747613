/* eslint-disable @typescript-eslint/no-non-null-assertion */
import DOMPurify from "dompurify";
import { FC } from "react";
import { useSelector } from "react-redux";

import SubscriptionLink from "web/Layout/SubscriptionLink";
import SubscriptionsDatesInfo from "web/Layout/SubscriptionsDatesInfo";

import benefitsBannerImg from "web/assets/images/benefits-banner-v3.png";

import useGroupAvailability from "web/hooks/useGroupAvailability";

import getWindowDateFromBenefitGroups from "web/utils/page/product/subscription/getWindowDateFromBenefitGroups";

import { PropsWithClasses } from "web/types/Common";
import {
  ChoiceButtonType,
  SubscriptionsDatesInfoVariants,
} from "web/types/Subscription";

import classify from "web/classify";
import {
  useGetEmployeeConfigurationQuery,
  useGetEmployeeDetailsQuery,
} from "web/features/employee/employeeApiSlice";

import canShowBanner from "./canShowBanner";
import defaultClasses from "./subscriptionBanner.scss";

const SubscriptionBanner: FC<PropsWithClasses> = ({ classes = {} }) => {
  const { data: employeeDetails } = useGetEmployeeDetailsQuery();

  // switch - don't show subscriptions' banner for employee group
  const { alternativeStartPageDisabled: disableBannerForEmployeeGroup } =
    employeeDetails || {};

  const { data: employeeConfig } = useGetEmployeeConfigurationQuery();
  const {
    alternativeStartPageOverride: enableBannerAlways, // switch - always show subscriptions' banner
    alternativeStartPageOverrideUMS: enableBannerAlwaysUMS,
    bannerForEmployeeWithoutDraft, // show banner to users who never passed configurator
    bannerForEmployeeWithoutDraftUMS,
    subscriptionThresholdDay,
    subscriptionThresholdDayUMS,
    bannerContent,
    bannerContentUMS,
  } = employeeConfig || {};

  const { config, hasEnabledAnySubscriptions } = useSelector(
    (state) => state.subscriptionQuote
  );

  const [hasBothGroupEnabled, isGroupAvailable] = useGroupAvailability();

  let defaultGroupConfig = {
    // case when both groups OR only I group is enabled
    isWindowOpen: config?.open,
    selectionWindowOpenToDate: config?.selectionWindowOpenToDate,
    selectionWindowType: config?.selectionWindowType,
    lastConfigurationDone: config?.lastConfigurationDone,
    enableBannerAlways,
    bannerForEmployeeWithoutDraft,
    subscriptionThresholdDay,
  };

  // Never show banner if subscriptions are disabled or banner is disabled for employee group
  if (!hasEnabledAnySubscriptions || disableBannerForEmployeeGroup) {
    return null;
  }

  const isGroupOneAvailable = isGroupAvailable(1);
  const isGroupTwoAvailable = isGroupAvailable(2);

  if (!hasBothGroupEnabled && isGroupTwoAvailable) {
    // case when only UMS is enabled
    defaultGroupConfig = {
      ...defaultGroupConfig,
      isWindowOpen: config.openUms,
      selectionWindowOpenToDate: config?.selectionWindowOpenToDateUms,
      selectionWindowType: config?.selectionWindowTypeUms,
      enableBannerAlways: enableBannerAlwaysUMS,
      bannerForEmployeeWithoutDraft: bannerForEmployeeWithoutDraftUMS,
      subscriptionThresholdDay: subscriptionThresholdDayUMS,
    };
  }

  const windowDate = getWindowDateFromBenefitGroups(
    defaultGroupConfig.selectionWindowType,
    defaultGroupConfig.selectionWindowOpenToDate,
    defaultGroupConfig.subscriptionThresholdDay
  );

  const showBannerFlag = canShowBanner(defaultGroupConfig);

  return showBannerFlag ? (
    <section className={`${classes.root} container`}>
      <img
        className={classes.bgImage}
        src={benefitsBannerImg}
        alt="Abonamenty"
      />
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <div>
            {windowDate && (
              <SubscriptionsDatesInfo
                finalDates={windowDate}
                variant={SubscriptionsDatesInfoVariants.LARGE}
                highlightColor="#00A1ED"
                displayDisclaimer={hasBothGroupEnabled}
              />
            )}
          </div>
          <div className={classes.desc}>
            {isGroupOneAvailable && bannerContent && (
              <div
                className="cke_editable"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(bannerContent),
                }}
              />
            )}
            {isGroupTwoAvailable && bannerContentUMS && (
              <div
                className="cke_editable"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(bannerContentUMS),
                }}
              />
            )}
          </div>
          <div className={classes.buttons}>
            {!hasBothGroupEnabled && (
              <SubscriptionLink
                type={ChoiceButtonType.CHANGE_SUBSCRIPTIONS}
                group={isGroupTwoAvailable ? 2 : 1}
              />
            )}
            <SubscriptionLink type={ChoiceButtonType.MY_SUBSCRIPTIONS} />
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export default classify<PropsWithClasses>(defaultClasses)(SubscriptionBanner);
