import { FC } from "react";
import { useLocation } from "react-router-dom";

import Loading from "web/Layout/Common/Loading";

import urls from "web/constants/urls";

import type { PropsWithClasses } from "web/types/Common";

import { useAppContext } from "web/context/app";
import { useGetFooterQuery } from "web/features/api/graphQLApiSlice";

import FooterDefault from "./footerDefault";
import FooterFromCms from "./footerFromCms";

const hideFooterPathNames = [
  urls.navigation,
  urls.checkoutDefaultPayments,
  urls.checkoutDefaultSummary,
  urls.checkoutInternationalTourismPayments,
  urls.checkoutInternationalTourismSummary,
  urls.checkoutDomesticTourismInformation,
  urls.checkoutDomesticTourismPayments,
  urls.checkoutDomesticTourismSummary,
  urls.checkoutSubscriptionConfiguration,
  urls.checkoutSubscriptionInformation,
  urls.checkoutSubscriptionAgreements,
  urls.checkoutSubscriptionSummary,
  urls.checkoutSubscriptionBlocked,
  urls.checkoutBuyNowPayments,
  urls.checkoutBuyNowSummary,
  urls.checkoutSplash,
  urls.checkoutExternalPrepaid,
];

const FooterContainer: FC<PropsWithClasses> = ({ classes = {} }) => {
  const { data: footerBlock, isLoading } = useGetFooterQuery();

  const { pathname } = useLocation();
  const { isMobile } = useAppContext();
  const isHidden = hideFooterPathNames.some(
    (pathName) => pathname.indexOf(pathName) !== -1
  );

  if (isLoading) {
    return <Loading />;
  }

  if (!isHidden && !isMobile) {
    return footerBlock?.length ? (
      <FooterFromCms footerContent={footerBlock} classes={classes} />
    ) : (
      <FooterDefault classes={classes} />
    );
  }

  return null;
};

export default FooterContainer;
