/* eslint-disable react/prop-types */
import { FC } from "react";
import DiscountCommon from "web/Components/Common/Discount";
import { MBProductType } from "web/types/Product";

interface IProductCardDiscountProps {
  value: number;
  type?: MBProductType;
}

const ProductCardDiscount: FC<IProductCardDiscountProps> = ({
  value,
  type,
}) => {
  switch (type) {
    case "universal_code": {
      return <DiscountCommon value={value} />;
    }
    default: {
      return null;
    }
  }
};

export default ProductCardDiscount;
