import { FC } from "react";
import withLabel from "web/Components/Common/withLabel";
import classes from "./bestseller.scss";

interface IProductCardReviewsLabelBestsellerProps {
  label: string;
}

const ProductCardReviewsLabelBestseller: FC<
  IProductCardReviewsLabelBestsellerProps
> = ({ label }) => {
  return label ? (
    <span className={classes.root}>
      <span>{label}</span>
    </span>
  ) : null;
};

export default withLabel(ProductCardReviewsLabelBestseller);
