/* eslint-disable import/no-extraneous-dependencies */
import storage from "localforage";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

import reducer from ".";
import persistWhitelist from "./persistWhitelist";

const persistConfig = {
  key: "root",
  storage,
  whitelist: persistWhitelist,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer<ReturnType<typeof reducer>>(
  persistConfig,
  reducer
);

export default persistedReducer;
