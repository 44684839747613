import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import __ from "web/Layout/Translations";

import useCodeItemStatus from "web/hooks/useCodeItemStatus";
import { CodeItemStatusCode } from "web/hooks/useCodeItemStatus/useCodeItemStatus";
import usePaginationParams from "web/hooks/usePaginationParams";
import useUrlParams from "web/hooks/useUrlParams";

import orderStateStatus from "web/constants/orderStateStatus";

import { ProductCodeStatus } from "web/types/Code";
import { OrderStatus } from "web/types/Order";

import { useAppContext } from "web/context/app";
import { getCodes, setCodeUsed } from "web/features/codes/codesThunks";
import { FilterValues } from "web/features/codes/utils/helpers";

import CodeItemHeader from "./CodeItemHeader";
import Content from "./Content";
import Image from "./Image";
import Loader from "./Loader";
import ShowCode from "./ShowCode";
import classesDesktop from "./codeItemDesktop.scss";
import classesMobile from "./codeItemMobile.scss";

const classesInit = () => {
  const { isMobile } = useAppContext();

  return isMobile ? classesMobile : classesDesktop;
};

interface ICodeItemProps {
  link?: string;
  name: string;
  itemId: number;
  image?: string;
  userUsed?: boolean | number;
  expirationDate: string;
  options?: { attribute?: { wartosc?: string } }[];
  shortened?: boolean;
  state?: string;
  orderStatus?: string;
  dataT1?: string;
  codeDetails?: any;
  productStatus: ProductCodeStatus;
  orderStatusCode?: OrderStatus | undefined;
  producSuperOptions?: any;
  sku: string;
}

const CodeItem = ({
  link = "",
  name,
  image = "",
  userUsed = 0,
  expirationDate,
  options = [],
  shortened = false,
  itemId,
  state = "",
  orderStatus = "",
  orderStatusCode = "" as OrderStatus,
  dataT1 = "code_item",
  codeDetails = {},
  productStatus,
  producSuperOptions = {},
  sku = "",
}: ICodeItemProps) => {
  const dispatch = useDispatch();
  const { settingMarkIds = [] } = useSelector((state) => state.codes);
  const classes = classesInit();
  const [isAvailable, setAvailability] = useState(!userUsed);
  const isSettingMark = settingMarkIds.some((id) => id === itemId);

  const {
    hoverLabel,
    switchable,
    label,
    isCanceled,
    active,
    isExpired,
    statusCode,
    disabled,
  } = useCodeItemStatus(
    productStatus,
    isAvailable,
    expirationDate,
    orderStatusCode
  );
  const { page } = usePaginationParams();
  const { availability } = useUrlParams("availability");

  const rootClassName =
    isExpired || !isAvailable ? classes.rootUsed : classes.rootAvailable;
  const onChangeHandler = useCallback(async () => {
    const isUsed = isAvailable ? 1 : 0;
    await dispatch(setCodeUsed({ itemId, isUsed }));
    dispatch(getCodes({ page, filterValue: availability as FilterValues }));
    setAvailability((status) => !status);
  }, [setAvailability, isAvailable]);
  const { isMobile } = useAppContext();
  const isToBeCanceled =
    state === orderStateStatus.processing ||
    state === orderStateStatus.canceled ||
    state === orderStateStatus.pending;

  return (
    <article className={rootClassName}>
      {!isToBeCanceled ? (
        <CodeItemHeader
          className={classes.header}
          onChange={onChangeHandler}
          active={!!active}
          statusCode={statusCode as CodeItemStatusCode}
          switchable={switchable}
          hoverLabel={hoverLabel as string}
          label={label as string}
        />
      ) : (
        <CodeItemHeader
          className={classes.header}
          onChange={onChangeHandler}
          statusCode={statusCode as CodeItemStatusCode}
          active={false}
          switchable={switchable}
          hoverLabel={hoverLabel as string}
          label={orderStatus}
        />
      )}
      <div className={classes.middleContainer}>
        <Image link={link} name={__(name)} image={image} />
        {isMobile ? (
          <h2 className={classes.title}>
            <Link to={link} title={__(name)}>
              {__(name)}
            </Link>
          </h2>
        ) : null}
      </div>
      {shortened ? null : (
        <Content
          link={link}
          name={__(name)}
          expirationDate={expirationDate}
          options={options}
          dataT1={`${dataT1}_content`}
          codeDetails={codeDetails}
          producSuperOptions={producSuperOptions}
        />
      )}
      <ShowCode
        isCanceled={!!isCanceled || !!isToBeCanceled || !!isExpired}
        name={__(name)}
        itemId={itemId}
        orderStatusCode={orderStatusCode}
        sku={sku}
        statusCode={statusCode}
        isShowCodeDisabled={!!disabled}
      />
      <Loader isLoading={isSettingMark} />
    </article>
  );
};

export default CodeItem;
