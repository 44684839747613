import { dayMilliseconds } from "web/constants/date";
import storageNames from "web/constants/storageNames";

import BrowserPersistence from "./system/storage/storage/browserPersistence";

const storage = new BrowserPersistence();

export const getSkippedMessages = (): number[] =>
  storage.getItem(storageNames.skippedMessages) || [];

export const addSkippedMessage = (id: number) => {
  const skippedMessages = getSkippedMessages() || [];
  if (
    skippedMessages.length &&
    skippedMessages.some((sid: number) => sid === id)
  )
    return;
  storage.setItem(
    storageNames.skippedMessages,
    null,
    [...skippedMessages, id],
    dayMilliseconds
  );
};
