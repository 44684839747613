/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo } from "react";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IAttribute } from "web/types/Attributes";
import type { ICartParentProductAttribute } from "web/types/Cart";
import { ICategory } from "web/types/Category";
import type { Nullable } from "web/types/Utils";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";
import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";

export interface IProductOption {
  productAttributesId?:
    | ICartParentProductAttribute[]
    | [Record<string, never>]
    | Partial<ICartParentProductAttribute>[];
  productCategoriesIds?: number[];
  productCategoryId?: Nullable<number>;
  productId?: number | string;
}
export interface IFinalProductOption {
  fullAttributePath: string;
  fullCategoriesPaths: string[];
  fullCategoryPath: string;
  productId: string;
}

const useGetProductOptions = (
  productsOptionsList: IProductOption[]
): [IFinalProductOption[], boolean] => {
  const productAttributesIds = isArrayHasItems(productsOptionsList)
    ? productsOptionsList.map(
        (productOption) => productOption.productAttributesId
      )
    : null;

  const productsAttributesList = isArrayHasItems(productAttributesIds)
    ? productAttributesIds.flat(1)
    : null;

  const { attributes } = useMemo(() => {
    if (isArrayHasItems(productsAttributesList)) {
      return {
        attributes: productsAttributesList.reduce<string[]>(
          (result, current) => {
            const keys = current ? Object.keys(current) : [];

            return [...result, ...keys];
          },
          [] as string[]
        ),
      };
    }
    return {};
  }, [productsAttributesList]);

  const { data: dataAttributes, loading: loadingAttributes } =
    useDataCachedAttributes({
      ids: attributes!,
      skip: false,
    });

  const productVariants: (string | never[])[] = [];

  if (isArrayHasItems(productAttributesIds)) {
    productAttributesIds.forEach((listItem) => {
      // @ts-ignore
      const attributesProcessed: string | [] = listItem.reduce<string | []>(
        // @ts-ignore
        (result, current) => {
          const attributeData: IAttribute | undefined =
            current &&
            dataAttributes?.find(
              (dataItem) =>
                dataItem &&
                current[dataItem.attribute_code as keyof typeof current]
            );
          const optionData =
            attributeData &&
            isArrayHasItems(attributeData.attribute_options) &&
            attributeData.attribute_options.find(
              (option) =>
                option &&
                current[
                  attributeData.attribute_code as keyof typeof current
                ] === option.value
            );

          return optionData ? `${result}${optionData.label}/` : result;
        },
        []
      );

      productVariants.push(
        attributesProcessed ? attributesProcessed.slice(0, -1) : ""
      );
    });
  }

  const productCategoryIds: number[] = [];
  if (productsOptionsList) {
    productsOptionsList.forEach(
      ({ productCategoryId, productCategoriesIds }) => {
        if (productCategoryId) productCategoryIds.push(+productCategoryId);
        if (productCategoriesIds?.length)
          // @ts-ignore
          productCategoryIds.push(...productCategoriesIds);
      }
    );
  }

  const modifiedCategoriesIds = isArrayHasItems(productCategoryIds)
    ? [...new Set(productCategoryIds.flat().map((id) => +id))]
    : productCategoryIds;

  const { data: dataCategories, loading: loadingCategories } =
    useDataCachedCategories({
      ids: modifiedCategoriesIds?.length > 0 ? modifiedCategoriesIds : [],
    }) as { data: ICategory[]; loading: boolean };
  const isGlobalCategory =
    modifiedCategoriesIds.length === 1 &&
    (modifiedCategoriesIds[0] === 1 || modifiedCategoriesIds[0] === 2);

  const parentsCategories =
    dataCategories?.map((dataCategory) => {
      const splitedIds = dataCategory.path?.split("/");
      splitedIds?.splice(0, 2);

      return splitedIds?.map((id) => parseInt(id, 10));
    }) || [];

  const parentsCategoriesList = parentsCategories?.flat(1);

  const uniqIds = [...new Set(parentsCategoriesList)];

  const { data: dataFinalCategories, loading: loadingUniqIds } =
    useDataCachedCategories({
      ids: uniqIds,
    }) as { data: ICategory[]; loading: boolean };

  let finalProductOptions: IFinalProductOption[] = [];

  if (
    (dataFinalCategories.length ||
      isGlobalCategory ||
      !productCategoryIds.length ||
      (productCategoryIds.length &&
        !dataCategories.length &&
        !loadingCategories)) &&
    isArrayHasItems(productsOptionsList) &&
    !loadingUniqIds
  ) {
    const final = productsOptionsList.map((productOption, index) => {
      const getCategoryPath = (productCategoryId: Nullable<number>) => {
        if (
          !productCategoryId ||
          productCategoryId === 1 ||
          productCategoryId === 2 ||
          !dataCategories?.length ||
          !dataCategories[0]?.path?.length
        ) {
          return "MyBenefit";
        }

        const productCategoriesArray = isArrayHasItems(dataCategories)
          ? dataCategories.filter((dataCategoryObject) => {
              return +productCategoryId === +dataCategoryObject.id;
            })
          : [];

        const productCategoryPath =
          productCategoriesArray?.length > 0
            ? productCategoriesArray[0].path?.split("/")
            : [];

        productCategoryPath.splice(0, 2);
        const finalCategoryPath = productCategoryPath.map((categoryId) => {
          const categorySingleObject = isArrayHasItems(dataFinalCategories)
            ? dataFinalCategories.filter(
                (singleCategory) => +singleCategory.id === +categoryId
              )
            : [];

          return categorySingleObject.length
            ? `${categorySingleObject[0].name}/`
            : "";
        });

        const finalCategoryPathString = finalCategoryPath.join("");

        return finalCategoryPathString.slice(0, -1);
      };

      return {
        productId: productOption.productId,
        fullCategoryPath:
          getCategoryPath(Number(productOption?.productCategoryId)) ||
          "MyBenefit",
        fullAttributePath:
          productVariants[index].length === 0 ? "" : productVariants[index],
        fullCategoriesPaths: productOption?.productCategoriesIds?.reduce(
          (acc, category) => {
            const categoryPath = category ? getCategoryPath(+category) : null;
            if (categoryPath) acc.push(categoryPath);
            return acc;
          },
          [] as string[]
        ),
      };
    });

    finalProductOptions = final as IFinalProductOption[];
  }

  const isReady: boolean =
    !loadingCategories &&
    !loadingUniqIds &&
    !loadingAttributes &&
    finalProductOptions?.every(
      (productObject) => productObject?.fullCategoryPath?.length
    );

  return [finalProductOptions, isReady];
};

export default useGetProductOptions;
