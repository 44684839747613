import { DocumentNode, ExecutableDefinitionNode, FieldNode } from "graphql";

import { Nullable } from "web/types/Utils";

const filterByOperationDefinition = (
  items: readonly ExecutableDefinitionNode[]
) =>
  items.filter(
    (definition) => definition.kind === "OperationDefinition" && definition.name
  );

const getOperationName = (document: DocumentNode) => {
  if (document && document.definitions && document.definitions.length) {
    const definitionsFiltered = filterByOperationDefinition(
      document.definitions as ExecutableDefinitionNode[]
    );

    if (definitionsFiltered.length) {
      return (
        definitionsFiltered.map((definition) => definition.name?.value)[0] ||
        null
      );
    }
  }
  return null;
};

const getSelectionSetName = (document: DocumentNode): Nullable<string[]> => {
  if (document && document.definitions && document.definitions.length) {
    const definitionsFiltered = filterByOperationDefinition(
      document.definitions as ExecutableDefinitionNode[]
    );

    if (definitionsFiltered.length) {
      return definitionsFiltered.reduce<string[]>((result, current) => {
        if (
          current &&
          current.selectionSet &&
          current.selectionSet.selections &&
          current.selectionSet.selections.length
        ) {
          const names = current.selectionSet.selections.reduce<string[]>(
            (subResult, subCurrent) =>
              subCurrent &&
              (subCurrent as FieldNode).name &&
              (subCurrent as FieldNode).name.value
                ? [...subResult, (subCurrent as FieldNode).name.value]
                : subResult,
            []
          );

          return [...result, ...names];
        }
        return result;
      }, []);
    }
  }
  return null;
};

export const getFlattedData = <T = unknown>(
  data: { data: Record<string, { edges: { node: T }[] }> },
  query: DocumentNode,
  selectionSet?: string
) => {
  const operationName = getOperationName(query);
  const selectionSetName = selectionSet
    ? [selectionSet]
    : getSelectionSetName(query);

  if (
    data &&
    data.data &&
    selectionSetName &&
    selectionSetName.length &&
    data.data[selectionSetName[0]]
  ) {
    switch (operationName) {
      case "campaignGraphics":
      case "cmsBlocks":
        return data.data[selectionSetName[0]] || [];
      default:
        return data.data[selectionSetName[0]].edges &&
          data.data[selectionSetName[0]].edges.length
          ? data.data[selectionSetName[0]].edges.reduce<T[]>(
              (result, current) => {
                const name = "node";
                return current &&
                  Object.prototype.hasOwnProperty.call(current, name)
                  ? [...result, current.node]
                  : result;
              },
              []
            )
          : [];
    }
  }
  return [];
};

export { filterByOperationDefinition, getOperationName, getSelectionSetName };
