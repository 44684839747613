import { useEffect } from "react";

import isCheckoutDefault from "./isCheckoutDefault";

const cartBC = window.BroadcastChannel ? new BroadcastChannel("cart") : null;
const CART_CHANGED = "cart_changed";

export const broadcastCartChange = () => cartBC?.postMessage(CART_CHANGED);

const useCartRefreshHandler = () => {
  useEffect(() => {
    if (cartBC) {
      cartBC.onmessage = ({ data }) => {
        // if cart changed in other tab and user is on default checkout, redirect to cart page with reload
        if (data === CART_CHANGED && isCheckoutDefault()) {
          window.location.href = "/checkout/default/cart";
        }
      };
    }

    return cartBC?.close;
  }, []);
};

export default useCartRefreshHandler;
