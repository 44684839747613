import DOMPurify from "dompurify";
import { FC, useEffect, useState } from "react";

interface IHtmlTemplateProps {
  template: string;
  variables?: { [key: string]: string };
  className?: string;
}

const HtmlTemplate: FC<IHtmlTemplateProps> = ({
  template,
  variables = {},
  className = "",
}) => {
  const [html, setHtml] = useState("");

  useEffect(() => {
    if (!template) {
      setHtml("");
      return;
    }
    let result = template;
    Object.keys(variables).forEach((key) => {
      const re = new RegExp(`{{(\\s+)?${key}(\\s+)?}}`, "g");
      result = result.replace(re, variables[key] || "");
    });
    setHtml(result);
  }, [variables, template]);

  return (
    <span
      className={className}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(html, { ADD_ATTR: ["target", "rel"] }),
      }}
    />
  );
};

export default HtmlTemplate;
