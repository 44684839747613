import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import SkeletonProductImage from "./SkeletonImage";
import SkeletonProductAside from "./SkeletonProductAside";
import SkeletonProductConfigurableOptions from "./SkeletonProductConfigurableOptions";
import SkeletonProductPrices from "./SkeletonProductPrices";
import defaultClasses from "./product.scss";

const SkeletonProduct: FC<PropsWithClasses> = ({ classes = {} }) => {
  const { isMobile } = useAppContext();

  return !isMobile ? (
    <div className={classes.productWrapper}>
      <div className={classes.desktopProductImageWrapper}>
        <SkeletonProductImage />
      </div>
      <SkeletonProductAside />
    </div>
  ) : (
    <div>
      <SkeletonProductImage />
      <div className={classes.mobileProductContentWrapper}>
        <Skeleton className={classes.starsRating} />
        <Skeleton className={classes.mobileProductTitle} />
        <SkeletonProductPrices />
        <SkeletonProductConfigurableOptions />
      </div>
    </div>
  );
};

export default classify<PropsWithClasses>(defaultClasses)(SkeletonProduct);
