// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-infoData-iEb{margin-right:60px;font-size:14px;line-height:19px}.info-infoDataLarge-Nb2{margin-right:50px;font-size:1.875rem}.info-infoDataProduct-E1X{font-size:13px;line-height:1.5}`, "",{"version":3,"sources":["webpack://./web/Layout/SubscriptionsDatesInfo/info.scss"],"names":[],"mappings":"AAIuB,mBACrB,iBAAA,CACA,cAAA,CACA,gBAAA,CAGF,wBACE,iBAAA,CACA,kBAAA,CAGF,0BACE,cAAA,CACA,eAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .infoData {\n  margin-right: 60px;\n  font-size: 14px;\n  line-height: 19px;\n}\n\n.infoDataLarge {\n  margin-right: 50px;\n  font-size: 1.875rem;\n}\n\n.infoDataProduct {\n  font-size: 13px;\n  line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoData": `info-infoData-iEb`,
	"infoDataLarge": `info-infoDataLarge-Nb2`,
	"infoDataProduct": `info-infoDataProduct-E1X`
};
export default ___CSS_LOADER_EXPORT___;
