import { FC, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getSearchParameterAll from "web/utils/system/url/getSearchParameterAll";

import FiltersFieldsetWrapper from "../Wrapper";
import DateRange from "./dateRange";

interface IDateRangeContainerProps {
  title: string;
  code: string;
  labelFrom?: string;
  labelTo?: string;
}

const DateRangeContainer: FC<IDateRangeContainerProps> = ({
  title,
  code,
  labelFrom = "Data od",
  labelTo = "Data do",
}) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const dateParams = getSearchParameterAll({ name: code, search });
  const from =
    isArrayHasItems(dateParams) && +dateParams[0] ? +dateParams[0] : null;
  const to =
    isArrayHasItems(dateParams) && +dateParams[1] ? +dateParams[1] : null;
  const minDate = from || Date.now();
  const onChangeHandler = useCallback(
    (values: number[]) => {
      const params = new URLSearchParams(search);
      params.delete(code);

      if (isArrayHasItems(values)) {
        values.forEach((value) => {
          if (value) {
            params.append(code, encodeURIComponent(value));
          }
        });
      }
      push(`${pathname}?${params.toString()}`);
    },
    [pathname, push, search, code]
  );

  return (
    <FiltersFieldsetWrapper title={title}>
      <DateRange
        from={from}
        to={to}
        min={minDate}
        max={to}
        labelFrom={labelFrom}
        labelTo={labelTo}
        onChange={onChangeHandler}
        dataT1="filters_date_range"
      />
    </FiltersFieldsetWrapper>
  );
};

export default DateRangeContainer;
