const convertToRomanNumeral = (num: string | number): string | number => {
  const numberParsed = Number(num);

  if (!numberParsed) {
    return "";
  }
  if (numberParsed > 3) {
    return numberParsed;
  }

  return `I${convertToRomanNumeral(numberParsed - 1)}`;
};

export default convertToRomanNumeral;
