import __ from "web/Layout/Translations";

import CANCELLATION_POLICY from "web/constants/cancellationPolicy";

type CancellationPolicyType = typeof CANCELLATION_POLICY;

const enum OfferTypes {
  REFUNDABLE = "Oferta zwrotna",
  NON_REFUNDABLE = "Oferta bezzwrotna",
}

const getRefundLabel = (
  policy: CancellationPolicyType[keyof CancellationPolicyType]
) => {
  if (policy === CANCELLATION_POLICY.NON_REFUNDABLE) {
    return __(OfferTypes.NON_REFUNDABLE);
  }

  return __(OfferTypes.REFUNDABLE);
};

export default getRefundLabel;
