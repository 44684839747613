import { Button } from "@benefit-systems/common-components";
import { FC, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import __ from "web/Layout/Translations";

import { pageName, pageSize } from "web/constants/orders";

import { useAppContext } from "web/context/app";

interface ILoadMoreProps {
  total: number;
  currentTotal: number;
  shouldChangePage: boolean;
  loading: boolean;
}

const LoadMore: FC<ILoadMoreProps> = ({ total, currentTotal, shouldChangePage = false, loading = false }) => {
  const { push } = useHistory();
  const { pathname, search, hash } = useLocation();
  const { isMobile } = useAppContext();
  const clickHandler = useCallback(() => {
    const params = new URLSearchParams(search);
    const currentPage = parseInt(params.get(pageName) || "1", 10);
    const currentSize = parseInt(params.get(pageSize) || "10", 10);

    if (!Number.isNaN(currentPage)) {
      if (isMobile && !shouldChangePage) {
        params.set(pageName, `1`);
        if (currentSize + 10 > total) {
          params.set(pageSize, `${total}`);
        } else {
          params.set(pageSize, `${currentSize + 10}`);
        }
      } else {
        params.set(pageName, `${currentPage + 1}`);
      }
    }

    push(`${pathname}?${params.toString()}${hash}`);
  }, [pathname, search, hash, push]);

  return currentTotal && currentTotal !== total ? (
    <Button onClick={clickHandler} disabled={loading}>
      {__("Załaduj więcej")}
    </Button>
  ) : null;
};

export default LoadMore;
