import { FC } from "react";

import NoPricePlaceholder from "web/Layout/Common/Price/noPricePlaceholder";
import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";

import { PropsWithClasses } from "web/types/Common";
import type { Nullable } from "web/types/Utils";

import classify from "web/classify";

import defaultClasses from "./price.scss";

interface IPriceProps {
  value?: Nullable<string | number>;
  currency?: string;
  negative?: boolean;
  from?: boolean;
  dataT1?: string;
  alternativePlaceholder?: string;
}

const Price: FC<PropsWithClasses<IPriceProps>> = ({
  classes = {},
  value = null,
  currency = "",
  negative = false,
  from = false,
  dataT1 = "price",
  alternativePlaceholder = "",
}) => {
  const priceFormatted = formatNumber(value as string);

  if (Number(value) === 0) {
    return (
      <NoPricePlaceholder
        dataT1={dataT1}
        alternativePlaceholder={alternativePlaceholder}
      />
    );
  }

  return (
    <strong
      className={classes.root}
      data-t1={`${dataT1}_value`}
      data-t2={formatNumber(value as string)}
      data-testid="price"
    >
      {negative ? "- " : null}
      {from ? (
        <span
          className={classes.from}
          data-t1={`${dataT1}_price_from`}
          data-t2={value}
        >
          {__("od")}&nbsp;
        </span>
      ) : null}
      {priceFormatted}&nbsp;
      {currency && (
        <sup
          className={classes.currency}
          data-t1={`${dataT1}_price_currency`}
          data-t2={value}
        >
          {currency}
        </sup>
      )}
    </strong>
  );
};

export default classify<PropsWithClasses<IPriceProps>>(defaultClasses)(Price);
