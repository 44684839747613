/* eslint-disable react/no-array-index-key */
import { FC } from "react";
import { useLocation } from "react-router-dom";

import usePrevious from "web/hooks/usePrevious/usePrevious";

import getSearchParameter from "web/utils/system/url/getSearchParameter";

import { pageLayout } from "web/constants/toolbar";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import SkeletonProductCard from "./SkeletonProductCard";
import defaultClasses from "./productsList.scss";

type PageLayoutOption = typeof pageLayout.options[number];

const SkeletonProductsList: FC<PropsWithClasses> = ({ classes = {} }) => {
  const { search } = useLocation();
  const { isMobile } = useAppContext();
  const pageTypeFromUrl = getSearchParameter({ name: pageLayout.name, search });
  const prevPage = usePrevious({ pageTypeFromUrl });

  let pageLayoutCurrent =
    pageTypeFromUrl &&
    pageLayout.options.includes(pageTypeFromUrl as PageLayoutOption)
      ? pageTypeFromUrl
      : pageLayout.options[0];

  if (!pageTypeFromUrl && prevPage && prevPage.pageTypeFromUrl !== null) {
    pageLayoutCurrent = prevPage.pageTypeFromUrl;
  }

  const isGrid = pageLayoutCurrent !== "list";
  let listClass;
  if (isGrid) {
    listClass = isMobile ? classes.mobileGrid : classes.desktopList;
  } else {
    listClass = isMobile ? classes.mobileList : classes.desktopList;
  }

  return (
    <div className={listClass}>
      {Array(9)
        .fill(undefined)
        .map((item, index) => (
          <SkeletonProductCard key={index} isGrid={isGrid} />
        ))}
    </div>
  );
};

export default classify<PropsWithClasses>(defaultClasses)(SkeletonProductsList);
