import { FC } from "react";
import { useHistory, useLocation } from "react-router";

import { filtersHidden } from "web/Components/Common/Filters/Mobile/filters";

import FiltersIcon from "web/assets/icons/filters.svg";

import urlSearchParamsToolbarFiltered from "web/utils/page/product/universal/urlSearchParamsToolbarFiltered";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import { pageLayout } from "web/constants/toolbar";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./actions.scss";

interface IActionsProps {
  openModal: () => unknown;
}

type PageLayoutOption = typeof pageLayout.options[number];

const Actions: FC<PropsWithClasses<IActionsProps>> = ({
  openModal,
  classes = {},
}) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const pageTypeFromUrl = getSearchParameter({ name: pageLayout.name, search });

  const filtersActive = urlSearchParamsToolbarFiltered(search);
  const amountOfActiveFilters = Object.keys(filtersActive).filter(
    (item) => !filtersHidden.includes(item)
  ).length;

  const [layoutGridName, layoutListName] = pageLayout.options;
  const pageLayoutCurrent =
    pageTypeFromUrl &&
    pageLayout.options.indexOf(pageTypeFromUrl as PageLayoutOption) !== -1
      ? pageTypeFromUrl
      : layoutGridName;

  const gridIconClass =
    pageLayoutCurrent === layoutGridName ? classes.box_chosen : classes.box;
  const listIconClass =
    pageLayoutCurrent === layoutListName ? classes.line_chosen : classes.line;

  const handleSwitchProductType = (type: string) => {
    const params = new URLSearchParams(search);
    params.set(pageLayout.name, encodeURIComponent(type));
    push(`${pathname}?${params.toString()}`);
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <button
          className={classes.button_list}
          type="button"
          onClick={() => handleSwitchProductType("grid")}
          data-t1="category_mobile_actions_grid"
          aria-label="Switch to grid view"
        >
          <div className={gridIconClass} />
        </button>
        <button
          className={classes.button_list}
          type="button"
          onClick={() => handleSwitchProductType("list")}
          data-t1="category_mobile_actions_list"
          aria-label="Switch to list view"
        >
          <div className={listIconClass} />
          <div className={listIconClass} />
          <div className={listIconClass} />
        </button>
      </div>
      <button
        className={classes.button}
        type="button"
        onClick={openModal}
        data-t1="category_mobile_actions_filters"
        aria-label="Show filters"
      >
        {amountOfActiveFilters.toString() === "0" ? (
          <div />
        ) : (
          <span className={classes.badge}>{amountOfActiveFilters}</span>
        )}
        <FiltersIcon width={18} />
      </button>
    </div>
  );
};

export default classify<PropsWithClasses<IActionsProps>>(defaultClasses)(
  Actions
);
