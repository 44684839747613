import DOMPurify from "dompurify";
import { FC, ReactElement, useMemo } from "react";

import __ from "web/Layout/Translations";

import CheckIcon from "web/assets/icons/check.svg";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import {
  ContentTypeActiveFormData,
  HeaderTypeActiveFormData,
  ISubscriptionFormData,
  SubscriptionActiveFormData,
  SubscriptionActiveFormDataType,
  SubscriptionActiveFormField,
  SubscriptionActiveFormFieldType,
} from "web/types/Subscription";

import styles from "./activeFormPreview.scss";

interface IActiveFormPreviewProps {
  configuration: SubscriptionActiveFormData[];
  formData: ISubscriptionFormData;
}

const ActiveFormPreview: FC<IActiveFormPreviewProps> = ({
  configuration,
  formData,
}) => {
  const getHeader = (header: HeaderTypeActiveFormData) => {
    return (
      <strong className={styles.header} key={header.id}>
        {header.value}
      </strong>
    );
  };

  const getContent = (content: ContentTypeActiveFormData) => {
    return (
      <div key={content.id} className="cke_editable">
        <strong className={styles.title}>{content.name}</strong>
        <div className={styles.contentWrapper}>
          {content?.useCheckbox ? (
            <span className={styles.checkbox}>
              {formData.fields[content.id] ? (
                <CheckIcon className={styles.icon} />
              ) : null}
            </span>
          ) : null}
          <div
            className={styles.checkboxLabel}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(content?.content),
            }}
          />
        </div>
      </div>
    );
  };

  const getField = (
    field: SubscriptionActiveFormField,
    multiValueType?: string
  ): ReactElement => {
    const { fields } = formData;
    const { id } = field;
    let value = multiValueType
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fields[id][multiValueType]
      : fields[id];

    if (
      field.type === SubscriptionActiveFormFieldType.DATE ||
      field.type === SubscriptionActiveFormFieldType.BIRTHDATE
    ) {
      value = formatDate(value) || "";
    }
    if (field.type === SubscriptionActiveFormFieldType.REQUIRE_PESEL) {
      return (
        <>
          {getField(
            {
              type: SubscriptionActiveFormFieldType.CHECKBOX,
              id,
              label: __("Obcokrajowiec / nowonarodzone dziecko"),
            },
            "babyOrForeigner"
          )}
          {getField(
            {
              type: SubscriptionActiveFormFieldType.PESEL,
              id,
              label: __("Pesel"),
            },
            SubscriptionActiveFormFieldType.PESEL
          )}
          {getField(
            {
              type: SubscriptionActiveFormFieldType.BIRTHDATE,
              id,
              label: __("Data urodzenia"),
            },
            SubscriptionActiveFormFieldType.BIRTHDATE
          )}
        </>
      );
    }
    if (field.type === SubscriptionActiveFormFieldType.CHECKBOX) {
      return (
        <div className={styles.contentWrapper} key={id}>
          <span className={styles.checkbox}>
            {value ? <CheckIcon className={styles.icon} /> : null}
          </span>
          <div
            className={styles.label}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(field.label),
            }}
          />
        </div>
      );
    }

    if (field.type === SubscriptionActiveFormFieldType.SELECT) {
      value =
        field?.options?.find((el) => el.value === fields[id])?.label ||
        fields[id];
    }

    const currentLabel = field.type === "select" ? field.title : field.label;

    return (
      <dl className={styles.item} key={id}>
        <dt className={styles.label}>{currentLabel}</dt>
        <dd className={styles.value}>{value || "-"}</dd>
      </dl>
    );
  };

  const formBody = useMemo(() => {
    if (!isArrayHasItems(configuration)) return null;

    return configuration.map((section) => {
      switch (section.type) {
        case SubscriptionActiveFormDataType.HEADER:
          return getHeader(section);
        case SubscriptionActiveFormDataType.CONTENT:
          return getContent(section);
        case SubscriptionActiveFormDataType.FORM:
          return section.fields.map((key) => getField(key));
        default:
          return null;
      }
    });
  }, [configuration]);

  return <div className={styles.root}>{formBody}</div>;
};

export default ActiveFormPreview;
