// IMPORTANT: check if UTC dates are provided
const addMonthsToDate = (date: string, months: string | number) => {
  const result = new Date(date);
  const d = result.getUTCDate();
  result.setUTCMonth(result.getUTCMonth() + +months);
  if (result.getUTCDate() !== d) {
    result.setUTCDate(0);
  }
  return result;
};

const addDaysToDate = (date: string, days: string) => {
  const result = new Date(date);
  result.setUTCDate(result.getUTCDate() + +days);
  return result;
};

const isSecondDateAfterFirstDate = (firstDate: Date, secondDate: Date) => {
  return firstDate.setUTCHours(0, 0, 0, 0) < secondDate.setUTCHours(0, 0, 0, 0);
};

const isFirstDateEqualSecondDate = (firstDate: Date, secondDate: Date) => {
  return (
    firstDate.setUTCHours(0, 0, 0, 0) === secondDate.setUTCHours(0, 0, 0, 0)
  );
};

const isTodayAfterDate = (date: Date) => {
  return isSecondDateAfterFirstDate(date, new Date());
};

const hasBlockadeBeforeThresholdDay = (
  blockadeDate: Date,
  examinedDate = new Date()
) => willHaveBlockade(blockadeDate, 1, examinedDate);

const hasBlockadeAfterThresholdDay = (
  blockadeDate: Date,
  examinedDate = new Date()
) => willHaveBlockade(blockadeDate, 2, examinedDate);

const willHaveBlockade = (
  blockadeDate: Date,
  monthsFromExaminedDate: number,
  examinedDate: Date
) => {
  const firstDayOfMonthAfterExaminedDateMonth = new Date(
    examinedDate.getFullYear(),
    examinedDate.getMonth() + monthsFromExaminedDate,
    1
  );

  const willBeAfterOrEqualBlockade =
    isSecondDateAfterFirstDate(
      blockadeDate,
      firstDayOfMonthAfterExaminedDateMonth
    ) ||
    isFirstDateEqualSecondDate(
      blockadeDate,
      firstDayOfMonthAfterExaminedDateMonth
    );

  return !willBeAfterOrEqualBlockade;
};

const isDateInCurrentMonth = (date: Date | string | number) => {
  if (!date) return false;

  const today = new Date();
  const dateObject = new Date(date);

  return areDatesInTheSameMonth(dateObject, today);
};

const areDatesInTheSameMonth = (firstDate: Date, secondDate: Date) => {
  if (!firstDate || !secondDate) return false;

  const firstDateMonth = firstDate.getMonth();
  const firstDateYear = firstDate.getFullYear();
  const secondDateMonth = secondDate.getMonth();
  const secondDateYear = secondDate.getFullYear();

  return firstDateMonth === secondDateMonth && firstDateYear === secondDateYear;
};

const hasSubscriptionStarted = (
  startsDate: Date,
  examinedDate = new Date()
) => {
  return (
    isSecondDateAfterFirstDate(startsDate, examinedDate) ||
    isFirstDateEqualSecondDate(startsDate, examinedDate)
  );
};

const addMonthsAndDaysToDate = (
  startDate: string,
  months: string | number,
  thresholdDay = 15
) => {
  const result = new Date(startDate);
  const originalDate = result.getUTCDate();

  result.setUTCMonth(result.getUTCMonth() + +months - 2);

  if (result.getUTCDate() !== originalDate) {
    result.setUTCDate(0);
  }

  result.setUTCDate(result.getUTCDate() + thresholdDay - 1);

  return result;
};

export default {
  addMonthsAndDaysToDate,
  addMonthsToDate,
  addDaysToDate,
  isSecondDateAfterFirstDate,
  isTodayAfterDate,
  hasBlockadeBeforeThresholdDay,
  hasBlockadeAfterThresholdDay,
  isDateInCurrentMonth,
  isFirstDateEqualSecondDate,
  areDatesInTheSameMonth,
  willHaveBlockade,
  hasSubscriptionStarted,
};
