import type { IAttributeOption } from "web/types/Attributes";

import isArrayHasItems from "../../../data/validator/array/isArrayHasItems";

const mergeLabelsAttributes = (
  attributeOptions: IAttributeOption[],
  labels: (string | number)[]
) => {
  if (!isArrayHasItems(attributeOptions) || !isArrayHasItems(labels)) {
    return null;
  }

  return labels.map((labelId) =>
    attributeOptions.find(
      (attributeOption) =>
        attributeOption && +attributeOption.value === +labelId
    )
  );
};

export default mergeLabelsAttributes;
