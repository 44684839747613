import { useFormikContext } from "formik";
import { FC, useEffect, useMemo, useState } from "react";

import __ from "web/Layout/Translations";

import Select from "web/Components/Common/Select";
import SelectItem from "web/Components/Common/Select/SelectItem/selectItem";

import useDropdown from "web/hooks/useDropdown";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./selectForm.scss";

export interface ISelectOption {
  value?: string;
  label: string;
  name?: string;
}

interface ISelectFormProps {
  options: ISelectOption[];
  name: string;
  placeholder?: string;
  dataT1?: string;
  enableReinitialize?: boolean;
  boldTextIfActive?: boolean;
  hasBackgroundWhenActive?: boolean;
  isDisabled?: boolean;
  shouldTranslateLabel?: boolean;
  selectOnChange?: (val: ISelectOption) => void;
}

const SelectForm: FC<PropsWithClasses<ISelectFormProps>> = ({
  classes = {},
  options,
  name,
  placeholder = "",
  enableReinitialize = false,
  dataT1 = "select_form",
  boldTextIfActive = false,
  hasBackgroundWhenActive = false,
  isDisabled = false,
  selectOnChange = null,
  shouldTranslateLabel = false,
}) => {
  const searchActiveOption =
    isArrayHasItems(options) &&
    options.find((option) => option?.label === placeholder);
  const [active, setActive] = useState(searchActiveOption);
  const [isOpen, setIsOpen] = useDropdown({
    scopeSelector: `#${name}`,
    clickOutside: true,
  });

  useEffect(() => {
    if (enableReinitialize) setActive(searchActiveOption);
  }, [JSON.stringify(searchActiveOption)]);

  const { errors, touched, setFieldValue } = useFormikContext();

  const activeValue =
    (active as ISelectOption)?.value || (active as ISelectOption)?.name;

  useEffect(() => {
    setFieldValue(
      name,
      typeof active === "object" && active ? activeValue : ""
    );
  }, [activeValue]);

  const isError =
    errors[name as keyof typeof errors] &&
    touched[name as keyof typeof touched];

  const changeActive = (chosenOption: ISelectOption) => {
    if (typeof selectOnChange === "function") {
      selectOnChange(chosenOption);
    }
    setActive(chosenOption);
    setIsOpen(false);
  };

  const selectClasses = {
    root: `${isError ? classes.selectError : classes.selectRoot} ${
      isOpen ? classes.selectRoot_open : ""
    }`,
    header: classes.selectHeader,
    list: classes.selectList,
  };

  const activeLabel = useMemo(() => {
    const label =
      typeof active === "object" && active?.label ? active.label : placeholder;

    return shouldTranslateLabel ? __(label) : label;
  }, [active, shouldTranslateLabel]);

  return isArrayHasItems(options) ? (
    <div className={classes.root} id={name}>
      <div className={classes.wrapper}>
        <div className={classes.selectWrapper}>
          <Select
            active={<span className={classes.selectLabel}>{activeLabel}</span>}
            placeholder={placeholder}
            boldTextIfActive={boldTextIfActive}
            hasBackgroundWhenActive={hasBackgroundWhenActive}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            dataT1={dataT1}
            classes={selectClasses}
            isDisabled={isDisabled}
          >
            {options?.map((option, index) => {
              const optionValue = option.value || option.name;
              return (
                <SelectItem
                  key={`${name}_${optionValue}`}
                  index={index}
                  dataT1={dataT1}
                  dataT2={option.value}
                  onSelect={(value) => changeActive(options[value])}
                >
                  <span data-t1="option_label" data-t2={option.label}>
                    {shouldTranslateLabel ? __(option.label) : option.label}
                  </span>
                </SelectItem>
              );
            })}
          </Select>
        </div>
      </div>
    </div>
  ) : null;
};

export default classify<PropsWithClasses<ISelectFormProps>>(defaultClasses)(
  SelectForm
);