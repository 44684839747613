import checkIfTimePassed from "web/utils/data/validator/dateAndTime/checkIfTimePassed";

import { dayMilliseconds } from "web/constants/date";

import {
  FormType,
  ISubscriptionFormData,
  ISubscriptionReceiverForm,
  SubscriptionCheckoutSteps,
  SubscriptionCollectedDataScope,
  SubscriptionFormActions,
  SubscriptionFormReceiver,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

interface IFormProperties {
  form: ISubscriptionReceiverForm;
  formData: ISubscriptionFormData;
  receiverType: SubscriptionFormReceiver;
  subscriptionAllowChangingPersonalData: Nullable<string>;
  wayChooseOthersInActiveForm: SubscriptionFormReceiver;
  configuratorStep: SubscriptionCheckoutSteps;
}

export const isExternalForm = (
  formType: FormType,
  receiverType: SubscriptionFormReceiver,
  wayChooseOthersInActiveForm: SubscriptionFormReceiver
) => {
  return (
    formType === FormType.ACTIVE_FORM &&
    receiverType === SubscriptionFormReceiver.OTHERS &&
    wayChooseOthersInActiveForm === SubscriptionFormReceiver.OTHERS
  );
};

export const isAutoFilled = (
  receiverType: SubscriptionFormReceiver,
  scope: SubscriptionCollectedDataScope
) => {
  return (
    receiverType === SubscriptionFormReceiver.EMPLOYEE &&
    scope === SubscriptionCollectedDataScope.NAMES
  );
};

/**
 * Function specyfing various form modes which determine way of components view & logic handling.
 * All calculated data based on form type, flags etc. should be placed here.
 * @param { object } form - form configuration & data from API.
 * @param { object } formData - form fields in interal state (includes currently changed values).
 * @param { string } receiverType - whom data is saved in the form - employee's or others'.
 * @param { string } subscriptionAllowChangingPersonalData - setting from company configuration determining if employee is allowed to change data.
 * @param { string } wayChooseOthersInActiveForm - setting from subscription configuration re active form for others.
 * @param { string } configuratorStep - configurator page on which form is rendered.
 */
export default ({
  form,
  formData,
  receiverType,
  subscriptionAllowChangingPersonalData,
  wayChooseOthersInActiveForm,
  configuratorStep,
}: IFormProperties) => {
  const {
    completed,
    awaitsUpdate,
    emailSentAt,
    itemActive,
    scope,
    sendCounter,
    submitted,
    type: formType,
    receiverEmail,
  } = form;
  const { fields, filledInByUser } = formData;
  // Is current active form sent by e-mail to other person; employee only gives their e-mail address
  const externalForm = isExternalForm(
    formType,
    receiverType,
    wayChooseOthersInActiveForm
  );
  // In case when company disallow changing any submitted form
  const allowChangingPersonalData = !!Number(
    subscriptionAllowChangingPersonalData
  );
  // In case when company disallow employees to change their data, firstname & lastname fields are autofilled & disabled
  const nameFieldsDisabled =
    receiverType === SubscriptionFormReceiver.EMPLOYEE &&
    !allowChangingPersonalData;
  // Case for form where all fields are autofilled
  const autoFilled = isAutoFilled(receiverType, scope);
  // Case for form with only employee's firstname & lastname, which he can't edit
  const employeeEditDisabled =
    nameFieldsDisabled && scope === SubscriptionCollectedDataScope.NAMES;
  const hasAnyFieldValues = !!Object.keys(fields).some(
    (field) => (fields[field] as string)?.length
  );
  // Form is considered completed if it was:
  // - filledInByUser - data in filled in & saved to internal state on current form view
  // - completed - data was filled in on 2nd step of configurator & saved to draft, but subscription is not yet active
  // - submitted - data was filled in & whole configurator proccess is finished, subscription is pending / active
  // - employeeEditDisabled - data is filled in automatically
  const wasEverFilledIn =
    configuratorStep !== SubscriptionCheckoutSteps.INFORMATIONS ||
    filledInByUser ||
    autoFilled ||
    completed ||
    submitted ||
    (externalForm && hasAnyFieldValues);
  // forms can be edited only on 2nd step of configurator
  const editable =
    configuratorStep === SubscriptionCheckoutSteps.INFORMATIONS &&
    // imported subscriptions can have filled in active forms, but never were submitted
    // in case above we check whether subscription is active
    (completed || !!submitted || itemActive) &&
    allowChangingPersonalData;
  // forms for others to be resend on form edit
  const resend = externalForm && (awaitsUpdate || !!submitted || itemActive);
  let preview = false;
  let actions = SubscriptionFormActions.DEFAULT;

  if (formType === FormType.ACTIVE_FORM) {
    // Active forms have different views for employee & others
    if (receiverType === SubscriptionFormReceiver.EMPLOYEE) {
      // Active form for employee has no preview fields (only buttons)
      preview = false;
    } else if (externalForm) {
      // In forms sent by email to others completed state is irrelevant, check only if employee gave other person e-mail
      // OR if any other field are provided via import
      preview = hasAnyFieldValues;
    } else {
      // Was filled in the past or in the current configuration proccess
      preview = wasEverFilledIn;
    }
  } else {
    // Simple forms are considered completed when they were filled in at any point in the past or all their fields are filled
    // in automatically & not editable
    preview = wasEverFilledIn;
  }

  // Specify possible actions
  if (wasEverFilledIn) {
    if (formType === FormType.ACTIVE_FORM) {
      // Actions for active forms
      if (receiverType === SubscriptionFormReceiver.EMPLOYEE) {
        // Actions for employee's active forms
        if (awaitsUpdate) {
          // If active form had changed (e.g. new field was added) form actions resets to initial state
          actions = filledInByUser
            ? SubscriptionFormActions.PREVIEW_AND_EDIT
            : SubscriptionFormActions.DEFAULT;
        } else {
          // Case for when form is on newly chosen subscription (filled in on the first time)
          // or filled in previosly & changing data is enabled in company configuration
          actions =
            editable || filledInByUser
              ? SubscriptionFormActions.PREVIEW_AND_EDIT
              : // When form was filled in previously & changing data is disabled in company configuration, employee can only preview data
                SubscriptionFormActions.PREVIEW;
        }
      } else {
        // Actions for others's active forms
        // eslint-disable-next-line no-lonely-if
        if (externalForm) {
          // Actions for others filling in forms
          // If active form had changed (e.g. new field was added) form needs to be resend
          if (awaitsUpdate) {
            if (filledInByUser) {
              actions = SubscriptionFormActions.EDIT;
            } else if (!receiverEmail) {
              actions = SubscriptionFormActions.DEFAULT;
            } else if (
              // if email is resend & form isn't filled in 24h, require resend again
              sendCounter === 0 ||
              (sendCounter &&
                emailSentAt &&
                checkIfTimePassed(emailSentAt, dayMilliseconds))
            ) {
              actions = SubscriptionFormActions.RESEND;
            } else {
              // awaiting update forms has edit default action
              actions = SubscriptionFormActions.EDIT;
            }
          } else {
            // Case for when form is on newly chosen subscription (filled in on the first time)
            // or filled in previosly & changing data is enabled in company configuration
            actions =
              configuratorStep === SubscriptionCheckoutSteps.INFORMATIONS &&
              (editable ||
                filledInByUser ||
                (hasAnyFieldValues && allowChangingPersonalData))
                ? SubscriptionFormActions.EDIT
                : // When form was filled in previously & changing data is disabled in company configuration, employee cannot perform any action
                  SubscriptionFormActions.NONE;
          }
        } else {
          // Actions for employee filling in others' forms
          // eslint-disable-next-line no-lonely-if
          if (awaitsUpdate) {
            // If active form had changed (e.g. new field was added) form actions
            actions = SubscriptionFormActions.EDIT;
          } else {
            // Case for when form is on newly chosen subscription (filled in on the first time)
            // or filled in previosly & changing data is enabled in company configuration
            // eslint-disable-next-line no-lonely-if
            if (configuratorStep === SubscriptionCheckoutSteps.INFORMATIONS) {
              actions =
                editable || filledInByUser
                  ? SubscriptionFormActions.EDIT
                  : // When form was filled in previously & changing data is disabled in company configuration, employee cannot perform any action
                    SubscriptionFormActions.NONE;
            } else {
              // Different preview action for configurator steps other than 2nd
              actions = SubscriptionFormActions.PREVIEW_OTHERS;
            }
          }
        }
      }
    } else {
      // Actions for simple forms - the same configuration for employee's forms & others' forms
      actions =
        configuratorStep === SubscriptionCheckoutSteps.INFORMATIONS &&
        (filledInByUser || editable || (autoFilled && !submitted))
          ? SubscriptionFormActions.EDIT
          : SubscriptionFormActions.NONE;
    }
  }

  return {
    actions,
    awaitsUpdate,
    employeeEditDisabled,
    externalForm,
    nameFieldsDisabled,
    preview,
    resend,
  };
};
