import { FC } from "react";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./starsRating.scss";
import StarsRow from "./starsRow";

interface IStarsRatingProps {
  gap?: number;
  size?: number;
  count?: number;
  rating: number;
}

const StarsRating: FC<PropsWithClasses<IStarsRatingProps>> = ({
  classes = {},
  gap = 8,
  size = 18,
  count = 5,
  rating,
}) => {
  const rowStyles = {
    height: `${size}px`,
  };
  const ratingInt = parseInt(rating.toString(), 10);
  const gapQty = ratingInt === count ? ratingInt - 1 : ratingInt;
  const width = ratingInt * size + gapQty * gap + size * (rating - ratingInt);
  const topStyles = {
    width: `${width}px`,
  };

  return typeof rating === "number" ? (
    <div className={classes.root}>
      <div className={classes.row} style={rowStyles} data-t1="stars_rating_row">
        <div className={classes.bottom} data-t1="stars_rating_top">
          <StarsRow
            className={classes.box}
            gap={gap}
            size={size}
            count={count}
            isBottom
          />
        </div>
        <div
          className={classes.top}
          style={topStyles}
          data-t1="stars_rating_top"
        >
          <StarsRow
            className={classes.box}
            gap={gap}
            size={size}
            count={count}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default classify<PropsWithClasses<IStarsRatingProps>>(defaultClasses)(
  StarsRating
);
