import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";

const getSaleDateLabel = (saleDateFrom: string, saleDateTo: string) => {
  const isAnnouncement =
    saleDateFrom && (Date.parse(saleDateFrom) || 0) > Date.now();
  switch (true) {
    case !!isAnnouncement: {
      return `${__("Sprzedaż zaczyna się")}: ${formatDate(saleDateFrom)}`;
    }
    case !!saleDateTo: {
      return `${__("Sprzedaż do")}: ${formatDate(saleDateTo)}`;
    }
    default:
      return null;
  }
};

export default getSaleDateLabel;
