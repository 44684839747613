/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";

import useSortMethods from "web/hooks/useSortMethods";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import FiltersFieldsetWrapper from "../Wrapper";
import type { CommonOptions } from "../fieldset";
import Checkbox from "./Checkbox";
import Sort from "./Sort";
import classes from "./multiselect.scss";

const sortItem = (
  items: CommonOptions[],
  sortMethod: (first: string, second: string) => number,
  sortMethodName: string
) => {
  return isArrayHasItems(items)
    ? items.slice().sort((first, second) => {
        if (
          typeof sortMethod === "function" &&
          typeof sortMethodName === "string"
        ) {
          const key = sortMethodName === "alphabet" ? "label" : "count";
          // @ts-ignore
          return sortMethod(first[key], second[key]);
        }
        return 0;
      })
    : [];
};

interface IMultiselectProps {
  options: CommonOptions[];
  code: string;
  title: string;
}

const Multiselect: FC<IMultiselectProps> = ({ options = {}, code, title }) => {
  const { sortMethod, nameMethod, titleMethod, setNextMethod } =
    useSortMethods();
  const sort = <Sort action={setNextMethod} name={titleMethod} />;

  const optionsSorted = useMemo(() => {
    return sortItem(options as CommonOptions[], sortMethod, nameMethod);
  }, [nameMethod, sortMethod, options]);

  return isArrayHasItems(optionsSorted) ? (
    <FiltersFieldsetWrapper title={title} header={sort}>
      <ul className={classes.root}>
        {optionsSorted.map(({ label, info, value, count }) => {
          return (
            !!label &&
            !!count && (
              <li className={classes.item} key={value}>
                <Checkbox
                  name={code}
                  value={value}
                  label={label}
                  // @ts-ignore
                  info={info}
                  count={count}
                  filterTitle={title}
                  dataT1="multiselect_checkbox"
                />
              </li>
            )
          );
        })}
      </ul>
    </FiltersFieldsetWrapper>
  ) : null;
};

export default Multiselect;
