import { FC } from "react";

import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { PropsWithClasses } from "web/types/Common";
import type {
  IConfigurableOptionsAttribute,
  IProductOptions,
} from "web/types/ProductOptions";

import Configurable from "./Configurable";
import classes from "./top.scss";

interface IProductCardTopProps {
  isAnnouncement: boolean;
  options: IProductOptions;
}

const ProductCardTop: FC<PropsWithClasses<IProductCardTopProps>> = ({
  className,
  isAnnouncement = false,
  options = {} as IProductOptions,
}) => {
  switch (true) {
    case !!isAnnouncement: {
      return (
        <div className={className}>
          <div className={classes.announcement}>{__("Zapowiedź")}</div>
        </div>
      );
    }
    case Boolean(
      options &&
        options.configurable_options &&
        isArrayHasItems(options.configurable_options.attributes)
    ): {
      const firstAttribute = options.configurable_options?.attributes[0];
      const {
        code,
        options: optionsAttr,
      }: IConfigurableOptionsAttribute | Record<string, never> =
        typeof firstAttribute === "object" ? firstAttribute : {};
      return (
        <div className={`${className} ${classes.hidden}`}>
          <Configurable code={code} options={optionsAttr} />
        </div>
      );
    }
    default: {
      return null;
    }
  }
};

export default ProductCardTop;
