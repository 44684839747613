import { DEFAULT_MULTIVALUES } from "web/Layout/SubscriptionReceivers/SubscriptionReceiverForm/ActiveForm/constants";

import {
  IActiveFormValues,
  ISubscriptionFormData,
  ISubscriptionReceiverFormField,
  SubscriptionActiveFormFieldType,
} from "web/types/Subscription";

const typesWithValidations = [
  SubscriptionActiveFormFieldType.REQUIRE_PESEL,
  SubscriptionActiveFormFieldType.PESEL,
  SubscriptionActiveFormFieldType.EMAIL,
  SubscriptionActiveFormFieldType.PHONE,
  SubscriptionActiveFormFieldType.POSTCODE,
];

/**
 * Function parsing active form data from API to structure used for by Formik. Returns object with values and array of validation rules.
 * @param { array } fields - fields configuration from API.
 * @param { object } formData - formData - form fields in interal state (includes currently changed values).
 */
export default (
  fields: ISubscriptionReceiverFormField[],
  formData: ISubscriptionFormData,
  lang: { key: string; code: string }
) => {
  const init: IActiveFormValues = {};
  const toValidate: any[] = [];

  fields.forEach((field) => {
    const rules: string[] = [];
    const { id, type, required } = field;

    switch (type) {
      case SubscriptionActiveFormFieldType.CHECKBOX:
        init[id] = !!formData.fields[id];
        break;
      case SubscriptionActiveFormFieldType.REQUIRE_PESEL:
        init[id] = formData.fields[id]
          ? formData.fields[id]
          : DEFAULT_MULTIVALUES[SubscriptionActiveFormFieldType.REQUIRE_PESEL];
        break;
      default:
        init[id] = formData.fields[id] || "";
    }

    if (required) {
      rules.push("required");
    }

    if (
      typesWithValidations.includes(type as SubscriptionActiveFormFieldType)
    ) {
      rules.push(type);
    }

    if (type === SubscriptionActiveFormFieldType.NUMBER) {
      rules.push("numeric");
    }

    toValidate.push({
      name: id,
      rules,
      ignoreSubResultValidation:
        type === SubscriptionActiveFormFieldType.REQUIRE_PESEL,
      selectedLanguage: lang.key,
    });
  });

  // a little hack to fix formik - it sets meta.touched to false if there is >1 nested fields (requirePesel)
  const initJSON = JSON.stringify(init);
  return [JSON.parse(initJSON), toValidate];
};
