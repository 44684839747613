import ExternalRedirect from "web/Components/Common/ExternalRedirect";

import useLangFromParams from "web/hooks/useLangFromParams/useLangFromParams";

import { externalHelpCenter } from "web/constants/urls";

const FAQRoutes = () => {
  // const dispatch = useDispatch();
  // const { url } = useRouteMatch();
  // const {
  //   isStoreConfigGotten,
  //   storeConfig: { header_logo_src, header_logo_alt },
  // } = useSelector((state) => state.app);

  const { isParamLangEn } = useLangFromParams();

  return (
    <ExternalRedirect
      url={`${externalHelpCenter}/${isParamLangEn ? "en" : "pl"}`}
    />
  );

  // useEffect(() => {
  //   (async () => {
  //     await dispatch(
  //       getStoreConfig({ id: 1, query: storeConfigMin, omitToken: true })
  //     );
  //   })();
  // }, [dispatch, getStoreConfig, storeConfigMin]);

  // if (!isStoreConfigGotten) return <Loading />;

  // return (
  //   <main className={classes.root}>
  //     <div className={classes.logoBox}>
  //       <Logo logoSrc={header_logo_src} logoAlt={header_logo_alt} />
  //     </div>
  //     <Link to="/" className={classes.returnLink}>
  //       <ArrowLeftIcon className={classes.returnIcon} />
  //       {isParamLangEn ? "Go back to login page" : __("Wróc do logowania")}
  //     </Link>
  //     <Switch>
  //       <Route exact path={url} component={FAQ} />
  //       <Route path={url} component={UrlResolver} />
  //     </Switch>
  //   </main>
  // );
};

export default FAQRoutes;
