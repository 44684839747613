import { DateTypes } from "web/types/Utils";

const formatHours = (dateStr: DateTypes) => {
  if (!dateStr) {
    return null;
  }

  const date = new Date(dateStr);

  if (Number.isNaN(Number(date))) {
    return null;
  }

  return [date.getHours(), date.getMinutes()]
    .map((num) => `0${num}`.slice(-2))
    .join(":");
};

export default formatHours;
