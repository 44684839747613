const newRelicExcludedErrors = [
  /failed to fetch/i,
  /fetch error/i,
  /fetch_error/i,
  /load failed/i,
  /Liczba kodów w zamówieniu przekracza dostępną ilość kodów dla tego produktu/i,
  /redirected to login/i,
  /Produkt jest w tej chwili niedostępny/i,
  /Ten produkt jest niedostępny na magazynie/i,
  /NetworkError/i,
];

export default newRelicExcludedErrors;
