// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listingMobile-row-0gI{position:relative;align-items:flex-start;justify-content:center;margin-right:0 !important;margin-left:0 !important}.listingMobile-row-0gI .col.col-9{flex:0 0 100%;max-width:100%}`, "",{"version":3,"sources":["webpack://./web/Layout/Listing/listingMobile.scss"],"names":[],"mappings":"AAIuB,uBACrB,iBAAA,CACA,sBAAA,CACA,sBAAA,CACA,yBAAA,CACA,wBAAA,CACA,kCACE,aAAA,CACA,cAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .row {\n  position: relative;\n  align-items: flex-start;\n  justify-content: center;\n  margin-right: 0 !important;\n  margin-left: 0 !important;\n  :global(.col.col-9) {\n    flex: 0 0 100%;\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `listingMobile-row-0gI`
};
export default ___CSS_LOADER_EXPORT___;
