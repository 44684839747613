const links = {
  pl: [
    { url: "/regulamin", name: "Regulamin", position: 100 },
    {
      url: "https://www.mybenefit.pl/polityka-cookies/",
      name: "Polityka cookies",
      isAnchor: true,
      position: 200,
      openInNewTab: true,
    },
    {
      url: "https://www.mybenefit.pl/polityka-prywatnosci/",
      name: "Polityka prywatności",
      isAnchor: true,
      position: 300,
      openInNewTab: true,
    },
    {
      // eslint-disable-next-line
      url: "javascript:Didomi.preferences.show()",
      name: "Ustawienia cookies",
      isAnchor: true,
      position: 400,
    },
    { url: "/lp/payu.html", name: "Płatności PayU", position: 500 },
    { url: "/lp/paypo.html", name: "Płatności PayPo", position: 600 },
  ],
  en: [
    { url: "/regulamin", name: "Regulamin", position: 100 },
    {
      url: "https://www.mybenefit.pl/en/cookies-policy/ ",
      name: "Polityka cookies",
      isAnchor: true,
      position: 200,
      openInNewTab: true,
    },
    {
      url: "https://www.mybenefit.pl/en/privacy-policy/",
      name: "Polityka prywatności",
      isAnchor: true,
      position: 300,
      openInNewTab: true,
    },
    {
      // eslint-disable-next-line
      url: "javascript:Didomi.preferences.show()",
      name: "Ustawienia cookies",
      isAnchor: true,
      position: 400,
    },
    { url: "/lp/payu.html", name: "Płatności PayU", position: 500 },
    { url: "/lp/paypo.html", name: "Płatności PayPo", position: 600 },
  ],
};

const getNavLinks = (
  languageCode: string
): {
  url: string;
  name: string;
  position: number;
  isAnchor?: boolean;
  openInNewTab?: boolean;
}[] => links[languageCode as keyof typeof links] ?? links.pl;

export default getNavLinks;
