import { isNil } from "lodash";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import Badge from "web/Layout/Common/Badge";

import Bell from "web/assets/icons/bell.svg";

import addObjectFallback from "web/utils/data/transform/addObjectFallback";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { getSkippedMessages } from "web/utils/manageSkippedMessages";

import urls from "web/constants/urls";

import { useGetUnreadMessagesQuery } from "web/features/messages/messagesApiSlice";

import MessagesTrigger from "./messagesTrigger";
import styles from "./messagesTrigger.scss";

interface IMessagesTriggerContainerProps {
  onNotificationOpen?: (arg: boolean) => void;
}

const SHOW_MAX_MESSAGES = 3;
const MessagesTriggerContainer: FC<IMessagesTriggerContainerProps> = ({
  onNotificationOpen = () => {},
}) => {
  const { data: unreadData } = useGetUnreadMessagesQuery();
  const {
    count: amount,
    messages: messageList,
    popup: messagesAsPopup,
  } = addObjectFallback(unreadData);

  const filteredMessages = useMemo(() => {
    if (!messagesAsPopup?.length) return [];
    const skippedMessages = getSkippedMessages();
    return messagesAsPopup.filter(
      ({ id }) => !skippedMessages.some((sMsgId) => id === +sMsgId)
    );
  }, [messagesAsPopup]);

  const messagesProcessed = isArrayHasItems(messageList)
    ? messageList.filter((message) => !message.read).slice(0, SHOW_MAX_MESSAGES)
    : [];

  return isArrayHasItems(messagesProcessed) ||
    isArrayHasItems(messagesAsPopup) ? (
    <MessagesTrigger
      amount={amount as number}
      messageList={messagesProcessed}
      messagesAsPopup={filteredMessages}
      onNotificationOpen={onNotificationOpen}
    />
  ) : (
    <Link className={styles.trigger} to={urls.customerMessages}>
      {!isNil(amount) && (
        <Badge className={styles.badgeEmpty} amount={amount} withoutSpacer />
      )}
      <Bell className={styles.icon} />
    </Link>
  );
};

export default MessagesTriggerContainer;
