/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isNil } from "lodash";
import { FC } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";

import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";
import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import classes from "./points.scss";

const PanelItemPoints: FC = () => {
  const { data: customerData } = useGetCustomerDetailsQuery();
  const { data: dataBanks, isLoading } = useGetBanksQuery();
  const { total: totalPoints } = dataBanks || {};
  const { language } = customerData || {};

  const engClassName = language === "en_GB" ? classes.eng : "";

  const pointsText = __("pkt");
  const pointsFormatted =
    !isNil(totalPoints) && `${formatNumber(totalPoints)}`.replace(/ /g, "");

  return (
    <>
      <span className={`${classes.root} ${engClassName}`}>
        {isLoading ? (
          // @ts-ignore
          <SkeletonTheme highlightColor="#ccc">
            <Skeleton width={55} height={14} />
          </SkeletonTheme>
        ) : (
          pointsFormatted
        )}
      </span>
      <span className={`${classes.text} ${engClassName}`}>{pointsText}</span>
    </>
  );
};

export default PanelItemPoints;
