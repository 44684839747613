import { IGtm } from "web/types/Gtm";
import { Nullable } from "web/types/Utils";

const checkNewIdsUniq = (
  lastProductImpressionEvent: IGtm,
  gtmEnhancedEcommOptions: IGtm,
  lastVirtualPageViewEvent: Nullable<IGtm>
) => {
  const lastEnventProducts =
    lastProductImpressionEvent?.ecommerce?.impressions?.map(
      (item) => item.id
    ) || [];
  const { date_from, date_to, amount_people } =
    lastProductImpressionEvent?.ecommerce?.impressions?.[0] || {};
  let isAllNewIdsUniq = false;

  if (
    (lastVirtualPageViewEvent?.["gtm.uniqueEventId"] as number) >
    (lastProductImpressionEvent?.["gtm.uniqueEventId"] as number)
  ) {
    isAllNewIdsUniq = true;
  } else if (lastEnventProducts?.length > 0 && gtmEnhancedEcommOptions) {
    const {
      date_from: currDateFrom,
      date_to: currDateTo,
      amount_people: currAmountPeople,
    } = gtmEnhancedEcommOptions?.ecommerce?.impressions?.[0] || {};
    if (
      date_from !== currDateFrom ||
      date_to !== currDateTo ||
      amount_people !== currAmountPeople
    ) {
      return true;
    }
    isAllNewIdsUniq = !!gtmEnhancedEcommOptions?.ecommerce?.impressions?.every(
      (item) => !lastEnventProducts.includes(item.id)
    );
  } else if (lastEnventProducts?.length === 0) {
    isAllNewIdsUniq = true;
  }

  return isAllNewIdsUniq;
};

export default checkNewIdsUniq;
