export default {
  firstname: "Imię",
  lastname: "Nazwisko",
  pesel: "Pesel",
  email: "E-mail",
  receiverEmail: "E-mail",
  confirmEmail: "Powtórz e-mail",
  phone: "Numer telefonu",
  phoneNumber: "Numer telefonu",
};
