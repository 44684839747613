/* eslint-disable max-len */
import { cloneDeep } from "lodash";

import { DEFAULT_MULTIVALUES } from "web/Layout/SubscriptionReceivers/SubscriptionReceiverForm/ActiveForm/constants";

import sanitizeObject from "web/utils/data/parser/object/sanitizeObject";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { peselMask } from "web/utils/peselMask";

import {
  ISubscriptionFormData,
  ISubscriptionReceiverFormField,
  ISubscriptionReceiverFormFieldOption,
  ISubscriptionReceiverFormValues,
  SubscriptionActiveFormFieldType,
  SubscriptionFormFieldType,
} from "web/types/Subscription";

const virtualFields = [SubscriptionActiveFormFieldType.CONFIRM_EMAIL];

/**
 * Function parsing simple forms data from API to structure used for by Formik. Returns object with values and array of validation rules.
 * @param { array } fields - fields configuration from API.
 * @param { object } formData - form fields in interal state (includes currently changed values).
 */
export const getInitialValues = (
  fields: ISubscriptionReceiverFormField[],
  formData: ISubscriptionFormData
) => {
  const init: ISubscriptionReceiverFormValues = {};
  const toValidate: any[] = [];

  fields.forEach((field) => {
    let rules: string[] = [];
    const { id, type } = field;
    init[id] = formData.fields[id];

    switch (type) {
      case SubscriptionFormFieldType.PESEL:
        rules = ["required", "pesel"];
        break;
      case SubscriptionFormFieldType.EMAIL:
        rules = ["required", "email"];
        break;
      case SubscriptionActiveFormFieldType.CONFIRM_EMAIL:
        init[id] = formData.fields.receiverEmail;
        rules = ["required", "confirmEmail"];
        break;
      case SubscriptionFormFieldType.PHONE:
        rules = ["required", "phone"];
        break;
      default:
        rules = ["required"];
    }

    toValidate.push({
      name: id,
      rules,
    });
  });

  return [init, toValidate];
};

/**
 * Function mapping a field type and additional properties.
 * @param { string } type - field type.
 * @param { boolean } nameFieldsDisabled - flag determining wheter name & lastname fields are editable.
 */
export const getFieldSpecificProps = (
  type: SubscriptionFormFieldType | SubscriptionActiveFormFieldType,
  nameFieldsDisabled = false
) => {
  switch (type) {
    case SubscriptionFormFieldType.FIRSTNAME:
    case SubscriptionFormFieldType.LASTNAME: {
      return { disabled: nameFieldsDisabled };
    }
    case SubscriptionFormFieldType.PESEL:
      return { mask: peselMask };
    case SubscriptionFormFieldType.EMAIL:
    case SubscriptionFormFieldType.RECEIVER_EMAIL: {
      return { disablePaste: true };
    }
    default:
      return {};
  }
};

/**
 * Function matching field type from API active form config and field value from form internal state. Uses field id to match two entities.
 * @param { array } fields - fields configuration from API.
 * @param { object } formData - form fields in interal state (includes currently changed values).
 * @param { string } type - field type.
 * @returns { string } - field value.
 */
export const getValueByType = (
  fields: ISubscriptionReceiverFormField[],
  formData: ISubscriptionFormData,
  type: SubscriptionFormFieldType
) => {
  if (!isArrayHasItems(fields)) return "-";

  const fieldId = fields.find((field) => field.type === type)?.id ?? "";

  return formData.fields[fieldId];
};

/**
 * Form value getter. Finds field value by matching form data API structure with field type passed in arg.
 * @param { array } fields - fields configuration from API.
 * @param { string } type - field type.
 * @returns { string } - field value.
 */
export const getFieldValueByType = (
  fields: ISubscriptionReceiverFormField[],
  type: SubscriptionFormFieldType
) => {
  if (!isArrayHasItems(fields)) return "-";

  return fields.find((field) => field.type === type)?.value || "-";
};

/**
 * Function formatting multivalue form fields from array to Formik friendly object.
 * @param { array } options - collection of field values from API.
 * @param { string } type - field type.
 * @returns object with field id & value.
 */
export const getMultivalueFromOptions = (
  options: ISubscriptionReceiverFormFieldOption[],
  type: SubscriptionActiveFormFieldType | SubscriptionFormFieldType
) => {
  if (!Array.isArray(options)) return {};

  if (!options.length) {
    return DEFAULT_MULTIVALUES[type];
  }

  const multiValue: any = {};

  options.forEach(({ id, value }) => {
    multiValue[id] = value;
  });

  return multiValue;
};

/**
 * Function deleting virtual fields from form values collections before sending it to API. Virtual fields are used only in interface, e.g. from email confirmation.
 * @param { object } values - Formik values.
 */
export const removeVirtualFields = (
  values: ISubscriptionReceiverFormValues
) => {
  const parsedValues = cloneDeep(values);

  virtualFields.forEach((key) => {
    delete parsedValues[key];
  });

  return sanitizeObject(parsedValues);
};
