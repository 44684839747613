import { IGtm } from "web/types/Gtm";

const getLastVirtualPageViewEvent = (defaultValue = {}): IGtm => {
    const dataLayerTemp = window.dataLayer
    ? [...window.dataLayer].reverse()
    : [];

    return dataLayerTemp.find((element) => element.event === "virtualPageView") || defaultValue;    
};

export default getLastVirtualPageViewEvent;
