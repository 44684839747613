/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Image from "web/Layout/Common/Image";
import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";

import useOnProductClikCallback from "web/hooks/useOnProductClikCallback";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getPriceFinalPriceDiscount from "web/utils/page/product/universal/getPriceFinalPriceDiscount";
import processUrlKey from "web/utils/page/product/universal/processUrlKey";

import type { IProduct, ProductInList } from "web/types/Product";

import classes from "./product.scss";

interface ISearchAutocompleteProductProps {
  className: string;
  closeAction: () => void;
  product: ProductInList;
  searchPhrase: string;
  productPosition: number;
}

const SearchAutocompleteProduct: FC<ISearchAutocompleteProductProps> = ({
  className,
  closeAction,
  product,
  searchPhrase = "",
  productPosition = 1,
}) => {
  const {
    price,
    final_price: finalPrice,
    thumbnail: image,
    product_options: options,
    mb_product_type: type,
  } = product;
  const url = processUrlKey(product.url_key);
  const isPriceVisible =
    [
      "international_tourism_travelplanet",
      "international_tourism_other",
      "anixe_tourism",
    ].indexOf(type) === -1;

  const { price: priceProcessed, finalPrice: finalPriceProcessed } =
    getPriceFinalPriceDiscount({
      price,
      finalPrice,
      options,
    });

  const isConfigurable =
    options &&
    options.configurable_options &&
    isArrayHasItems(options.configurable_options.attributes);

  const name = isConfigurable ? __(product.name) : product.name;

  const isAnnouncement =
    product.sale_date_from &&
    (Date.parse(product.sale_date_from) || 0) > Date.now();

  // @ts-ignore
  const outOfStock = parseInt(product.stock_status, 10) === 0;
  const isUnavailable = isAnnouncement || outOfStock;
  const textFrom = isConfigurable ? `${__("od")} ` : "";
  const isDiscount = priceProcessed !== finalPriceProcessed;
  const classNamePriceBox = isDiscount
    ? classes.priceBoxDiscount
    : classes.priceBox;

  const [sendProductClickEvent] = useOnProductClikCallback(
    product as IProduct,
    productPosition,
    searchPhrase
  );

  // in case of subscription item show different price coming from filtered subscriptions
  const dataSubscription = useSelector((state) => state.benefitGroups);
  const benefitGroups = dataSubscription?.benefitGroups;

  const priceToShow =
    !!benefitGroups && product.mb_product_type.includes("abonament")
      ? benefitGroups[product.sku]?.price
      : finalPriceProcessed;

  return (
    <article className={className} data-t1="autocomplete_product">
      <Link
        to={url}
        className={classes.imageBox}
        // @ts-ignore
        onClick={(closeAction, () => sendProductClickEvent())}
        data-t1="autocomplete_product_link_image"
      >
        <Image className={classes.image} src={image} alt={name} isProduct />
      </Link>
      <div className={classes.content}>
        <h4 className={classes.name} data-t1="autocomplete_product_title">
          <Link
            className={classes.link}
            to={url}
            title={name}
            // @ts-ignore
            onClick={(closeAction, () => sendProductClickEvent())}
            data-t1="autocomplete_product_title_link"
          >
            {name}
          </Link>
        </h4>
        {isPriceVisible && !isUnavailable ? (
          <div className={classNamePriceBox} data-t1="product_price_box">
            {textFrom}
            <Price
              className={classes.price}
              value={priceToShow}
              currency={__("zł")}
              dataT1="final_price"
            />
          </div>
        ) : null}
      </div>
    </article>
  );
};

export default SearchAutocompleteProduct;
