import { FC } from "react";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import StarsRating from "../StarsRating";
import defaultClasses from "./rating.scss";

interface IRatingProps {
  average: number;
  reviews?: number;
}

const Rating: FC<PropsWithClasses<IRatingProps>> = ({
  average = 0,
  reviews = 0,
  classes = {},
}) => {
  const averageProcessed =
    +average && typeof +average === "number" ? average : 0;
  const reviewsProcessed =
    +reviews && typeof +reviews === "number" ? reviews : 0;

  return (
    <div className={classes.root}>
      <StarsRating
        className={classes.box}
        size={15}
        gap={6}
        rating={averageProcessed}
      />
      <span className={classes.reviews} data-t1="rating_reviews">
        {reviewsProcessed || averageProcessed}
      </span>
    </div>
  );
};

export default classify<PropsWithClasses<IRatingProps>>(defaultClasses)(Rating);
