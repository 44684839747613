import { FC } from "react";

import {
  additionalAddressFields,
  userNamesFields,
} from "web/Layout/SubscriptionReceivers/SubscriptionReceiverDuplicateCard/DuplicateCardForm/DuplicateSelect/additionalFormFields";
import __ from "web/Layout/Translations";

import Select from "web/Components/Common/Select";
import SelectItem from "web/Components/Common/Select/SelectItem/selectItem";

import yellowMark from "web/assets/images/mark_yellow.png";

import useDropdown from "web/hooks/useDropdown";

import {
  DUPLICATE_DATA_TYPE,
  DUPLICATE_REASONS_ID,
} from "web/constants/benefits";

import {
  DuplicateCardFormActions,
  DuplicateCardPayload,
  IDuplicateCardFormState,
  IDuplicateFormItem,
  List,
} from "web/types/Subscription";

import classes from "../duplicateCardForm.scss";

interface IDispatchTypes {
  type: DuplicateCardFormActions | string;
  payload?: DuplicateCardPayload;
}

type ListEle = List[];

interface IDuplicateSelect {
  formItem: IDuplicateFormItem;
  disptachFormFieldsState: ({ type, payload }: IDispatchTypes) => void;
  formFieldsState: IDuplicateCardFormState;
  textInputsHandler: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
    required: boolean
  ) => void;
  placeDuplicateDeliveredType: string;
  personalDataError: boolean;
  setPersonalDataError: React.Dispatch<React.SetStateAction<boolean>>;
}

const messages = {
  personalDataFieldError: "Wpisane dane są takie same jak obecne",
  personalDataNotChangedError: `Wybierając "Zmieniły się dane personalne" nie możesz zamówić 
  duplikatu karty na te same dane, które były dotychczas. Zmień powód zamówienia duplikatu lub wpisz poprawne dane.`,
};

const DuplicateSelect: FC<IDuplicateSelect> = ({
  formItem,
  disptachFormFieldsState,
  formFieldsState,
  textInputsHandler,
  placeDuplicateDeliveredType,
  personalDataError,
  setPersonalDataError,
}) => {
  const [isOpen, setIsOpen] = useDropdown({
    scopeSelector: `.${classes.root}`,
    clickOutside: true,
  });

  const selectClasses = {
    root: classes.selectRoot,
    header: classes.selectHeader,
    list: classes.selectList,
  };

  const changeActive = (
    allOptions: ListEle,
    chosenOption: number,
    type: string
  ) => {
    if (
      type === "reason" &&
      allOptions[chosenOption].id !== DUPLICATE_REASONS_ID.change &&
      allOptions[chosenOption].id !== DUPLICATE_REASONS_ID.incorrect &&
      placeDuplicateDeliveredType !== DUPLICATE_DATA_TYPE.address
    ) {
      disptachFormFieldsState({
        type: DuplicateCardFormActions.CLEAR_NAME_LASTNAME,
      });
    }

    if (
      (type === "reason" &&
        allOptions[chosenOption].id === DUPLICATE_REASONS_ID.change) ||
      allOptions[chosenOption].id === DUPLICATE_REASONS_ID.incorrect ||
      placeDuplicateDeliveredType === DUPLICATE_DATA_TYPE.address
    ) {
      const firstNameValue = formFieldsState.newFirstName;
      const lastNameValue = formFieldsState.newLastName;
      disptachFormFieldsState({
        type: DuplicateCardFormActions.NAME_LASTNAME_REQUIRED,
        payload: {
          newFirstName:
            typeof firstNameValue !== "string" ? firstNameValue?.value : "",
          newLastName:
            typeof lastNameValue !== "string" ? lastNameValue?.value : "",
        },
      });
    }

    disptachFormFieldsState({
      type,
      payload: { ...allOptions[chosenOption], required: formItem.required },
    });
    setIsOpen(false);
    setPersonalDataError(false);
  };

  const preparedFormItem = { ...formItem };

  if (
    formItem.inputType === "select" &&
    formItem.type === "place" &&
    placeDuplicateDeliveredType !== DUPLICATE_DATA_TYPE.own &&
    placeDuplicateDeliveredType !== DUPLICATE_DATA_TYPE.mp
  ) {
    preparedFormItem.list = formItem.list.map((ele) => {
      return { id: ele.id, value: ele.name };
    });
  }

  const ifShowAdditionalFormData =
    (preparedFormItem.type === "reason" &&
      placeDuplicateDeliveredType !== DUPLICATE_DATA_TYPE.address &&
      (formFieldsState?.reason?.id === DUPLICATE_REASONS_ID.change ||
        formFieldsState?.reason?.id === DUPLICATE_REASONS_ID.incorrect)) ||
    placeDuplicateDeliveredType === DUPLICATE_DATA_TYPE.address;

  const currSelectValue =
    formFieldsState[preparedFormItem.type as keyof IDuplicateCardFormState];
  const selectValue =
    typeof currSelectValue !== "string" ? currSelectValue?.value : "";

  const { reason } = formFieldsState;
  const reasonId = reason?.id ?? "";

  const isReasonChangeOrIncorrect =
    reasonId === DUPLICATE_REASONS_ID.change ||
    reasonId === DUPLICATE_REASONS_ID.incorrect;

  return preparedFormItem.inputType === "select" &&
    preparedFormItem.showWhen.includes(placeDuplicateDeliveredType) ? (
    <div className={classes.duplicateForm__formItem}>
      <span className={classes.duplicateForm__label}>
        {preparedFormItem.label}
        {preparedFormItem.required && (
          <span className={classes.duplicateForm__required}>*</span>
        )}
      </span>
      <div
        className={classes.duplicateForm__selectBox}
        style={{ zIndex: preparedFormItem.index }}
      >
        <Select
          active={
            <span className={classes.selectLabel}>
              {typeof formFieldsState[
                preparedFormItem.type as keyof IDuplicateCardFormState
              ] === "object" && selectValue
                ? __(selectValue)
                : preparedFormItem.placeholder}
            </span>
          }
          placeholder={preparedFormItem.placeholder}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          classes={selectClasses}
        >
          {preparedFormItem.list?.map((option, index, array) => {
            const optionValue =
              option && typeof option !== "string" ? option.value : "";
            const prepOptionValue =
              typeof optionValue === "string" ? __(optionValue) : optionValue;
            return (
              <SelectItem
                key={prepOptionValue}
                index={index}
                onSelect={(value: number) =>
                  changeActive(array, value, preparedFormItem.type)
                }
              >
                <span data-t1="option_value" data-t2={prepOptionValue}>
                  {prepOptionValue}
                </span>
              </SelectItem>
            );
          })}
        </Select>
      </div>
      {isReasonChangeOrIncorrect && (
        <span className={classes.duplicateForm__newDataLabel}>
          {__("Wprowadź dane do nowej karty")}
        </span>
      )}
      {ifShowAdditionalFormData &&
        userNamesFields(isReasonChangeOrIncorrect)
          .sort((a, b) => a.position - b.position)
          .map((field) => {
            const currValue =
              formFieldsState[field.type as keyof IDuplicateCardFormState];
            return (
              <>
                <span className={classes.duplicateForm__label}>
                  {__(field.label)}
                  {field.required && (
                    <span className={classes.duplicateForm__required}>*</span>
                  )}
                </span>
                <div className={classes.duplicateForm__selectBox}>
                  <input
                    type="text"
                    className={`${classes.input} ${
                      personalDataError ? classes["input--error"] : ""
                    }`}
                    data-t1={field.label}
                    onChange={(e) => {
                      setPersonalDataError(false);
                      textInputsHandler(e, field.type, field.required);
                    }}
                    value={
                      currValue && typeof currValue !== "string"
                        ? currValue.value
                        : ""
                    }
                  />
                  {personalDataError && (
                    <span className={classes.errorMessage}>
                      {messages.personalDataFieldError}
                    </span>
                  )}
                </div>
                {field?.textBelow !== "" &&
                  placeDuplicateDeliveredType !==
                    DUPLICATE_DATA_TYPE.address && (
                    <div className={classes.yellowMark}>
                      <img
                        className={classes.yellowMark__icon}
                        src={yellowMark}
                        alt="exclamation mark"
                      />
                      <span className={classes.yellowMark__text}>
                        {__(field?.textBelow)}
                      </span>
                    </div>
                  )}
              </>
            );
          })}
      {personalDataError && (
        <p className={classes.errorMessage}>
          {messages.personalDataNotChangedError}
        </p>
      )}
      {placeDuplicateDeliveredType === "address" &&
        additionalAddressFields
          .sort((a, b) => a.position - b.position)
          .map((field) => {
            const currValue =
              formFieldsState[field.type as keyof IDuplicateCardFormState];
            return (
              <>
                <span className={classes.duplicateForm__label}>
                  {__(field.label)}
                  {field.required && (
                    <span className={classes.duplicateForm__required}>*</span>
                  )}
                </span>
                <div className={classes.duplicateForm__selectBox}>
                  <input
                    type="text"
                    className={classes.input}
                    onChange={(e) =>
                      textInputsHandler(e, field.type, field.required)
                    }
                    value={
                      currValue && typeof currValue !== "string"
                        ? currValue.value
                        : ""
                    }
                  />
                </div>
              </>
            );
          })}
    </div>
  ) : null;
};

export default DuplicateSelect;
