import { FC } from "react";

import LogoSignIcon from "web/assets/icons/logo-sign.svg";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./loading.scss";

const Loading: FC<PropsWithClasses> = ({ classes = {} }) => {
  return (
    <div className={classes.root} data-testid="loading-component">
      <LogoSignIcon
        className={`${classes.element} ${classes.elementVariant || ""}`}
      />
      <div className="visually-hidden">Ładowanie</div>
    </div>
  );
};

export default classify<PropsWithClasses>(defaultClasses)(Loading);
