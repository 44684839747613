import { useCallback, useEffect } from "react";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import { useAppContext } from "web/context/app";

import classes from "./mobileAppUpdateModal.scss";

/** @deprecated */
const DEPRECATED_WEBVIEW_USER_AGENT =
  "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_0_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.93 Safari/537.36";

const MobileAppUpdateModal = () => {
  const {
    modal: { dispatch: dispatchModal },
  } = useAppContext();

  const showModal = useCallback(() => {
    dispatchModal({
      type: ModalActionTypes.ADD_MODAL,
      modal: <Modal />,
    });
  }, [dispatchModal]);
  useEffect(() => {
    if (window.navigator.userAgent === DEPRECATED_WEBVIEW_USER_AGENT) {
      showModal();
    }
  }, []);

  return null;
};

const Modal = () => (
  <div className={classes.root}>
    <div className={classes.wrapper}>
      <p className={classes.heading}>
        Twoja aplikacja jest nie aktualna, kliknij by ją zaktualizować.
      </p>
      <div>
        {navigator.platform !== "iPhone" && (
          <a
            href="https://play.google.com/store/apps/details?id=com.mybenefit"
            className={classes.updateButton}
          >
            Google Play
          </a>
        )}
        {navigator.platform !== "Linux aarch64" && (
          <a
            href="https://apps.apple.com/in/app/mybenefit/id1601176160"
            className={classes.updateButton}
          >
            Apple Store
          </a>
        )}
      </div>
    </div>
  </div>
);

export default MobileAppUpdateModal;
