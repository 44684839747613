/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import __ from "web/Layout/Translations";

import logotype from "web/assets/logo.svg";

import logout from "web/utils/system/essentials/logout";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./ErrorSSO.scss";

const ErrorSSO: FC<PropsWithClasses> = ({ classes = {} }) => {
  const cookies = document.cookie;

  document.cookie =
    "sso_error=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

  const readCookie = (name: string, cookie: string) => {
    const nameEQ = `${name}=`;
    const ca = cookie.split(";");
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const value = readCookie("sso_error", cookies);
  const idTokenHint = readCookie("id_token_hint", cookies);

  let errorMessage;

  switch (value) {
    case "ERR_OMB_LOGGED":
      errorMessage = __("Błędne dane logowania, spróbuj ponownie.");
      break;
    case "ERR_UNKNOWN_ROLE":
      errorMessage = __(
        "Próbujesz się zalogować do aplikacji używając konta z nieznaną rola w systemie."
      );
      break;
    default:
      errorMessage = "";
  }

  if (!cookies || errorMessage === "") {
    logout("", null, "sso - no cookiws");
    return null;
  }

  if (cookies && errorMessage) {
    return (
      <div className={classes.wrapper}>
        <div className={classes.box}>
          <img className={classes.logo} src={logotype} alt="logo" />

          <p className={classes.text}>{__(errorMessage)}</p>
          <div className={classes.bottom_wrapper}>
            <button
              type="button"
              className={classes.button}
              onClick={(additionalParams) =>
                //  @ts-ignore
                logout(additionalParams, idTokenHint, "sso - cookies")
              }
            >
              {__("Wyloguj się")}
            </button>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default classify<PropsWithClasses>(defaultClasses)(ErrorSSO);
