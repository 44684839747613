export const enum RGCheckboxDependency {
  FILL_CALCULATOR = "rentable-group/calculator/fill-calculator-checkbox",
  FINANCING = "rentable-group/calculator/financing-checkbox",
  CALCULATOR = "rentable-group/calculator/enable-calculator",
}

const getCurrentCheckboxDependency = (
  fillCalculatorCheckbox?: boolean,
  financingCheckbox?: boolean
) => {
  if (fillCalculatorCheckbox) {
    return RGCheckboxDependency.FILL_CALCULATOR;
  }

  if (financingCheckbox) {
    return RGCheckboxDependency.FINANCING;
  }

  return RGCheckboxDependency.CALCULATOR;
};

export default getCurrentCheckboxDependency;
