import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import AbstractBrowserStorage from "./abstractBrowserStorage";
import NamespacedLocalStorage from "./namespacedStorage";
import redirectToBrowserSettingsError from "./redirectToBrowserSettingsError";

export default class BrowserTemporality extends AbstractBrowserStorage {
  static KEY = "FENIKS_BROWSER_TEMPORALITY";

  constructor() {
    super();
    try {
      this.storage = new NamespacedLocalStorage(
        window.sessionStorage,
        BrowserTemporality.KEY
      );
    } catch (error) {
      redirectToBrowserSettingsError();
    }
  }

  getItem(name, key) {
    try {
      const dataByName = this.storage.getItem(name);
      const dataByNameParsed = dataByName ? JSON.parse(dataByName) : null;

      if (dataByNameParsed) {
        if (key) {
          return Object.prototype.hasOwnProperty.call(dataByNameParsed, key)
            ? dataByNameParsed[key]
            : null;
        }

        return dataByNameParsed;
      }
    } catch (error) {
      newRelicErrorReport(
        error,
        "web-app/web/utils/system/storage/storage/browserTemporality.js - 32"
      );
      console.error(error);
      redirectToBrowserSettingsError();
    }

    return null;
  }

  setItem(name, key, value) {
    if (key) {
      try {
        const dataByName = this.storage.getItem(name);
        const dataByNameParsed = dataByName ? JSON.parse(dataByName) : {};
        this.storage.setItem(
          name,
          JSON.stringify({
            ...dataByNameParsed,
            [key]: value,
          })
        );
      } catch (error) {
        newRelicErrorReport(
          error,
          "web-app/web/utils/system/storage/storage/browserTemporality.js - 55"
        );
        console.error(error);
        redirectToBrowserSettingsError();
      }
    } else {
      this.storage.setItem(name, JSON.stringify(value));
    }
  }
}
