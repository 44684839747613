// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clearInputFieldWrapper-root-oeM{position:relative}.clearInputFieldWrapper-root-oeM .clearInputFieldWrapper-clearButton-YN5{position:absolute;right:1rem;top:50%;transform:translateY(-50%);background:none;border:none;width:24px;height:24px;display:flex;align-items:center;justify-content:center;padding:0;opacity:0;transition:opacity .2s ease-in-out;visibility:hidden}.clearInputFieldWrapper-root-oeM .clearInputFieldWrapper-clearButton-YN5.clearInputFieldWrapper-show-NCk{visibility:visible;opacity:1}.clearInputFieldWrapper-root-oeM .clearInputFieldWrapper-clearButton-YN5 .clearInputFieldWrapper-closeIcon-nra{width:15px;height:15px}`, "",{"version":3,"sources":["webpack://./web/Components/Common/ClearInputFieldWrapper/clearInputFieldWrapper.scss"],"names":[],"mappings":"AAIuB,iCACrB,iBAAA,CAEA,yEACE,iBAAA,CACA,UAAA,CACA,OAAA,CACA,0BAAA,CACA,eAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,SAAA,CACA,SAAA,CACA,kCAAA,CACA,iBAAA,CAEA,yGACE,kBAAA,CACA,SAAA,CAGF,+GACE,UAAA,CACA,WAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  position: relative;\n\n  .clearButton {\n    position: absolute;\n    right: 1rem;\n    top: 50%;\n    transform: translateY(-50%);\n    background: none;\n    border: none;\n    width: 24px;\n    height: 24px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0;\n    opacity: 0;\n    transition: opacity 0.2s ease-in-out;\n    visibility: hidden;\n\n    &.show {\n      visibility: visible;\n      opacity: 1;\n    }\n\n    .closeIcon {\n      width: 15px;\n      height: 15px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `clearInputFieldWrapper-root-oeM`,
	"clearButton": `clearInputFieldWrapper-clearButton-YN5`,
	"show": `clearInputFieldWrapper-show-NCk`,
	"closeIcon": `clearInputFieldWrapper-closeIcon-nra`
};
export default ___CSS_LOADER_EXPORT___;
