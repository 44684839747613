import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getAttachmentFiles from "web/utils/page/product/subscription/getAttachmentFiles";

import { ATTACHMENT_TYPES } from "web/constants/benefits";

import { ISubscriptionAttachment } from "web/types/Subscription";

export interface ISortedAttachments {
  [key: string]: {
    title: string;
    attachments: { name: string; id: string }[];
  };
}

// eslint-disable-next-line import/prefer-default-export
export const getSortedAttachments = (
  attachments: ISubscriptionAttachment[]
) => {
  const currentLanguage = getLanguageCode();
  const sortedAttachments: ISortedAttachments = {};

  Object.keys(ATTACHMENT_TYPES).forEach((type) => {
    sortedAttachments[type] = {
      title: ATTACHMENT_TYPES[type as keyof typeof ATTACHMENT_TYPES],
      attachments: attachments
        .filter((attachment) => attachment.type === type)
        .flatMap((attachment) => {
          const files = getAttachmentFiles(attachment);

          if (isArrayHasItems(files)) {
            return files.flatMap((file) => {
              return file.language === currentLanguage
                ? { name: attachment.name, id: file.id }
                : [];
            });
          }

          return [];
        }),
    };
  });

  return sortedAttachments;
};
