import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";

import type { Nullable } from "web/types/Utils";

const getProductDate = (
  date: Nullable<string>,
  time: Nullable<string>,
  validDate?: Nullable<string>
) => {
  switch (true) {
    case Boolean(date): {
      return `${date ? `${__("Data")}: ${formatDate(date)}` : ""} ${
        time ? `${__("godz.")} ${time}` : ""
      }`;
    }
    case !!validDate: {
      return `${__("Ważny do")}: ${formatDate(validDate)}`;
    }
    default:
      return null;
  }
};

export default getProductDate;
