import DOMPurify from "dompurify";
import { useFormikContext } from "formik";
import { FC, useEffect } from "react";

import Checkbox from "web/Components/Common/Form/Checkbox";

import { RentableGroupAgreement } from "web/types/Employee";

import { IRentableGroupCalculatorValues } from "../RentableGroupCalculator/rentableGroupCalculatorContainer";
import classes from "./rentableGroupCalculatorAgreements.scss";
import getAgreementFields from "./utils/getAgreementFields";
import useAgreementsFiltered from "./utils/useAgreementsFiltered";

export interface IRentableGroupCalculatorAgreementsListField {
  name: string;
  type: string;
  label: string;
  initialValue: boolean;
  rules: string[];
  dependencies: RentableGroupAgreement["dependencies"];
}

interface IRentableGroupCalculatorAgreementsProps {
  agreementsListFields: IRentableGroupCalculatorAgreementsListField[];
  initialValues?: IRentableGroupCalculatorValues["agreements"];
}

const RentableGroupCalculatorAgreements: FC<
  IRentableGroupCalculatorAgreementsProps
> = ({ agreementsListFields, initialValues }) => {
  const { values, setFieldValue } =
    useFormikContext<IRentableGroupCalculatorValues>();

  const agreementsFiltered = useAgreementsFiltered(
    values,
    agreementsListFields
  );

  useEffect(() => {
    setFieldValue(
      "agreements",
      getAgreementFields(agreementsFiltered, values.agreements, initialValues)
    );
  }, [agreementsFiltered]);

  return (
    <section className={classes.agreementsList}>
      {agreementsFiltered?.map(({ name, label }) => (
        <Checkbox key={name} name={`agreements.${name}`}>
          <div
            className={`${classes.checkboxText} reset-global-styles cke_editable`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(label),
            }}
          />
        </Checkbox>
      ))}
    </section>
  );
};
export default RentableGroupCalculatorAgreements;
