import { cloneDeep } from "lodash";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import SectionList from "web/Layout/Common/SectionList";
import ErrorBoundary from "web/Layout/ErrorBoundary";
import PointsLanguage from "web/Layout/MainHeader/Desktop/Customer/PointsLanguage";
import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";
import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import Logo from "../Common/Logo";
import { getLanguageCode } from "../Translations/LanguageWrapper";
import defaultClasses from "./footer.scss";
import getNavLinks from "./utils/getNavLinks";

const FooterDefault: FC<PropsWithClasses> = ({ classes = {} }) => {
  const { sections } = useSelector((state) => state.customerMenu);
  const { data: dataBanks } = useGetBanksQuery();
  const { total: totalPoints } = dataBanks || {};

  const { storeConfig } = useSelector((state) => state.app);

  const [footerLogoSrc, footerLogoAlt] = [
    storeConfig?.footer_logo_src,
    storeConfig?.footer_logo_alt,
  ];

  const { menuSections, rulesTitle } = useMemo(() => {
    const sectionsCloned = cloneDeep(sections);
    const rulesTitle = __("Zasady");

    return {
      menuSections: sectionsCloned,
      rulesTitle,
    };
  }, [sections]);

  return (
    <footer className={`container ${classes.root}`}>
      <div className={classes.row}>
        <div className={classes.sections}>
          <ErrorBoundary>
            {isArrayHasItems(menuSections) &&
              menuSections.map((menuSection) => {
                return (
                  <SectionList
                    key={menuSection.name}
                    className={classes.section}
                    title={menuSection.name}
                    items={menuSection.items}
                  />
                );
              })}
            <SectionList
              key={rulesTitle}
              className={classes.section}
              title={rulesTitle}
              items={getNavLinks(getLanguageCode())}
            />
          </ErrorBoundary>
        </div>
        <ErrorBoundary>
          <div className={classes.column}>
            <Logo
              className={classes.logo}
              logoSrc={footerLogoSrc}
              logoAlt={footerLogoAlt}
            />
            <PointsLanguage
              className={classes.points}
              totalPoints={totalPoints}
            />
          </div>
        </ErrorBoundary>
      </div>
    </footer>
  );
};

export default classify(defaultClasses)(FooterDefault);
