import { FC } from "react";
import { Link } from "react-router-dom";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";

import classes from "./image.scss";

interface IProductCardImageProps {
  className: string;
  name: string;
  image: string;
  onClick: () => void;
  to: {
    pathname: string;
    search: string;
    state: { categoryId: string; clickFrom?: string };
  };
}

const ProductCardImage: FC<IProductCardImageProps> = ({
  className,
  name,
  image,
  to,
  onClick = () => {},
}) => {
  return (
    <Link to={to} className={className} title={name} onClick={onClick}>
      <LazyLoadImage
        className={classes.image}
        src={image}
        alt={name}
        width={400}
        height={264}
        isProduct
      />
    </Link>
  );
};

export default ProductCardImage;
