import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";

import __ from "web/Layout/Translations";

import HideAnimation from "web/Components/Common/HideAnimation";

import RentableGroupCalculator from "./RentableGroupCalculator";
import RentableGroupCalculatorInstruction from "./RentableGroupCalculatorInstruction";
import RentableGroupCalculatorResult from "./RentableGroupCalculatorResult";
import RentableGroupCalculatorSelectedGroup from "./RentableGroupCalculatorSelectedGroup";
import RentableGroupCalculatorStatements from "./RentableGroupCalculatorStatements";
import rentableGroupCalculatorCopy from "./const/rentableGroupCalculatorCopy";
import shouldShowCalculator from "./utils/shouldShowCalculator";
import useCalculateRentableGroup from "./utils/useCalculateRentableGroup";
import useRentableGroupCalculatorStatements from "./utils/useRentableGroupsCalculatorStatements";

const {
  instructionHeader,
  instruction,
  peopleCount: peopleCountCopy,
  averageIncome: averageIncomeCopy,
  incomeGroup: incomeGroupCopy,
  incomeGroupDeclared: incomeGroupDeclaredCopy,
  noIncomeGroupAvailable,
} = rentableGroupCalculatorCopy;

export interface IRentableGroupCalculatorValues {
  income: {
    me: number;
    people: { role: string; income: number }[];
  };
  "fill-calculator-checkbox"?: boolean;
  "financing-checkbox"?: boolean;
  incomeGroupId?: string;
  agreements?: Record<string, boolean>;
}

export type Statements = ("fill-calculator-checkbox" | "financing-checkbox")[];

const RentableGroupCalculatorContainer = () => {
  const { values, setFieldValue } =
    useFormikContext<IRentableGroupCalculatorValues>();

  const statements = useRentableGroupCalculatorStatements();

  const { peopleCount, averageIncome, incomeGroup } =
    useCalculateRentableGroup(values);

  useEffect(() => {
    setFieldValue("incomeGroupId", incomeGroup?.id);
  }, [incomeGroup?.id]);

  const showCalculator = useMemo(
    () => shouldShowCalculator(values, statements),
    [values, statements]
  );

  const showSelectedGroup = useMemo(
    () =>
      incomeGroup === null ||
      shouldShowCalculator(values, ["financing-checkbox"]),
    [values, incomeGroup]
  );

  const incomeGroupCopyDisplayed = useMemo(() => {
    const incomeGroupCopyToShow = showCalculator
      ? incomeGroupCopy
      : incomeGroupDeclaredCopy;
    return incomeGroup?.id ? incomeGroupCopyToShow : noIncomeGroupAvailable;
  }, [showCalculator, incomeGroup?.id]);

  return (
    <>
      <HideAnimation isOpen={showCalculator}>
        <RentableGroupCalculatorInstruction
          header={__(instructionHeader)}
          paragraph={__(instruction)}
        />
      </HideAnimation>

      <RentableGroupCalculatorStatements statements={statements} />

      <HideAnimation isOpen={showCalculator}>
        <RentableGroupCalculator />
        <RentableGroupCalculatorResult
          peopleCount={peopleCount}
          peopleCountCopy={__(peopleCountCopy)}
          averageIncome={averageIncome}
          averageIncomeCopy={__(averageIncomeCopy)}
        />
      </HideAnimation>

      <HideAnimation isOpen={showSelectedGroup}>
        <RentableGroupCalculatorSelectedGroup
          incomeGroup={incomeGroup?.frontendName}
          incomeGroupCopy={__(incomeGroupCopyDisplayed)}
        />
      </HideAnimation>
    </>
  );
};
export default RentableGroupCalculatorContainer;
