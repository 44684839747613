export const eventDate = {
  attribute_code: "event_date",
  attribute_label: "Data",
  input_type: "daterange",
  labelFrom: "Data od",
  labelTo: "Data do",
};

export const location = {
  attribute_code: "location",
  attribute_label: "Lokalizacja",
  input_type: "location",
};

export const geolocation = {
  attribute_code: "geolocation_data",
  attribute_options: [
    {
      label: "+10km",
      value: "10km",
    },
    {
      label: "+20km",
      value: "20km",
    },
    {
      label: "+50km",
      value: "50km",
    },
    {
      label: "+75km",
      value: "75km",
    },
    {
      label: "+100km",
      value: "100km",
    },
  ],
};

export const gtmFiltersKeys = {
  final_price_from: "dimension7",
  final_price_to: "dimension8",
  location_location_parent_location_parent: "dimension9",
  location_province: "dimension9",
  availability_filter: "dimension10",
  product_label: "dimension11",
};
