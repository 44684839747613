import { useMemo } from "react";

import { useGetEmployeeConfigurationQuery } from "web/features/employee/employeeApiSlice";

import { Statements } from "../rentableGroupCalculatorContainer";

const useRentableGroupCalculatorStatements = () => {
  const { data: employeeConfig } = useGetEmployeeConfigurationQuery();
  const {
    rentableGroupCalculatorFillCheckbox,
    rentableGroupCalculatorFinancingCheckbox,
  } = employeeConfig || {};

  return useMemo(
    () =>
      [
        rentableGroupCalculatorFillCheckbox && "fill-calculator-checkbox",
        rentableGroupCalculatorFinancingCheckbox && "financing-checkbox",
      ].filter(Boolean) as Statements,
    [
      rentableGroupCalculatorFillCheckbox,
      rentableGroupCalculatorFinancingCheckbox,
    ]
  );
};

export default useRentableGroupCalculatorStatements;
