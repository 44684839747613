import { useLayoutEffect, useState } from "react";

const useIsPrint = () => {
  const [isPrint, setIsPrint] = useState(false);

  const print = () => {
    setIsPrint(true);
  };

  useLayoutEffect(() => {
    if (isPrint) {
      window.print();
      setIsPrint(false);
    }
  }, [isPrint]);

  return { print, isPrint };
};

export default useIsPrint;
