import { ATTACHMENT_SCOPE } from "web/constants/benefits";

import { ISubscriptionAttachment } from "web/types/Subscription";

export default (attachment: ISubscriptionAttachment) => {
  switch (attachment["@type"]) {
    case ATTACHMENT_SCOPE.GROUP:
      return attachment?.benefitGroupAttachmentFiles;
    case ATTACHMENT_SCOPE.SUBGROUP:
      return attachment?.benefitSubgroupAttachmentFiles;
    default:
      return attachment?.attachmentFiles;
  }
};
