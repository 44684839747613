import { FC, useState } from "react";

import SubscriptionReceiver from "web/Layout/SubscriptionReceivers/SubscriptionReceiver";
import __ from "web/Layout/Translations";

import {
  FormView,
  HandleToggleReceiversModalFn,
  IDuplicateCardData,
  ISubscriptionReceiver,
  SubscriptionCheckoutSteps,
  SubscriptionFormReceiver,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import styles from "./subscriptionReceivers.scss";

interface ISubscriptionReceiversProps {
  receivers: ISubscriptionReceiver[];
  wayChooseOthersInActiveForm: SubscriptionFormReceiver;
  subscriptionId: string;
  viewType: FormView;
  configuratorStep?: SubscriptionCheckoutSteps;
  enableOrderDuplicateCard?: boolean;
  duplicateCardData?: Nullable<IDuplicateCardData>;
  duplicateTrigger?: boolean;
  handleToggleReceiversModal?: HandleToggleReceiversModalFn;
  updateReceiversDataFunc?: (newReceiverData: ISubscriptionReceiver) => void;
}

const SubscriptionReceivers: FC<ISubscriptionReceiversProps> = ({
  receivers,
  wayChooseOthersInActiveForm,
  subscriptionId,
  viewType = FormView.CONFIGURATOR,
  configuratorStep = SubscriptionCheckoutSteps.INFORMATIONS,
  duplicateCardData,
  duplicateTrigger = false,
  enableOrderDuplicateCard = false,
  handleToggleReceiversModal,
  updateReceiversDataFunc,
}) => {
  const [openDuplicateForm, setOpenDuplicateForm] =
    useState<Nullable<string>>(null);

  const employeeReceiver = receivers.find(
    (receiver) => receiver.type === SubscriptionFormReceiver.EMPLOYEE
  );
  const otherReceivers = receivers.filter(
    (receiver) => receiver.type === SubscriptionFormReceiver.OTHERS
  );
  // Check for unsubmitted forms - only on configurator view; on My Subscriptions view all forms are submitted by default
  const unsubmittedFormsAvailable =
    viewType === FormView.CONFIGURATOR &&
    configuratorStep === SubscriptionCheckoutSteps.INFORMATIONS &&
    !!otherReceivers.find(({ form }) => !form?.submitted);
  const formsAwaitingUpdateAvailable = !!otherReceivers.find(
    ({ form }) => form?.awaitsUpdate
  );

  return (
    <section className={styles.root}>
      {employeeReceiver && (
        <>
          <h4>{__("Twoje dane")}</h4>
          <SubscriptionReceiver
            data={employeeReceiver}
            subscriptionId={subscriptionId}
            viewType={viewType}
            configuratorStep={configuratorStep}
            // DUPLICATE PROPS
            enableOrderDuplicateCard={enableOrderDuplicateCard}
            duplicateCardData={duplicateCardData}
            updateReceiversDataFunc={updateReceiversDataFunc}
            duplicateTrigger={duplicateTrigger}
            handleToggleReceiversModal={handleToggleReceiversModal}
            setOpenDuplicateForm={setOpenDuplicateForm}
            openDuplicateForm={openDuplicateForm}
          />
        </>
      )}
      {!!otherReceivers.length && (
        <div className={styles.receivers}>
          <h4 className={styles.heading}>
            {__("Dane osób, dla których wybierasz abonament")}
          </h4>
          {(unsubmittedFormsAvailable || formsAwaitingUpdateAvailable) && (
            <p>
              {wayChooseOthersInActiveForm === SubscriptionFormReceiver.OTHERS
                ? __("Na podany e-mail zostanie wysłany formularz.")
                : __("Uzupełnij dane osób dodatkowych.")}
            </p>
          )}
          <div className={styles.receivers__items}>
            {otherReceivers.map((receiver, index) => (
              <SubscriptionReceiver
                key={receiver.id}
                data={receiver}
                index={employeeReceiver ? index + 1 : index}
                subscriptionId={subscriptionId}
                wayChooseOthersInActiveForm={wayChooseOthersInActiveForm}
                viewType={viewType}
                configuratorStep={configuratorStep}
                // DUPLICATE PROPS
                enableOrderDuplicateCard={enableOrderDuplicateCard}
                duplicateCardData={duplicateCardData}
                updateReceiversDataFunc={updateReceiversDataFunc}
                duplicateTrigger={duplicateTrigger}
                handleToggleReceiversModal={handleToggleReceiversModal}
                setOpenDuplicateForm={setOpenDuplicateForm}
                openDuplicateForm={openDuplicateForm}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default SubscriptionReceivers;
