/**
 * Checks if the given subscription item IDs are equal to item ids.
 * @param {string | number[]} ids - item_ids from msProducts.
 * @param {ISubscriptionItemMs[]} subscriptionItems - sub_items from msProducts,
 */
import { ISubscriptionItemMs } from "web/types/Subscription";

const areSubscriptionIdsEqual = (
  ids: (string | number)[],
  subscriptionItems: ISubscriptionItemMs[]
) => {
  if (ids.length !== subscriptionItems.length) {
    return false;
  }

  return ids.every((id) =>
    subscriptionItems.some((item) => String(id) === String(item.id))
  );
};

export default areSubscriptionIdsEqual;
