/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { removePromoLink, setPromoItems } from "web/features/gtm/gtm";
import { IGtmEcommerceImpression } from "web/types/Gtm";
import { DomesticTourismProductInList, ProductInList } from "web/types/Product";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getListingPromotion from "web/utils/system/GTM/getListingPromotion";
import useGetProductOptions from "../useGetProductOptions";

const useGetProductsGtmsOptions = (data: (ProductInList | DomesticTourismProductInList)[], gtms: any, location: any) => {
    const dispatch = useDispatch();
    const { promoLinks, promoItems } = gtms;
    const { pathname } = location;

    const productsOptionsData = useMemo(() => {
      return isArrayHasItems(data)
          ? data.map((itemData) => {
              const productObj = {
                productId: itemData.id,
                productCategoryId: itemData?.main_category_id,
                productAttributesId: [{}],
                productCategoriesIds: itemData?.category_ids,
              };
              return productObj;
          })
          : [];
    }, [data]);

    useEffect(() => {
      return () => {
        dispatch(removePromoLink(pathname));
      }
    }, [pathname]);

    const listingPromotion = useMemo(() => getListingPromotion(promoLinks), [promoLinks]);
    useEffect(() => {
      const { creative_slot, promotion_name } = listingPromotion;

      if (!promotion_name) {
        return;
      }
      const promotionsPerProduct = {};

      data?.forEach(({ id }) => {
        if (!(id in promoItems) && creative_slot && promotion_name) {
          // @ts-ignore
          promotionsPerProduct[id] = { creative_slot, promotion_name };
        }
      });
      dispatch(setPromoItems(promotionsPerProduct));

    }, [data, listingPromotion, promoItems]);
      
    const [finalProductOptions, isReady] =
      useGetProductOptions(productsOptionsData);

    const dataLayerTemp = window.dataLayer ? [...window.dataLayer].reverse() : [];

    const lastProductImpressionEvent =
      dataLayerTemp.find((element) => element.event === "productImpression") as IGtmEcommerceImpression ||
      null;

    const lastVirtualPageViewEvent =
      dataLayerTemp.find((element) => element.event === "virtualPageView") || null;
  
    return {
      finalProductOptions,
      isReady,
      lastProductImpressionEvent,
      lastVirtualPageViewEvent,
      listingPromotion
    };
};

export default useGetProductsGtmsOptions;