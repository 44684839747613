import { FC } from "react";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";

import useDataCachedProductsInList from "web/features/useDataCached/useDataCachedProductsInList";

import Products from "./products";

interface ISearchAutocompleteProductsContainer {
  ids: number[];
  closeAction: () => void;
  searchPhrase: string;
}

const SearchAutocompleteProductsContainer: FC<
  ISearchAutocompleteProductsContainer
> = ({ ids = [], closeAction, searchPhrase = "" }) => {
  const { loading, error, data } = useDataCachedProductsInList({
    ids,
  });
  switch (true) {
    case !!loading: {
      return <Loading />;
    }
    case !!error: {
      return <ErrorComponent />;
    }
    default: {
      return (
        <Products
          products={data!}
          closeAction={closeAction}
          searchPhrase={searchPhrase}
        />
      );
    }
  }
};

export default SearchAutocompleteProductsContainer;
