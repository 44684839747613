import { FC } from "react";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { ProductInList } from "web/types/Product";

import { useSelector } from "react-redux";
import __ from "web/Layout/Translations";
import { useLocation } from "react-router";
import getSearchParameter from "web/utils/system/url/getSearchParameter";
import ProductsGtms from "web/Layout/Listing/Common/Products/productsGtms";
import classes from "./products.scss";
import Product from "./Product";

interface ISearchAutocompleteProducts {
  products: ProductInList[];
  closeAction: () => void;
  searchPhrase: string;
}

const SearchAutocompleteProducts: FC<ISearchAutocompleteProducts> = ({
  products = [],
  closeAction,
  searchPhrase = "",
}) => {
  const { search } = useLocation();
  const {
    grid_per_page: pageSizeDefault,
    base_currency_code: currency,
  } = useSelector((state) => state.app.storeConfig);
  const searchFromUrl = getSearchParameter({ name: searchPhrase, search });

  const gtmOptions = {
    cgroup1: __("Sugerowane wyniki"),
    cgroup2: __(`${searchFromUrl?.toLowerCase()}`),
  };

  return isArrayHasItems(products) ? (
    <span>
      {products.map((product, index) => {
        const productPosition = index + 1;

        return (
          <Product
            className={classes.item}
            key={product.id}
            closeAction={closeAction}
            product={product}
            searchPhrase={searchPhrase}
            productPosition={productPosition}
          />
        );
      })}
      <ProductsGtms
        data={products}
        previousValue={0}
        currency={currency}
        categoriesPath={gtmOptions.cgroup2}
        cgGroup1Path={gtmOptions.cgroup1}
        pageSizeDefault={pageSizeDefault}
        withVirtualPageView={false}
        verifyPrevIds={false}
        verifyCategoryPath={false}
        searchPhrase={searchPhrase}
      />
    </span>
  ) : null;
};

export default SearchAutocompleteProducts;
