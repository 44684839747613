import { Button } from "@benefit-systems/common-components";
import { useCallback } from "react";

import __ from "web/Layout/Translations";

import CodeModal from "web/Components/Common/CodeItem/CodeModal";

import { CodeItemStatusCode } from "web/hooks/useCodeItemStatus/useCodeItemStatus";
import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import orderCodeStatus from "web/constants/orderCodeStatus";

import { useAppContext } from "web/context/app";

import codeLabelMap from "../utils/codeLabelMap";
import classes from "./showCode.scss";

const buttonClass = { root: classes.button };

interface IShowCodeProps {
  name?: string;
  isCanceled?: boolean;
  orderStatusCode?: string;
  itemId: number;
  sku: string;
  statusCode: CodeItemStatusCode;
  isShowCodeDisabled: boolean;
}

const ShowCode = ({
  name = "",
  isCanceled = false,
  itemId,
  orderStatusCode = "",
  sku = "",
  statusCode,
  isShowCodeDisabled,
}: IShowCodeProps) => {
  const orderPaid = orderStatusCode === orderCodeStatus.claimSuccess;

  const codeLabel = codeLabelMap[statusCode];

  const { modal } = useAppContext();
  const { dispatch } = modal;

  const showModal = useCallback(() => {
    dispatch({
      type: ModalActionTypes.ADD_MODAL,
      modal: isCanceled ? null : (
        <CodeModal
          productName={name}
          itemId={itemId}
          orderPaid={orderPaid}
          sku={sku}
        />
      ),
    });
  }, [dispatch, CodeModal, name]);

  return (
    <div className={classes.root}>
      <Button
        onClick={showModal}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        classes={buttonClass}
        dataT1="show_code_button"
        disabled={isShowCodeDisabled}
      >
        <span className={classes.label} data-t1="show_code_label">
          {__(codeLabel)}
        </span>
      </Button>
    </div>
  );
};

export default ShowCode;
