import { useCallback, useEffect, useMemo, useState } from "react";

import debounced from "web/utils/system/DOM/time/debounced";

const TIMEOUT = 50;
const SCROLL_HEIGHT_DIMENSION = 250;
const useScroll = () => {
  const [isActive, toggleStatus] = useState(false);
  const toggleStatusHandler = useCallback(() => {
    const windowScrollHeight =
      (window && window.pageYOffset) ||
      (document && document.documentElement.scrollTop);
    const statusNew = windowScrollHeight > SCROLL_HEIGHT_DIMENSION;
    toggleStatus(statusNew);
  }, [toggleStatus]);
  const scrollHandlerDebounced = useMemo(
    () => debounced(toggleStatusHandler, TIMEOUT),
    [toggleStatusHandler]
  );

  useEffect(() => {
    window.addEventListener("scroll", scrollHandlerDebounced);
    return () =>
      window && window.removeEventListener("scroll", scrollHandlerDebounced);
  }, [scrollHandlerDebounced]);

  return isActive;
};

export default useScroll;
