import { useContext, useEffect } from "react";

import { InternetConnectionCtx } from "web/context/internetConnection";

const useInternetConnection = () => {
  const ctx = useContext(InternetConnectionCtx);

  useEffect(() => {
    if (!ctx)
      throw new Error(
        "useInternetConnection used outside of internet connection context!"
      );
  }, []);

  return ctx;
};

export default useInternetConnection;
