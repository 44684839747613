import { FC } from "react";
import { Helmet } from "react-helmet";

interface IHelmetHeader {
  title?: string;
  description?: string;
  keywords?: string;
}

const HelmetHeader: FC<IHelmetHeader> = ({
  title = "",
  description = "",
  keywords = "",
}) => {
  return title || description || keywords ? (
    <Helmet>
      {title ? <title>{title}</title> : null}
      {description ? <meta name="description" content={description} /> : null}
      {keywords ? <meta name="keywords" content={keywords} /> : null}
    </Helmet>
  ) : null;
};

export default HelmetHeader;
