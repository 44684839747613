import { motion } from "framer-motion";
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from "react";

import __ from "web/Layout/Translations";

import ArrowUpIcon from "web/assets/icons/arrow-up.svg";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./accordion.scss";

const arrowVariants = {
  open: {
    transform: "scaleY(1)",
  },
  close: {
    transform: "scaleY(-1)",
  },
};

export type AccordionVariant = "primary" | "secondary" | "tertiary";

interface IAccordionProps {
  label: string | ReactNode;
  expanded?: boolean;
  onChange?: (isExpanded: boolean) => void;
  arrow?: boolean;
  foldedHeight?: string;
  expandedContentTop?: React.ReactNode;
  seeMoreButton?: boolean;
  requireAccordion?: boolean;
  dataT1?: string;
  closeHeight?: number;
  variant?: AccordionVariant;
  rootDataT1?: string;
  rootDataT2?: string;
}

const Accordion: FC<PropsWithChildren<PropsWithClasses<IAccordionProps>>> = ({
  label = "",
  expanded = false,
  children = "",
  onChange = () => {},
  arrow = false,
  classes = {},
  foldedHeight = "",
  expandedContentTop = null,
  seeMoreButton = false,
  requireAccordion = true,
  dataT1 = "accordion_button",
  closeHeight = 0,
  variant = "primary",
  rootDataT1 = null,
  rootDataT2 = null,
}) => {
  const initialExpanded = requireAccordion ? expanded : true;
  const [isExpanded, setExpanded] = useState(initialExpanded);

  const classesByVariant = {
    primary: classes?.primary,
    secondary: classes?.secondary,
    tertiary: classes?.tertiary,
  };

  const variants = {
    open: {
      opacity: 1,
      height: "auto",
      overflow: "visible",
    },
    close: {
      opacity: closeHeight ? 1 : 0,
      height: closeHeight ? `${closeHeight}px` : 0,
      overflow: "hidden",
    },
  };

  const transition = { duration: 0.2 };
  const variantsVisible = {
    open: {
      height: "auto",
    },
    close: {
      height: foldedHeight,
    },
  };

  useEffect(() => {
    onChange(isExpanded);
  }, [isExpanded, onChange]);

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  const animatableStylesName = isExpanded ? "open" : "close";

  const seeMoreClickHandler = () => setExpanded(!isExpanded);
  const headerClickHandler = () => {
    if (requireAccordion) setExpanded(!isExpanded);
  };

  const getArrow = (
    <motion.span
      initial={animatableStylesName}
      animate={animatableStylesName}
      transition={transition}
      variants={arrowVariants}
    >
      <ArrowUpIcon className={classes.arrow} />
    </motion.span>
  );

  return (
    <div
      className={`${classes.root ? classes.root : ""} ${
        classesByVariant[variant] ? classesByVariant[variant] : ""
      }`}
      data-t1={rootDataT1}
      data-t2={rootDataT2}
    >
      <button
        type="button"
        className={classes.button}
        onClick={headerClickHandler}
        data-t1={dataT1}
      >
        {label && <span className={classes.label}>{label}</span>}
        {arrow && requireAccordion ? getArrow : null}
      </button>
      <div className={classes.wrapper}>
        {expandedContentTop ? (
          <div className={classes.contentTop}>{expandedContentTop}</div>
        ) : null}

        <motion.div
          className={classes.expandable}
          initial={animatableStylesName}
          animate={animatableStylesName}
          transition={transition}
          variants={foldedHeight ? variantsVisible : variants}
        >
          <div className={classes.content}>{children}</div>
        </motion.div>
        {seeMoreButton && requireAccordion ? (
          <button
            type="button"
            className={classes.additionalButton}
            onClick={seeMoreClickHandler}
            data-t1={`${dataT1}_2`}
          >
            {isExpanded ? __("Zobacz mniej") : __("Zobacz więcej")}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default classify<PropsWithChildren<PropsWithClasses<IAccordionProps>>>(
  defaultClasses
)(Accordion);
