import { FC } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import { IProductCardProps } from "../container";
import Product from "./productCard";

export type ProductCardProps = Omit<IProductCardProps, "productType">;

const ProductCardContainer: FC<ProductCardProps> = ({
  product,
  isWishlistPage,
  categoryId,
  params,
  clickFrom,
  positionNumber,
  listingPosition,
  listingAmount,
  listingCategory = "",
  variant = "default",
}) => {
  return (
    <ErrorBoundary>
      <Product
        product={product}
        isWishlistPage={isWishlistPage}
        categoryId={categoryId}
        clickFrom={clickFrom}
        params={params}
        positionNumber={positionNumber}
        listingPosition={listingPosition}
        listingAmount={listingAmount}
        listingCategory={listingCategory}
        variant={variant} // TODO: pass disabled if window is closed
      />
    </ErrorBoundary>
  );
};

export default ProductCardContainer;
