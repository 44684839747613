/* eslint-disable @typescript-eslint/ban-ts-comment */
import { node } from "prop-types";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Loading from "web/Layout/Common/Loading/loading";
import { loadInterfaceTranslation } from "web/Layout/Translations";
import {
  getLanguageKey,
  isDefaultLanguage,
  setLanguage,
} from "web/Layout/Translations/LanguageWrapper";

import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";

const LanguageWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const { tokenTTL } = useSelector((state) => state.app);

  const { data: customerData } = useGetCustomerDetailsQuery(undefined, {
    skip: !tokenTTL,
  });
  const { language: userLanguage } = customerData || {};

  useEffect(() => {
    const language = getLanguageKey();
    if (userLanguage && userLanguage !== language) {
      setLanguage(userLanguage);
      window.location.reload();
    }
  }, [userLanguage]);

  useEffect(() => {
    if (!translationsLoaded) {
      if (!isDefaultLanguage) {
        const setAsLoaded = () => {
          setTranslationsLoaded(true);
        };
        loadInterfaceTranslation(setAsLoaded);
      } else {
        // @ts-ignore
        window.translations = {};
      }
    }
  }, [translationsLoaded]);

  if (!isDefaultLanguage && !translationsLoaded) {
    return <Loading />;
  }

  return <>{children}</> || null;
};

LanguageWrapper.propTypes = {
  children: node.isRequired,
};

export default LanguageWrapper;
