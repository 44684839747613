// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tile-root-wpD{display:block}.tile-image-YAO{border-radius:25px;width:100%}`, "",{"version":3,"sources":["webpack://./web/Layout/MainHeader/Desktop/MainMenu/Tile/tile.scss"],"names":[],"mappings":"AAIuB,eACnB,aAAA,CAGJ,gBACI,kBAAA,CACA,UAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n    display: block;\n}\n\n.image {\n    border-radius: 25px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `tile-root-wpD`,
	"image": `tile-image-YAO`
};
export default ___CSS_LOADER_EXPORT___;
