// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorBoundary-smallErrorMessage-Znf{font-size:10px;width:80px}`, "",{"version":3,"sources":["webpack://./web/Layout/ErrorBoundary/ErrorBoundary.scss"],"names":[],"mappings":"AAIuB,qCACrB,cAAA,CACA,UAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .smallErrorMessage {\n  font-size: 10px;\n  width: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallErrorMessage": `ErrorBoundary-smallErrorMessage-Znf`
};
export default ___CSS_LOADER_EXPORT___;
