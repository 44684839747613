import { FC } from "react";
import { Link } from "react-router-dom";

import PriceCommon from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";

import { zlotyPerMonth } from "web/constants/benefits";

import classes from "./price.scss";

interface IProductCardFooterPriceProps {
  isConfigurable?: boolean;
  price?: number;
  finalPrice: number;
  dataT1?: string;
  to: {
    pathname: string;
    search: string;
    state: { categoryId: string; clickFrom?: string };
  };
  isSubscription?: boolean;
  onClick?: () => void;
}

const ProductCardFooterPrice: FC<IProductCardFooterPriceProps> = ({
  price,
  finalPrice,
  isConfigurable = false,
  isSubscription = false,
  to,
  dataT1 = "price",
  onClick = () => {},
}) => {
  const textFrom = isConfigurable || isSubscription ? `${__("od")} ` : "";
  const isDiscount = finalPrice !== price;
  const currency = isSubscription ? zlotyPerMonth : "zł";
  const classNameFinalBox =
    isDiscount && !isSubscription ? classes.finalBoxDiscount : classes.finalBox;

  return (
    <Link className={classes.root} to={to} onClick={onClick}>
      {isDiscount && !isSubscription ? (
        <div className={classes.priceBox} data-t1="price_common">
          <PriceCommon
            className={classes.price}
            value={price}
            currency={__(currency)}
            dataT1={`price_common_${dataT1}`}
          />
        </div>
      ) : null}
      <div className={classNameFinalBox} data-t1="price_final">
        {textFrom}
        <PriceCommon
          className={classes.final}
          value={finalPrice}
          currency={__(currency)}
          dataT1={`price_final_${dataT1}`}
        />
      </div>
    </Link>
  );
};

export default ProductCardFooterPrice;
