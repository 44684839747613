import { FC } from "react";
import { Link } from "react-router-dom";

import { SkeletonBreadcrumbs } from "web/Layout/Common/SkeletonComponents";

import HomeIcon from "web/assets/icons/home.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import Dropdown from "./Dropdown";
import classes from "./breadcrumbs.scss";

interface IBreadcrumbsChild {
  label: string;
  url: string;
}

export interface IBreadcrumb {
  label: string;
  url?: string;
  isInactive?: boolean;
  children?: IBreadcrumbsChild[];
}

export interface IBreadcrumbsProps {
  className?: string;
  breadcrumbs: IBreadcrumb[];
  noHome?: boolean;
  loading?: boolean;
}

const Breadcrumbs: FC<IBreadcrumbsProps> = ({
  className,
  breadcrumbs = [],
  noHome = false,
  loading = false,
}) => {
  if (loading) {
    return <SkeletonBreadcrumbs />;
  }

  return isArrayHasItems(breadcrumbs) ? (
    <nav className={className || classes.root}>
      <ul className={classes.list} data-t1="breadcrumbs_list">
        {breadcrumbs.map((item, index, array) => {
          switch (true) {
            case !index && typeof item.url === "string" && !noHome: {
              return (
                <li
                  className={classes.item}
                  key={item.url}
                  data-t2={item.label}
                  data-t1="breadcrumbs_item_li"
                >
                  <Link
                    to={item.url as string}
                    className={classes.link}
                    title={item.label}
                    data-t1="breadcrumbs_item_link"
                    data-t2={item.label}
                  >
                    <HomeIcon
                      className={classes.icon}
                      width={22}
                      data-t1="breadcrumbs_item_icon"
                    />
                    <span data-t2={item.label}>{item.label}</span>
                  </Link>
                </li>
              );
            }
            case index === array.length - 1 || item.isInactive || !item.url: {
              return (
                <li
                  className={classes.item}
                  key={item.label}
                  data-t2={`${item.label}`}
                  data-t1="breadcrumbs_item_li"
                >
                  <span
                    className={classes.text}
                    data-t1="breadcrumbs_item_text"
                    data-t2={item.label}
                  >
                    {item.label}
                  </span>
                </li>
              );
            }
            case !!isArrayHasItems(item.children) && item.url === "string": {
              return (
                <li
                  className={classes.item}
                  key={item.url}
                  data-t2={item.label}
                  data-t1="breadcrumbs_item_dropdown"
                >
                  <Dropdown
                    label={item.label}
                    url={item.url as string}
                    items={item.children as IBreadcrumbsChild[]}
                  />
                </li>
              );
            }
            default: {
              return (
                <li
                  className={classes.item}
                  key={item.url}
                  data-t2={item.label}
                  data-t1="breadcrumbs_item_li"
                >
                  <Link
                    to={item.url as string}
                    className={classes.link}
                    title={item.label}
                    data-t1="breadcrumbs_item_link"
                    data-t2={item.label}
                  >
                    {item.label}
                  </Link>
                </li>
              );
            }
          }
        })}
      </ul>
    </nav>
  ) : null;
};

export default Breadcrumbs;
