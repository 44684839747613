import { FC } from "react";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";

import useDataCachedProductsInList from "web/features/useDataCached/useDataCachedProductsInList";

import Products from "./products";

interface ISearchAutocompleteProductsContainerMobileProps {
  ids: number[];
  closeAction: () => void;
}

const SearchAutocompleteProductsContainerMobile: FC<
  ISearchAutocompleteProductsContainerMobileProps
> = ({ ids = [], closeAction }) => {
  const { loading, error, data } = useDataCachedProductsInList({
    ids,
  });

  switch (true) {
    case !!loading: {
      return <Loading />;
    }
    case !!error: {
      return <ErrorComponent />;
    }
    default: {
      return <Products products={data!} closeAction={closeAction} />;
    }
  }
};

export default SearchAutocompleteProductsContainerMobile;
