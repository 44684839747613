import type { Nullable } from "web/types/Utils";

const debounced = <T extends unknown[] = unknown[]>(
  fn: (...args: T) => unknown,
  delay: number
) => {
  let id: Nullable<NodeJS.Timeout>;
  return (...args: T) => {
    if (id) {
      clearTimeout(id);
    }
    id = setTimeout(() => {
      fn(...args);
      id = null;
    }, delay);
  };
};

export default debounced;
