import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { useAppContext } from "web/context/app";
import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";
import useIsNativeApp from "web/hooks/useIsNativeApp/useIsNativeApp";

import NavigationPanel from "./navigationPanel";
import Modal from "./Modal";
import classes from "./container.scss";

const hiddenPanelMobilePathNames = [
  "/checkout/",
  "/checkout_failed/",
  "/company-events",
  "/checkout-external/prepaid-card",
];

export const isPWA = (): boolean => {
  return window.matchMedia("(display-mode: standalone)").matches || document.referrer.startsWith("android-app://");
};

const isMobileAppSupported = (): boolean => {
  const mobileRegex = /Android|iPhone|iPad|iPod|Huawei/i;
  return mobileRegex.test(navigator.userAgent);
}

const NavigationPanelContainer = () => {
  const { pathname } = useLocation();
  const { isMobile, modal } = useAppContext();
  const { dispatch } = modal;
  const isNativeApp = useIsNativeApp();

  const shouldShowNavigationPanel = useMemo(
    () => isMobile && !hiddenPanelMobilePathNames.some((path) => pathname.includes(path)),
    [isMobile, pathname]
  );

  useEffect(() => {
    
    if ((isPWA() || isMobileAppSupported()) && !isNativeApp ) {
      dispatch({
        type: ModalActionTypes.ADD_MODAL,
        modal: <Modal />,
        classes: { root: classes.modal },
      });
    }
  }, [isNativeApp, isMobile, dispatch]);

  return shouldShowNavigationPanel ? <NavigationPanel /> : null;
};

export default NavigationPanelContainer;