import { Button } from "@benefit-systems/common-components";
import { useCallback } from "react";

import Sort from "web/Pages/Customer/Content/Orders/Mobile/Sort";
import FilterButton from "web/Pages/Product/Shared/RoomOffers/Shared/Toolbar/FilterButton";

import __ from "web/Layout/Translations";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import { sortCategoryAnixeOptions } from "web/constants/toolbar";

import { useAppContext } from "web/context/app";

import classes from "./filters.scss";

const Filters = () => {
  const { modal } = useAppContext();
  const { dispatch } = modal;

  const closeModal = useCallback(() => {
    dispatch({ type: ModalActionTypes.RESET });
  }, [dispatch]);

  return (
    <div className={classes.rootAnixe}>
      <strong className={classes.header}>
        <h3 className={classes.title_main} data-t1="filters_main_title">
          {__("Sortuj")}
        </h3>
        <Sort options={sortCategoryAnixeOptions} />
      </strong>
      <div className={classes.actionsAnixe}>
        <div className={classes.anixeButtonWrapper}>
          {__("Opcje filtrowania:")}
          <FilterButton />
        </div>
        <div className={classes.actionsButtonsWrapper}>
          <Button
            type="reset"
            variant="secondary"
            transparent
            onClick={closeModal}
            dataT1="filters_clear"
          >
            {__("Anuluj")}
          </Button>
          <Button type="button" onClick={closeModal} dataT1="filters_submit">
            {__("Zatwierdź")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Filters;
