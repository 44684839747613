export default (options, customOptionTitles = []) => {
  return options
    ? {
        custom_options: options.map((option) => {
          return {
            fields: Object.entries(option)
              .filter(([key]) => key !== "person")
              .map(([key, value]) => {
                const title = customOptionTitles?.find(
                  ({ id }) => key === id
                )?.title;
                return {
                  option_id: key,
                  option_value: value,
                  title,
                };
              }),
            person: option.person,
          };
        }),
      }
    : {};
};
