import { FC, memo } from "react";

import classes from "./rentableGroupCalculatorInstruction.scss";

interface IRentableGroupCalculatorInstructionsProps {
  header: string;
  paragraph: string;
}

const RentableGroupCalculatorInstructions: FC<IRentableGroupCalculatorInstructionsProps> =
  memo(({ header, paragraph }) => {
    return (
      <section className={classes.root}>
        <h5 className={classes.header}>{header}</h5>
        <p className={classes.paragraph}>{paragraph}</p>
      </section>
    );
  });
export default RentableGroupCalculatorInstructions;
