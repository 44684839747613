/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import Checkbox from "web/Components/Common/Filters/Shared/Fieldset/Multiselect/Checkbox";

import { geolocation } from "web/constants/filters";

import Geolocation from "../../../Desktop/Geolocation";
import { LocationOptions } from "../fieldset";
import classes from "./location.scss";

interface ILocationProps {
  options: LocationOptions[];
}

const Location: FC<ILocationProps> = ({ options }) => {
  return (
    <div className={classes.root}>
      {options?.map((option) => {
        switch (option && option.attribute_code) {
          case geolocation.attribute_code: {
            return <Geolocation key={option.attribute_code} option={option} />;
          }
          default: {
            return (
              <ul
                className={classes.list}
                key={option.attribute_code}
                data-t1="location_list_item"
                data-t2={option.attribute_code}
              >
                {option.attribute_options?.map((attrOptions) => {
                  // @ts-ignore
                  const labelWithCount = `${attrOptions.label} (${attrOptions.count})`;
                  if (+attrOptions.value === 0) return null;
                  return (
                    <li
                      className={classes.item}
                      key={attrOptions.value}
                      data-t2={attrOptions.value}
                      data-t1="location_list_item_value"
                    >
                      <Checkbox
                        name={option.attribute_code}
                        value={attrOptions.value}
                        label={labelWithCount}
                        // @ts-ignore
                        count={attrOptions.count}
                        data-t2={option.attribute_code}
                        data-t1="location_list_item_value_field"
                      />
                    </li>
                  );
                })}
              </ul>
            );
          }
        }
      })}
    </div>
  );
};

export default Location;
