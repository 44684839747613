/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useField } from "formik";
import { FC, SyntheticEvent } from "react";
import MaskedInput from "react-text-mask";

import __ from "web/Layout/Translations";

import ValidationErrorMessage from "web/Components/Common/ValidationErrorMessage";

import { PeselMask } from "web/utils/peselMask";
import { validateField } from "web/utils/system/formValidator/validation";

import type { Classes, PropsWithClasses } from "web/types/Common";
import type { Nullable } from "web/types/Utils";

import classify from "web/classify";

import formLabels from "../../../../constants/formLabels";
import SelectForm from "../Select";
import type { ISelectOption } from "../Select/selectForm";
import defaultClasses from "./field.scss";

interface IFieldProps {
  classesSelect?: Classes;
  label?: Nullable<string>;
  type?: string;
  name: string;
  placeholder?: Nullable<string>;
  validationRules?: Nullable<string[]>;
  onChange?: Nullable<(val: string) => void>;
  onBlur?: Nullable<(val: string) => void>;
  select?: boolean;
  disabled?: boolean;
  textArea?: boolean;
  mask?: PeselMask | ((val: string) => RegExp[]);
  dataT1?: string;
  isDataT1NotChanged?: boolean;
  selectOptions?: {
    name?: string;
    label: string;
    disabled?: boolean;
    value?: string | boolean;
  }[];
  ignoreDisabledStyle?: boolean;
  disablePaste?: boolean;
  enableNumericHandler?: boolean;
}

const Field: FC<PropsWithClasses<IFieldProps>> = ({
  classes = {},
  classesSelect = {},
  label = null,
  type = "",
  name,
  placeholder = null,
  validationRules = null,
  onChange = null,
  onBlur = null,
  select = false,
  selectOptions = [],
  textArea = false,
  disabled = false,
  mask = null,
  dataT1 = "field",
  isDataT1NotChanged = false,
  disablePaste,
  ignoreDisabledStyle = false,
  enableNumericHandler = false,
  ...restProps
}) => {
  // @ts-ignore
  const [field, meta] = useField({
    name,
    // @ts-ignore
    validate: validationRules ? validateField(validationRules) : null,
  });

  const { value = "", ...fieldRest } = field;
  // whitespace at the beginning not allowed

  const valueWithoutWhitespace =
    type === "number" ? value : String(value).trimStart();

  const isError = meta.error && meta.touched;
  const inputDisabledClass =
    disabled && !ignoreDisabledStyle ? classes.inputDisabled : classes.input;
  const inputClassName = isError ? classes.inputError : inputDisabledClass;
  const textAreaClassName = isError ? classes.textAreaError : classes.textArea;
  const customEvents = {} as {
    onChange?: (e: SyntheticEvent) => void;
    onBlur?: (e: SyntheticEvent) => void;
  };
  if (typeof onChange === "function")
    customEvents.onChange = (e) => {
      fieldRest.onChange(e);
      onChange(valueWithoutWhitespace);
    };
  if (typeof onBlur === "function")
    customEvents.onBlur = (e) => {
      fieldRest.onBlur(e);
      onBlur(valueWithoutWhitespace);
    };

  const disablePasteFunc = (event: SyntheticEvent) => {
    if (disablePaste || label === formLabels.confirmEmail)
      event.preventDefault();
  };

  const onChangeNumericHandler = (event: SyntheticEvent) => {
    const { onChange: fieldRestOnChange } = fieldRest || {};
    const { currentTarget } = event;
    const currentValue = (currentTarget as HTMLInputElement).value;
    const isItNumberOnly =
      /^-?\d+$/.test(currentValue) || currentValue.length === 0;

    if (fieldRestOnChange && isItNumberOnly) {
      fieldRestOnChange(event);
    }
  };
  /* eslint-disable react/jsx-props-no-spreading */

  const onWheel = (e: Event & { target: HTMLInputElement }) => {
    e.target.blur();

    e.stopPropagation();
  };
  // eslint-disable-next-line no-nested-ternary
  const inputVariants = textArea ? (
    <textarea
      className={textAreaClassName}
      placeholder={placeholder!}
      data-t1={dataT1}
      value={valueWithoutWhitespace}
      {...fieldRest}
      {...customEvents}
      {...restProps}
    />
  ) : mask ? (
    <MaskedInput
      type={type}
      className={inputClassName}
      placeholder={placeholder!}
      disabled={disabled}
      data-t1={dataT1}
      mask={mask}
      value={valueWithoutWhitespace}
      {...fieldRest}
      {...customEvents}
      {...restProps}
    />
  ) : (
    // @ts-ignore
    <input
      type={type}
      className={inputClassName}
      placeholder={placeholder!}
      disabled={disabled}
      onPaste={disablePasteFunc}
      data-t1={isDataT1NotChanged ? dataT1 : `${dataT1}_${name}`}
      data-t2={valueWithoutWhitespace}
      value={valueWithoutWhitespace}
      {...fieldRest}
      {...customEvents}
      {...restProps}
      {...(enableNumericHandler
        ? { onChange: (e) => onChangeNumericHandler(e) }
        : null)}
      // eslint-disable-next-line object-shorthand
      {...(type === "number" ? { onWheel: onWheel } : null)}
    />
  );

  const input = select ? (
    <SelectForm
      options={selectOptions as ISelectOption[]}
      // @ts-ignore
      name={name}
      dataT1={dataT1 || name}
      placeholder={placeholder!}
      // @ts-ignore
      initialValue={meta.initialValue}
      classes={classesSelect}
      value={valueWithoutWhitespace}
      {...fieldRest}
      {...customEvents}
      {...restProps}
    />
  ) : (
    inputVariants
  );

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <div className={classes.root}>
      {label ? (
        <label className={classes.label} data-t1={`${dataT1}_label`}>
          <span className={classes.labelText} data-t1={`${dataT1}_label_span`}>
            {__(label)}
          </span>
          {input}
        </label>
      ) : (
        input
      )}
      {isError && <ValidationErrorMessage name={name} />}
    </div>
  );
};

export default classify<PropsWithClasses<IFieldProps>>(defaultClasses)(Field);
