import { DocumentNode, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import getHelpCenterServiceCategories from "web/queries/default/getHelpCenterServiceCategories.graphql";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import {
  IHelpCenterServiceCategory,
  IHelpCenterServiceType,
} from "web/types/HelpCenter";
import type { Email, Nullable } from "web/types/Utils";

import { useGetEmployeeDetailsQuery } from "web/features/employee/employeeApiSlice";

export const DEFAULT_BENEFIT_TYPE = "default";
const LIFESTYLE_TYPE = "lifestyle";

export interface IContactFormCategoriesValues {
  benefitType: Nullable<string>;
  category: Nullable<string>;
  description: string;
  email: Email;
  productId: string;
  statement: string;
  subject: Nullable<string>;
}

interface IMapTopicToOptionsArgs {
  name: string;
  value: string;
}

interface IValueWithLabel {
  value: string;
  label: string;
}

const useContactFormCategories = (
  values: IContactFormCategoriesValues,
  setValue: (...args: unknown[]) => void
) => {
  const { mybenefit_active_menu: MBActiveLabel } = useSelector(
    ({ app }) => app.storeConfig
  );

  const { data: employeeDetails } = useGetEmployeeDetailsQuery();
  const { mbActiveEnabled: hasMBActive } = employeeDetails || {};

  const [helpCategories, setHelpCategories] = useState([]);
  const [benefitTypes, setBenefitTypes] = useState<IValueWithLabel[]>([]);
  const [subjects, setSubjects] = useState<IValueWithLabel[]>([]);
  const { category, benefitType } = values;
  const { data: helpCenterRequest, error } = useQuery(
    getHelpCenterServiceCategories as DocumentNode
  );

  if (error)
    newRelicErrorReport(
      error,
      "web-app/web/hooks/useContactFormCategories/useContactFormCategories.js - 27"
    );

  const mapTopicToOption = ({ name, value }: IMapTopicToOptionsArgs) => {
    if (value === LIFESTYLE_TYPE && hasMBActive) {
      return {
        value,
        label: MBActiveLabel,
      };
    }
    return { value, label: name };
  };

  useEffect(() => {
    setValue("benefitType", "");
    if (category === LIFESTYLE_TYPE) {
      setValue("subject", " ");
    }
  }, [category]);

  useEffect(() => {
    if (category === LIFESTYLE_TYPE) {
      setValue("subject", " ");
    }
  }, [benefitType]);

  useEffect(() => {
    const helpCategoriesOptions =
      helpCenterRequest?.helpCenter?.categories?.map(mapTopicToOption);
    setHelpCategories(helpCategoriesOptions);
  }, [helpCenterRequest]);

  useEffect(() => {
    const types = getTypesForCategory(
      category as string,
      helpCenterRequest?.helpCenter?.categories
    ).map(mapTopicToOption);
    if (noBenefitsTypes(types)) {
      setBenefitTypes([]);
      setValue("benefitType", DEFAULT_BENEFIT_TYPE);
      return;
    }

    setBenefitTypes(types);
  }, [helpCenterRequest, category]);

  useEffect(() => {
    if (!category) {
      setSubjects([]);
      return;
    }
    const types = getTypesForCategory(
      category,
      helpCenterRequest?.helpCenter?.categories
    );
    if (!types?.length) {
      setSubjects([]);
      return;
    }
    const typeSubjects =
      getSubjectsForTypes(benefitType as string, types)?.map(
        mapTopicToOption
      ) || [];
    setSubjects(typeSubjects);
  }, [helpCenterRequest, benefitType, category]);
  return { helpCategories, benefitTypes, subjects };
};

const noBenefitsTypes = (types: IValueWithLabel[]) =>
  types && types.length === 1 && types[0].value === "";

const getTypesForCategory = (
  category: string,
  categoriesOptions: IHelpCenterServiceCategory[]
) => {
  const selectedCategory =
    category && categoriesOptions?.find(({ value }) => value === category);

  return (selectedCategory && selectedCategory.types) || [];
};

const getSubjectsForTypes = (type: string, types: IHelpCenterServiceType[]) => {
  const selectedType = types?.find(({ value }) => {
    return value === type || (type === DEFAULT_BENEFIT_TYPE && value === "");
  });
  return selectedType?.subjects || [];
};

export default useContactFormCategories;
