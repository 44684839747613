import { FC } from "react";

import { Nullable } from "web/types/Utils";

interface IExternalLinkProps {
  url: string;
  email?: boolean;
  phone?: boolean;
  className?: string;
  dataT1?: Nullable<string>;
  dataT2?: Nullable<string>;
}
const ExternalLink: FC<IExternalLinkProps> = ({
  url,
  email = false,
  phone = false,
  className = "",
  dataT1 = null,
  dataT2 = null,
}) => {
  if (phone) {
    return (
      <a
        className={className}
        href={`tel:${url}`}
        data-t1={dataT1}
        data-t2={dataT2}
      >
        {url}
      </a>
    );
  }
  if (email) {
    return (
      <a
        className={className}
        href={`mailto: ${url}`}
        title={url}
        data-t1={dataT1}
        data-t2={dataT2}
      >
        {url}
      </a>
    );
  }
  return (
    <a
      className={className}
      href={url.includes("http") ? url : `https://${url}`}
      target="_blank"
      rel="noreferrer"
      data-t1={dataT1}
      data-t2={dataT2}
    >
      {url}
    </a>
  );
};

export default ExternalLink;
