// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rentableGroupCalculatorAgreements-agreementsList-S6N{display:flex;flex-direction:column;gap:1rem;padding:0 1rem}.rentableGroupCalculatorAgreements-checkboxText-Ac3{font-size:.8125rem;line-height:150%}`, "",{"version":3,"sources":["webpack://./web/Layout/RentableGroup/RentableGroupCalculatorForm/RentableGroupCalculatorAgreements/rentableGroupCalculatorAgreements.scss"],"names":[],"mappings":"AAIuB,sDACrB,YAAA,CACA,qBAAA,CACA,QAAA,CACA,cAAA,CAGF,oDACE,kBAAA,CACA,gBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .agreementsList {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  padding: 0 1rem;\n}\n\n.checkboxText {\n  font-size: 0.8125rem;\n  line-height: 150%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agreementsList": `rentableGroupCalculatorAgreements-agreementsList-S6N`,
	"checkboxText": `rentableGroupCalculatorAgreements-checkboxText-Ac3`
};
export default ___CSS_LOADER_EXPORT___;
