import { ErrorInfo, PureComponent, ReactNode } from "react";
import { connect } from "react-redux";

import __ from "web/Layout/Translations";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { setNotificationError } from "web/features/app/appSlice";

import classes from "./ErrorBoundary.scss";

type Error = {
  message: string;
};

interface IErrorBoundaryProps {
  children: ReactNode;
  smallTextSize?: boolean;
  setError: ({ message }: { message: string }) => void;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

const text = "Ups! Coś poszło nie tak";
class ErrorBoundary extends PureComponent<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    console.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { setError } = this.props;
    newRelicErrorReport(
      error,
      `ERROR BOUNDARY - stack: ${errorInfo.componentStack}`
    );
    console.error(error, errorInfo);
    setError({ message: error?.message ?? text });
  }

  render() {
    const { children, smallTextSize } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className={smallTextSize && classes.smallErrorMessage}>
          {__(text)}
        </div>
      );
    }

    return children || null;
  }
}

const mapDispatchToProps = {
  setError: setNotificationError,
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);
