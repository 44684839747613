import { FC } from "react";
import { Link } from "react-router-dom";

import LazyLoadImage from "web/Layout/Common/LazyLoadImage";
import Price from "web/Layout/Common/Price";
import __ from "web/Layout/Translations";

import useIsPL from "web/hooks/useIsPL";
import useOnProductClikCallback from "web/hooks/useOnProductClikCallback";

import processUrlKey from "web/utils/page/product/universal/processUrlKey";

import type { PropsWithClasses } from "web/types/Common";
import {
  DomesticTourismProduct,
  IProduct,
  MBProductType,
} from "web/types/Product";

import classify from "web/classify";

import type { ProductCartListProps } from "./container";
import defaultClasses from "./product.scss";

const ProductCardListProduct: FC<PropsWithClasses<ProductCartListProps>> = ({
  product,
  classes = {},
  positionNumber = 0,
}) => {
  const isPL = useIsPL();
  if (!product) {
    return null;
  }
  const {
    name,
    image,
    url_key: urlKey,
    final_price: finalPrice,
    price,
    mb_product_type: productType,
  } = product as IProduct;
  const hasAnixePrice =
    productType === MBProductType.ANIXE_TOURISM &&
    (product as IProduct<DomesticTourismProduct>).tourism_anixe_min_offer
      .total_price;
  const hasDiscount = finalPrice < price;
  const hasConfigurablePrice = !!(product as IProduct)?.product_options
    ?.configurable_options?.price;

  const [sendProductClickEvent] = useOnProductClikCallback(
    product as IProduct,
    positionNumber
  );

  const fromPrice = hasAnixePrice || hasConfigurablePrice;

  const itemName = name?.length > 100 ? `${name.substring(0, 100)}...` : name;

  return (
    <Link
      to={processUrlKey(urlKey)}
      className={classes.root}
      onClick={() => sendProductClickEvent()}
    >
      <div className={classes.leftSideContainer}>
        <div className={classes.imageContainer}>
          <LazyLoadImage
            className={classes.image}
            src={image}
            alt={name}
            isProduct
          />
        </div>
      </div>
      <div className={classes.wrapper}>
        <strong
          className={classes.name}
          data-t1="category_mobile_simple_product_name"
        >
          {itemName}
        </strong>
        {hasAnixePrice || finalPrice ? (
          <>
            {hasDiscount && (
              <div className={classes.priceBeforeDiscount}>
                <Price value={price} currency={__("zł")} />
              </div>
            )}
            <div className={classes.price}>
              <div
                className={`${
                  hasDiscount
                    ? classes.priceWrapperWithDiscount
                    : classes.priceWrapper
                } ${isPL ? "" : classes.en}`}
              >
                <Price
                  from={fromPrice as boolean}
                  value={
                    hasAnixePrice
                      ? (product as IProduct<DomesticTourismProduct>)
                          .tourism_anixe_min_offer.total_price
                      : finalPrice
                  }
                  currency={__("zł")}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </Link>
  );
};

export default classify<PropsWithClasses<ProductCartListProps>>(defaultClasses)(
  ProductCardListProduct
);
