import { IBenefitGroup, ISubscriptionAttachment } from "web/types/Subscription";

export type GroupWise = {
  groupName: string;
  benefit: {
    benefitGroup?: IBenefitGroup;
  };
};

export interface IGroupBenefitsSummary {
  groupName: string;
  benefitGroup?: IBenefitGroup;
  benefitGroupAttachments?: ISubscriptionAttachment[];
  items: any; // Items can be benefits with various structure
}

// TODO: delete function after feature flag removal
const prepareGroupBenefitsSummary = <T extends GroupWise>(
  benefits: T[]
): IGroupBenefitsSummary[] => {
  const formsDataByGroup: IGroupBenefitsSummary[] = [];

  benefits.forEach((ele) => {
    const isThisGroupAlreadyThere = formsDataByGroup.some(
      (data) => data.groupName === ele.groupName
    );
    if (!isThisGroupAlreadyThere)
      formsDataByGroup.push({
        groupName: ele.groupName,
        benefitGroup: ele.benefit?.benefitGroup,
        benefitGroupAttachments:
          ele.benefit?.benefitGroup?.benefitGroupAttachments,
        items: [{ ...ele }],
      });
    else {
      const addNewElementToThisGroup = formsDataByGroup.find(
        (formData) => formData.groupName === ele.groupName
      );
      addNewElementToThisGroup?.items.push({ ...ele });
    }
  });

  return formsDataByGroup;
};

export default prepareGroupBenefitsSummary;
