// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productsMobileGrid-list-Ul1{display:flex;flex-wrap:wrap;margin:-25px -15px;flex-direction:column;padding-bottom:150px}.productsMobileGrid-item-_xJ{display:flex;flex-direction:column;padding:25px 15px;width:100%;max-width:430px;margin:0 auto}`, "",{"version":3,"sources":["webpack://./web/Layout/Listing/Common/Products/productsMobileGrid.scss"],"names":[],"mappings":"AAIuB,6BACrB,YAAA,CACA,cAAA,CACA,kBAAA,CACA,qBAAA,CACA,oBAAA,CAGF,6BACE,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .list {\n  display: flex;\n  flex-wrap: wrap;\n  margin: -25px -15px;\n  flex-direction: column;\n  padding-bottom: 150px;\n}\n\n.item {\n  display: flex;\n  flex-direction: column;\n  padding: 25px 15px;\n  width: 100%;\n  max-width: 430px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `productsMobileGrid-list-Ul1`,
	"item": `productsMobileGrid-item-_xJ`
};
export default ___CSS_LOADER_EXPORT___;
