import { nativeRedirectionQueryParam } from "web/constants/native";
import { webViewScreenPath } from "web/constants/webView";

type Body = {
  [x: string]: unknown;
};

const getWebViewRedirectionBody = (body: Body) => ({
  ...body,
  [nativeRedirectionQueryParam]: webViewScreenPath,
});

export default getWebViewRedirectionBody;
