// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autocomplete-root-jnU{display:flex}.autocomplete-categories-Ugz{position:relative;flex-shrink:0;margin-right:60px;width:170px}.autocomplete-products-MWl{position:relative;flex-grow:1;min-width:1px}.autocomplete-empty-wI7{display:block;font-size:14px}`, "",{"version":3,"sources":["webpack://./web/Layout/SearchAutocomplete/Desktop/Autocomplete/autocomplete.scss"],"names":[],"mappings":"AAIuB,uBACrB,YAAA,CAGF,6BACE,iBAAA,CACA,aAAA,CACA,iBAAA,CACA,WAAA,CAGF,2BACE,iBAAA,CACA,WAAA,CACA,aAAA,CAGF,wBACE,aAAA,CACA,cAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: flex;\n}\n\n.categories {\n  position: relative;\n  flex-shrink: 0;\n  margin-right: 60px;\n  width: 170px;\n}\n\n.products {\n  position: relative;\n  flex-grow: 1;\n  min-width: 1px;\n}\n\n.empty {\n  display: block;\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `autocomplete-root-jnU`,
	"categories": `autocomplete-categories-Ugz`,
	"products": `autocomplete-products-MWl`,
	"empty": `autocomplete-empty-wI7`
};
export default ___CSS_LOADER_EXPORT___;
