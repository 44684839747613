import { isNil } from "lodash";
import { FC } from "react";
import { Link } from "react-router-dom";

import Badge from "web/Layout/Common/Badge";

import HeartIcon from "web/assets/icons/heart-empty.svg";

import urls from "web/constants/urls";

import { Classes } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./wishlistTrigger.scss";

interface IWishlistTriggerProps {
  classes?: Classes;
  count?: number;
}

const WishlistTrigger: FC<IWishlistTriggerProps> = ({
  classes = {},
  count,
}) => {
  return (
    <Link
      to={urls.customerWishlist}
      className={classes.root}
      data-t1="wishlist_trigger"
    >
      {!isNil(count) && <Badge className={classes.badge} amount={count} />}
      <HeartIcon className={classes.icon} data-t1="heart_icon" />
    </Link>
  );
};

export default classify<IWishlistTriggerProps>(defaultClasses)(WishlistTrigger);
