import type { StringNumber } from "web/types/Utils";

const getDiscount = (oldPrice: StringNumber, newPrice: StringNumber) => {
  const oldPriceNum = +oldPrice;
  const newPriceNum = +newPrice;

  const value =
    newPriceNum === oldPriceNum
      ? 0
      : ((oldPriceNum - newPriceNum) / oldPriceNum) * 100;

  return +value.toFixed(2);
};

export default getDiscount;
