import { FC } from "react";

import Points from "web/Layout/Common/Points";
import __ from "web/Layout/Translations";

import getProductsBanksActiveTotal from "web/utils/page/product/universal/getProductsBanksActiveTotal";

import { PaymentSettings } from "web/types/Payment";

import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import classes from "./pointsStatus.scss";

interface IProductCardFooterPointsStatusProps {
  price: number;
  payments: PaymentSettings;
}

const ProductCardFooterPointsStatus: FC<
  IProductCardFooterPointsStatusProps
> = ({ price, payments = [] }) => {
  const { data: dataBanks } = useGetBanksQuery();
  const { items: banks } = dataBanks || {};

  const { canBePaidByMoney, banksActiveTotal } = getProductsBanksActiveTotal(
    banks!,
    price,
    payments
  );

  switch (true) {
    case (banksActiveTotal < price && !canBePaidByMoney) || price === 0: {
      return <div />;
    }
    case banksActiveTotal >= price: {
      return (
        <div className={classes.root}>{__("Za całość zapłać punktami")}</div>
      );
    }
    case banksActiveTotal < price &&
      canBePaidByMoney &&
      banksActiveTotal !== 0: {
      return (
        <div className={classes.root}>
          <Points className={classes.points} points={banksActiveTotal} />
          {" + "}
          {__("resztę dopłać")}
        </div>
      );
    }
    default: {
      return (
        <div className={classes.root}>{__("Zapłać środkami własnymi")}</div>
      );
    }
  }
};

export default ProductCardFooterPointsStatus;
