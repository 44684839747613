import { FC } from "react";

import FiltersFieldsetWrapper from "../Wrapper/wrapper";
import { LocationOptions } from "../fieldset";
import Location from "./location";

interface ILocationContainerProps {
  title: string;
  options: LocationOptions[];
}

const LocationContainer: FC<ILocationContainerProps> = ({ title, options }) => {
  return (
    <FiltersFieldsetWrapper title={title}>
      <Location options={options} />
    </FiltersFieldsetWrapper>
  );
};

export default LocationContainer;
