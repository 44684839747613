import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./productConfigurableOptions.scss";

const SkeletonConfigurableOptions: FC<PropsWithClasses> = ({
  classes = {},
}) => {
  return <Skeleton className={classes.configurableOptions} />;
};

export default classify<PropsWithClasses>(defaultClasses)(
  SkeletonConfigurableOptions
);
