/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import { DatepickerFormik } from "web/Layout/Datepicker";
import {
  BIRTH_DATE_MAX,
  BIRTH_DATE_MIN,
} from "web/Layout/SubscriptionReceivers/SubscriptionReceiverForm/ActiveForm/constants";
import __ from "web/Layout/Translations";

import Checkbox from "web/Components/Common/Form/Checkbox";
import Field from "web/Components/Common/Form/Field";

import { peselMask } from "web/utils/peselMask";

import type { Classes, PropsWithClasses } from "web/types/Common";
import type { RequirePeselField } from "web/types/Subscription";

interface IRequirePeselFormProps {
  field: RequirePeselField;
  checkboxClass: Classes;
  tooltipMessage?: string;
  lang?: {
    key: string;
    code: string;
  };
}

const labels = {
  pl: {
    babyOrForeigner: "Obcokrajowiec / nowonarodzone dziecko",
    pesel: "Pesel",
    birthDate: "Data urodzenia",
  },
  en: {
    babyOrForeigner: "Foreigner/newborn baby",
    pesel: "Pesel number",
    birthDate: "Date of birth",
  },
};

const RequirePeselForm: FC<PropsWithClasses<IRequirePeselFormProps>> = ({
  field,
  checkboxClass,
  classes = {},
  tooltipMessage,
  lang = { key: "pl_PL", code: "pl" },
}) => {
  return (
    <>
      <Checkbox
        uppercase
        name={`${field.id}.babyOrForeigner`}
        classes={checkboxClass}
        tooltipMessage={tooltipMessage}
      >
        <label
          htmlFor={`${field.id}.babyOrForeigner`}
          className={`${classes.label} ${classes.checkboxLabel}`}
        >
          {__(labels[lang.code as keyof typeof labels].babyOrForeigner)}
        </label>
      </Checkbox>
      <label htmlFor={`${field.id}.pesel`} className={classes.label}>
        {__(labels[lang.code as keyof typeof labels].pesel)}
      </label>
      <Field
        // @ts-ignore
        id={`${field.id}.pesel`}
        name={`${field.id}.pesel`}
        mask={peselMask}
      />
      <label htmlFor={`${field.id}.birthDate`} className={classes.label}>
        {__(labels[lang.code as keyof typeof labels].birthDate)}
      </label>
      <div className={`${classes.inputWrapper} ${classes.dataInputWrapper}`}>
        <DatepickerFormik
          name={`${field.id}.birthDate`}
          // @ts-ignore
          label={field.label}
          placeholder={__(labels[lang.code as keyof typeof labels].birthDate)}
          className={classes.input}
          minDate={BIRTH_DATE_MIN}
          maxDate={BIRTH_DATE_MAX}
        />
      </div>
    </>
  );
};

export default RequirePeselForm;
