/* eslint-disable react/no-danger */
import { Button } from "@benefit-systems/common-components";
import DOMPurify from "dompurify";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Modal from "web/Layout/SubscriptionModal";
import __ from "web/Layout/Translations";

import CheckIcon from "web/assets/icons/ik_check_surcharge.svg";

import { IApiRequestMethods, IApiRequestOpts } from "web/api/apiRequestTypes";

import useDupplicateCardForm from "web/hooks/useDuplicateCardForm/useDuplicateCardForm";
import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import sanitizeObject from "web/utils/data/parser/object/sanitizeObject";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { DUPLICATE_DATA_TYPE, DUPLICATE_REASONS } from "web/constants/benefits";
import restUrls from "web/constants/restUrls";

import {
  DuplicateCardFormActions,
  DuplicateOrderStatus,
  IDuplicateCardData,
  IDuplicateCardFormState,
  IDuplicateFormItem,
  ISubscriptionReceiver,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import { request } from "web/api";
import { useAppContext } from "web/context/app";
import { setNotificationError } from "web/features/app/appSlice";

import prepareDuplicateFormsData from "./DuplicateSelect/PrepareDuplicateFormsData/prepareDuplicateFormsData";
import DuplicateSelect from "./DuplicateSelect/duplicateSelect";
import classes from "./duplicateCardForm.scss";
import modalClasses from "./duplicateSuccessModal.scss";

interface IDuplicateCardForm {
  duplicateCardData: IDuplicateCardData;
  receivers: string;
  receiverId: string;
  currentReceiver: ISubscriptionReceiver;
  updateReceiversDataFunc?: (newReceiverData: ISubscriptionReceiver) => void;
  setOpenDuplicateForm: (id: Nullable<string>) => void;
  setCurrentDuplicateOrderStatus: (status: DuplicateOrderStatus) => void;
}

const messages = {
  formSendGeneralError: "Wysyłanie formularza nie powiodło się",
};

const DuplicateCardForm: FC<IDuplicateCardForm> = ({
  duplicateCardData,
  receivers,
  receiverId,
  currentReceiver,
  updateReceiversDataFunc,
  setOpenDuplicateForm,
  setCurrentDuplicateOrderStatus,
}) => {
  const dispatch = useDispatch();
  const { formFieldsState, disptachFormFieldsState } = useDupplicateCardForm();
  const [error, setError] = useState("");
  const [personalDataError, setPersonalDataError] = useState(false);
  const { modal } = useAppContext();
  const { dispatch: mDispatch } = modal;

  const setModal = useCallback(
    (content: JSX.Element) => {
      mDispatch({ type: ModalActionTypes.ADD_MODAL, modal: content });
    },
    [mDispatch]
  );

  const closeModal = useCallback(() => {
    mDispatch({ type: ModalActionTypes.RESET });
  }, [mDispatch]);

  const { address, jo, mp, own, without__deliver } = DUPLICATE_DATA_TYPE;

  const {
    organizationUnits,
    ownList,
    workPlaces,
    duplicateInfo,
    placeDuplicateDeliveredType: deliveredType,
  } = duplicateCardData;

  const prepOwnList = ownList.map((own) => {
    return { value: own };
  });
  const prepWorkPlaces = workPlaces.map((place) => {
    return { value: place };
  });

  const duplicatePlace = [
    ...prepOwnList,
    ...organizationUnits,
    ...prepWorkPlaces,
  ];

  const placeDuplicateDeliveredType = deliveredType || without__deliver;

  useEffect(() => {
    if (placeDuplicateDeliveredType === address) {
      disptachFormFieldsState({
        type: DuplicateCardFormActions.CREATE_ADDRESS_DATA,
      });
    }

    if (
      placeDuplicateDeliveredType !== address &&
      placeDuplicateDeliveredType !== without__deliver &&
      formFieldsState &&
      formFieldsState.place
    ) {
      disptachFormFieldsState({
        type: DuplicateCardFormActions.PLACE_REQUIRED,
      });
    }
  }, [placeDuplicateDeliveredType]);

  const selectArray: IDuplicateFormItem[] = [
    {
      inputType: "select",
      label: __("Powód"),
      type: "reason",
      list: DUPLICATE_REASONS,
      placeholder: __("Wybierz powód"),
      index: 2,
      position: 1,
      showWhen: [address, jo, mp, own, without__deliver],
      required: true,
    },
    {
      inputType: "select",
      label: __("Miejsce dostarczenia duplikatu"),
      type: "place",
      list: duplicatePlace,
      placeholder: "",
      index: 1,
      position: 4,
      showWhen: [jo, mp, own],
      required:
        placeDuplicateDeliveredType !== address &&
        placeDuplicateDeliveredType !== without__deliver,
    },
  ];

  const textInputsHandler = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
    type: string,
    required: boolean
  ) => {
    const { value } = e.target;
    disptachFormFieldsState({ type, payload: { value, required } });
  };

  const closeDuplicateFormModal = () => {
    // Close all duplicate forms
    setOpenDuplicateForm(null);
  };

  const submitDuplicateData = () => {
    const parsedObj = prepareDuplicateFormsData(
      formFieldsState,
      placeDuplicateDeliveredType
    );

    const prepParseObj = sanitizeObject(parsedObj);

    const fetchCardData = async () => {
      try {
        const requestUrl = `${restUrls.duplicateCardOrder.replace(
          ":uuid",
          receiverId || receivers
        )}`;

        const requestOpts: IApiRequestOpts = {
          method: IApiRequestMethods.POST,
          body: JSON.stringify({ data: { ...prepParseObj } }),
        };

        const response = (await request(requestUrl, requestOpts)) as {
          status: string;
        };

        if (response.status === "active") {
          const successModal = (
            <Modal classes={modalClasses}>
              <>
                <CheckIcon className={modalClasses.icon} />
                <span className={modalClasses.text}>
                  {__(
                    "Zlecenie wydania duplikatu zostało przesłane do realizacji."
                  )}
                </span>
              </>
            </Modal>
          );

          // Prepare changed receiver item for benefitItem state
          const prepReceiverData = {
            ...currentReceiver,
            duplicateOrderStatus: DuplicateOrderStatus.JUST_ORDERED,
          };

          // Update for benefitItem state
          if (typeof updateReceiversDataFunc === "function")
            updateReceiversDataFunc(prepReceiverData);

          // Close all duplicate forms
          setOpenDuplicateForm(null);
          setCurrentDuplicateOrderStatus(DuplicateOrderStatus.JUST_ORDERED);

          setModal(successModal);
          setTimeout(() => closeModal(), 2500);
        }
      } catch (errorData: any) {
        const hasPersonalDataNotChange =
          typeof errorData.message === "string"
            ? errorData.message.includes("PERSONAL_DATA_NOT_CHANGE")
            : false;
        if (hasPersonalDataNotChange && errorData?.response?.status === 422) {
          setPersonalDataError(true);
        } else setError(__(messages.formSendGeneralError));
        dispatch(
          setNotificationError({
            message: __(messages.formSendGeneralError),
          })
        );
        console.error(errorData);
      }
    };

    fetchCardData();
  };

  const isDisabled = Object.keys(formFieldsState)
    .map((key) => [key, formFieldsState[key as keyof IDuplicateCardFormState]])
    .some((field) => {
      if (isArrayHasItems(field) && field[1]) {
        const fieldElement = field[1];
        const isNotString = typeof fieldElement !== "string";
        return isNotString && !fieldElement.value && fieldElement.required;
      }

      return true;
    });

  return (
    <div className={classes.duplicateForm}>
      <h3 className={classes.duplicateForm__title}>
        {__("Zamówienie duplikatu")}
      </h3>
      {isArrayHasItems(selectArray) &&
        selectArray
          .sort((a, b) => a.position - b.position)
          .map((formItem) => {
            return (
              <DuplicateSelect
                key={formItem.position}
                formItem={formItem}
                disptachFormFieldsState={disptachFormFieldsState}
                formFieldsState={formFieldsState}
                textInputsHandler={textInputsHandler}
                placeDuplicateDeliveredType={placeDuplicateDeliveredType}
                personalDataError={personalDataError}
                setPersonalDataError={setPersonalDataError}
              />
            );
          })}
      <div className={classes.duplicateForm__formItem}>
        <span className={classes.duplicateForm__label}>{__("Uwagi")}</span>
        <textarea
          className={classes.duplicateForm__textArea}
          value={formFieldsState.userComment?.value}
          onChange={(e) =>
            textInputsHandler(e, DuplicateCardFormActions.USER_COMMENT, false)
          }
          maxLength={255}
        />
      </div>
      <div className={classes.duplicateForm__formItem}>
        <span className={classes.duplicateForm__reqInfo}>
          <span className={classes.duplicateForm__required}>*</span>
          {__("Pola wymagane")}
        </span>
        {error && <span className={classes.duplicateForm__error}>{error}</span>}
        <div
          className={`${classes.duplicateForm__info} cke_editable`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(duplicateInfo as string),
          }}
        />
      </div>
      <div
        className={`${classes.duplicateForm__formItem} ${classes.duplicateForm__buttonWrap}`}
      >
        <Button
          type="button"
          variant="secondary"
          onClick={() => closeDuplicateFormModal()}
        >
          {__("Anuluj")}
        </Button>
        <Button
          type="button"
          variant="tertiary"
          onClick={submitDuplicateData}
          disabled={isDisabled}
        >
          {__("Wyślij zamówienie")}
        </Button>
      </div>
    </div>
  );
};

export default DuplicateCardForm;
