import { FC } from "react";

import Button from "web/Pages/PageGenerator/Button";

import PrinterIcon from "web/assets/icons/printer.svg";

import { useAppContext } from "web/context/app";

import classes from "./printButton.scss";

interface IPrintButtonProps {
  className?: string;
  label: string;
}

const PrintButton: FC<IPrintButtonProps> = ({ className = "", label }) => {
  const { print } = useAppContext();

  return (
    <div className={className || classes.root}>
      <Button onClick={print} className={classes.button} dataT1="print_button">
        <PrinterIcon className={classes.icon} />
        {label}
      </Button>
    </div>
  );
};

export default PrintButton;
