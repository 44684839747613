import { FC } from "react";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

interface ILocationParentProps {
  location: string;
  className?: string;
}

const LocationParent: FC<ILocationParentProps> = ({ location, className }) => {
  const { loading, error, data } = useDataCachedAttributes({
    ids: ["location_location_parent_location_parent"],
  });

  if (loading || error) {
    return null;
  }

  const attributeOptions = data?.[0]?.attribute_options;
  const locationLabel =
    isArrayHasItems(attributeOptions) &&
    attributeOptions.find((option) => +option.value === +location)?.label;

  return locationLabel ? (
    <div
      className={className}
      data-t1="location_parent_label"
      data-t2={locationLabel}
    >
      {locationLabel}
    </div>
  ) : null;
};

export default LocationParent;
