import { FC, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getSearchParameterAll from "web/utils/system/url/getSearchParameterAll";

import { Nullable } from "web/types/Utils";

import classes from "./activeFiltersItem.scss";

interface IActiveFiltersItemProps {
  code: string;
  value?: string;
  values?: Nullable<string[]>;
  label: string;
  labelValue: string;
}

const ActiveFiltersItem: FC<IActiveFiltersItemProps> = ({
  value = "",
  values,
  code,
  label = "",
  labelValue = "",
}) => {
  const { pathname, search } = useLocation();
  const { push } = useHistory();
  const removeFilter = useCallback(() => {
    const params = new URLSearchParams(search);
    const valuesFromSearch = getSearchParameterAll({ name: code, search });
    params.delete(code);

    if (!values && isArrayHasItems(valuesFromSearch)) {
      valuesFromSearch.forEach((valueItem) => {
        if (`${valueItem}` !== `${value}`) {
          params.append(code, encodeURIComponent(valueItem));
        }
      });
    }
    push(`${pathname}?${params.toString()}`);
  }, [push, pathname, search, code, value]);

  return (
    <button
      type="button"
      data-t1="remove_filter_button"
      onClick={removeFilter}
      className={classes.root}
    >
      <span className={classes.remove} data-t1="active_filters_item_span" />
      {label || code}
      {": "}
      {labelValue || value}
    </button>
  );
};

export default ActiveFiltersItem;
