import useLogout from "web/Pages/Customer/Content/Main/Logout/utils/useLogout";

import __ from "web/Layout/Translations";

import classes from "./logout.scss";

const MainHeaderLogout = () => {
  const text = __("Wyloguj się");

  const { isDisabled, logoutHandler } = useLogout();

  return (
    <button
      type="button"
      className={classes.root}
      title={text}
      onClick={logoutHandler}
      data-t1="logout_button"
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};

export default MainHeaderLogout;
