import { FC } from "react";

import { ImageSource } from "web/Layout/Common/Image";

import defaultThumbnail from "web/assets/images/subscriptionGroupThumb.png";

import { Nullable } from "web/types/Utils";

import styles from "./groupThumbnail.scss";

interface IGroupThumbnailProps {
  src: Nullable<string>;
  alt: string;
}

const GroupThumbnail: FC<IGroupThumbnailProps> = ({ src, alt }) => (
  <ImageSource
    className={styles.root}
    src={src || defaultThumbnail}
    alt={alt}
  />
);

export default GroupThumbnail;
