import { isNil } from "lodash";

import storageNames from "web/constants/storageNames";

import BrowserPersistence from "../storage/storage/browserPersistence";

const storage = new BrowserPersistence();

const isValidTokenAccess = (): boolean => {
  const tokenAccess = storage.getItem(storageNames.tokenAccess);
  return !isNil(tokenAccess);
};

export default isValidTokenAccess;
