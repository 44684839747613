import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import isExternal from "web/utils/data/validator/url/isExternal";
import processAnchorHref from "web/utils/system/url/processAnchorHref";

export interface IAnchorProps {
  to: string;
  title?: string;
  onClick?: () => void;
  className?: string;
  dataT1?: string;
}

const Anchor: FC<PropsWithChildren<IAnchorProps>> = ({
  to,
  title = "",
  onClick = () => {},
  className = "",
  dataT1 = "anchor",
  children = "",
}) => {
  const newHref = processAnchorHref(to);

  return isExternal(newHref) ? (
    <a
      target="_blank"
      rel="noreferrer"
      href={newHref}
      title={title}
      className={className}
      data-t1={dataT1}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <Link
      to={newHref}
      title={title}
      onClick={onClick}
      className={className}
      data-t1={dataT1}
      data-t2={title}
    >
      {children}
    </Link>
  );
};

export default Anchor;
