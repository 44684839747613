// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-root-dqF{position:absolute;left:0;right:0;top:0;bottom:0;z-index:100;display:flex;justify-content:center;align-items:center;background-color:rgba(255,255,255,.5)}.loader-loader-KwD{width:50px;height:50px}`, "",{"version":3,"sources":["webpack://./web/Components/Common/CodeItem/Loader/loader.scss"],"names":[],"mappings":"AAIuB,iBACrB,iBAAA,CACA,MAAA,CACA,OAAA,CACA,KAAA,CACA,QAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qCAAA,CAGF,mBACE,UAAA,CACA,WAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 100;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(255,255,255, 0.5);\n}\n\n.loader {\n  width: 50px;\n  height: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `loader-root-dqF`,
	"loader": `loader-loader-KwD`
};
export default ___CSS_LOADER_EXPORT___;
