/* eslint-disable react/jsx-props-no-spreading */
import { FC } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import Fieldset, { FieldsetType } from "./fieldset";

const Container: FC<FieldsetType> = (props) => {
  return (
    <ErrorBoundary>
      <Fieldset {...props} />
    </ErrorBoundary>
  );
};

export default Container;
