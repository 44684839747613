import { FC, PropsWithChildren, createContext } from "react";
import { useSelector } from "react-redux";

import useCampaignGraphics from "web/hooks/useCampaignGraphics";

import placements from "web/constants/campaignGraphics";

import { IStoreConfig } from "web/types/StoreConfig";
import type { Nullable } from "web/types/Utils";

export const CampaignGraphicsContext = createContext({});

type Placements = typeof placements;

interface ICampaignGraphicsProviderProps {
  categoryId?: Nullable<number>;
  placement: Placements[keyof Placements];
}

const CampaignGraphicsProvider: FC<
  PropsWithChildren<ICampaignGraphicsProviderProps>
> = ({ categoryId, placement, children }) => {
  const storeConfig = useSelector(
    (state) => state.app.storeConfig
  ) as IStoreConfig;
  const data = useCampaignGraphics({ categoryId, placement, storeConfig });

  return (
    <CampaignGraphicsContext.Provider value={{ data }}>
      {children}
    </CampaignGraphicsContext.Provider>
  );
};

export default CampaignGraphicsProvider;
