import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import defaultClasses from "./productCard.scss";

interface ISkeletonProductCardProps {
  isGrid?: boolean;
  isItemWithFixedWidth?: boolean;
}

const SkeletonProductCard: FC<PropsWithClasses<ISkeletonProductCardProps>> = ({
  classes = {},
  isGrid = true,
  isItemWithFixedWidth = false,
}) => {
  const { isMobile } = useAppContext();

  return isGrid || !isMobile ? (
    <div className={isMobile ? classes.mobileGridItem : `${classes.desktopItem} ${isItemWithFixedWidth ? classes.desktopItemWithFixedWidth : ""}`}>
      <Skeleton className={classes.imageBox} />
      <Skeleton className={classes.category} />
      <Skeleton className={classes.productTitle} />
      <Skeleton className={classes.body} />
      <div className={classes.contentWrapper}>
        <Skeleton className={classes.priceBox} />
        <Skeleton className={classes.pointsStatus} />
      </div>
    </div>
  ) : (
    <Skeleton className={classes.mobileListItem} />
  );
};

export default classify<PropsWithClasses<ISkeletonProductCardProps>>(
  defaultClasses
)(SkeletonProductCard);
