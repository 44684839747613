/* eslint-disable react/button-has-type, @typescript-eslint/ban-ts-comment */
import { Field, Form, Formik, useFormik } from "formik";
import { FC, SyntheticEvent, useEffect } from "react";

import CloseIcon from "web/assets/icons/close.svg";
import Magnifier from "web/assets/icons/magnifier.svg";

import sanitizeObject from "web/utils/data/parser/object/sanitizeObject";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./search.scss";

interface ISearchProps {
  label?: string;
  placeholder?: string;
  submitText?: string;
  layout?: string;
  onChange?: (...values: unknown[]) => unknown;
  onSubmit?: (value: unknown) => unknown;
  initialValue?: string;
  hasRandomId?: boolean;
  hideSubmitOnInput?: boolean;
  dataT1?: string;
  inputType?: string;
}

const name = "search";
const Search: FC<PropsWithClasses<ISearchProps>> = ({
  classes = {},
  placeholder = "Czego szukasz?",
  label = "Szukaj",
  submitText = "Szukaj",
  layout = "TO_LEFT",
  onChange = null,
  onSubmit = null,
  initialValue = "",
  hasRandomId = true,
  hideSubmitOnInput = false,
  dataT1 = "search",
  inputType = "text",
}) => {
  const formik = useFormik({
    initialValues: {
      [name]: initialValue,
    },
    onSubmit: (values) => {
      const sanitizedValues = sanitizeObject(values);
      if (onSubmit) onSubmit(sanitizedValues[name]);
      JSON.stringify(sanitizedValues, null, 2);
    },
  });
  const inputClassName =
    layout === "TO_RIGHT"
      ? `${classes.input} ${classes.inputRight}`
      : classes.input;
  const submitClassName =
    layout === "TO_RIGHT" ? classes.submitRight : classes.submit;
  const labelClassName = classes.label || "visually-hidden";
  const rowClassName = hideSubmitOnInput ? classes.rowHideIcon : classes.row;

  const identifier = hasRandomId
    ? `${name}-${Math.random().toString().slice(2)}`
    : name;

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(formik.values[name], formik.dirty);
    }
  }, [formik.values[name], formik.dirty]);

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    formik.setFieldValue(name, "", false);
  };

  return (
    // @ts-ignore
    <Formik>
      <div className={classes.root}>
        <Form className={classes.form} onSubmit={formik.handleSubmit}>
          <label
            htmlFor={identifier}
            className={labelClassName}
            data-t1={`${dataT1}_label`}
          >
            {label}
          </label>
          <div className={rowClassName}>
            <Field
              className={inputClassName}
              id={identifier}
              name={name}
              type={inputType}
              onChange={formik.handleChange}
              value={formik.values[name]}
              placeholder={placeholder}
              autoComplete="off"
              data-t1={`${dataT1}_input`}
            />

            {formik.values[name] ? (
              <button
                className={classes.autocomplete}
                type="reset"
                data-t1={`${dataT1}_button_autocomplete`}
                onClick={handleClick}
              >
                <CloseIcon className={classes.icon} />
              </button>
            ) : (
              <button
                className={submitClassName}
                type="submit"
                data-t1={`${dataT1}_button`}
                title={submitText}
              >
                <Magnifier className={classes.icon} />
              </button>
            )}
          </div>
        </Form>
      </div>
    </Formik>
  );
};

export default classify(defaultClasses)(Search);
