/* eslint-disable react/prop-types */
import { FC } from "react";

import { pageLayout } from "web/constants/toolbar";

import { ICampaignGraphics } from "web/types/CampaignGraphics";
import type { Classes, PropsWithClasses } from "web/types/Common";
import type {
  DomesticTourismProductInList,
  ProductInList,
} from "web/types/Product";
import type { ITourismParams } from "web/types/Tourism";
import type { Nullable } from "web/types/Utils";

import { useAppContext } from "web/context/app";

import Carousel from "./Carousel";
import Default from "./Default";
import List from "./List";

export type LayoutType = "grid" | "list" | "carousel";

export interface IProductCardProps {
  product: ProductInList | ICampaignGraphics | DomesticTourismProductInList;
  gtmProduct?: ProductInList | ICampaignGraphics | DomesticTourismProductInList;
  isWishlistPage?: boolean;
  categoryId?: string;
  params?: Nullable<ITourismParams>;
  productType?: LayoutType;
  positionNumber?: number;
  listingPosition?: number;
  listingAmount?: number;
  listingCategory?: string;
  variant?: string;
  isAddingToFavoritesDisabled?: boolean;
  clickFrom?: string;
}

const ProductCardContainer: FC<PropsWithClasses<IProductCardProps>> = ({
  classes,
  product,
  gtmProduct,
  isWishlistPage,
  categoryId,
  params,
  productType,
  positionNumber,
  listingPosition,
  listingAmount,
  listingCategory,
  variant,
}) => {
  const { isMobile } = useAppContext();
  const [, listName] = pageLayout.options;
  switch (true) {
    case isMobile && productType === listName: {
      return (
        <List
          positionNumber={positionNumber}
          classes={classes as Classes}
          product={product}
        />
      );
    }
    case isMobile && productType === "carousel": {
      return (
        <Carousel
          classes={classes}
          product={product}
          gtmProduct={gtmProduct}
          listingPosition={listingPosition}
          listingAmount={listingAmount}
          listingCategory={listingCategory}
        />
      );
    }
    default: {
      return (
        <Default
          product={product}
          isWishlistPage={isWishlistPage}
          categoryId={categoryId}
          params={params}
          positionNumber={positionNumber}
          listingPosition={listingPosition}
          listingAmount={listingAmount}
          listingCategory={listingCategory}
          variant={variant}
        />
      );
    }
  }
};

export default ProductCardContainer;
