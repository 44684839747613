/* eslint-disable consistent-return */
/* eslint-disable  array-callback-return */

const getValueByStringPath =  (values: Record<string, number | string>, name: string) => {
  if (typeof values === "object" && typeof name === "string") {
    const nameSplit = name.split(".");
    return nameSplit.reduce<number| string | Record<string, number | string> | undefined>(
      (result, current) => {
        if (result && Object.prototype.hasOwnProperty.call(result, current)) {
          return (<Record<string, number | string>>result)[current];
        }
      },
      values
    );
  }
};


export default getValueByStringPath;