import __ from "web/Layout/Translations";

import EmptyAnixeImage from "web/assets/icons/emptyAnixeListing.svg";
import EmptyAnixeImageEn from "web/assets/icons/emptyAnixeListing_en.svg";

import useIsPL from "web/hooks/useIsPL";

import { useAppContext } from "web/context/app";

import classes from "./emptyAnixeListing.scss";

const EmptyAnixeListing = () => {
  const { isMobile } = useAppContext();
  const isPL = useIsPL();

  return (
    <section className={classes.emptyAnixeListing}>
      <div className={classes.textWrapper}>
        <h3 className={classes.title}>
          {__("Nie znaleźliśmy żadnych ofert dla Twojego zapytania")}
        </h3>
        <h5 className={classes.subTitle}>{__("Co możesz teraz zrobić?")}</h5>
        <ul className={classes.list}>
          <li className={classes.listItem}>
            {isMobile
              ? __("spróbuj zmienić kryteria wyszukiwania")
              : __("sprawdź czy wpisałeś poprawną nazwę obiektu i lokalizację")}
          </li>
          <li className={classes.listItem}>
            {__("spróbuj wybrać inną datę przyjazdu i wyjazdu")}
          </li>
        </ul>
      </div>
      <div className={classes.imageWrapper}>
        {isPL ? (
          <EmptyAnixeImage className={classes.image} data-testid="image-pl" />
        ) : (
          <EmptyAnixeImageEn className={classes.image} data-testid="image-en" />
        )}
      </div>
    </section>
  );
};

export default EmptyAnixeListing;
