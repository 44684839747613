import { FC } from "react";

import __ from "web/Layout/Translations";

import Field from "web/Components/Common/Form/Field";
import Select from "web/Components/Common/Form/Select";

import TrashIcon from "web/assets/icons/trash.svg";

import rentableGroupCalculatorCopy from "../const/rentableGroupCalculatorCopy";
import peopleOptions from "../const/rentableGroupCalculatorPeopleOptions";
import classes from "./rentableGroupCalculatorPerson.scss";

const peopleMap = {
  me: "Ja",
  partner: "Małżonek/Partner",
  child: "Dziecko",
  other: "Inna osoba",
};

const { removePerson, personInHousehold, incomeInHousehold } =
  rentableGroupCalculatorCopy;

interface IRentableGroupCalculatorPersonProps {
  personRole: string;
  isSelectDisabled?: boolean;
  onRemove?: () => void;
  selectName: string;
  inputName: string;
}

const RentableGroupCalculatorPerson: FC<
  IRentableGroupCalculatorPersonProps
> = ({
  personRole,
  selectName,
  inputName,
  isSelectDisabled = false,
  onRemove,
}) => {
  return (
    <div className={`${classes.root} ${onRemove ? "" : classes.noRemoveBtn}`}>
      <div className={classes.inputWrapper}>
        <div className={classes.selectWrapper}>
          <h4 className={classes.fieldLabel}>{__(personInHousehold)}</h4>
          <Select
            options={peopleOptions}
            placeholder={peopleMap[personRole as keyof typeof peopleMap]}
            name={selectName}
            classes={{ selectRoot: classes.select }}
            isDisabled={isSelectDisabled}
            shouldTranslateLabel
          />
        </div>

        <div className={classes.incomeInputWithLabelWrapper}>
          <h4 className={classes.fieldLabel}>{__(incomeInHousehold)}</h4>
          <div className={classes.incomeInputWrapper}>
            <Field
              name={inputName}
              type="number"
              validationRules={["required"]}
            />
            <span className={classes.currency}>{__("zł")}</span>
          </div>
        </div>
      </div>

      {onRemove && (
        <div className={classes.btnWrapper}>
          <button
            className={classes.removeBtn}
            type="button"
            onClick={onRemove}
          >
            <TrashIcon width={16.5} />
            <span className={classes.removeBtnText}>{__(removePerson)}</span>
          </button>
        </div>
      )}
    </div>
  );
};
export default RentableGroupCalculatorPerson;
