import { isNil } from "lodash";

import type { IEmployeeRentableGroup } from "web/types/Employee";
import { Nullable } from "web/types/Utils";

import { IRentableGroupCalculatorValues } from "../rentableGroupCalculatorContainer";
import calculateAverageIncome from "./calculateAverageIncome";
import calculateTotalIncome from "./calculateTotalIncome";

interface ICalculateRentableGroupsArgs {
  formValues: IRentableGroupCalculatorValues;
  rentableGroups?: Nullable<IEmployeeRentableGroup[]>;
  calculatorRentableGroupDefault?: string;
  calculatorRentableGroupHighest?: string;
  monthCountToDivide: number;
}

const calculateRentableGroup = ({
  formValues,
  rentableGroups,
  calculatorRentableGroupDefault,
  calculatorRentableGroupHighest,
  monthCountToDivide,
}: ICalculateRentableGroupsArgs) => {
  const {
    income,
    "fill-calculator-checkbox": fillCalculatorCheckbox,
    "financing-checkbox": financingCheckbox,
  } = formValues;
  if (fillCalculatorCheckbox || financingCheckbox) {
    return {
      peopleCount: 0,
      averageIncome: 0,
      incomeGroup:
        rentableGroups?.find(
          ({ id }) =>
            id ===
            (fillCalculatorCheckbox
              ? calculatorRentableGroupHighest
              : calculatorRentableGroupDefault)
          // this explicit null is returned for group displaying condition
        ) ?? null,
    };
  }

  const peopleCount = income.people.length + 1;
  const totalIncome = calculateTotalIncome(income);
  const averageIncome = calculateAverageIncome({
    totalIncome,
    peopleCount,
    monthsCount: monthCountToDivide,
  });

  const incomeGroup = rentableGroups?.find((group) => {
    const { rentableGroupIncomeFrom: from, rentableGroupIncomeTo: to } = group;
    if (isNil(from) && isNil(to)) {
      return false;
    }
    return (
      averageIncome >= +(group.rentableGroupIncomeFrom ?? 0) &&
      (!group.rentableGroupIncomeTo ||
        averageIncome <= +group.rentableGroupIncomeTo)
    );
  });
  return {
    peopleCount,
    averageIncome,
    incomeGroup,
  };
};

export default calculateRentableGroup;
