/* eslint-disable react/prop-types */
import { FC, useMemo } from "react";

import Points from "web/Layout/Common/Points";
import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import Multiselect from "../Multiselect";
import { CommonOptions } from "../fieldset";

interface ISpecialProps {
  code: string;
  title: string;
  options: CommonOptions[];
}

const Special: FC<ISpecialProps> = ({ code, title, options }) => {
  const { data: dataBanks } = useGetBanksQuery();
  const { items: banks } = dataBanks || {};

  switch (code) {
    case "payment_settings": {
      const optionsProcessed = useMemo(() => {
        return isArrayHasItems(options)
          ? options.map((option) => {
              const { value, count } = option;
              if (value === "payu") {
                return {
                  value,
                  count,
                  label: __("Płatność własna"),
                  info: null,
                };
              }

              const currentBank =
                banks &&
                banks.find((bank) => bank.points_bank_id === option.value);
              const { points_bank_name: label, balance } = currentBank || {
                points_bank_name: __("Bank punktów ktory nie istnieje"),
                balance: 0,
              };
              return {
                value: option.value,
                count: option.count,
                label,
                info: (
                  <>
                    {__("masz")}{" "}
                    <Points
                      dataT1="special_points"
                      className="test"
                      points={balance}
                    />
                  </>
                ),
              };
            })
          : [];
      }, [JSON.stringify(options), JSON.stringify(banks)]);
      return (
        <Multiselect title={title} code={code} options={optionsProcessed} />
      );
    }
    default: {
      return null;
    }
  }
};

export default Special;
