import { Button } from "@benefit-systems/common-components";
import DOMPurify from "dompurify";
import { ElementType, FC, useCallback } from "react";

import { readMoreTypeConditions } from "web/Pages/ContactForm/constants";

import Definition from "web/Layout/SubscriptionDefinition";
import FilesList from "web/Layout/SubscriptionFilesList";
import __ from "web/Layout/Translations";

import Title from "web/Components/Common/Title/title";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { getSortedAttachments } from "web/utils/page/product/subscription/prepareAttachments";
import setBenefitGroupTypeParam from "web/utils/page/product/subscription/setBenefitGroupTypeParam";

import urls from "web/constants/urls";

import { PropsWithClasses } from "web/types/Common";
import { ISubscriptionAttachment } from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import ReadMoreBenefitView from "./SubscriptionReadMoreBenefitView";
import defaultClasses from "./subscriptionReadMore.scss";

interface ISubscriptionReadMoreProps {
  title: string;
  description?: Nullable<string>;
  attachments: ISubscriptionAttachment[];
  benefitLegends: {
    code: string;
    name: string;
    icon: ElementType;
    message: string;
  }[];
  benefitDraftUUID: Nullable<string>;
  price?: number;
  quantity?: number;
  type: string;
  groupType?: string;
  groupName: string;
  endsAt?: string;
  expectedEndsAt?: string;
  fundingSourceData?: string[];
  isWindowOpen?: boolean;
}

const titleClasses = {
  header: defaultClasses.title,
};

const definitionClass = {
  root: defaultClasses.definition,
  tooltip: defaultClasses.detailsTooltip,
};

const SubscriptionReadMore: FC<
  PropsWithClasses<ISubscriptionReadMoreProps>
> = ({
  classes = {},
  title = "",
  description,
  attachments,
  benefitLegends,
  benefitDraftUUID,
  price,
  quantity,
  type,
  groupType,
  groupName,
  endsAt,
  expectedEndsAt,
  fundingSourceData,
  isWindowOpen,
}) => {
  const { modal } = useAppContext();
  const { dispatch } = modal;

  const closeModal = useCallback(() => {
    dispatch({ type: ModalActionTypes.RESET });
  }, [dispatch]);

  const checkoutGroupURL = `${
    urls.checkoutSubscriptionConfiguration
  }${setBenefitGroupTypeParam(groupType)}`;
  const sortedAttachments = getSortedAttachments(attachments);
  const benefitIcons = benefitLegends.map(({ name, icon: Icon, message }) => {
    return (
      <Definition key={name} message={message} classes={definitionClass}>
        <Icon className={classes.detailsIcon} />
        {__(name)}
      </Definition>
    );
  });

  return (
    <section className={classes.root}>
      {type === readMoreTypeConditions.seeDetails ? (
        <ReadMoreBenefitView
          price={price}
          quantity={quantity}
          name={title}
          groupName={groupName}
          endsAt={endsAt}
          expectedEndsAt={expectedEndsAt}
          fundingSourceData={fundingSourceData}
        />
      ) : (
        <>
          <span className={defaultClasses.groupTitle}>{groupName}</span>
          <Title classes={titleClasses} name={title} />
        </>
      )}
      {benefitIcons.length ? (
        <div className={classes.definitionWrapper}>
          <h3 className={classes.definitionTitle}>
            {__("Oznaczenie abonamentu")}
          </h3>
          {benefitIcons}
        </div>
      ) : null}
      {description ? (
        <div className={classes.descWrapper}>
          <h2 className={classes.subtitle}>{__("Opis abonamentu")}</h2>
          <div
            className={`${classes.description} cke_editable cke_editable_themed cke_contents_ltr cke_show_borders`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description, { ADD_ATTR: ["target"] }),
            }}
          />
        </div>
      ) : null}
      {Object.keys(sortedAttachments).map((groupType) => {
        if (!isArrayHasItems(sortedAttachments[groupType]?.attachments)) {
          return null;
        }
        return (
          <FilesList
            key={groupType}
            list={sortedAttachments[groupType]}
            benefitDraftUUID={benefitDraftUUID}
          />
        );
      })}
      {type === readMoreTypeConditions.seeDetails && (
        <Button
          type="button"
          variant="primary"
          onClick={closeModal}
          to={checkoutGroupURL}
          className={defaultClasses.footerBtn}
          disabled={!isWindowOpen}
        >
          {__("Wybierz / zmień abonament")}
        </Button>
      )}
    </section>
  );
};

export default classify<PropsWithClasses<ISubscriptionReadMoreProps>>(
  defaultClasses
)(SubscriptionReadMore);
