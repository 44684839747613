import { FC } from "react";
import classes from "./place.scss";

interface IProductCardPlaceProps {
  name?: string;
}

const ProductCardPlace: FC<IProductCardPlaceProps> = ({ name }) => {
  return name ? <div className={classes.root}>{name}</div> : null;
};

export default ProductCardPlace;
