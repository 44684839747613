/* eslint-disable @typescript-eslint/ban-ts-comment */
import { motion } from "framer-motion";
import { useCallback, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";

import ErrorBoundary from "web/Layout/ErrorBoundary";
import __ from "web/Layout/Translations";

import useDropdown from "web/hooks/useDropdown";

import formatNumber from "web/utils/data/parser/number/formatNumber";
import scrollToTop from "web/utils/system/DOM/scroll/scrollToTop";

import { useGetMenuQuery } from "web/features/api/graphQLApiSlice";
import { useGetCompanyDetailsQuery } from "web/features/company/companyApiSlice";
import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";
import { setRestrictions } from "web/features/customerMenu/customerMenuSlice";
import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";

import Dropdown from "./Dropdown";
import styles from "./customer.scss";

const variants = {
  open: {
    opacity: 1,
    visibility: "visible",
  },
  closed: {
    opacity: 0,
    visibility: "hidden",
  },
};
const transition = { duration: 0.2 };

const MainHeaderCustomer = () => {
  const dispatch = useDispatch();

  const { data } = useGetCompanyDetailsQuery();
  const { id } = data || {};

  // disabled for one company per special request
  const shouldShowPoints = id !== "f5c7ac49-7670-4d8a-8fe0-94f2d67d8d32";

  const { data: menuData } = useGetMenuQuery();
  const clientMenu = menuData?.customerMenu ?? "{}";

  useEffect(() => {
    if (clientMenu.length > 2) {
      dispatch(setRestrictions(JSON.parse(clientMenu)?.client_menu?.elements));
    }
  }, [clientMenu]);

  const { data: dataBanks, isLoading } = useGetBanksQuery();
  const { total: totalPoints } = dataBanks || {};

  const { data: customer } = useGetCustomerDetailsQuery();
  const { firstname, lastname } = customer || {};

  const [isOpen, setIsOpen] = useDropdown({
    scopeSelector: `.${styles.root}`,
    clickOutside: true,
  });

  const name = `${firstname || ""} ${lastname || ""}`.trim();
  const pointsFormatted = totalPoints ? formatNumber(totalPoints) : 0;
  const pointsText = __("pkt");
  const classActive = isOpen ? styles.active : "";
  const toggleOpen = () => {
    setIsOpen((state) => !state);
  };

  const toggleOpenRedirect = useCallback(() => {
    setIsOpen((state) => !state);
    scrollToTop();
  }, [setIsOpen, isOpen]);

  return (
    <div className={styles.root} data-t1="customer">
      <button
        className={styles.trigger}
        type="button"
        title={name}
        onClick={toggleOpen}
        data-t1="customer_trigger"
      >
        <strong
          className={`${styles.name} ${classActive}`}
          data-t1="customer_name"
        >
          {name}
        </strong>
        {shouldShowPoints && (
          <span className={styles.points} data-t1="customer_points">
            {isLoading || !id ? (
              <Skeleton />
            ) : (
              <span key="points-container">
                {pointsFormatted}{" "}
                <sup className={styles.pointsText} key="points-text">
                  {pointsText}
                </sup>
              </span>
            )}
          </span>
        )}
      </button>
      <motion.div
        className={styles.dropdown}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        transition={transition}
        // @ts-ignore
        variants={variants}
        data-t1="customer_dropdown"
      >
        <ErrorBoundary>
          <Dropdown
            totalPoints={totalPoints ?? 0}
            setIsOpen={toggleOpenRedirect}
          />
        </ErrorBoundary>
      </motion.div>
    </div>
  );
};

export default MainHeaderCustomer;
