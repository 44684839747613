import { ISubscriptionDraftForms } from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

export default (
  subscriptionId: string,
  message: string,
  initialDraft?: Nullable<ISubscriptionDraftForms>
) => {
  if (!initialDraft) return message;

  const subscriptionName = initialDraft.benefits.find(
    (subscription) => subscription.id === subscriptionId
  )?.name;

  if (!subscriptionName) return message;

  return message.replace(`{{subscription}}`, subscriptionName);
};
