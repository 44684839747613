import urls from "web/constants/urls";

const redirectToBrowserSettingsError = () => {
  if (
    window?.location?.pathname &&
    window.location.pathname !== urls.browserSettingsError
  ) {
    window.location.replace(urls.browserSettingsError);
  }
};

export default redirectToBrowserSettingsError;
