import { useSelector } from "react-redux";

import BankResetAnnouncement from "web/Layout/BankResetAnnouncement";
import Logo from "web/Layout/Common/Logo";
import ErrorBoundary from "web/Layout/ErrorBoundary";
import MessagesTrigger from "web/Layout/MainHeader/Shared/MessagesTrigger";
import SearchAutocomplete from "web/Layout/SearchAutocomplete";

import { useGetCompanyDetailsQuery } from "web/features/company/companyApiSlice";

import CartTrigger from "./CartTrigger";
import Customer from "./Customer";
import MainMenu from "./MainMenu";
import WishlistTrigger from "./WishlistTrigger";
import classes from "./mainHeader.scss";

const DesktopMainHeader = () => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const [headerLogoSrc, headerLogoAlt] = storeConfig
    ? [storeConfig.header_logo_src, storeConfig.header_logo_alt]
    : [null, null];

  const { data: company } = useGetCompanyDetailsQuery();
  const shouldShowMessage = company?.displayMessageOneTimeLossPoints || false;

  return (
    <header className={classes.root}>
      <BankResetAnnouncement shouldShowMessage={shouldShowMessage} />
      <div className={classes.header}>
        <div className={`container ${classes.headerRow}`}>
          <div className={classes.logoBox} data-t1="logo">
            <Logo
              className={classes.logo}
              logoSrc={headerLogoSrc}
              logoAlt={headerLogoAlt}
            />
          </div>
          <ErrorBoundary smallTextSize>
            <SearchAutocomplete className={classes.search} />
          </ErrorBoundary>
          <ul className={classes.list}>
            <li className={classes.itemCustomer}>
              <ErrorBoundary smallTextSize>
                <Customer />
              </ErrorBoundary>
            </li>
            <li className={classes.item}>
              <ErrorBoundary smallTextSize>
                <MessagesTrigger />
              </ErrorBoundary>
            </li>
            <li className={classes.item}>
              <ErrorBoundary smallTextSize>
                <WishlistTrigger />
              </ErrorBoundary>
            </li>
            <li className={classes.item}>
              <ErrorBoundary smallTextSize>
                <CartTrigger />
              </ErrorBoundary>
            </li>
          </ul>
        </div>
      </div>
      <ErrorBoundary smallTextSize>
        <MainMenu />
      </ErrorBoundary>
    </header>
  );
};

export default DesktopMainHeader;
