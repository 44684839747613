import type { StringNumber } from "web/types/Utils";

const getPriceWithCommission = (
  price: StringNumber,
  commission: StringNumber
) => {
  return +price + +price * (+commission / 100);
};

export default getPriceWithCommission;
