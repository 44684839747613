import { oneOf } from "prop-types";
import { FC } from "react";
import { useLocation } from "react-router-dom";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import getFilterParametersUrl from "web/utils/page/category/getFilterParametersUrl";
import getAdditionalFilterParametersUrl from "web/utils/system/url/getAdditionalFilterParametersUrl";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import {
  sortCategoryAnixeOptions,
  sortCategoryOptions,
  sortDirectionName,
  sortName,
} from "web/constants/toolbar";

import type { TupleToUnion } from "web/types/Utils";

import ListingCategory from "./ListingCategory";
import ListingSearch from "./ListingSearch";

interface IListingProps {
  type: string;
}

type SortCategoryOption = TupleToUnion<typeof sortCategoryOptions>;
type SortCategoryAnixeOption = TupleToUnion<typeof sortCategoryAnixeOptions>;

const Listing: FC<IListingProps> = ({ type }) => {
  const { search } = useLocation();
  const nameFromUrl = getSearchParameter({ name: sortName, search });
  const directionFromUrl = getSearchParameter({
    name: sortDirectionName,
    search,
  });

  const categoryNameIsInUrl = ({
    name,
  }: SortCategoryOption | SortCategoryAnixeOption) => name === nameFromUrl;
  const optionDirectionNameIsInUrl = ({
    direction,
  }: SortCategoryOption | SortCategoryAnixeOption) =>
    direction && direction?.some(({ name }) => name === directionFromUrl);

  const currentName =
    nameFromUrl &&
    (sortCategoryOptions?.some(categoryNameIsInUrl) ||
      sortCategoryAnixeOptions?.some(categoryNameIsInUrl))
      ? nameFromUrl
      : sortCategoryOptions[0].name;

  const currentDirection =
    directionFromUrl &&
    (sortCategoryOptions?.some(optionDirectionNameIsInUrl) ||
      sortCategoryAnixeOptions?.some(optionDirectionNameIsInUrl))
      ? directionFromUrl
      : null;

  const activeFilters = getFilterParametersUrl(search) as string[];
  const additionalParameters = getAdditionalFilterParametersUrl(search);

  const listingByType = {
    category: ListingCategory,
    search: ListingSearch,
  };

  const Listing = listingByType[type as keyof typeof listingByType];

  return (
    <ErrorBoundary>
      <Listing
        sortName={currentName as string}
        activeFilters={activeFilters}
        sortDirection={currentDirection as string}
        additionalParameters={additionalParameters as string}
      />
    </ErrorBoundary>
  );
};

Listing.propTypes = {
  type: oneOf(["search", "category"]).isRequired,
};

export default Listing;
