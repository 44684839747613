import { FC } from "react";

import Loading from "web/Layout/Common/Loading";

import classes from "./loader.scss";

interface ILoaderProps {
  isLoading?: boolean;
}

const Loader: FC<ILoaderProps> = ({ isLoading = false }) => {
  return isLoading ? (
    <div className={classes.root}>
      <Loading className={classes.loader} />
    </div>
  ) : null;
};

export default Loader;
