import { motion } from "framer-motion";
import { FC, PropsWithChildren, ReactNode, useCallback } from "react";

import useDropdown from "web/hooks/useDropdown";

import { useAppContext } from "web/context/app";

import FiltersFieldsetHeader from "./Header";
import classes from "./wrapper.scss";

interface IFiltersFieldsetWrapper {
  title: string;
  header?: ReactNode;
}

const variants = {
  open: {
    opacity: 1,
    height: "auto",
    overflow: "visible",
  },
  closed: {
    overflow: "hidden",
    opacity: 0,
    height: 0,
  },
};
const transition = { duration: 0.2 };

const FiltersFieldsetWrapper: FC<
  PropsWithChildren<IFiltersFieldsetWrapper>
> = ({ title, header, children }) => {
  const { isMobile } = useAppContext();

  const config = {
    initialState: !isMobile,
    scopeSelector: null,
    clickOutside: false,
    keydown: false,
  };

  const [isOpen, toggleOpen] = useDropdown(config);

  const toggleHandler = useCallback(() => {
    toggleOpen((status) => !status);
  }, [toggleOpen]);

  return children ? (
    <div className={classes.root}>
      {header && isOpen ? (
        <FiltersFieldsetHeader
          title={title}
          isOpen={isOpen}
          toggle={toggleHandler}
          data-t1="wrapper_header"
        >
          {header}
        </FiltersFieldsetHeader>
      ) : (
        <FiltersFieldsetHeader
          title={title}
          isOpen={isOpen}
          toggle={toggleHandler}
          data-t1="wrapper_header"
        />
      )}
      <motion.div
        className={classes.body}
        initial="open"
        animate={isOpen ? "open" : "closed"}
        transition={transition}
        variants={variants}
      >
        <div className={classes.content}>{children}</div>
      </motion.div>
    </div>
  ) : null;
};

export default FiltersFieldsetWrapper;
