import { FC, PropsWithChildren } from "react";

import classes from "./box.scss";

type headingNumbers = 1 | 2 | 3 | 4 | 5 | 6;

interface IBoxProps {
  headingText?: string;
  headingTag: `h${headingNumbers}`;
  headingClass?: string;
  sectionClass?: string;
}

const Box: FC<PropsWithChildren<IBoxProps>> = ({
  headingText,
  headingTag,
  headingClass,
  children,
  sectionClass,
}) => {
  const HeadingTag = headingTag;

  return (
    <section className={sectionClass}>
      {headingText && (
        <HeadingTag className={headingClass || classes.heading}>
          {headingText}
        </HeadingTag>
      )}
      <div className={classes.box}>{children}</div>
    </section>
  );
};

export default Box;
