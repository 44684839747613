import formatNumber from "web/utils/data/parser/number/formatNumber";

import type { IPaymentSource, ISources } from "web/types/Order";

interface ISubstractCanceledPaymentsArgs {
  sources: IPaymentSource[] | ISources[];
  total: number;
  paid: number;
}

const substractCanceledPayments = ({
  sources,
  total,
  paid,
}: ISubstractCanceledPaymentsArgs) => {
  if (sources.length) {
    let totalReturnPrice = 0;
    let successfullyPaid = 0;
    // eslint-disable-next-line array-callback-return
    sources.map((paymentItem) => {
      if (paymentItem?.status === "success") {
        if (+paymentItem?.value < 0) {
          totalReturnPrice += -1 * parseFloat(paymentItem?.value);
        } else {
          successfullyPaid += parseFloat(paymentItem?.value);
        }
      }
      if (paymentItem?.status === "returned") {
        totalReturnPrice += parseFloat(paymentItem?.value);
      }
    });

    return {
      successfullyPaid: formatNumber(successfullyPaid),
      remainsToPay: formatNumber(total - successfullyPaid),
      returnedPayment: totalReturnPrice,
    };
    /* eslint-disable no-else-return */
  } else {
    return {
      successfullyPaid: formatNumber(paid),
      remainsToPay: formatNumber(total - paid),
      returnedPayment: 0,
    };
  }
};

export default substractCanceledPayments;
