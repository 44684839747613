export default (payload) => {
  const {
    total_count: totalCount,
    cart_id: cartId,
    payment_method: paymentMethods,
    payment,
    ...rest
  } = payload;

  return {
    cartId,
    totalCount,
    paymentMethods,
    paymentMethod:
      payment &&
      typeof payment === "object" &&
      Object.prototype.hasOwnProperty.call(payment, "method")
        ? payment.method
        : payment,
    ...rest,
  };
};
