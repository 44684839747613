import { shape, string } from "prop-types";
import { useMemo } from "react";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import classify from "web/classify";

import defaultClasses from "./codeAttributesList.scss";

const CodeAttributeList = ({
  classes,
  codeDetails,
  producSuperOptions,
  codeAttributeListSize = 2,
}) => {
  const productAttributeData = codeDetails?.parent_product_data
    ?.product_attribute_data
    ? codeDetails?.parent_product_data?.product_attribute_data
    : {};

  const attributesList = useMemo(() => {
    const attributes = [];

    Object.keys(productAttributeData).forEach((key) => {
      if (producSuperOptions.hasOwnProperty(key)) {
        attributes.push([productAttributeData[key], producSuperOptions[key]]);
      }
    });

    return attributes;
  }, [productAttributeData, producSuperOptions]);

  return isArrayHasItems(attributesList) ? (
    <div className={classes.root}>
      {isArrayHasItems(attributesList)
        ? attributesList
            .slice(0, codeAttributeListSize)
            .map((listItem, index) => {
              return (
                <div
                  key={`${index + attributesList.length}`}
                  className={classes.option}
                >
                  <span className={classes.label}>{`${listItem[0]}: `}</span>
                  <span className={classes.name}>{listItem[1]}</span>
                </div>
              );
            })
        : ""}
    </div>
  ) : null;
};

CodeAttributeList.propTypes = {
  classes: shape({
    root: string,
    item: string,
    name: string,
    value: string,
  }).isRequired,
  codeDetails: shape({}),
  producSuperOptions: shape({}),
};

CodeAttributeList.defaultProps = {
  codeDetails: {},
  producSuperOptions: {},
};

export default classify(defaultClasses)(CodeAttributeList);
