import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import SkeletonProductConfigurableOptions from "../SkeletonProductConfigurableOptions";
import SkeletonProductPrices from "../SkeletonProductPrices";
import defaultClasses from "./productAside.scss";

const SkeletonProductAside: FC<PropsWithClasses> = ({ classes = {} }) => {
  return (
    <div className={classes.productAside}>
      <Skeleton className={classes.desktopProductTitle} />
      <Skeleton className={classes.productPlaceInfo} />
      <Skeleton className={classes.productValidityInfo} />
      <SkeletonProductPrices />
      <SkeletonProductConfigurableOptions />
    </div>
  );
};

export default classify<PropsWithClasses>(defaultClasses)(SkeletonProductAside);
