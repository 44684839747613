/* eslint-disable no-param-reassign */
import isArrayHasItems from "../validator/array/isArrayHasItems";

const chunkArray = <T>(array: T[], itemsPerChunk: number) => {
  if (!isArrayHasItems(array) || !itemsPerChunk) return [];

  return array.reduce<T[][]>((result, item, index) => {
    const chunkIndex = Math.floor(index / itemsPerChunk);
    if (!result[chunkIndex]) {
      result[chunkIndex] = [];
    }
    result[chunkIndex].push(item as T);

    return result;
  }, []);
};

export default chunkArray;
