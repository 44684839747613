import { BENEFIT_TYPES, LEGEND } from "web/constants/benefits";

import {
  SubscriptionRelationFreeType,
  SubscriptionRelationType,
} from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

export const getBenefitTypes = (
  relationBenefitTypeConfiguration: SubscriptionRelationType,
  relationBenefitFreeTypeConfiguration: Nullable<SubscriptionRelationFreeType>,
  relationPeriodResignationBlockade: Nullable<number>
) => {
  const benefitTypes = [];
  benefitTypes.push(relationBenefitTypeConfiguration);
  if (
    relationBenefitTypeConfiguration === BENEFIT_TYPES.FREE &&
    relationBenefitFreeTypeConfiguration
  ) {
    benefitTypes.push(relationBenefitFreeTypeConfiguration);
  }
  if (relationPeriodResignationBlockade) benefitTypes.push(BENEFIT_TYPES.GRACE);

  return benefitTypes;
};

export default (
  relationBenefitTypeConfiguration: SubscriptionRelationType,
  relationBenefitFreeTypeConfiguration: Nullable<SubscriptionRelationFreeType>,
  relationPeriodResignationBlockade: Nullable<number>
) => {
  const benefitLegends = LEGEND.filter((def) => {
    const benefitTypes = getBenefitTypes(
      relationBenefitTypeConfiguration,
      relationBenefitFreeTypeConfiguration,
      relationPeriodResignationBlockade
    );
    return benefitTypes.indexOf(def.code) !== -1;
  });

  return benefitLegends;
};
