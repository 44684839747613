import { Button } from "@benefit-systems/common-components";
import { FC, useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import useInternetConnection from "web/hooks/useInternetConnection/useInternetConnection";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import {
  useGetBarCodeQuery,
  useGetCodeSettingsQuery,
  useGetProductCodeQuery,
} from "web/features/codes/codesApiSlice";

import DownloadCodeButton from "./DownloadCodeButton";
import classes from "./codeModal.scss";
import copyCodeToClipboard from "./utils/copyCodeToClipboard";
import printCode from "./utils/printCode";
import printCodeDirectly from "./utils/printCodeDirectly";

interface ICodeModalContainerProps {
  productName: string;
  itemId: number;
  orderPaid: boolean;
  sku: string;
}

const CodeModalContainer: FC<ICodeModalContainerProps> = ({
  productName,
  itemId,
  orderPaid,
  sku,
}) => {
  const dispatch = useDispatch();

  const [isPrinting, setIsPrinting] = useState(false);

  const codeInputRef = useRef<HTMLInputElement>(null);

  const { data: codeSettingsData, isLoading: loading } =
    useGetCodeSettingsQuery(sku);
  const canPrintCode = !!codeSettingsData?.canPrintCode;

  const { data: codeData } = useGetProductCodeQuery(itemId, {
    skip: !orderPaid,
  });
  const { code, isSplitted } = codeData || {};

  const { data: barcode } = useGetBarCodeQuery(itemId, {
    skip: !orderPaid || !canPrintCode,
  });

  const { isDisconnectedWebView } = useInternetConnection();

  const print = useCallback(
    () => printCode({ itemId, setIsPrinting }),
    [itemId]
  );

  // eslint-disable-next-line no-underscore-dangle
  const _code = (isSplitted ? code?.[0] : (code as string)) ?? null;

  const onCopyCodeToClipboardHandler = () =>
    copyCodeToClipboard({ dispatch, codeInputRef, code: _code ?? "" });

  const onPrintCodeDirectlyHandler = () =>
    printCodeDirectly({ productName, code: _code });

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.buttons}>
          <DownloadCodeButton
            hasCode={!!code}
            canPrintCode={canPrintCode}
            isPrinting={isPrinting}
            onPrintHandler={print}
            onPrintCodeDirectlyHandler={onPrintCodeDirectlyHandler}
          />
        </div>
        <div className={classes.title} data-t1="code_modal_title">
          <h2 className={classes.header} data-t1="code_modal_header">
            {__("Twój kod")}
          </h2>
          <h2 className={classes.productName} data-t1="code_modal_product_name">
            {productName}
          </h2>
        </div>
        <div className={classes.box}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {barcode && canPrintCode ? (
                <div className={classes.codeImageContainer}>
                  <img
                    className={classes.codeImage}
                    src={barcode}
                    alt={__("Kod")}
                  />
                </div>
              ) : null}
              {code &&
                (isSplitted && isArrayHasItems(code) ? (
                  code.map((line) => (
                    <div
                      key={line}
                      className={classes.codeLine}
                      data-t1="code_modal_code"
                    >
                      {line}
                    </div>
                  ))
                ) : (
                  <div className={classes.code} data-t1="code_modal_code">
                    {code}
                  </div>
                ))}
              {isDisconnectedWebView && (!barcode || !code) && (
                <p>{__("Brak połączenia z siecią")}</p>
              )}
            </>
          )}

          <form className={classes.copy} data-t1="code_modal_copy">
            {_code && (
              <input
                ref={codeInputRef}
                value={_code}
                className="visually-hidden"
                data-t1="code_modal_input"
                readOnly
              />
            )}
            <Button
              variant="secondary"
              onClick={onCopyCodeToClipboardHandler}
              dataT1="code_modal_button"
              transparent
            >
              {__("Kopiuj kod")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CodeModalContainer;
