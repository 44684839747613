import __ from "web/Layout/Translations";

import classes from "./reload.scss";

const SessionReload = () => {
  return (
    <div className={`container ${classes.root}`}>
      <p className={classes.text}>
        {__(
          "Wystąpił błąd podczas logowania. Odśwież stronę i spróbuj ponownie."
        )}
      </p>
      {window?.location?.reload && (
        <button
          className={classes.button}
          type="button"
          onClick={() => window.location.reload()}
        >
          {__("Odśwież stronę")}
        </button>
      )}
    </div>
  );
};

export default SessionReload;
