import { FC } from "react";
import { Link } from "react-router-dom";

import ImageCommon from "web/Layout/Common/Image";

import styles from "./image.scss";

interface IImageProps {
  link: string;
  name: string;
  image: string;
}

const Image: FC<IImageProps> = ({ link, name, image }) => {
  return (
    <Link to={link} className={styles.root}>
      <ImageCommon className={styles.image} src={image} alt={name} isProduct />
    </Link>
  );
};

export default Image;
