import { CodeItemStatusCode } from "web/hooks/useCodeItemStatus/useCodeItemStatus";

const labels = {
  showCodeLabel: "Zobacz kod",
  codeWaitingLabel: "Oczekuje na kod",
  codeCanceledLabel: "Kod anulowany",
};

const codeLabelMap: Record<
  CodeItemStatusCode,
  (typeof labels)[keyof typeof labels]
> = {
  [CodeItemStatusCode.AVAILABLE]: labels.showCodeLabel,
  [CodeItemStatusCode.CANCELED]: labels.codeCanceledLabel,
  [CodeItemStatusCode.EXPIRED]: labels.showCodeLabel,
  [CodeItemStatusCode.USED]: labels.showCodeLabel,
  [CodeItemStatusCode.WAITING]: labels.codeWaitingLabel,
};

export default codeLabelMap;
