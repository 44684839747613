import { FC } from "react";

import StarStrokeIcon from "web/assets/icons/star-stroke-fill.svg";
import StarIcon from "web/assets/icons/star.svg";

interface IStarsRowProps {
  className: string;
  gap?: number;
  size?: number;
  count: number;
  isBottom?: boolean;
}

const StarsRow: FC<IStarsRowProps> = ({
  className = "",
  gap = 8,
  size = 18,
  count = 5,
  isBottom = false,
}) => {
  if (!count) {
    return null;
  }

  return (
    <>
      {[...Array(Math.ceil(count))].map((star, index) => {
        const key = `${className}-${index}`;
        const styles =
          count - 1 === index ? undefined : { padding: `0 ${gap}px 0 0` };

        return (
          <span
            key={key}
            className={className}
            style={styles}
            data-t2={key}
            data-t1="stars_row"
          >
            {isBottom ? (
              <StarStrokeIcon width={size} />
            ) : (
              <StarIcon width={size} />
            )}
          </span>
        );
      })}
    </>
  );
};

export default StarsRow;
