import { useMemo } from "react";

import mergeLabelsAttributes from "web/utils/page/product/universal/mergeLabelsAttributes";

import { IAttribute } from "web/types/Attributes";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

const useProductLabels = (productLabelList: string[]) => {
  const { data } = useDataCachedAttributes({
    ids: ["product_label"],
  }) as { data: IAttribute[] };

  const attribute = useMemo<IAttribute | Record<string, never>>(() => {
    return data?.length ? data[0] : {};
  }, [JSON.stringify(data)]);

  const labels = useMemo(() => {
    return mergeLabelsAttributes(
      attribute?.attribute_options,
      productLabelList
    );
  }, [attribute]);

  return {
    isNew: labels?.find((label) => label?.code === "new")?.label || null,
    isBestseller:
      labels?.find((label) => label?.code === "bestseller")?.label || null,
  };
};

export default useProductLabels;
