import { FC, useCallback, useEffect, useReducer } from "react";

import Datepicker from "web/Layout/Datepicker";
import __ from "web/Layout/Translations";

import type { Nullable } from "web/types/Utils";

import classes from "./dateRange.scss";

enum DateReducerActionTypes {
  FROM = "from",
  TO = "to",
}

interface IDateReducerAction {
  type: DateReducerActionTypes;
  payload: number | { min: number; value: number };
}

interface IDateReducerState {
  currentFrom: Nullable<number>;
  currentTo: Nullable<number>;
}

const reducer = (state: IDateReducerState, action: IDateReducerAction) => {
  switch (action.type) {
    case DateReducerActionTypes.FROM: {
      return {
        currentFrom: action.payload as number,
        currentTo: state.currentTo,
      };
    }
    case DateReducerActionTypes.TO: {
      const fromProcessed =
        state.currentFrom || (action.payload as { min: number }).min;
      return {
        currentFrom: fromProcessed,
        currentTo: (action.payload as { value: number }).value,
      };
    }
    default:
      return state;
  }
};

interface IDateRangeProps {
  min: number;
  max: Nullable<number>;
  labelFrom: string;
  labelTo: string;
  to: Nullable<number>;
  from: Nullable<number>;
  onChange: (values: number[]) => void;
  dataT1: string;
}

const DateRange: FC<IDateRangeProps> = ({
  min,
  max = null,
  labelFrom,
  labelTo,
  to = null,
  from = null,
  onChange = () => {},
  dataT1 = "",
}) => {
  const [{ currentFrom, currentTo }, dispatch] = useReducer<
    React.Reducer<IDateReducerState, IDateReducerAction>
  >(reducer, {
    currentFrom: from,
    currentTo: to,
  });

  useEffect(() => {
    if (currentFrom || currentTo) onChange([currentFrom!, currentTo!]);
  }, [currentFrom, currentTo]);

  const changeFromHandler = useCallback(
    (value: number) => {
      dispatch({ type: DateReducerActionTypes.FROM, payload: value });
    },
    [dispatch]
  );
  const changeToHandler = useCallback(
    (value: number) => {
      dispatch({ type: DateReducerActionTypes.TO, payload: { min, value } });
    },
    [dispatch]
  );

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Datepicker
          className={classes.date}
          label={labelFrom}
          minDate="today"
          maxDate={max}
          defaultValue={currentFrom}
          onChange={changeFromHandler}
          dataT1={`${dataT1}_from`}
          placeholder={__("Data od..")}
        />
        <Datepicker
          className={classes.date}
          label={labelTo}
          minDate={min}
          defaultValue={currentTo}
          onChange={changeToHandler}
          dataT1={`${dataT1}_to`}
          placeholder={__("Data do..")}
        />
      </div>
    </div>
  );
};

export default DateRange;
