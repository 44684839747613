import { FC } from "react";

import __ from "web/Layout/Translations";

import logotype from "web/assets/logo.svg";

import logout from "web/utils/system/essentials/logout";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./FatalError.scss";

const FatalError: FC<PropsWithClasses> = ({ classes = {} }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.box}>
        <img className={classes.logo} src={logotype} alt="logo" />

        <p className={classes.text}>
          {__("Coś poszło nie tak, spróbuj jeszcze raz.")}
        </p>
        <div className={classes.bottom_wrapper}>
          <button
            type="button"
            className={classes.button}
            onClick={() => logout("", null, "fatal")}
          >
            {__("Wyloguj się")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default classify(defaultClasses)(FatalError);
