/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ElementType, FC, useCallback } from "react";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import { Classes, PropsWithClasses } from "web/types/Common";

import { useAppContext } from "web/context/app";

import Desktop from "./Desktop";
import Mobile from "./Mobile";

const Modal: FC<PropsWithClasses> = ({ className, classes }) => {
  const { modal, isMobile } = useAppContext();
  const component = modal && modal.component;
  const dispatch = modal && modal.dispatch;
  const modalRef = modal?.modalRef;
  const classesModal = modal && modal.classes;
  const ModalComponent = isMobile ? Mobile : Desktop;
  const closeAction = useCallback(() => {
    return (
      typeof dispatch === "function" &&
      dispatch({ type: ModalActionTypes.RESET })
    );
  }, [dispatch]);

  return (
    <div>
      <ModalComponent
        component={component as ElementType}
        className={className}
        classes={(classesModal || classes) as Classes}
        // @ts-ignore
        dispatch={dispatch}
        closeAction={closeAction}
        modalRef={modalRef}
      />
    </div>
  );
};

export default Modal;
