import { FC, useEffect, useState } from "react";

import splitGeolocationData from "web/utils/data/parser/geolocation/splitGeolocationData";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { Nullable } from "web/types/Utils";

import { useGoogleContext } from "web/context/google.context";

import ActiveFiltersItem from "./activeFiltersItem";

interface IActiveFilterGeolocationProps {
  value: string;
  code: string;
  label: string;
}

const ActiveFilterGeolocation: FC<IActiveFilterGeolocationProps> = ({
  value = "",
  code,
  label,
}) => {
  const { geocoderService } = useGoogleContext();
  const [labelValue, setLabelValue] = useState<Nullable<string>>(null);
  const { placeId, distance } = splitGeolocationData(value);
  useEffect(() => {
    if (placeId && geocoderService && geocoderService.geocode) {
      geocoderService.geocode(
        {
          placeId,
        },
        (result, status) => {
          if (
            status === "OK" &&
            isArrayHasItems(result) &&
            result[0] &&
            result[0].formatted_address
          ) {
            setLabelValue(result[0].formatted_address);
          }
        }
      );
    }
  }, [placeId]);
  return labelValue && distance ? (
    <ActiveFiltersItem
      code={code}
      value={value}
      label={label}
      labelValue={`${labelValue} +${distance}`}
    />
  ) : null;
};

export default ActiveFilterGeolocation;
