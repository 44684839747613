// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-root-dSr{display:flex;align-items:center;justify-content:space-between;margin-top:30px;margin-bottom:30px}.header-sort-cbp{width:280px;flex-shrink:0}`, "",{"version":3,"sources":["webpack://./web/Layout/Listing/Common/Header/Desktop/header.scss"],"names":[],"mappings":"AAIuB,iBACrB,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CACA,kBAAA,CAGF,iBACE,WAAA,CACA,aAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n\n.sort {\n  width: 280px;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `header-root-dSr`,
	"sort": `header-sort-cbp`
};
export default ___CSS_LOADER_EXPORT___;
