import datesManipulation from "web/utils/data/parser/dateAndTime/datesManipulation";

import { Nullable } from "web/types/Utils";

interface ISubscriptionBannerConfig {
  isWindowOpen: boolean;
  selectionWindowOpenToDate: string;
  enableBannerAlways?: Nullable<string>;
  bannerForEmployeeWithoutDraft?: Nullable<string>;
  lastConfigurationDone?: string;
}

export default (config: ISubscriptionBannerConfig): boolean => {
  // show banner only to users who never passed configurator - check if configuration has ever been done
  if (
    Number(config.bannerForEmployeeWithoutDraft) &&
    !config.lastConfigurationDone
  ) {
    return true;
  }

  // if banner is always enabled - check if last configuration was done in current window
  if (Number(config.enableBannerAlways)) {
    if (typeof config.lastConfigurationDone !== "string") {
      return config.isWindowOpen;
    }

    const isConfigurationDoneInCurrentMonth =
      datesManipulation.areDatesInTheSameMonth(
        new Date(config.selectionWindowOpenToDate),
        new Date(config.lastConfigurationDone)
      );

    return config.isWindowOpen && !isConfigurationDoneInCurrentMonth;
  }

  return false;
};
