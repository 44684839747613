import { FC } from "react";

import __ from "web/Layout/Translations";

import formatNumber from "web/utils/data/parser/number/formatNumber";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./points.scss";

interface IPointsProps {
  points?: number;
  dataT1?: string;
  hidePointsText?: boolean;
}

const Points: FC<PropsWithClasses<IPointsProps>> = ({
  classes = {},
  points = 0,
  dataT1 = "points_points_text",
  hidePointsText = false,
}) => {
  const pointsText = __("pkt");
  const pointsFormatted = formatNumber(points);
  return pointsFormatted ? (
    <strong
      className={classes.root}
      data-t1={`${dataT1}_value`}
      data-t2={pointsFormatted}
      data-testid="points"
    >
      {pointsFormatted}{" "}
      <sup
        className={classes.text}
        data-t1={`${dataT1}_text`}
        data-t2={pointsText}
      >
        {!hidePointsText && pointsText}
      </sup>
    </strong>
  ) : null;
};

export default classify(defaultClasses)(Points);
