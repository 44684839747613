import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import {
  SkeletonProductCategoriesWrapper,
  SkeletonProductsList,
} from "web/Layout/Common/SkeletonComponents";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import defaultClasses from "./categoriesList.scss";

const SkeletonCategoriesList: FC<PropsWithClasses> = ({ classes = {} }) => {
  const { isMobile } = useAppContext();

  return !isMobile ? (
    <>
      <Skeleton className={classes.desktopHeaderWrapper} />
      <div className={classes.listWrapper}>
        <div className={classes.categoriesWrapper}>
          <SkeletonProductCategoriesWrapper />
        </div>
        <div className={classes.desktopProductsWrapper}>
          <SkeletonProductsList />
        </div>
      </div>
    </>
  ) : (
    <>
      <Skeleton className={classes.mobileHeaderWrapper} />
      <div className={classes.mobileProductsWrapper}>
        <SkeletonProductsList />
      </div>
    </>
  );
};

export default classify<PropsWithClasses>(defaultClasses)(
  SkeletonCategoriesList
);
