import { FC } from "react";

import { useAppContext } from "web/context/app";

import ListingDesktopHeader from "./Desktop";
import { IListingDesktopHeader } from "./Desktop/header";
import ListingMobileHeader from "./Mobile";
import { IListingMobileHeader } from "./Mobile/header";

interface IListingHeaderContainer
  extends IListingDesktopHeader,
    IListingMobileHeader {}

const ListingHeaderContainer: FC<IListingHeaderContainer> = ({
  name,
  count,
  sortOptions,
  isEmpty,
  stats,
  facets,
  facetsHidden,
  attributes,
}) => {
  const { isMobile } = useAppContext();
  return (
    <>
      {isMobile ? (
        <ListingMobileHeader
          name={name}
          count={count}
          stats={stats}
          facets={facets}
          facetsHidden={facetsHidden}
          attributes={attributes}
        />
      ) : (
        <ListingDesktopHeader
          name={name}
          count={count}
          sortOptions={sortOptions}
          isEmpty={isEmpty}
        />
      )}
    </>
  );
};

export default ListingHeaderContainer;
