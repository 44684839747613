// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sectionList-root-lxT{display:block}.sectionList-title-rPi{display:block;font-size:16px;font-weight:700;margin:0 0 20px}.sectionList-list-ITc{display:block;font-size:14px}.sectionList-item-IB9{margin:0 0 10px}.sectionList-item-IB9:last-child{margin:0}.sectionList-link-fDj:hover{text-shadow:.5px 0 0 currentColor}`, "",{"version":3,"sources":["webpack://./web/Layout/Common/SectionList/sectionList.scss"],"names":[],"mappings":"AAIuB,sBACnB,aAAA,CAGJ,uBACI,aAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CAGJ,sBACI,aAAA,CACA,cAAA,CAGJ,sBACI,eAAA,CAEA,iCACI,QAAA,CAKJ,4BACI,iCAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n    display: block;\n}\n\n.title {\n    display: block;\n    font-size: 16px;\n    font-weight: 700;\n    margin: 0 0 20px;\n}\n\n.list {\n    display: block;\n    font-size: 14px;\n}\n\n.item {\n    margin: 0 0 10px;\n\n    &:last-child {\n        margin: 0;\n    }\n}\n\n.link {\n    &:hover {\n        text-shadow: .5px 0 0 currentColor;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `sectionList-root-lxT`,
	"title": `sectionList-title-rPi`,
	"list": `sectionList-list-ITc`,
	"item": `sectionList-item-IB9`,
	"link": `sectionList-link-fDj`
};
export default ___CSS_LOADER_EXPORT___;
