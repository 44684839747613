import { useSelector } from "react-redux";

import logotype from "web/assets/logo.svg";

import getSrcMedia from "web/utils/system/url/getSrcMedia";

import classes from "./header.scss";

const RentableGroupHeader = () => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const [src, alt] =
    storeConfig && storeConfig.header_logo_src
      ? [
          getSrcMedia({
            isLogotype: true,
            storeConfig,
            src: storeConfig.header_logo_src,
          }),
          storeConfig.logo_alt,
        ]
      : [logotype, ""];

  return (
    <header className={classes.root}>
      <div className="container">
        <div className={classes.wrapper}>
          <img
            src={src}
            className={classes.image}
            alt={alt}
            data-t1="logo_image"
          />
        </div>
      </div>
    </header>
  );
};

export default RentableGroupHeader;
