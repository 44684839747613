/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import Product from "web/Layout/ProductCard";
import { LayoutType } from "web/Layout/ProductCard/container";
import __ from "web/Layout/Translations";

import Banner from "web/Components/Common/Banner";
import withScrollToTop from "web/Components/Common/withScrollToTop/withScrollToTop";

import EmptyProductList from "web/assets/icons/emptyProductList.svg";

import usePrevious from "web/hooks/usePrevious/usePrevious";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import setProductsResolver from "web/utils/page/product/universal/setProductsResolver";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import getAdditionalFilterParametersUrl from "web/utils/system/url/getAdditionalFilterParametersUrl";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import { pageLayout } from "web/constants/toolbar";

import { IBanner } from "web/types/Banners";
import { ICampaignGraphics } from "web/types/CampaignGraphics";
import {
  DomesticTourismProductInList,
  IProduct,
  ProductInList,
} from "web/types/Product";
import { ITourismParams } from "web/types/Tourism";

import { useAppContext } from "web/context/app";
import { resetLastVisitedProductId } from "web/features/app/appSlice";

import EditAccommodationData from "../../../../Pages/Product/Mobile/EditAccommodationData/container";
import EmptyAnixeListing from "../../EmptyListing/Anixe/emptyAnixeListing";
import LoadMore from "./LoadMore";
import bannerStyles from "./bannerStyles.scss";
import editAccommodationDataClasses from "./editAccommodationData.scss";
import classes from "./products.scss";
import classesMobileGrid from "./productsMobileGrid.scss";
import classesMobileList from "./productsMobileList.scss";

type PageLayoutOption = (typeof pageLayout.options)[number];

interface IListingProductsProps {
  products: (ProductInList | DomesticTourismProductInList)[];
  count: number;
  campaign: false | ICampaignGraphics;
  activeFilters?: string[];
  campaignCounts: number;
  categoryId: string | number;
  isAnixeTourism?: boolean;
  newProductsId: number[];
}

const ListingProducts: FC<IListingProductsProps> = ({
  products,
  count,
  campaign = false,
  activeFilters,
  campaignCounts,
  categoryId = null,
  isAnixeTourism = false,
  newProductsId,
}) => {
  const dispatch = useDispatch();
  const { lastVisitedProductId } = useSelector((state) => state.app);
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const { search } = useLocation();
  const { isMobile } = useAppContext();
  const { id: storeId } = storeConfig;
  const pageTypeFromUrl = getSearchParameter({ name: pageLayout.name, search });
  const productList = useRef<HTMLUListElement>();
  // eslint-disable-next-line no-unused-vars
  const [lasItemOffsetTop, setLasItemOffsetTop] = useState(0);
  const [listItemLength, setListItemLength] = useState(0);
  const previousProducts = usePrevious(products);

  let pageLayoutCurrent =
    pageTypeFromUrl &&
    pageLayout.options.indexOf(pageTypeFromUrl as PageLayoutOption) !== -1
      ? pageTypeFromUrl
      : pageLayout.options[0];

  let itemClass: string;
  let listClass: string;
  const { grid_per_page: gridPerPage } = storeConfig;
  const prevPage = usePreviousPage({ pageTypeFromUrl }) as {
    pageTypeFromUrl: PageLayoutOption;
  };
  const availableItem = isArrayHasItems(activeFilters)
    ? [...activeFilters].find((f) => {
        return f.includes("availability_filter");
      })
    : false;
  const showAvailableProductsOnly = availableItem
    ? availableItem.includes("21")
    : false;

  if (!pageTypeFromUrl && prevPage && prevPage.pageTypeFromUrl !== null) {
    pageLayoutCurrent = prevPage.pageTypeFromUrl;
  }

  if (pageLayoutCurrent === "list") {
    itemClass = isMobile ? classesMobileList.item : classes.item;
    listClass = isMobile ? classesMobileList.list : classes.list;
  } else {
    itemClass = isMobile ? classesMobileGrid.item : classes.item;
    listClass = isMobile ? classesMobileGrid.list : classes.list;
  }

  const bannersSortingTypes = {
    1: "cyclic",
    2: "chosen",
  };

  const productsWithCampaign = useMemo<
    (ProductInList | DomesticTourismProductInList | ICampaignGraphics)[]
  >(() => {
    if (!campaign || !campaign.on_listing) return products;

    const result: (
      | ProductInList
      | DomesticTourismProductInList
      | ICampaignGraphics
    )[] = isArrayHasItems(products)
      ? products.filter((prod) => {
          return showAvailableProductsOnly &&
            (prod as ProductInList).stock_status === 0
            ? null
            : prod;
        })
      : [];
    const positionNumbers = campaign.on_listing.split(",").map(Number);
    const currentBannersSortingType =
      bannersSortingTypes[campaign.listing_type];

    if (currentBannersSortingType === "chosen") {
      positionNumbers.forEach((position) => {
        return result.splice(position - 1, 0, campaign);
      });
    }

    if (currentBannersSortingType === "cyclic") {
      const isDivisibleBy = (numberValue: number) =>
        (numberValue + 1) % positionNumbers[0] === 0;
      const makeArrayOfNumbers = (length: number) => [...Array(length).keys()];

      const productsCount = result.length;
      const bannersCount =
        makeArrayOfNumbers(productsCount).filter(isDivisibleBy).length;

      const bannersPositions = makeArrayOfNumbers(
        productsCount + bannersCount
      ).filter(isDivisibleBy);

      bannersPositions.forEach((position) => {
        return result.splice(position, 0, campaign);
      });
    }

    return result;
  }, [products, campaign, campaignCounts]);

  const paramsAnixeTourism = useMemo(() => {
    const additionalParameters = getAdditionalFilterParametersUrl(
      search,
      false
    );
    // @ts-ignore
    return additionalParameters ? jsonParse(additionalParameters) : {};
  }, [search]);

  useEffect(() => {
    if (productList.current?.children) {
      const productRefChildren = productList.current.children;
      const pageNumber = Math.ceil(productRefChildren.length / gridPerPage - 1);
      const lastItem = lastVisitedProductId
        ? document.querySelector(`li[data-t2="${lastVisitedProductId}"]`)
        : productRefChildren[gridPerPage * pageNumber];

      const lastItemPosition = (lastItem as HTMLElement)?.offsetTop;
      const listLength = productRefChildren.length;

      setListItemLength(listLength);
      setLasItemOffsetTop(lastItemPosition);

      dispatch(resetLastVisitedProductId());
    }
  }, [gridPerPage]);

  useEffect(() => {
    if (productList.current) {
      window.scrollTo(0, lasItemOffsetTop);
    }
  }, [listItemLength]);

  useEffect(() => {
    let newProducts: typeof productsWithCampaign = [];
    // @ts-ignore
    if (productsWithCampaign?.length > previousProducts?.length) {
      newProducts = productsWithCampaign
        .filter((product) => newProductsId?.includes(+(product as IProduct).id))
        .slice(-12);
    }
    if (!previousProducts) {
      newProducts = productsWithCampaign?.slice(-12);
    }

    if (isArrayHasItems(newProducts)) {
      // @ts-ignore
      setProductsResolver({ products: newProducts, storeId });
    }
  }, [productsWithCampaign]);

  return (
    <>
      {isArrayHasItems(productsWithCampaign) ? (
        <section className={classes.wrapper}>
          <ul
            data-t1="category_product_list"
            // @ts-ignore
            ref={productList}
            className={listClass}
          >
            {productsWithCampaign.map((product, index) => {
              /* eslint-disable */
              const productIndex = (index += 1);
              switch (true) {
                case !product ||
                  // @ts-ignore
                  !((product.name && product.image) || product.campaign_id): {
                  newRelicErrorReport(
                    new Error("Listing - invalid product"),
                    // @ts-ignore
                    `SKU: ${product.sku} - name: ${product.name} - id: ${product.id}`
                  );
                  return null;
                }
                // @ts-ignore
                case !!product.campaign_id: {
                  return (
                    <li
                      className={itemClass}
                      key={index}
                      data-t1="product_campaign_banner"
                      // @ts-ignore
                      data-t2={product.campaign_id}
                    >
                      <Banner
                        banner={product as IBanner}
                        classes={bannerStyles}
                        mobile={isMobile}
                      />
                    </li>
                  );
                }

                default: {
                  return (
                    <li
                      className={itemClass}
                      key={index}
                      data-t1="category_product"
                      // @ts-ignore
                      data-t2={product.id}
                    >
                      {/* @ts-ignore */}
                      <Product
                        // @ts-ignore
                        product={product}
                        data-t1="category_mobile_grid_product"
                        categoryId={
                          categoryId
                            ? (categoryId as string)
                            : `${(product as ProductInList)?.main_category_id}`
                        }
                        params={
                          isAnixeTourism
                            ? (paramsAnixeTourism as ITourismParams)
                            : null
                        }
                        productType={pageLayoutCurrent as LayoutType}
                        positionNumber={productIndex}
                      />
                    </li>
                  );
                }
              }
            })}
          </ul>
          <LoadMore count={count} campaignCounts={campaignCounts} />
        </section>
      ) : (
        <EmptyListing isAnixeTourism={isAnixeTourism} />
      )}
      {isMobile && isAnixeTourism && (
        <EditAccommodationData
          variant="listing"
          classes={editAccommodationDataClasses}
        />
      )}
    </>
  );
};

function usePreviousPage(value: unknown) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

interface IEmptyListingProps {
  isAnixeTourism: boolean;
}

const EmptyListing: FC<IEmptyListingProps> = ({ isAnixeTourism }) =>
  isAnixeTourism ? (
    <EmptyAnixeListing />
  ) : (
    <>
      <div className={classes.empty}>{__("Brak produktów")}</div>
      <EmptyProductList className={classes.noResultsIcon} width={446} />
    </>
  );

export default withScrollToTop(ListingProducts);
