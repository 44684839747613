import { useMemo, useReducer } from "react";
import {
  IDuplicateCardFormState,
  DuplicateCardFormActions,
  DuplicateCardPayload,
} from "web/types/Subscription";

const initialState = {
  reason: { value: "", required: true },
  place: { value: "", required: false },
  userComment: { value: "", required: false },
};

const textActionType = [
  "newFirstName",
  "newLastName",
  "street",
  "city",
  "post-code",
  "houseNumber",
  "flatNumber",
];

const reducer = (
  state: IDuplicateCardFormState,
  action: {
    type: DuplicateCardFormActions | string;
    payload?: DuplicateCardPayload;
  }
): IDuplicateCardFormState => {
  const textFields = textActionType.find((ele) => ele === action.type);

  switch (action.type) {
    case "reason":
      return { ...state, reason: action.payload };
    case "place":
      return { ...state, place: action.payload };
    case "USER_COMMENT":
      return { ...state, userComment: action.payload };
    case textFields:
      return { ...state, [action.type]: action.payload };
    case "CLEAR_NAME_LASTNAME":
      return {
        ...state,
        newFirstName: { value: "", required: false },
        newLastName: { value: "", required: false },
      };
    case "NAME_LASTNAME_REQUIRED":
      return {
        ...state,
        newFirstName: { value: action?.payload?.newFirstName, required: true },
        newLastName: { value: action?.payload?.newLastName, required: true },
      };
    case "PLACE_REQUIRED":
      return {
        ...state,
        place: { value: "", required: true },
      };
    case "CREATE_ADDRESS_DATA":
      return {
        ...state,
        newFirstName: { value: "", required: true },
        newLastName: { value: "", required: true },
        street: { value: "", required: true },
        city: { value: "", required: true },
        "post-code": { value: "", required: true },
        houseNumber: { value: "", required: false },
        flatNumber: { value: "", required: false },
      };
    default:
      throw new Error();
  }
};

const useDupplicateCardForm = () => {
  const [formFieldsState, disptachFormFieldsState] = useReducer(
    reducer,
    initialState
  );

  return useMemo(() => {
    return {
      formFieldsState,
      disptachFormFieldsState,
    };
  }, [formFieldsState, disptachFormFieldsState]);
};

export default useDupplicateCardForm;
