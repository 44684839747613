/* eslint-disable no-param-reassign */
import { IAnixeFiltersRoom } from "web/features/anixeFilters/anixeFiltersSlice";
import { isNumber } from "lodash";
import sendGtmEvent from "./sendGtmEvent";
  
const EMPTY_FILTER = "n/a";

const EVENT_CATEGORY_BY_VARIANT = {
    offer: "Kategoria produktowa",
    listing: "Kategoria produktowa",
    product: "Karta produktu",
};

const formatDate = (date: number | string) => {
    if (!isNumber(date)) {
        return date;
    }
    return new Date(date).toLocaleDateString("pl-PL");
};

interface ISendGtmFilterEventArgs {
    variant: "offer" | "listing" | "product",
    object: string,
    localization: string,
    dateFrom: number,
    dateTo: number,
    tourismRooms: IAnixeFiltersRoom[],
}

async function sendGtmFilterEvent({
    variant,
    object,
    localization,
    dateFrom,
    dateTo,
    tourismRooms
}: ISendGtmFilterEventArgs) {
    const { adults, children } = tourismRooms?.reduce((totals, item) => {
        totals.adults += item.adults;
        totals.children += item.children.length;
        return totals;
    }, { adults: 0, children: 0 }) || { adults: 0, children: 0 };

    const gtmOptions = {
        eventCategory: EVENT_CATEGORY_BY_VARIANT[variant],
        object: object || EMPTY_FILTER,
        localization: localization || EMPTY_FILTER,
        date_from: formatDate(dateFrom) || EMPTY_FILTER,
        date_to: formatDate(dateTo) || EMPTY_FILTER,
        adults: adults ?? EMPTY_FILTER,
        children: children ?? EMPTY_FILTER,
    };

    sendGtmEvent("filtruj", gtmOptions);
}

export default sendGtmFilterEvent;
