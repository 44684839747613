import formatDate from "web/utils/data/parser/dateAndTime/formatDate";

import { dayMilliseconds } from "web/constants/date";

import { Nullable } from "web/types/Utils";

type stringNumberUnion = string | number;

interface ITransformToMilisecondsArg {
  (
    day: stringNumberUnion,
    month: stringNumberUnion,
    year: stringNumberUnion
  ): number;
}

interface IDateElementChangerArg {
  (date: number): stringNumberUnion;
}

interface INewYearCheck {
  (to: string, from: string, year: stringNumberUnion): {
    fullDateFromDate: string;
    fullDateToDate: string;
  };
}

const dateElementChanger: IDateElementChangerArg = (date) => {
  return date <= 9 ? `0${date}` : date;
};

const transformToMiliseconds: ITransformToMilisecondsArg = (
  day,
  month,
  year
) => {
  const date = `${year}-${month}-${day}`;
  return Date.parse(date);
};

const newYearCheck: INewYearCheck = (to, from, year) => {
  if (!to || !from || !year)
    return { fullDateFromDate: from, fullDateToDate: to };
  const datesArray = [to, from];
  datesArray.forEach((ele, index) => {
    const toDateSplited = ele.split(".");
    if (parseInt(toDateSplited[1], 10) > 12) {
      const newMonth = parseInt(toDateSplited[1], 10) - 12;
      datesArray[index] = `${toDateSplited[0]}.${dateElementChanger(
        newMonth
      )}.${+year + 1}`;
    }
  });

  return { fullDateFromDate: datesArray[1], fullDateToDate: datesArray[0] };
};

const getWindowDateFromBenefitGroups = (
  selectionWindowType?: Nullable<string>,
  selectionWindowOpenToDate?: Nullable<string>,
  subscriptionThresholdDay?: Nullable<string>
) => {
  if (
    !selectionWindowType ||
    !selectionWindowOpenToDate ||
    !subscriptionThresholdDay
  )
    return null;

  const dateToOriginal = formatDate(selectionWindowOpenToDate);
  const currentDate = new Date().getTime();

  if (
    subscriptionThresholdDay &&
    dateToOriginal &&
    selectionWindowType === "period"
  ) {
    const periodDay = dateToOriginal.split(".")[0];
    const periodMonth = dateToOriginal.split(".")[1];
    const periodYear = dateToOriginal.split(".")[2];

    const choosedPeriodDay = dateElementChanger(
      Math.min(+periodDay, +subscriptionThresholdDay)
    );

    const periodToDate = `${choosedPeriodDay}.${periodMonth}.${periodYear}`;
    const periodFromDate = `01.${dateElementChanger(
      parseInt(periodMonth, 10) + 1
    )}.${periodYear}`;
    const periodToDateMilisecond = transformToMiliseconds(
      choosedPeriodDay,
      periodMonth,
      periodYear
    );

    if (currentDate > periodToDateMilisecond + dayMilliseconds) {
      const from = `01.${dateElementChanger(
        parseInt(periodMonth, 10) + 2
      )}.${periodYear}`;
      const to = `${choosedPeriodDay}.${dateElementChanger(
        parseInt(periodMonth, 10) + 1
      )}.${periodYear}`;

      const finalResultDates = newYearCheck(to, from, periodYear);

      return finalResultDates;
    }

    const finalResultDates = newYearCheck(
      periodToDate,
      periodFromDate,
      periodYear
    );

    return finalResultDates;
  }

  if (dateToOriginal) {
    const oneTimeToMonth = parseInt(dateToOriginal.split(".")[1], 10);
    const oneTimeToYear = dateToOriginal.split(".")[2];

    const oneTimeFromDate = `01.${dateElementChanger(
      oneTimeToMonth + 1
    )}.${oneTimeToYear}`;

    const finalResultDates = newYearCheck(
      dateToOriginal,
      oneTimeFromDate,
      oneTimeToYear
    );

    return finalResultDates;
  }

  return null;
};

export default getWindowDateFromBenefitGroups;
