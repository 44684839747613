import { useLocation } from "react-router-dom";

import type { Nullable } from "web/types/Utils";

type UrlParamsMap<T extends string> = { [K in T]: Nullable<string> };

const useUrlParams = <T extends string>(...paramNames: T[]) => {
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  return paramNames.reduce<UrlParamsMap<T>>((acc, curr) => {
    return {
      ...acc,
      [curr]: params.get(curr),
    };
  }, {} as UrlParamsMap<T>);
};

export default useUrlParams;
