import { FC } from "react";

import getProductDate from "web/utils/page/product/universal/getProductDate";

import type { Nullable } from "web/types/Utils";

import classes from "./eventValidDate.scss";

interface IEventValidDateProps {
  className?: Nullable<string>;
  date?: Nullable<string>;
  time?: Nullable<string>;
  validDate?: Nullable<string>;
}

const EventValidDate: FC<IEventValidDateProps> = ({
  className = "",
  date = "",
  time = "",
  validDate = "",
}) => {
  const dateClassName = validDate
    ? `${className || ""} ${classes.valid}`
    : className;
  const dateProcessed = getProductDate(date, time, validDate);

  return (
    <div
      data-t1="event_valid_date_date_processed"
      data-t2={date}
      className={dateClassName!}
    >
      {dateProcessed}
    </div>
  );
};

export default EventValidDate;
