import { useEffect, useState } from "react";

import { Nullable } from "web/types/Utils";

let viewportTimeoutId: Nullable<NodeJS.Timeout> = null;
const MOBILE_STATE_CHANGE_TIMEOUT = 100;
const MOBILE_BREAKPOINT =
  process && process.env && (process.env.MOBILE_BREAKPOINT as string);

const getMobileStatus = (windowWidthCurrent: number) => {
  return windowWidthCurrent < +MOBILE_BREAKPOINT;
};
const initialMobile = getMobileStatus(window && window.innerWidth);

const useIsMobile = () => {
  const [mobileState, setMobileState] = useState(initialMobile);
  const setMobileStatus = () => {
    const mobileCurrent = getMobileStatus(window && window.innerWidth);
    setMobileState(mobileCurrent);
  };
  const setMobileStateWithTimeOut = () => {
    if (viewportTimeoutId) {
      clearTimeout(viewportTimeoutId);
    }
    viewportTimeoutId = setTimeout(
      setMobileStatus,
      MOBILE_STATE_CHANGE_TIMEOUT
    );
  };

  useEffect(() => {
    if (window) {
      window.addEventListener("resize", setMobileStateWithTimeOut);
    }

    return () => {
      if (window) {
        window.removeEventListener("resize", setMobileStateWithTimeOut);
      }
    };
  }, []);

  return mobileState;
};

export default useIsMobile;
