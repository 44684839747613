const parseObjectToGetParameters = (paramsSource: Record<string, unknown>) => {
  const stackArray: unknown[] = [];
  const result: unknown[][] = [];

  const parseGetParamsAction = (
    params: Record<string, unknown>,
    deepIndex: number
  ) => {
    return Object.keys(params).forEach((key) => {
      stackArray.splice(deepIndex);
      if (params[key] !== null && typeof params[key] === "object") {
        stackArray.push(key);
        parseGetParamsAction(
          params[key] as Record<string, unknown>,
          deepIndex + 1
        );
      } else if (["string", "number"].indexOf(typeof params[key]) !== -1) {
        result.push([...stackArray, key, params[key]]);
      }
    });
  };

  parseGetParamsAction(paramsSource, 0);

  return result.reduce((accumulator, current, index) => {
    const sign = index ? "&" : "";
    const string = current.reduce(
      (subAccumulator, subCurrent, subIndex, arraySource) => {
        switch (true) {
          case !subIndex: {
            return subCurrent;
          }
          case subIndex === arraySource.length - 1: {
            return `${subAccumulator}=${subCurrent}`;
          }
          default:
            return `${subAccumulator}[${subCurrent}]`;
        }
      }
    );

    return `${accumulator}${sign}${string}`;
  }, "");
};

export default parseObjectToGetParameters;
