import { Button } from "@benefit-systems/common-components";
import { FC, memo, useEffect, useRef, useState } from "react";

import __ from "web/Layout/Translations";

import CheckIcon from "web/assets/icons/check.svg";
import AttentionIcon from "web/assets/icons/triangle-attention.svg";

import { addSkippedMessage } from "web/utils/manageSkippedMessages";

import { IMessage, IPopup } from "web/types/Message";

import { useMarkPopupReadMutation } from "web/features/messages/messagesApiSlice";

import desktopStyles from "./importantMessagesPopup.scss";
import mobileStyles from "./importantMessagesPopupMobile.scss";

interface IImportantMessagesPopup {
  items: IMessage[] | IPopup[];
  closeAction: (arg?: unknown) => void;
  isMobile: boolean;
}

type NotificationStatus = { [type: string]: number | boolean } & { id: number };

const ImportantMessagesPopup: FC<IImportantMessagesPopup> = memo(
  ({ items, closeAction, isMobile }) => {
    const [currentNotification, setCurrentNotification] = useState({
      ...items[0],
      popupIndex: 0,
    });
    const [maxPaginationCount, setMaxPaginationCount] = useState(8);
    const [notificationsStatuses, setNotificationsStatuses] = useState<
      NotificationStatus[]
    >([]);
    const paginationElement = useRef<HTMLDivElement>(null);
    const styles = isMobile ? mobileStyles : desktopStyles;
    const [markPopupRead] = useMarkPopupReadMutation();

    useEffect(() => {
      if (!isMobile || !paginationElement?.current?.offsetWidth) return;

      const handleResize = () => {
        if (paginationElement.current?.offsetWidth) {
          setMaxPaginationCount(
            Math.floor(paginationElement.current.offsetWidth / 45)
          );
        }
      };
      handleResize();

      window.addEventListener("resize", handleResize, false);
      // eslint-disable-next-line consistent-return
      return () => window.removeEventListener("resize", handleResize);
    });

    const Pagination = () => {
      if (items.length === 1) return null;
      return (
        <div className={styles.paginationWrapper} ref={paginationElement}>
          {items?.map(({ id }, index) => {
            let hasMoreItems = false;
            const currentIndex = currentNotification.popupIndex;
            const isCurrent = id === currentNotification.id;

            if (currentIndex + 1 > maxPaginationCount) {
              if (
                (index < currentIndex - maxPaginationCount + 1 &&
                  items.length - 1 !== currentIndex) ||
                index > currentIndex + 1 ||
                (currentIndex - maxPaginationCount - 1 >= index &&
                  items.length - 1 === currentIndex)
              ) {
                return;
              }
            } else if (index > maxPaginationCount) return;

            if (
              currentIndex < index &&
              index >= maxPaginationCount &&
              index + 1 !== items.length
            ) {
              hasMoreItems = true;
            }

            const { isRead, isSkipped } =
              notificationsStatuses.find(({ id: popupId }) => popupId === id) ||
              ({} as { isRead: boolean; isSkipped: boolean });

            // eslint-disable-next-line consistent-return
            return isRead ? (
              <div className={styles.checkedPaginationItem}>
                <CheckIcon className={styles.checkIcon} />
              </div>
            ) : (
              <div
                className={`${isSkipped ? styles.paginationSkippedItem : ""} ${
                  isCurrent ? styles.paginationCurrentItem : ""
                }`}
              >
                <span>
                  {index + 1}
                  {hasMoreItems && "+"}
                </span>
              </div>
            );
          })}
        </div>
      );
    };

    const markMessage = async (type: string) => {
      const nextIndex = currentNotification.popupIndex + 1;
      setNotificationsStatuses([
        ...notificationsStatuses,
        { id: currentNotification.id, [type]: true },
      ]);
      switch (type) {
        case "isRead":
          markPopupRead({ messageId: currentNotification.id });
          break;
        case "isSkipped":
          addSkippedMessage(currentNotification.id);
          break;
        default:
          break;
      }
      if (items[nextIndex]) {
        setCurrentNotification({
          ...items[nextIndex],
          popupIndex: nextIndex,
        });
      } else {
        closeAction();
      }
    };

    return (
      <div className={styles.root}>
        <Pagination />
        <div className={styles.headerWrapper}>
          <AttentionIcon />
          <h3 className={styles.headerTitle}>
            {__(currentNotification.subject)}
          </h3>
        </div>
        <div className={styles.scrollableContentWrapper}>
          <div className={styles.contentWrapper}>
            {currentNotification.message &&
              new DOMParser().parseFromString(
                currentNotification.message,
                "text/html"
              ).documentElement.textContent}
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <Button
            variant="tertiary"
            size="thin"
            className={styles.markReadButton}
            onClick={() => markMessage("isRead")}
          >
            {__("Oznacz jako przeczytane")}
          </Button>
          <Button
            variant="quaternary"
            className={styles.markLaterButton}
            onClick={() => markMessage("isSkipped")}
          >
            {__("Przypomnij później")}
          </Button>
        </div>
      </div>
    );
  }
);

export default ImportantMessagesPopup;
