/* eslint-disable consistent-return */

const convertStringToDate = (date: string, dashedReversed = false) => {
  if (typeof date !== "string" || !date) return;

  const splittedSign = dashedReversed ? "-" : ".";
  const splittedDate = date.split(splittedSign);
  const [dd, mm, yyyy] = splittedDate;

  return new Date(+yyyy, +mm - 1, +dd);
};

export default convertStringToDate;
