/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable react/prop-types */
import { QueryHookOptions, useQuery } from "@apollo/client";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";

import msCategoryPaths from "web/queries/default/categoryPaths.graphql";
import categoryCountsQuery from "web/queries/ms/categoryCounts.graphql";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { ICategory } from "web/types/Category";
import type { ICategoryCount } from "web/types/CategoryCount";
import { Nullable, Optional } from "web/types/Utils";

import {
  closeNavigation,
  setNavigationCategoryId,
} from "web/features/app/appSlice";
import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";

import WebViewList from "./webViewList";

const useCategoryCount = (token: string, storeId: number, ids?: number[]) => {
  const { data, error, loading } = useQuery<{
    msCategoryCounts: { edges: { node: ICategoryCount }[] };
  }>(categoryCountsQuery, {
    variables: {
      ids,
      token,
      storeId,
    },
    context: {
      clientName: "ms",
    },
    fetchPolicy: "cache-and-network",
    skip: !ids,
  });

  if (!ids) {
    return {};
  }

  const transformedData = data?.msCategoryCounts?.edges;
  return { data: transformedData, error, loading };
};

interface IPathsData {
  ids: number[];
  tree: string[];
}

interface INavigationListContainerWithCategoryDataProps {
  paths: string;
  rootCategoryId: number;
  navigationCategoryId: Nullable<number>;
  setNavigationCategoryIdAction: typeof setNavigationCategoryId;
  closeNavigationAction: typeof closeNavigation;
  storeId: number;
  token: string;
}

const NavigationListContainerWithCategoryData: FC<
  INavigationListContainerWithCategoryDataProps
> = ({
  paths,
  rootCategoryId,
  navigationCategoryId,
  setNavigationCategoryIdAction,
  closeNavigationAction,
  storeId,
  token,
}) => {
  const { tree, ids } = useMemo(() => {
    const pathsParsed = jsonParse<string[]>(paths);
    const pathsData = isArrayHasItems(pathsParsed)
      ? pathsParsed.reduce<IPathsData>(
          (result, path) => {
            if (typeof path === "string") {
              const rootCategoryIdString = `/${rootCategoryId}/`;
              const rootCategoryIdIndex = path.indexOf(rootCategoryIdString);
              if (rootCategoryIdIndex !== -1) {
                const pathProcessed = path.slice(
                  rootCategoryIdIndex + rootCategoryIdString.length
                );
                const idsCurrent = pathProcessed?.split("/").map((el) => +el);

                return {
                  ids: Array.from(new Set([...result.ids, ...idsCurrent])),
                  tree: [...result.tree, pathProcessed],
                };
              }
            }
            return result;
          },
          { ids: [], tree: [] }
        )
      : [];

    return pathsData as Optional<IPathsData>;
  }, [paths]);
  const {
    loading: dataCachedCategoriesLoading,
    error: dataCachedCategoriesError,
    data,
  } = useDataCachedCategories({
    // @ts-ignore
    ids,
    skip: !token,
  }) as { loading: boolean; error: unknown; data: ICategory[] };

  const {
    data: categoriesCountData,
    loading: categoriesCountLoading,
    error: categoriesCountError,
  } = useCategoryCount(token, storeId, ids);

  const loading = dataCachedCategoriesLoading || categoriesCountLoading;
  const error = dataCachedCategoriesError || categoriesCountError;
  let categoryIdsAmounts: ICategoryCount[] = [];
  if (categoriesCountData?.length) {
    categoryIdsAmounts = categoriesCountData.map(({ node: item }) => {
      const element = {
        id: item.id,
        product_count: item.product_count,
      };
      return element;
    });
  }

  switch (true) {
    case !token:
      return null;
    case !!loading: {
      return <Loading />;
    }
    case !!error: {
      return <ErrorComponent />;
    }
    case !!data.length: {
      return (
        <WebViewList
          rootCategoryId={rootCategoryId}
          setCategory={setNavigationCategoryIdAction}
          startCategoryId={navigationCategoryId}
          categoriesTree={tree}
          categoriesData={data}
          categoriesSizes={categoryIdsAmounts}
          closeNavigationAction={closeNavigationAction}
        />)
    }
    default: {
      return null;
    }
  }
};

const NavigationListContainer = () => {
  const {
    root_category_id: rootCategoryId,
    token,
    id: storeId,
  } = useSelector((state) => state.app.storeConfig);

  const { navigationCategoryId } = useSelector((state) => state.app);

  const optionsQuery: QueryHookOptions = {
    variables: {
      token,
      storeId: +storeId,
    },
    context: {
      clientName: "magento",
    },
    fetchPolicy: "cache-and-network",
  };

  const { data, error, loading } = useQuery<{ msCategoryPaths: string }>(
    msCategoryPaths,
    optionsQuery
  );

  switch (true) {
    case !!loading && (!data || !Object.keys(data).length): {
      return <Loading />;
    }
    case !!error: {
      newRelicErrorReport(
        error,
        "web-app/web/Layout/NavigationPanel/Navigation/List/container.js - 164"
      );
      return <ErrorComponent />;
    }
    default: {
      const paths = data ? data.msCategoryPaths : "";
      return (
        <NavigationListContainerWithCategoryData
          paths={paths}
          rootCategoryId={rootCategoryId}
          navigationCategoryId={navigationCategoryId}
          setNavigationCategoryIdAction={setNavigationCategoryId}
          closeNavigationAction={closeNavigation}
          token={token}
          storeId={storeId}
        />
      );
    }
  }
};

export default NavigationListContainer;
