import { FC, memo, useCallback } from "react";
import { Link } from "react-router-dom";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import __ from "web/Layout/Translations";

import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";

interface ICategoryLinkProps {
  className: string;
  id: string | number;
  dataT1?: string;
  onClick?: () => unknown;
}

const CategoryLink: FC<ICategoryLinkProps> = memo(
  ({ className = "", id, dataT1 = "", onClick = () => {} }) => {
    const { loading, error, data } = useDataCachedCategories({
      ids: [+id as number],
    });
    const onClickHandler = useCallback(() => {
      if (typeof onClick === "function") {
        onClick();
      }
    }, [onClick]);

    switch (true) {
      case !!loading && (!data || !Object.keys(data).length): {
        return (
          <span className={className} data-t1="category_link_loading">
            {__("Ładowanie")}
          </span>
        );
      }
      case !!error: {
        console.error(error);
        return <ErrorComponent />;
      }
      default: {
        const category = data && data.length && data[0] ? data[0] : null;

        if (!category) {
          return null;
        }

        return category.request_path ? (
          <Link
            className={className}
            to={`/${category.request_path}`}
            data-t1={dataT1}
            data-t2={id}
            onClick={onClickHandler}
          >
            {__(category.name)}
          </Link>
        ) : (
          <span className={className} data-t1="category_link_name">
            {__(category.name)}
          </span>
        );
      }
    }
  }
);

export default CategoryLink;
