import { FC } from "react";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import mergeLabelsAttributes from "web/utils/page/product/universal/mergeLabelsAttributes";

import { IAttribute } from "web/types/Attributes";
import { IConfigurableOptionsAttribute } from "web/types/ProductOptions";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

import Configurable from "./configurable";

type ProductCardTopConfigurableContainerProps =
  Partial<IConfigurableOptionsAttribute>;

const ProductCardTopConfigurableContainer: FC<
  ProductCardTopConfigurableContainerProps
> = ({ code, options = [] }) => {
  const { data, error, loading } = useDataCachedAttributes({
    ids: [code!],
  }) as { data: IAttribute[]; error: unknown; loading: boolean };

  if (error) {
    return <ErrorComponent />;
  }
  if (loading) {
    return <Loading />;
  }
  if (data && data.length) {
    const attribute = isArrayHasItems(data) ? data[0] : ({} as IAttribute);
    const attributeOptions =
      attribute && attribute.attribute_options
        ? attribute.attribute_options
        : [];
    const ids = isArrayHasItems(options)
      ? options.slice(0, 6).map((option) => option.id)
      : [];
    const optionsWithLabels = mergeLabelsAttributes(attributeOptions, ids);

    return optionsWithLabels ? (
      <Configurable options={optionsWithLabels} />
    ) : null;
  }

  return null;
};

export default ProductCardTopConfigurableContainer;
