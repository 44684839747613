/* eslint-disable import/prefer-default-export */
import { nativeAppName } from "./native";

// @TODO This is temporary object, so these values can be used in non-TS files. Don't use it if you can import enum from WebViewInfo wrapper
export const WebViewMessageTypes = {
  LOGIN: "login",
  CHANGE_PIN: "change_pin",
  TOGGLE_BIOMETRY: "toggle_biometry",
  CONVERT_TO_PDF: "convert_to_pdf",
  REQUEST_REVIEW: "request_review",
};

export const WebViewReviewUrls = ["/checkout_success"];
export const webViewScreenPath = `${nativeAppName}://webview`;
