import { useMemo } from "react";

import type { IRentableGroupCalculatorValues } from "../../RentableGroupCalculator/rentableGroupCalculatorContainer";
import type { IRentableGroupCalculatorAgreementsListField } from "../rentableGroupCalculatorAgreements";
import getCurrentCheckboxDependency from "./getCurrentCheckboxDependency";

const useAgreementsFiltered = (
  values: IRentableGroupCalculatorValues,
  agreementsListFields: IRentableGroupCalculatorAgreementsListField[]
) =>
  useMemo(() => {
    const currentDependency = getCurrentCheckboxDependency(
      values["fill-calculator-checkbox"],
      values["financing-checkbox"]
    );

    return agreementsListFields.filter(({ dependencies }) =>
      dependencies.includes(currentDependency)
    );
  }, [
    agreementsListFields,
    values["fill-calculator-checkbox"],
    values["financing-checkbox"],
  ]);

export default useAgreementsFiltered;
