import { FC } from "react";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import __ from "web/Layout/Translations";

import { geolocation } from "web/constants/filters";

import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";

import ActiveFilterGeolocation from "./activeFilterGeolocation";
import ActiveFiltersItem from "./activeFiltersItem";

interface IContainerWithCategoryDataProps {
  value: string;
  code: string;
  label: string;
}

const ContainerWithCategoryData: FC<IContainerWithCategoryDataProps> = ({
  value,
  code,
  label = "",
}) => {
  const { loading, error, data } = useDataCachedCategories({
    ids: [+value],
  });

  switch (true) {
    case !!loading && (!data || !Object.keys(data).length): {
      return <div key="filters-loading">{__("Ładowanie")}</div>;
    }
    case !!error: {
      return <ErrorComponent />;
    }
    default: {
      const categoryName = data && data[0] ? data[0].name : value;
      return (
        <ActiveFiltersItem
          code={code}
          value={value}
          labelValue={categoryName}
          label={label}
        />
      );
    }
  }
};

interface IActiveFiltersItemContainerProps {
  value?: string;
  values?: string[];
  code: string;
  label?: string;
  labelValue?: string;
}

const ActiveFiltersItemContainer: FC<IActiveFiltersItemContainerProps> = ({
  value = "",
  values = null,
  code,
  label = "",
  labelValue = "",
}) => {
  switch (code) {
    case "category_ids": {
      return (
        <ContainerWithCategoryData label={label} code={code} value={value} />
      );
    }
    case geolocation.attribute_code: {
      return (
        <ActiveFilterGeolocation code={code} value={value} label={label} />
      );
    }
    default: {
      return (
        <ActiveFiltersItem
          code={code}
          value={value}
          values={values}
          label={label}
          labelValue={labelValue}
        />
      );
    }
  }
};

export default ActiveFiltersItemContainer;
