import { ISubscriptionFilterBenefitGroups } from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

const getBenefitKeys = (data: Nullable<ISubscriptionFilterBenefitGroups>) => {
  if (!data || !data.benefitGroups) {
    return [];
  }
  const keys = Object.keys(data.benefitGroups);
  return keys;
};

export default getBenefitKeys;
