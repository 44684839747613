import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import GtmEvent from "web/Layout/Gtm/GtmEvent";

import useGetProductsGtmsOptions from "web/hooks/useGetProductsGtmsOptions";

import checkNewIdsUniq from "web/utils/system/GTM/checkNewIdsUnique";
import gtmGetOptions from "web/utils/system/GTM/gtmGetOptions";

import { ProductInList } from "web/types/Product";

interface IProductsGtmsProps {
  data?: ProductInList[];
  previousValue: number;
  currency?: string;
  categoriesPath?: string;
  cgGroup1Path?: string;
  pageSizeDefault: number;
  withVirtualPageView?: boolean;
  verifyPrevIds?: boolean;
  verifyCategoryPath?: boolean;
  searchPhrase?: string;
}

const ProductsGtms: FC<IProductsGtmsProps> = ({
  data = [],
  previousValue,
  currency = "",
  categoriesPath = "",
  cgGroup1Path = "",
  pageSizeDefault,
  withVirtualPageView = true,
  verifyPrevIds = true,
  verifyCategoryPath = true,
  searchPhrase = "",
}) => {
  const location = useLocation();
  const url = location?.pathname + location?.search;
  const gtms = useSelector((state) => state.gtm);
  const { promoItems } = gtms;

  const {
    finalProductOptions,
    isReady,
    lastProductImpressionEvent,
    lastVirtualPageViewEvent,
    listingPromotion,
  } = useGetProductsGtmsOptions(data, gtms, location);

  const [gtmVirtualPageViewOptions, gtmEnhancedEcommOptions] = useMemo(() => {
    return gtmGetOptions({
      data,
      currency,
      categories: categoriesPath,
      productsOptionsList: finalProductOptions,
      previousValue,
      lastProductImpressionEvent,
      isReady,
      lastProductPosition: previousValue,
      pageSizeDefault,
      cgGroup1Path,
      searchPhrase,
      location,
      promoItems,
      listingPromotion,
    });
  }, [
    isReady,
    previousValue,
    currency,
    categoriesPath,
    data,
    JSON.stringify(finalProductOptions),
    JSON.stringify(lastProductImpressionEvent),
    pageSizeDefault,
    cgGroup1Path,
    location,
    promoItems,
    listingPromotion,
  ]);

  const isAllGtmNewIdsUniq = verifyPrevIds
    ? checkNewIdsUniq(
        lastProductImpressionEvent,
        gtmEnhancedEcommOptions,
        lastVirtualPageViewEvent
      )
    : true;

  const isCorrectCategoryPath =
    gtmVirtualPageViewOptions?.cgroup2 &&
    gtmEnhancedEcommOptions?.ecommerce?.impressions?.[0]?.list?.includes(
      gtmVirtualPageViewOptions?.cgroup2
    );

  return (
    <>
      {withVirtualPageView &&
        gtmVirtualPageViewOptions?.cgroup2 &&
        (url !== lastVirtualPageViewEvent?.url ||
          lastVirtualPageViewEvent?.cgroup1 !==
            gtmVirtualPageViewOptions?.cgroup1 ||
          lastVirtualPageViewEvent?.cgroup2 !==
            gtmVirtualPageViewOptions?.cgroup2) && (
          <GtmEvent options={gtmVirtualPageViewOptions} withUrl />
        )}
      {isReady &&
        (gtmEnhancedEcommOptions?.ecommerce?.impressions?.length || 0) > 0 &&
        (isCorrectCategoryPath || !verifyCategoryPath) &&
        isAllGtmNewIdsUniq && (
          <GtmEvent
            eventName="productImpression"
            options={gtmEnhancedEcommOptions}
          />
        )}
    </>
  );
};

export default ProductsGtms;
