import Language from "web/types/Language";

interface IGetQueryStringParameters {
  language?: Language;
  value?: string[];
}

const getQueryString = (
  parameters: IGetQueryStringParameters = {},
  joinArray = false
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = { ...parameters };
  if (joinArray) {
    Object.keys(params).forEach((key) => {
      if (Array.isArray(params[key as keyof IGetQueryStringParameters])) {
        params[key] = params[key].join(",");
      }
    });
  }
  const result = `?${Object.keys(params)
    .map((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        if (joinArray) {
          return `${key}=${value.join(",")}`;
        }
        return value
          .map((el) => `${key}[]=${el}`.replaceAll("&", "+38+"))
          .join("&");
      }
      return `${key}=${value}`;
    })
    .join("&")}`;

  return encodeURI(result).replaceAll("+38+", "%26");
};

export default getQueryString;
