import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import { RestAPIS } from "web/constants/restUrls";

import { request } from "web/api";
import { setNotificationError } from "web/features/app/appSlice";

import ActivityItem, { IActivityItemProps } from "../activityItem";
import classes from "./barcodeDownloaderItem.scss";

export interface IBarcodeDownloaderActivityItem extends IActivityItemProps {
  benefitUUID: string;
  generateUrl: string;
}

export const onGeneratePDFHandler = async (
  url: string,
  setLoading: (arg: boolean) => void,
  dispatch: Dispatch
) => {
  const finalPdfGenerationUrl = `${RestAPIS.SUB_MANAGEMENT_API}${url}`;

  try {
    setLoading(true);
    const response = await request(finalPdfGenerationUrl, {
      method: IApiRequestMethods.PUT,
    });

    const { url: pdfUrl } = response as { url: string };

    if (pdfUrl) window.open(pdfUrl);
  } catch (err: any) {
    if (err) {
      const errorMessage = __(
        "Nie można wygenerować dokumentu ponieważ konfiguracja na to nie pozwala. Skontaktuj się ze swoim pracodawcą."
      );
      dispatch(
        setNotificationError({
          message: errorMessage,
          timeout: 5000,
        })
      );
    }
  }

  setLoading(false);
};

const BarcodeDownloaderActivityItem: FC<IBarcodeDownloaderActivityItem> = ({
  description,
  benefitUUID,
  generateUrl,
  name,
  receiverName,
  receiverLastname,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  return (
    <ActivityItem
      key={`${benefitUUID}-email-${generateUrl}`}
      name={name}
      description={description}
      onClick={() => onGeneratePDFHandler(generateUrl, setLoading, dispatch)}
      disabled={loading}
      receiverName={receiverName}
      receiverLastname={receiverLastname}
      loading
    >
      {loading ? (
        <Loading
          classes={{ elementVariant: classes["loading-element--small"] }}
        />
      ) : (
        __("Pobierz")
      )}
    </ActivityItem>
  );
};

export default BarcodeDownloaderActivityItem;
