import { useMemo, useReducer, useRef } from "react";

import type { Nullable } from "web/types/Utils";

import {
  AddModalConfig,
  IModalState,
  ModalAction,
  ModalActionTypes,
} from "./useModalTypes";

const initialState: IModalState = {
  component: null,
  menuIndex: null,
  classes: null,
  withoutBackdrop: false,
};
const reducer = (state: IModalState, action: ModalAction): IModalState => {
  switch (action?.type) {
    case ModalActionTypes.ADD_MODAL:
      return {
        component: action.modal,
        menuIndex: null,
        classes: action.classes,
        withoutBackdrop: action.withoutBackdrop,
      };
    case ModalActionTypes.SET_MENU_INDEX:
      return {
        component: null,
        menuIndex: action.index,
        classes: null,
      };
    case ModalActionTypes.RESET:
      return initialState;
    default:
      return initialState;
  }
};

const useModal = () => {
  const [{ component, menuIndex, classes, withoutBackdrop }, dispatch] =
    useReducer(reducer, initialState);

  const modalRef = useRef<Nullable<HTMLDivElement>>(null);

  return useMemo(() => {
    return {
      component,
      modalRef,
      menuIndex,
      dispatch,
      classes,
      withoutBackdrop,
      closeModal: () => dispatch({ type: ModalActionTypes.RESET }),
      openModal: (config: AddModalConfig) =>
        dispatch({ ...config, type: ModalActionTypes.ADD_MODAL }),
    };
  }, [component, menuIndex, classes, withoutBackdrop, dispatch]);
};

export default useModal;
