import { FC } from "react";
import { Link } from "react-router-dom";

import __ from "web/Layout/Translations";

import ToolTip from "web/Components/Common/ToolTip";

import setBenefitGroupTypeParam from "web/utils/page/product/subscription/setBenefitGroupTypeParam";

import urls from "web/constants/urls";

import { ChoiceButtonType } from "web/types/Subscription";

import classes from "./subscriptionLink.scss";

interface ISubscriptionLinkProps {
  type: ChoiceButtonType;
  disabled?: boolean;
  group?: number;
}

const SubscriptionLink: FC<ISubscriptionLinkProps> = ({
  type,
  disabled = false,
  group = 1,
}) => {
  const url =
    type === ChoiceButtonType.CHANGE_SUBSCRIPTIONS
      ? `${urls.checkoutSubscriptionConfiguration}${setBenefitGroupTypeParam(
          group
        )}`
      : urls.customerSubscriptions;
  const label =
    type === ChoiceButtonType.CHANGE_SUBSCRIPTIONS
      ? __("Wybierz / zmień abonamenty")
      : __("Moje abonamenty");
  const buttonClasses = `${classes.button} ${
    type === ChoiceButtonType.CHANGE_SUBSCRIPTIONS
      ? classes.dark
      : classes.light
  }`;

  return (
    <div className={classes.root}>
      {disabled ? (
        <>
          <button
            className={buttonClasses}
            type="button"
            data-t1="subscriptionSelectionLink"
            data-t2={`group${group}`}
            disabled
          >
            {label}
          </button>
          <ToolTip
            customStyles={classes}
            message={__("Minął termin wyboru abonamentów")}
            variant="top"
          />
        </>
      ) : (
        <Link
          className={buttonClasses}
          to={url}
          data-t1="subscriptionSelectionLink"
          data-t2={`group${group}`}
        >
          {label}
        </Link>
      )}
    </div>
  );
};

export default SubscriptionLink;
