import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import checkIfTimePassed from "web/utils/data/validator/dateAndTime/checkIfTimePassed";

import { dayMilliseconds } from "web/constants/date";

import {
  FormType,
  ISubscriptionReceiverForm,
  SubscriptionFormFieldType,
  SubscriptionFormReceiver,
} from "web/types/Subscription";

import { isAutoFilled } from "./getFormMode";

/**
 * Function determining given form as required. It results in global validation error on proceeding to configurator step 3 if form isn't filled in.
 * @param { object } form - form configuration & data from API.
 * @param { string } receiverType - whom data is saved in the form - employee's or others'.
 */
export default (
  form: ISubscriptionReceiverForm,
  receiverType: SubscriptionFormReceiver,
  external: boolean
) => {
  // required form is understood as neeeded to be filled in the current configuration process
  let required = false;
  let hasRequiredFields = false;
  let validateUniquePesel = null;
  const {
    awaitsUpdate,
    completed,
    emailSentAt,
    fields,
    ignoreValidate,
    sendCounter,
    scope,
    submitted,
    type,
    receiverEmail,
  } = form;
  const autoFilled = isAutoFilled(receiverType, scope);
  const wasEverFilledIn = completed || submitted || receiverEmail;
  const needsResend = !!(
    external &&
    awaitsUpdate &&
    (sendCounter === 0 ||
      (sendCounter &&
        emailSentAt &&
        checkIfTimePassed(emailSentAt, dayMilliseconds)))
  );

  switch (type) {
    case FormType.FORM:
      // In simple forms all fields are required by default
      // Already completed aren't validated; autofilled forms are considered as completed
      required = autoFilled ? false : !ignoreValidate && !wasEverFilledIn;
      validateUniquePesel =
        fields.find(
          (field) => field.type === SubscriptionFormFieldType.PESEL
        ) || null;

      return { required, validateUniquePesel };
    case FormType.ACTIVE_FORM:
      // Check if active form has at least one required field
      hasRequiredFields = isArrayHasItems(fields)
        ? fields.some((field) => field.required)
        : false;

      // - ignoreValidate - particular imported forms have switched off validation, so they cannot be validated
      // - completed - form was completed previously
      required =
        (hasRequiredFields && !ignoreValidate && !wasEverFilledIn) ||
        (!external && awaitsUpdate) ||
        needsResend;
      return { required, validateUniquePesel };
    default:
      return { required, validateUniquePesel };
  }
};
