import { Button } from "@benefit-systems/common-components";
import { FC, memo, useCallback } from "react";
import { Link } from "react-router-dom";

import __ from "web/Layout/Translations";

import CloseIcon from "web/assets/icons/close.svg";
import EnvelopeIcon from "web/assets/icons/envelope-closed.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getMessageSenderLabelType from "web/utils/page/customer/getMessageSenderLabelType";

import senderTypes from "web/constants/messageTypes";
import urls from "web/constants/urls";

import { IMessage } from "web/types/Message";

import { useAppContext } from "web/context/app";

import desktopStyles from "./dropdown.scss";
import mobileStyles from "./dropdownMobile.scss";

interface IMessagesTriggerProps {
  items: IMessage[];
  closeAction: (arg?: unknown) => void;
}

type SenderTypes = typeof senderTypes;

const MessagesTriggerDropdown: FC<IMessagesTriggerProps> = memo(
  ({ items, closeAction }) => {
    const { isMobile } = useAppContext();
    const getSenderIcon = useCallback(
      (type: SenderTypes[keyof SenderTypes]) => {
        switch (true) {
          case type === senderTypes.MB:
            return <span className={styles.iconLetter}>MB</span>;
          case type === senderTypes.HR:
            return <span className={styles.iconLetter}>HR</span>;
          default:
            return <EnvelopeIcon width={28} />;
        }
      },
      [items]
    );

    const styles = isMobile ? mobileStyles : desktopStyles;
    return (
      <div className={styles.root}>
        {isArrayHasItems(items) ? (
          <>
            <div className={styles.headerWrapper}>
              <h3 className={styles.header} data-t1="messages_dropdown_title">
                {__("Masz nieprzeczytane wiadomości")}
              </h3>
              {isMobile ? (
                <button
                  type="button"
                  className={styles.closeButton}
                  onClick={closeAction}
                >
                  <CloseIcon className={styles.closeIcon} />
                </button>
              ) : null}
            </div>
            <ul className={styles.list}>
              {items?.map(({ id, subject, senderType, message }) => {
                const messageText = new DOMParser().parseFromString(
                  message,
                  "text/html"
                ).documentElement.textContent;
                return (
                  <li key={id}>
                    <Link
                      to={urls.customerMessages}
                      className={styles.item}
                      data-t1="messages_dropdown_link"
                    >
                      <div className={styles.iconWrapper}>
                        {getSenderIcon(senderType)}
                      </div>
                      <strong
                        className={styles.sender}
                        data-t1="messages_dropdown_sender"
                      >
                        {getMessageSenderLabelType(senderType)}
                      </strong>
                      <span className={styles.message}>
                        <strong data-t1="messages_dropdown_subject">
                          {subject}
                        </strong>
                        <p
                          data-t1="messages_dropdown_message"
                          className={styles.description}
                        >
                          {messageText}
                        </p>
                      </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </>
        ) : null}
        <div className={styles.buttonWrapper}>
          <Button to={urls.customerMessages}>
            {__("Zobacz wszystkie wiadomości")}
          </Button>
        </div>
      </div>
    );
  }
);

export default MessagesTriggerDropdown;
