import { number } from "prop-types";
import { FC } from "react";

import classes from "./letterCounter.scss";

interface ILetterCounterProps {
  currentCount: number;
  maxCount: number;
}

const LetterCounter: FC<ILetterCounterProps> = ({ currentCount, maxCount }) => {
  return (
    <span className={classes.counter}>
      {currentCount}/{maxCount}
    </span>
  );
};

LetterCounter.propTypes = {
  currentCount: number.isRequired,
  maxCount: number.isRequired,
};

export default LetterCounter;
