import { FC } from "react";

import Categories from "../Categories";
import { CommonOptions, TextOptions } from "../fieldset";

interface ITextProps {
  code: string;
  options: TextOptions[];
}

const Text: FC<ITextProps> = ({ code, options }) => {
  switch (code) {
    case "category_ids": {
      return <Categories options={options as CommonOptions[]} code={code} />;
    }
    default: {
      return null;
    }
  }
};

export default Text;
