/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import DomesticTourismForm, {
  DOMESTIC_TOURISM_FORM_VARIANTS,
} from "web/Pages/Tourism/DomesticTourismForm";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import type { Filter } from "web/utils/page/category/mergeFacetsStatsAttributes";

import Fieldset from "../Shared/Fieldset";
import { FieldType } from "../Shared/Fieldset/fieldset";
import ActiveFiltersComponent from "./ActiveFilters";
import Map from "./Map";
import Reset from "./Reset";
import classes from "./filters.scss";

interface IFiltersProps {
  filters: Filter[];
  prices?: Record<string, string>;
  facetsHidden: string[];
  isAnixeTourism?: boolean;
  withShiftedActiveFilters?: boolean;
  ids: number[];
}

const Filters: FC<IFiltersProps> = ({
  filters,
  prices = null,
  facetsHidden = [],
  isAnixeTourism = false,
  withShiftedActiveFilters = false,
  ids,
}) => {
  const children =
    filters?.map((filter) => (
      <Fieldset
        key={filter.attribute_code}
        title={filter.attribute_label}
        code={filter.attribute_code}
        type={filter.input_type as FieldType}
        // @ts-ignore
        options={filter.attribute_options}
      />
    )) || [];
  children.splice(
    withShiftedActiveFilters ? 1 : 0,
    0,
    <ActiveFiltersComponent facetsHidden={facetsHidden} key="active-filters" />
  );

  return (
    <>
      {isAnixeTourism && (
        <DomesticTourismForm
          variant={DOMESTIC_TOURISM_FORM_VARIANTS.LISTING as "listing"}
        />
      )}

      {isArrayHasItems(filters) && (
        <div className={classes.marginTop}>
          {isAnixeTourism && (
            <Map ids={ids} prices={prices as Record<string, string>} />
          )}
          {children}
          <Reset facetsHidden={facetsHidden} />
        </div>
      )}
    </>
  );
};

export default Filters;
