import getLastVirtualPageViewEvent from "./getLastVirtualPageViewEvent";

const getPageReferrer = (location: { pathname: string, search: string }) => {
    const defaultUrl = location?.pathname + location?.search;
  
    const lastVirtualPageViewEvent = getLastVirtualPageViewEvent();

    return lastVirtualPageViewEvent?.url || defaultUrl;
};

export default getPageReferrer;