import {
  ISubscriptionReceiverForm,
  SubscriptionFormFieldType,
} from "web/types/Subscription";

const getNameAndLastname = (form: ISubscriptionReceiverForm) => {
  if (form) {
    const { fields = [], receiverEmail = "" } = form;
    const receiverName =
      fields.find(
        (ele) => ele.type === SubscriptionFormFieldType.FIRSTNAME && ele.value
      )?.value || "";
    const receiverLastname =
      fields.find(
        (ele) => ele.type === SubscriptionFormFieldType.LASTNAME && ele.value
      )?.value || "";

    return {
      firstname: receiverName,
      lastname: receiverLastname,
      receiverEmail,
    };
  }

  return {
    firstname: "",
    lastname: "",
    receiverEmail: "",
  };
};

export default getNameAndLastname;
