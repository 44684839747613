type GetSourceMediaArgs = {
  storeConfig: any;
  src: string;
  isProduct?: boolean;
  isCategory?: boolean;
  isCampaign?: boolean;
  isLogotype?: boolean;
  isHelpCenter?: boolean;
};

export default ({
  storeConfig,
  src,
  isProduct,
  isCategory,
  isCampaign,
  isLogotype,
  isHelpCenter,
}: GetSourceMediaArgs) => {
  const mediaUrl =
    storeConfig &&
    Object.prototype.hasOwnProperty.call(storeConfig, "base_media_url") &&
    storeConfig.base_media_url;
  const srcTrim = typeof src === "string" && src.length ? src.trim() : "";
  const srcTrimProcessed = !srcTrim.indexOf("/") ? srcTrim.slice(1) : srcTrim;

  switch (true) {
    case typeof src !== "string" || !src.length:
    case typeof mediaUrl !== "string":
    case src.indexOf("http") !== -1: {
      return src;
    }
    case !!isProduct: {
      return `${mediaUrl}catalog/product/${srcTrimProcessed}`;
    }
    case !!isCategory: {
      return `${mediaUrl}catalog/category/${srcTrimProcessed}`;
    }
    case !!isCampaign: {
      return `${mediaUrl}marketing/campaign/${srcTrimProcessed}`;
    }
    case !!isLogotype: {
      return `${mediaUrl}logo/${srcTrimProcessed}`;
    }
    case !!isHelpCenter: {
      return `${mediaUrl}helpcenter/category/${srcTrimProcessed}`;
    }
    default: {
      return `${mediaUrl}${srcTrimProcessed}`;
    }
  }
};
