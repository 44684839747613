import { FC } from "react";

import {
  SkeletonBreadcrumbs,
  SkeletonCategoriesListing,
} from "web/Layout/Common/SkeletonComponents";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";
import { useAppContext } from "web/context/app";

import defaultClasses from "./categoriesPage.scss";

const SkeletonCategoriesPage: FC<PropsWithClasses> = ({ classes = {} }) => {
  const { isMobile } = useAppContext();

  return !isMobile ? (
    <div className={`container ${classes.desktopWrapper}`}>
      <div className={classes.breadcrumbsWrapper}>
        <SkeletonBreadcrumbs />
      </div>
      <SkeletonCategoriesListing />
    </div>
  ) : (
    <div className={classes.mobileWrapper}>
      <SkeletonCategoriesListing />
    </div>
  );
};

export default classify<PropsWithClasses>(defaultClasses)(
  SkeletonCategoriesPage
);
