import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import canPayOnlinePayment from "web/utils/page/product/universal/canPayOnlinePayment";

import type { IBankItem } from "web/types/Banks";
import type { IPaymentSettingsItem } from "web/types/Payment";

const getProductsBanksActiveTotal = (
  banks: IBankItem[],
  price: number,
  payments: IPaymentSettingsItem[]
) => {
  const canBePaidByMoney = canPayOnlinePayment(payments);
  const banksActive =
    isArrayHasItems(banks) && isArrayHasItems(payments)
      ? banks.filter((bank) => {
          const activeBank = payments.find((payment) => {
            return bank.points_bank_id === payment.code;
          });
          return activeBank;
        })
      : [];
  const banksActiveTotal = isArrayHasItems(banksActive)
    ? banksActive.reduce((curr, bank) => {
        return Number(bank.balance) + curr;
      }, 0)
    : 0;

  return { canBePaidByMoney, banksActiveTotal };
};

export default getProductsBanksActiveTotal;
