import { FC, Suspense, lazy, memo } from "react";
import { useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import Cms from "web/Pages/Cms";
import FAQArticle from "web/Pages/FAQ/FAQArticle";
import FAQCategory from "web/Pages/FAQ/FAQCategory";
import HelpCenterArticle from "web/Pages/HelpCenterArticle";
import HelpCenterCategory from "web/Pages/HelpCenterCategory";
import Page404 from "web/Pages/Page404";

import Loading from "web/Layout/Common/Loading";
import useResolveRoute, {
  UrlResolverPageTypes,
  UrlResolverPageValues,
} from "web/Layout/UrlResolver/useResolveRoute";

import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";

const Product = lazy(() => import("web/Pages/Product"));
const Category = lazy(() => import("web/Pages/Category"));
const PageGenerator = lazy(() => import("web/Pages/PageGenerator"));

interface IUrlResolverProps {
  pathname: string;
  isLogged: boolean;
}

const UrlResolver: FC<IUrlResolverProps> = memo(({ pathname, isLogged }) => {
  const { search } = useLocation();
  const { id: storeId } = useSelector((state) => state.app.storeConfig);
  const { data: customerDetails } = useGetCustomerDetailsQuery(undefined, {
    skip: !isLogged,
  });
  const { language } = customerDetails || {};
  const isCustomerEN = language === "en_GB";

  const page = useResolveRoute({ storeId, pathname });
  const isLandingPageView = pathname?.indexOf("/lp/") > -1;
  const isLandingPageEN = isLandingPageView && pathname.includes("-en.html");
  const shouldRedirectToPLPage = isLandingPageEN && !isCustomerEN;

  if (!page) return <Page404 />;

  const { type } = page;

  if (type === UrlResolverPageTypes.LOADING) return <Loading />;

  const {
    id,
    meta_description: metaDescription,
    meta_keywords: metaKeywords,
    meta_title: metaTitle,
    relative_url: relativeUrl,
  } = page as UrlResolverPageValues;

  switch (type) {
    case UrlResolverPageTypes.REDIRECT: {
      const url = relativeUrl + search || UrlResolverPageTypes.REDIRECT;
      return <Redirect to={url} />;
    }
    case UrlResolverPageTypes.CMS_PAGE:
      return (
        <Cms
          id={id}
          metaDescription={metaDescription}
          metaKeywords={metaKeywords}
          metaTitle={metaTitle}
        />
      );
    case UrlResolverPageTypes.PAGE:
      return shouldRedirectToPLPage ? (
        <Redirect to={pathname.replace("-en", "")} />
      ) : (
        <Suspense fallback={<div />}>
          <PageGenerator id={id} />
        </Suspense>
      );
    case UrlResolverPageTypes.CATEGORY:
      return (
        <Suspense fallback={<div />}>
          <Category id={id} />
        </Suspense>
      );
    case UrlResolverPageTypes.PRODUCT:
      return (
        <Suspense fallback={<div />}>
          <Product
            id={id}
            metaDescription={metaDescription}
            metaKeywords={metaKeywords}
            metaTitle={metaTitle}
          />
        </Suspense>
      );

    case UrlResolverPageTypes.HELP_CATEGORY:
      return <HelpCenterCategory id={id} />;
    case UrlResolverPageTypes.HELP_ARTICLE:
      return <HelpCenterArticle id={id} />;
    case UrlResolverPageTypes.FAQ_CATEGORY:
      return <FAQCategory id={id} />;
    case UrlResolverPageTypes.FAQ_ARTICLE:
      return <FAQArticle id={id} />;
    default:
      return <Page404 />;
  }
});

export default UrlResolver;
