// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fieldPreview-field-Cao{word-break:break-all;margin-bottom:.5rem}`, "",{"version":3,"sources":["webpack://./web/Layout/SubscriptionReceivers/SubscriptionReceiver/SubscriptionReceiverDataPreview/FieldPreview/fieldPreview.scss"],"names":[],"mappings":"AAIuB,wBACrB,oBAAA,CACA,mBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .field {\n  word-break: break-all;\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `fieldPreview-field-Cao`
};
export default ___CSS_LOADER_EXPORT___;
