/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import Anchor from "web/Components/Common/Anchor";

import { ICategory } from "web/types/Category";
import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./categories.scss";

interface ICategoriesMobileProps {
  activeCategory?: string | number;
  categories: ICategory[];
}

const Categories: FC<PropsWithClasses<ICategoriesMobileProps>> = ({
  classes = {},
  activeCategory,
  categories,
}) => {
  return (
    <nav className={classes.root}>
      <ul className={classes.list}>
        {categories?.map((category) => (
          <li className={classes.item} key={category.name}>
            <Anchor
              className={
                activeCategory === category.name
                  ? classes.link_active
                  : classes.link
              }
              // @ts-ignore
              to={category.link || ""}
              title={category.name}
            >
              {category.name}
            </Anchor>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default classify<PropsWithClasses<ICategoriesMobileProps>>(
  defaultClasses
)(Categories);
