import formatDate from "@feniks-pwa/web-app/web/utils/data/parser/dateAndTime/formatDate";
import { FC } from "react";

import FundingSource from "web/Pages/Checkout/Subscription/FundingSource";

import __ from "web/Layout/Translations";

import { zlotyPerMonth } from "web/constants/benefits";

import classify from "web/classify";

import classes from "./subscriptionReadMoreBenefitView.scss";

interface IReadMoreBenefitViewProps {
  price: number;
  quantity: number;
  name: string;
  groupName: string;
  endsAt: string;
  expectedEndsAt?: string;
  fundingSourceData: { label: string; dataT1: string }[];
}

const ReadMoreBenefitView: FC<IReadMoreBenefitViewProps> = ({
  price,
  quantity,
  name,
  groupName,
  endsAt,
  expectedEndsAt,
  fundingSourceData,
}) => {
  const tableLabel = [
    "Rodzaj abonamentu",
    "Liczba osób",
    "Źródło finansowania",
    "Koszt",
    "Okres ważności",
  ];
  const tableLabelJSX = tableLabel.map((label) => (
    <li key={label} className={classes.benefitTable__item}>
      {__(label)}
    </li>
  ));
  const resultEndsAt = expectedEndsAt || endsAt;
  return (
    <div className={classes.seeDetails}>
      <span className={classes.seeDetails__title}>{groupName}</span>
      <div className={classes.benefitTable}>
        <ul className={classes.benefitTable__list}>{tableLabelJSX}</ul>
      </div>
      <div className={classes.benefitData}>
        <ul className={classes.benefitData__list}>
          <li className={classes.benefitData__item}>{name}</li>
          <li className={classes.benefitData__item}>
            {quantity} {__("os.")}
          </li>
          <li className={classes.benefitData__item}>
            <FundingSource fundingSourceData={fundingSourceData} />
          </li>
          <li className={classes.benefitData__item}>
            {price === 0 ? (
              "-"
            ) : (
              <>
                {price.toFixed(2)}
                <span className={classes.benefitData__currency}>
                  {__(zlotyPerMonth)}
                </span>
              </>
            )}
          </li>
          <li className={classes.benefitData__item}>
            {resultEndsAt ? formatDate(resultEndsAt) : __("Czas nieokreślony")}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default classify<any>(classes)(ReadMoreBenefitView);
