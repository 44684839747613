import { LayoutGroup } from "framer-motion";
import { FC, PropsWithChildren } from "react";

import useConstant from "web/hooks/useConstant";

interface ISharedLayoutAnimationProps {
  groupId?: string;
}

let id = 0;

const SharedLayoutAnimation: FC<
  PropsWithChildren<ISharedLayoutAnimationProps>
> = ({ children, groupId = "sl" }) => {
  return (
    // eslint-disable-next-line no-plusplus
    <LayoutGroup id={useConstant(() => `${groupId}-${id++}`)}>
      {children}
    </LayoutGroup>
  );
};

export default SharedLayoutAnimation;
