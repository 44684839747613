import { IFinalProductOption } from "web/hooks/useGetProductOptions/useGetProductOptions";

import { IGtm, IGtmEcommerceImpression, IPromoItems } from "web/types/Gtm";
import { DomesticTourismProductInList, ProductInList } from "web/types/Product";
import { Nullable } from "web/types/Utils";

import getPageReferrer from "./getPageReferrer";
import gtmGetProductImpressions from "./gtmGetProductImpressions";

interface IGtmOptionsProps {
  data: (ProductInList | DomesticTourismProductInList)[];
  currency: string;
  categories: string;
  isAnixe?: boolean;
  productsOptionsList: IFinalProductOption[];
  previousValue: number;
  lastProductImpressionEvent: Nullable<IGtmEcommerceImpression>;
  isReady: boolean;
  lastProductPosition?: number;
  dateFrom?: string;
  dateTo?: string;
  peopleAmount?: number;
  pageSizeDefault: number;
  cgGroup1Path: string;
  searchPhrase?: string;
  location: {
    pathname: string;
    search: string;
  };
  promoItems: IPromoItems;
  listingPromotion: IPromoItems["key"];
}

const gtmGetOptions = ({
  data,
  currency,
  categories,
  isAnixe = false,
  productsOptionsList,
  previousValue,
  lastProductImpressionEvent,
  isReady,
  lastProductPosition = 0,
  dateFrom = "",
  dateTo = "",
  peopleAmount = 0,
  pageSizeDefault,
  cgGroup1Path,
  searchPhrase = "",
  location,
  promoItems = {},
  listingPromotion = {},
}: IGtmOptionsProps): [{ cgroup1: string; cgroup2: string }, IGtm] => {
  const virtualView = {
    cgroup1: cgGroup1Path || "Kategoria produktowa",
    cgroup2: categories,
    page_referrer: getPageReferrer(location),
  };

  const enhancedEcomm = {
    ecommerce: {
      currencyCode: currency,
      impressions: isReady
        ? gtmGetProductImpressions({
            data,
            isAnixe,
            productsOptionsList,
            previousValue,
            lastProductImpressionEvent,
            lastProductPosition,
            currentCgGroup2: categories,
            dateFrom,
            dateTo,
            peopleAmount,
            pageSizeDefault,
            searchPhrase,
            promoItems,
            listingPromotion,
          })
        : [],
    },
  };
  return [virtualView, enhancedEcomm];
};

export default gtmGetOptions;
