import { dayMilliseconds } from "web/constants/date";

import { DuplicateOrderStatus } from "web/types/Subscription";

// TBD after FR-1106
export const checkDuplicateButtonStatus = (
  lastSportCardRequestAt?: string,
  enableOrderDuplicateCard?: boolean
) => {
  if (!enableOrderDuplicateCard) return DuplicateOrderStatus.UNAVAILABLE;
  if (!lastSportCardRequestAt) return DuplicateOrderStatus.NEVER_ORDERED;

  const currentData = new Date().getTime();
  const lastSportCardRequestAtMiliseconds = new Date(
    lastSportCardRequestAt
  ).getTime();

  return currentData - lastSportCardRequestAtMiliseconds > dayMilliseconds
    ? DuplicateOrderStatus.NEVER_ORDERED
    : DuplicateOrderStatus.JUST_ORDERED;
};

export const as24HoursPassed = (
  dateString: string,
  enableOrderDuplicateCard: boolean
) => {
  if (!enableOrderDuplicateCard) return DuplicateOrderStatus.UNAVAILABLE;
  if (!dateString) return DuplicateOrderStatus.NEVER_ORDERED;

  const currentDate = new Date();
  const pastDate = new Date(dateString);

  pastDate.setHours(pastDate.getHours() + 24);

  return currentDate.getTime() >= pastDate.getTime()
    ? DuplicateOrderStatus.ORDERED_IN_THE_PAST
    : DuplicateOrderStatus.JUST_ORDERED;
};
