// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productAside-productAside-awm{width:26%}.productAside-desktopProductTitle-hig{height:100px;margin-bottom:25px}.productAside-productPlaceInfo-SiM{height:18px;margin-bottom:16px}.productAside-productValidityInfo-vl2{height:16px;margin-bottom:25px}`, "",{"version":3,"sources":["webpack://./web/Layout/Common/SkeletonComponents/SkeletonProduct/SkeletonProductAside/productAside.scss"],"names":[],"mappings":"AAIyB,+BACrB,SAAA,CAGF,sCACE,YAAA,CACA,kBAAA,CAGF,mCACE,WAAA,CACA,kBAAA,CAGF,sCACE,WAAA,CACA,kBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                         .productAside {\n    width: 26%;\n  }\n  \n  .desktopProductTitle {\n    height: 100px;\n    margin-bottom: 25px;\n  }\n  \n  .productPlaceInfo {\n    height: 18px;\n    margin-bottom: 16px;\n  }\n  \n  .productValidityInfo {\n    height: 16px;\n    margin-bottom: 25px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productAside": `productAside-productAside-awm`,
	"desktopProductTitle": `productAside-desktopProductTitle-hig`,
	"productPlaceInfo": `productAside-productPlaceInfo-SiM`,
	"productValidityInfo": `productAside-productValidityInfo-vl2`
};
export default ___CSS_LOADER_EXPORT___;
