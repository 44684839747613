import { DUPLICATE_ADDITIONAL_FIELDS_NAME } from "web/constants/benefits";

export const userNamesFields = (isReasonChangeOrIncorrect: boolean) => {
  return [
    {
      inputType: "text",
      label: `${isReasonChangeOrIncorrect ? "Nowe imię" : "Imię"}`,
      type: "newFirstName",
      position: 2,
      textBelow: "",
      required: true,
    },
    {
      inputType: "text",
      label: `${isReasonChangeOrIncorrect ? "Nowe nazwisko" : "Nazwisko"}`,
      type: "newLastName",
      position: 3,
      textBelow: "Poprawne dane zgłoś także do swojego działu HR",
      required: true,
    },
  ];
};

export const additionalAddressFields = [
  {
    inputType: "text",
    label: "Ulica",
    type: DUPLICATE_ADDITIONAL_FIELDS_NAME.street,
    position: 4,
    textBelow: "",
    required: true,
  },
  {
    inputType: "text",
    label: "Miasto",
    type: DUPLICATE_ADDITIONAL_FIELDS_NAME.city,
    position: 5,
    textBelow: "",
    required: true,
  },
  {
    inputType: "text",
    label: "Kod pocztowy",
    type: DUPLICATE_ADDITIONAL_FIELDS_NAME["post-code"],
    position: 6,
    textBelow: "",
    required: true,
  },
  {
    inputType: "text",
    label: "Numer domu",
    type: DUPLICATE_ADDITIONAL_FIELDS_NAME.houseNumber,
    position: 7,
    textBelow: "",
    required: false,
  },
  {
    inputType: "text",
    label: "Numer mieszkania",
    type: DUPLICATE_ADDITIONAL_FIELDS_NAME.flatNumber,
    position: 8,
    textBelow: "",
    required: false,
  },
];
