import { shape, string } from "prop-types";

import UrlResolver from "./urlResolver";

const UrlResolverContainer = ({ location, isLogged }) => {
  const { pathname } = location;
  let pathnameProcessed;
  try {
    const url = new URL(`http://example.com${pathname}`);
    pathnameProcessed = url.pathname;
  } catch (error) {
    pathnameProcessed = pathname;
  }
  return <UrlResolver pathname={pathnameProcessed} isLogged={isLogged} />;
};

UrlResolverContainer.propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

export default UrlResolverContainer;
