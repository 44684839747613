import processUrlKey from "web/utils/page/product/universal/processUrlKey";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import storageNames from "web/constants/storageNames";

import type { IProduct } from "web/types/Product";

interface SetProductResolverArgs {
  products: IProduct[];
  storeId: number;
}
const storage = new BrowserPersistence();

const setProductsResolver = ({ products, storeId }: SetProductResolverArgs) => {
  products.forEach((product) => {
    const pathname = processUrlKey(product.url_key);
    const identifier = `${storeId}-${pathname}`;
    const urlResolver = storage.getItem(storageNames.urlResolver, identifier);

    if (!urlResolver) {
      // eslint-disable-next-line camelcase
      const { id, meta_description, meta_keywords, meta_title } = product;
      const resolverData = {
        id: +id,
        meta_description,
        meta_keywords,
        meta_title,
        redirectCode: null,
        relative_url: `/catalog/product/view/id/${id}`,
        robots: null,
        type: "PRODUCT",
      };
      storage.setItem(storageNames.urlResolver, identifier, resolverData);
    }
  });
};

export default setProductsResolver;
