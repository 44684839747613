import { useEffect, useState } from "react";

import urlResolverQuery from "web/queries/default/urlResolver.graphql";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import convertGraphqlDocumentToGetString from "web/utils/system/query/convertGraphqlDocumentToGetString";
import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";

import storageNames from "web/constants/storageNames";

import { Nullable } from "web/types/Utils";

export const enum UrlResolverPageTypes {
  REDIRECT = "REDIRECT",
  LOADING = "LOADING",
  CMS_PAGE = "CMS_PAGE",
  PAGE = "PAGE",
  CATEGORY = "CATEGORY",
  PRODUCT = "PRODUCT",
  HELP_CATEGORY = "HELP_CATEGORY",
  HELP_ARTICLE = "HELP_ARTICLE",
  FAQ_CATEGORY = "FAQ_CATEGORY",
  FAQ_ARTICLE = "FAQ_ARTICLE",
}

interface IuseResolveRouteArgs {
  pathname: string;
  storeId: number;
}

export interface UrlResolverPageValues {
  type: Omit<UrlResolverPageTypes, UrlResolverPageTypes.LOADING>;
  id: number;
  meta_description: string;
  meta_keywords: string;
  meta_title: string;
  relative_url: string;
}

export interface UrlResolverLoadingPageValues {
  type: UrlResolverPageTypes.LOADING;
}

type UrlResolverPage = Nullable<
  UrlResolverLoadingPageValues | UrlResolverPageValues
>;

type useResolveRouteType = (
  args: IuseResolveRouteArgs
) => Nullable<UrlResolverPage>;

const storage = new BrowserPersistence();
const initialState: UrlResolverLoadingPageValues = {
  type: UrlResolverPageTypes.LOADING,
};
const options = {
  method: "GET",
  credentials: "include" as RequestCredentials,
  headers: new Headers({
    "Content-Type": "application/json",
  }),
};

const useResolveRoute: useResolveRouteType = ({ pathname, storeId }) => {
  const identifier = `${storeId}-${pathname}`;
  const urlResolver = storage.getItem(storageNames.urlResolver, identifier);
  const [page, setPage] = useState<UrlResolverPage>(initialState);
  useEffect(() => {
    if (urlResolver) {
      setPage(urlResolver);
    } else {
      fetchRoute({ pathname, storeId })
        .then(({ data }) => {
          if (
            data &&
            data[storageNames.urlResolver] &&
            data[storageNames.urlResolver].id
          ) {
            storage.setItem(
              storageNames.urlResolver,
              identifier,
              data[storageNames.urlResolver]
            );
            setPage(data[storageNames.urlResolver]);
          } else {
            console.error(data);
            setPage(null);
          }
        })
        .catch((error) => {
          newRelicErrorReport(
            error,
            "web-app/web/Layout/UrlResolver/useResolveRoute.tsx - 91"
          );
          console.error(error);
          setPage(null);
        });
    }
  }, [pathname, storeId]);

  return page as UrlResolverPage;
};

function fetchRoute({ pathname, storeId }: IuseResolveRouteArgs) {
  const uri = convertGraphqlDocumentToGetString(urlResolverQuery, {
    variables: {
      url: pathname,
      storeId: storeId || 1,
    },
  });

  return fetch(uri, options)
    .then((response) => response.json())
    .then((response) => response);
}

export default useResolveRoute;
