import { FC } from "react";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import type { PropsWithClasses } from "web/types/Common";

import { IProductCardProps } from "../container";
import Product from "./product";

export type ProductCartListProps = Pick<
  IProductCardProps,
  "product" | "positionNumber"
>;

const ProductCardListContainer: FC<PropsWithClasses<ProductCartListProps>> = ({
  classes,
  product,
  positionNumber = 0,
}) => {
  return (
    <ErrorBoundary>
      <Product
        positionNumber={positionNumber}
        classes={classes}
        product={product}
      />
    </ErrorBoundary>
  );
};

export default ProductCardListContainer;
