import { IRentableGroupCalculatorValues } from "../../RentableGroupCalculator/rentableGroupCalculatorContainer";
import { IRentableGroupCalculatorAgreementsListField } from "../rentableGroupCalculatorAgreements";

const getAgreementFields = (
  agreementFieldsList: IRentableGroupCalculatorAgreementsListField[],
  agreementValues: IRentableGroupCalculatorValues["agreements"],
  initialValues: IRentableGroupCalculatorValues["agreements"]
) =>
  agreementFieldsList.reduce((acc, { name }) => {
    return {
      ...acc,
      [name]: !!(agreementValues?.[name] ?? initialValues?.[name]),
    };
  }, {});

export default getAgreementFields;
