function isSafariThatSupportsWebp() {
  const { userAgent } = navigator;
  const isDesktopSafari = /Version\/(\d+\.\d+)\s+Safari/.test(userAgent);
  const isIosSafari = /Version\/(\d+\.\d+)\s+Mobile\/(\w+)\s+Safari/.test(
    userAgent
  );
  let version;
  if (isDesktopSafari) {
    version = parseFloat(RegExp.$1);
  }
  if (isIosSafari) {
    version = parseFloat(RegExp.$1);
  }
  const isVersionValid =
    version &&
    ((isDesktopSafari && version > 16.0) || (isIosSafari && version > 14.0));
  return isVersionValid;
}

const canUseWebp = () => {
  if (isSafariThatSupportsWebp()) return true;
  const elem = document.createElement("canvas");
  if (elem.getContext && elem.getContext("2d")) {
    try {
      return elem.toDataURL("image/webp").indexOf("data:image/webp") === 0;
    } catch {
      return false;
    }
  }
  return false;
};

export default canUseWebp;
