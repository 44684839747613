import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";

import HeaderSearch from "web/Layout/HeaderSearch";

import List from "./List";
import headerClasses from "./header.scss";
import classes from "./navigation.scss";

const Navigation: FC = () => {
  const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 980) {
        history.push("/");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <div className={classes.root}>
      <HeaderSearch classes={headerClasses} />
      <List />
    </div>
  );
};

export default Navigation;
