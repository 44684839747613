/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from "@benefit-systems/common-components";
import { Field, Form, Formik } from "formik";
import { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import ValidationErrorMessage from "web/Components/Common/ValidationErrorMessage";

import editForm from "web/utils/page/product/subscription/forms/editForm";
import { validateField } from "web/utils/system/formValidator/validation";

import {
  FormView,
  ISubscriptionReceiver,
  ISubscriptionReceiverFormValues,
  SubscriptionFormFieldType,
} from "web/types/Subscription";

import {
  setNotificationError,
  setNotificationSuccess,
} from "web/features/app/appSlice";
import { invalidateCurrentUserSubscription } from "web/features/subscriptions/subscriptionsApiSlice";

import styles from "./editEmail.scss";

interface IEditEmailProps {
  receiver: ISubscriptionReceiver;
  benefitUUID: string;
  closeModal: () => void;
}

const EditEmail: FC<IEditEmailProps> = ({
  receiver,
  benefitUUID,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    [SubscriptionFormFieldType.RECEIVER_EMAIL]: receiver.form.receiverEmail,
  };
  const onSuccessFunction = () => {
    setLoading(false);
    invalidateCurrentUserSubscription(dispatch);
    dispatch(
      setNotificationSuccess({
        message: __("Adres e-mail został poprawnie zmieniony."),
      })
    );
    closeModal();
  };
  const onErrorFunction = () => {
    setLoading(false);
    dispatch(
      setNotificationError({ message: __("Nie udało się wysłać formularza.") })
    );
  };
  const submitHandler = useCallback(
    (values: ISubscriptionReceiverFormValues) => {
      setLoading(true);

      editForm({
        receiverId: receiver.id,
        receiverEmail: values.receiverEmail as string,
        receiverType: receiver.type,
        subscriptionId: benefitUUID,
        viewType: FormView.MY_SUBSCRIPTIONS,
        onSuccess: onSuccessFunction,
        onError: onErrorFunction,
      });
    },
    []
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Formik initialValues={initialValues} onSubmit={submitHandler}>
          <Form>
            <label
              htmlFor={SubscriptionFormFieldType.RECEIVER_EMAIL}
              className="visually-hidden"
            >
              E-mail
            </label>
            <Field
              type="email"
              name={SubscriptionFormFieldType.RECEIVER_EMAIL}
              id={SubscriptionFormFieldType.RECEIVER_EMAIL}
              component="input"
              placeholder={receiver.form.receiverEmail}
              validate={validateField(["required", "email"])}
              className={styles.input}
            />
            <ValidationErrorMessage
              name={SubscriptionFormFieldType.RECEIVER_EMAIL}
            />
            <Button
              type="submit"
              ariaLabel={__("Wyślij")}
              variant="tertiary"
              className={styles.submitBtn}
            >
              {__("Wyślij")}
            </Button>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default EditEmail;
