import { Dispatch } from "react";

import downloadFile from "web/api/downloadFile";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import restUrls from "web/constants/restUrls";

import { request } from "web/api";

type PrintCodeArgs = {
  itemId: number;
  setIsPrinting: Dispatch<React.SetStateAction<boolean>>;
};
type PrintCodeFn = (args: PrintCodeArgs) => void;

const printCode: PrintCodeFn = async ({ itemId, setIsPrinting }) => {
  try {
    setIsPrinting(true);
    const filePath = (await request(
      `${restUrls.getGiftPdf}?itemId=${itemId}`
    )) as string;
    const filename =
      (typeof filePath === "string" &&
        filePath.split("/").slice(-1)?.[0]?.split(".")?.[0]) ||
      "Kod";
    await downloadFile(filePath, filename, false, "pdf", true);
  } catch (errorData) {
    newRelicErrorReport(
      errorData,
      "web-app/web/Components/Common/CodeItem/CodeModal/utils/printCode.ts - 31"
    );
    console.error(errorData);
  } finally {
    setIsPrinting(false);
  }
};

export default printCode;
