import { FC, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./button.scss";

interface IButtonProps {
  to?: string;
  variant?: "primary" | "secondary" | "tertiary" | "quaternary";
  size?: "small" | "medium" | "large";
  block?: boolean;
  transparent?: boolean;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: () => void;
  dataT1?: string;
  ariaLabel?: string;
}

const Button: FC<PropsWithChildren<PropsWithClasses<IButtonProps>>> = ({
  to = "",
  variant = "primary",
  size = "medium",
  block = false,
  transparent = false,
  type = "button",
  disabled = false,
  classes = {},
  onClick = () => {},
  children = "",
  dataT1 = "button",
  ariaLabel = "",
}) => {
  const buttonClasses = `${classes.root} 
    ${classes[`root_${variant}`]} 
    ${classes[`root_${size}`]} 
    ${transparent ? classes.root_transparent : ""}
    ${block ? classes.root_block : ""}
    ${disabled ? classes.root_disabled : ""}`;

  return to ? (
    <Link to={to} onClick={onClick} className={buttonClasses} data-t1={dataT1}>
      <span data-t1={`${dataT1}_span`}>{children}</span>
    </Link>
  ) : (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      className={buttonClasses}
      disabled={disabled}
      data-t1={dataT1}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
};

export default classify<PropsWithChildren<PropsWithClasses<IButtonProps>>>(
  defaultClasses
)(Button);
