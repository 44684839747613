import { BENEFIT_GROUP_TYPE } from "web/constants/benefits";

import { Nullable } from "web/types/Utils";

export default (
  benefitGroupType: string | number = "1",
  additionalParams: Nullable<{ [key: string]: string }> = null
) => {
  if (!benefitGroupType) return "";

  const params = new URLSearchParams(additionalParams || {});

  params.set(BENEFIT_GROUP_TYPE, benefitGroupType.toString());
  return `?${params.toString()}`;
};
