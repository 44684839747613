import { FC, memo } from "react";
import { useSelector } from "react-redux";

import SectionList from "web/Layout/Common/SectionList";
import PointsLanguage from "web/Layout/MainHeader/Desktop/Customer/PointsLanguage";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { ombRedirect } from "web/constants/urls";

import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";

import styles from "./dropdown.scss";

interface IMainHeaderCustomerDropdownProps {
  totalPoints: number;
  setIsOpen: (isOpen: unknown) => void;
}

const MainHeaderCustomerDropdown: FC<IMainHeaderCustomerDropdownProps> = memo(
  ({ totalPoints, setIsOpen }) => {
    const { sections: menuSections } = useSelector(
      (state) => state.customerMenu
    );
    const { data: customer } = useGetCustomerDetailsQuery();
    const { ssoRole, impersonated } = customer || {};

    const ahrRedirectLink =
      ssoRole === "ahr" ? ombRedirect.redirectAhrToOmb : null;
    const ombRedirectLink = impersonated
      ? ombRedirect.redirectOperatorToOmb
      : null;

    const menuSectionWithItems = menuSections.filter(
      ({ items }) => items?.length
    );

    if (menuSectionWithItems?.length === 3) {
      return (
        <div className={styles.root}>
          <div className={styles.twoMenusContainer}>
            {[menuSections[0], menuSections[2]].map((menuSection) => (
              <SectionList
                key={menuSection.name}
                className={styles.section}
                title={menuSection.name}
                items={menuSection.items}
                onClick={setIsOpen}
              />
            ))}
          </div>
          <SectionList
            key={menuSections[1].name}
            className={styles.section}
            title={menuSections[1].name}
            items={menuSections[1].items}
            onClick={setIsOpen}
          />
          <PointsLanguage
            className={styles.sectionColumn}
            totalPoints={totalPoints}
            onClick={setIsOpen}
            ombRedirectLink={ombRedirectLink}
            ahrRedirectLink={ahrRedirectLink}
          />
        </div>
      );
    }
    return (
      <div className={styles.root}>
        {isArrayHasItems(menuSections) &&
          menuSections.map((menuSection) => {
            return (
              <SectionList
                key={menuSection.name}
                className={styles.section}
                title={menuSection.name}
                items={menuSection.items}
                onClick={setIsOpen}
              />
            );
          })}
        <PointsLanguage
          className={styles.sectionColumn}
          totalPoints={totalPoints}
          onClick={setIsOpen}
          ombRedirectLink={ombRedirectLink}
          ahrRedirectLink={ahrRedirectLink}
        />
      </div>
    );
  }
);

export default MainHeaderCustomerDropdown;
