import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { MBProductType, ProductInList } from "web/types/Product";

const filterBenefitsByGroup = (
  data: ProductInList[],
  groupType: MBProductType
) => {
  return data.filter((product) => product?.mb_product_type === groupType);
};

const useGetAvailableUserBenefits = (productsData?: ProductInList[]) => {
  if (!isArrayHasItems(productsData)) return [];

  const benefitsData = filterBenefitsByGroup(
    productsData,
    MBProductType.SUBSCRIPTION_GROUP_1
  );
  const benefitsDataUMS = filterBenefitsByGroup(
    productsData,
    MBProductType.SUBSCRIPTION_GROUP_2
  );

  return [...([benefitsData] || []), ...([benefitsDataUMS] || [])];
};

export default useGetAvailableUserBenefits;
