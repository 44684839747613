import { Form, useFormikContext } from "formik";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import ErrorBoundary from "web/Layout/ErrorBoundary";

import usePrevious from "web/hooks/usePrevious/usePrevious";

import urls from "web/constants/urls";

import { useAppContext } from "web/context/app";
import { closeNavigation } from "web/features/app/appSlice";

import AutocompleteDesktop from "./Desktop/Autocomplete";
import SearchFieldDesktop from "./Desktop/SearchField";
import AutocompleteMobile from "./Mobile/Autocomplete";
import SearchFieldMobile from "./Mobile/SearchField";
import classesDesktop from "./searchDesktop.scss";
import classesMobile from "./searchMobile.scss";

const timeout = 500;

interface ISearchAutocompleteSearchProps {
  className: string;
  name: string;
  pathname: string;
  search: string;
  onClick: () => void;
}

const SearchAutocompleteSearch: FC<ISearchAutocompleteSearchProps> = ({
  className,
  name,
  pathname,
  search,
  onClick = null,
}) => {
  const dispatch = useDispatch();
  const setTimeOutId = useRef<NodeJS.Timeout>();
  const [autocompleteValue, setAutocompleteValue] = useState("");
  const { values, dirty, setValues, initialValues } = useFormikContext<{
    [name: string]: string;
  }>();
  const { isMobile } = useAppContext();
  const classes = isMobile ? classesMobile : classesDesktop;
  const Autocomplete = isMobile ? AutocompleteMobile : AutocompleteDesktop;
  const SearchField = isMobile ? SearchFieldMobile : SearchFieldDesktop;
  const initialValue = initialValues[name as keyof typeof initialValues];
  const currentValue = values[name as keyof typeof values];
  const previousValue = usePrevious(currentValue);
  const closeAutocomplete = useCallback(() => {
    setAutocompleteValue("");
  }, [setAutocompleteValue]);
  const closeAutocompleteWithOnClick = useCallback(() => {
    if (typeof onClick === "function") {
      onClick();
    }
    dispatch(closeNavigation());
    closeAutocomplete();
  }, [closeAutocomplete, onClick]);
  useEffect(() => {
    clearTimeout(setTimeOutId.current);
    switch (true) {
      case Boolean(currentValue === initialValue && !dirty): {
        setAutocompleteValue(currentValue);
        break;
      }
      case Boolean(
        (currentValue && dirty) ||
          (previousValue !== currentValue && currentValue === initialValue)
      ): {
        setTimeOutId.current = setTimeout(() => {
          setAutocompleteValue(currentValue);
        }, timeout);
        break;
      }
      default: {
        clearTimeout(setTimeOutId.current);
        closeAutocomplete();
      }
    }
    return () => {
      clearTimeout(setTimeOutId.current);
    };
  }, [currentValue, previousValue, initialValue, closeAutocomplete]);

  useEffect(() => {
    clearTimeout(setTimeOutId.current);
    if (pathname !== urls.search) {
      setValues({
        [name]: "",
      });
    }
    if (!isMobile) {
      closeAutocomplete();
    }
  }, [pathname, setValues, closeAutocomplete, search, isMobile]);

  return (
    <div className={className || classes.root}>
      <div className={classes.wrapper} data-t1="search">
        <Form className={classes.form} data-t1="search_form">
          <SearchField name={name} currentValue={currentValue} />
        </Form>
        {autocompleteValue ? (
          <div className={classes.autocomplete}>
            <ErrorBoundary>
              <Autocomplete
                searchPhrase={autocompleteValue.replace("&", "")}
                closeAction={closeAutocompleteWithOnClick}
                className={classes.wrapper}
              />
            </ErrorBoundary>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SearchAutocompleteSearch;
