// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiselect-root-Khu{display:block}.multiselect-item-jK3{margin:0 0 10px}.multiselect-item-jK3:last-child{margin:0}`, "",{"version":3,"sources":["webpack://./web/Components/Common/Filters/Shared/Fieldset/Multiselect/multiselect.scss"],"names":[],"mappings":"AAIuB,sBACnB,aAAA,CAGJ,sBACI,eAAA,CAEA,iCACI,QAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n    display: block;\n}\n\n.item {\n    margin: 0 0 10px;\n\n    &:last-child {\n        margin: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `multiselect-root-Khu`,
	"item": `multiselect-item-jK3`
};
export default ___CSS_LOADER_EXPORT___;
