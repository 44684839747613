import { arrayOf, number, shape, string } from "prop-types";
import { useSelector } from "react-redux";

import ProductCarousel from "./productCarousel";

const Container = ({
  products,
  classes,
  itemWidth,
  className,
  listingPosition,
  listingAmount,
  listingCategory,
}) => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  return (
    <ProductCarousel
      products={products}
      className={className}
      classes={classes}
      itemWidth={itemWidth}
      currency={storeConfig.default_display_currency_code}
      productSuffix={storeConfig.product_url_suffix}
      listingPosition={listingPosition}
      listingAmount={listingAmount}
      listingCategory={listingCategory}
    />
  );
};

Container.propTypes = {
  className: string,
  classes: shape({}),
  products: arrayOf(shape()),
  itemWidth: number,
  listingPosition: number,
  listingAmount: number,
  listingCategory: string,
};

Container.defaultProps = {
  className: "",
  classes: {},
  products: [],
  itemWidth: 290,
  listingPosition: null,
  listingAmount: null,
  listingCategory: "",
};

export default Container;
