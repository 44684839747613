import __ from "web/Layout/Translations";

import senderTypes from "web/constants/messageTypes";

const getMessageSenderLabelType = (
  type: typeof senderTypes[keyof typeof senderTypes],
  reminder?: boolean
) => {
  switch (true) {
    case type === senderTypes.MB:
      return __("MyBenefit");
    case type === senderTypes.HR:
      return __("Dział HR");
    case type === senderTypes.AHR:
      return __("Twój pracodawca");
    case type === senderTypes.employee && !!reminder:
      return __("Moje");
    default:
      return __("Przypomnienie");
  }
};

export default getMessageSenderLabelType;
