/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions  */

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { Field, useFormikContext } from "formik";
import { FC, KeyboardEventHandler, useCallback } from "react";

import getValueByStringPath from "web/utils/page/product/domesticTourism/getValueByStringPath";

import classes from "./selectItem.scss";

interface ISelectItemProps {
  option:
    | string
    | {
        value: string;
        label: string;
        id?: string | undefined;
      };
  closeAction: () => void;
  name: string;
}

const SelectItem: FC<ISelectItemProps> = ({ option, closeAction, name }) => {
  const { values } = useFormikContext<Record<string, string | number>>();
  const currentValue = getValueByStringPath(values, name);
  const isActive =
    currentValue && currentValue === (option as { value: string }).value;
  const keyDownHandler: KeyboardEventHandler<HTMLLabelElement> = useCallback(
    (event) => {
      if (["Enter", " "].indexOf(event.key) !== -1) {
        closeAction();
      }
    },
    [closeAction]
  );

  const classNameRootActive = isActive && classes.rootActive;
  const classNameRoot = classNameRootActive || classes.root;

  return (
    <label
      key={(option as { value: string }).value}
      className={classNameRoot}
      onClick={closeAction}
      onKeyDown={keyDownHandler}
      tabIndex={0}
    >
      <Field
        type="radio"
        className="visually-hidden"
        name={name}
        value={(option as { value: string }).value}
        dataT1="select_item_radio"
      />
      <span>{(option as { label: string }).label}</span>
    </label>
  );
};

export default SelectItem;
