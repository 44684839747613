import { FC, ReactNode, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import sendGtmEvent from "web/utils/system/GTM/sendGtmEvent";
import getSearchParameterAll from "web/utils/system/url/getSearchParameterAll";

import { gtmFiltersKeys } from "web/constants/filters";

import type { StringNumber } from "web/types/Utils";

import Checkbox from "./checkbox";

export interface IMultiselectCheckboxContainerProps {
  label: string;
  labelWithoutCount?: string;
  value: StringNumber;
  count: number;
  info?: ReactNode;
  name: string;
  filterTitle?: string;
}

const MultiselectCheckboxContainer: FC<IMultiselectCheckboxContainerProps> = ({
  name,
  value,
  info = null,
  label,
  labelWithoutCount = "",
  count,
  filterTitle,
}) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const currentValuesByName = getSearchParameterAll({ name, search });

  const valueFromSearch =
    !!currentValuesByName.length &&
    currentValuesByName.some((item) => `${item}` === `${value}`);

  const sendGtmFiltersEvent = () => {
    const additionalParams = gtmFiltersKeys?.[
      name as keyof typeof gtmFiltersKeys
    ]
      ? {
          [gtmFiltersKeys[name as keyof typeof gtmFiltersKeys]]:
            labelWithoutCount || label,
        }
      : {};

    const gtmObject = {
      eventName: "filtrujProdukty",
      options: {
        eventCategory: filterTitle,
        ...additionalParams,
      },
    };
    sendGtmEvent(gtmObject.eventName, gtmObject.options);
  };

  const onChangeHandler = useCallback(
    (checkboxState: boolean) => {
      const params = new URLSearchParams(search);
      params.delete(name);

      if (checkboxState) {
        currentValuesByName.forEach((valueItem) => {
          params.append(name, encodeURIComponent(valueItem));
        });

        params.append(name, encodeURIComponent(value));

        sendGtmFiltersEvent();
      } else {
        currentValuesByName.forEach((valueItem) => {
          if (`${valueItem}` !== `${value}`) {
            params.append(name, encodeURIComponent(valueItem));
          }
        });
      }

      push(`${pathname}?${params.toString()}`);
    },
    [name, pathname, push, value, currentValuesByName]
  );

  return (
    <Checkbox
      name={name}
      info={info}
      label={label}
      count={count}
      value={valueFromSearch}
      onChange={onChangeHandler}
    />
  );
};

export default MultiselectCheckboxContainer;
