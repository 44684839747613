import { Dispatch, RefObject } from "react";

import __ from "web/Layout/Translations";

import { setNotificationSuccess } from "web/features/app/appSlice";

type CopyCodeToClipboardArgs = {
  dispatch: Dispatch<unknown>;
  codeInputRef: RefObject<HTMLInputElement>;
  code: string;
};
type CopyCodeToClipboardFn = (args: CopyCodeToClipboardArgs) => void;

const copyCodeToClipboard: CopyCodeToClipboardFn = ({
  dispatch,
  codeInputRef,
  code,
}) => {
  if (window.ReactNativeWebView) {
    codeInputRef.current?.select();
    document.execCommand("copy");
  } else {
    window.navigator.clipboard.writeText(code);
  }
  dispatch(setNotificationSuccess({ message: __("Kod został skopiowany") }));
};

export default copyCodeToClipboard;
