import { useFormikContext } from "formik";
import { motion } from "framer-motion";
import { FC, useCallback } from "react";

import ArrowUpIcon from "web/assets/icons/arrow-up.svg";

import useDropdown from "web/hooks/useDropdown";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getValueByStringPath from "web/utils/page/product/domesticTourism/getValueByStringPath";

import { Nullable } from "web/types/Utils";

import SelectItem from "./SelectItem";
import classes from "./select.scss";

interface ISelectProps {
  options: Nullable<string[] | { value: string; label: string; id?: string }[]>;
  className?: string;
  name: string;
  placeholder?: string;
  readonly: boolean;
}

const dropdownVariants = {
  open: {
    height: "auto",
  },
  closed: {
    height: 0,
  },
};
const transition = { duration: 0.25 };
const Select: FC<ISelectProps> = ({
  className = "",
  name,
  options,
  placeholder = "",
  readonly = false,
}) => {
  const [isOpen, setIsOpen] = useDropdown({
    scopeSelector: `[data-select-name="${name}"]`,
  });
  const { values, errors, touched } =
    useFormikContext<Record<string, string | number>>();
  const isError =
    errors[name as keyof typeof errors] &&
    touched[name as keyof typeof touched];
  const buttonClass = isError ? classes.buttonError : classes.trigger;
  const classNameWrapper = isOpen ? classes.wrapperActive : classes.wrapper;
  const toggleHandler = useCallback(() => {
    setIsOpen((status) => !status);
  }, [setIsOpen]);
  const closeHandler = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  const valueActive = getValueByStringPath(values, name);
  const optionActive =
    values &&
    (options as { value: string; label: string }[])?.find(
      (option) => option.value === valueActive
    );
  const labelActive = optionActive && optionActive.label;
  const label = labelActive || placeholder;
  const labelClasses = labelActive
    ? classes.labelActive
    : classes.labelPlaceholder;

  return isArrayHasItems(options) && name ? (
    <div className={className || classes.root} data-select-name={name}>
      <div className={classNameWrapper}>
        <button
          disabled={readonly}
          type="button"
          className={buttonClass}
          onClick={toggleHandler}
          data-t1="select_options_input_button"
        >
          <span className={labelClasses}>{label}</span>
          <ArrowUpIcon
            width={10}
            className={classes.arrow}
            dataT1="select_options_input_arrow_up_icon"
          />
        </button>
        <div className={classes.body}>
          <motion.div
            className={classes.dropdown}
            animate={isOpen ? "open" : "closed"}
            transition={transition}
            variants={dropdownVariants}
          >
            <div className={classes.content}>
              {options?.map((option) => (
                <SelectItem
                  name={name}
                  option={option}
                  key={`${name}${(option as { id: string }).id}`}
                  closeAction={closeHandler}
                />
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Select;
