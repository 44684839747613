import {
  isDefaultLanguage,
  previewTranslations,
} from "web/Layout/Translations/LanguageWrapper";
import {
  fetchInterfaceTranslations,
  fetchMissingTranslations,
} from "web/Layout/Translations/helpers";

import ResponseError from "web/api/responseError";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import { SimpleFn } from "web/types/Common";

import isGetToken401 from "./utils/isGetToken401";

declare global {
  interface Window {
    translations: {
      notInitialized: boolean;
      phrases: {
        [x: string]: string;
      };
    };
  }
}

type Phrase = string;
type Args = string[];

window.translations = window.translations || { notInitialized: true };

const INITIAL_ATTEMPTS = 3;
const useTranslation = !isDefaultLanguage; // && !isMockView()); //|| isTestMode();

export const getTranslation = (phrase: Phrase) => {
  return window.translations.phrases[phrase];
};
// eslint-disable-next-line no-underscore-dangle
const __ = (phrase: Phrase, args: Args = []) => translate(phrase, args);

const missingPhrases = new Set<string>();
export const markAsMissing = (phrases: Phrase[]) => {
  phrases?.forEach((phrase) => phrase && missingPhrases.add(phrase));
};
const INTERFACE_CODE = "magento_pwa";

const getMissingTranslations = async () => {
  const newTranslations = await fetchMissingTranslations(
    missingPhrases,
    INTERFACE_CODE
  );
  // const newTranslationsN = await fetchMissingTranslations(
  //   missingPhrases,
  //   "catalogManagement:product:name"
  // );

  if (newTranslations) {
    setTranslations(newTranslations);
  }
  // if (newTranslationsN) {
  //   setTranslations(newTranslationsN);
  // }
};

if (useTranslation) {
  setInterval(getMissingTranslations, 10000);
}

export const setTranslations = (phrases: { [x: Phrase]: Phrase }) => {
  window.translations = {
    phrases: {
      ...window.translations.phrases,
      ...phrases,
    },
    notInitialized: false,
  };
};

let attempts = INITIAL_ATTEMPTS;

export const loadInterfaceTranslation = async (
  setAsLoaded: SimpleFn | null,
  fromTranslate?: boolean
) => {
  try {
    window.translations.phrases = await fetchInterfaceTranslations(
      INTERFACE_CODE
    );
    const name = await fetchInterfaceTranslations(
      "catalogManagement:product:name"
    );
    if (name) {
      setTranslations(name);
    }
  } catch (error) {
    if (!isGetToken401(error as ResponseError)) {
      newRelicErrorReport(
        error,
        "web-app/web/Layout/Translations/index.ts - 99"
      );
      console.error(error);
    }

    if (attempts > 0 && fromTranslate) {
      // if request failed retry in 10s (3 attempts)
      setTimeout(() => {
        attempts -= 1;
        loadInterfaceTranslation(null, true);
      }, 10000);
    }
  }
  if (typeof setAsLoaded === "function") {
    setAsLoaded();
  }
};

// eslint-disable-next-line no-underscore-dangle
const _translate = () => {
  let canTryToRefetch = true;

  const resetRefetchConditions = () => {
    canTryToRefetch = true;
    attempts = INITIAL_ATTEMPTS;
  };

  return (phrase: Phrase, args: Args = []) => {
    if (
      window.translations.notInitialized &&
      process.env.NODE_ENV === "development"
    ) {
      throw Error(`Translation for '${phrase}' executed to early`);
    }
    // try refetch translations if translate is called for the first time
    // while translations aren't initialized
    if (window.translations.notInitialized && canTryToRefetch) {
      canTryToRefetch = false;
      loadInterfaceTranslation(null, true);

      // reset refetch conditions every 10 minutes
      setTimeout(resetRefetchConditions, 600000);
    }

    let result = phrase;

    // use original phrase if translations aren't initialized
    if (useTranslation && !window.translations.notInitialized) {
      const translation = getTranslation(phrase);
      if (translation === undefined) {
        markAsMissing([phrase]);
      }
      result = translation || result;
    }

    if (args.length) {
      const elementsToReplace = result.matchAll(/{(.*?)}/g);
      [...elementsToReplace].forEach(([toReplace], index) => {
        result = result.replace(toReplace, args[index]);
      });
    }
    return previewTranslations ? `[I]${result}[/I]` : result;
  };
};

export const translate = _translate();

export default __;
