import { motion } from "framer-motion";
import { FC, PropsWithChildren, ReactElement, ReactNode } from "react";

import ArrowUpIcon from "web/assets/icons/arrow-up.svg";
import CheckIcon from "web/assets/icons/check.svg";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./select.scss";

interface ISelectProps {
  active?: ReactNode;
  closedBgColor?: boolean;
  activeCheckbox?: boolean;
  onSelect?: () => void;
  dataT1?: string;
  isOpen?: boolean;
  setIsOpen: (val: boolean) => void;
  placeholder?: string;
  boldTextIfActive?: boolean;
  hasBackgroundWhenActive?: boolean;
  isDisabled?: boolean;
}
const variants = {
  open: {
    height: "auto",
  },
  close: {
    height: 0,
  },
};

const arrowVariants = {
  open: {
    transform: "scaleY(1)",
  },
  close: {
    transform: "scaleY(-1)",
  },
};

const transitionDuration = { duration: 0.2 };

const Select: FC<PropsWithChildren<PropsWithClasses<ISelectProps>>> = ({
  active = "",
  children = "",
  classes = {},
  closedBgColor = false,
  activeCheckbox = false,
  onSelect = () => {},
  dataT1 = "select",
  isOpen,
  setIsOpen,
  placeholder = "",
  boldTextIfActive,
  hasBackgroundWhenActive,
  isDisabled = false,
}) => {
  const toggling = () => {
    setIsOpen(!isOpen);
    onSelect();
  };

  const buttonClassName =
    !isOpen && closedBgColor ? classes.headerClosed : classes.header;

  const buttonBoldClass =
    boldTextIfActive &&
    (active as ReactElement)?.props?.children !== placeholder
      ? ` ${classes.boldText}`
      : "";

  const buttonBackgroundClass =
    hasBackgroundWhenActive &&
    (active as ReactElement).props?.children !== placeholder
      ? ` ${classes.activeBackground}`
      : "";

  const expandArrow = (
    <motion.div
      initial="close"
      animate={isOpen ? "open" : "close"}
      variants={arrowVariants}
      transition={transitionDuration}
      className={classes.arrowContainer}
    >
      <ArrowUpIcon className={classes.arrow} />
    </motion.div>
  );

  return (
    <div className={`${classes.root} ${isDisabled ? classes.disabled : ""}`}>
      <button
        type="button"
        data-t1={`${dataT1}_button`}
        data-t2={active}
        className={`${buttonClassName}${buttonBoldClass}${buttonBackgroundClass}`}
        onClick={toggling}
        disabled={isDisabled}
      >
        {activeCheckbox ? (
          <div className={classes.checkWrapper}>
            <span className={classes.check} data-t1={`${dataT1}_check`}>
              <CheckIcon className={classes.icon} />
            </span>
            <span
              className={classes.checkText}
              data-t1={`${dataT1}_check_text`}
              data-t2={active}
            >
              {active}
            </span>
          </div>
        ) : (
          active
        )}
        {expandArrow}
      </button>
      <motion.div
        initial="close"
        animate={isOpen ? "open" : "close"}
        variants={variants}
        transition={transitionDuration}
      >
        <ul className={classes.list} data-t1={`${dataT1}_section`}>
          {children}
        </ul>
      </motion.div>
    </div>
  );
};

export default classify<PropsWithChildren<PropsWithClasses<ISelectProps>>>(
  defaultClasses
)(Select);
export { Select };
