import { useSelector } from "react-redux";

const index = () => {
  const { config } = useSelector((state) => state.subscriptionQuote);

  const isGroupAvailable = (groupType: string | number): boolean => {
    if (Number(groupType) === 1) return config?.configuratorWindowOpen;
    return config?.configuratorWindowOpenUms;
  };

  const areBothGroupsAvailable =
    config?.configuratorWindowOpen && config?.configuratorWindowOpenUms;

  return [areBothGroupsAvailable, isGroupAvailable] as const;
};

export default index;
