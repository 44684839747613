/* eslint-disable consistent-return */
import { GeocoderServiceType } from "web/context/google.context";
import { Nullable } from "web/types/Utils";


import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

const getLocationName = ({
    placeId,
    geocoderService,
}: {
    placeId: Nullable<string>,
    geocoderService: GeocoderServiceType,
}) => {
    return new Promise((resolve, reject) => {
      if (placeId) {
        geocoderService?.geocode(
          {
            placeId,
          },
          (result, status) => {
            if (
              status === "OK" &&
              isArrayHasItems(result) &&
              result[0]?.address_components
            ) {
              const shortName = result[0].address_components.find(
                (item) => item.short_name
              )?.short_name;

              if (shortName) {
                resolve({
                  shortName,
                });
              }
            } else {
              return reject(new Error("Location not found"));
            }
          }
        );
      } else {
        resolve({
          shortName: "",
        });
      }
    });
};

export default getLocationName;
