import { FC, memo } from "react";

import classes from "./rentableGroupCalculatorSelectedGroup.scss";

interface IRentableGroupCalculatorSelectedGroupProps {
  incomeGroup?: string;
  incomeGroupCopy: string;
}

const RentableGroupCalculatorSelectedGroup: FC<IRentableGroupCalculatorSelectedGroupProps> =
  memo(({ incomeGroup, incomeGroupCopy }) => {
    return (
      <section className={classes.root}>
        <p className={classes.paragraph}>{incomeGroupCopy}</p>
        {incomeGroup && (
          <span className={classes.highlight}>{incomeGroup}</span>
        )}
      </section>
    );
  });
export default RentableGroupCalculatorSelectedGroup;
