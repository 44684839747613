const stringNumToFixed = (stringNum: string | number, fixedLength = 2) => {
  const parsed = +stringNum;
  if (Number.isNaN(parsed) || fixedLength < 0 || fixedLength > 100) {
    return stringNum;
  }

  return parsed.toFixed(fixedLength);
};

export default stringNumToFixed;
