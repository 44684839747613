import { names } from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import formatDate from "../../data/parser/dateAndTime/formatDate";
import jsonParse from "../../data/parser/string/jsonParse";
import addRidToParameters from "../domesticTourism/rid/addRidToParameters";

const values = [...Object.values(names), "rid"];

export default (
  search: string,
  dateFormatted = true,
  isJSONReturned = true
): string | Record<never, never> => {
  const params = new URLSearchParams(search);

  const data = values.reduce((result, value) => {
    if (params.has(value)) {
      const paramByKey = params.get(value);
      let paramByKeyProcessed: string;

      try {
        paramByKeyProcessed = paramByKey ? decodeURIComponent(paramByKey) : "";
      } catch (error) {
        newRelicErrorReport(
          error,
          "web-app/web/utils/system/url/getAdditionalFilterParametersUrl.ts - 25"
        );
        paramByKeyProcessed = paramByKey as string;
      }

      switch (value) {
        case names.tourismObject: {
          return {
            ...result,
            tourism_object: paramByKeyProcessed?.replaceAll("&", ""),
          };
        }
        case names.rooms: {
          return {
            ...result,
            rooms: jsonParse(paramByKeyProcessed),
          };
        }
        case names.dateTo: {
          return {
            ...result,
            date_to: dateFormatted
              ? formatDate(paramByKeyProcessed, true)
              : paramByKeyProcessed,
          };
        }
        case names.dateFrom: {
          return {
            ...result,
            date_from: dateFormatted
              ? formatDate(paramByKeyProcessed, true)
              : paramByKeyProcessed,
          };
        }
        default: {
          return result;
        }
      }
    }
    return result;
  }, {});

  if (!Object.keys(data)?.length) {
    return "";
  }

  const dataWithRid = addRidToParameters(data);

  return isJSONReturned ? JSON.stringify(dataWithRid) : dataWithRid;
};
