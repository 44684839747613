/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { names } from "web/Pages/Tourism/DomesticTourismForm/domesticTourismFormFields";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import domesticTourismPins from "web/queries/ms/domesticTourismPins.graphql";

import useQueryWrapper from "web/hooks/useQueryWrapper";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import chunkArray from "web/utils/data/transform/chunkArray";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getFilterParametersUrl from "web/utils/page/category/getFilterParametersUrl";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import getSearchParameter from "web/utils/system/url/getSearchParameter";

import type { DomesticTourismPins } from "web/types/Product";
import type { Nullable } from "web/types/Utils";

import Modal from "./mapModal";
import classes from "./mapModal.scss";

export interface IMapProducts {
  products: (DomesticTourismPins & { price: string })[];
  center: { lat: number; lng: number };
}

interface IMapModalContainerProps {
  ids: number[];
  prices: Nullable<Record<string, string>>;
}

const MapModalContainer: FC<IMapModalContainerProps> = ({
  ids,
  prices = null,
}) => {
  const { search } = useLocation();
  const [fetchMoreError, setFetchMoreError] = useState(null);
  const [canFetchMore, setCanFetchMore] = useState(true);
  const activeFilters = getFilterParametersUrl(search);
  const {
    token,
    id: storeId,
    google_api_key: googleApiKey,
  } = useSelector((state) => state.app.storeConfig);

  const chunkedIds = chunkArray<number>(
    ids?.map((item) => +item),
    400
  );

  const variables = {
    storeId,
    token,
    ...(isArrayHasItems(activeFilters) ? { attributes: activeFilters } : {}),
    ...(isArrayHasItems(chunkedIds) ? { ids: chunkedIds[0] } : {}),
  };

  const {
    loading,
    data,
    error: useQueryError,
    fetchMore,
  } = useQueryWrapper<{
    msProducts: { edges: { node: DomesticTourismPins }[] };
  }>(domesticTourismPins, {
    variables,
    context: {
      clientName: "ms",
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (chunkedIds.length <= 1 || !data || !canFetchMore) return;

    setCanFetchMore(false);
    chunkedIds.slice(1).forEach((idsArray) => {
      fetchMore({
        variables: { ...variables, ids: idsArray },
        updateQuery: (pv, { fetchMoreResult }) => {
          if (!fetchMoreResult) return pv;

          return {
            msProducts: {
              __typename: "msProductsConnection",
              edges: [
                ...pv.msProducts?.edges,
                ...(fetchMoreResult.msProducts?.edges || []),
              ],
            },
          };
        },
      }).catch((err) => setFetchMoreError(err));
    });
  }, [data]);

  const error = useQueryError || fetchMoreError;

  switch (true) {
    case !!error: {
      newRelicErrorReport(
        error,
        "web-app/web/Components/Common/Filters/Desktop/Map/MapModal/container.js - 90"
      );
      console.error(error);
      return (
        <div className={classes.root}>
          <ErrorComponent />
        </div>
      );
    }
    case (!!loading && (!data || !Object.keys(data).length)) ||
      // @ts-ignore
      ids.length > data?.msProducts?.edges?.length: {
      return (
        <div className={classes.root}>
          <Loading />
        </div>
      );
    }

    default: {
      // @ts-ignore
      const { products, center }: IMapProducts =
        data?.msProducts && isArrayHasItems(data.msProducts.edges)
          ? data.msProducts.edges.reduce<IMapProducts>(
              // @ts-ignore
              (result, current) => {
                if (current && current.node && current.node.geolocation_data) {
                  const latsArray = result.products.map(
                    (item) => +item.geolocation_data.lat
                  );
                  const lngsArray = result.products.map(
                    (item) => +item.geolocation_data.lon
                  );

                  const calculateAverage = (array: number[]) => {
                    let total = 0;
                    let count = 0;

                    array.forEach((item) => {
                      total += item;
                      count += 1;
                    });

                    return total / count;
                  };

                  const averageLat = calculateAverage(latsArray);
                  const averageLng = calculateAverage(lngsArray);
                  const latNext = result.center.lat
                    ? averageLat
                    : +current.node.geolocation_data.lat;
                  const lngNext = result.center.lng
                    ? averageLng
                    : +current.node.geolocation_data.lon;
                  return {
                    products: [
                      ...result.products,
                      {
                        ...current.node,
                        price:
                          prices && !!Object.keys(prices).length
                            ? prices[current.node.id]
                            : null,
                      },
                    ],
                    center: {
                      lat: latNext,
                      lng: lngNext,
                    },
                  };
                }

                return result;
              },
              { products: [], center: { lat: 0, lng: 0 } } as IMapProducts
            )
          : { products: [] };
      const locationParameter = getSearchParameter({
        name: names.location,
        search,
      });
      const { placeId } = locationParameter
        ? jsonParse<{ placeId: string }>(locationParameter)
        : ({} as { placeId: string });
      return isArrayHasItems(products) ? (
        <div className={classes.root}>
          <Modal
            storeId={storeId}
            token={token}
            products={products as (DomesticTourismPins & { price: string })[]}
            googleApiKey={googleApiKey}
            center={center}
            placeId={placeId}
          />
        </div>
      ) : (
        <div className={classes.root}>{__("Brak wyników wyszukiwania")}</div>
      );
    }
  }
};

export default MapModalContainer;
