import { motion } from "framer-motion";
import { FC, ReactNode, useCallback, useEffect, useState } from "react";

import __ from "web/Layout/Translations";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./switch.scss";

interface ISwitchProps {
  active: boolean;
  onChange: (val: boolean) => void;
  size?: number;
  activeBackgroundColor?: string;
  inactiveBackgroundColor?: string;
  activeDotColor?: string;
  inactiveDotColor?: string;
  activeXPosition?: string;
  inactiveXPosition?: string;
  label?: string | ReactNode;
  disabled?: boolean;
}

const transitionConfig = { duration: 0.2 };
const Switch: FC<PropsWithClasses<ISwitchProps>> = ({
  active,
  onChange,
  classes = {},
  size = 24,
  activeBackgroundColor = "#c0e9f8",
  inactiveBackgroundColor = "#d1e6e6",
  activeDotColor = "#00a1ed",
  inactiveDotColor = "#afcbcb",
  activeXPosition = "0%",
  inactiveXPosition = "100%",
  label,
  disabled = false,
}) => {
  const [isActive, toggleActiveStatus] = useState(!!active);
  const title = __("Przełącznik");
  const toggleActive = useCallback(() => {
    toggleActiveStatus((status) => {
      const newStatus = !status;

      if (typeof onChange === "function") {
        onChange(newStatus);
      }

      return newStatus;
    });
  }, [toggleActiveStatus, onChange]);

  useEffect(() => {
    toggleActiveStatus(!!active);
  }, [active]);

  const motionStatus = isActive ? "active" : "inactive";

  const dotVariants = {
    active: {
      x: activeXPosition,
      backgroundColor: activeDotColor,
    },
    inactive: {
      x: inactiveXPosition,
      backgroundColor: inactiveDotColor,
    },
  };

  const buttonVariants = {
    active: {
      backgroundColor: activeBackgroundColor,
    },
    inactive: {
      backgroundColor: inactiveBackgroundColor,
    },
  };

  return (
    <>
      <div className={classes.background}>
        <motion.button
          className={classes.root}
          variants={buttonVariants}
          initial={motionStatus}
          animate={motionStatus}
          transition={transitionConfig}
          type="button"
          onClick={toggleActive}
          style={{
            width: `${size * 2}px`,
            borderRadius: `${size / 2}px`,
          }}
          title={title}
          disabled={disabled}
          data-t2={title}
          data-t1="switch_button"
        >
          <motion.div
            variants={dotVariants}
            initial={motionStatus}
            animate={motionStatus}
            className={classes.dot}
            transition={transitionConfig}
            style={{
              height: `${size}px`,
              width: `${size}px`,
            }}
          />
        </motion.button>
      </div>
      {label ? (
        <button
          className={classes.label}
          type="button"
          onClick={toggleActive}
          disabled={disabled}
          data-t1="switch_button_label"
          data-t2={label}
        >
          {label}
        </button>
      ) : null}
    </>
  );
};

export default classify<PropsWithClasses<ISwitchProps>>(defaultClasses)(Switch);
