import { FC } from "react";

import CategoryLink from "./categoryLink";

interface ICategoryLinkContainerProps {
  className: string;
  id: string | number;
  dataT1: string;
  name?: string;
  onClick?: () => unknown;
}

const CategoryLinkContainer: FC<ICategoryLinkContainerProps> = ({
  className,
  id,
  dataT1,
  onClick,
  name,
}) => {
  switch (true) {
    case !!id: {
      return (
        <CategoryLink
          id={id}
          className={className}
          dataT1={dataT1}
          onClick={onClick}
        />
      );
    }
    case !!name: {
      return (
        <span className={className} data-t1="category_link_name">
          {name}
        </span>
      );
    }
    default:
      return null;
  }
};

export default CategoryLinkContainer;
