import { useQuery } from "@apollo/client";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import ArrowRightIcon from "web/assets/icons/arrow-right.svg";

import productTypesQuery from "web/queries/ms/productsTypesByIds.graphql";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import {
  BENEFIT_PRODUCT_TYPES,
  BENEFIT_URLKEY_TYPES,
} from "web/constants/benefits";

import type { ICategory } from "web/types/Category";

import classes from "./categories.scss";

interface ISearchAutocompleteWithSubscriptionsProps {
  categories: ICategory[];
  searchPhrase: string;
  closeAction: () => void;
  productIds: number[];
}

const SearchAutocompleteWithSubscriptions: FC<
  ISearchAutocompleteWithSubscriptionsProps
> = ({ categories = [], searchPhrase, closeAction, productIds }) => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const { token, id: storeId } = storeConfig;

  const options = useMemo(() => {
    return {
      variables: {
        ids: productIds,
        token,
        storeId,
      },
      context: {
        clientName: "ms",
      },
    };
  }, [searchPhrase, storeId, productIds, token]);

  const { loading, data } = useQuery<{
    msProducts: { edges: [{ node: { id: string; mb_product_type: string } }] };
  }>(productTypesQuery, options);

  const productTypes = data?.msProducts?.edges?.map((el) => {
    return el.node;
  });

  const categoriesFilteredWithSub =
    isArrayHasItems(categories) && isArrayHasItems(productTypes)
      ? categories.filter((category) => {
          if (category && category.id) {
            if (category?.url_key === BENEFIT_URLKEY_TYPES.GROUP_1) {
              return (
                productTypes?.findIndex(
                  (el) => el.mb_product_type === BENEFIT_PRODUCT_TYPES.GROUP_1
                ) !== -1
              );
            }
            if (category?.url_key === BENEFIT_URLKEY_TYPES.GROUP_2) {
              return (
                productTypes?.findIndex(
                  (el) => el.mb_product_type === BENEFIT_PRODUCT_TYPES.GROUP_2
                ) !== -1
              );
            }
            if (category?.url_key === BENEFIT_URLKEY_TYPES.GROUP_BOTH) {
              return (
                productTypes?.findIndex((el) =>
                  el.mb_product_type.includes(
                    BENEFIT_PRODUCT_TYPES.GROUP_1 ||
                      BENEFIT_PRODUCT_TYPES.GROUP_2
                  )
                ) !== -1
              );
            }
            if (storeConfig && storeConfig.root_category_id) {
              return `${storeConfig.root_category_id}` !== `${category.id}`;
            }

            return true;
          }
          return false;
        })
      : [];

  if (loading) return <Loading />;

  return isArrayHasItems(categoriesFilteredWithSub) ? (
    <>
      <h3 className={classes.title} data-t1="autocomplete_category_title">
        {__("Kategorie")}
      </h3>
      <ul className={classes.list}>
        {categoriesFilteredWithSub.map((category) => {
          const url = `/search?search=${searchPhrase}&category_ids=${category.id}`;
          return (
            <li className={classes.item} key={category.id}>
              <Link
                to={url}
                className={classes.link}
                onClick={closeAction}
                data-t1="autocomplete_category_link"
              >
                {category.name}
                <ArrowRightIcon className={classes.icon} width={10} />
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  ) : null;
};

export default SearchAutocompleteWithSubscriptions;
