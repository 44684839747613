export default {
  trip_goal: "Cel wyjazdu",
  booking_nr: "Numer rezerwacji",
  cost_all_booking: "Wartość całej rezerwacji",
  consultant_email: "Konsultant biura podróży",
  date: "Termin wyjazdu",
  mb_payment_amount: "Płatność z MyBenefit",
  email: "E-mail",
  phone: "Numer telefonu",
  orderPartiallyPaidStates: [
    "partially_paid",
    "partially_paid_booking_confirmed",
  ],
  cancellationPolicyMessages: {
    NonRefundable: "Oferta bezzwrotna - brak możliwości anulacji.",
    Refundable: "Zapoznaj się z kosztami anulacji Twojej rezerwacji.",
    FeeApplies: "Zapoznaj się z kosztami anulacji Twojej rezerwacji.",
    Unknown:
      "Partner nie udostępnił nam szczegółowych informacji o anulacji czy zmianie rezerwacji dokonywanych w jego Obiekcie. W celu uzyskania informacji w tym zakresie, prosimy o bezpośredni kontakt z Partnerem.",
    Free: "Partner nie udostępnił nam szczegółowych informacji o anulacji czy zmianie rezerwacji dokonywanych w jego Obiekcie. W celu uzyskania informacji w tym zakresie, prosimy o bezpośredni kontakt z Partnerem.",
  },
};
