// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additionalText-root-KBb{font-size:13px;font-weight:400;margin:0 0 5px}`, "",{"version":3,"sources":["webpack://./web/Layout/ProductCard/Default/AdditionalText/additionalText.scss"],"names":[],"mappings":"AAIuB,yBACrB,cAAA,CACA,eAAA,CACA,cAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .root {\n  font-size: 13px;\n  font-weight: 400;\n  margin: 0 0 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `additionalText-root-KBb`
};
export default ___CSS_LOADER_EXPORT___;
