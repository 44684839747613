// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-banner-h5M{width:100%;margin-bottom:30px;display:flex;overflow:hidden;align-items:center;justify-content:center}.banner-banner__image-L6W{max-width:100%;max-height:100%}`, "",{"version":3,"sources":["webpack://./web/Components/Common/Banner/banner.scss"],"names":[],"mappings":"AAIuB,mBACnB,UAAA,CACA,kBAAA,CACA,YAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CAEA,0BACI,cAAA,CACA,eAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .banner {\n    width: 100%;\n    margin-bottom: 30px;\n    display: flex;\n    overflow: hidden;\n    align-items: center;\n    justify-content: center;\n\n    &__image {\n        max-width: 100%;\n        max-height: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `banner-banner-h5M`,
	"banner__image": `banner-banner__image-L6W`
};
export default ___CSS_LOADER_EXPORT___;
