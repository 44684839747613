import { FC, PropsWithChildren } from "react";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./selectItem.scss";

interface ISelectItemProps {
  index: number;
  onSelect?: (val: number) => void;
  dataT1?: string;
  dataT2?: string;
}

const SelectItem: FC<PropsWithChildren<PropsWithClasses<ISelectItemProps>>> = ({
  index,
  onSelect = () => {},
  children,
  classes = {},
  dataT1 = "select_item",
  dataT2 = "",
}) => {
  return (
    <li className={classes.root} data-t1={`${dataT1}_option`} data-t2={dataT2}>
      <button
        type="button"
        onClick={() => onSelect(index)}
        className={classes.button}
        data-t1={`${dataT1}_button`}
        data-t2={dataT2}
      >
        {children}
      </button>
    </li>
  );
};

export default classify<PropsWithChildren<PropsWithClasses<ISelectItemProps>>>(
  defaultClasses
)(SelectItem);
