import { FC, useMemo } from "react";
import { useSelector } from "react-redux";

import TourismLocation from "web/Pages/Product/Shared/Tourism/TourismLocation";
import TourismObjectInfo from "web/Pages/Product/Shared/Tourism/TourismObjectInfo";

import SubscriptionsDatesInfo from "web/Layout/SubscriptionsDatesInfo";
import __ from "web/Layout/Translations";

import EventValidDate from "web/Components/Common/EventValidDate";
import SaleDate from "web/Components/Common/SaleDate";
import ToolTip from "web/Components/Common/ToolTip";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import {
  getProductTypeWindowDate,
  isSubscriptionDisabled,
} from "web/utils/page/product/subscription/windowGroupConfig";

import { BENEFIT_PRODUCT_TYPES } from "web/constants/benefits";

import {
  DomesticTourismProduct,
  IProduct,
  MBProductType,
} from "web/types/Product";
import { SubscriptionsDatesInfoVariants } from "web/types/Subscription";

import { useGetEmployeeConfigurationQuery } from "web/features/employee/employeeApiSlice";

import AdditionalText from "../AdditionalText";
import Facilities from "../Facilities";
import Location from "../Location";
import Place from "../Place";
import classes from "../productCard.scss";

interface IProductCardBodyProps {
  product: IProduct<void | DomesticTourismProduct>;
  isAdditionalExist: boolean;
}

const ProductCardBody: FC<IProductCardBodyProps> = ({
  product,
  isAdditionalExist,
}) => {
  const { data: employeeConfig } = useGetEmployeeConfigurationQuery();
  const { subscriptionThresholdDay, subscriptionThresholdDayUMS } =
    employeeConfig || {};

  const {
    open: isWindowOpen,
    selectionWindowType,
    openUms: isUmsWindowOpen,
    selectionWindowTypeUms,
    selectionWindowOpenToDate,
  } = useSelector((state) => state.subscriptionQuote.config);
  const inStock = +product?.stock_status > 0;
  const isDisabled = useMemo(
    () =>
      isSubscriptionDisabled(
        product.mb_product_type,
        isWindowOpen,
        isUmsWindowOpen
      ),
    [product.mb_product_type, isWindowOpen, isUmsWindowOpen]
  );

  switch (product.mb_product_type) {
    case MBProductType.UNIVERSAL_CODE: {
      return (
        <>
          <Location
            cities={product.location_cities_cities}
            province={product.location_province}
            location={product.location_location_parent_location_parent}
            city={product.location_address_city}
            code={product.location_address_postal_code}
            street={product.location_address_street}
          />
          {product?.code_realization_place && (
            <Place name={product.code_realization_place} />
          )}
          {(inStock || !!product.event_date || !product.code_valid_date) && (
            <EventValidDate
              className={classes.eventDate}
              date={product.event_date!}
              time={product.event_time!}
              validDate={product.code_valid_date!}
            />
          )}
          {isAdditionalExist ? (
            <div className={classes.additional}>
              <SaleDate
                className={classes.saleDate}
                from={product.sale_date_from!}
                to={product.sale_date_to!}
              />
              <AdditionalText text={product.additional_short_text} />
            </div>
          ) : null}
        </>
      );
    }
    case MBProductType.INTERNATIONAL_TOURISM_OTHER:
    case MBProductType.INTERNATIONAL_TOURISM_TRAVELPLANET: {
      return (
        <p className={classes.tourism}>
          {product && product.facility_web_page ? (
            <>
              <span>{product.facility_web_page}</span>
              <br />
            </>
          ) : null}
          {__("Zarezerwuj usługę w oddziale lub zadzwoń")}
        </p>
      );
    }
    case MBProductType.ANIXE_TOURISM: {
      const productTyped = product as IProduct<DomesticTourismProduct>;
      return (
        <>
          <div className={classes.tourismDomestic}>
            <TourismObjectInfo
              className={classes.tourismDomesticItem}
              product={product as DomesticTourismProduct}
              short
            />
            {product && (
              <TourismLocation
                className={classes.tourismDomesticItem}
                product={product as DomesticTourismProduct}
                short
              />
            )}
          </div>
          {isArrayHasItems(product?.tourism_facilities) && (
            <Facilities
              className={classes.facilities}
              facilities={product.tourism_facilities}
            />
          )}
          {isAdditionalExist &&
          isArrayHasItems(productTyped.tourism_anixe_min_offer?.rooms) ? (
            <div className={classes.additional}>
              <div className={classes.additionalTourismDomestic}>
                <strong>{__("Najtańsza opcja w wybranym terminie")}</strong>
                {product.tourism_anixe_min_offer?.rooms.map((room) => {
                  const key = `${room.mhr}${room.room_type}`;
                  return (
                    <div key={key}>
                      {room.num}. {__("pokój")}
                      <br />
                      {__("Wyżywienie")}
                      {": "}
                      {__(room.meal_name?.trim())}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </>
      );
    }
    case BENEFIT_PRODUCT_TYPES.GROUP_1:
    case BENEFIT_PRODUCT_TYPES.GROUP_2: {
      const windowDate = getProductTypeWindowDate(
        product.mb_product_type,
        selectionWindowOpenToDate,
        selectionWindowType,
        subscriptionThresholdDay as string,
        selectionWindowTypeUms,
        subscriptionThresholdDayUMS as string
      );

      return (
        <>
          {windowDate && (
            <SubscriptionsDatesInfo
              finalDates={windowDate}
              variant={SubscriptionsDatesInfoVariants.PRODUCT}
              highlightColor="#102b3f"
            />
          )}
          {isDisabled && (
            <ToolTip message={__("Minął termin wyboru abonamentów")} />
          )}
        </>
      );
    }
    default: {
      return null;
    }
  }
};

export default ProductCardBody;
