import { motion } from "framer-motion";
import { FC } from "react";

import { availableLanguages } from "web/Layout/Translations/LanguageWrapper";

import { PropsWithClassesExternalForm } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "../LanguageSwitcher/languageSwitcher.scss";
import SharedLayoutAnimation from "../SharedLayoutAnimation";

const LanguageSwitcherExternalForm: FC<PropsWithClassesExternalForm> = ({
  classes = {},
  lang,
  changeLanguage,
}) => {
  return (
    <div className={classes.root} data-t1="language_switcher">
      <SharedLayoutAnimation groupId="language_switcher">
        <ul className={classes.list}>
          {availableLanguages.map(({ key, label, name, code }) => {
            const isActive = key === lang.key;

            return (
              <motion.li key={label} className={classes.item}>
                {isActive && (
                  <motion.div layoutId="active" className={classes.active} />
                )}
                <button
                  type="button"
                  data-t1="language_switcher_button"
                  data-t2={name}
                  className={classes.button}
                  style={{ color: isActive ? "black" : undefined }}
                  onClick={() => changeLanguage({ key, code })}
                  title={label}
                >
                  {name}
                </button>
              </motion.li>
            );
          })}
        </ul>
      </SharedLayoutAnimation>
    </div>
  );
};

export default classify<PropsWithClassesExternalForm>(defaultClasses)(
  LanguageSwitcherExternalForm
);
