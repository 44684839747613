import { FC, ReactElement, useCallback } from "react";
import { useDispatch } from "react-redux";

import Modal, {
  SubscriptionModalCloseBtn,
} from "web/Layout/SubscriptionModal/subscriptionModal";
import __ from "web/Layout/Translations";

import WarningIcon from "web/assets/icons/warning.svg";

import { ModalActionTypes } from "web/hooks/useModal/useModalTypes";

import {
  FormType,
  HandleToggleReceiversModalFn,
  ISubscriptionItemCurrentUserSubscription,
  ISubscriptionTotals,
  ISubscriptionUserCurrent,
} from "web/types/Subscription";

import { useAppContext } from "web/context/app";
import { subscriptionsApiSlice } from "web/features/subscriptions/subscriptionsApiSlice";

import ActivityItem, { ActivityItemProps } from "../activityItem";
import ItemFormToFill from "./ItemFormToFill";
import classes from "./formFillerItem.scss";

interface IFormFillerActivityItemItemProps extends ActivityItemProps {
  benefitsWithFormsToUpdate: ISubscriptionItemCurrentUserSubscription[];
  setDataRequiredActivities: React.Dispatch<
    React.SetStateAction<ISubscriptionUserCurrent>
  >;
  totals: ISubscriptionTotals;
}

type UpdatedReceiverType = Parameters<HandleToggleReceiversModalFn>[0];

const FormFillerItem: FC<IFormFillerActivityItemItemProps> = ({
  benefitsWithFormsToUpdate,
  setDataRequiredActivities,
  totals,
  ...restProps
}) => {
  const { modal } = useAppContext();
  const { dispatch } = modal;
  const dispatchRedux = useDispatch();

  const onSetModalHandler = useCallback(
    (content: ReactElement) => {
      dispatch({ type: ModalActionTypes.ADD_MODAL, modal: content });
    },
    [dispatch]
  );

  const onCloseModalHandler = useCallback(() => {
    dispatch({ type: ModalActionTypes.RESET });
  }, [dispatch]);

  const description = (
    <div className={classes.warning}>
      <WarningIcon className={classes.warning__sign} />
      <span className={classes.warning__info}>
        {__(`
              Uzupełnij swoje dane dotyczące abonamentów, 
              które wybrałeś lub wpisz adres e-mail osób dla których wybrałeś abonament.
            `)}
      </span>
    </div>
  );

  const onOpenModalHandler = useCallback(
    (updatedReceiver?: UpdatedReceiverType) => {
      let newItems: ISubscriptionItemCurrentUserSubscription[] = [];

      if (updatedReceiver) {
        setDataRequiredActivities((prevData) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          newItems = prevData.items.map((item) => {
            const currentReceiversData = item?.receivers?.map((receiver) => {
              if (receiver.id === updatedReceiver.id) {
                const fieldsForReceiver = updatedReceiver.fields
                  ? receiver.form.fields.map((field) => {
                      return {
                        ...field,
                        value: updatedReceiver.fields?.[field.id] ?? "",
                      };
                    })
                  : [];

                return {
                  ...receiver,
                  form: {
                    ...receiver.form,
                    ...(updatedReceiver.receiverEmail
                      ? { receiverEmail: updatedReceiver.receiverEmail }
                      : { fields: fieldsForReceiver }),
                    awaitsUpdate: false,
                    completed: receiver.formType === FormType.ACTIVE_FORM,
                  },
                };
              }

              return receiver;
            });

            return {
              ...item,
              receivers: currentReceiversData,
            };
          });
          return {
            ...prevData,
            items: newItems,
          };
        });
      }

      // filter out all items that don't need updating
      const newItemsToUpdate = [...newItems]?.filter((newItem) => {
        const receiversNeedUpdating = newItem.receivers?.some((receiver) => {
          if (receiver?.form?.awaitsUpdate) return true;
          return false;
        });
        return receiversNeedUpdating;
      });

      const benefitsToUpdate = updatedReceiver
        ? newItemsToUpdate
        : benefitsWithFormsToUpdate;

      /*
        @TODO
        1. Analize if we should move from RTK query to redux thunks
        2. Keep this reloading and remove updating state
      */
      dispatchRedux(
        subscriptionsApiSlice.util.invalidateTags(["CurrentUserSubscriptions"])
      );

      onSetModalHandler(
        <Modal
          title={__("Zmieniły się wymagania w formularzach")}
          description={description}
          additionalChildren={
            <SubscriptionModalCloseBtn onClick={onCloseModalHandler}>
              {__("Zamknij")}
            </SubscriptionModalCloseBtn>
          }
          classes={{ content: classes.modalContent }}
        >
          {benefitsToUpdate.map((item: any) => {
            return (
              <ItemFormToFill
                key={item["@id"]}
                item={item}
                handleToggleEditModal={onOpenModalHandler}
              />
            );
          })}
        </Modal>
      );
    },
    [onCloseModalHandler, onSetModalHandler, benefitsWithFormsToUpdate]
  );

  return (
    <ActivityItem {...restProps} onClick={() => onOpenModalHandler()}>
      {__("Uzupełnij dane")}
    </ActivityItem>
  );
};

export default FormFillerItem;
