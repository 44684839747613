import { FC, PropsWithChildren } from "react";

import Labels from "web/Layout/SubscriptionGroupSummary/Labels";

import GroupThumbnail from "web/Components/Common/GroupThumbnail";

import { PropsWithClasses } from "web/types/Common";
import { Nullable } from "web/types/Utils";

import styles from "./subscriptionGroupSummary.scss";

interface ISubscriptionSummaryProps {
  benefitGroupType: string;
  extendedView?: boolean;
  name: string;
  groupPhotoURL: Nullable<string>;
}

const SubscriptionGroupSummary: FC<
  PropsWithChildren<PropsWithClasses<ISubscriptionSummaryProps>>
> = ({
  benefitGroupType = "1",
  children,
  extendedView = false,
  name,
  groupPhotoURL,
}) => {
  const displayPhoto = benefitGroupType === "2";
  const classesHeader = displayPhoto ? styles.headerUMS : styles.header;

  return (
    <section className={styles.root}>
      <header className={classesHeader}>
        {displayPhoto && <GroupThumbnail src={groupPhotoURL} alt={name} />}
        <h3 className={styles.header__title}>{name}</h3>
      </header>
      <section className={styles.body}>
        <Labels
          extendedView={extendedView}
          benefitGroupType={benefitGroupType}
        />
        {children}
      </section>
    </section>
  );
};

export default SubscriptionGroupSummary;
