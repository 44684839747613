// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-image-n7c{position:absolute;top:0;right:0;bottom:0;left:0;z-index:1;width:100%;height:100%;-o-object-fit:cover;object-fit:cover}`, "",{"version":3,"sources":["webpack://./web/Layout/ProductCard/Default/Image/image.scss"],"names":[],"mappings":"AAIuB,iBACrB,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,SAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA,CAAA,gBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .image {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 1;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `image-image-n7c`
};
export default ___CSS_LOADER_EXPORT___;
