import { FC } from "react";

import { Nullable } from "web/types/Utils";

import classes from "./additionalText.scss";

interface IProductAdditionalText {
  text?: Nullable<string>;
}

const ProductCardAdditionalText: FC<IProductAdditionalText> = ({ text }) => {
  return text ? <div className={classes.root}>{text}</div> : null;
};

export default ProductCardAdditionalText;
