import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { IAgreement } from "web/types/Agreement";

const sortRegulations = (a: IAgreement, b: IAgreement) => {
  if (new Date(a.createdAt) > new Date(b.createdAt)) {
    return -1;
  }
  return 1;
};

const filterAgreementsById = (agreements: IAgreement[]) => {
  const acceptedAgreements: IAgreement[] = [];
  if (isArrayHasItems(agreements)) {
    agreements.reverse().sort(sortRegulations).forEach((item) => {
      if (
        acceptedAgreements.find(
          ({ agreementId }) => agreementId === item.agreementId
        )
      )
        return;

      acceptedAgreements.push(item);
    });
  }
  return acceptedAgreements;
};

export default filterAgreementsById;
