/* eslint-disable react/prop-types */
import { arrayOf, bool, shape, string } from "prop-types";
import { useMemo } from "react";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

import CodeAttributeList from "./codeAttributeList";
import DetailsList from "./detailsList";

const ContainerVariant = ({
  className,
  classes,
  items,
  codesVariant,
  codeDetails,
  producSuperOptions,
  variant,
}) => {
  if (!isArrayHasItems(items)) {
    return null;
  }

  if (codesVariant) {
    return (
      <CodeAttributeList
        codeDetails={codeDetails}
        classes={classes}
        className={className}
        items={items}
        producSuperOptions={producSuperOptions}
      />
    );
  }

  return (
    <DetailsList
      classes={classes}
      className={className}
      items={items}
      variant={variant}
    />
  );
};

ContainerVariant.propTypes = {
  className: string,
  codesVariant: bool,
  classes: shape({}),
  items: arrayOf(shape({})),
  producSuperOptions: shape({}),
  codeDetails: shape({}),
};

ContainerVariant.defaultProps = {
  codesVariant: false,
  className: "",
  classes: {},
  items: [],
  producSuperOptions: {},
  codeDetails: {},
};

const ContainerWithAttributes = ({
  className,
  classes,
  items,
  attributes,
  codesVariant,
  codeDetails,
  variant,
}) => {
  const ids = useMemo(() => {
    return attributes?.map((attribute) => attribute && attribute.attribute);
  }, [JSON.stringify(attributes)]);

  const { loading, error, data } = useDataCachedAttributes({
    ids,
  });

  switch (true) {
    case !!loading: {
      return <Loading />;
    }
    case !!error: {
      console.error(error);
      return <ErrorComponent />;
    }
    default: {
      const itemsProcessed =
        isArrayHasItems(data) && isArrayHasItems(items)
          ? items.map((item) => {
              if (item && item.attribute && item.option) {
                const currentAttribute = data.find(
                  (dataItem) =>
                    dataItem && dataItem.attribute_code === item.attribute
                );
                const currentOption =
                  currentAttribute &&
                  isArrayHasItems(currentAttribute.attribute_options) &&
                  currentAttribute.attribute_options.find(
                    (option) => option && `${option.value}` === `${item.option}`
                  );

                return currentOption && currentOption.label
                  ? {
                      value: currentOption.label,
                      label: currentAttribute.attribute_label,
                    }
                  : item;
              }
              return item;
            }, [])
          : items;
      return (
        <ContainerVariant
          classes={classes}
          className={className}
          items={itemsProcessed}
          codesVariant={codesVariant}
          codeDetails={codeDetails}
          variant={variant}
        />
      );
    }
  }
};

ContainerWithAttributes.propTypes = {
  className: string,
  classes: shape({}),
  attributes: arrayOf(shape({})).isRequired,
  items: arrayOf(shape({})).isRequired,
  codesVariant: bool,
  codeDetails: shape({}),
};

ContainerWithAttributes.defaultProps = {
  className: "",
  classes: {},
  codesVariant: false,
  codeDetails: {},
};

const Container = ({
  className,
  classes,
  items,
  codesVariant,
  codeDetails,
  producSuperOptions,
  variant = "",
}) => {
  const attributes = isArrayHasItems(items)
    ? items.reduce((result, current) => {
        return current && current.attribute && current.option
          ? [...result, current]
          : result;
      }, [])
    : null;

  return isArrayHasItems(attributes) ? (
    <ContainerWithAttributes
      attributes={attributes}
      classes={classes}
      className={className}
      items={items}
      codesVariant={codesVariant}
      codeDetails={codeDetails}
      variant={variant}
    />
  ) : (
    <ContainerVariant
      classes={classes}
      className={className}
      items={items}
      codesVariant={codesVariant}
      codeDetails={codeDetails}
      producSuperOptions={producSuperOptions}
      variant={variant}
    />
  );
};

Container.propTypes = {
  className: string,
  classes: shape({}),
  items: arrayOf(shape({})),
  codesVariant: bool,
  codeDetails: shape({}),
  producSuperOptions: shape({}),
  variant: string,
};

Container.defaultProps = {
  className: "",
  classes: {},
  items: [],
  codesVariant: false,
  codeDetails: {},
  producSuperOptions: {},
};

export default Container;
