import { FC } from "react";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";

import useDataCachedCategories from "web/features/useDataCached/useDataCachedCategories";

import Categories from "./categories";

interface ISearchAutocompleteCategoriesMobileProps {
  ids: number[];
  searchPhrase: string;
  closeAction: () => void;
}

const SearchAutocompleteCategoriesMobile: FC<
  ISearchAutocompleteCategoriesMobileProps
> = ({ ids = [], searchPhrase, closeAction }) => {
  const { loading, error, data } = useDataCachedCategories({
    ids,
  });

  switch (true) {
    case !!loading: {
      return <Loading />;
    }
    case !!error: {
      return <ErrorComponent />;
    }
    default: {
      return (
        <Categories
          categories={data!}
          searchPhrase={searchPhrase}
          closeAction={closeAction}
        />
      );
    }
  }
};

export default SearchAutocompleteCategoriesMobile;
