import { motion } from "framer-motion";
import { FC } from "react";

import ArrowUpIcon from "web/assets/icons/arrow-down.svg";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultStyles from "./dropArrow.scss";

const variants = {
  open: {
    transform: "scaleY(1)",
  },
  close: {
    transform: "scaleY(-1)",
  },
};

const transition = { duration: 0.2 };

interface IDropArrowProps {
  open?: boolean;
  size?: "small" | "medium" | "large";
}

const DropArrow: FC<PropsWithClasses<IDropArrowProps>> = ({
  open = true,
  size = "medium",
  classes = {},
}) => {
  const rootClasses = `${classes.root} ${classes[`root_${size}`]} `;

  return (
    <motion.div
      initial="close"
      animate={open ? "open" : "close"}
      variants={variants}
      transition={transition}
      className={rootClasses}
    >
      <ArrowUpIcon className={classes.icon} />
    </motion.div>
  );
};

export default classify<PropsWithClasses<IDropArrowProps>>(defaultStyles)(
  DropArrow
);
