/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";

import __ from "web/Layout/Translations";
import {
  getLanguageCode,
  setLocaleField,
} from "web/Layout/Translations/LanguageWrapper";

import Anchor from "web/Components/Common/Anchor";

import type { PropsWithClasses } from "web/types/Common";
import {
  ICategoryMenuItem,
  IFunctionalityMenuItem,
  IUrlFunctionalityMenuItem,
  IUrlMenuItem,
  MenuItemType,
} from "web/types/Menu";
import { Nullable } from "web/types/Utils";

import classify from "web/classify";

import defaultClasses from "./mainMenuItem.scss";

interface IMainMenuItemProps {
  setIndex: (index: Nullable<number>) => void;
  dataT1: string;
  item:
    | IUrlMenuItem
    | ICategoryMenuItem<MenuItemType.URL_CATEGORY | MenuItemType.CATEGORY>
    | IUrlFunctionalityMenuItem
    | IFunctionalityMenuItem;
}

const MainMenuItem: FC<PropsWithClasses<IMainMenuItemProps>> = ({
  classes = {},
  item,
  setIndex,
  dataT1 = "main_menu_item_anchor_title",
}) => {
  const currentLanguage = getLanguageCode();
  const url =
    // @ts-ignore
    item.category_request_path ||
    // @ts-ignore
    setLocaleField(currentLanguage, item.url, item.url_en);
  return (
    <Anchor
      className={classes.link}
      onClick={() => setIndex(null)}
      to={url}
      title={item.title}
      dataT1={dataT1}
    >
      {__(item.title)}
    </Anchor>
  );
};

export default classify<PropsWithClasses<IMainMenuItemProps>>(defaultClasses)(
  MainMenuItem
);
