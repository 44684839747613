import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import __ from "web/Layout/Translations";

import ArrowRightIcon from "web/assets/icons/arrow-right.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { ICategory } from "web/types/Category";

import classes from "./categories.scss";

interface ISearchAutocompleteCategoriesProps {
  categories: ICategory[];
  searchPhrase: string;
  closeAction: () => void;
}

const SearchAutocompleteCategories: FC<ISearchAutocompleteCategoriesProps> = ({
  categories = [],
  searchPhrase,
  closeAction,
}) => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const categoriesFiltered = isArrayHasItems(categories)
    ? categories.filter((category) => {
        if (category && category.id) {
          if (storeConfig && storeConfig.root_category_id) {
            return `${storeConfig.root_category_id}` !== `${category.id}`;
          }
          return true;
        }
        return false;
      })
    : [];

  return isArrayHasItems(categoriesFiltered) ? (
    <>
      <h3 className={classes.title} data-t1="autocomplete_category_title">
        {__("Kategorie")}
      </h3>
      <ul className={classes.list}>
        {categoriesFiltered.map((category) => {
          const url = `/search?search=${searchPhrase}&category_ids=${category.id}`;
          return (
            <li className={classes.item} key={category.id}>
              <Link
                to={url}
                className={classes.link}
                onClick={closeAction}
                data-t1="autocomplete_category_link"
              >
                {category.name}
                <ArrowRightIcon className={classes.icon} width={10} />
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  ) : null;
};

export default SearchAutocompleteCategories;
