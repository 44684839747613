import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IAttribute } from "web/types/Attributes";

const getProvinceLabel = (
  attributesData: IAttribute[],
  provinceCode?: string | string[]
) => {
  const language = localStorage.getItem("language") || "pl_PL";
  const isPL = language === "pl_PL";

  if (provinceCode && typeof provinceCode === "string") {
    return isPL ? `w. ${provinceCode.toLowerCase()}` : provinceCode;
  }

  if (
    !isArrayHasItems(attributesData?.[0]?.attribute_options) ||
    !isArrayHasItems(provinceCode)
  ) {
    return null;
  }

  const currentOption = attributesData[0].attribute_options.find((option) => {
    return `${option.value}` === `${provinceCode[0]}`;
  });

  const provinceLabel =
    currentOption?.label && isPL
      ? `w. ${currentOption.label.toLowerCase()}`
      : currentOption?.label;

  return provinceLabel;
};

export default getProvinceLabel;
