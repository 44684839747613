import {
  IFormConfig,
  IFormsData,
} from "web/Pages/Checkout/Subscription/InformationStep/withContext";

import isArrayValuesUnique from "web/utils/data/validator/array/isArrayValuesUnique";

export default (formConfig: IFormConfig, formsData: IFormsData) => {
  let isUniqueForCurrentForms = true;
  let isUniqueForPreviousForms = true;

  if (formConfig.validateUniquePesel) {
    const { receiverId, subscriptionId, validateUniquePesel } = formConfig;
    const { value: peselToValidate } = validateUniquePesel;
    const pesels: string[] = [];

    // iterate through all edited forms
    Object.keys(formsData).forEach((formData) => {
      const {
        fields,
        receiverId: currentFormReceiverId,
        subscriptionId: currentFormSubscriptionId,
      } = formsData[formData];

      // check values only from the same subscription
      if (subscriptionId === currentFormSubscriptionId && !!fields) {
        // add PESEL to compare among other currently filled in forms
        pesels.push(fields.pesel as string);

        // check if currently filled in form has the same PESEL as any previous form
        if (
          peselToValidate === fields.pesel &&
          receiverId !== currentFormReceiverId
        )
          isUniqueForPreviousForms = false;
      }
    });
    // check if all currently filled in forms have unique values
    isUniqueForCurrentForms = isArrayValuesUnique(pesels);
  }

  return isUniqueForCurrentForms && isUniqueForPreviousForms;
};
