// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscriptionReceiverDuplicateCard-duplicate__orderButton-kjK{margin:1rem 0 0 2rem}.subscriptionReceiverDuplicateCard-duplicate__orderedText-Mho{font-weight:600;margin:10px 0}.subscriptionReceiverDuplicateCard-duplicate__info-Tqn{flex:1 1 100%;margin-top:2rem}.subscriptionReceiverDuplicateCard-duplicate__infoText-H4f{font-size:15px}`, "",{"version":3,"sources":["webpack://./web/Layout/SubscriptionReceivers/SubscriptionReceiverDuplicateCard/subscriptionReceiverDuplicateCard.scss"],"names":[],"mappings":"AAKE,8DACE,oBAAA,CAGF,8DACE,eAAA,CACA,aAAA,CAGF,uDACE,aAAA,CACA,eAAA,CAGF,2DACE,cAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .duplicate {\n  &__orderButton {\n    margin: 1rem 0 0 2rem;\n  }\n\n  &__orderedText {\n    font-weight: 600;\n    margin: 10px 0;\n  }\n\n  &__info {\n    flex: 1 1 100%;\n    margin-top: 2rem;\n  }\n\n  &__infoText {\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"duplicate__orderButton": `subscriptionReceiverDuplicateCard-duplicate__orderButton-kjK`,
	"duplicate__orderedText": `subscriptionReceiverDuplicateCard-duplicate__orderedText-Mho`,
	"duplicate__info": `subscriptionReceiverDuplicateCard-duplicate__info-Tqn`,
	"duplicate__infoText": `subscriptionReceiverDuplicateCard-duplicate__infoText-H4f`
};
export default ___CSS_LOADER_EXPORT___;
