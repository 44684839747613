/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import { SkeletonProductsList } from "web/Layout/Common/SkeletonComponents";
import ErrorBoundary from "web/Layout/ErrorBoundary";
import ListingHeader from "web/Layout/Listing/Common/Header";
import classes from "web/Layout/Listing/listing.scss";
import classesMobile from "web/Layout/Listing/listingMobile.scss";

import Categories from "web/Components/Common/Categories";
import Filters from "web/Components/Common/Filters";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import areSubscriptionIdsEqual from "web/utils/page/category/areSubscriptionIdsEqual";

import { pageDefault, pageName, sortOptions } from "web/constants/toolbar";

import { Facets } from "web/types/Facets";
import { Stats } from "web/types/Stats";
import { ISubscriptionItemMs } from "web/types/Subscription";

import { useAppContext } from "web/context/app";
import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

import BannerCampaign from "./BannerCampaign";
import Products from "./Products";

const facetsHidden = ["mb_product_type_filter", "category_ids"];
const facetsHiddenForSpecialCategories = [
  "mb_product_type_filter",
  "category_ids",
  "geolocation_data",
];

interface IListingSubscriptionsProps {
  ids: (string | number)[];
  facets: Facets;
  stats: Stats;
  name: string;
  count?: number;
  sortOptions: (typeof sortOptions)[];
  additionalParameters: string;
  categoriesPath: string;
  subscriptionItems?: ISubscriptionItemMs[];
}

const ListingSubscriptions: FC<IListingSubscriptionsProps> = ({
  ids = null,
  facets = [],
  stats = [],
  name,
  count = 0,
  sortOptions,
  additionalParameters,
  categoriesPath = "",
  subscriptionItems = [],
}) => {
  const { storeConfig, isFiltersLoading } = useSelector((state) => state.app);
  const { grid_per_page: gridPerPage } = storeConfig;
  const { isMobile } = useAppContext();
  const { search } = useLocation();
  const paramsSearch = new URLSearchParams(search);

  const currentPageParam = paramsSearch.get(pageName);
  const currentPage =
    (currentPageParam && parseInt(currentPageParam, 10)) || pageDefault;

  const idsSliced = useMemo(() => {
    return isArrayHasItems(ids)
      ? ids
          .slice(0, gridPerPage * currentPage)
          .reduce<(string | number)[]>(
            (result, id) => (id && +id ? [...result, +id] : result),
            []
          )
      : [];
  }, [JSON.stringify(ids), currentPage]);

  const options = useMemo(() => {
    const filterCodes = [
      ...(stats?.map((stat) => stat.code) || []),
      ...(facets?.map((facet) => facet.code) || []),
    ];
    const filterFilteredCodes = filterCodes.filter(
      (item) => item && ["price"].indexOf(item) === -1
    );

    return {
      ids: filterFilteredCodes,
    };
  }, [JSON.stringify(stats), JSON.stringify(facets)]);

  const {
    loading: loadingCachedAttributes,
    error,
    data,
  } = useDataCachedAttributes(options);

  const rowClasses = isMobile ? classesMobile.row : classes.row;

  const dataFilter = useSelector((state) => state.benefitGroups);
  const isLoadingFilterBenefits = dataFilter?.isLoading;

  const loading =
    isLoadingFilterBenefits || loadingCachedAttributes || isFiltersLoading;

  const filteredSkus = dataFilter?.benefitGroups
    ? Object.keys(dataFilter.benefitGroups)
    : [];

  const filteredIdsToFetch = isArrayHasItems(filteredSkus)
    ? subscriptionItems
        ?.filter((el) => {
          return filteredSkus.includes(el.sku);
        })
        .map((el) => +el.id)
    : [];

  switch (true) {
    case !!error: {
      return <ErrorComponent />;
    }
    default: {
      const isSubscriptions = isArrayHasItems(ids)
        ? areSubscriptionIdsEqual(ids, subscriptionItems)
        : false;

      const facetsHiddenProcessed = isSubscriptions
        ? facetsHiddenForSpecialCategories
        : facetsHidden;

      const countProcessed = isSubscriptions
        ? filteredIdsToFetch.length
        : count;
      const countShow = isLoadingFilterBenefits ? undefined : countProcessed;

      // filter out sub items if present on non-sub listing
      const idsFiltered = ids?.filter((el) => {
        const isSubItem = subscriptionItems.find((sub) => +sub.id === el);
        return !isSubItem;
      });
      return (
        <>
          <ErrorBoundary>
            <ListingHeader
              name={name}
              count={isSubscriptions && loading ? undefined : countShow}
              sortOptions={sortOptions}
              stats={stats}
              facets={facets}
              facetsHidden={facetsHiddenProcessed}
              attributes={data}
            />
          </ErrorBoundary>
          <div className={`row ${rowClasses}`}>
            {isMobile ? null : (
              <aside className="col col-3">
                <ErrorBoundary>
                  <Categories
                    className={classes.categories}
                    facets={facets}
                    subItems={subscriptionItems}
                  />
                </ErrorBoundary>
                <ErrorBoundary>
                  <Filters
                    stats={stats}
                    facets={facets}
                    facetsHidden={facetsHiddenProcessed}
                    attributes={data}
                    isAnixeTourism={false}
                    ids={ids as number[]}
                  />
                </ErrorBoundary>
                <ErrorBoundary>
                  <BannerCampaign />
                </ErrorBoundary>
              </aside>
            )}
            <div className="col col-9">
              <ErrorBoundary>
                {loading ||
                !idsSliced.length ||
                (!dataFilter &&
                  (!filteredIdsToFetch.length || !idsFiltered?.length)) ? (
                  <SkeletonProductsList />
                ) : (
                  <Products
                    ids={
                      isSubscriptions
                        ? filteredIdsToFetch
                        : (idsFiltered as string[])
                    }
                    activeFilters={[]}
                    isAnixeTourism={false}
                    additionalParameters={additionalParameters}
                    categoriesPath={categoriesPath}
                  />
                )}
              </ErrorBoundary>
            </div>
          </div>
        </>
      );
    }
  }
};

export default ListingSubscriptions;
