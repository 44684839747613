import Automatic from "../assets/icons/subscription/ik_automatyczne.svg";
import Obligatory from "../assets/icons/subscription/ik_obowiazkowe.svg";
import GracePeriod from "../assets/icons/subscription/ik_z_karencja.svg";
import Dependent from "../assets/icons/subscription/ik_zalezne.svg";
import Replacing from "../assets/icons/subscription/ik_zastepujace.svg";

const LEGEND = [
  {
    code: "dependent",
    name: "Zależne",
    icon: Dependent,
    message: "Nie wybierzesz tego abonamentu bez pakietu głównego",
  },
  {
    code: "mandatory",
    name: "Obowiązkowe",
    icon: Obligatory,
    message: "Pakiet obligatoryjny, wskazany przez Twojego pracodawcę",
  },
  {
    code: "auto",
    name: "Automatyczny",
    icon: Automatic,
    message: "Ten abonament jest wybierany przez Twojego pracodawcę na start",
  },
  {
    code: "grace",
    name: "Z karencją",
    icon: GracePeriod,
    message:
      "Ten abonament ma czas obowiązywania określony przez Twojego pracodawcę. Nie można z niego zrezygnować przed upływem wyznaczonego terminu",
  },
  {
    code: "replacing",
    name: "Zastępujące",
    icon: Replacing,
    message: "Zastępuje abonament automatyczny",
  },
];

const BENEFIT_TYPES = {
  AUTO: "auto",
  FREE: "free",
  MANDATORY: "mandatory",
  OPTIONAL: "optional",
  GRACE: "grace",
};
const BENEFIT_FREE_CONFIGURATIONS = {
  DEPENDENT: "dependent",
  INDEPENDENT: "independent",
  REPLACING: "replacing",
};

const BENEFIT_PURCHASE_METHOD = {
  INDIVIDUAL: "individual",
  GROUP: "group",
};

const BENEFIT_EMPLOYER_PRICE_TYPE = {
  HIDDEN: "hiddenEmployerPrice",
  VARIANT: "variantPrice",
};
const BENEFIT_PRICE_TYPE_METHOD = {
  EMPLOYER: "employer",
  EMPLOYEE: "employee",
  CO_FINANCED: "coFinanced",
  EMPLOYEE_PAY_U: "employeePayU",
  POINTS: "points",
};

const IRI = {
  BENEFIT: "/api/subscription-management/v1/rest/benefits",
  GROUP: "/api/subscription-management/v1/rest/benefit-groups",
};

const GROUP_TYPES = {
  SINGLE: "single",
  MULTI: "multichoice",
};

// @TODO should be deleted when all components that use it will be .tsx ( there is enum in Subscription.ts )
const BENEFIT_STATUS = {
  STATUS_CANCELED: "canceled",
  STATUS_PENDING: "pending",
  STATUS_PENDING_ACCEPTED: "pending_accepted",
  STATUS_ACTIVE: "active",
  STATUS_RESIGNED: "resigned",
  STATUS_SUSPENDED: "suspended",
  STATUS_DENIED: "denied",
  COMPLETED_STATUS_READY: "ready",
  COMPLETED_STATUS_WAITING_FOR_ACTIVE_FORMS: "waitingForActiveForms",
  COMPLETED_STATUS_WAITING_FOR_PDF_FORMS: "waitingForPdfForms",
  NEW_PERSON: "new_person",
  PROCESSING: "processing",
  BENEFIT_PRICING_CHANGED: "benefit_pricing_changed",
};

const REQUIRED_ACTIVITES_INFO_BLOCK =
  "Lista czynności, które należy podjąć by wybrane abonamenty mogły zostać zrealizowane w najbliższym terminie, lub by zostały aktywne przez kolejny okres.";
const BENEFIT_PRODUCT_TYPES = {
  GROUP_1: "abonament_group_1",
  GROUP_2: "abonament_group_2",
};

const BENEFIT_URLKEY_TYPES = {
  GROUP_BOTH: "abonamenty",
  GROUP_1: "abonamenty-grupa-i",
  GROUP_2: "abonamenty-grupa-ii",
};

const BENEFIT_GROUP_TYPE = "benefitGroupType";

const ATTACHMENT_SCOPE = {
  GROUP: "BenefitGroupAttachment",
  SUBGROUP: "BenefitSubgroupAttachment",
};

const ATTACHMENT_TYPES = {
  form: "Formularze",
  regulations: "Regulaminy",
  other: "Inne dokumenty",
};

enum SOURCE_FOUND_LABEL {
  employeePayU = "100% płatne z PayU",
  points = "100% płatne z :bankName",
  employee = "100% płatne przez Ciebie",
  employeeSubText = "comiesięczne potrącenie z wynagrodzenia",
  employer = "100% płatne przez pracodawcę",
}

enum SOURCE_FOUND_MARKER {
  employeePayU = "employeePayUSubText",
  points = "pointsSubText",
  employee = "employeeSubText",
  employeeSubText = "employeeSubText",
  employer = "employerSubText",
}

const SOURCE_FOUND_LABEL_SUMMARY = {
  employeePayU: "płatne z PayU",
  employee: "płatne przez Ciebie",
  employer: "płatne przez pracodawcę",
  points: "płatne z",
};

const DUPLICATE_DATA_TYPE = {
  address: "address",
  jo: "jo",
  mp: "mp",
  own: "own",
  without__deliver: "without__deliver",
};

const DUPLICATE_REASONS_ID = {
  lost: "lost",
  damage: "damage",
  change: "change",
  incorrect: "incorrect",
  "lost-by-object": "lost-by-object",
};

const DUPLICATE_ADDITIONAL_FIELDS_NAME = {
  city: "city",
  flatNumber: "flatNumber",
  houseNumber: "houseNumber",
  "post-code": "post-code",
  street: "street",
};

const DUPLICATE_REASONS = [
  { id: DUPLICATE_REASONS_ID.lost, value: "Karta została zgubiona" },
  { id: DUPLICATE_REASONS_ID.damage, value: "Karta uległa uszkodzeniu" },
  { id: DUPLICATE_REASONS_ID.change, value: "Zmieniły się dane personalne" },
  { id: DUPLICATE_REASONS_ID.incorrect, value: "Błędne dane na karcie" },
  {
    id: DUPLICATE_REASONS_ID["lost-by-object"],
    value: "Karta zgubiona przez obiekt sportowy",
  },
];

const DUPLICATE_CALL = {
  from_desktop: "fromDesktop",
  from_stepTwo: "fromStepTwo",
};

const DUPLICATE_ACTION_TYPES = {
  cancel: "cancel",
  correct: "correct",
};

const INITIAL_DUPLICATE_RECEIVER_DATA = {
  showForm: false,
  index: -1,
  receiver: "",
};

const AGREEMENT_TYPES = {
  OFFLINE_ONLY: "offline",
  ONLINE_ONLY: "online",
  ANY: "any",
  NONE: "none",
};

const AGREEMENT_DEFAULT_LEVEL = "company";
const REMOVE_PERSON_PARAM = "removePerson";

export {
  LEGEND,
  BENEFIT_TYPES,
  BENEFIT_FREE_CONFIGURATIONS,
  IRI,
  GROUP_TYPES,
  BENEFIT_PURCHASE_METHOD,
  BENEFIT_EMPLOYER_PRICE_TYPE,
  BENEFIT_PRICE_TYPE_METHOD,
  BENEFIT_STATUS,
  REQUIRED_ACTIVITES_INFO_BLOCK,
  BENEFIT_PRODUCT_TYPES,
  BENEFIT_URLKEY_TYPES,
  BENEFIT_GROUP_TYPE,
  SOURCE_FOUND_LABEL,
  SOURCE_FOUND_MARKER,
  ATTACHMENT_SCOPE,
  ATTACHMENT_TYPES,
  SOURCE_FOUND_LABEL_SUMMARY,
  DUPLICATE_REASONS,
  DUPLICATE_REASONS_ID,
  DUPLICATE_DATA_TYPE,
  DUPLICATE_CALL,
  DUPLICATE_ACTION_TYPES,
  DUPLICATE_ADDITIONAL_FIELDS_NAME,
  AGREEMENT_TYPES,
  AGREEMENT_DEFAULT_LEVEL,
  INITIAL_DUPLICATE_RECEIVER_DATA,
  REMOVE_PERSON_PARAM,
};

export const NOT_ACTIVE_STATUSES = [
  BENEFIT_STATUS.STATUS_PENDING,
  BENEFIT_STATUS.NEW_PERSON,
  BENEFIT_STATUS.STATUS_PENDING_ACCEPTED,
  BENEFIT_STATUS.PROCESSING,
  BENEFIT_STATUS.BENEFIT_PRICING_CHANGED,
];
export const pointsPerMonth = "pkt/msc";
export const zlotyPerMonth = "zł/msc";
