/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import { IApiRequestMethods } from "web/api/apiRequestTypes";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";
import getQueryString from "web/utils/system/query/getQueryString";

import restUrls from "web/constants/restUrls";

import Language from "web/types/Language";

import { request } from "web/api";

export const fetchInterfaceTranslations = async (scope: string) => {
  const language = getLanguageCode() as Language;
  const response = await request(
    `${restUrls.interfaceTranslations}${getQueryString({
      language,
      value: [scope],
    })}`,
    {
      method: IApiRequestMethods.GET,
    }
  );
  return JSON.parse(response as string);
};
export const fetchSimpleTranslations = async (scopes: string[]) => {
  const language = getLanguageCode() as Language;
  const response = await request(
    `${restUrls.interfaceTranslations}${getQueryString({
      language,
      value: scopes,
    })}`,
    {
      method: IApiRequestMethods.GET,
    }
  );
  return JSON.parse(response as string);
};

export const fetchMissingTranslations = async (
  missingPhrases: Set<string>,
  scope: string
) => {
  const phrasesToFetch: string[] = [];
  [...missingPhrases].slice(0, 100).forEach((phrase) => {
    if (phrase) {
      phrasesToFetch.push(phrase);
      missingPhrases.delete(phrase);
    }
  });
  if (!phrasesToFetch.length) {
    return null;
  }

  const language = getLanguageCode() as Language;
  if (language === "pl") {
    return null;
  }

  const urls = splitTranslationRequestUrl(
    `${restUrls.interfaceMissingTranslations}${getQueryString({
      language,
      // @ts-ignore
      scope,
      value: phrasesToFetch,
    })}`
  );

  try {
    const responses = await Promise.all(
      urls.map((url) => {
        return request(url, {
          method: IApiRequestMethods.GET,
        });
      })
    );

    const responsesMerged = responses.reduce(
      // @ts-ignore
      (acc, curr) => ({ ...acc, ...jsonParse(curr) }),
      {}
    );

    return responsesMerged as { [key: string]: string };
  } catch (error) {
    console.error(error);
    newRelicErrorReport(error, "web-app/web/Layout/T/helpers.js - 151");
    return {};
  }
};

const splitTranslationRequestUrl = (
  url: string,
  result: string[] = []
): string[] => {
  const identifier = "&value";
  const maxUrlLength = 2048;

  if (url.length > maxUrlLength) {
    const prefix = url.slice(0, url.indexOf(identifier));

    const lastValueIndex = url.slice(0, maxUrlLength).lastIndexOf(identifier);

    result.push(url.slice(0, lastValueIndex));

    const nextUrl = `${prefix}${url.slice(lastValueIndex)}`;
    return splitTranslationRequestUrl(nextUrl, result);
  }

  return [...result, url];
};
