/* eslint-disable react/prop-types */
import { isNil } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Badge from "web/Layout/Common/Badge";
import Loading from "web/Layout/Common/Loading";

import CartIcon from "web/assets/icons/cart.svg";

import urls from "web/constants/urls";

import { getCartDetails } from "web/features/cart/cartThunks";

import CartTrigger from "./cartTrigger";
import classes from "./cartTrigger.scss";

const CartTriggerContainer = () => {
  const { items, totalCount, isAddingItem, isAddingItems } = useSelector(
    (state) => state.cart
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCartDetails());
  }, []);

  switch (true) {
    case isAddingItem: {
      return <Loading />;
    }
    case totalCount && totalCount > 0: {
      return (
        <CartTrigger
          items={items}
          totalCount={totalCount!}
          isAddingItem={isAddingItem}
          isAddingItems={isAddingItems}
        />
      );
    }
    default: {
      return (
        <Link
          className={classes.trigger}
          to={urls.checkoutDefaultCart}
          data-t1="cart_link"
        >
          {!isNil(totalCount) && (
            <Badge className={classes.badgeEmpty} amount={totalCount} />
          )}
          <CartIcon className={classes.icon} />
        </Link>
      );
    }
  }
};

export default CartTriggerContainer;
