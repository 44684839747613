import type { IGtm } from "web/types/Gtm";

interface IGtmGetLastPageTypeArgs {
  clickFrom?: string;
  listingPosition?: number;
  listingAmount?: number;
  listingCategory?: string;
  searchPhrase?: string;
  currentCgGroup2?: string;
  lastVirtualPageViewEvent?: ILastVirtualPageViewEvent;
  hasProductClick?: boolean;
  pageName?: string,
}

interface IGtmLastPage {
  list?: string;
  element?: string;
}

interface ILastVirtualPageViewEvent extends IGtm {
  productName?: string;
}

const gtmGetLastPageType = ({
  clickFrom = "",
  listingPosition = 1,
  listingAmount = 1,
  listingCategory = "",
  searchPhrase = "",
  currentCgGroup2,
  lastVirtualPageViewEvent = {} as ILastVirtualPageViewEvent,
  hasProductClick = true,
  pageName = "",
}: IGtmGetLastPageTypeArgs) => {
  const dataLayerTemp = window.dataLayer ? [...window.dataLayer].reverse() : [];

  const lastVisitedPage: ILastVirtualPageViewEvent = Object.keys(
    lastVirtualPageViewEvent
  ).length
    ? lastVirtualPageViewEvent
    : dataLayerTemp.find((element) => element.event === "virtualPageView") ||
      ({} as ILastVirtualPageViewEvent);

  const gtmLastPage: IGtmLastPage = {};

  if (searchPhrase.length > 0) {
    gtmLastPage.list = `Sugerowane wyniki:1:1:${searchPhrase}`;
  }
  if ((lastVisitedPage && searchPhrase.length === 0) || currentCgGroup2) {
    if (clickFrom) gtmLastPage.element = clickFrom;

    switch (true) {
      case !hasProductClick:
        gtmLastPage.list = undefined;
        break;
      case lastVisitedPage?.cgroup1 === "Strona wyszukiwania":
        gtmLastPage.list = `${
          lastVisitedPage.cgroup1
        }:1:1:${lastVisitedPage.cgroup2?.toLowerCase()}`;
        break;
      case !!lastVisitedPage?.cgroup2 || currentCgGroup2:
        gtmLastPage.list = `Kategoria:1:1:${
          currentCgGroup2 || lastVisitedPage?.cgroup2
        }`;
        break;
      case lastVisitedPage?.cgroup1 === "Konto klienta":
        gtmLastPage.list = `${lastVisitedPage.cgroup1}:1:1:Ulubione`;
        break;
      case lastVisitedPage?.cgroup1 === "Strona główna":
        gtmLastPage.list = `${lastVisitedPage.cgroup1}:${listingPosition}:${listingAmount}:${listingCategory}`;
        break;
        case lastVisitedPage?.cgroup1 === "LP":
          gtmLastPage.list = `${lastVisitedPage.cgroup1}:${listingPosition}:${listingAmount}:${pageName}/${listingCategory}`;
          break;
  
      case !!lastVisitedPage?.productName:
        gtmLastPage.list = `Produkt: ${lastVisitedPage.productName}`;
        break;
      case !!lastVisitedPage?.cgroup1:
        gtmLastPage.list = lastVisitedPage.cgroup1;
        break;
      default:
        return {};
    }
  }

  return gtmLastPage;
};

export default gtmGetLastPageType;
