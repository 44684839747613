import { FC } from "react";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { IAttributeOption } from "web/types/Attributes";

import classes from "./configurable.scss";

const getRightLeftRoundIndex = (length: number) => {
  switch (length) {
    case 6: {
      return [3, 5];
    }
    case 5: {
      return [3, 4];
    }
    case 4: {
      return [3, 3];
    }
    case 3: {
      return [0, 2];
    }
    case 2: {
      return [0, 1];
    }
    default:
      return [0, 0];
  }
};

interface IProductCardTopConfigurableProps {
  options: (IAttributeOption | undefined)[];
}

const ProductCardTopConfigurable: FC<IProductCardTopConfigurableProps> = ({
  options = [],
}) => {
  const optionsProcessed = isArrayHasItems(options)
    ? options.reduce((result, current) => {
        if (current) {
          return [...result, current];
        }

        return result;
      }, [] as IAttributeOption[])
    : [];
  const optionsSliced = isArrayHasItems(optionsProcessed)
    ? optionsProcessed.slice(0, 6)
    : [];
  const [leftIndex, rightIndex] = getRightLeftRoundIndex(optionsSliced.length);

  return (
    <div className={classes.root}>
      <ul className={classes.list}>
        {optionsSliced.map((option, index) => {
          const itemClassName = `${classes.item} ${
            index === leftIndex ? classes.itemLeft : ""
          } ${index === rightIndex ? classes.itemRight : ""}`.trim();
          return (
            <li className={itemClassName} key={option.value}>
              <span className={classes.option}>{option.label}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProductCardTopConfigurable;
