import { FC } from "react";

import __ from "web/Layout/Translations";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import ResetButton from "../ResetButton";
import ActiveFiltersItem from "./ActiveFiltersItem";
import styles from "./activeFilters.scss";
import type { IActiveFilter } from "./container";

interface IActiveFiltersProps {
  filters: IActiveFilter[];
}

const ActiveFilters: FC<PropsWithClasses<IActiveFiltersProps>> = ({
  classes = {},
  filters,
}) => {
  return isArrayHasItems(filters) ? (
    <div className={classes.root}>
      <span className={classes.title} data-t1="active_filters_span">
        {__("Aktywne filtry")}
      </span>
      <ul className={classes.list}>
        {filters.map((filter) => (
          <li
            className={classes.item}
            key={filter.code + filter.value}
            data-t1={filter.code}
            data-t2="filter_code_item"
          >
            <ActiveFiltersItem
              code={filter.code}
              label={filter.label}
              value={filter.value}
              values={filter.values}
              labelValue={filter.labelValue}
            />
          </li>
        ))}
      </ul>
      <ResetButton
        className={classes.remove}
        dataT1="active_filters_delete_all"
        text={__("Usuń wszystkie")}
      />
    </div>
  ) : null;
};

export default classify<PropsWithClasses<IActiveFiltersProps>>(styles)(
  ActiveFilters
);
