import { AnyAction } from "redux";

import errorExceptionsConfig from "./errorExceptions.config";

/**
 * checks if rtk error should be logged
 * @param action rtk action
 * @param exceptions logging exceptions config {endpointName: status[]}
 */
const isErrorException = (
  { meta, payload }: AnyAction,
  exceptions = errorExceptionsConfig
) => {
  const endpointName = meta?.arg?.endpointName || "";
  const { status } = payload || {};
  const exception = exceptions[endpointName];
  return exception === "all" || !!exception?.includes(status);
};

export default isErrorException;
