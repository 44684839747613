// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.productPrices-desktopProductPrices-Xa_{height:60px;margin-bottom:25px}.productPrices-mobileProductPrices-xLB{height:60px;margin-bottom:16px}`, "",{"version":3,"sources":["webpack://./web/Layout/Common/SkeletonComponents/SkeletonProduct/SkeletonProductPrices/productPrices.scss"],"names":[],"mappings":"AAIuB,wCACrB,WAAA,CACA,kBAAA,CAGF,uCACE,WAAA,CACA,kBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .desktopProductPrices {\n  height: 60px;\n  margin-bottom: 25px;\n}\n\n.mobileProductPrices {\n  height: 60px;\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktopProductPrices": `productPrices-desktopProductPrices-Xa_`,
	"mobileProductPrices": `productPrices-mobileProductPrices-xLB`
};
export default ___CSS_LOADER_EXPORT___;
