import { isArray } from "lodash";

import sanitizeObject from "../object/sanitizeObject";
import sanitizeString from "../string/sanitizeString";

const sanitizeArray = (array: unknown[]): unknown[] => {
  if (!isArray(array)) return array;
  return array.map((item) => {
    if (typeof item === "string") return sanitizeString(item);
    if (isArray(item)) return sanitizeArray(item);
    if (item && typeof item === "object") return sanitizeObject(item);
    return item;
  });
};

export default sanitizeArray;
