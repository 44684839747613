import { FC } from "react";

import __ from "web/Layout/Translations";

import Switch from "web/Components/Common/Switch";

import { CodeItemStatusCode } from "web/hooks/useCodeItemStatus/useCodeItemStatus";

import classes from "./codeItemHeader.scss";

interface ICodeItemHeader {
  className?: string;
  onChange: (val: boolean) => void;
  switchable?: boolean;
  label: string;
  hoverLabel: string;
  statusCode: CodeItemStatusCode;
  active: boolean;
}

const CodeItemHeader: FC<ICodeItemHeader> = ({
  className,
  onChange,
  switchable = false,
  label,
  hoverLabel,
  statusCode,
  active,
}) => {
  const classNameRoot = className || classes.root;

  return (
    <header className={classNameRoot}>
      <span className={classes.container}>
        <div className={`switch ${switchable && "active"} ${classes.content}`}>
          <div className={`${classes.dot} ${classes[statusCode]}`} />
          <div className={classes.label}>
            <span className="statusText" data-t1="status_text_4">
              {__(label)}
            </span>
          </div>
        </div>
        <div className={`switch ${switchable && "active"} ${classes.content}`}>
          <Switch
            classes={classes}
            active={active}
            size={21}
            label={
              <span className="statusText" data-t1="status_text_4">
                {__(hoverLabel)}
              </span>
            }
            onChange={onChange}
            disabled={!switchable}
          />
        </div>
      </span>
    </header>
  );
};

export default CodeItemHeader;
