import BrowserPersistence from "web/utils/system/storage/storage/browserPersistence";
import redirectToBrowserSettingsError from "web/utils/system/storage/storage/redirectToBrowserSettingsError";

import storageNames from "web/constants/storageNames";

const storage = new BrowserPersistence();

export const availableLanguages = [
  { key: "pl_PL", label: "Polski", name: "PL", code: "pl" },
  { key: "en_GB", label: "Angielski", name: "EN", code: "en" },
];

export const defaultLanguage = "pl_PL";

export const getLanguageKey =
  (): (typeof availableLanguages)[number]["code"] => {
    try {
      const language = window.localStorage.getItem(
        storageNames.language
      ) as string;
      if (availableLanguages.find((lang) => lang.key === language)) {
        return language;
      }
    } catch (error) {
      redirectToBrowserSettingsError();
    }

    return defaultLanguage;
  };

export const getLanguageCode = () => {
  const selectedLanguage = getLanguageKey();
  return (
    availableLanguages.find(({ key }) => key === selectedLanguage)?.code || "pl"
  );
};

export const setLanguage = (lang: string) => {
  window.localStorage.setItem(storageNames.language, lang);
  storage.setItem(storageNames.language, null, lang);
};

export const availableLanguagesKeys = availableLanguages.map(({ key }) => key);

export const availableLanguagesCodes = availableLanguages.map(
  ({ code }) => code
);

export const convertToKeyIfCode = (code: string) => {
  const filteredLanguage = availableLanguages.find((language) => {
    return language.code === code;
  });

  return filteredLanguage ? filteredLanguage.key : code;
};

export const isDefaultLanguage = getLanguageKey() === defaultLanguage;

function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts?.length === 2
    ? (parts as string[]).pop()?.split(";").shift()
    : null;
}
export const previewTranslations =
  !isDefaultLanguage && getCookie("preview_translations") === "1";

export const setLocaleField = (
  lang = "pl",
  fieldPl: string,
  fieldEn: string
) => {
  return lang === "en" && fieldEn ? fieldEn : fieldPl;
};
