/* eslint-disable consistent-return */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { removePromoLink, setPromoItems } from "web/features/gtm/gtm";
import { IPromoItems } from "web/types/Gtm";

const useSetGtmPromoItemsByPathname = ( ids: number[]) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { promoLinks } = useSelector((state) => state.gtm);

  useEffect(() => {
    const { creative_slot, promotion_name } = promoLinks?.[location?.pathname] || {};
    if (!promotion_name) {
      return;
    }

    const promotionsPerProduct: IPromoItems = {};
    ids.forEach((id: number) => {
      promotionsPerProduct[id] = { creative_slot, promotion_name };
    });
    dispatch(setPromoItems(promotionsPerProduct));

    return () => {
      dispatch(removePromoLink(location?.pathname));
    };
  }, [ids, location?.pathname, promoLinks, dispatch]);
};

export default useSetGtmPromoItemsByPathname;
