import { motion } from "framer-motion";
import { FC, useMemo, useState } from "react";

import LinkTransition from "web/Layout/Common/LinkTransition";
import { SkeletonProductCategories } from "web/Layout/Common/SkeletonComponents";
import __ from "web/Layout/Translations";

import DropArrow from "web/Components/Common/DropArrow";

import ArrowBackIcon from "web/assets/icons/arrow-long-left.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import urls from "web/constants/urls";

import { ICategory } from "web/types/Category";
import { PropsWithClasses } from "web/types/Common";
import { Facets } from "web/types/Facets";
import { ISubscriptionItemMs } from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import classify from "web/classify";

import List from "./List";
import defaultClasses from "./categories.scss";

const variants = {
  open: {
    opacity: 1,
    height: "auto",
    overflow: "visible",
  },
  close: {
    opacity: 0,
    height: 0,
    overflow: "hidden",
  },
};

interface ICategoriesProps {
  categories: ICategory[];
  parentCategoryId: Nullable<number>;
  childrenIds: number[];
  siblingIds: number[];
  subscriptionItems?: ISubscriptionItemMs[];
  facets: Facets;
}

const Categories: FC<PropsWithClasses<ICategoriesProps>> = ({
  classes = {},
  categories = [],
  parentCategoryId = null,
  childrenIds = [],
  siblingIds = [],
  subscriptionItems = [],
  facets = [],
}) => {
  const [isExpanded, setExpanded] = useState(true);
  const homePageData = {
    request_path: urls.home,
    name: __("Strona Główna"),
  };
  const [parentCategory, categoriesToShow] = useMemo(() => {
    const parentCategoryById =
      parentCategoryId &&
      isArrayHasItems(categories) &&
      categories.find(
        (categoryItem) =>
          categoryItem && `${categoryItem.id}` === `${parentCategoryId}`
      );
    const parentCategoryMemo = parentCategoryById || homePageData;
    if (isArrayHasItems(categories)) {
      const categoriesChildrenToShow = isArrayHasItems(childrenIds)
        ? categories.filter(
            (categoryItem) =>
              categoryItem &&
              childrenIds.some((id) => `${id}` === `${categoryItem.id}`)
          )
        : [];
      const categoriesSiblingsToShow = isArrayHasItems(siblingIds)
        ? categories.filter(
            (categoryItem) =>
              categoryItem &&
              siblingIds.some((id) => `${id}` === `${categoryItem.id}`)
          )
        : [];
      const categoriesToShowMemo = isArrayHasItems(categoriesChildrenToShow)
        ? categoriesChildrenToShow
        : categoriesSiblingsToShow;

      return [parentCategoryMemo, categoriesToShowMemo];
    }

    return [parentCategoryMemo, []];
  }, [
    parentCategoryId,
    JSON.stringify(categories),
    homePageData,
    siblingIds,
    childrenIds,
  ]);
  const urlBack =
    parentCategory.request_path === homePageData.request_path
      ? parentCategory.request_path
      : `/${parentCategory.request_path}`;

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <LinkTransition
          className={classes.linkBack}
          url={urlBack}
          name={parentCategory.name}
          dataT1="categories"
        >
          <ArrowBackIcon className={classes.linkBackIcon} width={12} />
          {__("Cofnij do")}
          <span className={classes.linkBackName}>{parentCategory.name}</span>
        </LinkTransition>
        {isArrayHasItems(categoriesToShow) && (
          <button
            type="button"
            className={classes.button}
            onClick={() => setExpanded(!isExpanded)}
            aria-label="Toggle expand"
          >
            <DropArrow className={classes.arrow} open={!isExpanded} />
          </button>
        )}
      </header>
      {isArrayHasItems(categoriesToShow) && (
        <motion.div
          className={classes.content}
          animate={isExpanded ? "open" : "close"}
          variants={variants}
        >
          <div className={classes.wrapper}>
            <List
              categories={categoriesToShow}
              facets={facets}
              subscriptionItems={subscriptionItems}
            />
          </div>
        </motion.div>
      )}
      {!isArrayHasItems(categoriesToShow) && <SkeletonProductCategories />}
    </div>
  );
};

export default classify<PropsWithClasses<ICategoriesProps>>(defaultClasses)(
  Categories
);
