import { useState } from "react";
import { useSelector } from "react-redux";

import BankResetAnnouncement from "web/Layout/BankResetAnnouncement";
import Logo from "web/Layout/Common/Logo";
import ErrorBoundary from "web/Layout/ErrorBoundary";
import MessagesTrigger from "web/Layout/MainHeader/Shared/MessagesTrigger";

import { useGetCompanyDetailsQuery } from "web/features/company/companyApiSlice";

import classes from "./mainHeader.scss";

const MobileMainHeader = () => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const [headerLogoSrc, headerLogoAlt] = storeConfig
    ? [storeConfig.header_logo_src, storeConfig.header_logo_alt]
    : [null, null];
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const { data: company } = useGetCompanyDetailsQuery();
  const shouldShowMessage = company?.displayMessageOneTimeLossPoints || false;

  return (
    <>
      <header
        className={`${classes.root} ${
          isNotificationOpen ? classes.rootHigherIndex : ""
        }`}
      >
        <div>
          <BankResetAnnouncement shouldShowMessage={shouldShowMessage} />
        </div>
        <div className={classes.header}>
          <ErrorBoundary>
            <Logo
              className={classes.logo}
              logoSrc={headerLogoSrc}
              logoAlt={headerLogoAlt}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <MessagesTrigger
              onNotificationOpen={(isOpen: boolean) =>
                setNotificationOpen(isOpen)
              }
            />
          </ErrorBoundary>
        </div>
      </header>
      <div style={{ height: "64px" }}/> { /* element is added to prevent Firefox errors with infinite scrolling because of "position: sticky" */ }
    </>
  );
};

export default MobileMainHeader;
