/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useField } from "formik";
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import { useMemo } from "react";

import ValidationErrorMessage from "web/Components/Common/ValidationErrorMessage";

import CheckIcon from "web/assets/icons/check.svg";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import { validateField } from "web/utils/system/formValidator/validation";

import classify from "web/classify";

import ToolTip from "../../ToolTip";
import styles from "./checkbox.scss";

const Checkbox = ({
  children,
  name,
  required,
  type,
  classes,
  onChange,
  onBlur,
  onClick,
  isDisabled,
  validationRules,
  dataT1,
  tooltipMessage,
  uppercase,
  groupType,
}) => {
  const rules = useMemo(() => {
    switch (true) {
      case !!isArrayHasItems(validationRules): {
        return validationRules;
      }
      case typeof required === "string" && !!required.length: {
        return [required];
      }
      default:
        return null;
    }
  }, [validationRules, required]);
  const [field, meta] = useField({
    name,
    onChange,
    onBlur,
    validate: rules ? validateField(rules) : null,
  });
  const isError = meta.error && meta.touched;
  const disabledClassName = isDisabled ? classes.faded : "";
  const errorClassName = isError ? classes.checkError : classes.check;
  const checkClassName = `${errorClassName} ${disabledClassName}`.trim();

  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.flex}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={`${classes.label} ${classes.customLabel}`}>
          <input
            type={type}
            className="visually-hidden"
            disabled={isDisabled}
            checked={field.value}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...field}
            data-t1={dataT1}
          />
          <span className={checkClassName} data-t1={dataT1}>
            <CheckIcon className={classes.icon} />
          </span>
          {required && <span className={classes.required}>*</span>}
          <span
            className={`${classes.labelText ? classes.labelText : ""} ${
              uppercase ? classes.uppercase : ""
            }`.trim()}
          >
            {children}
          </span>
        </label>
        {tooltipMessage && (
          <ToolTip
            message={tooltipMessage}
            customStyles={!groupType ? classes : ""}
          />
        )}
      </div>
      <ValidationErrorMessage name={name} />
    </div>
  );
};

Checkbox.propTypes = {
  classes: shape({
    root: string,
    label: string,
    check: string,
    checkError: string,
    icon: string,
    faded: string,
    required: string,
    inactive: string,
    error: string,
  }).isRequired,
  validationRules: oneOfType([arrayOf(string), oneOf([null])]),
  children: oneOfType([string, node]),
  name: string.isRequired,
  required: bool,
  isDisabled: bool,
  type: oneOf(["checkbox", "radio"]),
  onChange: oneOfType([func, oneOf([null])]),
  onBlur: oneOfType([func, oneOf([null])]),
  onClick: oneOfType([func, oneOf([null])]),
  dataT1: string,
  tooltipMessage: string,
  uppercase: bool,
  groupType: number,
};

Checkbox.defaultProps = {
  children: "",
  required: false,
  isDisabled: false,
  type: "checkbox",
  onChange: null,
  onBlur: null,
  onClick: null,
  validationRules: null,
  dataT1: "checkbox",
  tooltipMessage: "",
  uppercase: false,
};

export default classify(styles)(Checkbox);
export { Checkbox };
