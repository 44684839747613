/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ComponentType } from "react";

import HelmetHeader from "./helmetHeader";

export interface WithHelmetProps {
  title: string;
}

const withHelmet = <Props extends object>(
  WrappedComponent: ComponentType<Props>
): React.FC<Props & WithHelmetProps> => {
  return ({ title, ...restProps }: WithHelmetProps) => {
    return (
      <>
        <HelmetHeader title={title} />
        {/* @ts-ignore */}
        <WrappedComponent title={title} {...restProps} />
      </>
    );
  };
};

export default withHelmet;
