/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC } from "react";
import { Redirect, useLocation } from "react-router-dom";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import { SkeletonCategoriesListing } from "web/Layout/Common/SkeletonComponents";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { IAttribute } from "web/types/Attributes";
import type { Facets } from "web/types/Facets";
import type { Stats } from "web/types/Stats";
import { ISubscriptionItemMs } from "web/types/Subscription";
import { Nullable } from "web/types/Utils";

import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

import Listing from "../listing";

interface IListingTypeContainerProps {
  ids: (number | string)[];
  facets: Facets;
  stats: Stats;
  name: string;
  count: number;
  additionalParameters: string;
  categoriesPath: string;
  cgGroup1Path?: string;
  subscriptionItems?: ISubscriptionItemMs[];
}

interface IDifferentTypesData {
  tourism: {
    count: number;
    attributes: string[];
  };
  standard: {
    count: number;
    attributes: string[];
  };
}

const mbProductTypeCode = "mb_product_type_filter";
const ListingTypeContainer: FC<IListingTypeContainerProps> = ({
  ids = [],
  facets = [],
  stats = [],
  name,
  count,
  additionalParameters,
  categoriesPath,
  cgGroup1Path,
  subscriptionItems = [],
}) => {
  const types =
    isArrayHasItems(facets) &&
    facets.find((facet) => facet.code === mbProductTypeCode);

  const { loading, error, data } = useDataCachedAttributes({
    ids: [mbProductTypeCode],
    skip: !types || types.values?.length === 1,
  }) as { loading: boolean; error: unknown; data: IAttribute[] };

  const { search, pathname } = useLocation();

  if (!types || (isArrayHasItems(types.values) && types.values.length === 1)) {
    return (
      <Listing
        ids={ids as number[]}
        stats={stats}
        facets={facets}
        name={name}
        count={count}
        additionalParameters={additionalParameters}
        categoriesPath={categoriesPath}
        cgGroup1Path={cgGroup1Path}
        subscriptionItems={subscriptionItems}
      />
    );
  }

  switch (true) {
    case !!loading: {
      return <SkeletonCategoriesListing />;
    }
    case !!error: {
      console.error(error);
      return <ErrorComponent />;
    }
    default: {
      // @ts-ignore
      const differentTypesData: Nullable<IDifferentTypesData> =
        isArrayHasItems(data) && isArrayHasItems(types.values)
          ? types.values.reduce<IDifferentTypesData>(
              // @ts-ignore
              (result, current) => {
                const currentAttribute = data.find(
                  (item) => item && item.attribute_code === mbProductTypeCode
                );
                const currentOption =
                  currentAttribute &&
                  isArrayHasItems(currentAttribute.attribute_options) &&
                  currentAttribute.attribute_options.find(
                    (item) => item && current && item.value === current.value
                  );

                if (currentOption) {
                  if (currentOption.code === "anixe_tourism") {
                    return {
                      ...result,
                      tourism: {
                        count: current.count,
                        attributes: [current.value],
                      },
                    };
                  }

                  return {
                    ...result,
                    standard: {
                      count: +result.standard.count + +current.count,
                      attributes: [
                        ...result.standard.attributes,
                        current.value,
                      ],
                    },
                  };
                }

                return result;
              },
              {
                tourism: {
                  count: 0,
                  attributes: [],
                },
                standard: {
                  count: 0,
                  attributes: [],
                },
              }
            )
          : null;

      return differentTypesData &&
        differentTypesData.tourism &&
        differentTypesData.tourism.count &&
        differentTypesData.standard &&
        differentTypesData.standard.count ? (
        <Redirect
          to={getUrl({
            search,
            attributes: differentTypesData.standard.attributes,
            code: mbProductTypeCode,
            pathname,
          })}
        />
      ) : (
        <Listing
          ids={ids as number[]}
          stats={stats}
          facets={facets}
          name={name}
          count={count}
          additionalParameters={additionalParameters}
          categoriesPath={categoriesPath}
          cgGroup1Path={cgGroup1Path}
          subscriptionItems={subscriptionItems}
        />
      );
    }
  }
};

export default ListingTypeContainer;

interface IGetUrlArgs {
  search: string;
  attributes: string[];
  code: string;
  pathname: string;
}

const getUrl = ({ search, attributes, code, pathname }: IGetUrlArgs) => {
  const params = new URLSearchParams(search);
  params.delete(code);

  if (isArrayHasItems(attributes)) {
    attributes.forEach((attribute) => {
      params.append(code, encodeURIComponent(attribute));
    });
  }

  return `${pathname}?${params.toString()}`;
};
