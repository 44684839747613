import { ErrorData, IApiRequestMethods } from "web/api/apiRequestTypes";

import restUrls from "web/constants/restUrls";

import {
  FormView,
  ISubscriptionReceiverFormValues,
  SubscriptionFormReceiver,
} from "web/types/Subscription";

import { request } from "web/api";

interface IFormEditData {
  fields?: ISubscriptionReceiverFormValues;
  receiverId: string;
  receiverType: SubscriptionFormReceiver;
  receiverEmail?: string;
  subscriptionId: string;
  viewType: FormView;
  onSuccess: (response: unknown) => void;
  onError: (error: ErrorData) => void;
}

export default async ({
  fields,
  receiverId,
  receiverEmail,
  receiverType,
  subscriptionId,
  viewType,
  onSuccess,
  onError,
}: IFormEditData) => {
  const data = {
    fields,
    receiverId,
    receiver: receiverType,
    receiverEmail: receiverEmail ?? null,
  };
  const path =
    restUrls[
      viewType === FormView.CONFIGURATOR
        ? "employeeSubscriptionDraftItemsEditReceiverForm"
        : "employeeSubscriptionItemsEditReceiverForm"
    ];

  try {
    const response = await request(path.replace(":uuid", subscriptionId), {
      headers: {
        accept: "application/ld+json",
        "Content-type": "application/merge-patch+json",
      },
      method: IApiRequestMethods.PATCH,
      body: JSON.stringify(data),
    });

    if (typeof onSuccess === "function") onSuccess(response);
  } catch (error) {
    if (typeof onError === "function") onError(error as ErrorData);
    console.error(error);
  }
};
