/* eslint-disable react/jsx-props-no-spreading */
import { FC, PropsWithChildren } from "react";

import FiltersFieldsetHeaderTrigger, {
  IFiltersFieldsetHeaderTrigger,
} from "./Trigger";
import classes from "./header.scss";

const FiltersFieldsetHeader: FC<
  PropsWithChildren<IFiltersFieldsetHeaderTrigger>
> = ({ children, title, ...props }) => (
  <header className={classes.root} data-t1={`triggerHeader__${title}`}>
    <FiltersFieldsetHeaderTrigger title={title} {...props} />
    {children}
  </header>
);

export default FiltersFieldsetHeader;
