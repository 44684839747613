import { Dispatch } from "react";

import { getStoreConfig } from "web/features/app/appThunks";
import { getSubscriptionConfiguration } from "web/features/subscriptionQuote/subscriptionQuoteThunks";

const getCustomerStoreConfigAndGoogle = async (
  dispatch: Dispatch<unknown>
): Promise<void> => {
  await dispatch(getStoreConfig({}));
};

const fetchSesssionData = async (
  dispatch: Dispatch<unknown>
): Promise<void> => {
  getCustomerStoreConfigAndGoogle(dispatch);
  dispatch(getSubscriptionConfiguration());
};

export default fetchSesssionData;
