import { FC, PropsWithChildren } from "react";

import { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./badge.scss";

interface IBadgeProps {
  amount?: string | number;
  dataT1?: string;
  withoutSpacer?: boolean;
  round?: boolean;
}

const Badge: FC<PropsWithChildren<PropsWithClasses<IBadgeProps>>> = ({
  classes = {},
  amount = null,
  dataT1 = "badge",
  children = null,
  withoutSpacer,
  round,
}) => {
  const content = typeof amount === "number" ? amount : children;
  const spacer = !withoutSpacer && <>&nbsp;</>;
  return (
    <strong className={classes.root} data-t1={dataT1} data-t2={content}>
      {spacer}
      <span
        className={classes.box}
        style={round ? { borderRadius: "5px" } : undefined}
      >
        <span className={classes.number}>{content}</span>
      </span>
    </strong>
  );
};

export default classify<PropsWithChildren<PropsWithClasses<IBadgeProps>>>(
  defaultClasses
)(Badge);
