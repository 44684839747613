// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from "uuid";

import BrowserTemporality from "web/utils/system/storage/storage/browserTemporality";

import storageNames from "web/constants/storageNames";

const storage = new BrowserTemporality();
export const ridKey = "RID";

const getRid = () => {
  const ridFromStorage = storage.getItem(storageNames.tourismDomestic, ridKey);

  if (ridFromStorage) {
    return ridFromStorage;
  }

  const rid = uuidv4();

  storage.setItem(storageNames.tourismDomestic, ridKey, rid);

  return rid;
};

export default getRid;
