import { useField } from "formik";
import { bool, number, oneOfType, shape, string } from "prop-types";

import convertUtcDateToLocal from "web/utils/data/parser/dateAndTime/convertUtcDateToLocal";

import Datepicker from "./datepicker";

const DatepickerFormik = ({
  classes,
  className,
  label,
  placeholder,
  name,
  minDate,
  maxDate,
  disabled,
  buttonBackgroundColor,
  buttonBorderColor,
  datePickerIconColor,
  dataT1,
  isMobile,
  eventListenerName,
  dropdownClickOutside,
  convertToDate,
}) => {
  const [field, meta, helpers] = useField(name);
  const isError = meta.error && meta.touched;
  const { setValue } = helpers;
  // Date values should be stored in database in universal format
  // For independent reasons (flatpickr doesn't support UTC), date should be converted to local time
  const value = convertUtcDateToLocal(field, convertToDate);

  return (
    <>
      <Datepicker
        name={name}
        isError={isError}
        classes={classes}
        className={className}
        isMobile={isMobile}
        label={label}
        placeholder={placeholder}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        defaultValue={value}
        buttonBackgroundColor={buttonBackgroundColor}
        buttonBorderColor={buttonBorderColor}
        datePickerIconColor={datePickerIconColor}
        onChange={setValue}
        dataT1={dataT1}
        eventListenerName={eventListenerName}
        dropdownClickOutside={dropdownClickOutside}
        useUTC
      />
      <input type="hidden" name={name} value={value} />
    </>
  );
};
DatepickerFormik.propTypes = {
  className: string,
  classes: oneOfType([shape({}), string]),
  dataT1: string,
  label: string,
  placeholder: string,
  name: string.isRequired,
  disabled: bool,
  buttonBackgroundColor: string,
  buttonBorderColor: string,
  datePickerIconColor: string,
  minDate: oneOfType([string, number, shape({})]),
  maxDate: oneOfType([string, number, shape({})]),
  isMobile: bool,
  eventListenerName: string,
  dropdownClickOutside: bool,
  convertToDate: bool,
};

DatepickerFormik.defaultProps = {
  className: "",
  classes: {},
  dataT1: "date_picker_formik",
  label: "",
  placeholder: "Data",
  disabled: false,
  minDate: null,
  maxDate: null,
  buttonBackgroundColor: null,
  buttonBorderColor: null,
  datePickerIconColor: null,
  isMobile: false,
  eventListenerName: "click",
  dropdownClickOutside: true,
  convertToDate: false,
};

export default DatepickerFormik;
