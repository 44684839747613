import { FC } from "react";

import LinkTransition from "web/Layout/Common/LinkTransition";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { ICategory } from "web/types/Category";
import { IFacetsItem } from "web/types/Facets";
import { Nullable } from "web/types/Utils";

import { useCategoryContext } from "web/context/category";

import classes from "./list.scss";

interface IListProps {
  categories: ICategory[];
  facet: IFacetsItem;
}

export type CategoryWithCount = ICategory & { count?: Nullable<number> };

const List: FC<IListProps> = ({ categories, facet }) => {
  const { category = {} as ICategory } = useCategoryContext();
  const { id: currentCategoryId } = category;

  const categoriesToShow = processCategoriesToShow(category, categories, facet);

  return isArrayHasItems(categoriesToShow) ? (
    <ul className={classes.root}>
      {(categoriesToShow as CategoryWithCount[]).map(
        ({ id, name, request_path: requestPath, count }) => {
          const isCurrentCategory = +currentCategoryId === +id;
          return (
            <li className={classes.item} key={id}>
              {isCurrentCategory ? (
                <span className={classes.linkCurrent}>
                  {name} <span className={classes.count}>({count})</span>
                </span>
              ) : (
                <LinkTransition
                  className={classes.link}
                  url={`/${requestPath}`}
                  name={name}
                  dataT1="list"
                >
                  {name}{" "}
                  {count && <span className={classes.count}>({count})</span>}
                </LinkTransition>
              )}
            </li>
          );
        }
      )}
    </ul>
  ) : null;
};

export default List;

// used for both - desktop list and subscriptions
export const processCategoriesToShow = (
  category: ICategory,
  categories: ICategory[],
  facet: IFacetsItem
) => {
  const {
    extra_field_over_subcategory_name: extraFieldName,
    extra_field_over_subcategory_url: extraFieldUrl,
  } = category;

  const extraFieldOverCategory = extraFieldName &&
    extraFieldUrl && {
      request_path:
        extraFieldUrl[0] === "/" ? extraFieldUrl.substring(1) : extraFieldUrl,
      name: extraFieldName,
    };

  const categoriesFiltered = categories
    .reduce((result: ICategory[], current) => {
      const countItem =
        facet && isArrayHasItems(facet.values)
          ? facet.values.find(({ value }) => `${value}` === `${current.id}`)
          : null;

      return countItem && countItem.count
        ? [
            ...result,
            {
              ...current,
              count: countItem.count,
            },
          ]
        : result;
    }, [])
    .sort((a, b) => a?.position - b?.position);

  const categoriesToShow = extraFieldOverCategory
    ? [extraFieldOverCategory, ...categoriesFiltered]
    : categoriesFiltered;

  return categoriesToShow;
};
