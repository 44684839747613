import { FC, PropsWithChildren } from "react";

import ExternalForm from "web/Pages/ExternalForm";

import { nativeRedirectionQueryParam } from "web/constants/native";
import urls from "web/constants/urls";

import Loading from "../Common/Loading/loading";
import ErrorSSO from "../ErrorPages/ErrorSSO";
import classes from "./external.scss";

const External: FC<PropsWithChildren> = ({ children }) => {
  const { pathname, search } = window.location;

  const params = new URLSearchParams(search);

  if (params.has(nativeRedirectionQueryParam)) {
    const processedPathname =
      pathname.charAt(0) === "/" ? pathname.slice(1) : pathname;
    const nativeUrl = params.get(nativeRedirectionQueryParam);
    (window as Window).location = `${nativeUrl}?url=${processedPathname}`;

    return (
      <div className={classes.mobileAppRedirectionInfo}>
        <Loading />
      </div>
    );
  }
  if (pathname === urls.ssoError) {
    return <ErrorSSO />;
  }
  if (pathname.includes(urls.externalSubscriptionForm)) {
    const pathArray = pathname.split("/");
    const subscriptionItemId = pathArray[pathArray.length - 2];
    const token = pathArray[pathArray.length - 1];
    return (
      <ExternalForm token={token} subscriptionItemId={subscriptionItemId} />
    );
  }
  return <>{children}</>;
};

export default External;
