/* eslint-disable react/prop-types */
import { Field as FieldFormik, useFormikContext } from "formik";
import { FC } from "react";

import ValidationErrorMessage from "web/Components/Common/ValidationErrorMessage";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import { Nullable } from "web/types/Utils";

import Select from "./Select";
import classes from "./field.scss";
import FieldAge from "./fieldAge";

interface IFieldProps {
  className?: string;
  type: string;
  name: string;
  id: string;
  label: string;
  readonly: boolean;
  key: string;
  options: Nullable<string[] | { value: string; label: string }[]>;
  rules: string[];
  placeholder?: string;
  component?: string;
}

const Field: FC<IFieldProps> = ({
  className = "",
  type,
  name,
  id,
  label,
  readonly,
  key,
  options = [],
  placeholder,
  component,
  rules = [],
}) => {
  const isRequired =
    isArrayHasItems(rules) && rules.some((rule) => rule === "required");
  const errorComponent = <ValidationErrorMessage name={name} />;
  const rootClassName = className || classes.root;
  const { errors, touched } = useFormikContext();

  const isError =
    errors[name as keyof typeof errors] &&
    touched[name as keyof typeof touched];

  const fieldClassName = isError ? classes.fieldError : classes.field;

  switch (type) {
    case "select": {
      return (
        <div className={rootClassName} key={key}>
          <span className={isRequired ? classes.labelRequired : classes.label}>
            {label}
          </span>
          <Select
            className={classes.select}
            name={name}
            options={options}
            readonly={readonly}
            placeholder={placeholder}
          />
          {errorComponent}
        </div>
      );
    }
    case "hidden": {
      return name && name.indexOf("age") !== -1 ? (
        <div className={`${rootClassName} ${classes.age}`} key={key}>
          <label className={classes.label} htmlFor={id}>
            {label}
          </label>
          <FieldAge id={id} name={name} type={type} key={key} />
        </div>
      ) : (
        <FieldFormik name={name} type={type} key={key} />
      );
    }
    default: {
      return (
        <div className={rootClassName} key={key}>
          <label
            className={isRequired ? classes.labelRequired : classes.label}
            htmlFor={id}
          >
            {label}
          </label>
          <FieldFormik
            component={component}
            className={fieldClassName}
            name={name}
            type={type}
            id={id}
            readOnly={readonly}
            tabIndex={readonly ? -1 : 0}
            placeholder={placeholder}
          />
          {errorComponent}
        </div>
      );
    }
  }
};

export default Field;
