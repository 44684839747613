import { useMemo } from "react";

import __ from "web/Layout/Translations";

import { dayMilliseconds } from "web/constants/date";
import orderCodeStatus from "web/constants/orderCodeStatus";
import productStatusCodes from "web/constants/productCodeStatus";

import { ProductCodeStatus } from "web/types/Code";
import { OrderStatus } from "web/types/Order";

interface ICodeItemStatus {
  hoverLabel?: string;
  switchable?: boolean;
  disabled?: boolean;
  label?: CodeItemStatusLabel;
  isCanceled?: boolean;
  active?: boolean;
  isExpired?: boolean | "";
  statusCode: CodeItemStatusCode;
}

const enum CodeItemStatusLabel {
  CANCELED = "Anulowany",
  PENDING = "Oczekuje na kod",
  EXPIRED = "Przeterminowany",
  CAN_BE_USED = "Do wykorzystania",
  IS_USED = "Wykorzystany",
}

export const enum CodeItemStatusCode {
  AVAILABLE = "available",
  USED = "used",
  EXPIRED = "expired",
  CANCELED = "canceled",
  WAITING = "waiting",
}

const useCodeItemStatus = (
  productStatus: ProductCodeStatus,
  isAvailable: boolean,
  expirationDate: string,
  orderStatus: OrderStatus = "" as OrderStatus
): ICodeItemStatus =>
  useMemo(() => {
    const isExpired =
      expirationDate &&
      (Date.parse(expirationDate.replace(/-/g, "/")) || 0) + dayMilliseconds <
        Date.now();
    const { canceled, canceledError, claimFailed, reservationFailed } =
      productStatusCodes;
    const {
      canceled: orderCanceled,
      canceledError: orderCanceledError,
      canceledMissingPayment: orderCanceledMissingPayment,
      toCancel: orderToCancel,
      rollBackError: orderRollbackError,
      rollBack: orderRollback,
      rolledBack: orderRolledBack,
      toManuallyCancel: orderToManuallyCancel,
      manuallyCancelError: orderManuallyCancelError,
      claimSuccess: orderClaimSuccess,
    } = orderCodeStatus;

    // CANCELED
    if (
      [canceled, canceledError, reservationFailed, claimFailed].includes(
        productStatus
      ) ||
      [
        orderCanceled,
        orderCanceledError,
        orderCanceledMissingPayment,
        orderToCancel,
        orderRollbackError,
        orderRollback,
        orderRolledBack,
        orderToManuallyCancel,
        orderManuallyCancelError,
      ].includes(orderStatus)
    ) {
      return {
        disabled: true,
        label: __(CodeItemStatusLabel.CANCELED) as CodeItemStatusLabel,
        isCanceled: true,
        active: false,
        isExpired,
        statusCode: CodeItemStatusCode.CANCELED,
      };
    }

    // EXPIRED / USED
    if (isExpired) {
      return {
        disabled: true,
        label: (isAvailable
          ? __(CodeItemStatusLabel.EXPIRED)
          : __(CodeItemStatusLabel.IS_USED)) as CodeItemStatusLabel,
        isCanceled: false,
        active: false,
        isExpired,
        statusCode: isAvailable
          ? CodeItemStatusCode.EXPIRED
          : CodeItemStatusCode.USED,
      };
    }

    // CAN BE USED / USED
    if (orderStatus === orderClaimSuccess) {
      return {
        hoverLabel: __("Odznacz jeśli wykorzystałeś"),
        switchable: true,
        disabled: false,
        label: (isAvailable
          ? __(CodeItemStatusLabel.CAN_BE_USED)
          : __(CodeItemStatusLabel.IS_USED)) as CodeItemStatusLabel,
        isCanceled: false,
        active: isAvailable,
        isExpired,
        statusCode: isAvailable
          ? CodeItemStatusCode.AVAILABLE
          : CodeItemStatusCode.USED,
      };
    }

    // PENDING
    return {
      disabled: true,
      label: __(CodeItemStatusLabel.PENDING) as CodeItemStatusLabel,
      isCanceled: false,
      active: isAvailable,
      isExpired,
      statusCode: CodeItemStatusCode.WAITING,
    };
  }, [productStatus, isAvailable, expirationDate, orderStatus]);

export default useCodeItemStatus;
