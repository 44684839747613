import { number } from "prop-types";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";

import __ from "web/Layout/Translations";

import { pageDefault, pageName } from "web/constants/toolbar";

import styles from "./loadMore.scss";

interface IListingProductsLoadMoreProps {
  count: number;
  campaignCounts: number;
}

const ListingProductsLoadMore: FC<IListingProductsLoadMoreProps> = ({
  count,
  campaignCounts,
}) => {
  const { push } = useHistory();
  const { search, pathname } = useLocation();
  const params = new URLSearchParams(search);
  const { grid_per_page: pageSizeDefault } = useSelector(
    (state) => state.app.storeConfig
  );
  const pageCurrent = parseInt(params.get(pageName) || String(pageDefault), 10);
  const pageSizeCurrent = pageCurrent * pageSizeDefault - campaignCounts;

  const loadMoreAction = () => {
    params.set(pageName, `${pageCurrent + 1}`);

    push(`${pathname}?${params.toString()}`);
  };

  return pageSizeCurrent < count ? (
    <button
      type="button"
      onClick={loadMoreAction}
      className={styles.root}
      data-t1="load_more"
    >
      <span className={styles.row} data-t1="load_more_span">
        <span className={styles.icon}>
          <span className={styles.dots} />
        </span>
        {__("Załaduj więcej")}
      </span>
    </button>
  ) : null;
};

ListingProductsLoadMore.propTypes = {
  count: number.isRequired,
  campaignCounts: number.isRequired,
};

export default ListingProductsLoadMore;
