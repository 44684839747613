import { arrayOf, number, shape, string } from "prop-types";

import CarouselMobile from "web/Layout/CarouselMobile";
import Product from "web/Layout/ProductCard";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import getPriceFinalPriceDiscount from "web/utils/page/product/universal/getPriceFinalPriceDiscount";

import classify from "web/classify";

import defaultClasses from "./productCarousel.scss";

const ProductCarouselMobile = ({
  products,
  classes,
  itemWidth,
  currency,
  productSuffix,
  listingPosition,
  listingAmount,
  listingCategory,
}) => {
  const productStyles = {
    width: typeof itemWidth === "number" && itemWidth ? `${itemWidth}px` : null,
  };
  const isOverflowHidden = products.length <= 2;
  return isArrayHasItems(products) ? (
    <CarouselMobile
      className={classes.root}
      withoutOverflow={isOverflowHidden}
      listingPosition={listingPosition}
      listingAmount={listingAmount}
      listingCategory={listingCategory}
    >
      {products.map((product) => {
        const {
          id,
          url_key: urlKey,
          name,
          thumbnail,
          price: productPrice,
          final_price: productFinalPrice,
          product_options: options,
        } = product;

        const { price, finalPrice } = getPriceFinalPriceDiscount({
          price: productPrice,
          finalPrice: productFinalPrice,
          options,
        });

        const modifiedProduct = {
          url: `/${urlKey}${productSuffix}`,
          name,
          image: thumbnail,
          price,
          finalPrice,
          currency,
          options,
        };

        return (
          <div key={id} className={classes.product} style={productStyles}>
            <Product
              product={modifiedProduct}
              gtmProduct={product}
              productType="carousel"
              listingPosition={listingPosition}
              listingAmount={listingAmount}
              listingCategory={listingCategory}
            />
          </div>
        );
      })}
    </CarouselMobile>
  ) : null;
};

ProductCarouselMobile.propTypes = {
  classes: shape({
    root: string,
  }).isRequired,
  products: arrayOf(shape({})).isRequired,
  itemWidth: number.isRequired,
  currency: string.isRequired,
  productSuffix: string.isRequired,
  listingPosition: number,
  listingAmount: number,
  listingCategory: string,
};

ProductCarouselMobile.defaultProps = {
  listingPosition: null,
  listingAmount: null,
  listingCategory: "",
};

export default classify(defaultClasses)(ProductCarouselMobile);
