import { FC } from "react";

import __ from "web/Layout/Translations";

import { getValueByType } from "web/utils/page/product/subscription/forms/prepareFormData";

import formLabels from "web/constants/formLabels";

import {
  FormType,
  ISubscriptionFormData,
  ISubscriptionFormMode,
  ISubscriptionReceiverFormField,
  SubscriptionFormFieldType,
} from "web/types/Subscription";

import FieldPreview from "./FieldPreview";

interface ISubscriptionReceiverDataPreviewProps {
  fields: ISubscriptionReceiverFormField[];
  formType: FormType;
  formData: ISubscriptionFormData;
  mode: ISubscriptionFormMode;
}

const SubscriptionReceiverDataPreview: FC<
  ISubscriptionReceiverDataPreviewProps
> = ({ fields, formType, formData, mode }) => {
  return (
    <>
      {/* Small form should preview whole data - get form structure from fields & match them in values */}
      {formType === FormType.FORM &&
        fields.map((field) => (
          <FieldPreview
            key={field.id}
            label={field.label}
            value={formData.fields[field.id] as string}
          />
        ))}

      {/* Active Form should preview only firstname & lastname, in case of external forms also email */}
      {formType === FormType.ACTIVE_FORM && (
        <>
          {mode.externalForm && (
            <FieldPreview
              label={__(formLabels.email)}
              value={formData.fields.receiverEmail as string}
            />
          )}
          <FieldPreview
            label={__(formLabels.firstname)}
            value={
              getValueByType(
                fields,
                formData,
                SubscriptionFormFieldType.FIRSTNAME
              ) as string
            }
          />
          <FieldPreview
            label={__(formLabels.lastname)}
            value={
              getValueByType(
                fields,
                formData,
                SubscriptionFormFieldType.LASTNAME
              ) as string
            }
          />
        </>
      )}
    </>
  );
};

export default SubscriptionReceiverDataPreview;
