/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormikErrors } from "formik";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import isEmpty from "web/utils/data/validator/object/isEmpty";
import rulesValidation from "web/utils/system/formValidator/rulesValidation";

export const getMessageError = <T = Record<string, unknown>>(
  value: unknown,
  rule: { name: string; additional: unknown } | string,
  values?: T,
  selectedLanguage?: string
): string | null => {
  const [ruleName, ruleAdditional] =
    typeof rule === "object" ? [rule.name, rule.additional] : [rule];

  // @ts-ignore
  return rulesValidation[ruleName]?.call(rulesValidation, {
    value,
    ruleAdditional,
    values,
    selectedLanguage,
  });
};

export const validateField =
  (
    rules: (string | { name: string; additional: string })[],
    selectedLanguage?: string
  ) =>
  <T = Record<string, string>>(value: string, values: T) => {
    let message = null;
    rules?.some((rule) => {
      message = getMessageError(value, rule, values, selectedLanguage);
      return !!message;
    });
    return message;
  };

export const getInitialValues = <T = { [key: string]: string | boolean }>(
  fields: { name: string; initialValue: string | boolean }[]
): T =>
  fields?.reduce((result, current) => {
    const { name, initialValue } = current;
    return { ...result, [name]: initialValue };
  }, {} as T);

export const validateFields = <T = { [key: string]: string }>(
  fields: {
    name: string;
    rules?: (string | { name: string; additional: string })[];
    ignoreSubResultValidation?: boolean;
    selectedLanguage?: string;
  }[]
) => {
  const validateFieldsIteration = (values: T): Promise<FormikErrors<T>> =>
    // @ts-ignore
    Object.entries(values).reduce((result, [key, value]: [string, string]) => {
      const isValueNotEmptyObject =
        typeof value === "object" && !isEmpty(value);
      const validateSubResult = !fields.find((item) => item.name === key)
        ?.ignoreSubResultValidation;

      if (value && isValueNotEmptyObject && validateSubResult) {
        const subResult = validateFieldsIteration(value);

        return !isEmpty(subResult)
          ? {
              ...result,
              [key]: subResult,
            }
          : result;
      }

      const rules = fields.find((item) => item.name === key)?.rules;
      const selectedLanguage = fields.find(
        (item) => item.name === key
      )?.selectedLanguage;

      if (isArrayHasItems(rules)) {
        const messageError = validateField(rules, selectedLanguage)(
          value,
          values
        );

        if (messageError) {
          return { ...result, [key]: messageError };
        }
      }
      return result;
    }, {});
  return validateFieldsIteration;
};
