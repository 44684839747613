import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

type Prices = Array<string | number | undefined | null>;

export default (prices: Prices) => {
  if (!isArrayHasItems(prices)) return 0;

  return prices.reduce(
    (result: number, current) => (current ? result + +current : result),
    0
  );
};
