import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import GtmEvent from "web/Layout/Gtm/GtmEvent";

import useGetProductsGtmsOptions from "web/hooks/useGetProductsGtmsOptions";
import useGetTourismProductOptions from "web/hooks/useGetTourismProductOptions";

import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import checkNewIdsUniq from "web/utils/system/GTM/checkNewIdsUnique";
import gtmGetOptions from "web/utils/system/GTM/gtmGetOptions";

import { DomesticTourismProductInList } from "web/types/Product";
import { Nullable } from "web/types/Utils";

interface IAnixeTourismProductsGtmsProps {
  data?: DomesticTourismProductInList[];
  ids?: number[];
  additionalParameters?: Nullable<string>;
  previousValue: number;
  currency?: string;
  categoriesPath?: string;
  cgGroup1Path?: string;
  pageSizeDefault: number;
}

const AnixeTourismProductsGtms: FC<IAnixeTourismProductsGtmsProps> = ({
  data = [],
  ids = [],
  additionalParameters = "",
  previousValue,
  currency = "",
  categoriesPath = "",
  cgGroup1Path = "",
  pageSizeDefault,
}) => {
  const location = useLocation();
  const url = location?.pathname + location?.search;
  const gtms = useSelector((state) => state.gtm);
  const { promoItems } = gtms;

  const productsList = useMemo(() => {
    return isArrayHasItems(data) && isArrayHasItems(ids)
      ? ids.reduce((result: DomesticTourismProductInList[], id) => {
          const edgeData =
            id && data.find((edge) => edge && edge.id && +edge.id === +id);
          return edgeData ? [...result, edgeData] : result;
        }, [])
      : [];
  }, [data, ids]);

  const {
    finalProductOptions,
    isReady,
    lastProductImpressionEvent,
    lastVirtualPageViewEvent,
    listingPromotion,
  } = useGetProductsGtmsOptions(productsList, gtms, location);

  const { dateFrom, dateTo, peopleAmount } = useGetTourismProductOptions(
    additionalParameters as string
  );

  const [gtmVirtualPageViewOptions, gtmEnhancedEcommOptions] = useMemo(() => {
    return gtmGetOptions({
      data,
      currency,
      categories: categoriesPath,
      isAnixe: true,
      productsOptionsList: finalProductOptions,
      previousValue,
      lastProductImpressionEvent,
      isReady,
      lastProductPosition: previousValue,
      dateFrom,
      dateTo,
      peopleAmount,
      pageSizeDefault,
      cgGroup1Path,
      location,
      promoItems,
      listingPromotion,
    });
  }, [
    isReady,
    previousValue,
    currency,
    categoriesPath,
    data,
    JSON.stringify(finalProductOptions),
    JSON.stringify(lastProductImpressionEvent),
    dateFrom,
    dateTo,
    peopleAmount,
    pageSizeDefault,
    cgGroup1Path,
    location,
    promoItems,
    listingPromotion,
  ]);

  const isAllGtmNewIdsUniq = checkNewIdsUniq(
    lastProductImpressionEvent,
    gtmEnhancedEcommOptions,
    lastVirtualPageViewEvent
  );

  return (
    <>
      {gtmVirtualPageViewOptions?.cgroup2 &&
        (url !== lastVirtualPageViewEvent?.url ||
          lastVirtualPageViewEvent?.cgroup1 !==
            gtmVirtualPageViewOptions?.cgroup1 ||
          lastVirtualPageViewEvent?.cgroup2 !==
            gtmVirtualPageViewOptions?.cgroup2) && (
          <GtmEvent options={gtmVirtualPageViewOptions} withUrl />
        )}
      {isReady &&
        (gtmEnhancedEcommOptions?.ecommerce?.impressions?.length || 0) > 0 &&
        isAllGtmNewIdsUniq && (
          <GtmEvent
            eventName="productImpression"
            options={gtmEnhancedEcommOptions}
          />
        )}
    </>
  );
};
export default AnixeTourismProductsGtms;
