/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import ErrorComponent from "web/Layout/Common/ErrorComponent";
import Loading from "web/Layout/Common/Loading";
import __ from "web/Layout/Translations";

import formatDate from "web/utils/data/parser/dateAndTime/formatDate";
import splitGeolocationData from "web/utils/data/parser/geolocation/splitGeolocationData";
import formatNumber from "web/utils/data/parser/number/formatNumber";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import urlSearchParamsToolbarFiltered from "web/utils/page/product/universal/urlSearchParamsToolbarFiltered";

import { eventDate, geolocation } from "web/constants/filters";

import type { BankItems } from "web/types/Banks";
import type { Nullable } from "web/types/Utils";

import { useGetBanksQuery } from "web/features/pointsBank/pointsBankApiSlice";
import useDataCachedAttributes from "web/features/useDataCached/useDataCachedAttributes";

import ActiveFilters from "./activeFilters";

export interface IActiveFilter {
  code: string;
  label: string;
  labelValue: string;
  value?: string;
  values?: string[];
  attribute_code?: string;
  attribute_label?: string;
  attribute_options?: IActiveFilter[];
}

interface IActiveFiltersWrapperProps {
  banks?: BankItems;
  values: Record<string, string[]>;
}

const ActiveFiltersWrapper: FC<IActiveFiltersWrapperProps> = ({
  values,
  banks,
}) => {
  const storeConfig = useSelector((state) => state.app.storeConfig);
  const { id: storeId, token } = storeConfig || {};
  const variables = useMemo(() => {
    const ids = Object.keys(values);
    return {
      ids,
    };
  }, [values, storeId, token]);

  const { loading, error, data } = useDataCachedAttributes(variables);
  switch (true) {
    case !!loading: {
      return <Loading />;
    }
    case !!error: {
      return <ErrorComponent />;
    }
    default: {
      // @ts-ignore
      const activeFilters: IActiveFilter[] = isArrayHasItems(data)
        ? // @ts-ignore
          data.reduce<IActiveFilter[]>((result, attributeData) => {
            const attributeCode = attributeData && attributeData.attribute_code;
            const attributeValues =
              attributeCode &&
              Object.prototype.hasOwnProperty.call(values, attributeCode)
                ? values[attributeCode]
                : null;

            if (attributeValues) {
              switch (true) {
                case attributeCode === geolocation.attribute_code: {
                  const { placeId, distance } =
                    splitGeolocationData(attributeValues);
                  return placeId && distance
                    ? [
                        ...result,
                        {
                          label: attributeData.attribute_label,
                          value: attributeValues,
                          code: attributeCode,
                        },
                      ]
                    : result;
                }
                case attributeCode === eventDate.attribute_code: {
                  const labelFrom = attributeValues[0]
                    ? `od ${formatDate(attributeValues[0])}`
                    : "";
                  const labelTo = attributeValues[1]
                    ? `do ${formatDate(attributeValues[1])}`
                    : "";
                  return [
                    ...result,
                    {
                      label: attributeData.attribute_label || attributeCode,
                      labelValue:
                        labelFrom || labelTo
                          ? `${labelFrom} ${labelTo}`.trim()
                          : "",
                      values: attributeValues,
                      code: attributeCode,
                    },
                  ];
                }
                case attributeCode === "payment_settings": {
                  const subValues = isArrayHasItems(attributeValues)
                    ? attributeValues.map((attributeValue) => {
                        if (attributeValue === "payu") {
                          return {
                            label:
                              (attributeData &&
                                attributeData.attribute_label) ||
                              attributeCode,
                            value: attributeValue,
                            labelValue: __("Płatność własna"),
                            code: attributeCode,
                          };
                        }

                        const currentBank =
                          isArrayHasItems(banks) &&
                          banks.find(
                            (bank) => bank.points_bank_id === attributeValue
                          );
                        const currentBankLabel = currentBank
                          ? currentBank.points_bank_name
                          : __("Bank punktów ktory nie istnieje");

                        return {
                          label:
                            (attributeData && attributeData.attribute_label) ||
                            attributeCode,
                          value: attributeValue,
                          labelValue: currentBankLabel,
                          code: attributeCode,
                        };
                      })
                    : [];

                  return [...result, ...subValues];
                }
                case attributeCode === "final_price": {
                  return [
                    ...result,
                    {
                      label:
                        (attributeData && attributeData.attribute_label) ||
                        attributeCode,
                      labelValue: `${formatNumber(
                        attributeValues[0]
                      )} - ${formatNumber(attributeValues[1])} ${__("zł")}`,
                      values: attributeValues,
                      code: attributeCode,
                    },
                  ];
                }
                case isArrayHasItems(attributeValues): {
                  const subValues = attributeValues.map((attributeValue) => {
                    const optionData =
                      attributeData &&
                      isArrayHasItems(attributeData.attribute_options)
                        ? attributeData.attribute_options.find(
                            (opt) =>
                              opt && `${opt.value}` === `${attributeValue}`
                          )
                        : null;
                    return {
                      label:
                        (attributeData && attributeData.attribute_label) ||
                        attributeCode,
                      value: attributeValue,
                      labelValue:
                        (optionData && optionData.label) || attributeValue,
                      code: attributeCode,
                    };
                  });

                  return [...result, ...subValues];
                }
                default: {
                  const optionData =
                    attributeData &&
                    isArrayHasItems(attributeData.attribute_options)
                      ? attributeData.attribute_options.find(
                          (opt) => opt && `${opt.value}` === `${attributeCode}`
                        )
                      : null;
                  return [
                    ...result,
                    {
                      label:
                        (attributeData && attributeData.attribute_label) ||
                        attributeCode,
                      value: attributeValues,
                      labelValue:
                        (optionData && optionData.label) || attributeCode,
                      code: attributeCode,
                    },
                  ];
                }
              }
            }

            return result;
          }, [])
        : ([] as IActiveFilter[]);

      return <ActiveFilters filters={activeFilters} />;
    }
  }
};

interface IActiveFiltersContainerProps {
  facetsHidden: string[];
}

const ActiveFiltersContainer: FC<IActiveFiltersContainerProps> = ({
  facetsHidden = [],
}) => {
  const { data: dataBanks } = useGetBanksQuery();
  const { items: banks } = dataBanks || {};
  const { search } = useLocation();
  const filtersActive = urlSearchParamsToolbarFiltered(search);

  const filtersActiveFiltered: Nullable<Record<string, string[]>> =
    useMemo(() => {
      switch (true) {
        // @ts-ignore
        case !filtersActive: {
          return null;
        }
        case !!isArrayHasItems(facetsHidden): {
          const keys = Object.keys(filtersActive);
          return keys && keys.length
            ? keys.reduce((result, key) => {
                return facetsHidden.indexOf(key) !== -1
                  ? result
                  : {
                      ...result,
                      [key]: filtersActive[key],
                    };
              }, {})
            : filtersActive;
        }
        default: {
          return filtersActive;
        }
      }
    }, [filtersActive]);

  return filtersActiveFiltered && Object.keys(filtersActiveFiltered)?.length ? (
    <ActiveFiltersWrapper values={filtersActiveFiltered} banks={banks} />
  ) : null;
};

export default ActiveFiltersContainer;
